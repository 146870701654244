import React, { Component } from "react";
import { Container, Step } from "semantic-ui-react";
import { observer } from "mobx-react";
import AssociatedUserState from "../../../state/associatedUserState";
import UserDetailForm from "./userDetailForm";
import EmergencyContactForm from "./emergencyContactForm";
import UserPreview from "./userReview";
import UserComplate from "./userComplete";
import ActivityState from "../../../state/activityState";

@observer
export default class UserDetailStep extends Component {
  onSelectStep = (event, data) => {
    /*if(ActivityState.isEditActivity){
			AssociatedUserState.setCurrentStep(data.stepnumber);
		}*/
  };

  render() {
    let { user, onClose, onSave, allowPreview, allowSelectUser, onCanCel } = this.props;
    let currentStep = AssociatedUserState.currentStep;

    user = AssociatedUserState.userFields;

    return (
      <Container className="registerBeacon-subContainer animated fadeIn fast">
        <Steps currentStep={currentStep} onSelectStep={this.onSelectStep} />
        <UserEditor currentStep={currentStep} user={user} onClose={onClose} onSave={onSave} allowPreview={allowPreview} allowSelectUser={allowSelectUser} onCanCel={onCanCel} />
      </Container>
    );
  }
}

const Steps = ({ currentStep, onSelectStep }) => {
  if (currentStep === 0 || currentStep === 1)
    return (
      <Step.Group widths={2}>
        <Step active={currentStep === 0} stepnumber={0}>
          <Step.Content>
            <Step.Title>Beacon Holder Details</Step.Title>
          </Step.Content>
        </Step>

        <Step active={currentStep === 1} stepnumber={1}>
          <Step.Content>
            <Step.Title>Emergency Contacts</Step.Title>
          </Step.Content>
        </Step>
      </Step.Group>
    );
  else return "";
};

const UserEditor = ({ currentStep, user, onClose, onSave, allowPreview, allowSelectUser, onCanCel }) => {
  switch (currentStep) {
    case 0:
      return <UserDetailForm user={user} onClose={onClose} allowSelectUser={allowSelectUser} onCanCel={onCanCel} />;
    case 1:
      return <EmergencyContactForm user={user} onSave={onSave} allowPreview={allowPreview} />;
    case 2:
      return <UserPreview />;
    case 3:
      return <UserComplate />;
    default:
      break;
  }
};
