import React, { Component } from "react";
import { Container, Modal, Header, Button } from "semantic-ui-react";
import ActivityState from "../../state/activityState";
import ActivitySteps from "./activity/activitySteps";
import RouteState from "../../state/routeState";
import { observer } from "mobx-react";
import "./registerActivity.css";
import UserState from "../../state/userState";
import AppState from "../../state/appState";
import { Redirect } from "react-router-dom";
import i18n from "i18next";

@observer
export default class RegisterActivity extends Component {
  state = {
    showModal: false,
  };

  onAskToCancle = (e) => {
    if (UserState.notAllowToEditByRoleId.includes(UserState.currentUserProfile.roleId)) {
      this.onModalAccept();
    } else {
      this.setState({
        showModal: true,
      });
      e.stopPropagation(); //This prevent the tab itself to register the click.
    }
  };

  onModalCancel = () => {
    this.setState({
      showModal: false,
    });
  };

  onModalAccept = () => {
    this.setState({
      showModal: false,
    });
    ActivityState.resetSteps();
    ActivityState.resetData();
    ActivityState.setIsEditActivity(false);
    RouteState.setRoute("/dashboard/activities");
    ActivityState.setActiveTab(0);
  };

  render() {
    const { showModal } = this.state;

    let appLoaded = AppState.appLoaded;
    let currentUser = UserState.currentUser;
    if (appLoaded && !currentUser) {
      return <Redirect to="/" />;
    }

    let activity = ActivityState.getActivityFields();

    let display = ActivityState.currentStep == 5 ? "none" : "block";

    return (
      <div className="registerUser-page">
        {/* <Container className="animated fadeIn faster cancel-btn-margin noprint">
          <a style={{ display: display }} className="cancel-add-edit" onClick={this.onAskToCancle}>
            Cancel and return to dashboard
          </a>
        </Container> */}

        {
            !ActivityState.isHideAskToCancel && (
                <Container className="animated fadeIn faster cancel-btn-margin noprint">
                <a style={{ display: display }} className="cancel-add-edit" onClick={this.onAskToCancle}>
                    {i18n.t('cancel-and-return-to-dashboard')}
                </a>
                </Container>
            )
        }

        <Container className="registerBeacon-mainFormContainer animated fadeIn faster adjust-margin">
          <Container className="beaconDetail-form animated fadeIn faster">
            <ActivitySteps activity={activity} onAskToCancle={this.onAskToCancle} />
          </Container>
        </Container>

        <Modal centered={false} open={showModal} size={"tiny"} className="centered">
          <Modal.Content>
            <Header className="modal-header">WARNING</Header>
            {i18n.t('do-you-want-to-cancel-editing-and-return-to-the-dashboard')}
            <div className="activityDetail-submitContainer">
              <Button className="registerBeacon-submitButton" onClick={this.onModalAccept}>
                {i18n.t('yes')}
              </Button>
              <Button className="registerBeacon-submitButton" onClick={this.onModalCancel} primary>
                {i18n.t('no')}
              </Button>
            </div>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}
