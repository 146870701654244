import React, { Component } from 'react';
import { observer } from 'mobx-react';
import AppConfigState from '../state/appConfigState';
import { Container, Divider, Dimmer, Loader } from "semantic-ui-react";
import DashboardState from '../state/dashboardState';
import * as UserService from '../services/userServices';
import UserState from '../state/userState';
import i18n from "i18next";
@observer
export default class AccountDetail extends Component {

    componentDidMount = async () => {
        const {beacon} = this.props
        console.log('log ~ file: accountDetail.js ~ line 14 ~ AccountDetail ~ componentDidMount= ~ beacon', beacon);
        let username = beacon.owner ? (beacon.owner.includes('org.couchdb.user:') ? beacon.owner.split(':')[1] : beacon.owner): null;

        UserState.getUserProfileById(beacon.owner)
        DashboardState.getTotalBeaconsByOwner({ownerUsername: username});
        // DashboardState.getTotalBeaconsByOwner({accountOwnerName: [beacon.accountOwnerName]});

        DashboardState.getTotalActivitiesByOwner(beacon.owner);
    }

    onViewAllBeaconsOfOwner = () => {
        const {beacon, onClose, onUpdateSearch} = this.props;
        let username = beacon.owner ? (beacon.owner.includes('org.couchdb.user:') ? beacon.owner.split(':')[1] : beacon.owner): null;

        DashboardState.searchBeacons({ownerUsername: [username]})
        // DashboardState.searchBeacons({accountOwnerName: [beacon.accountOwnerName]})
        DashboardState.setSpecialQuery(true)
        onClose();
        onUpdateSearch(username);
    };

    onViewAllActivitiesOfOwner = () => {
        const {beacon, onClose, onSearchActivityByOwner} = this.props;

        // [TODO] add search by owner
        onSearchActivityByOwner(beacon.owner)
        onClose();
    };

    render(){
        const {isActivity} = this.props

        let accountDetail = UserState.accountDetail;

        return (
            <>
            <Container className="beaconReviewDetails-mainFormContainer animated fadeIn faster">
                {
                    !accountDetail || DashboardState.isLoading ?
                    <Dimmer active={DashboardState.isLoading} inverted>
                        <Loader>{i18n.t('loading')}</Loader>
                    </Dimmer>
                    :
                    <>
                        <div>
                        {i18n.t('name')}: <strong>{accountDetail.ownerName || ""}</strong>
                        </div>
                        <div>
                        {i18n.t('username')}: <strong>{(accountDetail._id.includes('org.couchdb.user:') ? accountDetail._id.split(':')[1] : accountDetail._id) || ""}</strong>
                        </div>
                        <div>
                        {i18n.t('email')}: <strong>{accountDetail.emailAddress || ""}</strong>
                        </div>
                        <div>
                        {i18n.t('address')}: <strong>{accountDetail.address || ""}</strong>
                        </div>
                        <div>
                        {i18n.t('zip-postal-code-2')}: <strong>{accountDetail.mailCode || ""}</strong>
                        </div>
                        <div>
                        {i18n.t('city')}: <strong>{accountDetail.city || ""}</strong>
                        </div>
                        <div>
                        {i18n.t('state-province')}: <strong>{accountDetail.province || ""}</strong>
                        </div>
                        <div>
                        {i18n.t('country')}: <strong>{accountDetail.mailCountry || ""}</strong>
                        </div>
                        {accountDetail.phone1Num && accountDetail.phone1Num != "" ? (
                        <div>
                            {i18n.t('telephone-number-1')}:{" "}
                            <strong>
                            {accountDetail.phone1Num}
                            {accountDetail.phone1Type ? " (" + phoneTypeToText(accountDetail.phone1Type, accountDetail.phone1TypeOther) + ")" : ""}
                            </strong>
                        </div>
                        ) : (
                        ""
                        )}
                        {accountDetail.phone2Num && accountDetail.phone2Num != "" ? (
                        <div>
                            {i18n.t('telephone-number-2')}:{" "}
                            <strong>
                            {accountDetail.phone2Num}
                            {accountDetail.phone2Type ? " (" + phoneTypeToText(accountDetail.phone2Type, accountDetail.phone2TypeOther) + ")" : ""}
                            </strong>
                        </div>
                        ) : (
                        ""
                        )}
                        {accountDetail.phone3Num && accountDetail.phone3Num != "" ? (
                        <div>
                            {i18n.t('telephone-number-3')}:{" "}
                            <strong>
                            {accountDetail.phone3Num}
                            {accountDetail.phone3Type ? " (" + phoneTypeToText(accountDetail.phone3Type, accountDetail.phone3TypeOther) + ")" : ""}
                            </strong>
                        </div>
                        ) : (
                        ""
                        )}
                        {accountDetail.phone4Num && accountDetail.phone4Num != "" ? (
                        <div>
                            {i18n.t('telephone-number-4')}:{" "}
                            <strong>
                            {accountDetail.phone4Num}
                            {accountDetail.phone4Type ? " (" + phoneTypeToText(accountDetail.phone4Type, accountDetail.phone4TypeOther) + ")" : ""}
                            </strong>
                        </div>
                        ) : (
                        ""
                        )}


                        <Divider />
                        {!isActivity ? (
                            <div>
                                <a className="beaconReviewDetails-Link" onClick={this.onViewAllBeaconsOfOwner} >
                                    {i18n.t('account-owner')} <b>{accountDetail.ownerName || ''}</b> {i18n.t('owns')} <b>{DashboardState.totalBeaconsForUser}</b> {i18n.t('beacons-click-here-to-view-all-owned-beacons')}
                                </a>
                            </div>
                        ):(
                            <div>
                                <a className="beaconReviewDetails-Link" onClick={this.onViewAllActivitiesOfOwner} >
                                   {i18n.t('account-owner-has')} <b>{DashboardState.totalActivitiesForUser}</b> {i18n.t('activities-for-his-registered-beacons-click-here-to-view-all-activities')} 
                                </a>
                            </div>
                        )}
                        
                    </>
                }
            </Container>
            </>
        )
    }
}

function phoneTypeToText(id, otherType = "") {
    let phoneTypes = AppConfigState.phoneTypes;
    let item = phoneTypes.find((row) => row.value == id);
    if (!item) return "";
    if (item.value == "OTHR") {
      return item.text + " - " + otherType;
    }
    return item.text;
}