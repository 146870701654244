import React, { Component } from "react";
import i18n from "i18next";
import DashboardState from "../../state/dashboardState";
import { observer } from "mobx-react";
import { Segment, Header, Table, Grid, Input, Icon, Button, Dropdown, Divider, Pagination, Dimmer, Loader, Image } from "semantic-ui-react";
import "./dashboard.css";
import "./activities.css";
import RouteState from "../../state/routeState";
import VehicleState from "../../state/vehicleState";
import ActivityState from "../../state/activityState";
import AssociatedUserState from "../../state/associatedUserState";
import AppConfigState from "../../state/appConfigState";
import * as Utils from "../../utils/utils";
import "./beacons.css";
import { isBrowser, isMobile } from "react-device-detect";
import { toJS } from "mobx";

const paginationOptions = [
  { key: "25", text: "25", value: 25 },
  { key: "50", text: "50", value: 50 },
  { key: "100", text: "100", value: 100 },
  { key: "0", text: "All", value: 0 },
];

const searchFields = [
  {
    key: "Vehicle Type",
    text: "Vehicle Type",
    value: "vehicleType",
  },
  {
    key: "Vehicle Model",
    text: "Vehicle Model",
    value: "vehicleModel",
  },
];

@observer
export default class Vehicles extends Component {
  constructor(props) {
    super(props);
    this.dropdownRef = React.createRef();
  }

  state = {
    itemsPerPage: 25,
    currentPage: 1,
    isLoading: false,

    // for filter
    searchOption: [],
    searchCriteria: [],
    searchCriteriaObj: {},
    searchType: searchFields[0].value,
    searchTypeLabel: searchFields[0].text,
    searchString: "",
    displaySearch: false,
  };

  componentDidMount() {
    DashboardState.searchVehicles({});
  }

  onExpandSearch = () => {
    this.setState({ displaySearch: !this.state.displaySearch });
  };

  onSetPage = (e, data) => {
    console.log("log: onSetPage -> data.activePage", data.activePage);
    this.setState({
      currentPage: data.activePage,
    });
  };

  onChangeitemsPerPage = (e, { value }) => {
    this.setState({
      itemsPerPage: value,
      currentPage: 1,
    });
  };

  // for filter
  onSearchTypeChange = (e, { value }) => {
    let label = findLabelByValue(value, searchFields);
    console.log("log: onSearchTypeChange -> value, text", value, label);
    this.setState({
      searchType: value,
      searchTypeLabel: label,
    });
  };

  onAddCriteria = () => {
    let { searchCriteria, searchCriteriaObj, searchOption, searchType, searchString, searchTypeLabel } = this.state;
    let newCriteria = searchType + ":" + searchString;
    searchCriteriaObj[searchType] = searchString;
    let newCriteriaLabel = searchTypeLabel + ":" + searchString;
    //console.log('onAddCriteria', searchString, newCriteria, newCriteriaLabel)
    if (!searchCriteria.includes(newCriteria) && searchString !== "") {
      searchOption.push({ key: newCriteria, text: newCriteriaLabel, value: newCriteria });
      searchCriteria.push(newCriteria);
      this.setState({
        searchOption: searchOption,
        searchCriteria: searchCriteria,
        searchString: "",
        searchCriteriaObj: searchCriteriaObj,
      });
      this.setState({
        isLoading: true,
      });
      DashboardState.searchVehicles(searchCriteriaObj).then((result) => {
        this.setState({
          isLoading: false,
        });
      });
      this.dropdownRef.current.handleClick(new Event("click")); //strangly the dropdown won't update until it receive a click
    }
  };

  onSearchStringChange = (e, { value }) => {
    this.setState({
      searchString: value,
    });
  };

  onChange = (e, { searchQuery, value }) => {
    this.setState({
      searchCriteria: value,
    });
  };

  onClearAllSearchCriteria = () => {
    this.setState({
      searchOption: [],
      searchCriteria: [],
      searchString: "",
      searchCriteriaObj: {},
    });
    this.setState({
      isLoading: true,
    });
    DashboardState.searchVehicles({}).then((result) => {
      this.setState({
        isLoading: false,
      });
    });
  };

  onSetPageByBlur = (nbOfPages) => {
    console.log("log: onSetPageByBlur -> nbOfPages", nbOfPages);
    this.setState({
      currentPage: nbOfPages,
    });
  };
  keyPressed = (event, nbOfPages) => {
    if (event.key === "Enter") {
      this.onSetPageByBlur(nbOfPages);
    }
  };

  onPreviousPageClick = () => {
    let currentPage = this.state.currentPage;
    if (currentPage > 1) {
      this.setState({ inputPage: currentPage - 1 });
    }
  };

  onNextPageClick = (nbOfPages) => {
    let currentPage = parseInt(DashboardState.beaconsCurrentPage);
    if (currentPage < nbOfPages) {
      this.setState({ inputPage: currentPage + 1 });
    }
  };

  onInputPageChange = (e) => {
    let pattern = /^\d+$/;
    let isNumber = pattern.test(e.target.value);
    if (isNumber || isNumber == "") {
      this.setState({ inputPage: e.target.value });
    }
  };

  render() {
    let { itemsPerPage, currentPage, isLoading, searchCriteria, searchOption, searchString, displaySearch } = this.state;
    const { onAddVehicle } = this.props;
    let vehicles = DashboardState.vehicles;
    console.log("log: render -> vehicles", vehicles);
    let users = DashboardState.associatedUsers;
    let tempActivity = DashboardState.activities;

    // add activity and user to vehicle
    vehicles = vehicles.map((vehicleRow) => {
      let _activity = tempActivity.map((activityRow) => {
        if (vehicleRow._id == activityRow.vehicleId) {
          vehicleRow.relatedActivity = activityRow;

          let _user = users.map((userRow) => {
            if (userRow._id == activityRow.holderId) {
              vehicleRow.relatedUser = userRow;
            }
          });
        }
      });
      return vehicleRow;
    });

    itemsPerPage = itemsPerPage == "0" ? vehicles.length : itemsPerPage;
    let nbOfPages = Math.ceil(vehicles.length / itemsPerPage);
    let pagedData = vehicles.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    let searchIconClass = "caret down";
    let searchRow = null;

    if (displaySearch) {
      searchIconClass = "caret up";
      searchRow = (
        <Grid.Row>
          <Grid.Column width={2}>
            <Header as="h4">{i18n.t('find-a-vehicle')}:</Header>
          </Grid.Column>
          <Grid.Column width={3} className="mobile-padding">
            <Dropdown defaultValue={searchFields[0].value} fluid selection options={searchFields} onChange={this.onSearchTypeChange} />
          </Grid.Column>
          <Grid.Column width={4} className="mobile-padding">
            <Input
              action={{ content: "ADD", color: "orange", onClick: () => this.onAddCriteria() }}
              value={searchString}
              placeholder={i18n.t('add-search-criteria-0')}
              fluid
              onChange={this.onSearchStringChange}
            />
          </Grid.Column>
          <Grid.Column width={5} className="mobile-padding">
            <Dropdown
              className="dashboard-beacons-searchCriteria"
              fluid
              multiple
              selection
              ref={this.dropdownRef}
              options={searchOption}
              value={searchCriteria}
              onChange={this.onChange}
            />
          </Grid.Column>

          <Grid.Column width={2} className="mobile-padding">
            <Button className="button-mobile" onClick={this.onClearAllSearchCriteria}>
              Clear
            </Button>
          </Grid.Column>
        </Grid.Row>
      );
    }

    return (
      <div>
        <Grid stackable className="fadeIn fast activities-caption">
          <Grid.Row>
            <Grid.Column width={9} className="beacons-optionsRow">
              <div style={{ display: "inline-block" }} className="pagination-mobile">
                {i18n.t('total-records')}: {vehicles.length}
              </div>

              <div style={{ display: "inline-block" }} className="pagination-mobile">
                <Button
                  icon="left chevron"
                  size="tiny"
                  onClick={() => this.onPreviousPageClick()}
                  disabled={DashboardState.isLoading}
                  style={{ color: "red", backgroundColor: "transparent" }}
                />
                {i18n.t('page')}:{" "}
                <Input
                  className="beacon-page-number"
                  // style={{width:'60px', height:'30.8px'}}
                  name="currentPage"
                  min={1}
                  type="number"
                  size="small"
                  disabled={DashboardState.isLoading}
                  onChange={this.onInputPageChange}
                  value={currentPage}
                  onBlur={() => this.onSetPageByBlur(nbOfPages)}
                  onKeyPress={(e) => this.keyPressed(e, nbOfPages)}
                />{" "}
                {i18n.t('of')}{" " + nbOfPages + " "}
                <Button
                  icon="right chevron"
                  size="tiny"
                  onClick={() => this.onNextPageClick(nbOfPages)}
                  disabled={DashboardState.isLoading}
                  style={{ color: "red", backgroundColor: "transparent" }}
                />
              </div>

              <div style={{ display: "inline-block" }} className="pagination-mobile">
                {i18n.t('records-per-page')}:{" "}
                <Dropdown inline header="Adjust records per page" options={paginationOptions} defaultValue={paginationOptions[0].value} onChange={this.onChangeitemsPerPage} />
              </div>
            </Grid.Column>

            <Grid.Column width={7} textAlign="right">
              <Button onClick={onAddVehicle} primary size="small">
                {i18n.t('add-vehicle')}
                <Icon name="plus" className="beacons-search-icon" />
              </Button>
              <Button basic onClick={this.onExpandSearch} size="small">
                {i18n.t('search-and-filter')}
                <Icon color="red" name={searchIconClass} className="beacons-search-icon" />
              </Button>
            </Grid.Column>
          </Grid.Row>
          {searchRow}
          <Grid.Row className="dashboard-beacons-gridRow-last">
            <Grid.Column width={11} />
            <Grid.Column width={5} textAlign="right">
              {i18n.t('click-a-vehicle-to-edit')}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <ContentTable items={pagedData} isLoading={isLoading} />
      </div>
    );
  }
}

class ContentTable extends Component {
  state = {
    editRow: null,
  };

  handleSort = (clickedColumn) => {
    DashboardState.handleSortVehicle(clickedColumn);
  };

  render() {
    let { items, isLoading } = this.props;
    let { editRow } = this.state;

    const column = DashboardState.column;
    const direction = DashboardState.direction;

    return (
      <div>
        <Segment>
          <Dimmer active={isLoading} inverted>
            <Loader>Loading</Loader>
          </Dimmer>
          <div className="sidescroll">
            <Table celled striped selectable sortable className="beacon-mobile">
              <Table.Header className="contactList-tableHeader">
                <Table.Row>
                  {/* <Table.HeaderCell className="dashboard-beacon-tableHeader">Image</Table.HeaderCell> */}
                  <Table.HeaderCell className="dashboard-beacon-tableHeader" sorted={column === "vehicleType" ? direction : null} onClick={() => this.handleSort("vehicleType")}>
                   {i18n.t('vehicle-type')}
                  </Table.HeaderCell>
                  <Table.HeaderCell className="dashboard-beacon-tableHeader" sorted={column === "vehicleModel" ? direction : null} onClick={() => this.handleSort("vehicleModel")}>
                    {i18n.t('vehicle-model')}
                  </Table.HeaderCell>
                  <Table.HeaderCell className="dashboard-beacon-tableHeader" sorted={column === "vehicleColor" ? direction : null} onClick={() => this.handleSort("vehicleColor")}>
                   {i18n.t('vehicle-colour')}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className="dashboard-beacon-tableHeader"
                    sorted={column === "registrationNumber" ? direction : null}
                    onClick={() => this.handleSort("registrationNumber")}
                  >
                    {i18n.t('registration-number')}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className="dashboard-beacon-tableHeader"
                    sorted={column === "registrationCountry" ? direction : null}
                    onClick={() => this.handleSort("registrationCountry")}
                  >
                    {i18n.t('registration-country')}
                  </Table.HeaderCell>
                  <Table.HeaderCell className="dashboard-beacon-tableHeader" sorted={column === "lastEditDate" ? direction : null} onClick={() => this.handleSort("lastEditDate")}>
                    {i18n.t('last-edit-date')}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body style={{ cursor: "initial" }}>
                {/* {items.map(item => (
									<TableRow key={item._id} item={item} onClick={this.onRowClick} />
								))} */}

                {items.map((item) => {
                  let AddActivity = (
                    <a className="font-blue" onClick={() => this.onRowClick(item, 0)} style={{ cursor: "pointer" }}>
                      {item.relatedActivity ? "YES" : "+Add"}
                    </a>
                  );

                  let hexId = item.relatedActivity && item.relatedActivity.beaconId ? item.relatedActivity.beaconId.split("_")[1] : "";
                  let AddBeacon = (
                    <a className="font-blue" onClick={() => this.onRowClick(item, 2)} style={{ cursor: "pointer" }}>
                      {item.relatedActivity ? hexId : "+Add"}
                    </a>
                  );

                  let AddUser = (
                    <a className="font-blue" onClick={() => this.onRowClick(item, 1)} style={{ cursor: "pointer" }}>
                      {item.relatedUser ? item.relatedUser.firstName + " " + item.relatedUser.lastName : "+Add"}
                    </a>
                  );

                  let AddImage = (
                    <a className="font-blue" onClick={() => this.onRowClick(item, 3, VehicleState.setCurrentStep(1))} style={{ cursor: "pointer" }}>
                      {item.image1 ? <Image src={item.image1} size="small" /> : "+Add Image"}
                    </a>
                  );

                  let AddVehicleType = (
                    <a className="font-blue" onClick={() => this.onRowClick(item, 3, VehicleState.setCurrentStep(0))} style={{ cursor: "pointer" }}>
                      {item.vehicleType ? item.vehicleType : "+Add"}
                    </a>
                  );

                  let AddVehicleModel = (
                    <a className="font-blue" onClick={() => this.onRowClick(item, 3, VehicleState.setCurrentStep(0))} style={{ cursor: "pointer" }}>
                      {item.vehicleModel ? item.vehicleModel : "+Add"}
                    </a>
                  );

                  let relatedData = {};
                  relatedData.AddActivity = AddActivity;
                  relatedData.AddBeacon = AddBeacon;
                  relatedData.AddUser = AddUser;
                  relatedData.AddImage = AddImage;
                  relatedData.AddVehicleType = AddVehicleType;
                  relatedData.AddVehicleModel = AddVehicleModel;

                  return <TableRow key={item._id} item={item} relatedData={relatedData} onRowClick={() => this.onRowClick(item, 3, VehicleState.setCurrentStep(0))} />;
                })}
              </Table.Body>
            </Table>
          </div>
          <EmptyStatePlaceHolder items={items} />
        </Segment>
      </div>
    );
  }

  onRowClick = (vehicle, primaryTab) => {
    // get vehicle detail
    if (vehicle._id == "") {
      vehicle.vehicleGroup = "addNewVehicle";
    } else {
      vehicle.vehicleGroup = "selectExistingVehicle";
      vehicle.selectVehicle = vehicle._id;
    }
    console.log('========> vehicle: ', toJS(vehicle))
    VehicleState.setVehicleFields(vehicle);
    RouteState.setRoute("/registerVehicle");
  };
}

const TableRow = ({ item, relatedData, onRowClick }) => (
  <Table.Row>
    {isBrowser ? (
      <>
        <Table.Cell textAlign="left" className="dashboard-beacon-resultCell dashboard-beacon-resultCell-link" style={{ cursor: "pointer" }} onClick={onRowClick}>
          <strong>{item.vehicleType || ""}</strong>
        </Table.Cell>
        <Table.Cell textAlign="left" className="dashboard-beacon-resultCell">
          {item.vehicleModel || ""}
        </Table.Cell>
        <Table.Cell textAlign="left" className="dashboard-beacon-resultCell">
          {item.vehicleColor || ""}
        </Table.Cell>
        <Table.Cell textAlign="left" className="dashboard-beacon-resultCell">
          {item.registrationNumber || ""}
        </Table.Cell>
        <Table.Cell textAlign="left" className="dashboard-beacon-resultCell">
          {countryCodeToText(item.registrationCountry) || ""}
        </Table.Cell>
        <Table.Cell className="dashboard-beacon-resultCell">{Utils.dateFormatWithTime(item.lastEditDate) || ""}</Table.Cell>
      </>
    ) : (
      <>
        {item.vehicleType && (
          <Table.Cell textAlign="left" className="contactList-cell dashboard-beacon-resultCell dashboard-beacon-resultCell-link" style={{ cursor: "pointer" }} onClick={onRowClick}>
            <div className="contactList-cell-mobileHeader">Vehicle Type</div>
            <div className="contactList-cell-content">
              <strong>{item.vehicleType || ""}</strong>
            </div>
          </Table.Cell>
        )}

        {item.vehicleModel && (
          <Table.Cell textAlign="left" className="contactList-cell dashboard-beacon-resultCell">
            <div className="contactList-cell-mobileHeader">Vehicle Model</div>
            <div className="contactList-cell-content">{item.vehicleModel || ""}</div>
          </Table.Cell>
        )}

        {item.vehicleColor && (
          <Table.Cell textAlign="left" className="contactList-cell dashboard-beacon-resultCell">
            <div className="contactList-cell-mobileHeader">Vehicle Colour</div>
            <div className="contactList-cell-content">{item.vehicleColor || ""}</div>
          </Table.Cell>
        )}

        {item.registrationNumber && (
          <Table.Cell textAlign="left" className="contactList-cell dashboard-beacon-resultCell">
            <div className="contactList-cell-mobileHeader">Registration Number</div>
            <div className="contactList-cell-content">{item.registrationNumber || ""}</div>
          </Table.Cell>
        )}

        {item.registrationCountry && (
          <Table.Cell textAlign="left" className="contactList-cell dashboard-beacon-resultCell">
            <div className="contactList-cell-mobileHeader">Registration Country</div>
            <div className="contactList-cell-content">{countryCodeToText(item.registrationCountry) || ""}</div>
          </Table.Cell>
        )}

        {item.lastEditDate && (
          <Table.Cell className="contactList-cell dashboard-beacon-resultCell">
            <div className="contactList-cell-mobileHeader">Last Edit Date</div>
            <div className="contactList-cell-content">{Utils.dateFormatWithTime(item.lastEditDate) || ""}</div>
          </Table.Cell>
        )}
      </>
    )}
  </Table.Row>
);

const EmptyStatePlaceHolder = ({ items }) => {
  if (items && items.length > 0) {
    return null;
  }

  return (
    <Header as="h2" icon textAlign="center">
      <Icon name="file outline" circular />
      <Header.Content>{i18n.t('no-vehicle-found')}</Header.Content>
    </Header>
  );
};

function findLabelByValue(value, dataSet) {
  let option = dataSet.find((entry) => entry.value === value);
  return option.text;
}

function countryCodeToText(countryCode) {
  if (!countryCode) return;

  let countryInfo = AppConfigState.countryNamesWithCode.find((row) => row.key == countryCode);
  return countryInfo.text;
}
