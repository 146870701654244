import i18n from "i18next";
import React, { Component } from "react";
import { Container, Step, Divider, Icon } from "semantic-ui-react";
import { observer } from "mobx-react";
import ActivityState from "../../../state/activityState";
import ActivityDetailForm from "./activityDetailForm";
import UserDetailForm from "./userDetailForm";
import BeaconForm from "./beaconForm";
import VehicleState from "../../../state/vehicleState";
import VehicleStep from "../../registerVehicle/vehicle/vehicleSteps";
import EmergencyContactForm from "./emergencyContactForm";
import ActivityReview from "./activityReview";
import ActivityComplete from "./activityComplete";
import AssociatedUserState from "../../../state/associatedUserState";
import VehicleDetailForm from "./vehicleDetailForm";
import * as Utils from "../../../utils/utils.js";

@observer
export default class ActivitySteps extends Component {
  onSelectStep = (event, data) => {
    /*if(ActivityState.isEditActivity){
            ActivityState.setCurrentStep(data.stepnumber);
            ActivityState.setActivityDetailCurrentStep(0);
            AssociatedUserState.setCurrentStep(0);
            VehicleState.setCurrentStep(0);
        }*/
  };

  onVehicleClose = (event, data) => {
    ActivityState.setCurrentStep(2);
  };

  onVehicleSave = (event, data) => {
    ActivityState.setNextStep();
  };

  render() {
    let { activity, onClose, onAskToCancle } = this.props;
    let currentStep = ActivityState.currentStep;
    let vehicle = VehicleState.getVehicleFields();

    return (
      <Container className="registerBeacon-subContainer animated fadeIn fast">
        <Steps currentStep={currentStep} onSelectStep={this.onSelectStep} />
        <ActivityEditor currentStep={currentStep} activity={activity} vehicle={vehicle} onVehicleClose={this.onVehicleClose} onVehicleSave={this.onVehicleSave} onAskToCancle={onAskToCancle} />
      </Container>
    );
  }
}

const Steps = ({ currentStep, onSelectStep }) => {
  if (currentStep > 4) return "";
  else
    return (
      <div className="for-desktop noprint">
        <Step.Group widths={5}>
          <Step active={currentStep === 0} stepnumber={0}>
            <Step.Content>
              <Step.Title>{i18n.t("itinerary")}</Step.Title>
            </Step.Content>
            <StepComplete currentStep={currentStep} stepnumber={0} />
          </Step>

          <Step active={currentStep === 1} stepnumber={1}>
            <Step.Content>
              <Step.Title>{i18n.t("participants")}</Step.Title>
            </Step.Content>
            <StepComplete currentStep={currentStep} stepnumber={1} />
          </Step>

          <Step active={currentStep === 2} stepnumber={2}>
            <Step.Content>
              <Step.Title>{i18n.t("emergency-contacts")}</Step.Title>
            </Step.Content>
            <StepComplete currentStep={currentStep} stepnumber={2} />
          </Step>

          <Step active={currentStep === 3} stepnumber={3}>
            <Step.Content>
              <Step.Title>{i18n.t("vehicle")}</Step.Title>
            </Step.Content>
            <StepComplete currentStep={currentStep} stepnumber={3} />
          </Step>

          <Step active={currentStep === 4} stepnumber={4}>
            <Step.Content>
              <Step.Title>{i18n.t("activity-summary")}</Step.Title>
            </Step.Content>
            <StepComplete currentStep={currentStep} stepnumber={4} />
          </Step>
        </Step.Group>
        {/* <Divider /> */}
      </div>
    );
};

const StepComplete = ({ currentStep, stepnumber }) => {
  if (currentStep > stepnumber) {
    return <Icon name="check" color="green" className="registerBeacon-stepComplete" />;
  } else {
    return null;
  }
};

const ActivityEditor = ({ currentStep, activity, vehicle, onVehicleClose, onVehicleSave, onAskToCancle }) => {
  switch (currentStep) {
    case 0:
      Utils.sendGtagEvent("addActivity_display");
      return <ActivityDetailForm activity={activity} onAskToCancle={onAskToCancle} />;
    case 1:
      // return <UserDetailStep activity={activity} allowPreview={false} />
      Utils.sendGtagEvent("addActivity_step1Finish");
      return <UserDetailForm allowPreview={false} activity={activity} allowSelectUser={true} />;
    case 2:
      Utils.sendGtagEvent("addActivity_step2Finish");
      return <EmergencyContactForm activity={activity} />;
    case 3:
      Utils.sendGtagEvent("addActivity_step3Finish");
      return <VehicleDetailForm vehicle={vehicle} activity={activity} onClose={onVehicleClose} onSave={onVehicleSave} allowSelectVehicle={true} />;
    case 4:
      Utils.sendGtagEvent("addActivity_step4Finish");
      return <ActivityReview activity={activity} />;
    case 5:
      Utils.sendGtagEvent("addActivity_save");
      return <ActivityComplete activity={activity} />;
    default:
      break;
  }
};
