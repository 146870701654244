import i18n from "i18next";
import React, { Component, useEffect } from "react";
import * as Yup from "yup";
import { Form, Input, Button, Popup, Select, Header, Grid } from "semantic-ui-react";
import RegistrationState from "../../../state/registrationState";
import withSemanticUIFormik from "../../../utils/formHelper";
import RouteState from "../../../state/routeState";
import ImageUploader from "../../../components/imageUpload";
import UserState from "../../../state/userState";

const statusOption = [
  { key: "af", value: "af", text: "Working" },
  { key: "ax", value: "ax", text: "Aland Islands" },
  { key: "al", value: "al", text: "Albania" },
  { key: "dz", value: "dz", text: "Algeria" },
  { key: "as", value: "as", text: "American Samoa" },
];

const options = [{ key: "m", text: "Male", value: "male" }, { key: "f", text: "Female", value: "female" }, { key: "o", text: "Other", value: "other" }];

let storedValues = {};
const InnerForm = (props) => {
  const { values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, isValid } = props;

  let hexHasError = touched.hex && errors.hex && errors.hex.length > 0;
  let canEditByStatus =
    props.decodedBeacon.specialStatus == "ACTIVE" || props.decodedBeacon.specialStatus == undefined || props.decodedBeacon.specialStatus == "RELEASETOMAINTENANCE";

  if (UserState.notAllowToEditByRoleId.includes(UserState.currentUserProfile.roleId)) {
    canEditByStatus = false;
  }

  const saveAndClose = () => {
    if (!RegistrationState.isNewRegisterBeacon) {
      RegistrationState.setIsSaving(true);
      RegistrationState.updateBeaconFields(props.values);
      RegistrationState.updateBeacon().then(() => {
        RegistrationState.setIsBeaconSaved(true);
        props.onClose();
        RegistrationState.setIsSaving(false);
      });
    }
  };
  storedValues = values;
  const saveToStateBeforeHandleChange = function(e, data) {
    console.log("log: InnerForm -> saveToStateBeforeHandleChange -> name, value", data.name, data.value);
    RegistrationState.saveOnFieldChange(data.name, data.value);
    handleChange(e, data);
  };

  return (
    <Form className="beaconLocation-form animated fadeIn faster">
      <Header as="h2" icon textAlign="center">
        <Header.Content>PPlease supply additional information about your vehicle, if applicable</Header.Content>
      </Header>

      <div>
        <label>
          Additional Vehicle / Usage Information
          <Popup
            content="Additional information deemed appropriate or helpful to Search and Rescue personnel regarding general beacon usage."
            trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
          />
        </label>
        <Form.TextArea
          placeholder=""
          name="additionalComment"
          value={values.additionalComment}
          onChange={saveToStateBeforeHandleChange}
          onBlur={handleBlur}
          disabled={!canEditByStatus}
          className={canEditByStatus ? "editing-input" : ""}
        />
      </div>

      <ImageUploader name="picture1" disabled={!canEditByStatus} value={values.picture1} onChange={saveToStateBeforeHandleChange} />
      <ImageUploader name="picture2" disabled={!canEditByStatus} value={values.picture2} onChange={saveToStateBeforeHandleChange} />

      <Grid centered columns={3} stackable>
        <Grid.Column>
          <Button className="registerBeacon-submitButton" onClick={onBackClick}>
            Back
          </Button>
        </Grid.Column>
        <Grid.Column>
          <Button className="registerBeacon-submitButton" type="submit" primary onClick={handleSubmit}>
            Next
          </Button>
        </Grid.Column>
        {/* {
            RegistrationState.isNewRegisterBeacon ? ''
            :
            <Grid.Column>
              <Button className="registerBeacon-submitButton" primary onClick={saveAndClose} loading={RegistrationState.isSaving}>
                Save & Close
              </Button>
            </Grid.Column>
          } */}
      </Grid>
    </Form>
  );
};

function onBackClick() {
  RegistrationState.setVehiculePreviousStep();
}

const beaconLocationForm = withSemanticUIFormik({
  mapPropsToValues: ({ decodedBeacon }) => {
    if (!decodedBeacon) {
      decodedBeacon = {};
    }
    return {
      additionalComment: decodedBeacon.additionalComment || "",
      picture1: decodedBeacon.picture1 || "",
      picture2: decodedBeacon.picture2 || "",
    };
  },

  validationSchema: Yup.object().shape({}),

  handleSubmit: (values, { setSubmitting, isValid }) => {
    console.log("log: values", values, isValid);

    RegistrationState.updateBeaconFields(values);
    RegistrationState.setVehiculeNextStep();
  },
})(InnerForm);

export default beaconLocationForm;
