import i18n from "i18next";
import React, { Component } from "react";
import * as Yup from "yup";
import { Form, Input, Button, Popup, Select, Header, Message, Radio, Icon, Label, Transition } from "semantic-ui-react";
import AssociatedUserState from "../../../state/associatedUserState";
import withSemanticUIFormik from "../../../utils/formHelper";
import ActivityState from "../../../state/activityState";
import AppConfigState from "../../../state/appConfigState";
import { countryCode } from "../../../utils/beaconDecode/countryCode";
import RouteState from "../../../state/routeState";
import { FormErrorDisplay } from "../../../components/formErrorDisplay";

const countryOptions = countryCode.map((country) => {
  return {
    text: country.code + " - " + country.country,
    value: country.code,
    key: country.code,
  };
});

const titleOptions = [
  { key: "mr", text: "Mr", value: "Mr" },
  { key: "ms", text: "Ms", value: "Ms" },
  { key: "mrs", text: "Mrs", value: "Mrs" },
  { key: "other", text: "Other", value: "Other" },
];

class InnerForm extends Component {
  state = {
    contact: 1,
    alternateContact: 1,
  };

  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    } else {
      ActivityState.setPreviousStep();
    }
  };

  onSave = () => {
    AssociatedUserState.updateUserFields(this.props.values);

    ActivityState.setNextStep();
  };

  // Emergency Contact Phone Number
  addContactNumber = () => {
    // this.setState(prevState => ({
    // 	contact: prevState.contact + 1
    // }))

    let totalPhone = document.getElementsByClassName("primaryPhone").length;
    this.setState({
      contact: totalPhone + 1,
    });
  };
  removeContactNumber = (fieldName, phoneType) => {
    this.props.values[fieldName] = "";
    this.props.values[phoneType] = "";
    this.setState((prevState) => ({
      contact: prevState.contact - 1,
    }));
  };

  // Alternate Contact Phone Number
  addAlternateNumber = () => {
    let totalPhone = document.getElementsByClassName("alternatePhone").length;
    this.setState({
      alternateContact: totalPhone + 1,
    });
  };
  removeAlternateNumber = (fieldName, phoneType) => {
    this.props.values[fieldName] = "";
    this.props.values[phoneType] = "";
    this.setState((prevState) => ({
      alternateContact: prevState.alternateContact - 1,
    }));
  };

  saveAndClose = () => {
    if (ActivityState.isEditActivity) {
      ActivityState.setIsSaving(true);
      AssociatedUserState.updateUserFields(this.props.values);
      ActivityState.saveActivity().then(() => {
        ActivityState.setIsSaving(false);
        RouteState.history.goBack();
      });
    }
  };

  render() {
    const { contact, alternateContact } = this.state;
    const { values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, isValid, onClose } = this.props;

    let primaryContactNameError = touched.primaryContactName && errors.primaryContactName && errors.primaryContactName.length > 0;
    let hasContactPhoneError = touched.primaryPhone1Num && errors.primaryPhone1Num && errors.primaryPhone1Num.length > 0;
    let hasContactPhoneTypeError = touched.primaryPhone1Type && errors.primaryPhone1Type && errors.primaryPhone1Type.length > 0;
    let primaryPhone2NumError = touched.primaryPhone2Num && errors.primaryPhone2Num && errors.primaryPhone2Num.length > 0;
    let primaryPhone3NumError = touched.primaryPhone3Num && errors.primaryPhone3Num && errors.primaryPhone3Num.length > 0;
    let primaryPhone4NumError = touched.primaryPhone4Num && errors.primaryPhone4Num && errors.primaryPhone4Num.length > 0;

    let alternatePhone1NumError = touched.alternatePhone1Num && errors.alternatePhone1Num && errors.alternatePhone1Num.length > 0;
    let alternatePhone1TypeError = touched.alternatePhone1Type && errors.alternatePhone1Type && errors.alternatePhone1Type.length > 0;
    let alternatePhone2NumError = touched.alternatePhone2Num && errors.alternatePhone2Num && errors.alternatePhone2Num.length > 0;
    let alternatePhone3NumError = touched.alternatePhone3Num && errors.alternatePhone3Num && errors.alternatePhone3Num.length > 0;
    let alternatePhone4NumError = touched.alternatePhone4Num && errors.alternatePhone4Num && errors.alternatePhone4Num.length > 0;

    const phoneTypeOption = AppConfigState.phoneTypes;

    var AnotherContactButton;
    if (contact < 4) {
      AnotherContactButton = (
        <Form.Group widths="equal">
          <a
            className="registerUserForm-addContactsButton"
            onClick={
              ActivityState.isEditable()
                ? this.addContactNumber
                : (e) => {
                    e.preventDefault();
                  }
            }
          >
            <Icon name="plus circle" color="red" size="large" />
            {"   "}
            {i18n.t('add-another-emergency-contact-phone-number')}
          </a>
        </Form.Group>
      );
    }
    var alternateContactButton;
    if (alternateContact < 4) {
      alternateContactButton = (
        <Form.Group widths="equal">
          <a
            className="registerUserForm-addContactsButton"
            onClick={
              ActivityState.isEditable()
                ? this.addAlternateNumber
                : (e) => {
                    e.preventDefault();
                  }
            }
          >
            <Icon name="plus circle" color="red" size="large" />
            {"   "}
            {i18n.t('add-another-alternate-contact-phone-number')}
          </a>
        </Form.Group>
      );
    }

    // scroll to error
    if (isSubmitting && Object.keys(errors).length > 0) {
      var errorBox = document.getElementById("scrollToError");
      errorBox.scrollIntoView();
    }

    return (
      <Form id="mainForm" className="activityDetail-form animated fadeIn faster">
        <Header as="h2" icon textAlign="center">
          <Header.Content>{i18n.t('add-emergency-contact')}</Header.Content>
        </Header>
        <div id="scrollToError" />
        <FormErrorDisplay errors={errors} touched={touched} status={status} />
        <div className="required-field-notation">* {i18n.t('required-fields-0')}</div>
        <Header as="h4">{i18n.t('primary-emergency-contact')}</Header>
        <Form.Group>
          <Form.Field width={16}>
            <label>
              {i18n.t('24-hour-emergency-contact-name')} <span style={{ color: "red" }}>*</span>
              <Popup trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}>
               {i18n.t('name-of-person-to-contact')},{" "}
                <b>
                  <u>{i18n.t('other-than-user')}</u>
                </b>
                , {i18n.t('in-event-of-detection-of-beacon-activation')}
              </Popup>
            </label>
            <Form.Input
            className="editing-input"
              fluid
              placeholder=""
              name="primaryContactName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.primaryContactName}
              error={primaryContactNameError}
              disabled={!ActivityState.isEditable()}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Input
          className="editing-input"
            fluid
            label="Emergency Contact Address Line 1"
            placeholder=""
            name="primaryContactAddressLine1"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.primaryContactAddressLine1}
            disabled={!ActivityState.isEditable()}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Input
          className="editing-input"
            fluid
            label={i18n.t('emergency-contact-address-line-2')}
            placeholder=""
            name="primaryContactAddressLine2"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.primaryContactAddressLine2}
            disabled={!ActivityState.isEditable()}
          />
        </Form.Group>

        <Form.Group>
          {/* <Form.Input fluid label="" placeholder="#" disabled width={2} value={1} onChange={handleChange} onBlur={handleBlur} className="contact-number" disabled={!ActivityState.isEditable()} /> */}

          <Form.Field width={8}>
            <label>
              Emergency Contact Phone Number <span style={{ color: "red" }}>*</span>
              <Popup
                content={i18n.t('between-one-and-four-telephone-numbers-may-be-entered-for-primary-emergency-contact-an-appropriate-telephone-number-type-must-be-selected')}
                trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
              />
            </label>
            <Form.Input
            className="editing-input"
              fluid
              placeholder=""
              name="primaryPhone1Num"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.primaryPhone1Num}
              error={hasContactPhoneError}
              disabled={!ActivityState.isEditable()}
              className="primaryPhone"
            />
          </Form.Field>

          <Form.Field width={8}>
            <label>
              Emergency Contact Phone Number Type <span style={{ color: "red" }}>*</span>
            </label>
            <Form.Group widths="equal" className="phone-type-other">
              <Form.Select
                fluid
                options={phoneTypeOption}
                placeholder={i18n.t('select')}
                name="primaryPhone1Type"
                className="editing-select"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.primaryPhone1Type}
                error={hasContactPhoneTypeError}
                disabled={!ActivityState.isEditable()}
              />
              {values.primaryPhone1Type == "OTHR" ? (
                <Form.Input
                className="editing-input"
                  fluid
                  placeholder=""
                  name="primaryPhone1TypeOther"
                  value={values.primaryPhone1TypeOther}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!ActivityState.isEditable()}
                />
              ) : (
                (values.primaryPhone1TypeOther = "")
              )}
            </Form.Group>
          </Form.Field>
        </Form.Group>

        <AdditionnalContacts
          handleChange={handleChange}
          handleBlur={handleBlur}
          hasContactPhoneError=""
          values={values}
          phoneTypeOption={phoneTypeOption}
          number={2}
          errors={primaryPhone2NumError}
          contact={contact}
          removeContactNumber={() => this.removeContactNumber("primaryPhone2Num", "primaryPhone2Type")}
          namePrefix="primaryPhone"
          labelPrefix="Emergency Contact Phone Number"
        />
        <AdditionnalContacts
          handleChange={handleChange}
          handleBlur={handleBlur}
          hasContactPhoneError=""
          values={values}
          phoneTypeOption={phoneTypeOption}
          number={3}
          errors={primaryPhone3NumError}
          contact={contact}
          removeContactNumber={() => this.removeContactNumber("primaryPhone3Num", "primaryPhone3Type")}
          namePrefix="primaryPhone"
          labelPrefix="Emergency Contact Phone Number"
        />
        <AdditionnalContacts
          handleChange={handleChange}
          handleBlur={handleBlur}
          hasContactPhoneError=""
          values={values}
          phoneTypeOption={phoneTypeOption}
          number={4}
          errors={primaryPhone4NumError}
          contact={contact}
          removeContactNumber={() => this.removeContactNumber("primaryPhone4Num", "primaryPhone4Type")}
          namePrefix="primaryPhone"
          labelPrefix="Emergency Contact Phone Number"
        />

        {AnotherContactButton}

				<Header as="h4">{i18n.t('alternate-emergency-contact')}</Header>
        {/* Alternate contact */}
        <Form.Group>
          <Form.Field width={16}>
            <label>
              {i18n.t('alternate-24-hour-emergency-contact-name')}
              <Popup trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}>
                {i18n.t('name-of-alternate-person-to-contact-in-event-of-detection-of-beacon-activation-if-primary-contact-unavailable')}
              </Popup>
            </label>
            <Form.Input
            className="editing-input"
              fluid
              placeholder=""
              name="alternateContactName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.alternateContactName}
              disabled={!ActivityState.isEditable()}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Input
          className="editing-input"
            fluid
            label={i18n.t('alternate-contact-address-line-1')}
            placeholder=""
            name="alternateContactAddressLine1"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.alternateContactAddressLine1}
            disabled={!ActivityState.isEditable()}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Input
          className="editing-input"
            fluid
            label={i18n.t('alternate-contact-address-line-2')}
            placeholder=""
            name="alternateContactAddressLine2"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.alternateContactAddressLine2}
            disabled={!ActivityState.isEditable()}
          />
        </Form.Group>

        <Form.Group widths="equal">
          {/* <Form.Input fluid label="" placeholder="#" disabled width={2} value={1} onChange={handleChange} onBlur={handleBlur} className="contact-number" disabled={!ActivityState.isEditable()} /> */}

          <Form.Field width={8}>
            <label>
              Alternate Contact Phone Number
              {values.alternateContactName && <span style={{ color: "red" }}>*</span>}
            </label>
            <Form.Input
           
              fluid
              placeholder=""
              name="alternatePhone1Num"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.alternatePhone1Num}
              disabled={!ActivityState.isEditable()}
              className="alternatePhone editing-input"
              error={alternatePhone1NumError}
            />
          </Form.Field>

          <Form.Field width={8}>
            <label>
              Alternate Contact Phone Number Type
              {values.alternateContactName && <span style={{ color: "red" }}>*</span>}
            </label>
            <Form.Group widths="equal" className="phone-type-other">
              <Form.Select
                fluid
                options={phoneTypeOption}
                placeholder={i18n.t('select')}
                name="alternatePhone1Type"
                className="editing-select"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.alternatePhone1Type}
                disabled={!ActivityState.isEditable()}
                error={alternatePhone1TypeError}
              />
              {values.alternatePhone1Type == "OTHR" ? (
                <Form.Input
                  className="editing-input"
                  fluid
                  placeholder=""
                  name="alternatePhone1TypeOther"
                  value={values.alternatePhone1TypeOther}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!ActivityState.isEditable()}
                />
              ) : (
                (values.alternatePhone1TypeOther = "")
              )}
            </Form.Group>
          </Form.Field>
        </Form.Group>

        <AdditionnalContacts
          handleChange={handleChange}
          handleBlur={handleBlur}
          hasContactPhoneError=""
          values={values}
          phoneTypeOption={phoneTypeOption}
          number={2}
          errors={alternatePhone2NumError}
          contact={alternateContact}
          removeContactNumber={() => this.removeAlternateNumber("alternatePhone2Num", "alternatePhone2Type")}
          namePrefix="alternatePhone"
          labelPrefix="Alternate Contact Phone Number"
        />
        <AdditionnalContacts
          handleChange={handleChange}
          handleBlur={handleBlur}
          hasContactPhoneError=""
          values={values}
          phoneTypeOption={phoneTypeOption}
          number={3}
          errors={alternatePhone3NumError}
          contact={alternateContact}
          removeContactNumber={() => this.removeAlternateNumber("alternatePhone3Num", "alternatePhone3Type")}
          namePrefix="alternatePhone"
          labelPrefix="Alternate Contact Phone Number"
        />
        <AdditionnalContacts
          handleChange={handleChange}
          handleBlur={handleBlur}
          hasContactPhoneError=""
          values={values}
          phoneTypeOption={phoneTypeOption}
          number={4}
          errors={alternatePhone4NumError}
          contact={alternateContact}
          removeContactNumber={() => this.removeAlternateNumber("alternatePhone4Num", "alternatePhone4Type")}
          namePrefix="alternatePhone"
          labelPrefix="Alternate Contact Phone Number"
        />

        {alternateContactButton}

        <div className="activityDetail-submitContainer">
          <Button className="registerBeacon-submitButton" onClick={this.onClose}>
            {i18n.t('back')}
          </Button>
          <Button className="registerBeacon-submitButton" type="submit" primary onClick={handleSubmit}>
            {i18n.t('next')}
          </Button>
          {/* {
						ActivityState.isEditActivity ?
						<Button className="activityDetail-submitButton" primary onClick={this.saveAndClose} loading={ActivityState.isSaving} >
						Save & Close
						</Button>
						: ''
					} */}
        </div>
      </Form>
    );
  }
}

const AdditionnalContacts = ({ handleChange, handleBlur, values, phoneTypeOption, number, contact, removeContactNumber, errors, namePrefix, labelPrefix }) => {
  // if (contact < number) {
  //   return null;
  // }

  if (!values[namePrefix + number + "Num"] && contact < number) {
    return null;
  }

  // let hasError = errors["contactPhone" + number];

  return (
    <Form.Group className="additional-phone">
      {/* <Form.Input fluid name="contactNumber" label="" placeholder="#" disabled width={2} value={number} onChange={handleChange} onBlur={handleBlur} className="contact-number" /> */}

      <Form.Field width={8}>
        <label>
          {/* Beacon Owner Telephone Number */}
          {labelPrefix}
          {/* <Popup
			  content="Between one and four telephone numbers may be entered. An appropriate telephone number type must be selected."
			  trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
			/> */}
        </label>
        <Form.Input
        className="editing-input"
          fluid
          name={namePrefix + number + "Num"}
          placeholder={i18n.t('phone-number')}
          error={errors}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values[namePrefix + number + "Num"]}
          disabled={!ActivityState.isEditable()}
          className={namePrefix}
        />
      </Form.Field>

      <Form.Field width={8}>
        <label>{labelPrefix + " " + " Type"}</label>
        <Form.Group widths="equal" className="phone-type-other">
          <Form.Select
            fluid
            name={namePrefix + number + "Type"}
            // label={labelPrefix + ' ' + " Type"}
            options={phoneTypeOption}
            placeholder={i18n.t('phone-type')}
            className="editing-select"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values[namePrefix + number + "Type"]}
            action="Search"
            disabled={!ActivityState.isEditable()}
          />
          {values[namePrefix + number + "Type"] == "OTHR" ? (
            <Form.Input
            className="editing-input"
              fluid
              placeholder=""
              name={namePrefix + number + "TypeOther"}
              value={values[namePrefix + number + "TypeOther"]}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!ActivityState.isEditable()}
            />
          ) : (
            (values[namePrefix + number + "TypeOther"] = "")
          )}
        </Form.Group>
      </Form.Field>

      {/* <Button icon circular className="registerUser-additionnalContactRemove" size="tiny" onClick={ActivityState.isEditable() ? removeContactNumber : (e) => {e.preventDefault()}}>
		  {" "}
		  <Icon name="minus circle" color="red" size="large" />
		</Button> */}
      <Popup
        content={i18n.t('delete-this-number')}
        trigger={
          <Button
            icon
            circular
            className="registerUser-additionnalContactRemove"
            size="tiny"
            onClick={
              ActivityState.isEditable()
                ? removeContactNumber
                : (e) => {
                    e.preventDefault();
                  }
            }
          >
            {" "}
            <Icon name="trash circle" color="red" size="large" />
          </Button>
        }
      />
    </Form.Group>
  );
};

//const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const EmergencyContactForm = withSemanticUIFormik({
  mapPropsToValues: ({ activity, allowPreview }) => {
    if (activity) {
      // console.log('log: user - mapPropsToValues', user);
      return {
        allowPreview: allowPreview ? allowPreview : true,

        primaryContactName: activity.primaryContactName || "",
        primaryContactAddressLine1: activity.primaryContactAddressLine1 || "",
        primaryContactAddressLine2: activity.primaryContactAddressLine2 || "",
        primaryPhone1Num: activity.primaryPhone1Num || "",
        primaryPhone1Type: activity.primaryPhone1Type || "",
        primaryPhone2Num: activity.primaryPhone2Num || "",
        primaryPhone2Type: activity.primaryPhone2Type || "",
        primaryPhone3Num: activity.primaryPhone3Num || "",
        primaryPhone3Type: activity.primaryPhone3Type || "",
        primaryPhone4Num: activity.primaryPhone4Num || "",
        primaryPhone4Type: activity.primaryPhone4Type || "",

        alternateContactName: activity.alternateContactName || "",
        alternateContactAddressLine1: activity.alternateContactAddressLine1 || "",
        alternateContactAddressLine2: activity.alternateContactAddressLine2 || "",
        alternatePhone1Num: activity.alternatePhone1Num || "",
        alternatePhone1Type: activity.alternatePhone1Type || "",
        alternatePhone2Num: activity.alternatePhone2Num || "",
        alternatePhone2Type: activity.alternatePhone2Type || "",
        alternatePhone3Num: activity.alternatePhone3Num || "",
        alternatePhone3Type: activity.alternatePhone3Type || "",
        alternatePhone4Num: activity.alternatePhone4Num || "",
        alternatePhone4Type: activity.alternatePhone4Type || "",

        primaryPhone1TypeOther: activity.primaryPhone1TypeOther || "",
        primaryPhone2TypeOther: activity.primaryPhone2TypeOther || "",
        primaryPhone3TypeOther: activity.primaryPhone3TypeOther || "",
        primaryPhone4TypeOther: activity.primaryPhone4TypeOther || "",
        alternatePhone1TypeOther: activity.alternatePhone1TypeOther || "",
        alternatePhone2TypeOther: activity.alternatePhone2TypeOther || "",
        alternatePhone3TypeOther: activity.alternatePhone3TypeOther || "",
        alternatePhone4TypeOther: activity.alternatePhone4TypeOther || "",
      };
    } else {
      return {
        allowPreview: allowPreview ? allowPreview : true,
      };
    }
  },

  validationSchema: Yup.object().shape({
    primaryContactName: Yup.string().required(i18n.t('emergency-contact-name-is-required')),
    primaryPhone1Num: Yup.string().required(i18n.t('emergency-contact-phone-number-is-required')),
    primaryPhone1Type: Yup.string().required(i18n.t('emergency-contact-phone-type-is-required')),
    primaryPhone2Num: Yup.string().notRequired(),
    primaryPhone3Num: Yup.string().notRequired(),
    primaryPhone4Num: Yup.string().notRequired(),

    // if use alternate contact
    alternateContactName: Yup.string(),
    alternatePhone1Num: Yup.string().when("alternateContactName", {
      is: (value) => value,
      then: Yup.string().required(i18n.t('alternate-contact-phone-number-is-required')),
      otherwise: Yup.string().notRequired(),
    }),
    alternatePhone1Type: Yup.string().when("alternateContactName", {
      is: (value) => value,
      then: Yup.string().required(i18n.t('alternate-contact-phone-number-type-is-required')),
    }),
    alternatePhone2Num: Yup.string().notRequired(),
    alternatePhone3Num: Yup.string().notRequired(),
    alternatePhone4Num: Yup.string().notRequired(),
  }),

  handleSubmit: (values, { setSubmitting, props }) => {
    console.log("log: values===================@form", values, props);

     ActivityState.updateActivityFields(values);
    ActivityState.setNextStep();
  },
})(InnerForm);

export default EmergencyContactForm;
