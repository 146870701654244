export const TAC_DB_URL = "https://b7ae5786-8abe-4fba-bf17-15cbf21db7ef-bluemix:e1924aec6a94741172d24863587c9525e77bd433b21331d9b19e90c08257d65b@b7ae5786-8abe-4fba-bf17-15cbf21db7ef-bluemix.cloudantnosqldb.appdomain.cloud/tac_dev/";
export const FIRST_GEN_BINARY_LENGTH = 60;
export const SECOND_GEN_BINARY_LENGTH = 92;
export const PROTOCOL_BITS = { start: 0, end: 1 };
export const COUNTRY_CODE_BITS = { start: 1, end: 11 };
export const LP_VALUE = 0; //LP = LOCATION PROTOCOL
export const UP_VALUE = 1; //UP = USER PROTOCOL
export const LP_BITS = { start: 11, end: 15 };

export const LP_NATIONAL_LOCATIONS = ["1000", "1010", "1011", "1111"];
export const LP_NATIONAL_LOCATIONS_VALUES = {
  "1000": "ELT",
  "1010": "EPIRB",
  "1011": "PLB",
  "1111": "TEST",
};

export const LP_NATIONAL_LOCATIONS_DEFAULT_DATA = "011111110000001111111100000";
export const LP_NATIONAL_LOCATIONS_IDDATA_BITS = { start: 15, end: 33 };
export const LP_NATIONAL_LOCATIONS_DEFAULTDATA_BITS = { start: 33, end: 60 };

export const LP_MMSI = "0010";
export const LP_MMSI_VALUE = "MMSI";
export const LP_MMSI_FIXED = "1100";
export const LP_MMSI_FIXED_VALUE = "MMSI fixed";
export const LP_MMSI_DEFAULT_DATA = "011111111101111111111";

export const LP_MMSI_ID_BITS = { start: 15, end: 35 };
export const LP_BEACON_NUMBER_BITS = { start: 35, end: 39 };
export const LP_BEACON_DEFAULT_DATA_BITS = { start: 39, end: 60 };

export const LP_AIRCRAFT_24BIT = "0011";
export const LP_AIRCRAFT_24BIT_VALUE = "ELT - Aircraft 24 bit address";
export const LP_AIRCRAFT_24BIT_ADDRESS_BITS = { start: 15, end: 39 };
export const LP_AIRCRAFT_24BIT_DEFAULT_DATA_BITS = { start: 39, end: 60 };
export const LP_AIRCRAFT_24BIT_DEFAULT_DATA = "011111111101111111111";

export const LP_ELT_AIRCRAFT = "0101";
export const LP_ELT_AIRCRAFT_value = "ELT - Aircraft operator";
export const LP_ELT_AIRCRAFT_OPERATOR_BITS = { start: 15, end: 30 };
export const LP_ELT_AIRCRAFT_SN_BITS = { start: 30, end: 39 };
export const LP_ELT_AIRCRAFT_DEFAULT_DATA_BITS = { start: 39, end: 60 };

export const LP_SERIAL_IDENTIFICATION = ["0100", "0110", "0111"];
export const LP_SERIAL_IDENTIFICATION_VALUES = {
  "0100": "ELT-Serial",
  "0110": "EPIRB-Serial",
  "0111": "PLB-Serial",
};

export const LP_SERIAL_IDENTIFICATION_TYPE_APPROVAL_BITS = { start: 15, end: 25 };
export const LP_SERIAL_IDENTIFICATION_SN_BITS = { start: 25, end: 39 };
export const LP_SERIAL_IDENTIFICATION_DEFAULT_DATA_BITS = { start: 39, end: 60 };

export const LP_RLS = "1101";
export const LP_RLS_VALUE = "RLS Location Protocol";
export const LP_RLS_ALTERNATE_BEACONTYPE_VALUE = "1111";
export const LP_RLS_BEACONTYPE_BITS = { start: 15, end: 17 };
export const LP_RLS_BEACONTYPE_CONDITION_BITS = { start: 17, end: 21 };
export const LP_RLS_LAST_SIX_MMSI_DIGIT = { start: 21, end: 41 };
export const LP_RLS_BEACONTYPE_VALUES = {
  "00": "ELT",
  "01": "EPIRB",
  "10": "PLB",
  "11": "Location test protocol",
};

export const LP_RLS_BEACONTYPE_ALTERNATE_VALUES = {
  "00": "First EPIRB",
  "01": "Second EPIRB",
  "10": "PLB",
  "11": "Location test protocol",
};

export const LP_RLS_DEFAULT_DATA = "0111111110111111111";

export const LP_RLS_TAC_TRL_NO_BITS = { start: 17, end: 27 };
export const LP_RLS__SN_BITS = { start: 27, end: 41 };
export const LP_RLS_DEFAULT_DATA_BITS = { start: 41, end: 60 };

export const LP_ELT_DT = "1001";
export const LP_ELT_DT_VALUE = "ELT(DT) Location Protocol";
export const LP_ELT_DT_BEACON_TYPE_BITS = { start: 15, end: 17 };
export const LP_ELT_DT_BEACON_TYPES = {
  "00": "ELT - Aircraft 24 bit address",
  "01": "ELT - Aircraft operators designator and serial number",
  "10": "ELT - TAC with serial number",
  "11": "ELT(DT) - location Test Protocol",
};

export const LP_ELT_DT_AIRCRAFT_24BIT_VALUE = "00";
export const LP_ELT_DT_AIRCRAFT_OPERATOR_VALUE = "01";
export const LP_ELT_DT_TAC_WITH_SN_VALUE = "10";
export const LP_ELT_DT_ELT_TEST_VALUE = "11";
export const LP_ELT_DT_AIRCRAFT_24BITS_ID_BITS = { start: 17, end: 41 };
export const LP_ELT_DT_AIRCRAFT_OPERATOR_24_BIT_ID_BITS = { start: 17, end: 32 };
export const LP_ELT_DT_AIRCRAFT_OPERATOR_SN = { start: 32, end: 41 };
export const LP_ELT_DT_TAC_SN_TYPE_APPROVAL_NB_BITS = { start: 17, end: 27 };
export const LP_ELT_DT_TAC_SN_NB_BITS = { start: 27, end: 41 };
export const LP_ELT_DT_TEST_PROTOCOL_24BIT_ID = { start: 17, end: 41 };
export const LP_ELT_DT_DEFAULT_DATA_BITS = { start: 41, end: 60 };

export const UP_USER_PROTOCOL_BITS = { start: 11, end: 14 };
export const UP_SERIAL_USER_PROTOCOL = "011";
export const UP_MMSI_OR_RADIO_CALL_SIGN = "010";
export const UP_RADIO_CALL_SIGN = "110";
export const UP_AVIATION_USER_AIRCRAFT = "001";
export const UP_ORBITOGRAPHY_PROTOCOL = "000";

export const UP_BEACON_TYPE_BITS = { start: 14, end: 17 };
export const UP_BEACON_TYPE = {
  "000": "ELT with serial number",
  "010": "EPIRB - float free with serial number",
  "100": "EPIRB - Non float free with serial number",
  "110": "PLB - Personal locator beacon with serial number",
};

export const UP_BEACON_TYPE_AIRCRAFT_ELT_24BITS = "ELT with 24-bit aircraft address";
export const UP_BEACON_ELT_AIRCRAFT_WITH_OPERATOR = "ELT with 24-bit aircraft address";
export const UP_FLAG_VALUE_NATIONAL_USER = "0";
export const UP_FLAG_VALUE_TA_CERT = "1";
export const UP_BEACON_TYPE_ELT_24bit_AIRCRAFT = "011";
export const UP_BEACON_TYPE_ELT_WITH_OPERATOR = "001";
export const UP_FLAG_FOR_TYPE_APPROVAL_BITS = { start: 17, end: 18 };
export const UP_FLAG_VALUE = { NationnalUser: 0, Certificate: 1 };
export const UP_SN_BITS = { start: 18, end: 38 };
export const UP_NATIONAL_USER_BITS = { start: 38, end: 48 };
export const UP_TYPE_APPROVAL_BITS = { start: 48, end: 58 };
export const UP_AUX_DEVICE_BITS = { start: 58, end: 60 };
export const UP_AUX_DEVICE_VALUE = {
  "00": "No auxiliary radio-locating device",
  "01": "121.5 MHz Auxiliary Radio-locating Device",
  "10": "Maritime 9ghz Search and rescue Radar Transponder",
  "11": "other auxiliary radio-locationg device(s)",
};

export const UP_AIRCRAFT_24BITS_ADDRESS_BITS = { start: 18, end: 42 };
export const UP_AIRCRAFT_ELT_NUMBER_BITS = { start: 43, end: 48 };
export const UP_ELT_AIRCRAFT_OPERATOR_DESIGNATOR_BITS = { start: 18, end: 36 };
export const UP_ELT_SN_DESIGNATED_BY_OPERATOR_BITS = { start: 36, end: 48 };

export const UP_MMSI_OR_RADIO_CALL_SIGN_BEACON_TYPE = "EPIRB - Maritime User Protocol MMSI";
export const UP_MMSI_OR_RADIO_CALL_SIGN_BEACON_TYPE_BITS = { start: 14, end: 50 };
export const UP_MMSI_OR_RADIO_CALL_SIGN_BEACON_NUMBER = { start: 50, end: 56 };
export const UP_MMSI_OR_RADIO_CALL_SIGN_SPARE = { start: 56, end: 58 };

export const UP_RADIO_CALL_BEACON_TYPE = "EPIRB - Radio Call Sign";
export const UP_RADIO_CALL_FIRST_CHARACTERS_BITS = { start: 14, end: 38 };
export const UP_RADIO_CALL_LAST_CHARACTERS_BITS = { start: 38, end: 50 };
export const UP_RADIO_CALL_BEACON_NUMBER_BITS = { start: 50, end: 56 };

export const UP_AVIATION_USER_AIRCRAFT_VALUE = "ELT Aviation User Aircraft Registration Marking";
export const UP_AVIATION_USER_AIRCRAFT_MARKING_BITS = { start: 14, end: 56 };
export const UP_AVIATION_USER_AIRCRAFT_BEACON_NUMBER_BITS = { start: 38, end: 50 };

export const GEN2_FIXED_BINARY_101_VALUE = "101";
export const GEN2_FIXED_BINARY_101_BITS = { start: 11, end: 14 };
export const GEN2_TAC_NO_BITS = { start: 14, end: 30 };
export const GEN2_BEACON_SN_BITS = { start: 30, end: 44 };
export const GEN2_TEST_PROTOCOL_FLAG = { start: 44, end: 45 };
export const GEN2_VESSEL_ID_TYPE_VALUES = {
  "011": "011 - Aircraft registration marking",
  "100": "100 - Aviation 24bit address",
  "101": "101 - Aircraft operator and serial number",
  "000": "000 - No Vessel ID",
  "001": "001 - Maritime MMSI",
  "010": "011 - Radio call sign",
};

export const GEN2_VESSEL_ID_TYPE = { start: 45, end: 48 };
export const GEN2_VESSEL_ID = { start: 48, end: 92 };

export const GEN2_REGISTRATION_MARK_BITS = { start: 48, end: 90 };
export const GEN2_AVIATION_24BIT_ADDRESS_BITS = { start: 48, end: 72 };

export const GEN2_AIRCRAFT_OPERATOR_DESIGNATOR_BITS = { start: 48, end: 63 };
export const GEN2_AIRCRAFT_OPERATOR_SN_BITS = { start: 66, end: 78 };

export const GEN2_SHIP_STATION_IDENTITY = { start: 48, end: 78 };
export const GEN2_EPIRB_AIS = { start: 78, end: 92 };

export const GEN2_RADIO_CALL_SIGN_BITS = { start: 48, end: 92 };
