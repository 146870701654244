import { action, observable, computed, toJS } from 'mobx';


class EditEmailTemplateState {
    @observable items = {}
    @observable currentTemplate = ''
    @observable isSaving = false

    @action setCurrentTemplate(value){
        this.currentTemplate = value
    }

    @action setItems(value){
        this.items = value
    }

    @action updateItems(value) {
        this.items = { ...this.items, ...value }
    }

    @action setIsSaving(value){
        this.isSaving = value
    }

}

const singleton = new EditEmailTemplateState()
export default singleton