import i18n from "i18next";
import React, { Component } from "react";
import * as Yup from "yup";
import { Form, Input, Button, Popup, Select, Header, Message, Radio, Icon, Label } from "semantic-ui-react";
import ActivityState from "../../../state/activityState";
import DashboardState from "../../../state/dashboardState";
import withSemanticUIFormik from "../../../utils/formHelper";
import RouteState from "../../../state/routeState";

class InnerForm extends Component {
  state = {
    beaconOptions: [],
    beaconInfos: [],
  };

  componentDidMount = () => {
    DashboardState.searchBeacons({ specialStatus: ["ACTIVE"] }).then((response) => {
      if (!response) return;

      let beaconCut = response.slice(0, 10);

      const beacon = beaconCut.map((row, index) => {
        return {
          text: row.hexId + " - " + row.beaconType,
          value: row._id,
          key: row._id,
        };
      });

      this.setState({
        beaconOptions: beacon,
        beaconInfos: response,
      });
    });
  };

  onSelectBeaconChange = (e, data) => {
    // set selected when changed
    if (data && data.name) {
      this.props.setFieldValue(data.name, data.value);
    }

    // set selecting vehicle info
    var selecting = this.state.beaconInfos.find((row) => row._id === data.value);

    // this.props.values.beaconType = selecting.beaconType
    // this.props.values.beaconMailCountry = selecting.mailCountry
    // this.props.values.beaconCountryCode = selecting.beaconCountryCode;
  };

  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    } else {
      ActivityState.setBeaconPreviousStep();
    }
  };

  saveAndClose = () => {
    if (ActivityState.isEditActivity) {
      ActivityState.setIsSaving(true);
      ActivityState.updateActivityFields(this.props.values);
      ActivityState.saveActivity().then(() => {
        ActivityState.setIsSaving(false);
        RouteState.history.goBack();
      });
    }
  };

  render() {
    const { values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, isValid, onClose } = this.props;

    return (
      <Form className="activityDetail-form animated fadeIn faster">
        <Header as="h2" icon textAlign="center">
          <Header.Content>{i18n.t('select-a-beacon-to-add-to-the-activity')}</Header.Content>
        </Header>

        <Form.Group>
          <Form.Field width="6" className="select-beacon-label">
            {i18n.t('select-a-beacon')}
          </Form.Field>
          <Form.Select
            width="8"
            name="selectBeacon"
            options={this.state.beaconOptions}
            placeholder="Select"
            onChange={this.onSelectBeaconChange}
            onBlur={handleBlur}
            value={values.selectBeacon}
            disabled={!ActivityState.isEditable()}
            search
          />
          {/* <Input name='beaconType' value={values.beaconType} type='hidden' />
					<Input name='beaconMailCountry' value={values.beaconMailCountry} type='hidden' />
					<Input name='beaconCountryCode' value={values.beaconCountryCode} type='hidden' /> */}
        </Form.Group>

        <div className="activityDetail-submitContainer">
          <Button className="registerBeacon-submitButton" onClick={this.onClose}>
            {i18n.t('back')}
          </Button>
          <Button className="registerBeacon-submitButton" type="submit" primary onClick={handleSubmit}>
            {i18n.t('next')}
          </Button>
          {/* {
						ActivityState.isEditActivity ? 
						<Button className="activityDetail-submitButton" primary onClick={this.saveAndClose} loading={ActivityState.isSaving} >
						Save & Close
						</Button>
						: ''
					} */}
        </div>
      </Form>
    );
  }
}

const BeaconForm = withSemanticUIFormik({
  mapPropsToValues: ({ activity }) => {
    if (activity) {
      // console.log('log: activity - mapPropsToValues', activity);
      return {
        selectBeacon: activity.selectBeacon || "",
        // beaconType: activity.beaconType || '',
        // beaconMailCountry: activity.beaconMailCountry || '',
        // beaconCountryCode: activity.beaconCountryCode || ''
      };
    } else {
      return {
        selectBeacon: "",
        // beaconType: '',
        // beaconMailCountry: '',
        // beaconCountryCode: ''
      };
    }
  },

  handleSubmit: (values, { setSubmitting, props }) => {
    console.log("log: values===================@form", values, props);
    ActivityState.updateActivityFields(values);
    ActivityState.setUserNextStep();
  },
})(InnerForm);

export default BeaconForm;
