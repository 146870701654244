import React, { Component } from 'react';
import * as Yup from 'yup';
import { Form, Input, Button, Popup, Select, Header, Message, Icon } from 'semantic-ui-react';
import RouteState from '../../state/routeState';
import i18n from "i18next";
export default class RegistrationComplete extends Component {

	componentDidMount(){
		if(!RegistrationState.isNewRegisterBeacon){
			var topPosition = document.getElementById("editBeaconModal");
			topPosition.scrollIntoView();
		}
		else{
		  window.scrollTo({top: 0});
		}
	}

	returnToDashboardClick = () => {
		RouteState.setRoute('/dashboard');
	}

	registerNewBeaconClick = ()=>{
		RouteState.setRoute('/newBeacon');
	}


	render() {

		let title = RegistrationState.isNewRegisterBeacon ? i18n.t('registration-complete') : i18n.t('edit-complete');

		return (
			<div>
				<Header as='h2' icon textAlign='center'>
					<Icon name='check' circular color="green" />
					<Header.Content>{title}</Header.Content>
				</Header>
				<div className="registerBeacon-submitContainer">
						<Button className="registerBeacon-submitButton" onClick={this.returnToDashboardClick} >{i18n.t('return-to-dashboard')}</Button>
						<Button className="registerBeacon-submitButton" type="submit" primary onClick={this.registerNewBeaconClick}>{i18n.t('register-a-new-beacon')}</Button>
					</div>
			</div>
		)
	}
}