import i18n from "i18next";
import React, { Component } from "react";
import { Segment, Header, Icon, List, Button, Container, Accordion } from "semantic-ui-react";
import { observer } from "mobx-react";
import { action, observable } from "mobx";
import DefaultPageLayout from "../../components/defaultPageLayout";
import SpecialMessage from '../specialMessage';
import AppConfigState from '../../state/appConfigState';
import AppState from '../../state/appState';
import "./faq.css";

@observer
export default class faq extends Component {

  state = {
    activeFAQId: '',
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props

    if(params.id) {
      this.setState({activeFAQId: params.id});
    }
    window.gtag("event", "helpAndFact_display", { event_category: "helpAndFact_display", event_label: "helpAndFact_display" });
  }

  render() {
    let currentLocale = AppState.currentLocale
      return (
          <DefaultPageLayout>
            <FaqMainPage activeFAQId={this.state.activeFAQId} currentLocale={currentLocale} />
          </DefaultPageLayout>
        );
  }
}

const FaqMainPage = ({ activeFAQId }) => {
  return (
    <Container className="faq-container">
      <Header as="h2" icon>
       {i18n.t('help-and-faq')}
      </Header>
      <SpecialMessage pageKey="Faq" showPlaceholder={!AppState.appLoaded} />
      <FaqQuestionAndAnswer activeFAQId={activeFAQId} />
    </Container>
  );
};

@observer
class FaqQuestionAndAnswer extends Component {

  componentWillUnmount(){
    AppConfigState.setFaqAccordionClick(false)
  }
 
  getQuestionAndAnswer() {
    console.log('----> app config 2 : ', AppConfigState.config);
    let _data = {
      panels: null
    }

    let data = AppConfigState.getPublishedPageContentWithCurrentLanguage('FaqQ&A');
    let panels = [];
    for(let i=0; i<data.length; i++) {
      panels.push({
        key: data[i].pageContentKey,
        title: data[i].subject,
        content: {
          content: ( <div dangerouslySetInnerHTML={{__html: data[i].content}} /> )
        }
      });
    }

    // set active index for first time
    const findIndex = data.findIndex(row => this.props.activeFAQId && this.props.activeFAQId == row.pageContentKey)
    if(this.props.activeFAQId && !AppConfigState.faqAccordionClick){
      AppConfigState.setActiveIndex(findIndex)
    }

    _data.panels = panels
    return _data
  }

  render() {

    let data = {
      panels: null
    }
    if(AppState.appLoaded){
      data = this.getQuestionAndAnswer()
    }
    else{
      return ''
    }

    return (
      <Segment style={{ margin: "0 0 20px" }}>
        <Accordion className="question-answer" fluid
          panels={data.panels}
          activeIndex={AppConfigState.activeIndex}
          onTitleClick={AppConfigState.handleTitleClick}
        />
      </Segment>
    );
  }
}
