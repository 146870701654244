import React, { Component } from "react";
import { observer } from "mobx-react";
import { Container, Header, Grid, Divider, Button, Icon, Table, GridRow, Image, Modal } from "semantic-ui-react";
import AppConfigState from "../../../state/appConfigState";
import UserState from "../../../state/userState";
import ActivityState from "../../../state/activityState";
import AssociatedUserState from "../../../state/associatedUserState";
import * as Utils from "../../../utils/utils";
import { EPIRBVehicleDisplay, ELTVehicleDisplay } from "../../../pages/registerActivity/activity/vehicleDetailForm";
import i18n from "i18next";
let showActivityByRole = ["4"];

@observer
export default class ActivityWidget extends Component {
  state = {
    activities: null,
  };

  componentDidMount = async () => {
    if (showActivityByRole.includes(UserState.currentUserProfile.roleId)) {
      let filter = {
        owner: this.props.owner ? this.props.owner : "",
        beaconId: this.props.hexId ? "beacon_" + this.props.hexId : "",
        isOngoing: this.props.isOngoing ? this.props.isOngoing : false,
      };
      let activities = await ActivityState.getActivityByBeaconId(filter);
      console.log("log: ActivityWidget -> componentDidMount -> activities", activities);
      if (activities) {
        this.setState({ activities: activities.docs });
      }
    }
  };

  render() {
    const { activities } = this.state;
    console.log("log: ActivityWidget -> render -> activities", activities);
    const { title } = this.props;

    return (
      <>
        {activities && activities.length > 0 ? <Header>{title}</Header> : ""}
        {activities && activities.length > 0
          ? activities.map((row, index) => {
              let totalAdult = row.participantAdults || 0;
              let totalTeen = row.participantTeens || 0;
              let totalChildren = row.participantChildren || 0;
              let allPaticipantText = totalAdult + " Adults | " + totalTeen + " Teens | " + totalChildren + " Children";

              let isElt = ActivityState.isAnEltAssociated(row);
              console.log('log: ActivityWidget -> render -> isElt', isElt);
              let isEPIRB = ActivityState.isAnEpirbAssociated(row);
              let isEltOrEpirb = ActivityState.isAnEltOrAnEpirbAssociated(row);

              let html = (
                <Grid key={index} columns={2} className="beaconReviewDetails-grid" stackable>
                  <div>
                    <strong>#{index + 1}</strong>
                  </div>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <div>
                        {i18n.t('activity-name')}: <strong>{row.activityName || ""}</strong>
                      </div>
                      <div>
                        {i18n.t('primary-activity')}: <strong>{row.primaryActivity || ""}</strong>
                      </div>
                      <div>
                        {i18n.t('secondary-activity')}: <strong>{row.secondaryActivity || ""}</strong>
                      </div>

                      {row.duration == "specific" ? (
                        <>
                          <div>
                            {i18n.t('departure')}: <strong>{Utils.dateFormatWithTime(row.durationSpecificStartDate, true)}</strong>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      <div>
                        {i18n.t('departure-location-place')}: <strong>{row.departureLocation || ""}</strong>
                      </div>
                      <div>
                        {i18n.t('city')}: <strong>{row.departureNearestTown || ""}</strong>
                      </div>
                      <div>
                        {i18n.t('province-state')}: <strong>{row.departureState || ""}</strong>
                      </div>
                      <div>
                        {i18n.t('country')}: <strong>{countryCodeToText(row.departureCountry)}</strong>
                      </div>

                      {row.duration == "specific" ? (
                        <>
                          <div>
                            {i18n.t('arrival')}: <strong>{Utils.dateFormatWithTime(row.durationSpecificEndDate, true)}</strong>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      <div>
                        {i18n.t('arrival-location-place')}: <strong>{row.arrivalLocation || ""}</strong>
                      </div>
                      <div>
                        {i18n.t('city')}: <strong>{row.arrivalNearestTown || ""}</strong>
                      </div>
                      <div>
                        {i18n.t('province-state')}: <strong>{row.arrivalState || ""}</strong>
                      </div>
                      <div>
                        {i18n.t('country')}: <strong>{countryCodeToText(row.arrivalCountry)}</strong>
                      </div>
                      {row.duration != "specific" ? (
                        <>
                          <div>
                            {i18n.t('duration')}: <strong>{row.duration != "specific" ? row.duration : ""}</strong>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      <div>
                        {i18n.t('participants')}: <strong>{allPaticipantText}</strong>
                      </div>
                      <div>
                        {i18n.t('additional-comments')}: <strong>{row.participantAdditionalComments || ""}</strong>
                      </div>
                      <div>
                        {i18n.t('safety-equipment')}: <strong>{row.safetyEquiptment || ""}</strong>
                      </div>
                      <div>
                        {i18n.t('communication-equipment')}: <strong>{row.communicationEquiptment || ""}</strong>
                      </div>

                      <br />
                      {isElt && <ELTVehicleDisplay hideHeader={true} activity={row} hideButtons={true} />}
                      {isEPIRB && <EPIRBVehicleDisplay hideHeader={true} activity={row} hideButtons={true} />}
                      {!isEltOrEpirb ? (
                        <>
                          <div>
                            {i18n.t('vehicle-type')}:{" "}
                            <strong>
                              {/* {row.vehicleType == 'Other' ? row.vehicleTypeOther : row.vehicleType ? row.vehicleType : ''} */}
                              {row.vehicleType == "Other" ? row.vehicleTypeOther || "" : row.vehicleType || ""}
                            </strong>
                          </div>
                          <div>
                            {i18n.t('vehicle-model')}: <strong>{row.vehicleModel || ""}</strong>
                          </div>
                          <div>
                            {i18n.t('registration-number')}: <strong>{row.registrationNumber || ""}</strong>
                          </div>
                          <div>
                            {i18n.t('registration-country')}: <strong>{row.registrationCountry || ""}</strong>
                          </div>
                          <div>
                            {i18n.t('colour')}: <strong>{row.vehicleColor || ""}</strong>
                          </div>
                          <div>
                            {i18n.t('specific-usage')}:{" "}
                            <strong>
                              {/* {row.specificUsage == 'Other' ? row.specificUsageOther : row.specificUsage ? row.specificUsage : ''} */}
                              {row.specificUsage == "Other" ? row.specificUsageOther || "" : row.specificUsage || ""}
                            </strong>
                          </div>
                          <div>
                            {i18n.t('comments-0')}: <strong>{row.comment || ""}</strong>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </Grid.Column>

                    <Grid.Column>
                      <div>
                        {i18n.t('title')}: <strong>{row.title || ""}</strong>
                      </div>
                      <div>
                        {i18n.t('holder-first-name')}: <strong>{row.firstName || ""}</strong>
                      </div>
                      <div>
                        {i18n.t('holder-last-name')}: <strong>{row.lastName || ""}</strong>
                      </div>
                      <div>
                        {i18n.t('relationship-to-beacon-owner')}: <strong>{row.userLink || ""}</strong>
                      </div>
                      <div>
                        {i18n.t('language')}: <strong>{row.operationLanguage || ""}</strong>
                      </div>
                      <div>
                        {i18n.t('medications-allergies-0')}: <strong>{row.medicalInfo || ""}</strong>
                      </div>
                      <div>
                        {i18n.t('address')}: <strong>{row.address || ""}</strong>
                      </div>
                      <div>
                        {i18n.t('city')}: <strong>{row.city || ""}</strong>
                      </div>
                      <div>
                        {i18n.t('state-province')}: <strong>{row.province || ""}</strong>
                      </div>
                      <div>
                        {i18n.t('country')}: <strong>{row.mailCountry || ""}</strong>
                      </div>
                      <div>
                        {i18n.t('zip-postal-code-1')}: <strong>{row.mailCode || ""}</strong>
                      </div>
                      {row.phoneNumber && row.phoneNumber != "" ? (
                        <div>
                          {i18n.t('phone-1-0')}:{" "}
                          <strong>
                            {row.phoneNumber || ""} {row.phoneType ? "(" + phoneTypeToText(row.phoneType, row.phoneTypeOther) + ")" : ""}
                          </strong>
                        </div>
                      ) : (
                        ""
                      )}
                      {row.phoneNumber2 && row.phoneNumber2 != "" ? (
                        <div>
                          {i18n.t('phone-2-0')}:{" "}
                          <strong>
                            {row.phoneNumber2 || ""} {row.phoneType2 ? "(" + phoneTypeToText(row.phoneType2, row.phoneType2Other) + ")" : ""}
                          </strong>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* tab-2 */}
                      <div>
                        {i18n.t('24-hour-emergency-contact-name')}: <strong>{row.primaryContactName || ""}</strong>
                      </div>
                      <div>
                        {i18n.t('emergency-contact-address-line-1')}: <strong>{row.primaryContactAddressLine1 || ""}</strong>
                      </div>
                      <div>
                        {i18n.t('emergency-contact-address-line-2')}: <strong>{row.primaryContactAddressLine2 || ""}</strong>
                      </div>
                      {row.primaryPhone1Num && row.primaryPhone1Num != "" ? (
                        <div>
                          {i18n.t('emergency-contact-phone-number-1')}:{" "}
                          <strong>
                            {row.primaryPhone1Num || ""} {row.primaryPhone1Type ? "(" + phoneTypeToText(row.primaryPhone1Type, row.primaryPhone1TypeOther) + ")" : ""}
                          </strong>
                        </div>
                      ) : (
                        ""
                      )}
                      {row.primaryPhone2Num && row.primaryPhone2Num != "" ? (
                        <div>
                          {i18n.t('emergency-contact-phone-number-2')}:{" "}
                          <strong>
                            {row.primaryPhone2Num || ""} {row.primaryPhone2Type ? "(" + phoneTypeToText(row.primaryPhone2Type, row.primaryPhone2TypeOther) + ")" : ""}
                          </strong>
                        </div>
                      ) : (
                        ""
                      )}
                      {row.primaryPhone3Num && row.primaryPhone3Num != "" ? (
                        <div>
                          {i18n.t('emergency-contact-phone-number-3')}:{" "}
                          <strong>
                            {row.primaryPhone3Num || ""} {row.primaryPhone3Type ? "(" + phoneTypeToText(row.primaryPhone3Type, row.primaryPhone3TypeOther) + ")" : ""}
                          </strong>
                        </div>
                      ) : (
                        ""
                      )}
                      {row.primaryPhone4Num && row.primaryPhone4Num != "" ? (
                        <div>
                          {i18n.t('emergency-contact-phone-number-4')}:{" "}
                          <strong>
                            {row.primaryPhone4Num || ""} {row.primaryPhone4Type ? "(" + phoneTypeToText(row.primaryPhone4Type, row.primaryPhone4TypeOther) + ")" : ""}
                          </strong>
                        </div>
                      ) : (
                        ""
                      )}

                      <div>
                        {i18n.t('alternate-24-hour-emergency-contact-name')}: <strong>{row.alternateContactName || ""}</strong>
                      </div>
                      <div>
                        {i18n.t('alternate-contact-address-line-1')}: <strong>{row.alternateContactAddressLine1 || ""}</strong>
                      </div>
                      <div>
                        {i18n.t('alternate-contact-address-line-2')}: <strong>{row.alternateContactAddressLine2 || ""}</strong>
                      </div>
                      {row.alternatePhone1Num && row.alternatePhone1Num != "" ? (
                        <div>
                          {i18n.t('alternate-contact-phone-number-1')}:{" "}
                          <strong>
                            {row.alternatePhone1Num || ""} {row.alternatePhone1Type ? "(" + phoneTypeToText(row.alternatePhone1Type, row.alternatePhone1TypeOther) + ")" : ""}
                          </strong>
                        </div>
                      ) : (
                        ""
                      )}
                      {row.alternatePhone2Num && row.alternatePhone2Num != "" ? (
                        <div>
                          {i18n.t('alternate-contact-phone-number-2')}:{" "}
                          <strong>
                            {row.alternatePhone2Num || ""} {row.alternatePhone2Type ? "(" + phoneTypeToText(row.alternatePhone2Type, row.alternatePhone2TypeOther) + ")" : ""}
                          </strong>
                        </div>
                      ) : (
                        ""
                      )}
                      {row.alternatePhone3Num && row.alternatePhone3Num != "" ? (
                        <div>
                          {i18n.t('alternate-contact-phone-number-3')}:{" "}
                          <strong>
                            {row.alternatePhone3Num || ""} {row.alternatePhone3Type ? "(" + phoneTypeToText(row.alternatePhone3Type, row.alternatePhone3TypeOther) + ")" : ""}
                          </strong>
                        </div>
                      ) : (
                        ""
                      )}
                      {row.alternatePhone4Num && row.alternatePhone4Num != "" ? (
                        <div>
                          {i18n.t('alternate-contact-phone-number-4')}:{" "}
                          <strong>
                            {row.alternatePhone4Num || ""} {row.alternatePhone4Type ? "(" + phoneTypeToText(row.alternatePhone4Type, row.alternatePhone4TypeOther) + ")" : ""}
                          </strong>
                        </div>
                      ) : (
                        ""
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  <Divider />
                </Grid>
              );
              return html;
            })
          : ""}
      </>
    );
  }
}

function countryCodeToText(id) {
  let countryNamesWithCode = AppConfigState.countryNamesWithCode;
  let item = countryNamesWithCode.find((row) => row.value == id);
  if (!item) return "";
  return item.text;
}

function phoneTypeToText(id, otherType = "") {
  let phoneTypes = AppConfigState.phoneTypes;
  let item = phoneTypes.find((row) => row.value == id);
  if (!item) return "";
  if (item.value == "OTHR") {
    return item.text + " - " + otherType;
  }
  return item.text;
}
