import { action, observable, computed, toJS } from "mobx";
import * as BeaconService from "../services/beaconService";
import * as utils from "../utils/utils";
import AppConfigState from "./appConfigState";
import * as MailService from "../services/mailService";
import * as PocService from "../services/pocService";
import { fetchDatafromAPI } from "../services/serviceUtils";
import beaconDecode from "../utils/beaconDecode/BeaconDecode";

class ContactUsState {
  @observable beacon = {};
  @observable isSubmitted = false;
  @observable mid;
  @observable isSupported = false;
  beaconType = "N/A";

  async getBeaconByHexId(hexId) {
    try {
      let beacon = await beaconDecode(hexId);
      this.setBeacon(beacon);
      console.log("ContactUsState -> getBeaconByHex -> result", beacon);
      return beacon;
    } catch (error) {
      console.log("ContactUsState -> getBeaconByHex -> error", error);
    }
  }
  @action setBeacon(beacon) {
    this.beacon = beacon;
  }

  @action setIsSubmitted(value) {
    this.isSubmitted = value;
  }

  @action resetState() {
    this.beacon = {};
    this.isSubmitted = false;
  }

  @action setMid(mid) {
    console.log("log ~ file: contactUsState.js ~ line 38 ~ ContactUsState ~ @actionsetMid ~ mid", mid);
    this.mid = mid;
  }

  setbeaconType(type) {
    this.beaconType = type;
  }

  replaceTemplateText(template, FormValues) {
    var mapObj = {
      "[SUBJECT]": FormValues.subject || "--subject--",
      "[NAME]": FormValues.name || "--name--",
      "[EMAIL]": FormValues.email || "--email--",
      "[HEXID]": FormValues.hex || "--hex id--",
      "[BEACONTYPE]": FormValues.beaconType || "--beacon type--",
      "[COUNTRY]": FormValues.country || "--country--",
      "[MESSAGE]": FormValues.message || "--message--",
    };
    let newMessage = template.content.replace(/\[SUBJECT\]|\[NAME\]|\[EMAIL\]|\[HEXID\]|\[BEACONTYPE\]|\[COUNTRY\]|\[MESSAGE\]/g, function(matched) {
      return mapObj[matched];
    });
    console.log(">>>>>>>>>>>>>>>>>>>>>>> new msg:\n " + newMessage);
    return newMessage;
  }

  async SubmitCRMTicket(FormData) {
    // return {err: null, message: 'Success'}
    try {
      if (!FormData.email) return { err: "error", message: "Email is mandatory" };

      let beaconType = "-1";
      if (this.beaconType.includes("EPIRB")) {
        beaconType = "0";
      } else if (this.beaconType.includes("ELT")) {
        beaconType = "1";
      } else if (this.beaconType.includes("PLB")) {
        beaconType = "2";
      } else if (this.beaconType.includes("SSAS")) {
        beaconType = "3";
      }
      this.isSupported = false;
      let pocInfo;
      // check if individual support
      let midInfo = AppConfigState.config.MIDInfo.find((row) => row.Mid == this.mid);

      if (this.mid === "N/A") {
        this.isSupported = true;
      } else if (midInfo && midInfo.Supported == "Y" && midInfo.SupportedTypes.includes(beaconType)) {
        this.isSupported = true;
      } else if (midInfo) {
        pocInfo = await this.getPOC(midInfo, this.beaconType);
      }
      

      let status;
      // if (response.operation.result.status === "Success") {
      if (true) {
        // call send email to (1) admin (2) user
        const emailTemplateKey = AppConfigState.getEmailTemplateKey("CONTACT_US");
        const emailTemplate = AppConfigState.getEmailTemplate(emailTemplateKey);
        let adminTemplate = {
          // email: "nichapon.didwisade@ofitechnology.com", // change to admin's email
          email: IBRD_CONFIG.adminEmail,
          subject: emailTemplate.subject,
          message: this.replaceTemplateText(emailTemplate, FormData),
        };

        let pocEmail = "";
        if (!this.isSupported && pocInfo) {
          pocEmail = pocInfo.Email;
        }

        if(this.beacon && this.beacon.callSign){
          FormData.callSign = this.beacon.callSign;
        }

        if(this.beacon && this.beacon.vehicleName){
          FormData.vehicleName = this.beacon.vehicleName;
        }

         if(this.mid){
          FormData.countryName = AppConfigState.getCountryNameByMid(this.mid);
        }


        let sendToAdmin = await MailService.sendMailContactUs(adminTemplate.email, adminTemplate.subject, adminTemplate.message, FormData, this.isSupported, pocEmail);
        console.log("log ~ file: contactUsState.js ~ line 109 ~ ContactUsState ~ SubmitCRMTicket ~ sendToAdmin", sendToAdmin);

        // for user
        if (this.isSupported) {
          const userEmailTemplateKey = AppConfigState.getEmailTemplateKey("CONTACT_US_AUTO");
          const userEmailTemplate = AppConfigState.getEmailTemplate(userEmailTemplateKey);
          let userTemplate = {
            email: FormData.email,
            // subject: 'International Beacon Registration Database (IBRD) - Contact us',
            // message: 'Your email has been relayed to the Secretariat of the International Cospas-Sarsat 			Programme and someone will contact you with a response within 72 hours. For questions and/or information related to a Search and Rescue (SAR) operations, please contact the appropriate Search and Rescue Point of Contact (SPOC) (available 24/7) whose data are available at www.cospas-sarsat.int .'
            subject: userEmailTemplate.subject,
            message: userEmailTemplate.content,
          };
          let sendToUser = await MailService.sendMail(userTemplate.email, userTemplate.subject, userTemplate.message);

          if ((sendToAdmin && sendToAdmin.err) || (sendToUser && sendToUser.err)) {
            status = { err: "error", message: "Cannot send email" };
          } else {
            status = { err: null, message: "Success" };
          }
        } else {
          status = { err: null, message: "Success" };
        }
      } else {
        status = { err: "error", message: "Cannot submit to IBRD's ticket system" };
      }

      console.log("=================> submit ticket status: " + status);
      return status;
    } catch (error) {
      console.log("contactState -> submit ticket -> error: ", error);
      // return { err: "error", message: "Cannot submit to IBRD's ticket system" };
      return { err: "error", message: error };
    }
  }

  async getPOC(midInfo, beaconType) {
    let pocIndex = midInfo.POCCrossRef;
    if (beaconType.includes("EPIRB") && midInfo.POCEPIRB != "0") {
      pocIndex = midInfo.POCEPIRB;
    } else if (beaconType.includes("ELT") && midInfo.POCELT != "0") {
      pocIndex = midInfo.POCELT;
    } else if (beaconType.includes("PLB") && midInfo.POCPLB != "0") {
      pocIndex = midInfo.POCPLB;
    } else if (beaconType.includes("SSAS") && midInfo.POCSSAS != "0") {
      pocIndex = midInfo.POCSSAS;
    }

    if (pocIndex !== "0") {
      try {
        let result = await PocService.getPOCByIndex(pocIndex);
        console.log("RegistrationState:getPointOfContact -> result", pocIndex, result);
        return result;
      } catch (error) {
        console.error("RegistrationState:getPointOfContact -> error", pocIndex, error);
      }
    }
    return null;
  }
}

const singleton = new ContactUsState();
export default singleton;
