import * as dbService from "./dbService";
import { insertOrUpdateDocument } from "./serviceUtils";
import generateHash from "random-hash";
import * as UserService from "./userServices";

export async function initIndexes() {
  try {
    let createSarLoginIndex = await createSarLoginReportIndex();
    console.log("AccessLogService -> ReportIndex -> result: " + JSON.stringify(createSarLoginIndex));
  } catch (err) {
    console.log("AccessLogService -> initIndex -> err: " + err);
  }
}

export async function save(data) {
  let db = dbService.getAccessLogDb();

  let ipAddress = await getIpAddress()

  console.log("log: save -> ipAddress", ipAddress);

  try {
    const logData = {
      _id: "accessLog_" + generateHash({ length: 16 }),
      type: "accessLog",

      userId: data.userId || "",
      beaconId: data.beaconId || "", 
      userName: data.userName || "",
      ownerName: data.ownerName || "",
      //password:   data.password || '', ??? What ?? plain text password for all users in access logs?
      ipAddress: ipAddress || "",
      sessionId: data.sessionId || "",
      sessionDate: data.sessionDate || "",
      sessionEvent: data.sessionEvent || "",
      roleId: data.roleId || "",
    };

    let result = await insertOrUpdateDocument(logData, db);
    console.log("accessLogSevice -> save -> result", result);
    return result;
  } catch (err) {
    console.log("accessLogSevice -> save -> error", err);
  }
}

export async function saveQueryLog(userProfile, criteria) {
  let db = dbService.getAccessLogDb();
	  let ipAddress = await getIpAddress()
  try {
    const logData = {
      _id: "queryLog_" + generateHash({ length: 16 }),
      type: "queryLog",
      userId: userProfile._id || "",
      ownerName: userProfile.ownerName || "",
      ipAddress: ipAddress || "",     
      roleId: userProfile.roleId || "",
			criteria: criteria,
    };

    let result = await insertOrUpdateDocument(logData, db);
    console.log("saveQueryLog -> save -> result", result);
    return result;
  } catch (err) {
    console.log("saveQueryLog -> save -> error", err);
  }
}


export async function saveExportLog(userProfile, criteria) {
  let db = dbService.getAccessLogDb();
	  let ipAddress = await getIpAddress()
  try {
    const logData = {
      _id: "exportLog_" + generateHash({ length: 16 }),
      type: "exportLog",
      userId: userProfile._id || "",
      ownerName: userProfile.ownerName || "",
      ipAddress: ipAddress || "",     
      roleId: userProfile.roleId || "",
			criteria: criteria,
    };

    let result = await insertOrUpdateDocument(logData, db);
    console.log("saveQueryLog -> save -> result", result);
    return result;
  } catch (err) {
    console.log("saveQueryLog -> save -> error", err);
  }
}

// SAR Login Report
async function createSarLoginReportIndex() {
  let db = dbService.getAccessLogDb();
  var ddoc = {
    _id: "_design/sarLoginReport",
    views: {
      sarLoginReport: {
        map: function(doc) {
          if (doc.sessionDate && doc.roleId === "4") {
            // return only sar user
            // var yearAndMonth = doc.sessionDate.substr(0, 7); // 2013-05-22 02:06:26.000 -> 2013-05
            var d, yearAndMonth;
            if (typeof doc.sessionDate === "number") {
              let createDate = new Date(doc.sessionDate);
              let year = createDate.getFullYear();
              let month = ("0" + (createDate.getMonth() + 1)).slice(-2);
              let day = ("0" + createDate.getDate()).slice(-2);
              let hours = ("0" + createDate.getHours()).slice(-2);
              let minutes = ("0" + createDate.getMinutes()).slice(-2);
              let seconds = ("0" + createDate.getSeconds()).slice(-2);

              d = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
              yearAndMonth = d.substr(0, 10);
            } else {
              yearAndMonth = doc.sessionDate.substr(0, 10);
            }

            var username = doc.userName;
            emit(yearAndMonth + "-" + username);
          }
        }.toString(),

        reduce: "_count",
      },
    },
  };

  // save the design doc
  try {
    try {
      await insertOrUpdateDocument(ddoc, db);
      // await insertOrUpdateDocument(ddoc, db) // if need to update index too!
    } catch (err) {
      if (err.name !== "conflict") {
        throw err;
      }
      // ignore if doc already exists
    }
  } catch (err) {
    console.log(err);
  }
}

export async function getSarLoginReport(startDate, endDate) {
  let db = dbService.getAccessLogDb();
  try {
    var result = await db.query("sarLoginReport", {
      group: true,
      include_docs: false,
      startkey: startDate,
      endkey: endDate + "\uffff",
      inclusive_end: false,
    });
    // console.log('===============> report data > getSarLoginReport: ' + JSON.stringify(result))
    return result;
  } catch (err) {
    console.log(err);
  }
}

async function getIpAddress() {
  let ipAddress = "";
  try {
    let response = await fetch("https://api.ipify.org?format=json");
    let result = await response.json();
    ipAddress = result.ip;
  } catch (error) {
    ipAddress = "blocked";
  }
  return ipAddress;
}
