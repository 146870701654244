import React, { Component } from 'react';
import { observer } from 'mobx-react';
import TopMenu from '../../components/topMenu';
import Footer from '../../components/footer';
import RegisterBeacon from './registerBeacon';
import RegistrationState from '../../state/registrationState';

import './registerBeacon.css'

@observer
export default class RegisterBeaconPage extends Component {

	componentDidMount(){

		/*let beacon = RegistrationState.decodedBeacon;
		if(!beacon.hexId){
			 RegistrationState.submitHexForRegistration("ACA9C65C34D34DC");
		}*/
	}

	render() {
		let beacon = RegistrationState.decodedBeacon;
		 console.log('log: RegisterBeaconPage -> render -> beacon', beacon);
		if(!beacon.hexId) return null;
   

		

		return (
			<div className="registerBeacon-page">
				<TopMenu />
				<RegisterBeacon beacon={beacon} />
				<Footer />
			</div>
		)
	}
}
