import { action, observable, computed, toJS } from "mobx";
import * as utils from "../utils/utils";
import AppConfigState from "../state/appConfigState";
import * as BeaconService from "../services/beaconService";
import * as PocService from "../services/pocService";
import AppState from "./appState";

class MIDInfoState {
  @observable items = [];
  @observable isSaving = false;
  @observable SupportedTypes = "";
  @observable SupportedTypesNDP = "";
  @observable column = null;
  @observable direction = null;
  @observable isLoading = false;

  @action setItems(value) {
    this.items = value;
  }

  @action setIsLoading(value) {
    this.isLoading = value;
  }

  @action updateItems(value) {
    this.items = { ...this.items, ...value };
  }

  @action setIsSaving(value) {
    this.isSaving = value;
  }

  @action setSupportedTypes(value) {
    this.SupportedTypes = value;
  }

  @action setSupportedTypesNDP(value) {
    this.SupportedTypesNDP = value;
  }

  async backupToPoc(mids) {
    try {
      let newMids = mids.map(mid => {
          mid.type = "mid"
          mid._id = "mid_" + mid.Mid;
          return mid;
      })
      await PocService.storeBulkPOCs(newMids);
    } catch (error) {
      console.log("MIDInfoState -> backupToPoc -> error", error);
    }
  }

  async searchMID(criteria) {
    this.setIsLoading(true);

    // console.log('>>>>>>>>> filter: ', criteria);
    let config = AppConfigState.config;
    if (JSON.stringify(criteria) === JSON.stringify({})) {
      this.setItems(config.MIDInfo);
      this.setIsLoading(false);
    } else {
      // filter by criteria
      let newMid = AppConfigState.config.MIDInfo.filter((item) => {
        for (var key in criteria) {
          let searchBy = criteria[key][0].toLowerCase();
          let itemText = item[key].toLowerCase();
          if (item[key] === undefined || !itemText.includes(searchBy)) return false;
        }
        return true;
      });
      this.setItems(newMid);
    }
    this.setIsLoading(false);
  }

  async getMID() {
    try {
      let appConfig = await BeaconService.getConfig();
      this.setItems(appConfig.MIDInfo);
    } catch (error) {
      console.log("ibrd: AppCOnfigState -> getConfig -> error", error);
    }
  }

  @action handleSort(clickedColumn) {
    let sortData = this.items;
    if (this.column !== clickedColumn) {
      sortData = utils.sortBy(sortData, clickedColumn);
      sortData = sortData.slice().reverse();
      this.column = clickedColumn;
      this.direction = "descending";
    } else {
      sortData = utils.sortBy(sortData, clickedColumn);
      this.direction = this.direction === "descending" ? "ascending" : "descending";
      if (this.direction === "descending") {
        sortData = sortData.slice().reverse();
      }
    }
    this.setItems(sortData);
  }
}

const singleton = new MIDInfoState();
export default singleton;
