import { withFormik, FieldArray } from 'formik';
import React from 'react';

const withSemanticUIFormik = props => WrappedComponent => {

  return withFormik(props)(class extends React.Component {
    handleBlur = e => {

      let input = e.target;
      //console.log("Home: extends -> handleBlur -> data", input.name, input.value);
      if (input && input.name) {
        this.props.setFieldValue(input.name, input.value);
        this.props.setFieldTouched(input.name);
      }
    };
    handleChange = (e, data) => {
      //console.log("Home: extends -> handleChange -> data", data);
      if (data && data.name) {
        this.props.setFieldValue(data.name, data.value);
      }
    };

    render() {
      return <WrappedComponent {...this.props}
      handleBlur={this.handleBlur}
      handleChange={this.handleChange} />;

    }});

};

export default withSemanticUIFormik;