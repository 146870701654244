import React, { Component } from "react";
import Footer from "../../components/footer";
import TopMenu from "../../components/topMenu";
import RegistrationState from "../../state/registrationState";
import DashboardState from "../../state/dashboardState";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { Message, Segment, Header, Table, Grid, Input, Icon, Button, Dropdown, Divider, Pagination, Dimmer, Loader, Label, Container } from "semantic-ui-react";
import "./dashboard.css";
import "./activities.css";
import RouteState from "../../state/routeState";
import ActivityState from "../../state/activityState";
import Activities from "./activities";
import AssociatedUserState from "../../state/associatedUserState";
import vehicleState from "../../state/vehicleState";
import VehicleSteps from "../registerVehicle/vehicle/vehicleSteps";
import * as Utils from "../../utils/utils";
import "./beacons.css";
import { isBrowser, isMobile } from "react-device-detect";
import i18n from "i18next";

const paginationOptions = [
  { key: "25", text: "25", value: 25 },
  { key: "50", text: "50", value: 50 },
  { key: "100", text: "100", value: 100 },
  { key: "0", text: "All", value: 0 },
];

const searchFields = [
  {
    key: "First Name",
    text: "First Name",
    value: "firstName",
  },
  {
    key: "Last Name",
    text: "Last Name",
    value: "lastName",
  },
];

@observer
export default class Users extends Component {
  constructor(props) {
    super(props);
    this.dropdownRef = React.createRef();
  }

  state = {
    itemsPerPage: 25,
    currentPage: 1,
    isLoading: false,

    // for filter
    searchOption: [],
    searchCriteria: [],
    searchCriteriaObj: {},
    searchType: searchFields[0].value,
    searchTypeLabel: searchFields[0].text,
    searchString: "",
    displaySearch: false,
  };

  componentDidMount() {
    DashboardState.searchAssociatedUsers({});
  }

  onSetPage = (e, data) => {
    console.log("log: onSetPage -> data.activePage", data.activePage);
    this.setState({
      currentPage: data.activePage,
    });
  };

  onChangeItemsPerPage = (e, { value }) => {
    this.setState({
      itemsPerPage: value,
      currentPage: 1,
    });
  };

  // for filter
  onSearchTypeChange = (e, { value }) => {
    let label = findLabelByValue(value, searchFields);
    console.log("log: onSearchTypeChange -> value, text", value, label);
    this.setState({
      searchType: value,
      searchTypeLabel: label,
    });
  };

  onAddCriteria = () => {
    let { searchCriteria, searchCriteriaObj, searchOption, searchType, searchString, searchTypeLabel } = this.state;
    let newCriteria = searchType + ":" + searchString;
    searchCriteriaObj[searchType] = searchString;
    let newCriteriaLabel = searchTypeLabel + ":" + searchString;
    //console.log('onAddCriteria', searchString, newCriteria, newCriteriaLabel)
    if (!searchCriteria.includes(newCriteria) && searchString !== "") {
      searchOption.push({ key: newCriteria, text: newCriteriaLabel, value: newCriteria });
      searchCriteria.push(newCriteria);
      this.setState({
        searchOption: searchOption,
        searchCriteria: searchCriteria,
        searchString: "",
        searchCriteriaObj: searchCriteriaObj,
      });
      this.setState({
        isLoading: true,
      });
      DashboardState.searchAssociatedUsers(searchCriteriaObj).then((result) => {
        this.setState({
          isLoading: false,
        });
      });
      this.dropdownRef.current.handleClick(new Event("click")); //strangly the dropdown won't update until it receive a click
    }
  };

  onSearchStringChange = (e, { value }) => {
    this.setState({
      searchString: value,
    });
  };

  onChange = (e, { searchQuery, value }) => {
    this.setState({
      searchCriteria: value,
    });
  };

  onClearAllSearchCriteria = () => {
    this.setState({
      searchOption: [],
      searchCriteria: [],
      searchString: "",
      searchCriteriaObj: {},
    });
    this.setState({
      isLoading: true,
    });
    DashboardState.searchAssociatedUsers({}).then((result) => {
      this.setState({
        isLoading: false,
      });
    });
  };

  onExpandSearch = () => {
    this.setState({ displaySearch: !this.state.displaySearch });
  };

  onSetPageByBlur = (nbOfPages) => {
    console.log("log: onSetPageByBlur -> nbOfPages", nbOfPages);
    this.setState({
      currentPage: nbOfPages,
    });
  };
  keyPressed = (event, nbOfPages) => {
    if (event.key === "Enter") {
      this.onSetPageByBlur(nbOfPages);
    }
  };

  onPreviousPageClick = () => {
    let currentPage = this.state.currentPage;
    if (currentPage > 1) {
      this.setState({ inputPage: currentPage - 1 });
    }
  };

  onNextPageClick = (nbOfPages) => {
    let currentPage = parseInt(DashboardState.beaconsCurrentPage);
    if (currentPage < nbOfPages) {
      this.setState({ inputPage: currentPage + 1 });
    }
  };

  onInputPageChange = (e) => {
    let pattern = /^\d+$/;
    let isNumber = pattern.test(e.target.value);
    if (isNumber || isNumber == "") {
      this.setState({ inputPage: e.target.value });
    }
  };

  render() {
    let { itemsPerPage, currentPage, isLoading, searchCriteria, searchOption, searchString, displaySearch } = this.state;
    const { onAddAssociatedUser } = this.props;
    let users = DashboardState.associatedUsers;
    let tempActivity = DashboardState.activities;
    let vehicle = DashboardState.vehicles;

    // add activity to user
    users = users.map((userRow) => {
      let _activity = tempActivity.map((activityRow) => {
        if (userRow._id == activityRow.holderId) {
          userRow.relatedActivity = activityRow;
          // delete activityRow._id
          // console.log('*************************' + activityRow._id)
          // return

          let _vehicle = vehicle.map((vehicleRow) => {
            if (vehicleRow._id == activityRow.vehicleId) {
              userRow.relatedVehicle = vehicleRow;
              // return
            }
          });
        }
      });

      return userRow;
    });

    itemsPerPage = itemsPerPage == "0" ? users.length : itemsPerPage;
    let nbOfPages = Math.ceil(users.length / itemsPerPage);
    let pagedData = users.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    let displayRow = null;
    let searchIconClass = "caret down";

    if (displaySearch) {
      searchIconClass = "caret up";
      displayRow = (
        <Grid.Row>
          <Grid.Column width={2}>
            <Header as="h4">{i18n.t('find-a-beacon-holder')}:</Header>
          </Grid.Column>
          <Grid.Column width={3} className="mobile-padding">
            <Dropdown defaultValue={searchFields[0].value} fluid selection options={searchFields} onChange={this.onSearchTypeChange} />
          </Grid.Column>
          <Grid.Column width={4} className="mobile-padding">
            <Input
              action={{ content: "ADD", color: "orange", onClick: () => this.onAddCriteria() }}
              value={searchString}
              placeholder={i18n.t('add-search-criteria-0')}
              fluid
              onChange={this.onSearchStringChange}
            />
          </Grid.Column>
          <Grid.Column width={5} className="mobile-padding">
            <Dropdown
              className="dashboard-beacons-searchCriteria"
              fluid
              multiple
              selection
              ref={this.dropdownRef}
              options={searchOption}
              value={searchCriteria}
              onChange={this.onChange}
            />
          </Grid.Column>

          <Grid.Column width={2} className="mobile-padding">
            <Button className="button-mobile" onClick={this.onClearAllSearchCriteria}>
              Clear
            </Button>
          </Grid.Column>
        </Grid.Row>
      );
    }

    return (
      <div>
        <Grid stackable className="fadeIn fast activities-caption">
          <Grid.Row>
            <Grid.Column width={9} className="beacons-optionsRow">
              <div style={{ display: "inline-block" }} className="pagination-mobile">
                {i18n.t('total-records')}: {users.length}
              </div>

              <div style={{ display: "inline-block" }} className="pagination-mobile">
                <Button
                  icon="left chevron"
                  size="tiny"
                  onClick={() => this.onPreviousPageClick()}
                  disabled={DashboardState.isLoading}
                  style={{ color: "red", backgroundColor: "transparent" }}
                />
                {i18n.t('page')}:{" "}
                <Input
                  className="beacon-page-number"
                  // style={{width:'60px', height:'30.8px'}}
                  name="currentPage"
                  min={1}
                  type="number"
                  size="small"
                  disabled={DashboardState.isLoading}
                  onChange={this.onInputPageChange}
                  value={currentPage}
                  onBlur={() => this.onSetPageByBlur(nbOfPages)}
                  onKeyPress={(e) => this.keyPressed(e, nbOfPages)}
                />{" "}
                of{" " + nbOfPages + " "}
                <Button
                  icon="right chevron"
                  size="tiny"
                  onClick={() => this.onNextPageClick(nbOfPages)}
                  disabled={DashboardState.isLoading}
                  style={{ color: "red", backgroundColor: "transparent" }}
                />
              </div>

              <div style={{ display: "inline-block" }} className="pagination-mobile">
                {i18n.t('records-per-page')}:{" "}
                <Dropdown inline header="Adjust records per page" options={paginationOptions} defaultValue={paginationOptions[0].value} onChange={this.onChangeactivityPerPage} />
              </div>
            </Grid.Column>

            <Grid.Column width={7} textAlign="right">
              <Button onClick={onAddAssociatedUser} primary size="small">
                {i18n.t('add-participant')}
                <Icon name="plus" className="beacons-search-icon" />
              </Button>
              <Button basic onClick={this.onExpandSearch} size="small">
                {i18n.t('search-and-filter')}
                <Icon color="red" name={searchIconClass} className="beacons-search-icon" />
              </Button>
            </Grid.Column>
          </Grid.Row>
          {displayRow}
          <Grid.Row className="dashboard-beacons-gridRow-last">
            <Grid.Column width={11} />
            <Grid.Column width={5} textAlign="right">
              {i18n.t('click-a-participant-to-edit')}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <ContentTable items={pagedData} isLoading={isLoading} />
      </div>
    );
  }
}

class ContentTable extends Component {
  state = {
    editRow: null,
  };

  handleSort = (clickedColumn) => {
    DashboardState.handleSortUsers(clickedColumn);
  };

  render() {
    let { items, isLoading } = this.props;
    let { editRow } = this.state;

    const column = DashboardState.column;
    const direction = DashboardState.direction;

    return (
      <div>
        <Segment>
          <Dimmer active={isLoading} inverted>
            <Loader>Loading</Loader>
          </Dimmer>
          <div className="sidescroll">
            <Table celled striped selectable sortable className="beacon-mobile">
              <Table.Header className="contactList-tableHeader">
                <Table.Row>
                  <Table.HeaderCell
                    className="dashboard-beacon-tableHeader"
                    sorted={column === "beaconHolderName" ? direction : null}
                    onClick={() => this.handleSort("beaconHolderName")}
                  >
                    {i18n.t('beacon-holder')}
                  </Table.HeaderCell>
                  <Table.HeaderCell className="dashboard-beacon-tableHeader" sorted={column === "phoneNumber" ? direction : null} onClick={() => this.handleSort("phoneNumber")}>
                    {i18n.t('contact-phone-number')}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className="dashboard-beacon-tableHeader"
                    sorted={column === "primaryContactName" ? direction : null}
                    onClick={() => this.handleSort("primaryContactName")}
                  >
                    {i18n.t('emergency-contact-name')}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className="dashboard-beacon-tableHeader"
                    sorted={column === "primaryPhone1Num" ? direction : null}
                    onClick={() => this.handleSort("primaryPhone1Num")}
                  >
                    {i18n.t('emergency-contact-phone-number')}
                  </Table.HeaderCell>
                  {/* <Table.HeaderCell className="dashboard-beacon-tableHeader">Activity</Table.HeaderCell> */}
                  <Table.HeaderCell className="dashboard-beacon-tableHeader" sorted={column === "lastEditDate" ? direction : null} onClick={() => this.handleSort("lastEditDate")}>
                    {i18n.t('last-edit-date')}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body style={{ cursor: "initial" }}>
                {items.map((item) => {
                  console.log("===========>>>>>>> related activity: " + item._id);
                  let AddUser = (
                    <a className="font-blue" onClick={() => this.onAddRelated(item, 1)} style={{ cursor: "pointer" }}>
                      {item.firstName == "" && item.lastName == "" ? "" : item.firstName + " " + item.lastName}
                    </a>
                  );

                  // add beacon button
                  let AddBeacon = (
                    <a className="font-blue" onClick={() => this.onAddRelated(item, 2)} style={{ cursor: "pointer" }}>
                      +Add
                    </a>
                  );
                  if (item.relatedActivity) {
                    let hexId = item.relatedActivity.beaconId ? item.relatedActivity.beaconId.split("_")[1] : "";
                    AddBeacon = (
                      <>
                        <a className="font-blue" onClick={() => this.onAddRelated(item, 2)} style={{ cursor: "pointer" }}>
                          <b>{hexId}</b>
                        </a>
                      </>
                    );
                  }

                  // add aivity button
                  let AddActivity = (
                    <a className="font-blue" onClick={() => this.onAddRelated(item, 0)} style={{ cursor: "pointer" }}>
                      +Add
                    </a>
                  );
                  if (item.relatedActivity) {
                    let status = item.relatedActivity.duration ? item.relatedActivity.duration.charAt(0).toUpperCase() + item.relatedActivity.duration.slice(1) : "N/A";

                    let date =
                      item.relatedActivity.duration == "specific"
                        ? formatDateActivity(item.relatedActivity.durationSpecificStartDate) + " - " + formatDateActivity(item.relatedActivity.durationSpecificEndDate)
                        : "";

                    AddActivity = (
                      <>
                        <a onClick={() => this.onAddRelated(item, 0)} style={{ cursor: "pointer" }}>
                          <span className="font-blue">
                            {/* <b>{activityStatus(item.relatedActivity.durationSpecificStartDate, item.relatedActivity.durationSpecificEndDate)}</b> */}
                            <b>{status}</b>
                          </span>
                          <br />
                          <span style={{ color: "rgba(0,0,0,.87)" }}>{date}</span>
                        </a>
                      </>
                    );
                  }

                  return (
                    <TableRow key={item._id} item={item} onAddBeacon={AddBeacon} onAddActivity={AddActivity} onAddUser={AddUser} onCellClick={() => this.onAddRelated(item, 1)} />
                  );
                })}
              </Table.Body>
            </Table>
          </div>
          <EmptyStatePlaceHolder items={items} />
        </Segment>
      </div>
    );
  }

  onAddRelated = (item, gotoTab) => {
    AssociatedUserState.resetData();
    AssociatedUserState.resetSteps();

    let userData = {};
    userData = { ...userData, ...item };
    if (item._id == "") {
      userData.userGroup = "addNewUser";
    } else {
      userData.userGroup = "selectExistingUser";
      userData.selectUser = item._id;
    }
    AssociatedUserState.setUserFields(userData);
    RouteState.setRoute("/registerAssociatedUser");
  };
}

const EmptyStatePlaceHolder = ({ items }) => {
  if (items && items.length > 0) {
    return null;
  }

  return (
    <Header as="h2" icon textAlign="center">
      <Icon name="file outline" circular />
      <Header.Content>No user found</Header.Content>
    </Header>
  );
};

const TableRow = ({ item, onAddBeacon, onAddActivity, onAddUser, onCellClick }) => (
  <Table.Row>
    {isBrowser ? (
      <>
        <Table.Cell textAlign="left" className="dashboard-beacon-resultCell dashboard-beacon-resultCell-link" style={{ cursor: "pointer" }} onClick={onCellClick}>
          <strong>
            {item.firstName} {item.lastName}
          </strong>
        </Table.Cell>
        <Table.Cell className="dashboard-beacon-resultCell">{item.phoneNumber || ""}</Table.Cell>
        <Table.Cell className="dashboard-beacon-resultCell">{item.primaryContactName || ""}</Table.Cell>
        <Table.Cell className="dashboard-beacon-resultCell">{item.primaryPhone1Num || ""}</Table.Cell>
        <Table.Cell className="dashboard-beacon-resultCell">{Utils.dateFormatWithTime(item.lastEditDate) || ""}</Table.Cell>
      </>
    ) : (
      <>
        {(item.firstName || item.lastName) && (
          <Table.Cell
            textAlign="left"
            className="contactList-cell dashboard-beacon-resultCell dashboard-beacon-resultCell-link"
            style={{ cursor: "pointer" }}
            onClick={onCellClick}
          >
            <div className="contactList-cell-mobileHeader">Beacon Holder</div>
            <div className="contactList-cell-content">
              <strong>
                {item.firstName} {item.lastName}
              </strong>
            </div>
          </Table.Cell>
        )}

        {item.phoneNumber && (
          <Table.Cell className="contactList-cell dashboard-beacon-resultCell">
            <div className="contactList-cell-mobileHeader">Contact Phone Number</div>
            <div className="contactList-cell-content">{item.phoneNumber || ""}</div>
          </Table.Cell>
        )}

        {item.primaryContactName && (
          <Table.Cell className="contactList-cell dashboard-beacon-resultCell">
            <div className="contactList-cell-mobileHeader">Emergency Contact Name</div>
            <div className="contactList-cell-content">{item.primaryContactName || ""}</div>
          </Table.Cell>
        )}

        {item.primaryPhone1Num && (
          <Table.Cell className="contactList-cell dashboard-beacon-resultCell">
            <div className="contactList-cell-mobileHeader">Emergency Contact Phone Number</div>
            <div className="contactList-cell-content">{item.primaryPhone1Num || ""}</div>
          </Table.Cell>
        )}

        {item.lastEditDate && (
          <Table.Cell className="contactList-cell dashboard-beacon-resultCell">
            <div className="contactList-cell-mobileHeader">Last Edit Date</div>
            <div className="contactList-cell-content">{Utils.dateFormatWithTime(item.lastEditDate) || ""}</div>
          </Table.Cell>
        )}
      </>
    )}
  </Table.Row>
);

function formatDateActivity(strdate) {
  if (strdate == "") return "";
  var date = new Date(strdate);
  var dd = ("0" + date.getDate()).slice(-2);
  var mm = date.getMonth(); //January is 0!
  var yyyy = date.getFullYear();

  const months = [i18n.t('january'), i18n.t('february'),i18n.t('march'), i18n.t('april'), i18n.t('may'), i18n.t('june'), i18n.t('july'), i18n.t('august'), i18n.t('september'), i18n.t('october'), i18n.t('november'), i18n.t('december')];

  var MM = months[mm];
  return MM + " " + dd + ", " + yyyy;
}

function activityStatus(startDate, endDate) {
  // [TODO] manage if startDate, EndDate is empty
  if (startDate == "" || endDate == "") {
    return "N/A";
  }

  var dateNow = new Date();
  var dateStart = new Date(startDate);
  var dateEnd = new Date(endDate);
  var status = "";

  if (dateNow < dateStart && dateNow < dateEnd) {
    status = "Pending";
  } else if (dateNow >= dateStart && dateNow <= dateEnd) {
    status = "Ongoing";
  } else {
    status = "Completed";
  }

  return status;
}

function findLabelByValue(value, dataSet) {
  let option = dataSet.find((entry) => entry.value === value);
  return option.text;
}
