import i18n from "i18next";
import React, { Component } from "react";
import * as Yup from "yup";
import { Form, Input, Button, Popup, Select, Header, Message, Radio } from "semantic-ui-react";
import VehicleState from "../../../state/vehicleState";
import withSemanticUIFormik from "../../../utils/formHelper";
import ImageUploader from "../../../components/imageUpload";
import RouteState from "../../../state/routeState";
import ActivityState from "../../../state/activityState";

const typeOptions = [{ key: "m", text: "Male", value: "male" }, { key: "f", text: "Female", value: "female" }, { key: "o", text: "Other", value: "other" }];

class InnerForm extends Component {
  state = {
    onRequestEdit: false,
  };

  onRequestEdit = () => {
    // this.setState({
    // 	requestEdid: true,
    // })
  };

  onClose = () => {
    // if (this.props.onClose) {
    // 	this.props.onClose();
    // } else {
    VehicleState.setPreviousStep();
    // }
  };

  onSave = () => {
    VehicleState.updateVehicleFields(this.props.values);

    if (this.props.onSave) {
      this.props.onSave();
    } else {
      if (this.props.allowPreview) {
        VehicleState.setNextStep();
      } else {
        VehicleState.saveVehicle();
        VehicleState.setCurrentStep(VehicleState.getCurrentStep() + 2); //skip preview
      }
    }
  };

  saveAndClose = () => {
    if (ActivityState.isEditActivity) {
      ActivityState.setIsSaving(true);
      VehicleState.updateVehicleFields(this.props.values);
      ActivityState.saveActivity().then(() => {
        ActivityState.setIsSaving(false);
        RouteState.history.goBack();
      });
    }
  };

  saveToStateBeforeHandleChange = (e, data) => {
    console.log("log: InnerForm -> saveToStateBeforeHandleChange -> name, value", data.name, data.value);
    VehicleState.saveOnFieldChange(data.name, data.value);
    this.props.handleChange(e, data);
  };

  render() {
    const { values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, isValid, onClose } = this.props;

    const { requestEdit } = this.state;

    return (
      <Form className="activityDetail-form animated fadeIn faster">
        <Header as="h2" icon textAlign="center">
          <Header.Content>Comments and Image</Header.Content>
        </Header>

        <Form.TextArea label="Comments" placeholder="" name="comment" value={values.comment} onBlur={handleBlur} onChange={handleChange} disabled={!ActivityState.isEditable()} />

        <ImageUploader name="image1" value={values.image1} onChange={this.saveToStateBeforeHandleChange} disabled={!ActivityState.isEditable()} />
        <ImageUploader name="image2" value={values.image2} onChange={this.saveToStateBeforeHandleChange} disabled={!ActivityState.isEditable()} />

        <div className="activityDetail-submitContainer">
          <Button className="registerBeacon-submitButton" onClick={this.onClose}>
            Back
          </Button>
          <Button className="registerBeacon-submitButton" type="submit" primary onClick={handleSubmit}>
            Next
          </Button>

          {/* <Button className="registerBeacon-submitButton" type="submit" primary onClick={this.onSave}>Next</Button> */}
          {/* {
						ActivityState.isEditActivity ?
						<Button className="activityDetail-submitButton" primary onClick={this.saveAndClose} loading={ActivityState.isSaving} >
						Save & Close
						</Button>
						: ''
					} */}
        </div>
      </Form>
    );
  }
}

const VehicleCommentForm = withSemanticUIFormik({
  mapPropsToValues: ({ vehicle, allowPreview }) => {
    if (vehicle) {
      return {
        comment: vehicle.comment || "",
        image1: vehicle.image1 || "",
        image2: vehicle.image2 || "",
        allowPreview: allowPreview ? allowPreview : true,
      };
    } else {
      return {
        comment: "",
        image1: "",
        image2: "",
        allowPreview: allowPreview ? allowPreview : true,
      };
    }
  },
  validationSchema: Yup.object().shape({
    //comment: Yup.string().required('Comment is required!'),
  }),

  handleSubmit: (values, { setSubmitting, isValid, props }) => {
    console.log("log: values=====================@comment", values, isValid);
    VehicleState.updateVehicleFields(values);
    console.log("log: values=====================@comment2", VehicleState.getVehicleFields());

    // if(values.allowPreview) {
    // 	VehicleState.setNextStep();
    // }
    // else {
    // 	VehicleState.saveVehicle();
    // 	VehicleState.setCurrentStep(VehicleState.getCurrentStep()+2); //skip preview
    // }

    if (props.onSave) {
      props.onSave();
    } else {
      if (props.allowPreview) {
        VehicleState.setNextStep();
      } else {
        VehicleState.saveVehicle();
        VehicleState.setCurrentStep(VehicleState.getCurrentStep() + 2); //skip preview
      }
    }
  },
})(InnerForm);

export default VehicleCommentForm;
