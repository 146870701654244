import dbService from "./dbService";
import { insertOrUpdateDocument } from "./serviceUtils";
import generateHash from "random-hash";

let db;

export async function initIndexes() {
  db = dbService();
  let result = await createAssociatedUserSearchIndex();
  console.log("log: initIndexes -> createAssociatedUserSearchIndex", result);
}

async function createAssociatedUserSearchIndex() {
  try {
    db = dbService();
    var result = await db.createIndex({
      index: {
        fields: ["type", "owner", "beaconCountryCode", "firstName", "lastName"],
        name: "associatedUserSearchIndex",
        ddoc: "associatedUserSearchIndex",
        type: "json",
      },
    });
    return result;
  } catch (err) {
    console.error(err);
  }
}

export async function searchAssociatedUsers(criterias, offset, limit) {
  console.log("AssociatedUserService:searchAssociatedUsers - criterias", criterias);
  console.time("AssociatedUserService:searchAssociatedUsers")
  let selector = {
    type: "user",
    owner: { $gte: null },
    beaconCountryCode: { $gte: null },
    firstName: { $gte: null },
    lastName: { $gte: null },
  };

  if (criterias.owner) {
    selector["owner"] = criterias.owner;
  }
  if (criterias.beaconCountryCode) {
    selector["beaconCountryCode"] = criterias.beaconCountryCode;
  }
  if (criterias.firstName) {
    // selector["firstName"] = { $gte: criterias.firstName, $lte: criterias.firstName + "\uffff" };
    selector["firstName"] = { $regex: "(?i)" + criterias.firstName + "(?i)" };
  }
  if (criterias.lastName) {
    // selector["lastName"] = { $gte: criterias.lastName, $lte: criterias.lastName + "\uffff" };
    selector["lastName"] = { $regex: "(?i)" + criterias.lastName + "(?i)" };
  }

  console.log("AssociatedUserService:searchAssociatedUsers - selector", selector);

  try {
    db = dbService();
    var result = await db.find({
      selector: selector,
      use_index: "associatedUserSearchIndex",
      // limit: 500,
    });
    console.log("AssociatedUserService:searchAssociatedUsers - result", result);
    console.timeEnd("AssociatedUserService:searchAssociatedUsers")
    return result;
  } catch (err) {
    console.error("AssociatedUserService:searchAssociatedUsers - error", err);
    throw err;
  }
}

export async function saveAssociatedUser(user, ownerId, beaconCountryCode) {
  console.log('log ~ file: associatedUserService.js ~ line 75 ~ saveAssociatedUser ~ user, ownerId, beaconCountryCode', user, ownerId, beaconCountryCode);
  try {
    // debugger;
    const userInfo = {
      // modify
      _id: user.userGroup == "selectExistingUser" ? user.selectUser : "user_" + generateHash({ length: 16 }),
      owner: ownerId,
      type: "user",
      beaconCountryCode: beaconCountryCode || "",

      // same source form: tab-1
      title: user.title || "",
      lastName: user.lastName || "",
      firstName: user.firstName || "",
      userLink: user.userLink || "",
      operationLanguage: user.operationLanguage || "",
      medicalInfo: user.medicalInfo || "",
      address: user.address || "",
      city: user.city || "",
      province: user.province || "",
      mailCountry: user.mailCountry || "",
      // phonePrefix: user.phonePrefix || "",
      phoneNumber: user.phoneNumber || "",
      phoneType: user.phoneType || "",
      // phonePrefix2: user.phonePrefix2 || "",
      phoneNumber2: user.phoneNumber2 || "",
      phoneType2: user.phoneType2 || "",

      // tab-2
      primaryContactName: user.primaryContactName || "",
      primaryContactAddressLine1: user.primaryContactAddressLine1 || "",
      primaryContactAddressLine2: user.primaryContactAddressLine2 || "",
      primaryPhone1Num: user.primaryPhone1Num || "",
      primaryPhone1Type: user.primaryPhone1Type || "",
      primaryPhone2Num: user.primaryPhone2Num || "",
      primaryPhone2Type: user.primaryPhone2Type || "",
      primaryPhone3Num: user.primaryPhone3Num || "",
      primaryPhone3Type: user.primaryPhone3Type || "",
      primaryPhone4Num: user.primaryPhone4Num || "",
      primaryPhone4Type: user.primaryPhone4Type || "",

      alternateContactName: user.alternateContactName || "",
      alternateContactAddressLine1: user.alternateContactAddressLine1 || "",
      alternateContactAddressLine2: user.alternateContactAddressLine2 || "",
      alternatePhone1Num: user.alternatePhone1Num || "",
      alternatePhone1Type: user.alternatePhone1Type || "",
      alternatePhone2Num: user.alternatePhone2Num || "",
      alternatePhone2Type: user.alternatePhone2Type || "",
      alternatePhone3Num: user.alternatePhone3Num || "",
      alternatePhone3Type: user.alternatePhone3Type || "",
      alternatePhone4Num: user.alternatePhone4Num || "",
      alternatePhone4Type: user.alternatePhone4Type || "",

      phoneTypeOther: user.phoneTypeOther || '',
      phoneType2Other: user.phoneType2Other || '',
      primaryPhone1TypeOther: user.primaryPhone1TypeOther || "",
      primaryPhone2TypeOther: user.primaryPhone2TypeOther || "",
      primaryPhone3TypeOther: user.primaryPhone3TypeOther || "",
      primaryPhone4TypeOther: user.primaryPhone4TypeOther || "",
      alternatePhone1TypeOther: user.alternatePhone1TypeOther || "",
      alternatePhone2TypeOther: user.alternatePhone2TypeOther || "",
      alternatePhone3TypeOther: user.alternatePhone3TypeOther || "",
      alternatePhone4TypeOther: user.alternatePhone4TypeOther || "",
    };
    console.log('log: saveAssociatedUser -> userInfo', userInfo);
    db = dbService();
    let result = await insertOrUpdateDocument(userInfo, db);
    return result;
  } catch (err) {
    console.error("AssociatedUserService:saveAssociatedUser - error", err);
  }
}
