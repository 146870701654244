import React from 'react';
import Dimmer from 'semantic-ui-react/dist/es/modules/Dimmer/Dimmer';
import Loader from 'semantic-ui-react/dist/es/elements/Loader/Loader';

export const Loading = () => (
		<Dimmer active inverted>
				<Loader content='Downloading bundle...'/>
		</Dimmer>
)

export default Loading;
