import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./semantic/dist/semantic.min.css";
import { setConfig } from "react-hot-loader";
import AppState from "./state/appState";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import * as english from "./i18n/en.json";
import * as french from "./i18n/fr.json";
console.log("log ~ file: index.js ~ line 11 ~ english", english);
setConfig({
  ignoreSFC: true,
  pureRender: true,
});

i18n
  .use(LanguageDetector)
  .init({
    debug: true,
    fallbackLng: "en",
    resources: {
      en: { translation: english.default },
      fr: { translation: french.default },
    },
  })
  .then(function(t) {
    console.log("log ~ file: index.js ~ line 27 ~ t", t);
    AppState.t = t;
    // initialized and ready to go!

    i18n.changeLanguage(null, (err, t) => {
      if (err) return console.log(" index.js something went wrong loading", err);
      console.log("*********************  index.js current language:" + i18n.language);
      let shortFormLanguage;
      if (i18n.language.includes("en")) {
        shortFormLanguage = "en";
      } else if (i18n.language.includes("fr")) {
        shortFormLanguage = "fr";
      } else {
        shortFormLanguage = "en";
      }
      AppState.setLanguage(shortFormLanguage);
      ReactDOM.render(<App />, document.getElementById("root"));
    });
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

serviceWorker.register(); //Temporary disable service working while in dev mode.

var IBRD_CONFIG_DEV = {
  apikey: "Q909w75A2pdK5Rh6NrpJ6XzVpH8A8hccCblUNqqkV1v_",
  host: "d6b915a0-1aa4-42d7-b80c-3f5d3b59dc73-bluemix.cloudantnosqldb.appdomain.cloud",
  iam_apikey_description: "Auto-generated for key crn:v1:bluemix:public:cloudantnosqldb:ca-tor:a/9094160ed5244abe9359eabf56ccdd47:bf4295a2-4026-4a7d-a03f-0a627d0159ae:resource-key:393c2adc-7ad9-40d5-840b-2a0825cf02bb",
  iam_apikey_name: "replicationapi",
  iam_role_crn: "crn:v1:bluemix:public:iam::::serviceRole:Manager",
  iam_serviceid_crn: "crn:v1:bluemix:public:iam-identity::a/9094160ed5244abe9359eabf56ccdd47::serviceid:ServiceId-034b5ce6-c3d3-44d5-9e00-62302c944be3",
  password: "4252231a77efb85d3a2f34411f7e25dc",
  port: 443,
  url: "https://apikey-v2-v0o2vcinzssnq6crwyym0cse6iw1uoqpj87vnnqvee0:4252231a77efb85d3a2f34411f7e25dc@d6b915a0-1aa4-42d7-b80c-3f5d3b59dc73-bluemix.cloudantnosqldb.appdomain.cloud",
  username: "apikey-v2-v0o2vcinzssnq6crwyym0cse6iw1uoqpj87vnnqvee0",
  authActionsEndpoint: "https://srn99qz2n6.execute-api.us-east-1.amazonaws.com/default/authActions",
  sendMailEndpoint: "https://od8adksff4.execute-api.us-east-1.amazonaws.com/staging",
  sendMailContactUs: "https://iyanouw7ee.execute-api.us-east-1.amazonaws.com/default/ibrdsendmail",
  adminEmail: "dbadmin@406registration.com",
  //"baseUrl": "https://www.406registration.com",
  baseUrl: "http://ibrd-staging.s3-website-us-east-1.amazonaws.com",
  createUserEndpoint: "https://srn99qz2n6.execute-api.us-east-1.amazonaws.com/default/createUser",
  uploadFileEndpoint: "https://lig3q2ynk1.execute-api.us-east-1.amazonaws.com/dev/fileupload",
  env: "dev",
};

var IBRD_CONFIG_STAGING = {
  apikey: "Q909w75A2pdK5Rh6NrpJ6XzVpH8A8hccCblUNqqkV1v_",
  host: "d6b915a0-1aa4-42d7-b80c-3f5d3b59dc73-bluemix.cloudantnosqldb.appdomain.cloud",
  iam_apikey_description: "Auto-generated for key crn:v1:bluemix:public:cloudantnosqldb:ca-tor:a/9094160ed5244abe9359eabf56ccdd47:bf4295a2-4026-4a7d-a03f-0a627d0159ae:resource-key:393c2adc-7ad9-40d5-840b-2a0825cf02bb",
  iam_apikey_name: "replicationapi",
  iam_role_crn: "crn:v1:bluemix:public:iam::::serviceRole:Manager",
  iam_serviceid_crn: "crn:v1:bluemix:public:iam-identity::a/9094160ed5244abe9359eabf56ccdd47::serviceid:ServiceId-034b5ce6-c3d3-44d5-9e00-62302c944be3",
  password: "4252231a77efb85d3a2f34411f7e25dc",
  port: 443,
  url: "https://apikey-v2-v0o2vcinzssnq6crwyym0cse6iw1uoqpj87vnnqvee0:4252231a77efb85d3a2f34411f7e25dc@d6b915a0-1aa4-42d7-b80c-3f5d3b59dc73-bluemix.cloudantnosqldb.appdomain.cloud",
  username: "apikey-v2-v0o2vcinzssnq6crwyym0cse6iw1uoqpj87vnnqvee0",
  authActionsEndpoint: "https://qjd4xjvjae.execute-api.us-east-1.amazonaws.com/stage/createtoken",
  sendMailEndpoint: "https://od8adksff4.execute-api.us-east-1.amazonaws.com/staging",
  sendMailContactUs: "https://iyanouw7ee.execute-api.us-east-1.amazonaws.com/default/ibrdsendmail",
  adminEmail: "dbadmin@406registration.com",
  //"baseUrl": "https://www.406registration.com",
  baseUrl: "http://ibrd-staging.s3-website-us-east-1.amazonaws.com",
  createUserEndpoint: "https://qjd4xjvjae.execute-api.us-east-1.amazonaws.com/stage/createUser",
  uploadFileEndpoint: "https://lig3q2ynk1.execute-api.us-east-1.amazonaws.com/dev/fileupload",
  env: "staging",
};

var IBRD_CONFIG_PROD = {
  apikey: "Vz-HN1ci10_pjtZQieXzdY-gGPrUhGQZNE1UsRhZyO5q",
  host: "9d7dc2db-d37a-4b51-b43f-95c859631e4d-bluemix.cloudantnosqldb.appdomain.cloud",
  iam_apikey_description: "Auto-generated for key crn:v1:bluemix:public:cloudantnosqldb:ca-tor:a/9094160ed5244abe9359eabf56ccdd47:3170c5a7-8578-4d9f-8479-d608eb3c2dd2:resource-key:7af5d661-045e-4bb1-a1de-da7e6b011add",
  iam_apikey_name: "IBRD",
  iam_role_crn: "crn:v1:bluemix:public:iam::::serviceRole:Manager",
  iam_serviceid_crn: "crn:v1:bluemix:public:iam-identity::a/9094160ed5244abe9359eabf56ccdd47::serviceid:ServiceId-495cbcc7-4d3e-47b9-8325-997c46a017ff",
  password: "8ed39c79654bbf9d11b5f9ef77862a70",
  port: 443,
  url: "https://apikey-v2-aspia0bm6ock5n6dfjzkxz4e1weji8diig6sicwkbdp:8ed39c79654bbf9d11b5f9ef77862a70@9d7dc2db-d37a-4b51-b43f-95c859631e4d-bluemix.cloudantnosqldb.appdomain.cloud",
  username: "apikey-v2-aspia0bm6ock5n6dfjzkxz4e1weji8diig6sicwkbdp",
  authActionsEndpoint: "https://h23sa254z9.execute-api.us-east-1.amazonaws.com/production/createtoken",
  sendMailEndpoint: "https://od8adksff4.execute-api.us-east-1.amazonaws.com/staging",
  sendMailContactUs: "https://iyanouw7ee.execute-api.us-east-1.amazonaws.com/default/ibrdsendmail",
  adminEmail: "dbadmin@406registration.com",
  //"baseUrl": "https://www.406registration.com",
  baseUrl: "http://ibrd-staging.s3-website-us-east-1.amazonaws.com",
  createUserEndpoint: "https://h23sa254z9.execute-api.us-east-1.amazonaws.com/production/createUser",
  uploadFileEndpoint: "https://lig3q2ynk1.execute-api.us-east-1.amazonaws.com/dev/fileupload",
  env: "prod",
};

let appUrl = window.location.origin;

var IBRD_CONFIG = IBRD_CONFIG_PROD;

if (appUrl.includes("staging")) {
  IBRD_CONFIG = IBRD_CONFIG_STAGING;
} else if (appUrl.includes("dev")) {
  IBRD_CONFIG = IBRD_CONFIG_DEV;
} else if (appUrl.includes("localhost")) {
  IBRD_CONFIG = IBRD_CONFIG_STAGING;
  console.warn("log ~ file: index.js ~ line 129 ~ appUrl LOCALHOST PLEASE MADE SURE YOU SELECTED DESIRED ENVIRONEMENT", IBRD_CONFIG);
}

window.IBRD_CONFIG = IBRD_CONFIG;
console.warn("log ~ file: index.js ~ line 134 ~ window.IBRD_CONFIG", window.IBRD_CONFIG.env);
