import React, { Component } from "react";
import { observer } from "mobx-react";
import { Container, Header, Grid, Divider, Button, Icon, Table, GridRow, Image, Modal, Dimmer, Loader, Segment } from "semantic-ui-react";
import TopMenu from "../../../components/topMenu";
import Footer from "../../../components/footer";
import RegistrationState from "../../../state/registrationState";
import AppConfigState from "../../../state/appConfigState";
import RouteState from "../../../state/routeState";
import ReactToPrint from "react-to-print";
import * as FileSaver from "file-saver";
import UserState from "../../../state/userState";
import ActivityState from "../../../state/activityState";
import ActivityWidget from "../../registerActivity/activity/ActivityWidget";
import DashboardState from "../../../state/dashboardState";
import OwnerHistoryWidget from "./ownerHistoryWidget";
import * as Utils from "../../../utils/utils";
import i18n from "i18next";
let showActivityByRole = ["4", "3"];

@observer
export default class beaconReviewDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      selectedBeaconVersion: null
    };
  }

  state = {
    isSaving: false
  };

  componentDidMount = async () => {
    if (!RegistrationState.isNewRegisterBeacon) {
      var topPosition = document.getElementById("editBeaconModal");
      topPosition.scrollIntoView();
    } else {
      window.scrollTo({ top: 0 });
    }

    let decodedBeacon = RegistrationState.decodedBeacon;
    if (DashboardState.selectedBeaconHistory) {
      decodedBeacon = DashboardState.selectedBeaconHistory;
    }
    // DashboardState.getTotalBeaconsForUser(decodedBeacon.owner);
    let username = decodedBeacon.owner ? (decodedBeacon.owner.includes("org.couchdb.user:") ? decodedBeacon.owner.split(":")[1] : decodedBeacon.owner) : null;
    UserState.getUserProfileById(decodedBeacon.owner);
    // DashboardState.getTotalBeaconsByOwner({ ownerUsername: [username] });
    DashboardState.getTotalBeaconsByOwner({ ownerUsername: username });

    // get owner history
    // DashboardState.getBeaconHistory(decodedBeacon._id);
    DashboardState.getAccountOwnerHistory(decodedBeacon._id);

    console.log("========> DashboardState.accountOwnerHistory : ", DashboardState.accountOwnerHistory);

    // get maintProvider
    if (UserState.currentUserProfile.roleId === "4" && decodedBeacon.specialStatus === "RELEASETOMAINTENANCE") {
      let maintProviderInfo = await UserState.getMaintProviderProfile(decodedBeacon.maintProvider);
    }

    // get account owner detail
    let beacon = RegistrationState.decodedBeacon;
    UserState.getUserProfileById(beacon.owner);

    if (RegistrationState.isPrintCertificate) {
      try {
        window.gtag("event", "profmgmt_success", {
          hexId: RegistrationState.decodedBeacon.hexId
        });
      } catch (error) {
        console.log("log ~ UserState ~ login ~ gtag error:", error);
      }
    }
  };

  onOpenModal = () => {
    this.setState({
      showModal: true
    });
  };
  onCloseModal = () => {
    this.setState({
      isSaving: false,
      showModal: false
    });
  };

  onEditBeaconClick = () => {
    RegistrationState.setCurrentStep(0);
    RegistrationState.setBeaconCurrentStep(0);
  };

  onEditOwnerClick = () => {
    RegistrationState.setCurrentStep(1);
    RegistrationState.setOwnerStep(0);
  };

  onEditVehicleClick = () => {
    RegistrationState.setCurrentStep(3);
    RegistrationState.setVehiculeStep(0);
  };

  onBackClick = () => {
    RegistrationState.setPreviousStep();
  };

  onSaveClick = () => {
    this.setState({ isSaving: true });
    delete RegistrationState.decodedBeacon.dontHaveEmail; // don't save this to database
    // RegistrationState.setNextStep();
    RegistrationState.saveBeacon().then(() => {
      this.setState({ isSaving: false });
      RegistrationState.setShowCompleteScreen(true);
      RegistrationState.setIsBeaconSaved(true);

      if (!RegistrationState.isNewRegisterBeacon) {
        var topPosition = document.getElementById("editBeaconModal");
        topPosition.scrollIntoView();
      } else {
        window.scrollTo({ top: 0 });
      }
    });
  };

  returnToDashboardClick = () => {
    RegistrationState.resetBeaconData();
    RegistrationState.resetSteps();
    RouteState.setRoute("/dashboard");
  };

  registerNewBeaconClick = () => {
    RegistrationState.resetBeaconData();
    RegistrationState.resetSteps();
    RouteState.setRoute("/newBeacon");
  };

  // onViewAllBeaconsOfOwner = () => {
  //   const { onClose, onSearchClick, clearFiltersForUser } = this.props;
  //   let decodedBeacon = RegistrationState.decodedBeacon;
  //   // let username = decodedBeacon.owner ? decodedBeacon.owner.split(":")[1] : "";
  //   let username = decodedBeacon.owner ? (decodedBeacon.owner.includes(":") ? decodedBeacon.owner.split(":")[1] : decodedBeacon.owner) : "";
  //   console.log('>>>>> username: ', username);
  //   DashboardState.setFilterUsername(username);
  //   onClose();
  //   clearFiltersForUser();
  //   // onSearchClick();
  // };

  onViewAllBeaconsOfOwner = () => {
    const { beacon, onClose, onUpdateSearch } = this.props;
    console.log("log ~ file: beaconReviewDetails.js ~ line 136 ~ beaconReviewDetails ~ beacon", beacon);
    let username = beacon.owner ? (beacon.owner.includes("org.couchdb.user:") ? beacon.owner.split(":")[1] : beacon.owner) : null;
    DashboardState.setSpecialQuery(true);
    DashboardState.searchBeacons({ ownerUsername: [username] });
    // DashboardState.searchBeacons({accountOwnerName: [beacon.accountOwnerName]})
    onClose();
    onUpdateSearch(beacon.owner);
  };

  onExportClick = () => {
    RegistrationState.updateBeaconLastEditDate();
    let contentTable = document.getElementsByClassName("print-section");
    let html = contentTable.item(0).innerHTML;
    let blob = new Blob([html], { type: "text/html;charset=" + document.characterSet });
    FileSaver.saveAs(blob, "beacon-certificate.html");
  };

  onEditingClose = () => {
    const { onClose, onSearchClick } = this.props;
    onClose();
    onSearchClick();
    RegistrationState.resetBeaconData();
    RegistrationState.resetSteps();
  };

  render() {
    const { showModal } = this.state;
    const { onClose, onDashboardEdit, onSearchClick, clearFiltersForUser, onAdminBeaconClick } = this.props;

    let decodedBeacon = RegistrationState.decodedBeacon;
    console.log("log: beaconReviewDetails -> render -> decodedBeacon", decodedBeacon);

    if (DashboardState.selectedBeaconHistory) {
      decodedBeacon = DashboardState.selectedBeaconHistory;
    }

    let beaconType = decodedBeacon.beaconType;
    // let beaconType = 'EPIRB'

    let canEditFields = true;
    canEditFields = !RegistrationState.isPrintCertificate;
    if (UserState.notAllowToEditByRoleId.includes(UserState.currentUserProfile.roleId)) {
      canEditFields = false;
    }

    let roleIdForBriefCertificate = UserState.roleIdForBriefCertificate;
    let isBriefCerUser = roleIdForBriefCertificate.includes(UserState.currentUserProfile.roleId) ? true : false;
    console.log("log: beaconReviewDetails -> render -> isBriefCerUser", isBriefCerUser);

    let accountDetail = UserState.accountDetail;
    showActivityByRole.includes(UserState.currentUserProfile.roleId) && RegistrationState.isPrintSummary;
    console.log("log: beaconReviewDetails -> render -> showActivityByRole.includes(UserState.currentUserProfile.roleId) && RegistrationState.isPrintSummar", showActivityByRole.includes(UserState.currentUserProfile.roleId), RegistrationState.isPrintSummary);

    return (
      <>
        <Container className="beaconReviewDetails-mainFormContainer2 animated fadeIn faster" style={{ display: RegistrationState.showCompleteScreen ? "none" : "block" }}>
          <div ref={(el) => (this.componentRefSummary = el)}>
            <Image src="/images/topmenu_406logo.png" style={{ display: !RegistrationState.showCompleteScreen ? "none" : "block" }} />
            <div className="print-only">
              <h2>{i18n.t("certificate-of-406-mhz-beacon-registration")}</h2>
            </div>

            <Header as="h2" className="beaconReviewDetails-header">
              <Header.Content>Summary </Header.Content>
              {!RegistrationState.isPrintCertificate && (
                <Header.Subheader>
                  {i18n.t("please-verify-that-the-information-provided-is-complete-and-correct-and-click-save-below-to-finish-registering-your-beacon-with-uin")} {decodedBeacon.hexId}
                </Header.Subheader>
              )}
            </Header>
            {/* {showActivityByRole.includes(UserState.currentUserProfile.roleId) && RegistrationState.isPrintSummary && DashboardState.totalBeaconsForUser > 1 && (
              <>
                <div style={{ marginBottom: "10px" }}>
                  <a onClick={this.onViewAllBeaconsOfOwner} className="beaconReviewDetails-Link noprint">
                    The user has {DashboardState.totalBeaconsForUser} beacons registered. Click here to view all beacons.
                  </a>
                </div>
              </>
            )} */}
            {showActivityByRole.includes(UserState.currentUserProfile.roleId) && RegistrationState.isPrintSummary ? (
              !accountDetail || DashboardState.isLoading ? (
                <Dimmer active={DashboardState.isLoading} inverted>
                  <Loader>Loading</Loader>
                </Dimmer>
              ) : (
                <div style={{ marginBottom: "10px" }}>
                  <a onClick={this.onViewAllBeaconsOfOwner} className="beaconReviewDetails-Link noprint">
                    {i18n.t("account-owner")} <b>{accountDetail.ownerName || ""}</b> {i18n.t("owns")} <b>{DashboardState.totalBeaconsForUser}</b> {i18n.t("beacons-click-here-to-view-all-owned-beacons")}
                  </a>
                </div>
              )
            ) : (
              ""
            )}
            {showActivityByRole.includes(UserState.currentUserProfile.roleId) && RegistrationState.isPrintSummary && (
              <>
                <ActivityWidget key={decodedBeacon.hexId} owner={decodedBeacon.owner} hexId={decodedBeacon.hexId} isOngoing={true} title="Current Activity" />
              </>
            )}
            <Grid columns={2} className="beaconReviewDetails-grid" stackable>
              <Grid.Row>
                <Grid.Column>
                  <Header>
                    {i18n.t("beacon")}
                    {canEditFields && (
                      <>
                        <span className="noprint">|</span>{" "}
                        <a onClick={this.onEditBeaconClick} className="beaconReviewDetails-editLink noprint">
                          {i18n.t("edit")}
                        </a>
                      </>
                    )}
                  </Header>
                  <div>
                    {i18n.t("beacon-type")}: <strong>{decodedBeacon.beaconType}</strong>
                  </div>
                  <div>
                    {i18n.t("hex-id")}: <strong>{decodedBeacon.hexId}</strong>
                  </div>
                  <div>
                    {i18n.t("country")}: <strong>{decodedBeacon.beaconCountryCode + " - " + AppConfigState.getCountryNameByMid(decodedBeacon.beaconCountryCode)}</strong>
                  </div>
                  {decodedBeacon.beaconManufacturer && (
                    <div>
                      {i18n.t("beacon-manufacturer")}:{" "}
                      <strong>
                        {manufacturerToText(decodedBeacon.beaconManufacturer)}
                        {decodedBeacon.beaconManufacturerOther ? " (" + decodedBeacon.beaconManufacturerOther + ")" : ""}
                      </strong>
                    </div>
                  )}

                  {decodedBeacon.serialNumber && (
                    <div>
                      <b>{i18n.t("serial-number")}:</b> {decodedBeacon.serialNumber || ""}
                    </div>
                  )}

                  {decodedBeacon.beaconModel && (
                    <div>
                      {i18n.t("model-name")}: <strong>{decodedBeacon.beaconModel}</strong>
                    </div>
                  )}

                  {decodedBeacon.typeApprovalCertificate && (
                    <div>
                      {i18n.t("c-s-type-approval-number")}: <strong>{decodedBeacon.typeApprovalCertificate}</strong>
                    </div>
                  )}

                  {(decodedBeacon.beaconHomingDevice || decodedBeacon.beaconHomingDeviceOther) && (
                    <div>
                      {i18n.t("beacon-homing-device")}:{" "}
                      <strong>
                        {decodedBeacon.beaconHomingDevice}
                        {decodedBeacon.beaconHomingDeviceOther ? " (" + decodedBeacon.beaconHomingDeviceOther + ")" : ""}
                      </strong>
                    </div>
                  )}

                  {decodedBeacon.beaconActivationMethod && (
                    <div>
                      {i18n.t("activation-method")}: <strong>{activationMedthodToText(decodedBeacon.beaconActivationMethod)}</strong>
                    </div>
                  )}

                  {decodedBeacon.additionalBeaconData && (
                    <div>
                      {i18n.t("additional-beacon-information")}: <strong>{decodedBeacon.additionalBeaconData}</strong>
                    </div>
                  )}

                  <br />
                  {decodedBeacon.initialDate && (
                    <div>
                      {i18n.t("initial-registration-date")}: <strong>{Utils.dateFormatWithTime(decodedBeacon.initialDate, true) || ""}</strong>
                    </div>
                  )}
                  {decodedBeacon.lastEditDate && (
                    <div>
                      {i18n.t("last-edit-date")}: <strong>{Utils.dateFormatWithTime(decodedBeacon.lastEditDate, true) || ""}</strong>
                    </div>
                  )}
                  {decodedBeacon.specialStatus && (
                    <div>
                      {i18n.t("special-status")}: <strong>{specialStatusToText(decodedBeacon.specialStatus, true) || "Active"}</strong>
                    </div>
                  )}

                  {["1", "3"].includes(UserState.currentUserProfile.roleId) && decodedBeacon.specialStatusInfo && (
                    <div>
                      {i18n.t("special-status-info")}: <strong>{decodedBeacon.specialStatusInfo || ""}</strong>
                    </div>
                  )}

                  {decodedBeacon.specialStatusDate && (
                    <div>
                      {i18n.t("special-status-date")}: <strong>{Utils.dateFormatWithTime(decodedBeacon.specialStatusDate, true) || ""}</strong>
                    </div>
                  )}
                  {decodedBeacon.lastConfirmationDate && (
                    <div>
                      {i18n.t("last-confirmation-date")}: <strong>{Utils.dateFormatWithTime(decodedBeacon.lastConfirmationDate, true) || ""}</strong>
                    </div>
                  )}

                  {!isBriefCerUser && (
                    <Header>
                      {i18n.t("beacon-owner")}
                      {canEditFields && (
                        <>
                          <span className="noprint">|</span>{" "}
                          <a onClick={this.onEditOwnerClick} className="beaconReviewDetails-editLink noprint">
                            {i18n.t("edit")}
                          </a>
                        </>
                      )}
                    </Header>
                  )}
                  {!isBriefCerUser && (
                    <>
                      {decodedBeacon.ownerName && (
                        <div>
                          {i18n.t("name")}: <strong>{decodedBeacon.ownerName}</strong>
                        </div>
                      )}

                      {decodedBeacon.emailAddress && (
                        <div>
                          {i18n.t("email")}: <strong>{decodedBeacon.emailAddress || ""}</strong>
                        </div>
                      )}

                      {decodedBeacon.address && (
                        <div>
                          {i18n.t("address")}: <strong>{decodedBeacon.address || ""}</strong>
                        </div>
                      )}

                      {decodedBeacon.mailCode && (
                        <div>
                          {i18n.t("zip-postal-code-2")}: <strong>{decodedBeacon.mailCode || ""}</strong>
                        </div>
                      )}

                      {decodedBeacon.city && (
                        <div>
                          {i18n.t("city")}: <strong>{decodedBeacon.city || ""}</strong>
                        </div>
                      )}

                      {decodedBeacon.province && (
                        <div>
                          {i18n.t("state-province")}: <strong>{decodedBeacon.province || ""}</strong>
                        </div>
                      )}

                      {decodedBeacon.mailCountry && (
                        <div>
                          {i18n.t("country")}: <strong>{decodedBeacon.mailCountry || ""}</strong>
                        </div>
                      )}

                      {decodedBeacon.medicalInfo && (
                        <div>
                          {i18n.t("medical-information")}: <strong>{decodedBeacon.medicalInfo || ""}</strong>
                        </div>
                      )}

                      {decodedBeacon.operatorLanguage && (
                        <div>
                          {i18n.t("communication-language")}: <strong>{decodedBeacon.operatorLanguage || ""}</strong>
                        </div>
                      )}

                      {decodedBeacon.phone1Num && decodedBeacon.phone1Num != "" ? (
                        <div>
                          {i18n.t("beacon-owner-telephone-number-1")}:{" "}
                          <strong>
                            {decodedBeacon.phone1Num}
                            {decodedBeacon.phone1Type ? " (" + phoneTypeToText(decodedBeacon.phone1Type, decodedBeacon.phone1TypeOther) + ")" : ""}
                          </strong>
                        </div>
                      ) : (
                        ""
                      )}
                      {decodedBeacon.phone2Num && decodedBeacon.phone2Num != "" ? (
                        <div>
                          {i18n.t("beacon-owner-telephone-number-2")}:{" "}
                          <strong>
                            {decodedBeacon.phone2Num}
                            {decodedBeacon.phone2Type ? " (" + phoneTypeToText(decodedBeacon.phone2Type, decodedBeacon.phone2TypeOther) + ")" : ""}
                          </strong>
                        </div>
                      ) : (
                        ""
                      )}
                      {decodedBeacon.phone3Num && decodedBeacon.phone3Num != "" ? (
                        <div>
                          {i18n.t("beacon-owner-telephone-number-3")}:{" "}
                          <strong>
                            {decodedBeacon.phone3Num}
                            {decodedBeacon.phone3Type ? " (" + phoneTypeToText(decodedBeacon.phone3Type, decodedBeacon.phone3TypeOther) + ")" : ""}
                          </strong>
                        </div>
                      ) : (
                        ""
                      )}
                      {decodedBeacon.phone4Num && decodedBeacon.phone4Num != "" ? (
                        <div>
                          {i18n.t("beacon-owner-telephone-number-4")}:{" "}
                          <strong>
                            {decodedBeacon.phone4Num}
                            {decodedBeacon.phone4Type ? " (" + phoneTypeToText(decodedBeacon.phone4Type, decodedBeacon.phone4TypeOther) + ")" : ""}
                          </strong>
                        </div>
                      ) : (
                        ""
                      )}
                      <br />

                      {decodedBeacon.primaryContactName && (
                        <div>
                          {i18n.t("24-hour-emergency-contact-name")}: <strong>{decodedBeacon.primaryContactName}</strong>
                        </div>
                      )}

                      {decodedBeacon.primaryContactAddressLine1 && (
                        <div>
                          {i18n.t("emergency-contact-address-line-1")}: <strong>{decodedBeacon.primaryContactAddressLine1}</strong>
                        </div>
                      )}

                      {decodedBeacon.primaryContactAddressLine2 && (
                        <div>
                          {i18n.t("emergency-contact-address-line-2")}: <strong>{decodedBeacon.primaryContactAddressLine2}</strong>
                        </div>
                      )}

                      {decodedBeacon.primaryPhone1Num && decodedBeacon.primaryPhone1Num != "" ? (
                        <div>
                          {i18n.t("emergency-contact-phone-number-1")}:{" "}
                          <strong>
                            {decodedBeacon.primaryPhone1Num}
                            {decodedBeacon.primaryPhone1Type ? " (" + phoneTypeToText(decodedBeacon.primaryPhone1Type, decodedBeacon.primaryPhone1TypeOther) + ")" : ""}
                          </strong>
                        </div>
                      ) : (
                        ""
                      )}
                      {decodedBeacon.primaryPhone2Num && decodedBeacon.primaryPhone2Num != "" ? (
                        <div>
                          {i18n.t("emergency-contact-phone-number-2")}:{" "}
                          <strong>
                            {decodedBeacon.primaryPhone2Num}
                            {decodedBeacon.primaryPhone2Type ? " (" + phoneTypeToText(decodedBeacon.primaryPhone2Type, decodedBeacon.primaryPhone2TypeOther) + ")" : ""}
                          </strong>
                        </div>
                      ) : (
                        ""
                      )}
                      {decodedBeacon.primaryPhone3Num && decodedBeacon.primaryPhone3Num != "" ? (
                        <div>
                          {i18n.t("emergency-contact-phone-number-3")}:{" "}
                          <strong>
                            {decodedBeacon.primaryPhone3Num}
                            {decodedBeacon.primaryPhone3Type ? " (" + phoneTypeToText(decodedBeacon.primaryPhone3Type, decodedBeacon.primaryPhone3TypeOther) + ")" : ""}
                          </strong>
                        </div>
                      ) : (
                        ""
                      )}
                      {decodedBeacon.primaryPhone4Num && decodedBeacon.primaryPhone4Num != "" ? (
                        <div>
                          {i18n.t("emergency-contact-phone-number-4")}:{" "}
                          <strong>
                            {decodedBeacon.primaryPhone4Num}
                            {decodedBeacon.primaryPhone4Type ? " (" + phoneTypeToText(decodedBeacon.primaryPhone4Type, decodedBeacon.primaryPhone4TypeOther) + ")" : ""}
                          </strong>
                        </div>
                      ) : (
                        ""
                      )}

                      {decodedBeacon.alternateContactName && (
                        <div>
                          {i18n.t("alternate-24-hour-emergency-contact-name")}: <strong>{decodedBeacon.alternateContactName}</strong>
                        </div>
                      )}
                      {decodedBeacon.alternateContactAddressLine1 && (
                        <div>
                          {i18n.t("alternate-contact-address-line-1")}: <strong>{decodedBeacon.alternateContactAddressLine1}</strong>
                        </div>
                      )}
                      {decodedBeacon.alternateContactAddressLine2 && (
                        <div>
                          {i18n.t("alternate-contact-address-line-2")}: <strong>{decodedBeacon.alternateContactAddressLine2}</strong>
                        </div>
                      )}

                      {decodedBeacon.alternatePhone1Num && decodedBeacon.alternatePhone1Num != "" ? (
                        <div>
                          {i18n.t("alternate-contact-phone-number-1")}:{" "}
                          <strong>
                            {decodedBeacon.alternatePhone1Num}
                            {decodedBeacon.alternatePhone1Type ? " (" + phoneTypeToText(decodedBeacon.alternatePhone1Type, decodedBeacon.alternatePhone1TypeOther) + ")" : ""}
                          </strong>
                        </div>
                      ) : (
                        ""
                      )}
                      {decodedBeacon.alternatePhone2Num && decodedBeacon.alternatePhone2Num != "" ? (
                        <div>
                          {i18n.t("alternate-contact-phone-number-2")}:{" "}
                          <strong>
                            {decodedBeacon.alternatePhone2Num}
                            {decodedBeacon.alternatePhone2Type ? " (" + phoneTypeToText(decodedBeacon.alternatePhone2Type, decodedBeacon.alternatePhone2TypeOther) + ")" : ""}
                          </strong>
                        </div>
                      ) : (
                        ""
                      )}
                      {decodedBeacon.alternatePhone3Num && decodedBeacon.alternatePhone3Num != "" ? (
                        <div>
                          {i18n.t("alternate-contact-phone-number-3")}:{" "}
                          <strong>
                            {decodedBeacon.alternatePhone3Num}
                            {decodedBeacon.alternatePhone3Type ? " (" + phoneTypeToText(decodedBeacon.alternatePhone3Type, decodedBeacon.alternatePhone3TypeOther) + ")" : ""}
                          </strong>
                        </div>
                      ) : (
                        ""
                      )}
                      {decodedBeacon.alternatePhone4Num && decodedBeacon.alternatePhone4Num != "" ? (
                        <div>
                          {i18n.t("alternate-contact-phone-number-4")}:{" "}
                          <strong>
                            {decodedBeacon.alternatePhone4Num}
                            {decodedBeacon.alternatePhone4Type ? " (" + phoneTypeToText(decodedBeacon.alternatePhone4Type, decodedBeacon.alternatePhone4TypeOther) + ")" : ""}
                          </strong>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}

                  {/* display maintProvider info */}
                  {UserState.currentUserProfile.roleId === "4" && decodedBeacon.specialStatus === "RELEASETOMAINTENANCE" && UserState.maintProviderInfo && (
                    <div style={{ marginTop: "30px" }}>
                      <Header>{i18n.t("maintenance-provider")}</Header>
                      <div>
                        {i18n.t("name")}: <strong>{UserState.maintProviderInfo.ownerName || ""}</strong>
                      </div>
                      <div>
                        {i18n.t("email")}: <strong>{UserState.maintProviderInfo.emailAddress || ""}</strong>
                      </div>
                      <div>
                        {i18n.t("address")}: <strong>{UserState.maintProviderInfo.address || ""}</strong>
                      </div>
                      <div>
                        {i18n.t("zip-postal-code-2")}: <strong>{UserState.maintProviderInfo.mailCode || ""}</strong>
                      </div>
                      <div>
                        {i18n.t("city")}: <strong>{UserState.maintProviderInfo.city || ""}</strong>
                      </div>
                      <div>
                        {i18n.t("state-province")}: <strong>{UserState.maintProviderInfo.province || ""}</strong>
                      </div>
                      <div>
                        {i18n.t("country")}: <strong>{UserState.maintProviderInfo.mailCountry || ""}</strong>
                      </div>
                      <div>
                        {i18n.t("communication-language")}: <strong>{UserState.maintProviderInfo.operatorLanguage || ""}</strong>
                      </div>
                      <div>
                        {i18n.t("beacon-owner-telephone-number-1")}: <strong>{UserState.maintProviderInfo.phone1Num || ""}</strong>
                      </div>
                      <div>
                        {i18n.t("beacon-owner-telephone-number-2")}: <strong>{UserState.maintProviderInfo.phone2Num || ""}</strong>
                      </div>
                    </div>
                  )}
                </Grid.Column>

                <Grid.Column>
                  <Header>
                    {i18n.t("vehicle")}
                    {canEditFields && (
                      <>
                        <span className="noprint">|</span>{" "}
                        <a onClick={this.onEditVehicleClick} className="beaconReviewDetails-editLink noprint">
                          {i18n.t("edit")}
                        </a>
                      </>
                    )}
                  </Header>
                  {beaconType.includes("ELT") ? (
                    <>
                      {decodedBeacon.vehicleType && (
                        <div>
                          {i18n.t("vehicle-type")}:{" "}
                          <strong>
                            {decodedBeacon.vehicleType || ""}
                            {decodedBeacon.vehicleType == "Other" ? " (" + decodedBeacon.vehicleTypeOther + ")" : ""}
                          </strong>
                        </div>
                      )}

                      {decodedBeacon.aircraft24BitAddress && decodedBeacon.aircraft24BitAddress != "" ? (
                        <div>
                          {i18n.t("aircraft-24-bit-address")}: <strong>{decodedBeacon.aircraft24BitAddress}</strong>
                        </div>
                      ) : (
                        ""
                      )}

                      {decodedBeacon.aircraft24BitAddressDecoded && decodedBeacon.aircraft24BitAddressDecoded != "" ? (
                        <div>
                          {i18n.t("aircraft-24-bit-address-decoded")}: <strong>{decodedBeacon.aircraft24BitAddressDecoded}</strong>
                        </div>
                      ) : (
                        ""
                      )}

                      {decodedBeacon.vehicleRegistrationNumber && (
                        <div>
                          {i18n.t("vehicle-registration-number")}: <strong>{decodedBeacon.vehicleRegistrationNumber || ""}</strong>
                        </div>
                      )}

                      {decodedBeacon.homeICAOCode && (
                        <div>
                          {i18n.t("home-icao-code")}: <strong>{decodedBeacon.homeICAOCode || ""}</strong>
                        </div>
                      )}

                      {(decodedBeacon.aircraftManufacturer || decodedBeacon.vehicleManufacturer) && (
                        <div>
                          {i18n.t("aircraft-manufacturer")}: <strong>{decodedBeacon.aircraftManufacturer || decodedBeacon.vehicleManufacturer || ""}</strong>
                        </div>
                      )}

                      {(decodedBeacon.aircraftModel || decodedBeacon.vehicleModel) && (
                        <div>
                          {i18n.t("aircraft-model")}: <strong>{decodedBeacon.aircraftModel || decodedBeacon.vehicleModel || ""}</strong>
                        </div>
                      )}

                      {(decodedBeacon.color || decodedBeacon.aircraftColor) && (
                        <div>
                          {i18n.t("aircraft-colour")}: <strong>{decodedBeacon.aircraftColor || decodedBeacon.color || ""}</strong>
                        </div>
                      )}

                      {decodedBeacon.aircraftOperatingAgency && (
                        <div>
                          {i18n.t("aircraft-agency")}: <strong>{decodedBeacon.aircraftOperatingAgency || ""}</strong>
                        </div>
                      )}

                      {decodedBeacon.aircraftOperatingAgencyPhone && (
                        <div>
                          {i18n.t("aircraft-agency-phone")}: <strong>{decodedBeacon.aircraftOperatingAgencyPhone || ""}</strong>
                        </div>
                      )}
                      {decodedBeacon.radioEquipment && (
                        <div>
                          {i18n.t("radio-equipment")}: <strong>{decodedBeacon.radioEquipment || ""}</strong>
                        </div>
                      )}
                      {decodedBeacon.radioEquipmentOther && (
                        <div>
                          {i18n.t("radio-equipment-other")}: <strong>{decodedBeacon.radioEquipmentOther || ""}</strong>
                        </div>
                      )}
                      <br />
                      {decodedBeacon.deployableSurvivalCrafts && (
                        <div>
                          {i18n.t("deployable-survival-crafts")}: <strong>{decodedBeacon.deployableSurvivalCrafts || ""}</strong>
                        </div>
                      )}
                      {decodedBeacon.fixedSurvivalCrafts && (
                        <div>
                          {i18n.t("fixed-survival-crafts")}: <strong>{decodedBeacon.fixedSurvivalCrafts || ""}</strong>
                        </div>
                      )}

                      {decodedBeacon.maxEndurance && (
                        <div>
                          {i18n.t("max-endurance")}: <strong>{decodedBeacon.maxEndurance || ""}</strong>
                        </div>
                      )}
                      {decodedBeacon.cruiseAirSpeed && (
                        <div>
                          {i18n.t("cruise-air-speed")}: <strong>{decodedBeacon.cruiseAirSpeed || ""}</strong>
                        </div>
                      )}
                      {decodedBeacon.hasOwnProperty("length") && (
                        <div>
                          {i18n.t("length")}: <strong>{decodedBeacon.length || "0"}</strong>
                        </div>
                      )}
                      {decodedBeacon.wingSpan && (
                        <div>
                          {i18n.t("wingspan")}: <strong>{decodedBeacon.wingSpan || ""}</strong>
                        </div>
                      )}
                      {decodedBeacon.peopleCapacity && (
                        <div>
                          {i18n.t("people-capacity")}: <strong>{decodedBeacon.peopleCapacity || ""}</strong>
                        </div>
                      )}

                      {decodedBeacon.vehicleNationality && (
                        <div>
                          {i18n.t("aircraft-nationality")}: <strong>{decodedBeacon.vehicleNationality || ""}</strong>
                        </div>
                      )}
                      {decodedBeacon.additionalComment && (
                        <div>
                          {i18n.t("additional-vehicle-usage-information")}: <strong>{decodedBeacon.additionalComment || ""}</strong>
                        </div>
                      )}

                      {decodedBeacon.sarHazard && (
                        <div>
                          {i18n.t("sar-hazard")}: <strong>{decodedBeacon.sarHazard || ""}</strong>
                        </div>
                      )}
                    </>
                  ) : beaconType.includes("EPIRB") ? (
                    <>
                      {decodedBeacon.vehicleType && (
                        <div>
                          {i18n.t("vehicle-type")}: <strong>{decodedBeacon.vehicleType || ""} </strong>
                        </div>
                      )}
                      {decodedBeacon.vehicleRegistrationNumber && (
                        <div>
                          {i18n.t("vehicle-registration-number")}: <strong>{decodedBeacon.vehicleRegistrationNumber || ""}</strong>
                        </div>
                      )}
                      {decodedBeacon.vehicleName && (
                        <div>
                          {i18n.t("vessel-name")}: <strong>{decodedBeacon.vehicleName || ""} </strong>
                        </div>
                      )}
                      {decodedBeacon.vehicleModel && (
                        <div>
                          {i18n.t("vessel-model")}: <strong>{decodedBeacon.vehicleModel || ""}</strong>
                        </div>
                      )}
                      {decodedBeacon.homePort && (
                        <div>
                          {i18n.t("home-port")}: <strong>{decodedBeacon.homePort || ""} </strong>
                        </div>
                      )}

                      {decodedBeacon.vehicleColor && (
                        <div>
                          {i18n.t("vessel-colour")}: <strong>{decodedBeacon.vehicleColor || ""}</strong>
                        </div>
                      )}
                      {decodedBeacon.hasOwnProperty("nbLifeBoat") && (
                        <div>
                          {i18n.t("number-of-life-boats")}: <strong>{decodedBeacon.nbLifeBoat || "0"} </strong>
                        </div>
                      )}
                      {decodedBeacon.hasOwnProperty("nbLifeRaft") && (
                        <div>
                          {i18n.t("number-of-life-rafts")}: <strong>{decodedBeacon.nbLifeRaft || "0"}</strong>
                        </div>
                      )}

                      {decodedBeacon.radioEquipment && (
                        <div>
                          {i18n.t("radio-equipment")}:{" "}
                          <strong>
                            {decodedBeacon.radioEquipment || ""}
                            {decodedBeacon.radioEquipment == "Other" ? " (" + decodedBeacon.radioEquipmentOther + ")" : ""}
                          </strong>
                        </div>
                      )}

                      {decodedBeacon.hasOwnProperty("callSign") && (
                        <div>
                          {i18n.t("radio-call-sign")}: <strong>{decodedBeacon.callSign}</strong>
                        </div>
                      )}
                      {decodedBeacon.hasOwnProperty("callSignDecoded") && (
                        <div>
                          {i18n.t("radio-call-sign-decoded")}: <strong>{decodedBeacon.callSignDecoded} </strong>
                        </div>
                      )}
                      {decodedBeacon.hasOwnProperty("aisNumber") && (
                        <div>
                          {i18n.t("ais-number")}: <strong>{decodedBeacon.aisNumber}</strong>
                        </div>
                      )}
                      {decodedBeacon.hasOwnProperty("Imarsat") && (
                        <div>
                          {i18n.t("inmarsat")}: <strong>{decodedBeacon.Imarsat} </strong>
                        </div>
                      )}

                      {decodedBeacon.vehicleCellularNum && (
                        <div>
                          {i18n.t("vessel-cellular")}: <strong>{decodedBeacon.vehicleCellularNum || ""}</strong>
                        </div>
                      )}
                      {decodedBeacon.vehicleSatellitePhone && (
                        <div>
                          {i18n.t("vessel-satellite-phone")}: <strong>{decodedBeacon.vehicleSatellitePhone || ""} </strong>
                        </div>
                      )}
                      {decodedBeacon.MMSI && (
                        <div>
                          {i18n.t("mmsi-number")}: <strong>{decodedBeacon.MMSI || ""}</strong>
                        </div>
                      )}
                      {decodedBeacon.MMSIDecoded && (
                        <div>
                          {i18n.t("mmsi-number-decoded")}: <strong>{decodedBeacon.MMSIDecoded || ""} </strong>
                        </div>
                      )}
                      {decodedBeacon.hasOwnProperty("length") && (
                        <div>
                          {i18n.t("length")}: <strong>{decodedBeacon.length || "0"}</strong>
                        </div>
                      )}
                      {decodedBeacon.hasOwnProperty("peopleCapacity") && (
                        <div>
                          {i18n.t("capacity-crew-and-passengers")}: <strong>{decodedBeacon.peopleCapacity || ""}</strong>
                        </div>
                      )}
                      {decodedBeacon.vehicleNationality && (
                        <div>
                          {i18n.t("vehicle-nationality")}: <strong>{countryCodeToText(decodedBeacon.vehicleNationality) || ""}</strong>
                        </div>
                      )}
                      {decodedBeacon.equippedWithDataRecord && (
                        <div>
                          {i18n.t("equipped-with-simplified-voyage-data-recorder")}: <strong>{decodedBeacon.equippedWithDataRecord || ""}</strong>
                        </div>
                      )}
                      {decodedBeacon.additionalComment && (
                        <div>
                          {i18n.t("additional-vehicle-usage-information")}: <strong>{decodedBeacon.additionalComment || ""}</strong>
                        </div>
                      )}

                      {decodedBeacon.sarHazard && (
                        <div>
                          {i18n.t("sar-hazard")}: <strong>{decodedBeacon.sarHazard || ""}</strong>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {decodedBeacon.vehicleType && (
                        <div>
                          {i18n.t("vehicle-type")}: <strong>{decodedBeacon.vehicleType || ""} </strong>
                        </div>
                      )}

                      {decodedBeacon.usageMoreInfo && (
                        <div>
                          {i18n.t("specific-usage")}:{" "}
                          <strong>
                            {decodedBeacon.usageMoreInfo || ""}
                            {decodedBeacon.usageMoreInfo == "Other" ? " (" + decodedBeacon.usageMoreInfoOther + ")" : ""}
                          </strong>
                        </div>
                      )}

                      {decodedBeacon.additionalComment && (
                        <div>
                          {i18n.t("additional-vehicle-usage-information")}: <strong>{decodedBeacon.additionalComment || ""}</strong>
                        </div>
                      )}

                      {decodedBeacon.sarHazard && (
                        <div>
                          {i18n.t("sar-hazard")}: <strong>{decodedBeacon.sarHazard || ""}</strong>
                        </div>
                      )}
                    </>
                  )}

                  {/* Vehicle images */}
                  {decodedBeacon.picture1 ? (
                    <div style={{ marginTop: "10px" }}>
                      <img style={{ width: "100%" }} src={decodedBeacon.picture1} />
                    </div>
                  ) : (
                    ""
                  )}
                  {decodedBeacon.picture2 ? (
                    <div style={{ marginTop: "10px" }}>
                      <img style={{ width: "100%" }} src={decodedBeacon.picture2} />
                    </div>
                  ) : (
                    ""
                  )}

                  {/* Account Owner Information */}
                  {["4"].includes(UserState.currentUserProfile.roleId) && accountDetail && (
                    <>
                      <Header>{i18n.t("account-owner")}</Header>
                      <div>
                        {i18n.t("name")}: <strong>{accountDetail.ownerName || ""}</strong>
                      </div>
                      <div>
                        {i18n.t("username")}: <strong>{(accountDetail._id && accountDetail._id.includes("org.couchdb.user:") ? accountDetail._id.split(":")[1] : accountDetail._id) || ""}</strong>
                      </div>
                      <div>
                        {i18n.t("email")}: <strong>{accountDetail.emailAddress || ""}</strong>
                      </div>
                      <div>
                        {i18n.t("address")}: <strong>{accountDetail.address || ""}</strong>
                      </div>
                      <div>
                        {i18n.t("zip-postal-code-2")}: <strong>{accountDetail.mailCode || ""}</strong>
                      </div>
                      <div>
                        {i18n.t("city")}: <strong>{accountDetail.city || ""}</strong>
                      </div>
                      <div>
                        {i18n.t("state-province")}: <strong>{accountDetail.province || ""}</strong>
                      </div>
                      <div>
                        {i18n.t("country")}: <strong>{accountDetail.mailCountry || ""}</strong>
                      </div>
                      {accountDetail.phone1Num && accountDetail.phone1Num != "" ? (
                        <div>
                          {i18n.t("telephone-number-1")}:{" "}
                          <strong>
                            {accountDetail.phone1Num}
                            {accountDetail.phone1Type ? " (" + phoneTypeToText(accountDetail.phone1Type, accountDetail.phone1TypeOther) + ")" : ""}
                          </strong>
                        </div>
                      ) : (
                        ""
                      )}
                      {accountDetail.phone2Num && accountDetail.phone2Num != "" ? (
                        <div>
                          {i18n.t("telephone-number-2")}:{" "}
                          <strong>
                            {accountDetail.phone2Num}
                            {accountDetail.phone2Type ? " (" + phoneTypeToText(accountDetail.phone2Type, accountDetail.phone2TypeOther) + ")" : ""}
                          </strong>
                        </div>
                      ) : (
                        ""
                      )}
                      {accountDetail.phone3Num && accountDetail.phone3Num != "" ? (
                        <div>
                          {i18n.t("telephone-number-3")}:{" "}
                          <strong>
                            {accountDetail.phone3Num}
                            {accountDetail.phone3Type ? " (" + phoneTypeToText(accountDetail.phone3Type, accountDetail.phone3TypeOther) + ")" : ""}
                          </strong>
                        </div>
                      ) : (
                        ""
                      )}
                      {accountDetail.phone4Num && accountDetail.phone4Num != "" ? (
                        <div>
                          {i18n.t("telephone-number-4")}:{" "}
                          <strong>
                            {accountDetail.phone4Num}
                            {accountDetail.phone4Type ? " (" + phoneTypeToText(accountDetail.phone4Type, accountDetail.phone4TypeOther) + ")" : ""}
                          </strong>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* <div>
                          Last Edit Date: <strong>{Utils.dateFormatWithTime(accountDetail.lastEditDate, true) || ""}</strong>
                        </div> */}
                    </>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {/* All Activities */}
            {showActivityByRole.includes(UserState.currentUserProfile.roleId) && RegistrationState.isPrintSummary && (
              <>
                <ActivityWidget key={decodedBeacon.hexId} owner={decodedBeacon.owner} hexId={decodedBeacon.hexId} title="Activities" />
              </>
            )}
            {/* owner history */}
            {/* {DashboardState.ownerHistory.length > 0 && RegistrationState.isPrintSummary && <OwnerHistoryWidget title="Previous Owner" ownerHistory={DashboardState.ownerHistory} />} */}
            {DashboardState.accountOwnerHistory.length > 0 && RegistrationState.isPrintSummary && <OwnerHistoryWidget title="Previous Owner" ownerHistory={DashboardState.accountOwnerHistory || []} />}
            <div className="registerBeacon-submitContainer noprint">
              {RegistrationState.isPrintCertificate ? (
                <>
                  <div style={{ marginTop: "10px", textAlign: "center" }}>
                    <a className="beaconReviewDetails-editLink noprint" onClick={this.onOpenModal}>
                      <Icon name="print" color="grey" size="large" />
                      {i18n.t("print-certificate")}
                    </a>
                  </div>
                </>
              ) : RegistrationState.isPrintSummary ? (
                <>
                  <div style={{ marginTop: "10px", textAlign: "center" }}>
                    <ReactToPrint
                      trigger={() => (
                        <a className="beaconReviewDetails-editLink">
                          <Icon name="print" color="grey" size="large" /> Print Summary
                        </a>
                      )}
                      content={() => this.componentRefSummary}
                    />
                  </div>
                </>
              ) : canEditFields ? (
                <div className="activityDetail-submitContainer">
                  <Button className="registerBeacon-submitButton" onClick={this.onBackClick}>
                    {i18n.t("back")}
                  </Button>
                  <Button className="registerBeacon-submitButton" type="submit" primary onClick={this.onSaveClick} loading={this.state.isSaving}>
                    {i18n.t("save")}
                  </Button>
                </div>
              ) : (
                <div className="activityDetail-submitContainer">
                  <Button className="registerBeacon-submitButton" onClick={this.onBackClick}>
                    {i18n.t("back")}
                  </Button>
                  <Button
                    className="registerBeacon-submitButton"
                    type="submit"
                    primary
                    onClick={() => {
                      onClose();
                    }}
                  >
                    {i18n.t("close")}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Container>

        {/* Complete Screen */}

        <CompleteScreen
          onOpenModal={this.onOpenModal}
          showCompleteScreen={RegistrationState.showCompleteScreen}
          onExportClick={this.onExportClick}
          onCloseModal={this.onCloseModal}
          onDashboardEdit={onDashboardEdit}
          showModal={showModal}
          onEditingClose={this.onEditingClose}
          returnToDashboardClick={this.returnToDashboardClick}
          registerNewBeaconClick={this.registerNewBeaconClick}
          decodedBeacon={decodedBeacon}
          beaconType={beaconType}
        />

        <PrintCertificateModal decodedBeacon={decodedBeacon} beaconType={beaconType} onExportClick={this.onExportClick} onCloseModal={this.onCloseModal} showModal={showModal} />
      </>
    );
  }
}

class CompleteScreen extends React.Component {
  onClickAddActivity = () => {
    RegistrationState.resetBeaconData();
    RegistrationState.resetSteps();
    RouteState.setRoute("/dashboard/activities");
  };

  render() {
    const { onOpenModal, onExportClick, onCloseModal, showModal, decodedBeacon, beaconType, onDashboardEdit, onEditingClose, returnToDashboardClick, registerNewBeaconClick, showCompleteScreen } = this.props;

    if (!showCompleteScreen) return null;
    let title = RegistrationState.isNewRegisterBeacon ? "Registration Complete!" : "Edit Complete!";

    let activityLink = null;
    // user can create an activity except NDP user (LP:63542202)
    if (RegistrationState.isNewRegisterBeacon && UserState.currentUserProfile.roleId !== "1") {
      activityLink = (
        <Segment placeholder compact className="beaconReviewDetails-addActivityPrompt">
          <Header textAlign="center">
            {i18n.t("add-an-activity")}
            <Header.Subheader>{i18n.t("how-will-you-be-using-your-beacon-tell-us-how-you-will-be-using-your-beacon-by-adding-an-activity-this-provides-additional-information-to-assist-search-and-rescue-sar-responders-in-the-event-of-an-emergency-click-on-the-link-below-to-add-an-activity")}</Header.Subheader>
          </Header>
          <Button primary onClick={this.onClickAddActivity}>
            {i18n.t("add-activity")}
          </Button>
        </Segment>
      );
    }

    return (
      <Container textAlign="center" className=" animated fadeIn faster">
        <Header as="h2" icon textAlign="center">
          <Icon name="check" circular color="green" />
          <Header.Content>{title}</Header.Content>
        </Header>

        <Divider />
        <div>
          <div style={{ marginTop: "10px" }}>
            <a className="beaconReviewDetails-editLink noprint" onClick={onOpenModal}>
              <Icon name="print" color="grey" size="large" />
              {i18n.t("print-certificate")}
            </a>
          </div>
        </div>
        {activityLink}

        {/* <PrintCertificateModal decodedBeacon={decodedBeacon} beaconType={beaconType} onExportClick={onExportClick} onCloseModal={onCloseModal} showModal={showModal} /> */}
        {/* <CompleteScreen showCompleteScreen={showCompleteScreen} /> */}
        <div className="activityDetail-submitContainer">
          {onDashboardEdit ? (
            <>
              <Button className="registerBeacon-submitButton" onClick={onEditingClose}>
                {i18n.t("close")}
              </Button>
            </>
          ) : (
            <>
              <Button className="registerBeacon-submitButton" onClick={returnToDashboardClick}>
                {i18n.t("return-to-dashboard")}
              </Button>
              <Button className="registerBeacon-submitButton" type="submit" primary onClick={registerNewBeaconClick}>
                {i18n.t("register-a-new-beacon")}
              </Button>
            </>
          )}
        </div>
      </Container>
    );
  }
}

class PrintCertificateModal extends React.Component {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
  }
  render() {
    const { showModal, decodedBeacon, beaconType, onCloseModal, onExportClick } = this.props;

    if (!showModal) return null;

    window.gtag("event", "bncCertificate_print", { event_category: "bncCertificate_print", event_label: "bncCertificate_print" });
    return (
      <>
        <Modal centered={false} open={true} onClose={onCloseModal} size="tiny">
          <Modal.Header>{i18n.t("print-options")}</Modal.Header>
          <Modal.Content>
            <ReactToPrint
              trigger={() => (
                <a className="beaconReviewDetails-editLink">
                  <Icon name="print" color="grey" size="large" /> {i18n.t("print-to-local-printer")}
                </a>
              )}
              content={() => this.componentRef.current}
              onBeforePrint={() => {
                RegistrationState.updateBeaconLastEditDate();
              }}
              pageStyle={pageStyle}
            />
            <br />
            <br />
            <a className="beaconReviewDetails-editLink noprint" onClick={onExportClick}>
              <Icon name="file" color="grey" size="large" />
              {i18n.t("save-file")}
            </a>
          </Modal.Content>

          <Modal.Actions>
            <Button onClick={onCloseModal} negative>
              {i18n.t("close")}
            </Button>
          </Modal.Actions>
        </Modal>

        <div style={{ display: "none" }}>
          <PrintCertificate ref={this.componentRef} decodedBeacon={decodedBeacon} beaconType={beaconType} />
        </div>
      </>
    );
  }
}

function countryCodeToText(id) {
  let countryNamesWithCode = AppConfigState.countryNamesWithCode;
  let item = countryNamesWithCode.find((row) => row.value == id);
  if (!item) return "";
  return item.text;
}

function manufacturerToText(id) {
  if (!id) return "";
  let manufacturerOption = AppConfigState.manufacturerOption;
  let item = manufacturerOption.find((row) => row.value == id);
  if (!item) return id;
  return item.text;
}

function phoneTypeToText(id, otherType = "") {
  let phoneTypes = AppConfigState.phoneTypes;
  let item = phoneTypes.find((row) => row.value == id);
  if (!item) return "";
  if (item.value == "OTHR") {
    return item.text + " - " + otherType;
  }
  return item.text;
}

function activationMedthodToText(id) {
  const activationMethodOptions = AppConfigState.beaconActivationMethod;
  let item = activationMethodOptions.find((row) => row.value == id);
  if (!item) return "";
  return item.text;
}

function specialStatusToText(id, isBriefCer = false) {
  const options = AppConfigState.beaconStatusOption;
  let item = options.find((row) => row.value == id);
  if (!item) return "";
  return item.text;
}

function getCurrentDate() {
  var tempDate = new Date();
  var date = tempDate.getFullYear() + "-" + (tempDate.getMonth() + 1) + "-" + tempDate.getDate() + " " + tempDate.getHours() + ":" + tempDate.getMinutes() + ":" + tempDate.getSeconds();
  return date;
}

function tableToText(table) {
  let url = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port;
  let text = "<div>";
  text += '<img src="' + url + '/images/topmenu_406logo.png" />';
  text += "<h2>Certificate of 406 MHz beacon registration</h2>";
  text += table.innerHTML;
  text += "</div>";
  return text;
}

function tableToText2(table) {
  // go through cells
  let url = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port;
  let text = "<div>";
  text += '<img src="' + url + '/images/topmenu_406logo.png" />';
  text += "<h2>Certificate of 406 MHz beacon registration</h2>";
  text += "<table><tbody>";
  for (let i = 0; i < table.rows.length; i++) {
    let tableRow = table.rows[i];
    text += "<tr>";
    text += '<td style="width:50%">' + tableRow.cells[0].innerText + "</td>";
    text += '<td style="width:50%">' + (tableRow.cells[1].innerText != "" ? tableRow.cells[1].innerText : "") + "</td>";
    text += "</tr>";
  }
  text += "</tbody></table>";
  text += "</div>";
  return text;
}

const pageStyle = `
  @page {
    size: auto;
    margin: 10mm !important;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }

    .print-section {
      padding: 5px;
    }
  }
`;

@observer
class PrintCertificate extends Component {
  componentDidMount() {
    const { decodedBeacon, beaconType } = this.props;
    try {
      window.gtag("event", "cert_print", {
        hexId: decodedBeacon.hexId
      });
    } catch (error) {
      console.log("log ~ UserState ~ login ~ gtag error:", error);
    }
  }

  render() {
    const { decodedBeacon, beaconType } = this.props;

    let roleIdForBriefCertificate = UserState.roleIdForBriefCertificate;
    let isBriefCerUser = roleIdForBriefCertificate.includes(UserState.currentUserProfile.roleId) ? true : false;

    let protocol = location.protocol;
    let hostname = location.hostname;
    let baseUrl = protocol + "//" + hostname;
    if (hostname === "localhost") {
      baseUrl = protocol + "//" + hostname + ":" + location.port;
    }
    let imgUrl = baseUrl + "/images/topmenu_406logo.png";

    return (
      <div className="print-section">
        <div>
          <img src={imgUrl} />
        </div>

        <div style={{ textAlign: "center", margin: "20px auto" }}>
          <h2>Certificate of 406 MHz beacon registration</h2>
        </div>

        <div className="print-section-content">
          <div className="print-column" style={{ display: "inline-block", width: "45%", verticalAlign: "top" }}>
            {isBriefCerUser ? (
              <div>
                <div>
                  <b>{i18n.t("beacon-hex-id")}:</b> {decodedBeacon.hexId || ""}
                </div>
                <div>
                  <b>{i18n.t("beacon-type")}:</b> {decodedBeacon.beaconType || ""}
                </div>
                {decodedBeacon.beaconManufacturer && (
                  <div>
                    {i18n.t("beacon-manufacturer")}:{" "}
                    <strong>
                      {manufacturerToText(decodedBeacon.beaconManufacturer)}
                      {decodedBeacon.beaconManufacturerOther ? " (" + decodedBeacon.beaconManufacturerOther + ")" : ""}
                    </strong>
                  </div>
                )}
                {decodedBeacon.beaconModel && (
                  <div>
                    {i18n.t("model-name")}: <strong>{decodedBeacon.beaconModel}</strong>
                  </div>
                )}

                {decodedBeacon.typeApprovalCertificate && (
                  <div>
                    {i18n.t("c-s-type-approval-number")}: <strong>{decodedBeacon.typeApprovalCertificate}</strong>
                  </div>
                )}

                <br />

                {(decodedBeacon.beaconHomingDevice || decodedBeacon.beaconHomingDeviceOther) && (
                  <div>
                    {i18n.t("beacon-homing-device")}:{" "}
                    <strong>
                      {decodedBeacon.beaconHomingDevice}
                      {decodedBeacon.beaconHomingDeviceOther ? " (" + decodedBeacon.beaconHomingDeviceOther + ")" : ""}
                    </strong>
                  </div>
                )}

                {decodedBeacon.beaconActivationMethod && (
                  <div>
                    {i18n.t("activation-method")}: <strong>{activationMedthodToText(decodedBeacon.beaconActivationMethod)}</strong>
                  </div>
                )}

                {decodedBeacon.additionalBeaconData && (
                  <div>
                    {i18n.t("additional-beacon-information")}: <strong>{decodedBeacon.additionalBeaconData}</strong>
                  </div>
                )}

                <br />
                {decodedBeacon.initialDate && (
                  <div>
                    {i18n.t("initial-registration-date")}: <strong>{Utils.dateFormatWithTime(decodedBeacon.initialDate, true) || ""}</strong>
                  </div>
                )}
                {decodedBeacon.lastEditDate && (
                  <div>
                    {i18n.t("last-edit-date")}: <strong>{Utils.dateFormatWithTime(decodedBeacon.lastEditDate, true) || ""}</strong>
                  </div>
                )}
                {decodedBeacon.specialStatus && (
                  <div>
                    {i18n.t("special-status")}: <strong>{specialStatusToText(decodedBeacon.specialStatus, true) || "Active"}</strong>
                  </div>
                )}

                {["1", "3"].includes(UserState.currentUserProfile.roleId) && decodedBeacon.specialStatusInfo && (
                  <div>
                    {i18n.t("special-status-info")}: <strong>{decodedBeacon.specialStatusInfo || ""}</strong>
                  </div>
                )}

                {decodedBeacon.specialStatusDate && (
                  <div>
                    {i18n.t("special-status-date")}: <strong>{Utils.dateFormatWithTime(decodedBeacon.specialStatusDate, true) || ""}</strong>
                  </div>
                )}
                {decodedBeacon.lastConfirmationDate && (
                  <div>
                    {i18n.t("last-confirmation-date")}: <strong>{Utils.dateFormatWithTime(decodedBeacon.lastConfirmationDate, true) || ""}</strong>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div>
                  <b>{i18n.t("beacon-hex-id")}:</b> {decodedBeacon.hexId || ""}
                </div>
                <div>
                  <b>{i18n.t("beacon-type")}:</b> {decodedBeacon.beaconType || ""}
                </div>
                <div>
                  <b>{i18n.t("country")}:</b> {AppConfigState.getCountryNameByMid(decodedBeacon.beaconCountryCode) + " (" + decodedBeacon.beaconCountryCode + ")"}
                </div>

                {decodedBeacon.beaconManufacturer && (
                  <div>
                    {i18n.t("beacon-manufacturer")}:{" "}
                    <strong>
                      {manufacturerToText(decodedBeacon.beaconManufacturer)}
                      {decodedBeacon.beaconManufacturerOther ? " (" + decodedBeacon.beaconManufacturerOther + ")" : ""}
                    </strong>
                  </div>
                )}
                {decodedBeacon.serialNumber && (
                  <div>
                    <b>{i18n.t("serial-number")}:</b> {decodedBeacon.serialNumber || ""}
                  </div>
                )}
                {decodedBeacon.beaconModel && (
                  <div>
                    {i18n.t("model-name")}: <strong>{decodedBeacon.beaconModel}</strong>
                  </div>
                )}

                {decodedBeacon.typeApprovalCertificate && (
                  <div>
                    {i18n.t("c-s-type-approval-number")}: <strong>{decodedBeacon.typeApprovalCertificate}</strong>
                  </div>
                )}

                <br />

                {(decodedBeacon.beaconHomingDevice || decodedBeacon.beaconHomingDeviceOther) && (
                  <div>
                    {i18n.t("beacon-homing-device")}:{" "}
                    <strong>
                      {decodedBeacon.beaconHomingDevice}
                      {decodedBeacon.beaconHomingDeviceOther ? " (" + decodedBeacon.beaconHomingDeviceOther + ")" : ""}
                    </strong>
                  </div>
                )}

                {decodedBeacon.beaconActivationMethod && (
                  <div>
                    {i18n.t("activation-method")}: <strong>{activationMedthodToText(decodedBeacon.beaconActivationMethod)}</strong>
                  </div>
                )}

                {decodedBeacon.additionalBeaconData && (
                  <div>
                    {i18n.t("additional-beacon-information")}: <strong>{decodedBeacon.additionalBeaconData}</strong>
                  </div>
                )}

                <br />
                {decodedBeacon.initialDate && (
                  <div>
                    {i18n.t("initial-registration-date")}: <strong>{Utils.dateFormatWithTime(decodedBeacon.initialDate, true) || ""}</strong>
                  </div>
                )}
                {decodedBeacon.lastEditDate && (
                  <div>
                    {i18n.t("last-edit-date")}: <strong>{Utils.dateFormatWithTime(decodedBeacon.lastEditDate, true) || ""}</strong>
                  </div>
                )}
                {decodedBeacon.specialStatus && (
                  <div>
                    {i18n.t("special-status")}: <strong>{specialStatusToText(decodedBeacon.specialStatus, true) || "Active"}</strong>
                  </div>
                )}

                {["1", "3"].includes(UserState.currentUserProfile.roleId) && decodedBeacon.specialStatusInfo && (
                  <div>
                    {i18n.t("special-status-info")}: <strong>{decodedBeacon.specialStatusInfo || ""}</strong>
                  </div>
                )}

                {decodedBeacon.specialStatusDate && (
                  <div>
                    {i18n.t("special-status-date")}: <strong>{Utils.dateFormatWithTime(decodedBeacon.specialStatusDate, true) || ""}</strong>
                  </div>
                )}
                {decodedBeacon.lastConfirmationDate && (
                  <div>
                    {i18n.t("last-confirmation-date")}: <strong>{Utils.dateFormatWithTime(decodedBeacon.lastConfirmationDate, true) || ""}</strong>
                  </div>
                )}
              </div>
            )}

            {!isBriefCerUser && (
              <div>
                {decodedBeacon.ownerName && (
                  <div>
                    {i18n.t("name")}: <strong>{decodedBeacon.ownerName}</strong>
                  </div>
                )}

                {decodedBeacon.emailAddress && (
                  <div>
                    {i18n.t("email")}: <strong>{decodedBeacon.emailAddress || ""}</strong>
                  </div>
                )}

                {decodedBeacon.address && (
                  <div>
                    {i18n.t("address")}: <strong>{decodedBeacon.address || ""}</strong>
                  </div>
                )}

                {decodedBeacon.mailCode && (
                  <div>
                    {i18n.t("zip-postal-code-2")}: <strong>{decodedBeacon.mailCode || ""}</strong>
                  </div>
                )}

                {decodedBeacon.city && (
                  <div>
                    {i18n.t("city")}: <strong>{decodedBeacon.city || ""}</strong>
                  </div>
                )}

                {decodedBeacon.province && (
                  <div>
                    {i18n.t("state-province")}: <strong>{decodedBeacon.province || ""}</strong>
                  </div>
                )}

                {decodedBeacon.mailCountry && (
                  <div>
                    {i18n.t("country")}: <strong>{decodedBeacon.mailCountry || ""}</strong>
                  </div>
                )}

                {decodedBeacon.medicalInfo && (
                  <div>
                    {i18n.t("medical-information")}: <strong>{decodedBeacon.medicalInfo || ""}</strong>
                  </div>
                )}

                {decodedBeacon.operatorLanguage && (
                  <div>
                    {i18n.t("communication-language")}: <strong>{decodedBeacon.operatorLanguage || ""}</strong>
                  </div>
                )}
                {decodedBeacon.phone1Num && (
                  <div>
                    <b>{i18n.t("beacon-owner-telephone-number-1")}:</b> {decodedBeacon.phone1Num}
                    {decodedBeacon.phone1Type ? " (" + phoneTypeToText(decodedBeacon.phone1Type, decodedBeacon.phone1TypeOther) + ")" : ""}
                  </div>
                )}
                {decodedBeacon.phone2Num && (
                  <div>
                    <b>{i18n.t("beacon-owner-telephone-number-2")}:</b> {decodedBeacon.phone2Num}
                    {decodedBeacon.phone2Type ? " (" + phoneTypeToText(decodedBeacon.phone2Type, decodedBeacon.phone2TypeOther) + ")" : ""}
                  </div>
                )}
                {decodedBeacon.phone3Num && (
                  <div>
                    <b>{i18n.t("beacon-owner-telephone-number-3")}:</b> {decodedBeacon.phone3Num}
                    {decodedBeacon.phone3Type ? " (" + phoneTypeToText(decodedBeacon.phone3Type, decodedBeacon.phone3TypeOther) + ")" : ""}
                  </div>
                )}
                {decodedBeacon.phone4Num && (
                  <div>
                    <b>{i18n.t("greater-than-beacon-owner-telephone-number-4")}:</b> {decodedBeacon.phone4Num}
                    {decodedBeacon.phone4Type ? " (" + phoneTypeToText(decodedBeacon.phone4Type, decodedBeacon.phone4TypeOther) + ")" : ""}
                  </div>
                )}
              </div>
            )}

            <div className="pagebreak" />
            {/* emergengy */}
            {!isBriefCerUser && (
              <div>
                {decodedBeacon.primaryContactName && (
                  <div>
                    {i18n.t("24-hour-emergency-contact-name")}: <strong>{decodedBeacon.primaryContactName}</strong>
                  </div>
                )}

                {decodedBeacon.primaryContactAddressLine1 && (
                  <div>
                    {i18n.t("emergency-contact-address-line-1")}: <strong>{decodedBeacon.primaryContactAddressLine1}</strong>
                  </div>
                )}

                {decodedBeacon.primaryContactAddressLine2 && (
                  <div>
                    {i18n.t("emergency-contact-address-line-2")}: <strong>{decodedBeacon.primaryContactAddressLine2}</strong>
                  </div>
                )}

                {decodedBeacon.primaryPhone1Num && decodedBeacon.primaryPhone1Num != "" ? (
                  <div>
                    {i18n.t("emergency-contact-phone-number-1")}:{" "}
                    <strong>
                      {decodedBeacon.primaryPhone1Num}
                      {decodedBeacon.primaryPhone1Type ? " (" + phoneTypeToText(decodedBeacon.primaryPhone1Type, decodedBeacon.primaryPhone1TypeOther) + ")" : ""}
                    </strong>
                  </div>
                ) : (
                  ""
                )}
                {decodedBeacon.primaryPhone2Num && decodedBeacon.primaryPhone2Num != "" ? (
                  <div>
                    {i18n.t("emergency-contact-phone-number-2")}:{" "}
                    <strong>
                      {decodedBeacon.primaryPhone2Num}
                      {decodedBeacon.primaryPhone2Type ? " (" + phoneTypeToText(decodedBeacon.primaryPhone2Type, decodedBeacon.primaryPhone2TypeOther) + ")" : ""}
                    </strong>
                  </div>
                ) : (
                  ""
                )}
                {decodedBeacon.primaryPhone3Num && decodedBeacon.primaryPhone3Num != "" ? (
                  <div>
                    {i18n.t("emergency-contact-phone-number-3")}:{" "}
                    <strong>
                      {decodedBeacon.primaryPhone3Num}
                      {decodedBeacon.primaryPhone3Type ? " (" + phoneTypeToText(decodedBeacon.primaryPhone3Type, decodedBeacon.primaryPhone3TypeOther) + ")" : ""}
                    </strong>
                  </div>
                ) : (
                  ""
                )}
                {decodedBeacon.primaryPhone4Num && decodedBeacon.primaryPhone4Num != "" ? (
                  <div>
                    {i18n.t("emergency-contact-phone-number-4")}:{" "}
                    <strong>
                      {decodedBeacon.primaryPhone4Num}
                      {decodedBeacon.primaryPhone4Type ? " (" + phoneTypeToText(decodedBeacon.primaryPhone4Type, decodedBeacon.primaryPhone4TypeOther) + ")" : ""}
                    </strong>
                  </div>
                ) : (
                  ""
                )}

                {decodedBeacon.alternateContactName && (
                  <div>
                    {i18n.t("alternate-24-hour-emergency-contact-name")}: <strong>{decodedBeacon.alternateContactName}</strong>
                  </div>
                )}
                {decodedBeacon.alternateContactAddressLine1 && (
                  <div>
                    {i18n.t("alternate-contact-address-line-1")}: <strong>{decodedBeacon.alternateContactAddressLine1}</strong>
                  </div>
                )}
                {decodedBeacon.alternateContactAddressLine2 && (
                  <div>
                    {i18n.t("alternate-contact-address-line-2")}: <strong>{decodedBeacon.alternateContactAddressLine2}</strong>
                  </div>
                )}

                {decodedBeacon.alternatePhone1Num && decodedBeacon.alternatePhone1Num != "" ? (
                  <div>
                    {i18n.t("alternate-contact-phone-number-1")}:{" "}
                    <strong>
                      {decodedBeacon.alternatePhone1Num}
                      {decodedBeacon.alternatePhone1Type ? " (" + phoneTypeToText(decodedBeacon.alternatePhone1Type, decodedBeacon.alternatePhone1TypeOther) + ")" : ""}
                    </strong>
                  </div>
                ) : (
                  ""
                )}
                {decodedBeacon.alternatePhone2Num && decodedBeacon.alternatePhone2Num != "" ? (
                  <div>
                    {i18n.t("alternate-contact-phone-number-2")}:{" "}
                    <strong>
                      {decodedBeacon.alternatePhone2Num}
                      {decodedBeacon.alternatePhone2Type ? " (" + phoneTypeToText(decodedBeacon.alternatePhone2Type, decodedBeacon.alternatePhone2TypeOther) + ")" : ""}
                    </strong>
                  </div>
                ) : (
                  ""
                )}
                {decodedBeacon.alternatePhone3Num && decodedBeacon.alternatePhone3Num != "" ? (
                  <div>
                    {i18n.t("alternate-contact-phone-number-3")}:{" "}
                    <strong>
                      {decodedBeacon.alternatePhone3Num}
                      {decodedBeacon.alternatePhone3Type ? " (" + phoneTypeToText(decodedBeacon.alternatePhone3Type, decodedBeacon.alternatePhone3TypeOther) + ")" : ""}
                    </strong>
                  </div>
                ) : (
                  ""
                )}
                {decodedBeacon.alternatePhone4Num && decodedBeacon.alternatePhone4Num != "" ? (
                  <div>
                    {i18n.t("alternate-contact-phone-number-4")}:{" "}
                    <strong>
                      {decodedBeacon.alternatePhone4Num}
                      {decodedBeacon.alternatePhone4Type ? " (" + phoneTypeToText(decodedBeacon.alternatePhone4Type, decodedBeacon.alternatePhone4TypeOther) + ")" : ""}
                    </strong>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
            {UserState.currentUserProfile.roleId === "4" && decodedBeacon.specialStatus === "RELEASETOMAINTENANCE" && UserState.maintProviderInfo && (
              <div>
                <div>
                  <strong>{i18n.t("maintenance-provider")}</strong>
                </div>
                <div>
                  {i18n.t("name")}: <strong>{UserState.maintProviderInfo.ownerName || ""}</strong>
                </div>
                <div>
                  {i18n.t("email")}: <strong>{UserState.maintProviderInfo.emailAddress || ""}</strong>
                </div>
                <div>
                  {i18n.t("address")}: <strong>{UserState.maintProviderInfo.address || ""}</strong>
                </div>
                <div>
                  {i18n.t("zip-postal-code-2")}: <strong>{UserState.maintProviderInfo.mailCode || ""}</strong>
                </div>
                <div>
                  {i18n.t("city")}: <strong>{UserState.maintProviderInfo.city || ""}</strong>
                </div>
                <div>
                  {i18n.t("state-province")}: <strong>{UserState.maintProviderInfo.province || ""}</strong>
                </div>
                <div>
                  {i18n.t("country")}: <strong>{UserState.maintProviderInfo.mailCountry || ""}</strong>
                </div>
                <div>
                  {i18n.t("communication-language")}: <strong>{UserState.maintProviderInfo.operatorLanguage || ""}</strong>
                </div>
                <div>
                  {i18n.t("beacon-owner-telephone-number-1")}: <strong>{UserState.maintProviderInfo.phone1Num || ""}</strong>
                </div>
                <div>
                  {i18n.t("beacon-owner-telephone-number-2")}: <strong>{UserState.maintProviderInfo.phone2Num || ""}</strong>
                </div>
              </div>
            )}
          </div>

          <div className="print-column" style={{ display: "inline-block", width: "45%", verticalAlign: "top" }}>
            {beaconType.includes("ELT") ? (
              <div>
                {decodedBeacon.vehicleType && (
                  <div>
                    {i18n.t("vehicle-type")}:{" "}
                    <strong>
                      {decodedBeacon.vehicleType || ""}
                      {decodedBeacon.vehicleType == "Other" ? " (" + decodedBeacon.vehicleTypeOther + ")" : ""}
                    </strong>
                  </div>
                )}

                {decodedBeacon.aircraft24BitAddress && decodedBeacon.aircraft24BitAddress != "" ? (
                  <div>
                    {i18n.t("aircraft-24-bit-address")}: <strong>{decodedBeacon.aircraft24BitAddress}</strong>
                  </div>
                ) : (
                  ""
                )}

                {decodedBeacon.aircraft24BitAddressDecoded && decodedBeacon.aircraft24BitAddressDecoded != "" ? (
                  <div>
                    {i18n.t("aircraft-24-bit-address-decoded")}: <strong>{decodedBeacon.aircraft24BitAddressDecoded}</strong>
                  </div>
                ) : (
                  ""
                )}

                {decodedBeacon.vehicleRegistrationNumber && (
                  <div>
                    {i18n.t("vehicle-registration-number")}: <strong>{decodedBeacon.vehicleRegistrationNumber || ""}</strong>
                  </div>
                )}

                {decodedBeacon.homeICAOCode && (
                  <div>
                    {i18n.t("home-icao-code")}: <strong>{decodedBeacon.homeICAOCode || ""}</strong>
                  </div>
                )}

                {(decodedBeacon.aircraftManufacturer || decodedBeacon.vehicleManufacturer) && (
                  <div>
                    {i18n.t("aircraft-manufacturer")}: <strong>{decodedBeacon.aircraftManufacturer || decodedBeacon.vehicleManufacturer || ""}</strong>
                  </div>
                )}

                {(decodedBeacon.aircraftModel || decodedBeacon.vehicleModel) && (
                  <div>
                    {i18n.t("aircraft-model")}: <strong>{decodedBeacon.aircraftModel || decodedBeacon.vehicleModel || ""}</strong>
                  </div>
                )}

                {(decodedBeacon.color || decodedBeacon.aircraftColor) && (
                  <div>
                    {i18n.t("aircraft-colour")}: <strong>{decodedBeacon.aircraftColor || decodedBeacon.color || ""}</strong>
                  </div>
                )}

                {decodedBeacon.aircraftOperatingAgency && (
                  <div>
                    {i18n.t("aircraft-agency")}: <strong>{decodedBeacon.aircraftOperatingAgency || ""}</strong>
                  </div>
                )}

                {decodedBeacon.aircraftOperatingAgencyPhone && (
                  <div>
                    {i18n.t("aircraft-agency-phone")}: <strong>{decodedBeacon.aircraftOperatingAgencyPhone || ""}</strong>
                  </div>
                )}
                {decodedBeacon.radioEquipment && (
                  <div>
                    {i18n.t("radio-equipment")}: <strong>{decodedBeacon.radioEquipment || ""}</strong>
                  </div>
                )}
                {decodedBeacon.radioEquipmentOther && (
                  <div>
                    {i18n.t("radio-equipment-other")}: <strong>{decodedBeacon.radioEquipmentOther || ""}</strong>
                  </div>
                )}
                <br />
                {decodedBeacon.deployableSurvivalCrafts && (
                  <div>
                    {i18n.t("deployable-survival-crafts")}: <strong>{decodedBeacon.deployableSurvivalCrafts || ""}</strong>
                  </div>
                )}
                {decodedBeacon.fixedSurvivalCrafts && (
                  <div>
                    {i18n.t("fixed-survival-crafts")}: <strong>{decodedBeacon.fixedSurvivalCrafts || ""}</strong>
                  </div>
                )}

                {decodedBeacon.maxEndurance && (
                  <div>
                    {i18n.t("max-endurance")}: <strong>{decodedBeacon.maxEndurance || ""}</strong>
                  </div>
                )}
                {decodedBeacon.cruiseAirSpeed && (
                  <div>
                    {i18n.t("cruise-air-speed")}: <strong>{decodedBeacon.cruiseAirSpeed || ""}</strong>
                  </div>
                )}
                {decodedBeacon.hasOwnProperty("length") && (
                  <div>
                    {i18n.t("length")}: <strong>{decodedBeacon.length || "0"}</strong>
                  </div>
                )}
                {decodedBeacon.wingSpan && (
                  <div>
                    {i18n.t("wingspan")}: <strong>{decodedBeacon.wingSpan || ""}</strong>
                  </div>
                )}
                {decodedBeacon.peopleCapacity && (
                  <div>
                    {i18n.t("people-capacity")}: <strong>{decodedBeacon.peopleCapacity || ""}</strong>
                  </div>
                )}

                {decodedBeacon.vehicleNationality && (
                  <div>
                    {i18n.t("aircraft-nationality")}: <strong>{decodedBeacon.vehicleNationality || ""}</strong>
                  </div>
                )}
                {decodedBeacon.additionalComment && (
                  <div>
                    {i18n.t("additional-vehicle-usage-information")}: <strong>{decodedBeacon.additionalComment || ""}</strong>
                  </div>
                )}
                {decodedBeacon.sarHazard && (
                  <div>
                    {i18n.t("sar-hazard")}: <strong>{decodedBeacon.sarHazard || ""}</strong>
                  </div>
                )}
              </div>
            ) : beaconType.includes("EPIRB") ? (
              <div>
                {decodedBeacon.vehicleType && (
                  <div>
                    {i18n.t("vehicle-type")}: <strong>{decodedBeacon.vehicleType || ""} </strong>
                  </div>
                )}
                {decodedBeacon.vehicleRegistrationNumber && (
                  <div>
                    {i18n.t("vehicle-registration-number")}: <strong>{decodedBeacon.vehicleRegistrationNumber || ""}</strong>
                  </div>
                )}
                {decodedBeacon.vehicleName && (
                  <div>
                    {i18n.t("vessel-name")}: <strong>{decodedBeacon.vehicleName || ""} </strong>
                  </div>
                )}
                {decodedBeacon.vehicleModel && (
                  <div>
                    {i18n.t("vessel-model")}: <strong>{decodedBeacon.vehicleModel || ""}</strong>
                  </div>
                )}
                {decodedBeacon.homePort && (
                  <div>
                    {i18n.t("home-port")}: <strong>{decodedBeacon.homePort || ""} </strong>
                  </div>
                )}

                {decodedBeacon.vehicleColor && (
                  <div>
                    {i18n.t("vessel-colour")}: <strong>{decodedBeacon.vehicleColor || ""}</strong>
                  </div>
                )}
                {decodedBeacon.hasOwnProperty("nbLifeBoat") && (
                  <div>
                    {i18n.t("number-of-life-boats")}: <strong>{decodedBeacon.nbLifeBoat || "0"} </strong>
                  </div>
                )}
                {decodedBeacon.hasOwnProperty("nbLifeRaft") && (
                  <div>
                    {i18n.t("number-of-life-rafts")}: <strong>{decodedBeacon.nbLifeRaft || "0"}</strong>
                  </div>
                )}

                {decodedBeacon.radioEquipment && (
                  <div>
                    {i18n.t("radio-equipment")}:{" "}
                    <strong>
                      {decodedBeacon.radioEquipment || ""}
                      {decodedBeacon.radioEquipment == "Other" ? " (" + decodedBeacon.radioEquipmentOther + ")" : ""}
                    </strong>
                  </div>
                )}

                {decodedBeacon.hasOwnProperty("callSign") && (
                  <div>
                    {i18n.t("radio-call-sign")}: <strong>{decodedBeacon.callSign}</strong>
                  </div>
                )}
                {decodedBeacon.hasOwnProperty("callSignDecoded") && (
                  <div>
                    {i18n.t("radio-call-sign-decoded")}: <strong>{decodedBeacon.callSignDecoded} </strong>
                  </div>
                )}
                {decodedBeacon.hasOwnProperty("aisNumber") && (
                  <div>
                    {i18n.t("ais-number")}: <strong>{decodedBeacon.aisNumber}</strong>
                  </div>
                )}
                {decodedBeacon.hasOwnProperty("Imarsat") && (
                  <div>
                    {i18n.t("inmarsat")}: <strong>{decodedBeacon.Imarsat} </strong>
                  </div>
                )}

                {decodedBeacon.vehicleCellularNum && (
                  <div>
                    {i18n.t("vessel-cellular")}: <strong>{decodedBeacon.vehicleCellularNum || ""}</strong>
                  </div>
                )}
                {decodedBeacon.vehicleSatellitePhone && (
                  <div>
                    {i18n.t("vessel-satellite-phone")}: <strong>{decodedBeacon.vehicleSatellitePhone || ""} </strong>
                  </div>
                )}
                {decodedBeacon.MMSI && (
                  <div>
                    {i18n.t("mmsi-number")}: <strong>{decodedBeacon.MMSI || ""}</strong>
                  </div>
                )}
                {decodedBeacon.MMSIDecoded && (
                  <div>
                    {i18n.t("mmsi-number-decoded")}: <strong>{decodedBeacon.MMSIDecoded || ""} </strong>
                  </div>
                )}
                {decodedBeacon.hasOwnProperty("length") && (
                  <div>
                    {i18n.t("length")}: <strong>{decodedBeacon.length || "0"}</strong>
                  </div>
                )}
                {decodedBeacon.hasOwnProperty("peopleCapacity") && (
                  <div>
                    {i18n.t("capacity-crew-and-passengers")}: <strong>{decodedBeacon.peopleCapacity || ""}</strong>
                  </div>
                )}
                {decodedBeacon.vehicleNationality && (
                  <div>
                    {i18n.t("vehicle-nationality")}: <strong>{countryCodeToText(decodedBeacon.vehicleNationality) || ""}</strong>
                  </div>
                )}
                {decodedBeacon.equippedWithDataRecord && (
                  <div>
                    {i18n.t("equipped-with-simplified-voyage-data-recorder")}: <strong>{decodedBeacon.equippedWithDataRecord || ""}</strong>
                  </div>
                )}
                {decodedBeacon.additionalComment && (
                  <div>
                    {i18n.t("additional-vehicle-usage-information")}: <strong>{decodedBeacon.additionalComment || ""}</strong>
                  </div>
                )}
                {decodedBeacon.sarHazard && (
                  <div>
                    {i18n.t("sar-hazard")}: <strong>{decodedBeacon.sarHazard || ""}</strong>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div>
                  <b>{i18n.t("vehicle-type")}:</b> {decodedBeacon.vehicleType || ""}{" "}
                </div>
                <div>
                  <b>{i18n.t("specific-usage")}:</b> {decodedBeacon.usageMoreInfo || ""}
                  {decodedBeacon.usageMoreInfo == "Other" ? " (" + decodedBeacon.usageMoreInfoOther + ")" : ""}
                </div>
                <div>
                  <b>{i18n.t("additional-vehicle-usage-information")}:</b> {decodedBeacon.additionalComment || ""}
                </div>
                {decodedBeacon.sarHazard && (
                  <div>
                    {i18n.t("sar-hazard")}: <strong>{decodedBeacon.sarHazard || ""}</strong>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
