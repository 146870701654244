import React, { Component } from 'react';
import { Form, Input, Button, Popup, Select, Header, Message, Icon } from 'semantic-ui-react';
import RouteState from '../../../state/routeState';
import VehicleState from '../../../state/vehicleState';

export default class VehicleComplete extends Component {

	returnToDashboardClick = () => {
		VehicleState.resetSteps();
		RouteState.setRoute('/dashboard/beacons');		
	}

	addNewVehicleClick = () =>{
		VehicleState.resetSteps();
		VehicleState.resetData();
		RouteState.setRoute('/registerVehicle');
	}

	render() {
		return (
			<div>
				<Header as='h2' icon textAlign='center'>
					<Icon name='check' circular color="green" />
					<Header.Content>Registration Complete!</Header.Content>
				</Header>
				<div className="activityDetail-submitContainer">
						<Button className="registerBeacon-submitButton" onClick={this.returnToDashboardClick} >Return to dashboard</Button>
						<Button className="registerBeacon-submitButton" type="submit" primary onClick={this.addNewVehicleClick}>Add a new vehicle</Button>
					</div>
			</div>
		)
	}
}