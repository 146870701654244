import i18n from "i18next";
import React, { Component } from "react";
import { observer } from "mobx-react";
import { Container, Header, Grid, Divider, Button, Icon, GridRow, GridColumn } from "semantic-ui-react";
import ActivityState from "../../../state/activityState";
import AssociatedUserState from "../../../state/associatedUserState";
import VehicleState from "../../../state/vehicleState";
import AppConfigState from "../../../state/appConfigState";
import * as BeaconService from "../../../services/beaconService";
import { EPIRBVehicleDisplay, ELTVehicleDisplay } from "./vehicleDetailForm";
import DashboardState from "../../../state/dashboardState";
import { toJS } from "mobx";

@observer
export default class ActivityReview extends Component {
  state = {
    beaconInfo: {},
  };

  onEditActivityClick = () => {
    ActivityState.setCurrentStep(0);
    ActivityState.setActivityDetailCurrentStep(0);
  };

  onEditUserClick = () => {
    ActivityState.setCurrentStep(1);
    AssociatedUserState.setCurrentStep(0);
  };

  onEditBeaconClick = () => {
    ActivityState.setCurrentStep(0);
  };

  onEditVehicleClick = () => {
    ActivityState.setCurrentStep(3);
    VehicleState.setCurrentStep(0);
  };

  onBackClick = () => {
    ActivityState.setPreviousStep();
  };

  onSaveClick = () => {
    let beaconInfo = {
      beaconType: this.state.beaconInfo.beaconType,
      beaconCountryCode: this.state.beaconInfo.beaconCountryCode,
    };
    ActivityState.updateActivityFields(beaconInfo);
    ActivityState.saveActivity().then(() => {
      ActivityState.setNextStep();
    });
  };

  componentDidMount = async () => {
    // let beconInfo = BeaconService.getBeacon(ActivityState.activityFields.selectBeacon)
    let beaconInfo = await BeaconService.getBeacon(ActivityState.activityFields.selectBeacon);
    this.setState({ beaconInfo: beaconInfo });
    console.log(">>>>>>>>>>>>> beacon Info: ", beaconInfo);

    let activity = ActivityState.getActivityFields();
    DashboardState.getTotalActivitiesByOwner(activity.owner);
  };

  onViewAllActivitiesOfOwner = () => {
    const { onClose, onSearchActivityByOwner } = this.props;
    let activity = ActivityState.getActivityFields();

    onSearchActivityByOwner(activity.owner);
    onClose();
  };

  render() {
    let { beaconInfo } = this.state;
    let { hideButtons, activity, showOwnerActivityNumber } = this.props;
    let activityFields;
    if (activity) {
      activityFields = activity;
    } else {
      activityFields = ActivityState.getActivityFields();
    }
    let associatedUserFields = AssociatedUserState.getUserFields();
    let vehicleFields = VehicleState.getVehicleFields();

    let departureText = activityFields.duration == "specific" ? activityFields.durationSpecificStartDay + "/" + (activityFields.durationSpecificStartMonth + 1) + "/" + activityFields.durationSpecificStartYear : "";
    let arrivalText = activityFields.duration == "specific" ? activityFields.durationSpecificEndDay + "/" + (activityFields.durationSpecificEndMonth + 1) + "/" + activityFields.durationSpecificEndYear : "";

    let totalAdult = activityFields.participantAdults || 0;
    let totalTeen = activityFields.participantTeens || 0;
    let totalChildren = activityFields.participantChildren || 0;
    let allPaticipantText = totalAdult + " Adults | " + totalTeen + " Teens | " + totalChildren + " Children";

    let userName = (activityFields.firstName || "") + " " + (activityFields.lastName || "");
    let isElt = ActivityState.isAnEltAssociated(activity);
    let isEPIRB = ActivityState.isAnEpirbAssociated(activity);
    let isEltOrEpirb = ActivityState.isAnEltOrAnEpirbAssociated(activity);
    // let userPhone = (activityFields.phoneNumber || '');
    // let userContact = '';

    // let beaconType = activityFields.beaconType;
    // let beaconMailCountry = activityFields.beaconMailCountry;
    // console.log('activityFields.vehicleGroup', activityFields.vehicleGroup)

    let activityNumber = null;
    if (showOwnerActivityNumber) {
      activityNumber = (
        <div style={{ marginBottom: 20 }}>
          <a className="beaconReviewDetails-Link" onClick={this.onViewAllActivitiesOfOwner}>
            {i18n.t("account-owner-has")} <b>{DashboardState.totalActivitiesForUser}</b> {i18n.t("activities-for-his-registered-beacons-click-here-to-view-all-activities")}
          </a>
        </div>
      );
    }

    return (
      // <Container className='beaconReviewDetails-mainFormContainer animated fadeIn faster'>
      <Container className="activityReviewDetails-container animated fadeIn faster">
        <Header as="h2" className="beaconReviewDetails-header">
          <Header.Content>
            <span className="font-blue">{i18n.t("temporary-activity-summary")}</span>
          </Header.Content>
        </Header>
        {activityNumber}
        <Grid columns={2} className="beaconReviewDetails-grid" stackable>
          <Grid.Row>
            <Grid.Column>
              {/* Activity Info */}
              <Header className="font-blue">
                {"Activity".toUpperCase()} <span className="noprint">|</span>{" "}
                {!hideButtons && (
                  <a onClick={this.onEditActivityClick} className="beaconReviewDetails-editLink noprint">
                    {i18n.t("edit")}
                  </a>
                )}
              </Header>
              <div>
                {i18n.t("activity-name")}: <strong>{activityFields.activityName}</strong>
              </div>
              <div>
                {i18n.t("primary-activity")}: <strong>{activityFields.primaryActivity}</strong>
              </div>
              <div>
                {i18n.t("secondary-activity")}: <strong>{activityFields.secondaryActivity}</strong>
              </div>
              {/* <div>Country: <strong>{activityFields.countryCode + " - " + activityFields.countryName}</strong></div> */}

              {activityFields.duration == "specific" ? (
                <>
                  <div>
                    {i18n.t("departure")}: <strong>{departureText}</strong>
                  </div>
                </>
              ) : (
                ""
              )}
              {/* <div>Departure: <strong>{departureText}</strong></div> */}
              <div>
                {i18n.t("departure-location-place")}: <strong>{activityFields.departureLocation}</strong>
              </div>
              <div>
                {i18n.t("city")}: <strong>{activityFields.departureNearestTown}</strong>
              </div>
              <div>
                {i18n.t("province-state")}: <strong>{activityFields.departureState}</strong>
              </div>
              <div>
                {i18n.t("country")}: <strong>{countryCodeToText(activityFields.departureCountry)}</strong>
              </div>

              {activityFields.duration == "specific" ? (
                <>
                  <div>
                    {i18n.t("arrival")}: <strong>{arrivalText}</strong>
                  </div>
                </>
              ) : (
                ""
              )}
              {/* <div>Arrival: <strong>{arrivalText}</strong></div> */}
              <div>
                {i18n.t("arrival-location-place")}: <strong>{activityFields.arrivalLocation}</strong>
              </div>
              <div>
                {i18n.t("city")}: <strong>{activityFields.arrivalNearestTown}</strong>
              </div>
              <div>
                {i18n.t("province-state")}: <strong>{activityFields.arrivalState}</strong>
              </div>
              <div>
                {i18n.t("country")}: <strong>{countryCodeToText(activityFields.arrivalCountry)}</strong>
              </div>

              {activityFields.duration != "specific" ? (
                <>
                  <div>
                    {i18n.t("duration")}: <strong>{activityFields.duration != "specific" ? activityFields.duration : ""}</strong>
                  </div>
                </>
              ) : (
                ""
              )}

              <div>
                {i18n.t("participants")}: <strong>{allPaticipantText}</strong>
              </div>
              <div>
                {i18n.t("additional-comments")}: <strong>{activityFields.participantAdditionalComments}</strong>
              </div>
              <div>
                {i18n.t("safety-equipment")}: <strong>{activityFields.safetyEquiptment}</strong>
              </div>
              <div>
                {i18n.t("communication-equipment")}: <strong>{activityFields.communicationEquiptment}</strong>
              </div>

              {/* Vehicle Info */}

              {isElt && <ELTVehicleDisplay hideButtons={true} />}
              {isEPIRB && <EPIRBVehicleDisplay hideButtons={true} />}

              {!isEltOrEpirb ? (
                <>
                  <Header className="font-blue">
                    {"Vehicle".toUpperCase()} <span className="noprint">|</span>{" "}
                    {!hideButtons && (
                      <a onClick={this.onEditVehicleClick} className="beaconReviewDetails-editLink noprint">
                        {i18n.t("edit")}
                      </a>
                    )}
                  </Header>
                  <div>
                    {i18n.t("vehicle-type")}:{" "}
                    <strong>
                      {activityFields.vehicleType}
                      {activityFields.vehicleType == "Other" && activityFields.vehicleTypeOther ? " - " + activityFields.vehicleTypeOther : ""}
                    </strong>
                  </div>
                  <div>
                    {i18n.t("vehicle-model")}: <strong>{activityFields.vehicleModel}</strong>
                  </div>
                  <div>
                    {i18n.t("registration-number")}: <strong>{activityFields.registrationNumber}</strong>
                  </div>
                  <div>
                    {i18n.t("registration-country")}: <strong>{countryCodeToText(activityFields.registrationCountry)}</strong>
                  </div>
                  <div>
                    {i18n.t("colour")}: <strong>{activityFields.vehicleColor}</strong>
                  </div>
                  <div>
                    {i18n.t("specific-usage")}:{" "}
                    <strong>
                      {activityFields.specificUsage} {activityFields.specificUsage == "Other" ? "(" + activityFields.specificUsageOther + ")" : ""}
                    </strong>
                  </div>

                  <div>
                    {i18n.t("additional-vehicle-usage-information")}: <strong>{activityFields.comment}</strong>
                  </div>
                  {activityFields.image1 && (
                    <div style={{ marginTop: "10px" }}>
                      <img style={{ width: "100%" }} src={activityFields.image1} />
                    </div>
                  )}
                  {activityFields.image2 && (
                    <div style={{ marginTop: "10px" }}>
                      <img style={{ width: "100%" }} src={activityFields.image2} />
                    </div>
                  )}
                </>
              ) : (
                ""
              )}
            </Grid.Column>

            <Grid.Column>
              {/* User Info */}
              <Header className="font-blue">
                {"User".toUpperCase()} <span className="noprint">|</span>{" "}
                {!hideButtons && (
                  <a onClick={this.onEditUserClick} className="beaconReviewDetails-editLink noprint">
                    {i18n.t("edit")}
                  </a>
                )}
              </Header>
              <div>
                {i18n.t("title")}: <strong>{activityFields.title}</strong>
              </div>
              <div>
                {i18n.t("name")}: <strong>{userName}</strong>
              </div>
              <div>
                {i18n.t("relationship-to-beacon-owner")}: <strong>{activityFields.userLink}</strong>
              </div>
              <div>
                {i18n.t("language")}: <strong>{activityFields.language}</strong>
              </div>
              <div>
                {i18n.t("medications-allergies")}: <strong>{activityFields.medicalInfo}</strong>
              </div>

              <div>
                {i18n.t("address")}: <strong>{activityFields.address}</strong>
              </div>
              <div>
                {i18n.t("city")}: <strong>{activityFields.city}</strong>
              </div>
              <div>
                {i18n.t("state-province")}: <strong>{activityFields.province}</strong>
              </div>
              <div>
                {i18n.t("country")}: <strong>{countryCodeToText(activityFields.mailCountry)}</strong>
              </div>
              <div>
                {i18n.t("zip-postal-code-0")}: <strong>{activityFields.mailCode}</strong>
              </div>

              <div>
                {i18n.t("phone-1")}:{" "}
                <strong>
                  {activityFields.phoneNumber} {activityFields.phoneType ? "(" + phoneTypeToText(activityFields.phoneType, activityFields.phoneTypeOther) + ")" : ""}
                </strong>
              </div>
              {activityFields.phoneNumber2 && (
                <div>
                  {i18n.t("phone-2")}:{" "}
                  <strong>
                    {activityFields.phoneNumber2} {activityFields.phoneType2 ? "(" + phoneTypeToText(activityFields.phoneType2, activityFields.phoneType2Other) + ")" : ""}
                  </strong>
                </div>
              )}

              {/* <div><strong>Emergency Contact</strong></div> */}
              <div>
                {i18n.t("24-hour-emergency-contact-name")}: <strong>{activityFields.primaryContactName}</strong>
              </div>
              <div>
                {i18n.t("emergency-contact-address-line-1")}: <strong>{activityFields.primaryContactAddressLine1}</strong>
              </div>
              <div>
                {i18n.t("emergency-contact-address-line-2")}: <strong>{activityFields.primaryContactAddressLine2}</strong>
              </div>
              {activityFields.primaryPhone1Num && (
                <div>
                  {i18n.t("emergency-contact-phone-number-1")}:{" "}
                  <strong>
                    {activityFields.primaryPhone1Num} {activityFields.primaryPhone1Type ? "(" + phoneTypeToText(activityFields.primaryPhone1Type, activityFields.primaryPhone1TypeOther) + ")" : ""}
                  </strong>
                </div>
              )}
              {activityFields.primaryPhone2Num && (
                <div>
                  {i18n.t("emergency-contact-phone-number-2")}:{" "}
                  <strong>
                    {activityFields.primaryPhone2Num} {activityFields.primaryPhone2Type ? "(" + phoneTypeToText(activityFields.primaryPhone2Type, activityFields.primaryPhone2TypeOther) + ")" : ""}
                  </strong>
                </div>
              )}
              {activityFields.primaryPhone3Num && (
                <div>
                  {i18n.t("emergency-contact-phone-number-3")}:{" "}
                  <strong>
                    {activityFields.primaryPhone3Num} {activityFields.primaryPhone3Type ? "(" + phoneTypeToText(activityFields.primaryPhone3Type, activityFields.primaryPhone3TypeOther) + ")" : ""}
                  </strong>
                </div>
              )}
              {activityFields.primaryPhone4Num && (
                <div>
                  {i18n.t("emergency-contact-phone-number-4")}:{" "}
                  <strong>
                    {activityFields.primaryPhone4Num} {activityFields.primaryPhone4Type ? "(" + phoneTypeToText(activityFields.primaryPhone4Type, activityFields.primaryPhone4TypeOther) + ")" : ""}
                  </strong>
                </div>
              )}

              <div>
                {i18n.t("alternate-24-hour-emergency-contact-name")}: <strong>{activityFields.alternateContactName}</strong>
              </div>
              <div>
                {i18n.t("alternate-contact-address-line-1")}: <strong>{activityFields.alternateContactAddressLine1}</strong>
              </div>
              <div>
                {i18n.t("alternate-contact-address-line-2")}: <strong>{activityFields.alternateContactAddressLine2}</strong>
              </div>
              {activityFields.alternatePhone1Num && (
                <div>
                  {i18n.t("alternate-contact-phone-number-1")}:{" "}
                  <strong>
                    {activityFields.alternatePhone1Num} {activityFields.alternatePhone1Type ? "(" + phoneTypeToText(activityFields.alternatePhone1Type, activityFields.alternatePhone1TypeOther) + ")" : ""}
                  </strong>
                </div>
              )}
              {activityFields.alternatePhone2Num && (
                <div>
                  {i18n.t("alternate-contact-phone-number-2")}:{" "}
                  <strong>
                    {activityFields.alternatePhone2Num} {activityFields.alternatePhone2Type ? "(" + phoneTypeToText(activityFields.alternatePhone2Type, activityFields.alternatePhone2TypeOther) + ")" : ""}
                  </strong>
                </div>
              )}
              {activityFields.alternatePhone3Num && (
                <div>
                  {i18n.t("alternate-contact-phone-number-3")}:{" "}
                  <strong>
                    {activityFields.alternatePhone3Num} {activityFields.alternatePhone3Type ? "(" + phoneTypeToText(activityFields.alternatePhone3Type, activityFields.alternatePhone3TypeOther) + ")" : ""}
                  </strong>
                </div>
              )}
              {activityFields.alternatePhone4Num && (
                <div>
                  {i18n.t("alternate-contact-phone-number-4")}:{" "}
                  <strong>
                    {activityFields.alternatePhone4Num} {activityFields.alternatePhone4Type ? "(" + phoneTypeToText(activityFields.alternatePhone4Type, activityFields.alternatePhone4TypeOther) + ")" : ""}
                  </strong>
                </div>
              )}

              {/* Beacon Info */}
              <Header className="font-blue">
                {"Beacon".toUpperCase()} <span className="noprint">|</span>{" "}
                {!hideButtons && (
                  <a onClick={this.onEditBeaconClick} className="beaconReviewDetails-editLink noprint">
                    {i18n.t("edit")}
                  </a>
                )}
              </Header>
              <div>
                {i18n.t("beacon-hex-id")}: <strong>{beaconInfo.hexId}</strong>
              </div>
              <div>
                {i18n.t("beacon-type")}: <strong>{beaconInfo.beaconType}</strong>
              </div>
              <div>
                {i18n.t("country")}:{" "}
                <strong>
                  {beaconInfo.beaconCountry}
                  {beaconInfo.beaconCountryCode && " (" + beaconInfo.beaconCountryCode + ")"}
                </strong>
              </div>

              <br />
              <br />
              <div>
                <a className="beaconReviewDetails-editLink noprint" onClick={() => window.print()}>
                  {" "}
                  <Icon name="print" color="grey" size="large" />
                  {i18n.t("print-activity-summary")}
                </a>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {!hideButtons && (
          <div className="activityDetail-submitContainer noprint">
            <Button className="registerBeacon-submitButton" onClick={this.onBackClick}>
              {i18n.t("back")}
            </Button>
            <Button className="registerBeacon-submitButton" type="submit" primary onClick={this.onSaveClick} loading={ActivityState.isSaving}>
              {i18n.t("save-activity")}
            </Button>
          </div>
        )}
      </Container>
    );
  }
}

function countryCodeToText(countryCode) {
  if (!countryCode) return;

  let countryInfo = AppConfigState.countryNamesWithCode.find((row) => row.key == countryCode);
  return countryInfo.text;
}

function phoneTypeToText(id, otherType = "") {
  let phoneTypes = AppConfigState.phoneTypes;
  let item = phoneTypes.find((row) => row.value == id);
  if (!item) return "";
  if (item.value == "OTHR") {
    return item.text + " - " + otherType;
  }
  return item.text;
}
