import i18n from "i18next";
import { toJS } from "mobx";
import React, { Component } from "react";
import * as Yup from "yup";
import { Form, Input, Button, Popup, Select, Header, Message, Radio, Icon, Label, Container, FormField } from "semantic-ui-react";
import DashboardState from "../../../state/dashboardState";
import AssociatedUserState from "../../../state/associatedUserState";
import withSemanticUIFormik from "../../../utils/formHelper";
import { countryCode } from "../../../utils/beaconDecode/countryCode";
import AppConfigState from "../../../state/appConfigState";
import RouteState from "../../../state/routeState";
import ActivityState from "../../../state/activityState";
import { FormErrorDisplay } from "../../../components/formErrorDisplay";

const countryOptions = countryCode.map((country) => {
  return {
    text: country.code + " - " + country.country,
    value: country.code,
    key: country.code,
  };
});

const titleOptions = [
  { key: "mr", text: "Mr", value: "Mr" },
  { key: "ms", text: "Ms", value: "Ms" },
  { key: "mrs", text: "Mrs", value: "Mrs" },
  { key: "other", text: "Other", value: "Other" },
];

var userList = [
  { key: "u1", text: "User1", value: "User1" },
  { key: "u2", text: "User2", value: "User2" },
  { key: "u3", text: "User3", value: "User3" },
  { key: "u4", text: "User4", value: "User4" },
];

const contactNoOptions = [{ key: "1", text: "1", value: "1" }, { key: "2", text: "2", value: "2" }];

const numberOptions = [];
for (let i = 0; i < 50; i++) {
  numberOptions[i] = {};
  numberOptions[i].key = i;
  numberOptions[i].value = i;
  numberOptions[i].text = i;
}

// const phoneTypeOptions = [
// 	{ key: 'cellular', text: 'cellular', value: 'cellular' },
// 	{ key: 'home', text: 'home', value: 'home' },
// 	{ key: 'work', text: 'work', value: 'work' },
// 	{ key: 'fax', text: 'fax', value: 'fax' },
// 	{ key: 'other', text: 'other', value: 'other' },
// ]

class InnerForm extends Component {
  state = {
    anotherContact: false,
    userOptions: [],
    userInfos: [],
  };

  onClose = () => {
    // if (AssociatedUserState.hasRelatedActivity) {
    //   ActivityState.setPreviousStep();
    // } else {
    //   this.props.onCanCel();
    // }
    ActivityState.setPreviousStep();
  };

  addAnotherContactNumber = () => {
    this.setState({
      anotherContact: true,
    });
  };
  removeSecondContact = () => {
    this.setState({
      anotherContact: false,
    });

    // reset data
    this.props.values.phonePrefix2 = "";
    this.props.values.phoneNumber2 = "";
    this.props.values.phoneType2 = "";
  };

  componentDidMount = () => {
    DashboardState.searchAssociatedUsers({}).then((response) => {
      if (!response) return;
      const user = response.map((row, index) => {
        return {
          // text: row.firstName + " " + row.lastName + " (" + row._id + ")",
          text: row.firstName + " " + row.lastName,
          value: row._id,
          key: row._id,
        };
      });

      this.setState({
        userOptions: user,
        userInfos: response,
      });
    });

    /*if(this.props.user.phoneNumber2){
            this.setState({anotherContact: true})
        }*/
  };

  onSelectUserChange = (e, data) => {
    // console.log("+++++++++++++++++++++++++++++++ " + data.value)
    // set selected when changed
    if (data && data.name) {
      this.props.setFieldValue(data.name, data.value);
    }

    // set selecting user info
    var selecting = this.state.userInfos.find((row) => row._id === data.value);
    // console.log(selecting)
    // AssociatedUserState.setUserFields(selecting)

    this.props.values.title = selecting.title;
    this.props.values.lastName = selecting.lastName;
    this.props.values.firstName = selecting.firstName;
    this.props.values.userLink = selecting.userLink;
    this.props.values.operationLanguage = selecting.operationLanguage;
    this.props.values.medicalInfo = selecting.medicalInfo;
    this.props.values.address = selecting.address;
    this.props.values.city = selecting.city;
    this.props.values.province = selecting.province;
    this.props.values.phonePrefix = selecting.phonePrefix;
    this.props.values.phoneNumber = selecting.phoneNumber;
    this.props.values.phoneType = selecting.phoneType;
    this.props.values.phonePrefix2 = selecting.phonePrefix2;
    this.props.values.phoneNumber2 = selecting.phoneNumber2;
    this.props.values.phoneType2 = selecting.phoneType2;

    this.props.values.primaryContactName = selecting.primaryContactName;
    this.props.values.primaryContactAddressLine1 = selecting.primaryContactAddressLine1;
    this.props.values.primaryContactAddressLine2 = selecting.primaryContactAddressLine2;
    this.props.values.primaryPhone1Num = selecting.primaryPhone1Num;
    this.props.values.primaryPhone1Type = selecting.primaryPhone1Type;
    this.props.values.primaryPhone2Num = selecting.primaryPhone2Num;
    this.props.values.primaryPhone2Type = selecting.primaryPhone2Type;
    this.props.values.primaryPhone3Num = selecting.primaryPhone3Num;
    this.props.values.primaryPhone3Type = selecting.primaryPhone3Type;
    this.props.values.primaryPhone4Num = selecting.primaryPhone4Num;
    this.props.values.primaryPhone4Type = selecting.primaryPhone4Type;

    this.props.values.alternateContactName = selecting.alternateContactName;
    this.props.values.alternateContactAddressLine1 = selecting.alternateContactAddressLine1;
    this.props.values.alternateContactAddressLine2 = selecting.alternateContactAddressLine2;
    this.props.values.alternatePhone1Num = selecting.alternatePhone1Num;
    this.props.values.alternatePhone1Type = selecting.alternatePhone1Type;
    this.props.values.alternatePhone2Num = selecting.alternatePhone2Num;
    this.props.values.alternatePhone2Type = selecting.alternatePhone2Type;
    this.props.values.alternatePhone3Num = selecting.alternatePhone3Num;
    this.props.values.alternatePhone3Type = selecting.alternatePhone3Type;
    this.props.values.alternatePhone4Num = selecting.alternatePhone4Num;
    this.props.values.alternatePhone4Type = selecting.alternatePhone4Type;

    if (selecting.phoneNumber2 === "") {
      this.setState({
        anotherContact: false,
      });
    } else {
      this.setState({
        anotherContact: true,
      });
    }
  };

  saveAndClose = () => {
    if (ActivityState.isEditActivity) {
      ActivityState.setIsSaving(true);
      AssociatedUserState.updateUserFields(this.props.values);
      ActivityState.saveActivity().then(() => {
        ActivityState.setIsSaving(false);
        RouteState.history.goBack();
      });
    }
  };

  render() {
    const { values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, isValid, onClose, allowSelectUser } = this.props;
    console.log("log: UserDetailForm -> values", values);

    const phoneTypeOptions = AppConfigState.phoneTypes;

    let phoneNumberError = touched.phoneNumber && errors.phoneNumber && errors.phoneNumber.length > 0;
    let phoneNumber2Error = touched.phoneNumber2 && errors.phoneNumber2 && errors.phoneNumber2.length > 0;
    let hasError = Object.keys(errors).length !== 0;

    var SecondContact;
    var contactElement = (
      <Container className="removeSecondContac-container">
        <Form.Group className="additional-phone">
          <Form.Input
            width="8"
            fluid
            name="phoneNumber2"
            label=""
            placeholder=""
            value={values.phoneNumber2}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!ActivityState.isEditable()}
            error={phoneNumber2Error}
          />
          <Form.Field width="8">
            <Form.Group widths="equal" className="phone-type-other">
              <Form.Select
                fluid
                name="phoneType2"
                label=""
                options={phoneTypeOptions}
                placeholder="Type"
                value={values.phoneType2}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!ActivityState.isEditable()}
              />
              {values.phoneType2 == "OTHR" ? (
                <Form.Input
                  fluid
                  placeholder=""
                  name="phoneType2Other"
                  value={values.phoneType2Other}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!ActivityState.isEditable()}
                />
              ) : (
                (values.phoneType2Other = "")
              )}
            </Form.Group>
          </Form.Field>

          <Popup
            content={i18n.t('delete-this-number')}
            trigger={
              <a
                className="removeSecondContact registerUser-additionnalContactRemove"
                onClick={
                  ActivityState.isEditable()
                    ? this.removeSecondContact
                    : (e) => {
                        e.preventDefault();
                      }
                }
              >
                <Icon name="trash circle" color="red" size="large" />
              </a>
            }
          />
        </Form.Group>
        {/* <Popup
                    content="Delete this number"
                    trigger={<a className="removeSecondContact" onClick={ActivityState.isEditable() ? this.removeSecondContact : (e) => {e.preventDefault()}}><Icon name="trash circle" color="red" size="large" /></a>}
                /> */}
      </Container>
    );

    var addAnotherContact;
    var AnotherContactButton = (
      <Container>
        <br />
        <Form.Group widths="equal">
          <a
            className="beaconReviewDetails-editLink"
            onClick={
              ActivityState.isEditable()
                ? this.addAnotherContactNumber
                : (e) => {
                    e.preventDefault();
                  }
            }
          >
            {" "}
            <Icon name="plus circle" color="red" size="large" />
            {i18n.t('add-another-contact-number')}
          </a>
        </Form.Group>
      </Container>
    );

    if (this.state.anotherContact) {
      SecondContact = contactElement;
      addAnotherContact = "";
    } else {
      SecondContact = "";
      addAnotherContact = AnotherContactButton;
    }

    // scroll to error
    if (isSubmitting && Object.keys(errors).length > 0) {
      var errorBox = document.getElementById("scrollToError");
      errorBox.scrollIntoView();
    }

    return (
      <Form className="activityDetail-form animated fadeIn faster" error={hasError}>
        <Header as="h2" icon textAlign="center">
          <Header.Content>{i18n.t('identify-the-participant-beacon-holder-responsible-for-the-beacon-during-the-activity')}</Header.Content>
        </Header>
        <div id="scrollToError" />
        <FormErrorDisplay errors={errors} touched={touched} status={status} />

        <div style={allowSelectUser ? { display: "block" } : { display: "none" }}>
          <Form.Field>
            <Form.Radio
              label={i18n.t('select-an-existing-beacon-holder')}
              name="userGroup"
              value="selectExistingUser"
              checked={values.userGroup === "selectExistingUser"}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!ActivityState.isEditable()}
            />
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Select
              name="selectUser"
              options={this.state.userOptions}
              placeholder="Select"
              onChange={this.onSelectUserChange}
              onBlur={handleBlur}
              value={values.selectUser}
              disabled={!ActivityState.isEditable() || !(values.userGroup === "selectExistingUser")}
            />
            <Form.Field />
          </Form.Group>

          <Form.Field>
            <Form.Radio
              label="Add a new user"
              name="userGroup"
              value="addNewUser"
              checked={values.userGroup === "addNewUser"}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!ActivityState.isEditable()}
            />
          </Form.Field>
        </div>

        <Form.Group widths="equal">
          <Form.Field>
            <Form.Select
              fluid
              name="title"
              label="Title"
              placeholder=""
              value={values.title}
              onBlur={handleBlur}
              onChange={handleChange}
              options={titleOptions}
              disabled={!ActivityState.isEditable()}
              className="editing-select"
            />
          </Form.Field>
          <Form.Field />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field>
            <label>
              {i18n.t('beacon-holder-first-name')}{" "}
              <Popup
                content={i18n.t('first-name-of-the-individual-possessing-the-beacon')}
                trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
              />
            </label>
            <Form.Input
              fluid
              name="firstName"
              placeholder=""
              value={values.firstName}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={!ActivityState.isEditable()}
              className="editing-input"
            />
          </Form.Field>
          <Form.Field>
            <label>
              {i18n.t('beacon-holder-last-name')}{" "}
              <Popup
                content={i18n.t('last-name-of-the-individual-possessing-the-beacon')}
                trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
              />
            </label>
            <Form.Input
              fluid
              name="lastName"
              placeholder=""
              value={values.lastName}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={!ActivityState.isEditable()}
              className="editing-input"
            />
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field>
            <Form.Input
              fluid
              name="userLink"
              label={i18n.t('relationship-to-beacon-owner')}
              placeholder=""
              value={values.userLink}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!ActivityState.isEditable()}
              className="editing-input"
            />
          </Form.Field>

          <Form.Field>
            <label>
              Language{" "}
              <Popup
                content={i18n.t('spoken-language-of-the-individual-possessing-the-beacon')}
                trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
              />
            </label>
            <Form.Input
              fluid
              name="operationLanguage"
              label=""
              placeholder=""
              value={values.operationLanguage}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!ActivityState.isEditable()}
              className="editing-input"
            />
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field>
            <label>
              Medications /Allergies{" "}
              <Popup content={i18n.t('list-any-medications-or-allergies')} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
            </label>
            <Form.Input
              fluid
              name="medicalInfo"
              placeholder=""
              value={values.medicalInfo}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!ActivityState.isEditable()}
              className="editing-input"
            />
          </Form.Field>

          <Form.Field />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Input
            fluid
            name="address"
            label="Address"
            placeholder=""
            value={values.address}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!ActivityState.isEditable()}
            className="editing-input"
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Input
            fluid
            name="city"
            label="City"
            placeholder=""
            value={values.city}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!ActivityState.isEditable()}
            className="editing-input"
          />
          <Form.Input
            fluid
            name="province"
            label="State/Province"
            placeholder=""
            value={values.province}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!ActivityState.isEditable()}
            className="editing-input"
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Select
            search
            fluid
            placeholder="Select"
            label="Country"
            name="mailCountry"
            value={values.mailCountry}
            options={countryOptions}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!ActivityState.isEditable()}
            className="editing-select"
          />

          <Form.Input
            fluid
            name="mailCode"
            label="ZIP / Postal Code"
            placeholder=""
            value={values.mailCode}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!ActivityState.isEditable()}
            className="editing-input"
          />
        </Form.Group>

        <Form.Group>
          <Form.Field>
            Contact Phone Number{" "}
            <Popup
              content={i18n.t('use-the-contact-phone-number-of-the-individual-possessing-the-beacon')}
              trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
              disabled={!ActivityState.isEditable()}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          {/* <Form.Select width='2' fluid name='phonePrefix' label='' options={contactNoOptions} placeholder='#' value='1' onChange={handleChange} onBlur={handleBlur} disabled /> */}
          <Form.Input
            width="8"
            fluid
            name="phoneNumber"
            label=""
            placeholder=""
            value={values.phoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!ActivityState.isEditable()}
            error={phoneNumberError}
            className="editing-input"
          />
          <Form.Field width="8">
            <Form.Group widths="equal" className="phone-type-other">
              <Form.Select
                fluid
                name="phoneType"
                label=""
                options={phoneTypeOptions}
                placeholder="Type"
                value={values.phoneType}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!ActivityState.isEditable()}
                className="editing-select"
              />
              {values.phoneType == "OTHR" ? (
                <Form.Input
                  fluid
                  placeholder=""
                  name="phoneTypeOther"
                  value={values.phoneTypeOther}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!ActivityState.isEditable()}
                  className="editing-input"
                />
              ) : (
                (values.phoneTypeOther = "")
              )}
            </Form.Group>
          </Form.Field>
        </Form.Group>

        

        {SecondContact}
        <Form.Field>
          <label>Communication Equipment</label>
          <Form.TextArea
            placeholder=""
            name="communicationEquipment"
            value={values.communicationEquipment}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!ActivityState.isEditable()}
          />
        </Form.Field>

        {/* <br/>
                <Form.Group widths='equal'>
                    <a className="beaconReviewDetails-editLink" onClick={this.addAnotherContactNumber}> <Icon name="plus circle" color="red" size="large" />Add another owner contact #</a>
                </Form.Group> */}

        {addAnotherContact}

        <Header as="h2" textAlign="center">
          <Header.Content>Provide the number of participants who will be partaking in the activity</Header.Content>
        </Header>

        <Header size="medium">Participants</Header>
        <Form.Group widths="equal">
          <Form.Field>
            <label>
              {"Adults (no:)"}{" "}
              <Popup
                content={i18n.t('specify-the-number-of-adults-participating-in-this-activity')}
                trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
              />
            </label>
            <Form.Select
              className="auto-min-width"
              placeholder="#"
              name="participantAdults"
              value={values.participantAdults}
              options={numberOptions}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!ActivityState.isEditable()}
            />
          </Form.Field>
          <Form.Field>
            <label>
              {"Teens (>= 12 years)"}{" "}
              <Popup
                content={i18n.t('specify-the-number-of-teens-participating-in-this-activity')}
                trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
              />
            </label>
            <Form.Select
              className="auto-min-width"
              placeholder="#"
              name="participantTeens"
              value={values.participantTeens}
              options={numberOptions}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!ActivityState.isEditable()}
            />
          </Form.Field>
          <Form.Field>
            <label>
              {"Children (< 12 years)"}{" "}
              <Popup
                content={i18n.t('specify-the-number-of-children-participating-in-this-activity')}
                trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
              />
            </label>
            <Form.Select
              className="auto-min-width"
              placeholder="#"
              name="participantChildren"
              value={values.participantChildren}
              options={numberOptions}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!ActivityState.isEditable()}
            />
          </Form.Field>
        </Form.Group>

        <Form.Field>
          <label>Remarks</label>
          <Form.TextArea
            placeholder=""
            name="participantAdditionalComments"
            value={values.participantAdditionalComments}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!ActivityState.isEditable()}
          />
        </Form.Field>

        <Header size="medium">Equipment</Header>
        <Form.Field>
          <label>
            Safety Equipment{" "}
            <Popup
              content={i18n.t('provide-details-of-safety-equipment-in-use-during-this-activity')}
              trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
            />
          </label>
          <Form.TextArea
            placeholder=""
            name="safetyEquiptment"
            value={values.safetyEquiptment}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!ActivityState.isEditable()}
          />
        </Form.Field>

        <Form.Field>
          <label>
            Communication Equipment{" "}
            <Popup
              content={i18n.t('provide-details-of-communication-devices-e-g-radios-phones-during-this-activity')}
              trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
            />
          </label>
          <Form.TextArea
            placeholder=""
            name="communicationEquiptment"
            value={values.communicationEquiptment}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!ActivityState.isEditable()}
          />
        </Form.Field>

        <div className="activityDetail-submitContainer">
          <Button className="registerBeacon-submitButton" onClick={this.onClose}>
            Back
          </Button>
          <Button className="registerBeacon-submitButton" type="submit" primary onClick={handleSubmit}>
            Next
          </Button>
          {/* {
						ActivityState.isEditActivity ?
						<Button className="activityDetail-submitButton" primary onClick={this.saveAndClose} loading={ActivityState.isSaving} >
						Save & Close
						</Button>
						: ''
					} */}
        </div>
      </Form>
    );
  }
}

const UserDetailForm = withSemanticUIFormik({
  mapPropsToValues: ({ user, activity }) => {
    console.log("UserDetailForm: activity", toJS(activity));
    let returnObject = {};
    if (activity) {
      // console.log('log: user - mapPropsToValues', user);
      returnObject = {
        userGroup: activity.userGroup || "",
        selectUser: activity.selectUser || "",
        title: activity.title || "",
        lastName: activity.lastName || "",
        firstName: activity.firstName || "",
        userLink: activity.userLink || "",
        operationLanguage: activity.operationLanguage || "",
        medicalInfo: activity.medicalInfo || "",
        address: activity.address || "",
        city: activity.city || "",
        province: activity.province || "",
        mailCountry: activity.mailCountry || "",
        mailCode: activity.mailCode || "",
        phonePrefix: activity.phonePrefix || "",
        phoneNumber: activity.phoneNumber || "",
        phoneType: activity.phoneType || "",

        phonePrefix2: activity.phonePrefix2 || "",
        phoneNumber2: activity.phoneNumber2 || "",
        phoneType2: activity.phoneType2 || "",

        phoneTypeOther: activity.phoneTypeOther || "",
        phoneType2Other: activity.phoneType2Other || "",
        communicationEquipment: activity.communicationEquipment || "",
      };
    } else {
      returnObject = {
        userGroup: "",
        selectUser: "",
        title: "",
        lastName: "",
        firstName: "",
        userLink: "",
        operationLanguage: "",
        medicalInfo: "",
        address: "",
        city: "",
        province: "",
        mailCountry: "",
        mailCode: "",
        phonePrefix: "",
        phoneNumber: "",
        phoneType: "",
        phonePrefix2: "",
        phoneNumber2: "",
        phoneType2: "",

        phoneTypeOther: "",
        phoneType2Other: "",
        communicationEquipment:"",
      };
    }
    let participantFields = {};
    if (activity) {
      participantFields = {
        participantAdults: activity.participantAdults || "",
        participantTeens: activity.participantTeens || "",
        participantChildren: activity.participantChildren || "",
        participantAdditionalComments: activity.participantAdditionalComments || "",
        safetyEquiptment: activity.safetyEquiptment || "",
        communicationEquiptment: activity.communicationEquiptment || "",
      };
    } else {
      participantFields = {
        participantAdults: "",
        participantTeens: "",
        participantChildren: "",
        participantAdditionalComments: "",
        safetyEquiptment: "",
        communicationEquiptment: "",
      };
    }
    return { ...participantFields, ...returnObject };
  },

  validationSchema: Yup.object().shape({
    phoneNumber: Yup.string().notRequired(),
    phoneNumber2: Yup.string().notRequired(),
  }),

  handleSubmit: (values, { setSubmitting, props }) => {
    console.log("log: ================= values @user form", values, props);
    ActivityState.updateActivityFields(values);
    ActivityState.setNextStep();
  },
})(InnerForm);

export default UserDetailForm;
