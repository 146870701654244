import i18n from 'i18next';
import React, { Component } from 'react';
import * as Yup from 'yup';
import { Form, Input, Button, Popup, Select, Header, Message, Radio } from 'semantic-ui-react';
import DashboardState from '../../../state/dashboardState';
import VehicleState from '../../../state/vehicleState';
import withSemanticUIFormik from "../../../utils/formHelper";
import {countryCode} from '../../../utils/beaconDecode/countryCode'
import RouteState from '../../../state/routeState'
import ActivityState from '../../../state/activityState'

const typeOptions = [
    { key: 'aircraft', text: 'Aircraft', value: 'Aircraft' },
    { key: 'boat', text: 'Boat', value: 'Boat' },
    { key: 'helicopter', text: 'Helicopter', value: 'Helicopter' },
	{ key: 'landvehicle', text: 'Land Vehicle', value: 'Land Vehicle' },
	{ key: 'none', text: 'None', value: 'None' },
	{ key: 'other', text: 'Other', value: 'Other' },
]

const specificUseOptions = [
    { key: 'fishing', text: 'Fishing', value: 'Fishing' },
    { key: 'hiking', text: 'Hiking', value: 'Hiking' },
    { key: 'hunting', text: 'Hunting', value: 'Hunting' },
	{ key: 'other', text: 'Other', value: 'Other' },
]

const countryCodeOptions = countryCode.sort((a,b) => a.country.localeCompare(b.country))
.map(country => {
	return {
		text:country.code + " - " + country.country,
		value:country.code,
		key:country.code
	}
})

const existingVehicleOptions = [
    { key: 'v1', text: 'Vehicle 1', value: 'Vehicle 1' },
    { key: 'v2', text: 'Vehicle 2', value: 'Vehicle 2' },
]


class InnerForm extends Component {

    state = {
        vehicleOptions: [],
        vehicleInfos: [],
    }

	onClose = () => {
        if(VehicleState.hasRelatedActivity){
            ActivityState.setPreviousStep();
        }
        else{
            this.props.onCancel();
        }
    }

    componentDidMount = () => {
        DashboardState.searchVehicles({}).then((response) => {
            if(!response) return;
            const vehicle = response.map((row, index) => {
                var _type = (row.vehicleType == '' || row.vehicleType === undefined) ? 'n/a' : row.vehicleType;
                return {
                    text: _type + " - " + row.vehicleModel,
                    value: row._id,
                    key: row._id
                }
            })

            this.setState({
                vehicleOptions: vehicle,
                vehicleInfos: response
            })
        })
    }

    onSelectVehicleChange = (e, data) => {
        // set selected when changed
        if (data && data.name) {
            this.props.setFieldValue(data.name, data.value);
        }

        // set selecting vehicle info
        var selecting = this.state.vehicleInfos.find(row => row._id === data.value);
        this.props.values.vehicleType = selecting.vehicleType,
        this.props.values.vehicleModel = selecting.vehicleModel,
        this.props.values.registrationNumber = selecting.registrationNumber,
        this.props.values.registrationCountry = selecting.registrationCountry,
        this.props.values.vehicleColor = selecting.vehicleColor,
        this.props.values.specificUsage = selecting.specificUsage

        // change state data selecting
        VehicleState.setVehicleFields(selecting)

    }

    handleChangeSpecificUsage = (e, data) => {
        if (data && data.name) {
            this.props.setFieldValue(data.name, data.value);
        }
        VehicleState.setCurrentSpecificUsage(data.value)
        if(data.value != 'Other'){
            this.props.values.specificUsageOther = ''
        }
    }

    saveAndClose = () => {
		if(ActivityState.isEditActivity){
		  ActivityState.setIsSaving(true)
          VehicleState.updateVehicleFields(this.props.values)
		  ActivityState.saveActivity().then(() => {
			ActivityState.setIsSaving(false)
			RouteState.history.goBack()
		  })
		}
	}

	render() {

		const {
			values,
			touched,
			errors,
			dirty,
			isSubmitting,
			handleChange,
			handleBlur,
			handleSubmit,
			handleReset,
			isValid,
            onClose,
            allowSelectVehicle
        } = this.props;

		return (
			<Form className="activityDetail-form animated fadeIn faster">
				<Header as='h2' icon textAlign='center'>
					<Header.Content>Add the vehicle details</Header.Content>
                </Header>

                <div style={(allowSelectVehicle) ? {display:'block'} : {display:'none'}}>
                    <Form.Field>
                        <Radio
                            label='Select a vehicle'
                            name='vehicleGroup'
                            value='selectExistingVehicle'
                            checked={values.vehicleGroup === 'selectExistingVehicle'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!ActivityState.isEditable()}
                        />
                    </Form.Field>
                    <Form.Group widths='equal'>
                        <Form.Select name='selectVehicle' options={this.state.vehicleOptions} placeholder='Select' onChange={this.onSelectVehicleChange} onBlur={handleBlur} value={values.selectVehicle} disabled={!ActivityState.isEditable() || !(values.vehicleGroup === 'selectExistingVehicle')} />
                        <Form.Field></Form.Field>
                    </Form.Group>

                    <Form.Field>
                        <Radio
                            label='Add a new vehicle'
                            name='vehicleGroup'
                            value='addNewVehicle'
                            checked={values.vehicleGroup === 'addNewVehicle'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!ActivityState.isEditable()}
                        />
                    </Form.Field>
                </div>

                <Form.Group widths='equal'>
                    <Form.Field>
                        <Form.Select fluid name='vehicleType' label='Vehicle Type' options={typeOptions} placeholder='Select' onChange={handleChange} onBlur={handleBlur} value={values.vehicleType} disabled={!ActivityState.isEditable()} />
                        {values.vehicleType == 'Other'
                            ? <Form.Input fluid placeholder='' name='vehicleTypeOther' value={values.vehicleTypeOther} onChange={handleChange} onBlur={handleBlur} disabled={!ActivityState.isEditable()} />
                            : values.vehicleTypeOther = ''}
                    </Form.Field>
                    <Form.Input fluid name='vehicleModel' label='Vehicle Model' placeholder='' value={values.vehicleModel} onBlur={handleBlur} onChange={handleChange} disabled={!ActivityState.isEditable()} />
                </Form.Group>

                <Form.Group widths='equal'>
                    <Form.Input fluid name='registrationNumber' label='Registration Number' placeholder='' value={values.registrationNumber} onChange={handleChange} onBlur={handleBlur} disabled={!ActivityState.isEditable()} />
                    <Form.Select search fluid name='registrationCountry' label='Registration Country' placeholder='Select' value={values.registrationCountry} onChange={handleChange} onBlur={handleBlur} options={countryCodeOptions} disabled={!ActivityState.isEditable()} />
                </Form.Group>

                <Form.Group widths='equal'>
                    <Form.Field>
                        <Form.Input fluid name='vehicleColor' label='Colour' placeholder='' value={values.vehicleColor} onChange={handleChange} onBlur={handleBlur} disabled={!ActivityState.isEditable()} />
                    </Form.Field>

                    <Form.Field>
                        <label>Specific Usage <Popup content='Select the checkbox that is most appropriate to the way the beacon will be specifically used. If Other is selected, enter a description in the box below Other.' trigger={<Button type="button" circular icon='question' primary size="mini" className='home-helpButton' />} /></label>
                        <Form.Select fluid name='specificUsage' options={specificUseOptions} placeholder='Select' value={values.specificUsage} onChange={this.handleChangeSpecificUsage} disabled={!ActivityState.isEditable()} />

                        {VehicleState.currentSpecificUsage == 'Other'
                            ? <Form.Input fluid placeholder='' name='specificUsageOther' value={values.specificUsageOther} onChange={handleChange} onBlur={handleBlur} disabled={!ActivityState.isEditable()} />
                            : ''
                        }
                    </Form.Field>
                </Form.Group>

                {/* {VehicleState.currentSpecificUsage == 'Other' ? <Form.Group widths='equal' >
                    <Form.Field></Form.Field>
                    <Form.Field>
                        <Form.Input fluid placeholder='' name='specificUsageOther' value={values.specificUsageOther} onChange={handleChange} onBlur={handleBlur} />
                    </Form.Field>
                    </Form.Group>
                    : ''
                } */}

 				<div className="activityDetail-submitContainer">
					<Button className="registerBeacon-submitButton" onClick={this.onClose} >Back</Button>
					<Button className="registerBeacon-submitButton" type="submit" primary onClick={handleSubmit}>Next</Button>
                    {/* {
						ActivityState.isEditActivity ?
						<Button className="activityDetail-submitButton" primary onClick={this.saveAndClose} loading={ActivityState.isSaving} >
						Save & Close
						</Button>
						: ''
					} */}
				</div>
			</Form>
		);
	}

};

const VehicleDetailForm = withSemanticUIFormik({
	mapPropsToValues: ({ vehicle }) => {
        if (vehicle) {
            return {
                vehicleGroup:   vehicle.vehicleGroup || '',
                selectVehicle:    vehicle.selectVehicle || '',
                vehicleType: vehicle.vehicleType || '',
                vehicleModel:   vehicle.vehicleModel || '',
                registrationNumber: vehicle.registrationNumber || '',
                registrationCountry: vehicle.registrationCountry || '',
                vehicleColor:   vehicle.vehicleColor || '',
                specificUsage:  vehicle.specificUsage || '',
                specificUsageOther: vehicle.specificUsageOther || '',
                vehicleTypeOther: vehicle.vehicleTypeOther || '',
            }
        }
        else{
            return {
                vehicleGroup: '',
                selectVehicle: '',
                vehicleType: '',
                vehicleModel: '',
                registrationNumber: '',
                registrationCountry: '',
                vehicleColor: '',
                specificUsage: '',
                specificUsageOther: '',
                vehicleTypeOther: '',
            }
        }
    },

	handleSubmit: (values, { setSubmitting, isValid }) => {
		console.log('log: values===================@vehicle form', values, isValid);
		VehicleState.updateVehicleFields(values);
        VehicleState.setNextStep();
    }
})(InnerForm);

export default VehicleDetailForm;
