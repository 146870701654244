import React, { Component, useState } from "react";
import TopMenu from "./topMenu";
import Footer from "./footer";

const ForgotPasswordLayout = ({ children, hasTopBanner }) => {
  return (
    <div className="defaultPageLayout-container">
      <header className="header">
        <TopMenu hasTopBanner={hasTopBanner} />
      </header>
      <section className="defaultPageLayout-content">
        <div className="defaultPageLayout-content-body">{children || ""}</div>
      </section>
      <footer className="footer">
        <Footer />
      </footer>
    </div>
  );
};
export default ForgotPasswordLayout