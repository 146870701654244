import { getPocDb } from "./dbService";
import { insertOrUpdateDocument } from "./serviceUtils";

export async function initIndexes() {
  let result = await createByTypeIndex();
  console.log("log: initIndexes -> createPOCSearchIndex", result);
  result = await createPOCSearchIndex();
  console.log("log: initIndexes -> createByTypeIndex", result);
}

async function createByTypeIndex() {
  let pocDb = await getPocDb();
  try {
    var result = await pocDb.createIndex({
      index: {
        fields: ["type"],
        name: "byTypeIndex",
        ddoc: "byTypeIndex",
        type: "json",
      },
    });
    return result;
  } catch (err) {
    console.error(err);
  }
}

async function createPOCSearchIndex() {
  let pocDb = await getPocDb();
  try {
    var result = await pocDb.createIndex({
      index: {
        fields: ["comments", "registration", "poc", "mcc"],
        name: "pocSearchIndex",
        ddoc: "pocSearchIndex",
        type: "json",
      },
    });
    return result;
  } catch (err) {
    console.error(err);
  }
}

export async function getPOCByIndex(pocIndex) {
  console.log("log: getPOCByIndex -> pocIndex", pocIndex);

  let pocDb = await getPocDb();

  try {
    var result = await pocDb.get(pocIndex);
    return result;
  } catch (err) {
    console.error("pocService:getPOCByIndex - error", err);
    throw err;
  }
}

export async function getAllPoc() {
  let db = await getPocDb();

  try {
    let result = await db.allDocs({
      include_docs: true,
    });
    return result.rows.map((entry) => entry.doc);
  } catch (error) {
    console.log("pocService -> getAllPoc -> error: " + error);
  }
}

export async function savePoc(item) {
  let db = await getPocDb();
  try {
    let result = await insertOrUpdateDocument(item, db);
    return result;
  } catch (error) {
    console.log("pocService -> savePoc -> error: " + error);
  }
}

export async function searchPOCByType(type) {
  console.log('log ~ file: pocService.js ~ line 83 ~ searchPOCByType ~ type', type);
  let pocDb = await getPocDb();
  if (!pocDb) return;
  let selector = {
    type:type,
    state:"1"
    // pOCIndex: { $gt: null },
  };

  try {
    var result = await pocDb.find({
      selector: selector,
    });
    return result;
  } catch (err) {
    console.error("pocService:searchPOC - error", err);
    throw err;
  }
}

export async function storeBulkPOCs(pocs){
  console.log('log ~ file: pocService.js ~ line 104 ~ storeBulkPOCs ~ pocs', pocs);
  let db = await getPocDb();
  try {
    let result = await db.bulkDocs(pocs);
    console.log('log ~ file: pocService.js ~ line 107 ~ storeBulkPOCs ~ result', result);
    return result;
  } catch (error) {
    console.log("pocService -> storeBulkPOCs -> error: " + error);
  }
}

export async function searchPOC(criteria) {
  let pocDb = await getPocDb();
  if (!pocDb) return;
  let selector = {
    type: "POCinfo",
    // pOCIndex: { $gt: null },
  };
  if (criteria.pOCIndex) {
    selector["_id"] = { $eq: criteria.pOCIndex[0] };
  }
  if (criteria.comments) {
    selector["comments"] = { $regex: "(?i)" + criteria.comments[0] + "(?i)" };
  }
  if (criteria.registration) {
    selector["registration"] = { $eq: criteria.registration[0] };
  }
  if (criteria.poc) {
    selector["poc"] = { $eq: criteria.poc[0] };
  }
  if (criteria.mcc) {
    selector["mcc"] = { $eq: criteria.mcc[0] };
  }

  try {
    var result = await pocDb.find({
      selector: selector,
      use_index: "pocSearchIndex",
    });
    return result;
  } catch (err) {
    console.error("pocService:searchPOC - error", err);
    throw err;
  }
}

export async function deletePOC(id) {
  let db = await getPocDb();
  try {
    let data = {};
    let doc = await db.get(id);
    console.log("log: deletePOC -> doc", doc);
    if (doc) {
      let deleteFromDB = await db.remove(doc);
      data.deleteFromDB = deleteFromDB;
    }
    return data;
  } catch (error) {
    console.log("pocService -> deletePOC -> error: " + error);
  }
}

export async function getLastId() {
  let tacDb = await getTACDb();
  if (!tacDb) return;
  let selector = {
    type: "tac",
  };
  try {
    var result = await tacDb.find({
      selector: selector,
      use_index: "byType",
    });
    return result;
  } catch (err) {
    console.error("tacService:searchPOC - error", err);
    throw err;
  }
}