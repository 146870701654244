import i18n from "i18next";
import React, { Component, useState, useEffect } from "react";
import * as Yup from "yup";
import { Form, Input, Button, Popup, Select, Header, Grid, Icon, Message, Transition, Segment } from "semantic-ui-react";
import RegistrationState from "../../../state/registrationState";
import AppConfigState from "../../../state/appConfigState";
import withSemanticUIFormik from "../../../utils/formHelper";
import RouteState from "../../../state/routeState";
import UserState from "../../../state/userState";
import { UserFormErrorDisplay } from "../../../components/formErrorDisplay";
import { toJS } from "mobx";

const contactNoOption = [{ key: "1", text: "1", value: "1" }, { key: "2", text: "2", value: "2" }, { key: "3", text: "3", value: "3" }, { key: "4", text: "4", value: "4" }];
const delegationStatusOptions = [{ key: "ndpManaged", value: false, text: "NDP Managed" }, { key: "ndpDelegated", value: true, text: "NDP Delegated" }];
let storedValues = {};

const InnerForm = (props) => {
  const [contact, setContact] = useState(1);
  const [dontHaveEmail, setDontHaveEmail] = useState(false);
  const [ndpDelegated, setNdpDelegated] = useState(props.values.isDelegated || false);

  let { values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, isValid, setErrors } = props;
  const addContactNumber = () => {
    // setContact(contact + 1);
    let totalPhone = document.getElementsByClassName("phoneNumber").length;
    setContact(totalPhone + 1);
  };

  const removeContactNumber = (fieldName, phoneType) => {
    // setContact(contact - 1);
    props.values[fieldName] = "";
    props.values[phoneType] = "";
    setContact(contact - 1);
  };

  const dontHaveEmailToggle = (e, data) => {
    values.dontHaveEmail = data.checked;
    setDontHaveEmail(data.checked);
    values.emailAddress = "";
    setErrors({}); // remove error message
  };

  const onDelegationChanged = (e, data) => {
    props.handleChange(e, data);
    setNdpDelegated(data.value);
    setErrors({}); // remove error message
  };

  const saveAndClose = () => {
    if (!RegistrationState.isNewRegisterBeacon) {
      RegistrationState.setIsSaving(true);
      RegistrationState.updateBeaconFields(props.values);
      RegistrationState.updateBeacon().then(() => {
        RegistrationState.setIsBeaconSaved(true);
        props.onClose();
        RegistrationState.setIsSaving(false);
      });
    }
  };
  storedValues = values;
  useEffect(() => {
    if (!RegistrationState.isNewRegisterBeacon) {
      var topPosition = document.getElementById("editBeaconModal");
      topPosition.scrollIntoView();
    } else {
      window.scrollTo({ top: 0 });
    }

    return function cleanup() {
      if (!RegistrationState.isNewRegisterBeacon) {
        console.log("log: InnerForm -> cleanup -> ", storedValues);
        //RegistrationState.updateBeaconFields(storedValues);
      }
    };
  }, []);

  let hasEmailAddressError = touched.emailAddress && errors.emailAddress && errors.emailAddress.length > 0;
  let hasOwnerNameError = touched.ownerName && errors.ownerName && errors.ownerName.length > 0;
  let hasContactPhoneError = touched.phone1Num && errors.phone1Num && errors.phone1Num.length > 0;
  let hasContactPhoneTypeError = touched.phone1Type && errors.phone1Type && errors.phone1Type.length > 0;
  let hasIsDelegatedError = touched.isDelegated && errors.isDelegated && errors.isDelegated.length > 0;
  let phone2NumError = touched.phone2Num && errors.phone2Num && errors.phone2Num.length > 0;
  let phone3NumError = touched.phone3Num && errors.phone3Num && errors.phone3Num.length > 0;
  let phone4NumError = touched.phone4Num && errors.phone4Num && errors.phone4Num.length > 0;
  let hasError = Object.keys(errors).length !== 0;

  const phoneTypeOption = AppConfigState.phoneTypes;
  const languageOption = AppConfigState.languages;
  const statusOption = AppConfigState.beaconStatusOption;

  let countryOptions = AppConfigState.countryNames;
  let languages = AppConfigState.languages;

  let roleId = UserState.currentUserProfile.roleId || "5";
  let canEditByStatus =
    props.decodedBeacon.specialStatus == "ACTIVE" || props.decodedBeacon.specialStatus == undefined || props.decodedBeacon.specialStatus == "RELEASETOMAINTENANCE";

  if (UserState.notAllowToEditByRoleId.includes(roleId)) {
    canEditByStatus = false;
  }

  var AnotherContactButton;
  if (contact < 4) {
    AnotherContactButton = (
      <Form.Group widths="equal">
        <a
          className="registerUserForm-addContactsButton"
          onClick={
            canEditByStatus
              ? addContactNumber
              : (e) => {
                  e.preventDefault();
                }
          }
          disabled={!canEditByStatus}
        >
          <Icon name="plus circle" color="red" size="large" />
          {"   "}
          {i18n.t('add-another-beacon-owner-phone-number')}
        </a>
      </Form.Group>
    );
  }

  // scroll to error
  if (isSubmitting && Object.keys(errors).length > 0) {
    var errorBox = document.getElementById("scrollToError");
    errorBox.scrollIntoView();
  }

  const saveToStateBeforeHandleChange = function(e, data) {
    console.log("log: InnerForm -> saveToStateBeforeHandleChange -> name, value", data.name, data.value);
    RegistrationState.saveOnFieldChange(data.name, data.value);
    handleChange(e, data);
  };

  let beaconType = "";
  if (RegistrationState.decodedBeacon && RegistrationState.decodedBeacon.beaconType) {
    beaconType = RegistrationState.decodedBeacon.beaconType;
  }

  return (
    <Form id="mainForm" className="beaconLocation-form animated fadeIn faster" error={hasError}>
      <Header as="h2" icon textAlign="center">
        <Header.Content>{i18n.t('please-supply-the-following-information-about-the-beacon-owner')}</Header.Content>
      </Header>
      <div id="scrollToError" />
      <UserFormErrorDisplay errors={errors} touched={touched} status={status} />
      <div className="required-field-notation">* required fields</div>

      <Form.Group>
        {/* {roleId === "1" && AppConfigState.config.ndpDelegationActivation && ( */}
        {roleId === "1" && AppConfigState.delegationEnabledByType(props.decodedBeacon) && (
          <Form.Field width={4}>
            <label>
              {i18n.t('delegation')} <span style={{ color: "red" }}>* </span>
              <Popup
                content={i18n.t('ndp-delegation-grants-the-beacon-owner-access-to-update-their-beacon-record-in-the-ibrd-as-ndp-you-retain-access-and-rights-to-view-edit-and-reclaim-management-of-the-beacon-at-all-times')}
                trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
              />
            </label>
            <Form.Select
              name="isDelegated"
              fluid
              placeholder="Select"
              error={hasIsDelegatedError}
              options={delegationStatusOptions}
              onChange={onDelegationChanged}
              onBlur={handleBlur}
              value={values.isDelegated}
              disabled={!canEditByStatus}
              className={canEditByStatus ? "editing-select" : ""}
            />
          </Form.Field>
        )}
        <Form.Field width={(roleId === "1" && AppConfigState.config.ndpDelegationActivation) ? "12" : "16"}>
          <label>
            {i18n.t('beacon-owner-name')} <span style={{ color: "red" }}>* </span>
            <Popup
              content={i18n.t('name-of-beacon-owner-person-company-or-government-agency-if-a-person-the-format-is-last-first-middle-initial-commas-are-not-needed')}
              trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
            />
          </label>
          <Form.Input
            fluid
            name="ownerName"
            value={values.ownerName}
            placeholder={i18n.t('name')}
            error={hasOwnerNameError}
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-input" : ""}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group>
        <Form.Field width="12">
          <label>
            {i18n.t('address')}
            <Popup content={i18n.t('street-address-po-box-where-owner-lives')} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
          </label>
          <Form.Input
            name="address"
            placeholder=""
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            value={values.address}
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-input" : ""}
          />
        </Form.Field>
        <Form.Field width={4}>
          <label>
            {i18n.t('zip-postal-code')}
            <Popup
              content={i18n.t('appropriate-zip-or-postal-code-to-the-address')}
              trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
            />
          </label>
          <Form.Input
            name="mailCode"
            placeholder=""
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            value={values.mailCode}
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-input" : ""}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Field>
          <label>
            {i18n.t('city')}
            <Popup content={i18n.t('city')} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
          </label>
          <Form.Input
            name="city"
            fluid
            placeholder=""
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            value={values.city}
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-input" : ""}
          />
        </Form.Field>
        <Form.Field>
          <label>
            {i18n.t('state-province-0')}
            <Popup content={i18n.t('state-or-province')} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
          </label>
          <Form.Input
            name="province"
            fluid
            placeholder=""
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            value={values.province}
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-input" : ""}
          />
        </Form.Field>
        <Form.Field>
          <label>
            {i18n.t('country')}
            <Popup content={i18n.t('country')} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
          </label>
          {/* <Form.Dropdown
            name="mailCountry"
            fluid
            options={countryOptions}
            placeholder="Country"
            onChange={handleChange}
            onBlur={handleBlur}
            search
            scrolling
            className="form-dropdown-style margin-top-0"
            value={values.mailCountry}
            disabled={!canEditByStatus} className={canEditByStatus ? 'editing-select' : ''}
          /> */}
          <Form.Select
            name="mailCountry"
            fluid
            options={countryOptions}
            placeholder={i18n.t('country')}
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            search
            scrolling
            className="form-dropdown-style margin-top-0"
            value={values.mailCountry}
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-select" : ""}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Field>
          <label>
            {i18n.t('email')}
            {(ndpDelegated ||beaconType.includes("ELT")) && <span style={{ color: "red" }}>* </span>}
            <Popup
              content={i18n.t('e-mail-address-where-all-correspondence-pertaining-to-the-beacon-should-be-sent-e-mail-addresses-are-not-used-for-any-purpose-other-than-this-registry-and-for-search-and-rescue')}
              trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
            />
          </label>
          <Form.Input
            name="emailAddress"
            fluid
            placeholder="john@doe.com"
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            value={values.emailAddress}
            error={hasEmailAddressError}
            disabled={dontHaveEmail || !canEditByStatus}
            className={canEditByStatus ? "editing-input" : ""}
          />

          {(UserState.currentUserProfile && UserState.currentUserProfile.roleId == "1") || beaconType.includes("ELT") ? (
            ""
          ) : (
            <Popup
              content={i18n.t('i-understand-that-by-not-providing-an-e-mail')}
              trigger={
                <Form.Checkbox
                  name="dontHaveEmail"
                  defaultChecked={values.dontHaveEmail}
                  label={i18n.t('i-do-not-have-an-e-mail-address-that-can-be-used-for-this-purpose')}
                  style={{ marginTop: "10px" }}
                  onChange={dontHaveEmailToggle}
                  disabled={!canEditByStatus}
                  className={canEditByStatus ? "editing-input" : ""}
                />
              }
            />
          )}
        </Form.Field>

        <Form.Field>
          <label>
            {i18n.t('communication-language')}
            <Popup
              content={i18n.t('preferred-language-of-verbal-communication')}
              trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
            />
          </label>
          <Form.Input
            name="operatorLanguage"
            fluid
            placeholder=""
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            value={values.operatorLanguage}
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-input" : ""}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group>
        <Form.Field width={8}>
          <label>
            {i18n.t('beacon-owner-phone-number')} <span style={{ color: "red" }}>* </span>
            <Popup
              content={i18n.t('between-one-and-four-telephone-numbers-may-be-entered')}
              trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
            />
          </label>
          <Form.Input
            fluid
            name="phone1Num"
            placeholder={i18n.t('phone-number')}
            error={hasContactPhoneError}
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            value={values.phone1Num}
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-input phoneNumber" : ""}
          />
        </Form.Field>

        <Form.Field width={8}>
          <label>
            {i18n.t('beacon-owner-phone-number-type')} <span style={{ color: "red" }}>* </span>
          </label>
          <Form.Group widths="equal" className="phone-type-other">
            <Form.Select
              fluid
              name="phone1Type"
              // label="Beacon Owner Phone Number Type"
              options={phoneTypeOption}
              placeholder={i18n.t('phone-type')}
              onChange={saveToStateBeforeHandleChange}
              onBlur={handleBlur}
              value={values.phone1Type}
              error={hasContactPhoneTypeError}
              disabled={!canEditByStatus}
              className={canEditByStatus ? "editing-select" : ""}
              required
            />

            {values.phone1Type == "OTHR" ? (
              <Form.Input
                fluid
                placeholder=""
                name="phone1TypeOther"
                value={values.phone1TypeOther}
                onChange={saveToStateBeforeHandleChange}
                onBlur={handleBlur}
                className={canEditByStatus ? "editing-input" : ""}
                disabled={!canEditByStatus}
              />
            ) : (
              (values.phone1TypeOther = "")
            )}
          </Form.Group>
        </Form.Field>
      </Form.Group>

      <AdditionnalContacts
        handleChange={saveToStateBeforeHandleChange}
        handleBlur={handleBlur}
        hasContactPhoneError={hasContactPhoneError}
        values={values}
        phoneTypeOption={phoneTypeOption}
        number={2}
        errors={phone2NumError}
        contact={contact}
        removeContactNumber={() => removeContactNumber("phone2Num", "phone2Type")}
        className="phoneNumber"
        canEditByStatus={canEditByStatus}
      />
      <AdditionnalContacts
        handleChange={saveToStateBeforeHandleChange}
        handleBlur={handleBlur}
        hasContactPhoneError={hasContactPhoneError}
        values={values}
        phoneTypeOption={phoneTypeOption}
        number={3}
        errors={phone3NumError}
        contact={contact}
        removeContactNumber={() => removeContactNumber("phone3Num", "phone3Type")}
        className="phoneNumber"
        canEditByStatus={canEditByStatus}
      />
      <AdditionnalContacts
        handleChange={saveToStateBeforeHandleChange}
        handleBlur={handleBlur}
        hasContactPhoneError={hasContactPhoneError}
        values={values}
        phoneTypeOption={phoneTypeOption}
        number={4}
        errors={phone4NumError}
        contact={contact}
        removeContactNumber={() => removeContactNumber("phone4Num", "phone4Type")}
        className="phoneNumber"
        canEditByStatus={canEditByStatus}
      />

      {AnotherContactButton}

      <Form.Group widths="equal">
        <Form.Field>
          <label>
            Medical Information
            <Popup
              content={i18n.t('list-any-relevant-medical-information-such-as-medications-or-conditions')}
              trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
            />
          </label>
          <Form.TextArea
            name="medicalInfo"
            placeholder="Medical Information"
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            value={values.medicalInfo}
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-input" : ""}
          />
        </Form.Field>
      </Form.Group>

      {/* <Grid centered columns={3} stackable>
        <Grid.Column>
          <Button className="registerBeacon-submitButton" onClick={onBackClick}>
            Back
          </Button>
        </Grid.Column>
        <Grid.Column>
          <Button className="registerBeacon-submitButton" type="submit" primary onClick={handleSubmit}>
            Next
          </Button>
        </Grid.Column>
      </Grid> */}

        <div className="activityDetail-submitContainer">
          <Button className="registerBeacon-submitButton" onClick={onBackClick}>
            {i18n.t('back')}
          </Button>
          <Button className="registerBeacon-submitButton" type="submit" primary onClick={handleSubmit}>
            {i18n.t('next')}
          </Button>
        </div>

    </Form>
  );
};

function onBackClick() {
  RegistrationState.setOwnerPreviousStep();
}

const AdditionnalContacts = ({ handleChange, handleBlur, values, phoneTypeOption, number, contact, removeContactNumber, errors, className, canEditByStatus }) => {
  // if (contact < number) {
  //   return null;
  // }

  if (!values["phone" + number + "Num"] && contact < number) {
    return null;
  }

  // let hasError = errors["contactPhone" + number];

  return (
    <Form.Group className="additional-phone">
      {/* <Form.Input fluid name="contactNumber" label="" placeholder="#" disabled width={2} value={number} onChange={handleChange} onBlur={handleBlur} className="contact-number" /> */}

      <Form.Field width={8}>
        <label>
          {i18n.t('beacon-owner-phone-number')}
          <Popup
            content={i18n.t('between-one-and-four-telephone-numbers-may-be-entered')}
            trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
          />
        </label>
        <Form.Input
          fluid
          name={"phone" + number + "Num"}
          placeholder={i18n.t('phone-number')}
          error={errors}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values["phone" + number + "Num"]}
          className={canEditByStatus ? "editing-input phoneNumber" : ""}
          disabled={!canEditByStatus}
        />
      </Form.Field>

      <Form.Field width={8}>
        <label>Beacon Owner Phone Number Type</label>
        <Form.Group widths="equal" className="phone-type-other">
          <Form.Select
            fluid
            name={"phone" + number + "Type"}
            // label="Beacon Owner Phone Number Type"
            options={phoneTypeOption}
            placeholder="phoneType"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values["phone" + number + "Type"]}
            action="Search"
            className={canEditByStatus ? "editing-select" : ""}
            disabled={!canEditByStatus}
          />
          {values["phone" + number + "Type"] == "OTHR" ? (
            <Form.Input
              fluid
              placeholder=""
              name={"phone" + number + "TypeOther"}
              value={values["phone" + number + "TypeOther"]}
              onChange={handleChange}
              onBlur={handleBlur}
              className={canEditByStatus ? "editing-input" : ""}
              disabled={!canEditByStatus}
            />
          ) : (
            (values["phone" + number + "TypeOther"] = "")
          )}
        </Form.Group>
      </Form.Field>

      <Popup
        content={i18n.t('delete-this-number')}
        trigger={
          <Button icon circular className="registerUser-additionnalContactRemove" size="tiny" onClick={removeContactNumber}>
            {" "}
            <Icon name="trash circle" color="red" size="large" />
          </Button>
        }
      />
    </Form.Group>
  );
};

//const phoneRegExp = /^[\+\d]+((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const beaconLocationForm = withSemanticUIFormik({
  mapPropsToValues: ({ decodedBeacon }) => {
    let roleId = UserState.currentUserProfile ? UserState.currentUserProfile.roleId : "5";
    if (!decodedBeacon._id && roleId == "5") {
      let user = UserState.currentUserProfile || RegistrationState.userToEdit;
      decodedBeacon = { ...decodedBeacon, ...user };
    }

    let isDelegatedValue = decodedBeacon.isDelegated || "";
    if (roleId == "1" && isDelegatedValue == "") {
      isDelegatedValue = false;
    }

    if (decodedBeacon) {
      return {
        isDelegated: isDelegatedValue,
        ownerName: decodedBeacon.ownerName || "",
        address: decodedBeacon.address || "",
        mailCode: decodedBeacon.mailCode || "",
        city: decodedBeacon.city || "",
        province: decodedBeacon.province || "",
        mailCountry: decodedBeacon.mailCountry || "",
        emailAddress: decodedBeacon.emailAddress || "",
        medicalInfo: decodedBeacon.medicalInfo || "",
        operatorLanguage: decodedBeacon.operatorLanguage || "",
        contactNumber: decodedBeacon.contactNumber || "1",
        phone1Num: decodedBeacon.phone1Num || "",
        phone1Type: decodedBeacon.phone1Type || "",
        phone2Num: decodedBeacon.phone2Num || "",
        phone2Type: decodedBeacon.phone2Type || "",
        phone3Num: decodedBeacon.phone3Num || "",
        phone3Type: decodedBeacon.phone3Type || "",
        phone4Num: decodedBeacon.phone4Num || "",
        phone4Type: decodedBeacon.phone4Type || "",
        dontHaveEmail: decodedBeacon.dontHaveEmail || "",
        phone1TypeOther: decodedBeacon.phone1TypeOther || "",
        phone2TypeOther: decodedBeacon.phone2TypeOther || "",
        phone3TypeOther: decodedBeacon.phone3TypeOther || "",
        phone4TypeOther: decodedBeacon.phone4TypeOther || "",
      };
    }
  },
  validationSchema: function() {
    let roleId = UserState.currentUserProfile.roleId || "5";
    if (roleId == "1") {
      return Yup.object().shape({
        isDelegated: Yup.boolean().required("Delegation is required"),
        ownerName: Yup.string().required(i18n.t('beacon-owner-name-is-required')),
        dontHaveEmail: Yup.boolean(),
        emailAddress: Yup.string().when("isDelegated", {
          is: (value) => value == true,
          then: Yup.string()
            .required("Email address is required")
            .email("Email format is invalid"),
          /*otherwise: Yup.string()
              .when("dontHaveEmail", {
                is: (value) => value == false || value == undefined,
                then: Yup.string()
                  .required("Email address is required")
                  .email("Email format is invalid"),
              })*/
        }),
        phone1Num: Yup.string()
          .required(i18n.t('beacon-owner-phone-number-is-required')),
        phone1Type: Yup.string().required(i18n.t('beacon-owner-phone-number-type-is-required')),

        phone2Num: Yup.string()
          .notRequired(),
        phone3Num: Yup.string()
          .notRequired(),
        phone4Num: Yup.string()
          .notRequired(),
      });
    }

    return Yup.object().shape({
      ownerName: Yup.string().required(i18n.t('beacon-owner-name-is-required')),
      dontHaveEmail: Yup.boolean(),
      emailAddress: Yup.string().when("dontHaveEmail", {
        is: (value) => value == false || value == undefined,
        then: Yup.string()
          .required(i18n.t('email-address-is-required'))
          .email(i18n.t('email-format-is-invalid')),
      }),
      phone1Num: Yup.string()
        .required(i18n.t('beacon-owner-phone-number-is-required')),
      phone1Type: Yup.string().required(i18n.t('beacon-owner-phone-number-type-is-required')),

      phone2Num: Yup.string()
        .notRequired(),
      phone3Num: Yup.string()
        .notRequired(),
      phone4Num: Yup.string()
        .notRequired(),
    });
  },
  handleSubmit: (values, { setSubmitting, isValid }) => {
    console.log("log: values", values, isValid);
    RegistrationState.updateBeaconFields(values);
    RegistrationState.setNextStep();
  },
})(InnerForm);

export default beaconLocationForm;
