import * as CONST from "./beaconConstants.js";
import getCountryByCode from "./countryCode.js";
import hexUtils from "./hexUtils.js";
import decodeBaudot from "./baudot.js";
import * as dbService from "../../services/tacService";
export default async function decodeBeacon(beaconBinary, tacDb_remote) {
  let { countryCode, countryName } = hexUtils.getCountryCodeAndName(beaconBinary);
  console.log("log ~ file: gen2decode.mjs:8 ~ decodeBeacon ~ countryCode, countryName:", countryCode, countryName);
  console.log("Home: gen2Decode -> beaconBinary", beaconBinary, beaconBinary.length);
  let beaconInfo = await decodeUsingGen2Protocol(beaconBinary, tacDb_remote);
  return {
    ...beaconInfo,
    beaconCountryCode: countryCode.toString(),
    countryName,
  };
}

async function decodeUsingGen2Protocol(beaconBinary, tacDb_remote) {
  let fixedBinary101 = beaconBinary.substring(CONST.GEN2_FIXED_BINARY_101_BITS.start, CONST.GEN2_FIXED_BINARY_101_BITS.end);
  let fixedBinary101Ok = fixedBinary101 === CONST.GEN2_FIXED_BINARY_101_VALUE;

  let tacNoBinary = beaconBinary.substring(CONST.GEN2_TAC_NO_BITS.start, CONST.GEN2_TAC_NO_BITS.end);
  let tacNo = parseInt(tacNoBinary, 2);
  console.log("log ~ file: gen2decode.mjs:23 ~ decodeUsingGen2Protocol ~ tacNo:", tacNo);
  let addedFields = {};

  try {
    let result = await dbService.getTacByNo(tacNo, {});
    let manufacturer = result.find((item) => item.type === "manufacturers");
    let beaconType = result.find((item) => item.type === "beacon_types");
    addedFields.beaconManufacturer = manufacturer.name;
    addedFields.beaconType = beaconType.value;
  } catch (error) {
    console.log("log ~ file: gen1LocationProtocol.mjs:197 ~ decodeRLS ~ error:", error);
    if (error.status === 429) {
      throw error;
    }
    addedFields.tacError = "Tac not found";
  }

  let beaconSerialNumberBinary = beaconBinary.substring(CONST.GEN2_BEACON_SN_BITS.start, CONST.GEN2_BEACON_SN_BITS.end);
  let beaconSerialNumber = parseInt(beaconSerialNumberBinary, 2);
  let testProtocolFlag = beaconBinary.substring(CONST.GEN2_TEST_PROTOCOL_FLAG.start, CONST.GEN2_TEST_PROTOCOL_FLAG.end);
  let vesselTypeIdBinary = beaconBinary.substring(CONST.GEN2_VESSEL_ID_TYPE.start, CONST.GEN2_VESSEL_ID_TYPE.end);

  let vesselTypeIdValue = CONST.GEN2_VESSEL_ID_TYPE_VALUES[vesselTypeIdBinary];
  let vesselAdditionnalDetails = getAdditionnalInformationByBeacontype(vesselTypeIdBinary, beaconBinary);
  return {
    ...vesselAdditionnalDetails,
    typeApprovalCertificate: tacNo,
    serialNumber: beaconSerialNumber,
    testProtocolFlag,
    vesselTypeIdValue,
    ...addedFields,
  };
}

function getAdditionnalInformationByBeacontype(vesselTypeIdBinary, beaconBinary) {
  switch (vesselTypeIdBinary) {
    case "011":
      return getAirCraftRegistrationMarkingDetails(beaconBinary);
    case "100":
      return getAviation24bitAddressDetails(beaconBinary);
    case "101":
      return getAircraftOperatorAndSerialDetails(beaconBinary);
    case "000": //No Vessel ID value, may be defined for national use, defailt content for bits is all 0s
      return { beaconType: "PLB - No aircraft or maritime identity" };
    case "001":
      return getMaritimeMMSIDetails(beaconBinary);
    case "010":
      return getRadioCallSignDetails(beaconBinary);
    default:
      return {};
  }
}

function getAirCraftRegistrationMarkingDetails(beaconBinary) {
  console.log("log ~ file: gen2decode.mjs:69 ~ getAirCraftRegistrationMarkingDetails ~ beaconBinary:", beaconBinary);
  let registrationMarkingEncoded = beaconBinary.substring(CONST.GEN2_REGISTRATION_MARK_BITS.start, CONST.GEN2_REGISTRATION_MARK_BITS.end);
  let registrationMarking = decodeBaudot(registrationMarkingEncoded);
  let beaconType = "ELT - Marking tail";
  return {
    registrationMarkingEncoded,
    registrationMarking,
    beaconType,
  };
}

function getAviation24bitAddressDetails(beaconBinary) {
  console.log("log ~ file: gen2decode.mjs:81 ~ getAviation24bitAddressDetails ~ beaconBinary:", beaconBinary);
  let aviation24BitAddress = beaconBinary.substring(CONST.GEN2_REGISTRATION_MARK_BITS.start, CONST.GEN2_REGISTRATION_MARK_BITS.end);
  let beaconType = "ELT - Aviation 24bit address";
  return { aviation24BitAddress, beaconType };
}

function getAircraftOperatorAndSerialDetails(beaconBinary) {
  console.log("log ~ file: gen2decode.mjs:88 ~ getAircraftOperatorAndSerialDetails ~ beaconBinary:", beaconBinary);
  let aircraftOperatorBinary = beaconBinary.substring(CONST.GEN2_AIRCRAFT_OPERATOR_DESIGNATOR_BITS.start, CONST.GEN2_AIRCRAFT_OPERATOR_DESIGNATOR_BITS.end);
  let aircraftOperator = decodeBaudot(aircraftOperatorBinary);
  let aircraftSerialNumberBinary = beaconBinary.substring(CONST.GEN2_AIRCRAFT_OPERATOR_SN_BITS.start, CONST.GEN2_AIRCRAFT_OPERATOR_SN_BITS.end);
  let aircraftSerialNumber = parseInt(aircraftSerialNumberBinary, 2);
  let beaconType = "ELT - Aircraft operator and serial number";
  return {
    aircraftOperator,
    aircraftOperatorBinary,
    aircraftSerialNumber,
    serialNumber: aircraftSerialNumber,
    beaconType,
  };
}
function getMaritimeMMSIDetails(beaconBinary) {
  console.log("log ~ file: gen2decode.mjs:103 ~ getMaritimeMMSIDetails ~ beaconBinary:", beaconBinary);
  let shipStationIdentidy = beaconBinary.substring(CONST.GEN2_SHIP_STATION_IDENTITY.start, CONST.GEN2_SHIP_STATION_IDENTITY.end);
  let epirbAis = beaconBinary.substring(CONST.GEN2_EPIRB_AIS.start, CONST.GEN2_EPIRB_AIS.end);
  let beaconType = "EPIRB - Maritime MMSI";
  return {
    shipStationIdentidy,
    epirbAis,
    beaconType,
  };
}

function getRadioCallSignDetails(beaconBinary) {
  console.log("log ~ file: gen2decode.mjs:115 ~ getRadioCallSignDetails ~ beaconBinary:", beaconBinary);
  let radioCallSignEncoded = beaconBinary.substring(CONST.GEN2_RADIO_CALL_SIGN_BITS.start, CONST.GEN2_RADIO_CALL_SIGN_BITS.end);
  let radioCallSign = decodeBaudot(radioCallSignEncoded);
  let beaconType = "EPIRB - Radio Call Sign";
  return {
    radioCallSignDecoded: radioCallSign,
    radioCallSign,
    beaconType,
  };
}
