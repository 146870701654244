import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Container, Header, Grid, Divider, Button, Icon, GridRow, GridColumn } from 'semantic-ui-react'
import VehicleState from '../../../state/vehicleState';


@observer
export default class VehicleReview extends Component {

	onEditVehicleDetailClick = () =>{
		VehicleState.setCurrentStep(0);
	}

	onEditVehicleCommentClick = () =>{
		VehicleState.setCurrentStep(1);
	}

	onBackClick = () =>{
		VehicleState.setPreviousStep();
	}

	onSaveClick = () => {
		VehicleState.saveVehicle();
		VehicleState.setNextStep();
	}

	render() {

		let vehicleFields = VehicleState.getVehicleFields();

		return (
			<Container className='activityReviewDetails-container animated fadeIn faster' >
				<div className='print-only'>
					<h2>Certificate of 406 MHz beacon registration</h2>
				</div>

				<Header as='h2' className="beaconReviewDetails-header">
					<Header.Content>
						<span className="font-blue">Vehicle Summary</span>
					</Header.Content>
				</Header>
				<Grid columns={2} className="beaconReviewDetails-grid" stackable >
					<Grid.Row>
						<Grid.Column>
							<Header className="font-blue">{'Vehicle Details'.toUpperCase()} <span className='noprint'>|</span> <a onClick={this.onEditVehicleDetailClick} className="beaconReviewDetails-editLink noprint">Edit</a></Header>
							<div>Vehicle Type: <strong>{vehicleFields.vehicleType}
									{vehicleFields.vehicleType == 'Other' && vehicleFields.vehicleTypeOther ? ' - ' + vehicleFields.vehicleTypeOther : ''}</strong>
								</div>
							<div>Vehicle Model: <strong>{vehicleFields.vehicleModel}</strong></div>
							<div>Registration Number: <strong>{vehicleFields.registrationNumber}</strong></div>
							<div>Registration Country: <strong>{vehicleFields.registrationCountry}</strong></div>
							<div>Colour: <strong>{vehicleFields.vehicleColor}</strong></div>
							<div>Specific Usage: <strong>{vehicleFields.specificUsage} {vehicleFields.specificUsage == 'Other' ? '('+ vehicleFields.specificUsageOther +')' : ''}</strong></div>
						</Grid.Column>

						<Grid.Column>
							<Header className="font-blue">{'Images & Comments'.toUpperCase()} <span className='noprint'>|</span> <a onClick={this.onEditVehicleCommentClick} className="beaconReviewDetails-editLink noprint">Edit</a></Header>
							<div>Additional Vehicle / Usage Information: <strong>{vehicleFields.comment}</strong></div>
							{vehicleFields.image1 && <div style={{marginTop:'10px'}}><img style={{width:'100%'}} src={vehicleFields.image1} /></div> }
							{vehicleFields.image2 && <div style={{marginTop:'10px'}}><img style={{width:'100%'}} src={vehicleFields.image2} /></div> }

							<br/><br/>
							<div>
								<a className="beaconReviewDetails-editLink noprint" onClick={() => window.print()}> <Icon name="print" color="grey" size="large" />Print Vehicle Summary</a>
							</div>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<div className="activityDetail-submitContainer noprint">
					<Button className="registerBeacon-submitButton" onClick={this.onBackClick} >Back</Button>
					<Button className="registerBeacon-submitButton" type="submit" primary onClick={this.onSaveClick}>Save Vehicle</Button>
				</div>
			</Container>
		)
	}
}
