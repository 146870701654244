import { action, observable, computed, toJS } from "mobx";
import RegistrationState from "./registrationState";

class RouteState {
  @observable needToConfirmRoute;
  history;
  routeToConfirm;
  @observable isShowBurgerMenu = false;

  registerHistory(history) {
    //console.log("Home: RouteStore -> registerHistory -> path", history);
    this.history = history;
  }

  @action setRoute(path) {
    console.log("Home: RouteStore -> setRoute -> path", path, RegistrationState.hasUnsavedChanges);

    if (RegistrationState.hasUnsavedChanges) {
      this.needToConfirmRoute = true;
      this.routeToConfirm = path;
    } else {
      this.history.push(path);
    }
  }

  @action confirmRoute() {
    RegistrationState.resetBeaconData(); //this means user has cancelled registration.
    this.history.push(this.routeToConfirm);
    this.needToConfirmRoute = false;
  }

  @action cancelRoute() {
    this.needToConfirmRoute = false;
  }

  getState() {
    if (this.history) return this.history.location.pathname;
    else return null;
  }

  @action setIsShowBurgerMenu(value) {
    this.isShowBurgerMenu = value;
  }

}{}

const singleton = new RouteState(); //export class as singleton to be used through the application.
export default singleton;
