import dbService from "./dbService";
import { insertOrUpdateDocument } from "./serviceUtils";
import generateHash from "random-hash";

let db;

export async function initIndexes() {
  db = dbService();
  let result = await createVehicleSearchIndex();
  console.log("log: initIndexes -> createVehicleSearchIndex", result);
}

async function createVehicleSearchIndex() {
  try {
    db = dbService();
    var result = await db.createIndex({
      index: {
        fields: ["type", "owner", "beaconCountryCode", "vehicleType", "vehicleModel"],
        name: "vehicleSearchIndex",
        ddoc: "vehicleSearchIndex",
        type: "json",
      },
    });
    return result;
  } catch (err) {
    console.error(err);
  }
}

export async function searchVehicles(criterias, offset, limit) {
  console.log("VehicleService:searchVehicles - criterias", criterias);
  console.time("VehicleService:searchVehicles:searchVehicles");
  let selector = {
    type: "vehicle",
    owner: { $gte: null },
    beaconCountryCode: { $gte: null },
    vehicleType: { $gte: null },
    vehicleModel: { $gte: null },
  };

  if (criterias.owner) {
    selector["owner"] = criterias.owner;
  }
  if (criterias.beaconCountryCode) {
    selector["beaconCountryCode"] = criterias.beaconCountryCode;
  }
  if (criterias.vehicleType) {
    selector["vehicleType"] = { $gte: criterias.vehicleType, $lte: criterias.vehicleType + "\uffff" };
  }
  if (criterias.vehicleModel) {
    // selector["vehicleModel"] = { $gte: criterias.vehicleModel, $lte: criterias.vehicleModel + "\uffff" };
    selector["vehicleModel"] = { $regex: "(?i)" + criterias.vehicleModel + "(?i)" };
  }

  console.log("VehicleService:searchVehicles - selector", selector);

  try {
    db = dbService();
    var result = await db.find({
      selector: selector,
      use_index: "vehicleSearchIndex",
      // limit: 500
    });
    console.log("VehicleService:searchVehicles - result", result);
    console.timeEnd("VehicleService:searchVehicles:searchVehicles");
    return result;
  } catch (err) {
    console.error("VehicleService:searchVehicles - error", err);
    throw err;
  }
}

export async function saveVehicle(vehicle, ownerId, beaconCountryCode) {
  try {
    const vehicleInfo = {
      _id: vehicle.vehicleGroup == "selectExistingVehicle" ? vehicle.selectVehicle : "vehicle_" + generateHash({ length: 16 }),
      owner: ownerId,
      type: "vehicle",
      beaconCountryCode: beaconCountryCode || "",

      vehicleType: vehicle.vehicleType || "",
      vehicleModel: vehicle.vehicleModel || "",
      registrationNumber: vehicle.registrationNumber || "",
      registrationCountry: vehicle.registrationCountry || "",
      vehicleColor: vehicle.vehicleColor || "",
      specificUsage: vehicle.specificUsage || "",
      comment: vehicle.comment || "",
      image1: vehicle.image1, // url of s3
      image2: vehicle.image2, // url of s3
      vehicleTypeOther: vehicle.vehicleTypeOther || "",
    };
    db = dbService();
    let result = await insertOrUpdateDocument(vehicleInfo, db);
    return result;
  } catch (err) {
    console.error("vehicleService:saveVehicle - error", err);
  }
}
