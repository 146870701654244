export function insertOrUpdateDocument(doc, db) {
  return db
    .get(doc._id)
    .catch(function(e) {     
      if (e.name === "not_found") {
        return doc;
      } else {
        throw e; // hm, some other error
      }
    })
    .then(result => {    
    console.log('log: insertOrUpdateDocument -> result', result);
      let currentDate = new Date();
      if (result._rev) {        
        doc._rev = result._rev;        
      }
      else {
        doc.dateCreated = currentDate;
      }
      doc.lastEditDate = currentDate.getTime();
      return db.put(doc);
    })
    .catch(e => {
      console.error("log: insertOrUpdateDocument -> error", e);
      throw e;
    });
}

export async function fetchDatafromAPI(apiEndpoint, data) {
  //console.log('log: fetchDatafromAPI -> apiEndpoint', apiEndpoint);
  const response = await fetch(apiEndpoint, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      //credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json"
      },
      redirect: "follow", // manual, *follow, error
      //referrerPolicy: "no-referrer", // no-referrer, *client
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    }
  );  
  console.log('log: fetchDatafromAPI -> response', response);
  return await response.json(); // parses JSON response into native JavaScript objects
} 