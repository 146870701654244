const BAUDOT = {
  "111000": "A",
  "110011": "B",
  "101110": "C",
  "110010": "D",
  "110000": "E",
  "110110": "F",
  "101011": "G",
  "100101": "H",
  "101100": "I",
  "111010": "J",
  "111110": "K",
  "101001": "L",
  "100111": "M",
  "100110": "N",
  "100011": "O",
  "101101": "P",
  "111101": "Q",
  "101010": "R",
  "110100": "S",
  "100001": "T",
  "111100": "U",
  "101111": "V",
  "111001": "W",
  "110111": "X",
  "110101": "Y",
  "110001": "Z",
  "100100": " ",
  "011000": "-",
  "010111": "/",
  "001101": "0",
  "011101": "1",
  "011001": "2",
  "010000": "3",
  "001010": "4",
  "000001": "5",
  "010101": "6",
  "011100": "7",
  "001100": "8",
  "000011": "9",
  "010110": "?",
  "000000": "?",
  "100000": ""
};

export default function decodeBaudot(binaryValue) {
  console.log('log ~ decodeBaudot ~ binaryValue:', binaryValue);
  let correctedTo6BitBinary = "";
  if (binaryValue.length % 5 == 0) {
    let length = binaryValue.length / 6;

    for (let i = 0; i < length; i++) {
      let binaryPart = binaryValue.substring(i * 5, (i + 1) * 5);
      let sixbitValue = "1" + binaryPart;
      correctedTo6BitBinary += sixbitValue;
    }
  }

  if (correctedTo6BitBinary) {
    console.warn("15bits baudot to 6bits baudot", correctedTo6BitBinary);
    binaryValue = correctedTo6BitBinary;
  }

  if (binaryValue.length % 6 !== 0) {
    console.warn("decodeBaudot - binaryValue of wrong length", binaryValue);
    //throw new error('decodeBaudot - binaryValue of wrong length');
  }

  let length = binaryValue.length / 6;
  let returnString = "";
  for (let i = 0; i < length; i++) {
    let binaryPart = binaryValue.substring(i * 6, (i + 1) * 6);
    let decodedValue = BAUDOT[binaryPart];
    console.log('log ~ file: baudot.mjs:73 ~ decodeBaudot ~ decodedValue:', decodedValue);
    if (decodedValue === undefined) throw "ERROR - Baudot code error binary code not found - " + binaryPart;
    returnString += decodedValue;
  }
  console.log("log ~ file: baudot.js ~ line 77 ~ decodeBaudot ~ returnString", returnString);
  return returnString;
}
