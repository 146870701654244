import i18n from "i18next";
import React, { Component, useEffect } from "react";
import * as Yup from "yup";
import { Form, Input, Button, Popup, Select, Header, Grid, Transition, Message } from "semantic-ui-react";
import RegistrationState from "../../../state/registrationState";
import withSemanticUIFormik from "../../../utils/formHelper";
import RouteState from "../../../state/routeState";
import AppConfigState from "../../../state/appConfigState";
import UserState from "../../../state/userState";

const statusOption = [
  { key: "af", value: "af", text: "Working" },
  { key: "ax", value: "ax", text: "Aland Islands" },
  { key: "al", value: "al", text: "Albania" },
  { key: "dz", value: "dz", text: "Algeria" },
  { key: "as", value: "as", text: "American Samoa" },
];

const options = [{ key: "m", text: "Male", value: "male" }, { key: "f", text: "Female", value: "female" }, { key: "o", text: "Other", value: "other" }];
let storedValues = {};
const InnerForm = (props) => {
  const { values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, isValid } = props;

  let hexHasError = touched.hex && errors.hex && errors.hex.length > 0;
  let lengthHasError = touched.length && errors.length && errors.length.length > 0;
  let peopleCapacityHasError = touched.peopleCapacity && errors.peopleCapacity && errors.peopleCapacity.length > 0;
  let maxEnduranceHasError = touched.maxEndurance && errors.maxEndurance && errors.maxEndurance.length > 0;
  let cruiseAirSpeedHasError = touched.cruiseAirSpeed && errors.cruiseAirSpeed && errors.cruiseAirSpeed.length > 0;
  let wingSpanHasError = touched.wingSpan && errors.wingSpan && errors.wingSpan.length > 0;
  let aircraft24BitAddressHasError = touched.aircraft24BitAddress && errors.aircraft24BitAddress && errors.aircraft24BitAddress.length > 0;

  let countryCodeOptions = AppConfigState.countryNamesWithCode.sort((a, b) => a.text.localeCompare(b.text));
  let canEditByStatus =
    props.decodedBeacon.specialStatus == "ACTIVE" || props.decodedBeacon.specialStatus == undefined || props.decodedBeacon.specialStatus == "RELEASETOMAINTENANCE";

  if (UserState.notAllowToEditByRoleId.includes(UserState.currentUserProfile.roleId)) {
    canEditByStatus = false;
  }

  const saveAndClose = () => {
    if (!RegistrationState.isNewRegisterBeacon) {
      RegistrationState.setIsSaving(true);
      RegistrationState.updateBeaconFields(props.values);
      RegistrationState.updateBeacon().then(() => {
        RegistrationState.setIsBeaconSaved(true);
        props.onClose();
        RegistrationState.setIsSaving(false);
      });
    }
  };
  storedValues = values;
  useEffect(() => {
    return function cleanup() {
      if (!RegistrationState.isNewRegisterBeacon) {
        console.log("log: InnerForm -> cleanup -> ", storedValues);
        //RegistrationState.updateBeaconFields(storedValues);
      }
    };
  }, []);

  // handle aircraft24BitAddress
  const handleChange24BitAddress = (e, data) => {
    if (data && data.name && data.value.length <= 6) {
      props.setFieldValue(data.name, data.value);
    }
  };

  let showDecodedMismatchWarning = false;
  if (values.aircraft24BitAddress !== values.aircraft24BitAddressDecoded && touched.aircraft24BitAddress) {
    showDecodedMismatchWarning = true;
  }

  const saveToStateBeforeHandleChange = function(e, data) {
    console.log("log: InnerForm -> saveToStateBeforeHandleChange -> name, value", data.name, data.value);
    RegistrationState.saveOnFieldChange(data.name, data.value);
    handleChange(e, data);
  };

  return (
    <Form className="beaconLocation-form  animated fadeIn faster">
      <Header as="h2" icon textAlign="center">
        <Header.Content>Please supply additional information about your vehicle, if applicable</Header.Content>
      </Header>
      <ErrorDisplay errors={errors} touched={touched} status={status} />
      <DecodedMismatchWarning show={showDecodedMismatchWarning} />

      <Form.Group widths="equal">
        <Form.Input
          fluid
          label="Deployable Survival Crafts / Equipment"
          placeholder=""
          name="deployableSurvivalCrafts"
          value={values.deployableSurvivalCrafts}
          onChange={saveToStateBeforeHandleChange}
          onBlur={handleBlur}
          disabled={!canEditByStatus}
          className={canEditByStatus ? "editing-input" : ""}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input
          fluid
          label="Fixed Survival Crafts / Equipment"
          placeholder=""
          name="fixedSurvivalCrafts"
          value={values.fixedSurvivalCrafts}
          onChange={saveToStateBeforeHandleChange}
          onBlur={handleBlur}
          disabled={!canEditByStatus}
          className={canEditByStatus ? "editing-input" : ""}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input
          fluid
          label="Max. Endurance (h) "
          placeholder=""
          name="maxEndurance"
          value={values.maxEndurance}
          onChange={saveToStateBeforeHandleChange}
          onBlur={handleBlur}
          type="number"
          min="0"
          disabled={!canEditByStatus}
          className={canEditByStatus ? "editing-input" : ""}
          error={maxEnduranceHasError}
        />
        <Form.Input
          fluid
          label="Cruise Air Speed (kt) "
          placeholder=""
          name="cruiseAirSpeed"
          value={values.cruiseAirSpeed}
          onChange={saveToStateBeforeHandleChange}
          onBlur={handleBlur}
          type="number"
          min="0"
          disabled={!canEditByStatus}
          className={canEditByStatus ? "editing-input" : ""}
          error={cruiseAirSpeedHasError}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Field>
          <label>
            Length Overall (m)
            <Popup content="Overall length of vessel in metres." trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
          </label>
          <Form.Input
            fluid
            placeholder=""
            type="number"
            min="0"
            name="length"
            value={values.length}
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-input" : ""}
            error={lengthHasError}
          />
        </Form.Field>

        <Form.Input
          fluid
          label="Wing Span (m) "
          placeholder=""
          type="number"
          min="0"
          name="wingSpan"
          value={values.wingSpan}
          onChange={saveToStateBeforeHandleChange}
          onBlur={handleBlur}
          disabled={!canEditByStatus}
          className={canEditByStatus ? "editing-input" : ""}
          error={wingSpanHasError}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input
          fluid
          label="Capacity (Crew and Passengers)"
          placeholder=""
          type="number"
          min="0"
          name="peopleCapacity"
          value={values.peopleCapacity}
          onChange={saveToStateBeforeHandleChange}
          onBlur={handleBlur}
          disabled={!canEditByStatus}
          className={canEditByStatus ? "editing-input" : ""}
          error={peopleCapacityHasError}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Field>
          <label>
            Aircraft 24 Bit Address
            <Popup
              content="24-bit address of the aircraft, expressed as 6 hexadecimal (0-9, A-F) characters."
              trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
            />
          </label>
          <Form.Input
            fluid
            placeholder=""
            name="aircraft24BitAddress"
            value={values.aircraft24BitAddress}
            onChange={handleChange24BitAddress}
            onBlur={handleBlur}
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-input" : ""}
            error={aircraft24BitAddressHasError}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input
          fluid
          label="Aircraft 24 Bit Address (Decoded) "
          placeholder=""
          name="aircraft24BitAddressDecoded"
          value={values.aircraft24BitAddressDecoded}
          onChange={saveToStateBeforeHandleChange}
          onBlur={handleBlur}
          disabled={true}
        />
      </Form.Group>

      <Form.Field>
        <label>
          Aircraft Nationality
          <Popup
            content="MID country code for flag state or aircraft nationality of registration."
            trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
          />
        </label>
        <Form.Select
          search
          fluid
          options={countryCodeOptions}
          placeholder="Select"
          name="vehicleNationality"
          value={values.vehicleNationality}
          onChange={saveToStateBeforeHandleChange}
          onBlur={handleBlur}
          disabled={!canEditByStatus}
          className={canEditByStatus ? "editing-select" : ""}
        />
      </Form.Field>

      <Grid centered columns={3} stackable>
        <Grid.Column>
          <Button className="registerBeacon-submitButton" onClick={onBackClick}>
            Back
          </Button>
        </Grid.Column>
        <Grid.Column>
          <Button className="registerBeacon-submitButton" type="submit" primary onClick={handleSubmit}>
            Next
          </Button>
        </Grid.Column>
        {/* {RegistrationState.isNewRegisterBeacon ? (
          ""
        ) : (
          <Grid.Column>
            <Button className="registerBeacon-submitButton" primary onClick={saveAndClose} loading={RegistrationState.isSaving}>
              Save & Close
            </Button>
          </Grid.Column>
        )} */}
      </Grid>
    </Form>
  );
};

function onBackClick() {
  RegistrationState.setVehiculePreviousStep();
}

const ErrorDisplay = ({ errors, touched, status }) => {
  let errorList = [];
  Object.keys(errors).forEach((key) => {
    let value = errors[key];
    if (touched.hasOwnProperty(key)) {
      errorList.push(<Message.Item key={value}>{value}</Message.Item>);
    }
  });

  if (status) {
    errorList.push(<Message.Item key={status}>{status}</Message.Item>);
  }

  return (
    <Transition.Group animation="fade down" duration={500}>
      {errorList.length > 0 && (
        <Message error>
          <Message.Header>There are items that require your attention</Message.Header>
          <Message.List>{errorList}</Message.List>
        </Message>
      )}
    </Transition.Group>
  );
};

const beaconLocationForm = withSemanticUIFormik({
  mapPropsToValues: ({ decodedBeacon }) => {
    if (!decodedBeacon) {
      decodedBeacon = {};
    }
    console.log("ELT: decodedBeacon", decodedBeacon);
    return {
      deployableSurvivalCrafts: decodedBeacon.deployableSurvivalCrafts || "",
      fixedSurvivalCrafts: decodedBeacon.fixedSurvivalCrafts || "",
      maxEndurance: decodedBeacon.maxEndurance || "",
      cruiseAirSpeed: decodedBeacon.cruiseAirSpeed || "",
      length: decodedBeacon.length || "",
      wingSpan: decodedBeacon.wingSpan || "",
      peopleCapacity: decodedBeacon.peopleCapacity || "",
      aircraft24BitAddress: decodedBeacon.aircraft24BitAddress || "",
      aircraft24BitAddressDecoded: decodedBeacon.aircraft24BitAddressDecoded || "",
      vehicleNationality: decodedBeacon.vehicleNationality || "",
    };
  },
  validationSchema: Yup.object().shape({
    maxEndurance: Yup.number().min(0, "Length Overall (m) must be greater than or equal to 0"),
    cruiseAirSpeed: Yup.number().min(0, "Length Overall (m) must be greater than or equal to 0"),
    length: Yup.number().min(0, "Length Overall (m) must be greater than or equal to 0"),
    wingSpan: Yup.number().min(0, "Length Overall (m) must be greater than or equal to 0"),
    peopleCapacity: Yup.number().min(0, "Capacity (Crew and Passengers) must be greater than or equal to 0"),

    aircraft24BitAddressDecoded: Yup.string().notRequired(),
    aircraft24BitAddress: Yup.string()
      .notRequired()
      .matches(/^[0-9A-Fa-f]{1,64}$/, {
        message: "Aircraft 24 Bit Address must be valid 6 hexadecimal characters A-F, 0-9",
      })
      .test("len", "Aircraft 24 Bit Address must be valid 6 hexadecimal characters A-F, 0-9", function(val) {
        if ((val && val.length === 6) || !val) {
          return true;
        } else {
          return false;
        }
      }),
  }),

  handleSubmit: (values, { setSubmitting, isValid }) => {
    console.log("log: values", values, isValid);

    RegistrationState.updateBeaconFields(values);
    RegistrationState.setVehiculeNextStep();
  },
})(InnerForm);

const DecodedMismatchWarning = ({ show }) => {
  if (show) {
    return (
      <Transition.Group animation="fade down" duration={500}>
        <Message error>
          <Message.Header>Warning</Message.Header>
          <Message.List>
            Information entered does not match the information decoded from your UIN. Please contact your beacon manufacturer or place of purchase to resolve.
          </Message.List>
        </Message>
      </Transition.Group>
    );
  } else {
    return null;
  }
};

export default beaconLocationForm;
