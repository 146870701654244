import i18n from 'i18next'
import React, { Component } from 'react'
import * as Yup from 'yup'
import { Form, Button, Header } from 'semantic-ui-react'
import withSemanticUIFormik from '../../utils/formHelper'
import PocState from '../../state/pocState'
import AppConfigState from "../../state/appConfigState";
import TacState from '../../state/tacState'

class InnerForm extends Component {

    deletePOC = async () => {
        let {item, onClose, onSearchClick} = this.props;
        console.log('---> deleted!');
        let deletePoc = await PocState.deletePOC(item._id);
        onClose();
        // PocState.searchPOC({});
        onSearchClick()
    }

	render() {

		const {
			values,
			touched,
			errors,
			dirty,
			isSubmitting,
			handleChange,
			handleBlur,
			handleSubmit,
			handleReset,
			isValid,
            onClose,
            isNewRecord
        } =	this.props;

        let countryOptions = AppConfigState.countryNames
        let midOptions = AppConfigState.midNpdOnly
        let typeOptions = PocState.ContactTypesOptions

		return (
			<Form className="poc-form animated fadeIn faster">
				<Header as='h2' icon textAlign='center'>Edit point of contact.</Header>

                <Form.Group >
                    <Form.Field width='4'>
                        type:
                    </Form.Field>
                    <Form.Select
                        width='8'
                        search
                        fluid
                        placeholder=""
                        name="type"
                        defaultValue={values.type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        options={typeOptions}
                    />
                </Form.Group>
                <Form.Group >
                    <Form.Field width='4'>
                        name:
                    </Form.Field>
                    <Form.Input width='8' fluid name='name' placeholder='' defaultValue={values.name} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>
                <Form.Group >
                    <Form.Field width='4'>
                        telex:
                    </Form.Field>
                    <Form.Input width='8' fluid name='telex' placeholder='' defaultValue={values.telex} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>
                <Form.Group >
                    <Form.Field width='4'>
                        fax1:
                    </Form.Field>
                    <Form.Input width='8' fluid name='fax1' placeholder='' defaultValue={values.fax1} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>
                <Form.Group >
                    <Form.Field width='4'>
                        telephone1:
                    </Form.Field>
                    <Form.Input width='8' fluid name='telephone1' placeholder='' defaultValue={values.telephone1} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>          
                <Form.Group >
                    <Form.Field width='4'>
                        address:
                    </Form.Field>
                    {/* <Form.Input width='8' fluid name='address' placeholder='' defaultValue={values.address} onBlur={handleBlur} onChange={handleChange} /> */}
                    <Form.TextArea width='8' name='address' placeholder='' defaultValue={values.address} onBlur={handleBlur} onChange={handleChange} rows={5} />
                </Form.Group>
                <Form.Group >
                    <Form.Field width='4'>
                        city:
                    </Form.Field>
                    <Form.Input width='8' fluid name='city' placeholder='' defaultValue={values.city} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>
                <Form.Group >
                    <Form.Field width='4'>
                        zipcode:
                    </Form.Field>
                    <Form.Input width='8' fluid name='zipcode' placeholder='' defaultValue={values.zipcode} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>
                <Form.Group >
                    <Form.Field width='4'>
                        country:
                    </Form.Field>
                    <Form.Select
                        width='8'
                        search
                        fluid
                        placeholder=""
                        name="country"
                        defaultValue={values.country}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        options={countryOptions}
                    />
                </Form.Group>
                <Form.Group >
                    <Form.Field width='4'>
                        mid:
                    </Form.Field>
                    <Form.Select
                        width="8"
                        placeholder=""
                        fluid
                        selection
                        search
                        name="mid"
                        defaultValue={values.mid}
                        options={midOptions}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group >
                    <Form.Field width='4'>
                        email:
                    </Form.Field>
                    <Form.Input width='8' fluid name='email' placeholder='' defaultValue={values.email} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>
                <Form.Group >
                    <Form.Field width='4'>
                        registration:
                    </Form.Field>
                    <Form.Input width='8' fluid name='registration' placeholder='' defaultValue={values.registration} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>
                <Form.Group >
                    <Form.Field width='4'>
                        poc:
                    </Form.Field>
                    <Form.Input width='8' fluid name='poc' placeholder='' defaultValue={values.poc} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>
                <Form.Group >
                    <Form.Field width='4'>
                        mcc:
                    </Form.Field>
                    <Form.Input width='8' fluid name='mcc' placeholder='' defaultValue={values.mcc} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>

                <Form.Group >
                    <Form.Field width='4'>
                        aftn:
                    </Form.Field>
                    <Form.Input width='8' fluid name='aftn' placeholder='' defaultValue={values.aftn} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>
                <Form.Group >
                    <Form.Field width='4'>
                        email2:
                    </Form.Field>
                    <Form.Input width='8' fluid name='email2' placeholder='' defaultValue={values.email2} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>
                <Form.Group >
                    <Form.Field width='4'>
                        website_url:
                    </Form.Field>
                    <Form.Input width='8' fluid name='website_url' placeholder='' defaultValue={values.website_url} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>
           
                <Form.Group >
                    <Form.Field width='4'>
                        ci_webpage_1:
                    </Form.Field>
                    <Form.Input width='8' fluid name='ci_webpage_1' placeholder='' defaultValue={values.ci_webpage_1} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>
                <Form.Group >
                    <Form.Field width='4'>
                        comments:
                    </Form.Field>
                    <Form.TextArea width='8' name='comments' placeholder='' defaultValue={values.comments} onBlur={handleBlur} onChange={handleChange} rows={5} />
                </Form.Group>

 				<div className="activityDetail-submitContainer">
                    {!isNewRecord && <Button className="registerBeacon-submitButton" onClick={this.deletePOC} loading={PocState.isLoading} disabled={PocState.isLoading}>Delete</Button>}
					<Button className="registerBeacon-submitButton" onClick={onClose}>Close</Button>
					<Button className="registerBeacon-submitButton" type="submit" primary onClick={handleSubmit} loading={isSubmitting} disabled={isSubmitting} >Save</Button>
				</div>
			</Form>
		);
	}

};

const PocForm = withSemanticUIFormik({
	mapPropsToValues: ({ item }) => {
		if(item){
            // console.log('>>>>>> poc save: ' + JSON.stringify(item))
			return {
                ...item // keep all fields from db

                // _id:                item._id,
                // _rev:               item._rev,
                // type:               item.type,
				// id:                 item.id,
                // name:               item.name,
                // telex:              item.telex,
                // fax1:               item.fax1,
                // telephone1:         item.telephone1,
                // address:            item.address,
                // email:              item.email,
                // registration:       item.registration,
                // poc:                item.poc,
                // mcc:                item.mcc,
                // aftn:         item.aftn,
                // city:         item.city,
                // zipcode:      item.zipcode,
                // country:      item.country,
                // mid:      item.mid,
                // email2:       item.email2,
                // website_url:  item.website_url,
                // ci_webpage_1:      item.ci_webpage_1,
                // comments:     item.comments,
            }
		}else{
			return {
                type: '',
				id: '',
                name: '',
                telex: '',
                fax1: '',
                telephone1: '',
                address: '',
                email: '',
                registration: '',
                poc: '',
                mcc: '',
                aftn: '',
                city: '',
                zipcode: '',
                country: '',
                mid: '',
                email2: '',
                website_url: '',
                ci_webpage_1: '',
                comments: '',
			}
		}
	},
	validationSchema: Yup.object().shape({

	}),

	handleSubmit: async (values, { setSubmitting, isValid, props }) => {
        // console.log('---> handleSubmit > values: ', values)
        // return

        setSubmitting(true);
        if(props.isNewRecord){
            // add new Poc
            let lastId = await PocState.getLastId();
            let saveId = parseInt(lastId) + 1;
            // required fields for new POC
            values._id = saveId.toString();
            values.id = saveId.toString();
            values.state = values.state ? values.state : '1'; // published by default
            // manage date time format -> 0000-00-00 00:00:00
            let d = new Date();
            let currentDateTime = TacState.dateTimeFormat(d, true);
            values.created = currentDateTime

            console.log('---> handleSubmit > values: ', values)
            PocState.savePoc(values).then(res => {
                setSubmitting(false);
                props.onClose();
                // PocState.searchPOC({});
                props.onSearchClick()
            })
        }
        else{
            // edit existing
            // override values
            let d = new Date();
            let currentDateTime = TacState.dateTimeFormat(d, true);
            values.modified = currentDateTime;

            console.log('---> handleSubmit > values: ', values)
            PocState.savePoc(values).then(res => {
                setSubmitting(false);
                props.onClose();
                // PocState.searchPOC({});
                props.onSearchClick()
            })
        }

        
    }

})(InnerForm);

export default PocForm;