import React, { Component } from "react";
import Footer from "../../components/footer";
import TopMenu from "../../components/topMenu";
import "./registerActivity.css";
import RegisterActivity from './registerActivity'

export default class RegisterActivityPage extends Component {
  render() {
    return (
      <div className="registerBeacon-page">
        <TopMenu />
        <RegisterActivity />
        <Footer />
      </div>
    );
  }
}