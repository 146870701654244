import { action, observable, computed, toJS, autorun } from "mobx";
import * as BeaconService from "../services/beaconService";
import * as utils from "../utils/utils";
import * as ActivityService from "../services/activityService";
import * as AssociatedUserService from "../services/associatedUserService";
import * as VehicleService from "../services/vehicleService";
import * as AccessLogService from "../services/accessLogService";
import * as UserService from "../services/userServices";
import UserState from "./userState";
import AppConfigState from "./appConfigState";
import { ItemMeta } from "semantic-ui-react";

const ACTIVITY_ONGOING = "ongoing";

class DashboardState {
  beacons = []; /*Removing the observable on this list because it can grow to many nodes and becomes too heavy memory wise for shitty computer like Val's laptop. */
  allBeacons = [];
  @observable beaconsLastUpdate = 0;
  @observable beaconsCurrentPage = 1;
  @observable beaconPerPage = 25;
  @observable totalBeacons = 0;
  @observable serverSidePaging = false;
  @observable column = "lastEditDate";
  @observable direction = "descending";
  @observable activities = [];
  @observable associatedUsers = [];
  @observable vehicles = [];
  @observable activeIndexs = [];
  @observable isLoading = false;
  @observable isSpecialQuery = false;
  beaconPagingCursor = 0;
  beaconSearchCriteria = {};
  @observable filterUsername = null;
  @observable totalBeaconsForUser = 0;
  @observable All = false;
  @observable edBeacons = [];
  @observable ownerHistory = [];
  @observable accountOwnerHistory = [];
  @observable beaconHistory = [];
  @observable beaconHistorySelectValues = [];
  @observable displayGroupUserNotice = true;
  @observable selectAll = false;
  @observable selectedBeacons = [];
  @observable totalActivitiesForUser = 0;
  @observable selectedBeaconHistory = null;

  constructor() {}

  @action reset = () => {
    this.beacons = [];
    this.allBeacons = [];
    this.totalBeacons = 0;
  };

  @action setTotalBeacons = (value) => {
    this.totalBeacons = value;
  };

  @action initialSelectAll = () => {
    this.selectAll = false;
    let initialList = [];
    // this.beacons.forEach((item) => {
    this.allBeacons.forEach((item) => {
      initialList.push({
        id: item._id,
        checked: false,
        isDelegationEnabled: AppConfigState.delegationEnabledByType(item)
      });
    });
    this.selectedBeacons = initialList;
    // console.log("-----> initialSelectAll -> selectedBeacons", initialList, this.selectedBeacons, this.allBeacons)
  };

  @action toggleSelectAll = () => {
    this.selectAll = !this.selectAll;
    this.selectedBeacons.forEach((item) => {
      // item.checked = this.selectAll;
      if (item.isDelegationEnabled) {
        item.checked = this.selectAll;
      } else {
        item.checked = false;
      }
    });
    // console.log("-----> toggleSelectAll -> selectedBeacons", toJS(this.selectedBeacons))
  };

  @action toggleSelectItem = (e, data) => {
    console.log("-----> toggleSelectItem -> selectedBeacons: ", data);
    this.selectedBeacons.forEach((item) => {
      if (item.id == data.value) {
        item.checked = data.checked;
      }
    });
    if (data.checked == false) {
      this.selectAll = false;
    } else if (this.selectedBeacons.length > 0) {
      if (this.selectedBeacons.find((x) => x.checked == false) == undefined) {
        this.selectAll = true;
      }
    }
    console.log("-----> toggleSelectItem -> selectedBeacons -> changed: ", toJS(this.selectedBeacons), this.allBeacons);
  };

  @action initialAll = () => {
    this.All = false;
    let initialList = [];
    this.beacons.forEach((item) => {
      initialList.push({
        id: item._id,
        checked: false
      });
    });
    this.edBeacons = initialList;
    // console.log("initialAll -> edBeacons", initialList, this.edBeacons)
  };

  @action toggleAll = () => {
    this.All = !this.All;
    this.edBeacons.forEach((item) => {
      item.checked = this.All;
    });
    //console.log("toggleAll -> edBeacons", this.edBeacons)
  };

  @action toggleItem = (e, data) => {
    this.edBeacons.forEach((item) => {
      if (item.id == data.value) {
        item.checked = data.checked;
      }
    });
    if (data.checked == false) {
      this.All = false;
    } else if (this.edBeacons.length > 0) {
      if (this.edBeacons.find((x) => x.checked == false) == undefined) {
        this.All = true;
      }
    }
    //console.log("toggleItem -> edBeacons", this.edBeacons)
  };

  async searchBeacons(criterias) {
    console.log("log: DashboardState -> searchBeacons -> criterias", criterias);

    // save criterias to state to prevent lose criterias when click pagination navigation.
    this.beaconSearchCriteria = criterias;

    if (!UserState.currentUserProfile) {
      return null;
    }
    console.log("log: DashboardState -> searchBeacons -> UserState.currentUserProfile", UserState.currentUserProfile);

    let roleId = UserState.currentUserProfile.roleId;
    let userProfile = UserState.currentUserProfile;
    setTimeout(() => {
      AccessLogService.saveQueryLog(userProfile, criterias);
    }, 1000);
    this.beaconsCurrentPage = 1;
    switch (roleId) {
      case "1":
        let beacons = await this.searchBeaconForNdp(criterias);
        this.initialAll();
        return beacons;
      case "2":
        sendGtagEvent("inspector_bncQuery")
        return this.searchBeaconForAdmins(criterias);
      case "3":
      case "4":
        sendGtagEvent("sars_bncQuery")
        return this.searchBeaconForAdmins(criterias);
      case "5":
        sendGtagEvent("users_bncQuery")
        return this.searchBeaconForUser(criterias);
      case "7":
        return this.searchBeaconForMaintProvider(criterias);
      default:
        console.error("DashboardState -> searchBeacons -> not support role", roleId, UserState.currentUserProfile);
        break;
    }
  }

  async searchBeaconForUser(criterias) {
    try {
      let results;
      let user = UserState.currentUserProfile;
      let beacons = [];

      if (JSON.stringify(criterias) === JSON.stringify({})) {
        criterias.owner = user._id; //add owner as criteria so we get only user beacons.
        //results = await BeaconService.searchBeacon(criterias);
        //results = await BeaconService.getUserBeacons(user._id);
        results = await BeaconService.searchBeacon(criterias);

        if (results !== undefined) {
          beacons = results.docs;
          //beacons = results.map((row) => row.doc);
        }
      } else {
        criterias.owner = user._id; //add owner as criteria so we get only user beacons.
        results = await BeaconService.searchBeacon(criterias);
        if (results !== undefined) {
          beacons = results.docs;
        }
      }

      this.totalBeacons = beacons.length;
      this.setBeacons(beacons);

      if (!this.column) {
        //set default to lastEditDate
        this.handleSort("lastEditDate");
      } else {
        this.handleSort(this.column);
      }
      console.log("log: DashboardState -> searchBeaconForUser -> this.column", this.column);
      return beacons;
    } catch (error) {
      console.log("ibrd: DashboardState -> searchBeacons -> error", error);
    }
  }

  async searchBeaconForNdp(criterias) {
    console.log("log ~ file: dashboardState.js ~ line 208 ~ DashboardState ~ searchBeaconForNdp ~ criterias", criterias);
    this.setIsLoading(true);

    try {
      let user = UserState.currentUserProfile;
      let hasNoCriteria = false;
      delete criterias["beaconCountryCode"];
      if (JSON.stringify(criterias) === JSON.stringify({})) {
        hasNoCriteria = true;
      }
      //quick monkey patch fix for legacy users where countryNumber is not an array
      /*if(!isNaN(user.countryNumber)){
        user.countryNumber = [user.countryNumber]
      }*/

      let results;

      let countryNumber = toJS(user.countryNumber);
      let firstValue = countryNumber[0];
      let countryNumberArray;
      if (firstValue && firstValue.includes(",")) {
        criterias.beaconCountryCode = firstValue.split(",");
      } else {
        criterias.beaconCountryCode = countryNumber;
      }

      //criterias.beaconCountryCode = toJS(user.countryNumber); //add countryNumber as criteria so we get only allow country beacon for NDP
      console.log("log: DashboardState -> searchBeaconForNdp -> criterias", criterias);

      //this.totalBeacons = await BeaconService.getBeaconTotalRowsPerNDP(user.countryNumber);
      console.log("log ~ file: dashboardState.js ~ line 221 ~ DashboardState ~ searchBeaconForNdp ~ user.countryNumber", user.countryNumber, hasNoCriteria);

      if (!this.column) this.column = "lastEditDate";
      if (!this.direction) this.direction = "descending";

      this.beaconPagingCursor = (this.beaconsCurrentPage - 1) * this.beaconPerPage;

      if (hasNoCriteria) {
        this.totalBeacons = await BeaconService.getBeaconTotalRowsPerNDP(user.countryNumber);
        results = await BeaconService.searchBeacon(criterias, this.beaconPerPage, this.beaconPagingCursor, this.column, this.direction);
        beacons = results.docs;
        this.serverSidePaging = true;
      } else {
        results = await BeaconService.searchBeacon(criterias, undefined, undefined, this.column, this.direction);
        beacons = results.docs;
        this.totalBeacons = beacons.length;
        this.serverSidePaging = false;
      }

      //let results = await BeaconService.getAllBeaconsPaged(this.beaconPerPage, this.beaconPagingCursor, this.column, this.direction);
      // console.log("log: DashboardState -> searchBeaconForAdmins (getAllBeaconsPaged) -> results", results);

      //******************* */
      // [@William] It doesn't make sense to get total beacons based on countryNumber every time.
      // How about when we have search criterias?
      // Should it be counting from the search result?
      //******************* */
      // this.totalBeacons = await BeaconService.getBeaconTotalRowsPerNDP(user.countryNumber);

      //results = await BeaconService.searchBeacon(criterias);
      let beacons = [];
      if (results !== undefined) {
        beacons = results.docs;
        //
      }

      this.setBeacons(beacons);

      this.setIsLoading(false);
      this.initialSelectAll();
      return beacons;
    } catch (error) {
      console.log("ibrd: DashboardState -> searchBeacons -> error", error);
      this.setIsLoading(false);
    }
  }

  async searchBeaconForAdmins(criterias) {
    console.log("log: DashboardState -> searchBeaconForAdmins -> criterias", criterias);

    this.setIsLoading(true);

    try {
      let beacons = [];

      if (this.filterUsername !== null) {
        this.serverSidePaging = true;
        this.beaconSearchCriteria = criterias;
        criterias.ownerUsername = this.filterUsername;
        this.totalBeacons = await BeaconService.getBeaconTotalRowsPerUser(this.filterUsername);
        console.log("log: DashboardState -> searchBeaconForAdmins -> totalBeacons", this.totalBeacons);
        this.beaconPagingCursor = (this.beaconsCurrentPage - 1) * this.beaconPerPage;

        let results = await BeaconService.searchBeacon(criterias, this.beaconPerPage, this.beaconPagingCursor, this.column, this.direction);
        console.log("log: DashboardState -> searchBeaconForAdmins -> results", results);

        if (results !== undefined) {
          //beacons = results.rows.map((row) => row.doc);
          beacons = results.docs;
        }
        this.setIsLoading(false);
        this.setBeacons(beacons);
      } else if (JSON.stringify(criterias) === JSON.stringify({})) {
        this.serverSidePaging = true;
        this.totalBeacons = await BeaconService.getBeaconTotalRows();
        if (!this.column) this.column = "lastEditDate";
        if (!this.direction) this.direction = "descending";

        this.beaconPagingCursor = (this.beaconsCurrentPage - 1) * this.beaconPerPage;

        let results = await BeaconService.searchBeacon(criterias, this.beaconPerPage, this.beaconPagingCursor, this.column, this.direction);
        //let results = await BeaconService.getAllBeaconsPaged(this.beaconPerPage, this.beaconPagingCursor, this.column, this.direction);
        console.log("log: DashboardState -> searchBeaconForAdmins (getAllBeaconsPaged) -> results", results);

        if (results !== undefined) {
          beacons = results.docs;
        }
        this.setIsLoading(false);
        this.setBeacons(beacons);
      } else {
        this.beaconPagingCursor = (this.beaconsCurrentPage - 1) * this.beaconPerPage;
        this.serverSidePaging = false;
        this.beaconSearchCriteria = criterias;
        console.time("DashboardState -> searchBeaconForAdmins - custom query time");
        let results = await BeaconService.searchBeacon(criterias, undefined, undefined, this.column, this.direction);
        console.timeEnd("DashboardState -> searchBeaconForAdmins - custom query time");
        console.log("log: DashboardState -> searchBeaconForAdmins -> results", results);

        if (results !== undefined) {
          beacons = results.docs;
        }
        this.totalBeacons = beacons.length;
        this.setIsLoading(false);
        this.setBeacons(beacons);
      }
      // console.log('>>>>>>>>>>>>>> beacons: ', beacons);
      this.initialSelectAll();
      return beacons;
    } catch (error) {
      console.log("log: DashboardState -> searchBeaconForAdmins -> error", error);
      this.setIsLoading(false);
    }
  }

  async searchBeaconForMaintProvider(criterias) {
    this.setIsLoading(true);
    console.log("log: DashboardState -> searchBeaconForMaintProvider -> criterias", criterias);
    try {
      let results;
      let user = UserState.currentUserProfile;
      criterias.maintProvider = user._id;
      results = await BeaconService.searchBeacon(criterias);
      let beacons = [];
      if (results !== undefined) {
        beacons = results.docs;
      }
      this.totalBeacons = beacons.length;
      this.setBeacons(beacons);
      this.setIsLoading(false);
      return beacons;
    } catch (error) {
      console.log("ibrd: DashboardState -> searchBeacons -> error", error);
      this.setIsLoading(false);
    }
  }

  async searchBeaconForExport(criterias) {
    try {
      if (!UserState.currentUserProfile) {
        return null;
      }
      let roleId = UserState.currentUserProfile.roleId;
      let user = UserState.currentUserProfile;
      console.log("ibrd: DashboardState -> searchBeacons -> test: ", roleId, user);
      switch (roleId) {
        case "1":
          criterias.beaconCountryCode = user.countryNumber;
          break;
        //case "2":
        case "3":
        case "4":
          //If filterUsername !== null add criteria to search.
          if (this.filterUsername !== null) {
            criterias.ownerUsername = this.filterUsername;
          }
          break;
        case "5":
          criterias.owner = user._id;
          break;
        case "7":
          criterias.maintProvider = user._id;
          break;
        default:
          break;
      }

      let results = await BeaconService.searchBeacon(criterias);
      let beacons = [];
      if (results !== undefined) {
        beacons = results.docs;
      }
      let userProfile = UserState.currentUserProfile;
      AccessLogService.saveExportLog(userProfile, criterias);
      return beacons;
    } catch (error) {
      console.log("ibrd: DashboardState -> searchBeacons -> error", error);
    }
  }

  async getTotalBeaconsForUser(owner) {
    try {
      this.setTotalBeaconsForUser(0);
      let results = await BeaconService.getBeaconTotalRowsPerUser(owner);
      console.log("ibrd: DashboardState -> totalBeaconsForUser -> ", results);
      this.setTotalBeaconsForUser(results);
      return results;
    } catch (error) {
      console.log("ibrd: DashboardState -> totalBeaconsForUser -> error", error);
    }
  }
  @action setTotalBeaconsForUser(value) {
    this.totalBeaconsForUser = value;
  }

  async getTotalBeaconsByOwner(criterias) {
    try {
      const { ownerName, ownerUsername } = criterias;
      this.setIsLoading(true);
      this.setTotalBeaconsForUser(0);
      let results = await BeaconService.getUserBeaconsByOwnerAndAccount(ownerName, ownerUsername, true);
      console.log("log: getTotalBeaconsByOwner -> results", results);
      if (results && results.rows[0]) {
        this.setTotalBeaconsForUser(results.rows[0].value);
      }
      this.setIsLoading(false);
      return results;
    } catch (error) {
      console.log("ibrd: DashboardState -> totalBeaconsForUser -> error", error);
    }
  }

  async getBeaconsByOwner(criterias) {
    try {
      const { ownerName, ownerUsername } = criterias;
      this.setIsLoading(true);
      this.setTotalBeaconsForUser(0);
      let results = await BeaconService.getUserBeaconsByOwnerAndAccount(ownerName, ownerUsername, false);
      console.log("log: getBeaconsByOwner -> results", results);
      if (results && results.rows) {
        let beacons = results.rows.map((row) => row.doc);

        this.setBeacons(beacons);
        this.setTotalBeaconsForUser(beacons.length);
      }
      this.setIsLoading(false);
      return results;
    } catch (error) {
      console.log("ibrd: DashboardState -> getBeaconsByOwner -> error", error);
    }
  }

  @action handleSort(clickedColumn) {
    let roleId = UserState.currentUserProfile.roleId;
    this.column = clickedColumn;
    if (roleId === "2" || roleId === "3" || roleId === "4") {
      this.searchBeaconForAdmins(this.beaconSearchCriteria);
    } else {
      console.log("log: DashboardState -> @actionhandleSort -> clickedColumn", clickedColumn);
      let sortBeacon = this.allBeacons;
      this.beaconsCurrentPage = 1;
      sortBeacon = utils.sortBy(sortBeacon, clickedColumn);
      if (this.direction === "descending") {
        sortBeacon = sortBeacon.slice().reverse();
      }

      this.setBeacons(sortBeacon);
    }
  }

  @action setBeacons(beacons) {
    console.log("log: DashboardState -> @action setBeacons -> beacons", beacons);
    let currentPage = this.beaconsCurrentPage;
    this.allBeacons = beacons;
    // console.log("log: render -> currentPage", currentPage);
    let beaconPerPage = this.beaconPerPage;
    let nbOfPages = Math.ceil(this.totalBeacons / beaconPerPage);
    let pagedBeacons;
    if (this.serverSidePaging) {
      this.beacons = beacons;
    } else {
      this.beacons = beacons.slice((currentPage - 1) * beaconPerPage, currentPage * beaconPerPage);
    }

    this.beaconsLastUpdate = Date.now();
    BeaconService.storeBeaconOffline(beacons);
  }

  @action resetSearchByUsername() {
    console.log("log: @actionresetSearchByUsername -> resetSearchByUsername");

    this.filterUsername = null;
    this.isSpecialQuery = false;
    this.beaconsCurrentPage = 1;
    this.beaconSearchCriteria = {};
    this.searchBeacons({});
  }

  @action setSpecialQuery(isSpecial) {
    this.isSpecialQuery = isSpecial;
  }

  @action setBeaconsPage(page) {
    console.log("log: DashboardState -> @actionsetBeaconsPage -> page", page);

    if (this.beaconsCurrentPage !== page) {
      this.beaconsCurrentPage = page;

      if (!this.serverSidePaging) {
        this.setBeacons(this.allBeacons);
        return;
      }

      let roleId = UserState.currentUserProfile.roleId;
      switch (roleId) {
        case "1":
          return this.searchBeaconForNdp(this.beaconSearchCriteria);
        case "2":
        case "3":
        case "4":
          return this.searchBeaconForAdmins(this.beaconSearchCriteria);
        case "5":
          return this.searchBeaconForUser(this.beaconSearchCriteria);
        case "7":
          return this.searchBeaconForMaintProvider(this.beaconSearchCriteria);
        default:
          console.error("DashboardState -> searchBeacons -> not support role", roleId, UserState.currentUserProfile);
          break;
      }
    }
  }

  @action setBeaconsPerPage(beaconPerPage) {
    console.log("log: DashboardState -> @actionsetBeaconsPerPage -> beaconPerPage", beaconPerPage);
    this.beaconPerPage = beaconPerPage == 0 ? this.totalBeacons : beaconPerPage;
    this.beaconsCurrentPage = 1;

    let roleId = UserState.currentUserProfile.roleId;
    // if (roleId === "2" || roleId === "3" || roleId === "4") {
    //   this.searchBeaconForAdmins(this.beaconSearchCriteria);
    // }
    switch (roleId) {
      case "1":
        return this.searchBeaconForNdp(this.beaconSearchCriteria);
      case "2":
      case "3":
      case "4":
        return this.searchBeaconForAdmins(this.beaconSearchCriteria);
      case "5":
        return this.searchBeaconForUser(this.beaconSearchCriteria);
      case "7":
        return this.searchBeaconForMaintProvider(this.beaconSearchCriteria);
      default:
        console.error("DashboardState -> searchBeacons -> not support role", roleId, UserState.currentUserProfile);
        break;
    }
  }

  // Temporary Activity Search
  async searchActivities(criterias) {
    if (!UserState.currentUserProfile) {
      return null;
    }

    let roleId = UserState.currentUserProfile.roleId;

    switch (roleId) {
      case "1":
        return this.searchActivitiesForNdp(criterias);
      case "3":
      case "4": //not support
        return this.searchActivitiesForAdmin(criterias);
      case "5":
        return this.searchActivitiesForUser(criterias);
      case "7": //maintenance provider
        return null;
      default:
        console.error("DashboardState -> searchActivities -> not support role", roleId);
        break;
    }
  }

  isAdmin() {
    let roleId = UserState.currentUserProfile.roleId;
    return roleId === "3";
  }

  async searchActivitiesForAdmin(criterias) {
    try {
      //If filterUsername !== null add criteria to search.
      if (this.filterUsername !== null) {
        criterias.owner = "org.couchdb.user:" + this.filterUsername;
      }
      let results = await ActivityService.searchActivities(criterias);
      // get holder information
      let holderInfo = await AssociatedUserService.searchAssociatedUsers(criterias);
      let data = results.docs.map((row) => {
        let holder = holderInfo.docs.find((holder) => holder._id === row.holderId);
        row.holderInfo = holder;
        return row;
      });
      results.docs = data;

      let activities = [];
      if (results !== undefined) {
        activities = results.docs;
      }
      this.setActivities(activities);
      return activities;
    } catch (error) {
      console.log("ibrd: DashboardState -> searchActivitiesForUser -> error", error);
    }
  }

  async searchActivitiesForUser(criterias) {
    try {
      let user = UserState.currentUserProfile;
      criterias.owner = user._id;

      let results = await ActivityService.searchActivities(criterias);
      console.log("log: searchActivitiesForUser -> results", results);
      // get holder information
      /*let holderInfo = await AssociatedUserService.searchAssociatedUsers(criterias);
      let data = results.docs.map((row) => {
        let holder = holderInfo.docs.find((holder) => holder._id === row.holderId);
        row.holderInfo = holder;
        return row;
      });
      results.docs = data;*/

      let activities = [];
      if (results !== undefined) {
        activities = results.docs;
      }
      this.setActivities(activities);
      return activities;
    } catch (error) {
      console.log("ibrd: DashboardState -> searchActivitiesForUser -> error", error);
    }
  }

  async searchActivitiesForNdp(criterias) {
    try {
      let user = UserState.currentUserProfile;
      criterias.beaconCountryCode = user.mailCountry;

      let results = await ActivityService.searchActivities(criterias);
      let activities = [];
      if (results !== undefined) {
        activities = results.docs;
      }
      this.setActivities(activities);
      return activities;
    } catch (error) {
      console.log("ibrd: DashboardState -> searchActivitiesForNdp -> error", error);
    }
  }

  @action setActivities(activities) {
    this.activities = activities;
  }

  // Secondary User Search
  async searchAssociatedUsers(criterias) {
    if (!UserState.currentUserProfile) {
      return null;
    }

    let roleId = UserState.currentUserProfile.roleId;

    switch (roleId) {
      case "1":
        return this.searchAssociatedUsersForNdp(criterias);
      //case "2": not support
      case "3":
      //case "4": not support
      case "5":
        return this.searchAssociatedUsersForUser(criterias);
      case "7": //maintenance provider
        return null;
      default:
        console.error("DashboardState -> searchAssociatedUsers -> not support role", roleId);
        break;
    }
  }

  async searchAssociatedUsersForUser(criterias) {
    try {
      let user = UserState.currentUserProfile;
      criterias.owner = user._id;

      let results = await AssociatedUserService.searchAssociatedUsers(criterias);
      console.log("ibrd: DashboardState: searchAssociatedUsersForUser -> results", results);
      let associatedUsers = [];
      if (results !== undefined) {
        associatedUsers = results.docs;
      }
      this.setAssociatedUsers(associatedUsers);
      return associatedUsers;
    } catch (error) {
      console.log("ibrd: DashboardState -> searchAssociatedUsersForUser -> error", error);
    }
  }

  async searchAssociatedUsersForNdp(criterias) {
    try {
      let user = UserState.currentUserProfile;
      criterias.beaconCountryCode = user.mailCountry;

      let results = await AssociatedUserService.searchAssociatedUsers(criterias);
      let associatedUsers = [];
      if (results !== undefined) {
        associatedUsers = results.docs;
      }
      this.setAssociatedUsers(associatedUsers);
      return associatedUsers;
    } catch (error) {
      console.log("ibrd: DashboardState -> searchAssociatedUsersForNdp -> error", error);
    }
  }

  @action setAssociatedUsers(associatedUsers) {
    this.associatedUsers = associatedUsers;
  }

  //Vehicle Search
  async searchVehicles(criterias) {
    if (!UserState.currentUserProfile) {
      return null;
    }

    let roleId = UserState.currentUserProfile.roleId;

    switch (roleId) {
      case "1":
        return this.searchVehiclesForNdp(criterias);
      //case "2": not support
      case "3":
      //case "4": not support
      case "5":
        return this.searchVehiclesForUser(criterias);
      case "7": //maintenance provider
        return null;
      default:
        console.error("searchVehicles -> not support roleId", roleId);
        break;
    }
  }

  async searchVehiclesForUser(criterias) {
    try {
      let user = UserState.currentUserProfile;
      criterias.owner = user._id;

      let results = await VehicleService.searchVehicles(criterias);
      console.log("log: searchVehiclesForUser -> results", results);
      let vehicles = [];
      if (results !== undefined) {
        vehicles = results.docs;
      }
      this.setVehicles(vehicles);
      return vehicles;
    } catch (error) {
      console.log("ibrd: DashboardState -> searchVehiclesForUser -> error", error);
    }
  }

  async searchVehiclesForNdp(criterias) {
    try {
      let user = UserState.currentUserProfile;
      criterias.beaconCountryCode = user.mailCountry;

      let results = await VehicleService.searchVehicles(criterias);
      let vehicles = [];
      if (results !== undefined) {
        vehicles = results.docs;
      }
      this.setVehicles(vehicles);
      return vehicles;
    } catch (error) {
      console.log("ibrd: DashboardState -> searchVehiclesForNdp -> error", error);
    }
  }

  @action setVehicles(vehicles) {
    this.vehicles = vehicles;
  }

  @action setActiveIndexs(value) {
    this.activeIndexs = value;
  }

  async getActivitiesForBeacon(beaconId) {
    try {
      let result = await ActivityService.searchActivities({ beaconId: beaconId });
      console.log("log: getActivitiesForBeacon -> result", result);
      let activities = result.docs
        .filter((activity) => {
          if (activity.duration === ACTIVITY_ONGOING) {
            return true;
          }

          let durationSpecificStartDate = new Date(activity.durationSpecificEndDate).getTime();
          let durationSpecificEndDate = new Date(activity.durationSpecificEndDate).getTime();
          let now = Date.now();
          if (durationSpecificStartDate < now && durationSpecificEndDate > now) {
            return true;
          }

          return false;
        })
        .sort((a, b) => (a.lastEditDate > b.lastEditDate ? -1 : 1));
      console.log("log: getActivitiesForBeacon -> activities", activities);
      return activities[0];
    } catch (error) {
      console.error("log: getActivitiesForBeacon -> error", error);
    }
  }

  @action setIsLoading(value) {
    console.log("log: @actionsetIsLoading -> value", value);
    this.isLoading = value;
  }

  @action setFilterUsername(value) {
    console.log("log: @actionsetFilterUsername -> value", value);
    this.filterUsername = value;
    this.searchBeacons({});
    this.searchActivities({});
  }

  async getBeaconHistory(beaconId = "beacon_212E959230FFBFF") {
    let allowFeatureByRoleId = ["4"];
    if (!allowFeatureByRoleId.includes(UserState.currentUserProfile.roleId)) return;

    let history = await BeaconService.getBeaconHistory(beaconId);
    console.log("log: GetBeaconHistory -> history", history);

    let ownerHistory = [];
    let latestOwner;
    for (let beacon of history) {
      if (latestOwner != beacon.owner) {
        ownerHistory.push(beacon);
        latestOwner = beacon.owner;
      }
    }
    // exclude current owner
    ownerHistory.shift();
    console.log(">>>>>>>>>>>>> beacon history: ", ownerHistory);

    this.setOwnerHistory(ownerHistory);
  }

  @action setOwnerHistory(value) {
    this.ownerHistory = value;
  }

  @action setBeaconHistory(values) {
    this.beaconHistory = values;

    let selectValues = [];
    for (let beacon of values) {
      let lastEditDate = new Date(beacon.lastEditDate);
      let owner = beacon.owner ? beacon.owner.replace("org.couchdb.user:", "") : "Unknown";
      
      selectValues.push({
        key: beacon.lastEditDate,
        text: owner + " - " + lastEditDate.toLocaleString(),
        value: beacon.lastEditDate,
      });
    }
    this.beaconHistorySelectValues = selectValues;
  }

  @action setBeaconHistorySelection(lastEditdate){
    for(let beacon of this.beaconHistory){
      if(beacon.lastEditDate === lastEditdate){
        this.selectedBeaconHistory = beacon;
        console.log('log ~ dashboardState ~ setBeaconHistorySelection ~ selectedBeaconHistory:', beacon);
        break;
      } 
    }
  }

  @action setBeaconHistorySelectionToNothing(){
    this.selectedBeaconHistory = null;
  }

  async getAccountOwnerHistory(beaconId = "beacon_212E959230FFBFF") {
    try {
      console.log("log ~ file: dashboardState.js:883 ~ getAccountOwnerHistory ~ beaconId:", beaconId);
      let allowFeatureByRoleId = ["3", "4"];
      if (!allowFeatureByRoleId.includes(UserState.currentUserProfile.roleId)) return;

      let history = await BeaconService.getBeaconHistory(beaconId);
      console.log("log ~ file: dashboardState.js:888 ~ getAccountOwnerHistory ~ history:", history);
      this.setBeaconHistory(history);
      let accountHistory = [];
      let latestOwner;
      if (history && history.length > 0) {
        for (let beacon of history) {
          if (latestOwner != beacon.owner) {
            latestOwner = beacon.owner;
            // get account information

            if (!beacon.owner) continue;
            let account = await UserState.getUserProfileById(beacon.owner);
            if (account) {
              account.ownerChangeDate = beacon.lastEditDate;

              accountHistory.push(account);
            }
          }
        }
        // exclude current owner
        accountHistory.shift();
      }
      console.log(">>>>>>>>>>>>> account: ", accountHistory);

      this.setAccountOwnerHistory(accountHistory);
    } catch (error) {
      console.log("log ~ file: dashboardState.js:914 ~ getAccountOwnerHistory ~ error:", error);
    }
  }

  @action setAccountOwnerHistory(value) {
    this.accountOwnerHistory = value;
  }

  // handle sort activities
  @action handleSortActivity(clickedColumn) {
    let sortActivity = this.activities;
    if (this.columnActivity !== clickedColumn) {
      sortActivity = utils.sortBy(sortActivity, clickedColumn);
      sortActivity = sortActivity.slice().reverse();
      this.columnActivity = clickedColumn;
      this.directionActivity = "descending";
    } else {
      //if the same column is clicked reverse the order.
      sortActivity = utils.sortBy(sortActivity, clickedColumn);
      this.directionActivity = this.directionActivity === "descending" ? "ascending" : "descending";
      if (this.directionActivity === "descending") {
        sortActivity = sortActivity.slice().reverse();
      }
    }
    this.setActivities(sortActivity);
  }

  // handle sort assocoated user
  @action handleSortUsers(clickedColumn) {
    let sortUsers = this.associatedUsers;
    if (this.columnUser !== clickedColumn) {
      sortUsers = utils.sortBy(sortUsers, clickedColumn);
      sortUsers = sortUsers.slice().reverse();
      this.columnUser = clickedColumn;
      this.directionUser = "descending";
    } else {
      //if the same column is clicked reverse the order.
      sortUsers = utils.sortBy(sortUsers, clickedColumn);
      this.directionUser = this.directionUser === "descending" ? "ascending" : "descending";
      if (this.directionUser === "descending") {
        sortUsers = sortUsers.slice().reverse();
      }
    }
    this.setAssociatedUsers(sortUsers);
  }

  // handle sort vehicle
  @action handleSortVehicle(clickedColumn) {
    let sortVehicle = this.vehicles;
    if (this.columnVehicle !== clickedColumn) {
      sortVehicle = utils.sortBy(sortVehicle, clickedColumn);
      sortVehicle = sortVehicle.slice().reverse();
      this.columnVehicle = clickedColumn;
      this.directionVehicle = "descending";
    } else {
      //if the same column is clicked reverse the order.
      sortVehicle = utils.sortBy(sortVehicle, clickedColumn);
      this.directionVehicle = this.directionVehicle === "descending" ? "ascending" : "descending";
      if (this.directionVehicle === "descending") {
        sortVehicle = sortVehicle.slice().reverse();
      }
    }
    this.setVehicles(sortVehicle);
  }

  @action setDisplayGroupUserNotice(value) {
    this.displayGroupUserNotice = value;
  }

  async getGroupUserNoticeContent() {
    if (!UserState.currentUserProfile) return;

    this.setDisplayGroupUserNotice(false);

    let beaconCountryCodes = [];
    let beaconTypes = [];
    console.time("dashboardState: getGroupUserNoticeContent -> result");
    let result = await BeaconService.getBeaconTypeCount(UserState.currentUserProfile._id);
    console.timeEnd("dashboardState: getGroupUserNoticeContent -> result");
    console.log("dashboardState: getGroupUserNoticeContent -> result", result);

    for (let item of result) {
      let beaconCountryCode = item.key.split(":")[1];
      beaconCountryCodes.push(beaconCountryCode);
      if (item.key.includes("EPIRB")) {
        beaconTypes.push("0");
      } else if (item.key.includes("ELT")) {
        beaconTypes.push("1");
      } else if (item.key.includes("PLB")) {
        beaconTypes.push("2");
      } else if (item.key.includes("SSAS")) {
        beaconTypes.push("3");
      }
    }
    beaconCountryCodes = beaconCountryCodes.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
    console.log("unique beaconCountryCodes", beaconCountryCodes);

    beaconTypes = beaconTypes.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
    console.log("unique beaconTypes", beaconTypes);

    let groupUserNotice = AppConfigState.getPublishedPageContentWithCurrentLanguage("GroupUserNotice");
    console.log("groupUserNotice", groupUserNotice);

    //filter by User Role
    groupUserNotice = groupUserNotice.filter((item) => item.roleIds.includes(UserState.currentUserProfile.roleId) || item.roleIds.length == 0);
    console.log("groupUserNotice -> filter roles", groupUserNotice);
    //filter by MID
    if (beaconCountryCodes.length > 0) {
      groupUserNotice = groupUserNotice.filter((item) => {
        if (item.mids.length == 0) return item;
        for (var i = 0; i < beaconCountryCodes.length; i++) {
          if (item.mids.includes(beaconCountryCodes[i])) return item;
        }
      });
    }
    console.log("groupUserNotice -> filter mids", groupUserNotice);
    //filter by Beacon Type
    if (beaconTypes.length > 0) {
      groupUserNotice = groupUserNotice.filter((item) => {
        if (item.beaconTypes.length == 0) return item;
        for (var i = 0; i < beaconTypes.length; i++) {
          if (item.beaconTypes.includes(beaconTypes[i])) return item;
        }
      });
    }
    console.log("groupUserNotice -> filter beacon types", groupUserNotice);

    let content = "";
    for (let i = 0; i < groupUserNotice.length; i++) {
      content += groupUserNotice[i].content;
    }
    return content;
  }

  findBeaconByHexInMemory(hex) {
    for (let beacon of this.allBeacons) {
      if (beacon._id === hex) {
        return beacon;
      }
    }
    return null;
  }

  async getTotalActivitiesByOwner(owner) {
    try {
      this.setTotalActivitiesByOwner(0);
      let results = await ActivityService.getTotalActivitiesByOwner(owner);
      this.setTotalActivitiesByOwner(results);
      return results;
    } catch (error) {
      console.log("ibrd: DashboardState -> getTotalActivitiesByOwner -> error", error);
    }
  }
  @action setTotalActivitiesByOwner(value) {
    this.totalActivitiesForUser = value;
  }
}

const singleton = new DashboardState(); //export class as singleton to be used through the application.
export default singleton;

function sendGtagEvent(eventName) {
  try {
    window.gtag("event", eventName, { event_category: eventName, event_label: eventName });
  } catch (error) {
    console.log("log ~ UserState ~ login ~ gtag error:", error);
  }
}
