import i18n from "i18next";
import React, { Component } from "react";
import * as Yup from "yup";
import { Form, Button, Select, Input, Header, Radio, Label, Divider, Message, Transition, Container } from "semantic-ui-react";
import ActivityState from "../../../state/activityState";
import withSemanticUIFormik from "../../../utils/formHelper";
import "../registerActivity.css";
import { countryCode } from "../../../utils/beaconDecode/countryCode";
import RouteState from "../../../state/routeState";
import DashboardState from "../../../state/dashboardState";
import { FormErrorDisplay } from "../../../components/formErrorDisplay";

const countryOptions = countryCode
  .sort((a, b) => a.country.localeCompare(b.country))
  .map((country) => {
    return {
      text: country.code + " - " + country.country,
      value: country.code,
      key: country.code,
    };
  });

const unspecifiedDurationOptions = [{ key: "daily", text: i18n.t("daily"), value: "daily" }, { key: "weekly", text: i18n.t("weekly"), value: "weekly" }, { key: "monthly", text: i18n.t("monthly"), value: "monthly" }];

const dayOptions = [];
for (let i = 0; i < 31; i++) {
  dayOptions[i] = {};
  dayOptions[i].key = i + 1;
  dayOptions[i].value = i + 1;
  dayOptions[i].text = i + 1;
}

let monthOptions = [
  { key: "January", text: i18n.t("january"), value: 0 },
  { key: "February", text: i18n.t("february"), value: 1 },
  { key: "March", text: i18n.t("march"), value: 2 },
  { key: "April", text: i18n.t("april"), value: 3 },
  { key: "May", text: i18n.t("may"), value: 4 },
  { key: "June", text: i18n.t("june"), value: 5 },
  { key: "July", text: i18n.t("july"), value: 6 },
  { key: "August", text: i18n.t("august"), value: 7 },
  { key: "September", text: i18n.t("september"), value: 8 },
  { key: "October", text: i18n.t("october"), value: 9 },
  { key: "November", text: i18n.t("november"), value: 10 },
  { key: "December", text: i18n.t("december"), value: 11 },
];

const yearOptions = [];
let startYear = new Date().getFullYear() - 5;
for (let i = 0; i <= 20; i++) {
  yearOptions[i] = {};
  yearOptions[i].key = startYear + i;
  yearOptions[i].value = startYear + i;
  yearOptions[i].text = startYear + i;
}

class InnerForm extends Component {
  state = {
    requestEdit: false,
    beaconOptions: [],
    beaconInfos: [],
  };

  componentDidMount = () => {
    let beacons = DashboardState.beacons;
    let beaconCut = beacons.slice(0, 20);
    monthOptions = [
      { key: "January", text: i18n.t("january"), value: 0 },
      { key: "February", text: i18n.t("february"), value: 1 },
      { key: "March", text: i18n.t("march"), value: 2 },
      { key: "April", text: i18n.t("april"), value: 3 },
      { key: "May", text: i18n.t("may"), value: 4 },
      { key: "June", text: i18n.t("june"), value: 5 },
      { key: "July", text: i18n.t("july"), value: 6 },
      { key: "August", text: i18n.t("august"), value: 7 },
      { key: "September", text: i18n.t("september"), value: 8 },
      { key: "October", text: i18n.t("october"), value: 9 },
      { key: "November", text: i18n.t("november"), value: 10 },
      { key: "December", text: i18n.t("december"), value: 11 },
    ];

    // const beaconOptions = beaconCut.map((row, index) => {
    //   return {
    //     text: row.hexId + " - " + row.beaconType,
    //     value: row._id,
    //     key: row._id,
    //   };
    // });

    // work for edit mode, if add a new activity will show all beacons because cannot define with is owner
    const { activity } = this.props;
    let _beaconOptions = ActivityState.isAdminEditing ? beaconCut.filter((beacon) => beacon.owner === activity.owner) : beaconCut;
    let beaconOptions = _beaconOptions.map((row, index) => {
      return {
        text: row.hexId + " - " + row.beaconType,
        value: row._id,
        key: row._id,
      };
    });
    console.log("---> beacon options: ", _beaconOptions, ActivityState.isAdminEditing);

    this.setState({
      beaconOptions: beaconOptions,
      beaconInfos: beacons,
    });
  };

  onRequestEdit = () => {
    this.setState({
      requestEdit: true,
    });
  };

  onClose = () => {
    // if (this.props.onClose) {
    // 	this.props.onClose()
    // } else {
    // 	//
    // }
    ActivityState.setPreviousStep();
  };

  saveAndClose = () => {
    if (ActivityState.isEditActivity) {
      ActivityState.setIsSaving(true);
      ActivityState.updateActivityFields(this.props.values);
      ActivityState.saveActivity().then(() => {
        ActivityState.setIsSaving(false);
        RouteState.setRoute("/dashboard/activities");
      });
    }
  };

  onSelectBeaconChange = (e, data) => {
    // set selected when changed
    if (data && data.name) {
      this.props.setFieldValue(data.name, data.value);
    }

    // set selecting vehicle info
    var selecting = this.state.beaconInfos.find((row) => row._id === data.value);

    // this.props.values.beaconType = selecting.beaconType
    // this.props.values.beaconMailCountry = selecting.mailCountry
    // this.props.values.beaconCountryCode = selecting.beaconCountryCode;
  };

  render() {
    const { values, touched, errors, status, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, isValid, onClose, onAskToCancle } = this.props;
    console.log("log: ActivityDetailform -> render -> values", values);

    const { requestEdit } = this.state;

    let activityNameHasError = touched.activityName && errors.activityName && errors.activityName.length > 0;
    let primaryActivityHasError = touched.primaryActivity && errors.primaryActivity && errors.primaryActivity.length > 0;
    let durationHasError = touched.duration && errors.duration && errors.duration.length > 0;
    let durationUnspecifiedHasError = touched.durationUnspecified && errors.durationUnspecified && errors.durationUnspecified.length > 0;
    let durationSpecificStartDayHasError = touched.durationSpecificStartDay && errors.durationSpecificStartDay && errors.durationSpecificStartDay.length > 0;
    let durationSpecificStartMonthHasError = touched.durationSpecificStartMonth && errors.durationSpecificStartMonth && errors.durationSpecificStartMonth.length > 0;
    let durationSpecificStartYearHasError = touched.durationSpecificStartYear && errors.durationSpecificStartYear && errors.durationSpecificStartYear.length > 0;
    let durationSpecificEndDayHasError = touched.durationSpecificEndDay && errors.durationSpecificEndDay && errors.durationSpecificEndDay.length > 0;
    let durationSpecificEndMonthHasError = touched.durationSpecificEndMonth && errors.durationSpecificEndMonth && errors.durationSpecificEndMonth.length > 0;
    let durationSpecificEndYearHasError = touched.durationSpecificEndYear && errors.durationSpecificEndYear && errors.durationSpecificEndYear.length > 0;

    let dateNotCorrectError = errors.dateNotCorrect && errors.dateNotCorrect.length > 0;
    let requiredDateError = errors.requiredDate && errors.requiredDate.length > 0;

    // scroll to error
    if (isSubmitting && Object.keys(errors).length > 0) {
      var errorBox = document.getElementById("scrollToError");
      errorBox.scrollIntoView();
    }

    return (
      <Form id="mainForm" className="activityDetail-form animated fadeIn faster" error={activityNameHasError || primaryActivityHasError || status}>
        <Header as="h2" icon textAlign="center">
          <Header.Content>{i18n.t("select-a-beacon-to-add-to-the-activity")}</Header.Content>
        </Header>

        <Form.Group>
          <Form.Field width="6" className="select-beacon-label">
            {i18n.t("select-a-beacon")}
          </Form.Field>
          <Form.Select width="8" name="selectBeacon" options={this.state.beaconOptions} placeholder="Select" onChange={this.onSelectBeaconChange} onBlur={handleBlur} value={values.selectBeacon} disabled={!ActivityState.isEditable()} className="editing-select" search />
          {/* <Input name='beaconType' value={values.beaconType} type='hidden' />
					<Input name='beaconMailCountry' value={values.beaconMailCountry} type='hidden' />
					<Input name='beaconCountryCode' value={values.beaconCountryCode} type='hidden' /> */}
        </Form.Group>

        <Header as="h2" textAlign="center">
          <Header.Content>{i18n.t("add-itinerary-and-activity-details")}</Header.Content>
        </Header>
        <div id="scrollToError" />
        <FormErrorDisplay errors={errors} touched={touched} status={status} />
        <div className="required-field-notation">{i18n.t("required-fields")}</div>

        <Header size="medium">{i18n.t("activity")}</Header>
        <Form.Group widths="equal">
          <Form.Field>
            <label>
              {i18n.t("activity-name")} <span style={{ color: "red" }}>*</span>
            </label>
            <Form.Input fluid name="activityName" value={values.activityName} onChange={handleChange} onBlur={handleBlur} error={activityNameHasError} disabled={!ActivityState.isEditable()} className="editing-input" />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label>
              {i18n.t("primary-activity")} <span style={{ color: "red" }}>*</span>
            </label>
            <Form.Input fluid name="primaryActivity" value={values.primaryActivity} onChange={handleChange} onBlur={handleBlur} error={primaryActivityHasError} disabled={!ActivityState.isEditable()} className="editing-input" />
          </Form.Field>
          <Form.Input fluid label={i18n.t("secondary-activity")} name="secondaryActivity" value={values.secondaryActivity} onChange={handleChange} onBlur={handleBlur} disabled={!ActivityState.isEditable()} className="editing-input" />
        </Form.Group>
        <Divider />
        <Form.Group widths="equal">
          <Form.Input fluid label={i18n.t("departure-location-place")} name="departureLocation" value={values.departureLocation} onChange={handleChange} onBlur={handleBlur} disabled={!ActivityState.isEditable()} className="editing-input" />
          <Form.Input fluid label={i18n.t("nearest-town")} name="departureNearestTown" value={values.departureNearestTown} onChange={handleChange} onBlur={handleBlur} disabled={!ActivityState.isEditable()} className="editing-input" />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input fluid label={i18n.t("state-province")} name="departureState" value={values.departureState} onChange={handleChange} onBlur={handleBlur} disabled={!ActivityState.isEditable()} className="editing-input" />
          <Form.Select search fluid placeholder="Select" label={i18n.t("country")} name="departureCountry" value={values.departureCountry} options={countryOptions} onChange={handleChange} onBlur={handleBlur} disabled={!ActivityState.isEditable()} className="editing-select" />
        </Form.Group>
        <Divider />
        <Form.Group widths="equal">
          <Form.Input fluid label={i18n.t("arrival-location-place")} name="arrivalLocation" value={values.arrivalLocation} onChange={handleChange} onBlur={handleBlur} disabled={!ActivityState.isEditable()} className="editing-input" />
          <Form.Input fluid label={i18n.t("nearest-town")} name="arrivalNearestTown" value={values.arrivalNearestTown} onChange={handleChange} onBlur={handleBlur} disabled={!ActivityState.isEditable()} className="editing-input" />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input fluid label={i18n.t("state-province")} name="arrivalState" value={values.arrivalState} onChange={handleChange} onBlur={handleBlur} disabled={!ActivityState.isEditable()} className="editing-input" />
          <Form.Select search fluid placeholder={i18n.t("select")} label="Country" name="arrivalCountry" value={values.arrivalCountry} options={countryOptions} onChange={handleChange} onBlur={handleBlur} disabled={!ActivityState.isEditable()} className="editing-select" />
        </Form.Group>

        <Header size="medium">
          Duration <span style={{ color: "red" }}>*</span>
        </Header>

        <Form.Radio label={i18n.t("ongoing")} name="duration" value="ongoing" checked={values.duration === "ongoing"} onChange={handleChange} onBlur={handleBlur} error={durationHasError} disabled={!ActivityState.isEditable()} />

        <Form.Radio label={i18n.t("suspended")} name="duration" value="suspended" checked={values.duration === "suspended"} onChange={handleChange} onBlur={handleBlur} error={durationHasError} disabled={!ActivityState.isEditable()} />

        <Form.Radio label={i18n.t("specific-dates")} name="duration" value="specific" checked={values.duration === "specific"} onChange={handleChange} onBlur={handleBlur} error={durationHasError} disabled={!ActivityState.isEditable()} />

        {values.duration === "specific" ? (
          <>
            <div className="animated fadeIn faster">
              <label>{i18n.t("start-date")}</label>
              <Form.Group widths="equal">
                <Form.Select className="auto-min-width" placeholder={i18n.t("day")} name="durationSpecificStartDay" value={values.durationSpecificStartDay} options={dayOptions} onChange={handleChange} onBlur={handleBlur} error={dateNotCorrectError || requiredDateError} disabled={!ActivityState.isEditable()} className="editing-select" />
                <Form.Select
                  className="auto-min-width"
                  placeholder={i18n.t("month")}
                  name="durationSpecificStartMonth"
                  value={values.durationSpecificStartMonth}
                  options={monthOptions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={dateNotCorrectError || requiredDateError}
                  disabled={!ActivityState.isEditable()}
                  className="editing-select"
                />
                <Form.Select
                  className="auto-min-width"
                  placeholder={i18n.t("year")}
                  name="durationSpecificStartYear"
                  value={values.durationSpecificStartYear}
                  options={yearOptions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={dateNotCorrectError || requiredDateError}
                  disabled={!ActivityState.isEditable()}
                  className="editing-select"
                />
              </Form.Group>
              <label>{i18n.t("end-date")}</label>
              <Form.Group widths="equal">
                <Form.Select className="auto-min-width" placeholder={i18n.t("day")} name="durationSpecificEndDay" value={values.durationSpecificEndDay} options={dayOptions} onChange={handleChange} onBlur={handleBlur} error={dateNotCorrectError || requiredDateError} disabled={!ActivityState.isEditable()} />
                <Form.Select className="auto-min-width" placeholder={i18n.t("month")} name="durationSpecificEndMonth" value={values.durationSpecificEndMonth} options={monthOptions} onChange={handleChange} onBlur={handleBlur} error={dateNotCorrectError || requiredDateError} disabled={!ActivityState.isEditable()} />
                <Form.Select className="auto-min-width" placeholder={i18n.t("year")} name="durationSpecificEndYear" value={values.durationSpecificEndYear} options={yearOptions} onChange={handleChange} onBlur={handleBlur} error={dateNotCorrectError || requiredDateError} disabled={!ActivityState.isEditable()} />
              </Form.Group>
            </div>
          </>
        ) : (
          ""
        )}

        <div className="activityDetail-submitContainer">
          {/* <Button className="activityDetail-submitButton" onClick={onAskToCancle}>
            Back
          </Button> */}
          <Button className="activityDetail-submitButton" type="submit" primary onClick={handleSubmit}>
            {i18n.t("next")}
          </Button>
          {/* {
            ActivityState.isEditActivity ?
            <Button className="activityDetail-submitButton" primary onClick={this.saveAndClose} loading={ActivityState.isSaving} >
              Save & Close
            </Button>
            : ''
          } */}
        </div>
      </Form>
    );
  }
}

const ActivityDetailForm = withSemanticUIFormik({
  mapPropsToValues: ({ activity }) => {
    console.log("mapPropsToValues: activity", activity);
    if (activity) {
      let { startDay, startMonth, startYear } = "";
      let { endDay, endMonth, endYear } = "";
      if (activity.duration === "specific") {
        if (activity.durationSpecificStartDate) {
          var durationStartDate = new Date(activity.durationSpecificStartDate);
          startDay = durationStartDate.getDate();
          startMonth = durationStartDate.getMonth();
          startYear = durationStartDate.getFullYear();
        }
        if (activity.durationSpecificEndDate) {
          var durationEndDate = new Date(activity.durationSpecificEndDate);
          endDay = durationEndDate.getDate();
          endMonth = durationEndDate.getMonth();
          endYear = durationEndDate.getFullYear();
        }
      }

      return {
        activityName: activity.activityName || "",
        primaryActivity: activity.primaryActivity || "",
        secondaryActivity: activity.secondaryActivity || "",
        departureLocation: activity.departureLocation || "",
        departureNearestTown: activity.departureNearestTown || "",
        departureState: activity.departureState || "",
        departureCountry: activity.departureCountry || "",
        arrivalLocation: activity.arrivalLocation || "",
        arrivalNearestTown: activity.arrivalNearestTown || "",
        arrivalState: activity.arrivalState || "",
        arrivalCountry: activity.arrivalCountry || "",
        duration: activity.duration || "",
        durationUnspecified: activity.durationUnspecified || "",
        durationSpecificStartDate: activity.durationSpecificStartDate || "",
        durationSpecificEndDate: activity.durationSpecificEndDate || "",
        durationSpecificStartDay: startDay,
        durationSpecificStartMonth: startMonth,
        durationSpecificStartYear: startYear,
        durationSpecificEndDay: endDay,
        durationSpecificEndMonth: endMonth,
        durationSpecificEndYear: endYear,
        selectBeacon: activity.selectBeacon || "",
      };
    } else {
      return {
        activityName: "",
        primaryActivity: "",
        secondaryActivity: "",
        departureLocation: "",
        departureNearestTown: "",
        departureState: "",
        departureCountry: "",
        arrivalLocation: "",
        arrivalNearestTown: "",
        arrivalState: "",
        arrivalCountry: "",
        duration: "",
        durationUnspecified: "",
        durationSpecificStartDate: "",
        durationSpecificEndDate: "",
        durationSpecificStartDay: "",
        durationSpecificStartMonth: "",
        durationSpecificStartYear: "",
        durationSpecificEndDay: "",
        durationSpecificEndMonth: "",
        durationSpecificEndYear: "",
      };
    }
  },

  validationSchema: Yup.object().shape({
    activityName: Yup.string().required(i18n.t("activity-name-is-required")),
    primaryActivity: Yup.string().required(i18n.t("primary-activity-is-required")),
    duration: Yup.string().required(i18n.t("duration-is-required")),
    durationUnspecified: Yup.string().when("duration", {
      is: (val) => val === "unspecified",
      then: Yup.string().required(i18n.t("unspecified-duration-is-required")),
    }),
    // durationSpecificStartDay: Yup.string().when("duration", {
    //                                         is: val => (val === 'specific'),
    //                                         then: Yup.string().required('specific duration is required')
    //                                       }),
    // durationSpecificStartMonth: Yup.string().when("duration", {
    //                                         is: val => (val === 'specific'),
    //                                         then: Yup.string().required('specific duration is required')
    //                                       }),
    // durationSpecificStartYear: Yup.string().when("duration", {
    //                                         is: val => (val === 'specific'),
    //                                         then: Yup.string().required('specific duration is required')
    //                                       }),
    // durationSpecificEndDay: Yup.string().when("duration", {
    //                                         is: val => (val === 'specific'),
    //                                         then: Yup.string().required('specific duration is required')
    //                                       }),
    // durationSpecificEndMonth: Yup.string().when("duration", {
    //                                         is: val => (val === 'specific'),
    //                                         then: Yup.string().required('specific duration is required')
    //                                       }),
    // durationSpecificEndYear: Yup.string().when("duration", {
    //                                         is: val => (val === 'specific'),
    //                                         then: Yup.string().required('specific duration is required')
    //                                       }),
  }),

  handleSubmit: (values, { setSubmitting, isValid, setStatus, setErrors }) => {
    console.log("ActivityDetailForm: values", values);
    let { startDate, endDate } = "";

    if (values.duration === "specific") {
      // if(values.durationSpecificStartYear && values.durationSpecificStartMonth && values.durationSpecificStartDay) {
      //   startDate = new Date(values.durationSpecificStartYear, values.durationSpecificStartMonth, values.durationSpecificStartDay);
      // }
      // if(values.durationSpecificEndYear && values.durationSpecificEndMonth && values.durationSpecificEndDay) {
      //   endDate = new Date(values.durationSpecificEndYear, values.durationSpecificEndMonth, values.durationSpecificEndDay);
      // }
      values.durationUnspecified = "";
      startDate = new Date(values.durationSpecificStartMonth + 1 + "/" + values.durationSpecificStartDay + "/" + values.durationSpecificStartYear).getTime();
      endDate = new Date(values.durationSpecificEndMonth + 1 + "/" + values.durationSpecificEndDay + "/" + values.durationSpecificEndYear).getTime();
    } else {
      values.durationSpecificStartDay = "";
      values.durationSpecificStartMonth = "";
      values.durationSpecificStartYear = "";
      values.durationSpecificEndDay = "";
      values.durationSpecificEndMonth = "";
      values.durationSpecificEndYear = "";
    }
    values.durationSpecificStartDate = startDate;
    values.durationSpecificEndDate = endDate;

    // Validate: strat date is before end date
    if (values.duration === "specific") {
      let dateStart = new Date(values.durationSpecificStartMonth + 1 + "/" + values.durationSpecificStartDay + "/" + values.durationSpecificStartYear);
      let dateEnd = new Date(values.durationSpecificEndMonth + 1 + "/" + values.durationSpecificEndDay + "/" + values.durationSpecificEndYear);

      // compare date
      if (dateStart > dateEnd) {
        setStatus(i18n.t("start-date-must-be-before-end-date"));
        setErrors({
          dateNotCorrect: i18n.t("start-date-must-be-before-end-date"),
        });

        let target = document.getElementsByClassName("error message");
        target[0].scrollIntoView();
        return;
      } else if (!values.durationSpecificStartDay || values.durationSpecificStartMonth < 0 || !values.durationSpecificStartYear || !values.durationSpecificEndDay || values.durationSpecificEndMonth < 0 || !values.durationSpecificEndYear) {
        // date is required
        setStatus(i18n.t("specific-duration-is-required"));
        setErrors({
          requiredDate: i18n.t("specific-duration-is-required"),
        });
        return;
      }
    }
    values.beaconId = values.selectBeacon;

    ActivityState.updateActivityFields(values);
    ActivityState.setActivityDetailNextStep();
  },
})(InnerForm);

export default ActivityDetailForm;
