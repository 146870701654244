import React, { Component } from 'react';
import { Container, Icon, Step, Divider } from 'semantic-ui-react'
import RegistrationState from '../../../state/registrationState';

import OwnerEmergencyContactForm from './ownerEmergencyContactForm';
import OwnerDetailForm from './ownerDetailForm';
import { observer } from 'mobx-react';

@observer
export default class BeaconRegistrationStep extends Component {

	onSelectStep = (event, data) => {
    	//console.log("Home: BeaconRegistrationStep -> onSelectStep -> data", data);
		//RegistrationState.setOwnerStep(data.stepnumber)
	}

	render() {
		let {beacon, onClose} = this.props;
		let currentStep = RegistrationState.ownerCurrentStep;
		return (
			<Container className='registerBeacon-subContainer animated fadeIn fast'>
				{/* <Divider /> */}
				<BeaconRegistrationSteps currentStep={currentStep} onSelectStep={this.onSelectStep} />
				<CurrentOwnerStep currentStep={currentStep} decodedBeacon={beacon} onClose={onClose} />
			</Container>
		)
	}
}

const BeaconRegistrationSteps = ({ currentStep, onSelectStep }) => (
	<Step.Group widths={3}>
		<Step active={currentStep === 0} stepnumber={0} >
			<Step.Content>
				<Step.Title>Owner Details</Step.Title>
			</Step.Content>
			<StepComplete currentStep={currentStep} stepnumber={0} />
		</Step>

		<Step active={currentStep === 1} stepnumber={1} >
			<Step.Content>
				<Step.Title>Emergency Contacts</Step.Title>
			</Step.Content>
			<StepComplete currentStep={currentStep} stepnumber={1} />
		</Step>

	</Step.Group>
)

const CurrentOwnerStep = ({ currentStep, decodedBeacon, onClose }) => {
	switch (currentStep) {
		case 0:
			return <OwnerDetailForm decodedBeacon={decodedBeacon} onClose={onClose} />
			break;
		case 1:
			return <OwnerEmergencyContactForm decodedBeacon={decodedBeacon} onClose={onClose} />
			break;
		default:
			break;
	}
}

const StepComplete = ({ currentStep, stepnumber }) => {
  if (currentStep > stepnumber && RegistrationState.isNewRegisterBeacon) {
    return <Icon name="check" color="green" className="registerBeacon-stepComplete" />;
  } else {
    return null;
  }
};


