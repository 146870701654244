import { action, observable, computed, toJS } from "mobx";
import * as ActivityService from "../services/activityService";
import * as AssociatedUserService from "../services/associatedUserService";
import * as VehicleService from "../services/vehicleService";
import UserState from "./userState";
import AssociatedUserState from "./associatedUserState";
import VehicleState from "./vehicleState";
import RouteState from "./routeState";
import DashboardState from "./dashboardState";

class ActivityState {
  activityFields = {};
  @observable currentStep = 0;
  @observable activityDetailCurrentStep = 0;
  @observable beaconCurrentStep = 0;
  @observable users = [];
  @observable isAnotherContact = false;
  @observable isSaving = false;
  @observable isEditActivity = false;
  @observable activeTab = 0;
  @observable isHideAskToCancel = false;
  @observable isAdminEditing = false;
  @observable showAdminActivityView = false;


  getCurrentStep() {
    return this.currentStep;
  }

  async getActivityByBeaconId(filter) {
    try {
      let activities = await ActivityService.searchActivities(filter);
      //let holderInfo = await AssociatedUserService.searchAssociatedUsers(filter);
      //let vehicleInfo = await VehicleService.searchVehicles(filter);
      /*let data = activities.docs.map((row) => {
        let holder = holderInfo.docs.find((holder) => holder._id === row.holderId);
        row.holderInfo = holder;
        let vehicle = vehicleInfo.docs.find((vehicle) => vehicle._id === row.vehicleId);
        row.vehicleInfo = vehicle;
        return row;
      });*/
     
      return activities;
    } catch (error) {
      console.error("ActivityState: getActivityByBeaconId -> error: ", error);
    }
  }

  @action setAdminActivityView(value) {
    this.showAdminActivityView = value;
  }

  @action setIsAdminEditing(value) {
    this.isAdminEditing = value;
  }

  @action setHideAskToCancel(value) {
    this.isHideAskToCancel = value;
  }

  @action setActiveTab(value) {
    this.activeTab = value;
  }

  @action setIsEditActivity(value) {
    this.isEditActivity = value;
  }

  @action setIsSaving(value) {
    this.isSaving = value;
  }

  @action setCurrentStep(step) {
    this.currentStep = step;
    window.scrollTo(0, 0);
  }
  @action setNextStep() {
    this.currentStep += 1;
    window.scrollTo(0, 0);
  }
  @action setPreviousStep() {
    if (this.currentStep == 0) {
      RouteState.setRoute("/dashboard/activities");
    } else {
      this.currentStep -= 1;
    }
    window.scrollTo(0, 0);
  }

  @action resetSteps() {
    this.currentStep = 0;
    this.activityDetailCurrentStep = 0;
    this.beaconCurrentStep = 0;
    AssociatedUserState.setCurrentStep(0);
    VehicleState.setCurrentStep(0);
    window.scrollTo(0, 0);
  }

  @action resetData() {
    this.activityFields = {};
    this.isAnotherContact = false;
  }

  @action setActivityDetailCurrentStep(step) {
    this.activityDetailCurrentStep = step;
    window.scrollTo(0, 0);
  }
  @action setActivityDetailNextStep() {
    this.setNextStep();
    window.scrollTo(0, 0);
  }
  @action setActivityDetailPreviousStep() {
    this.activityDetailCurrentStep -= 1;
    window.scrollTo(0, 0);
  }

  @action setUserCurrentStep(step) {
    this.setNextStep();
  }
  @action setUserNextStep() {
    if (AssociatedUserState.getCurrentStep() === 1) {
      this.setNextStep();
    } else {
      AssociatedUserState.setNextStep();
    }
  }
  @action setUserPreviousStep() {
    if (AssociatedUserState.getCurrentStep() === 0) {
      this.setPreviousStep();
    } else {
      AssociatedUserState.setPreviousStep();
    }
  }

  @action setBeaconCurrentStep(step) {
    this.beaconCurrentStep = step;
    window.scrollTo(0, 0);
  }
  @action setBeaconNextStep() {
    if (this.beaconCurrentStep === 0) {
      this.setNextStep();
    } else {
      this.beaconCurrentStep += 1;
    }
    window.scrollTo(0, 0);
  }
  @action setBeaconPreviousStep() {
    if (this.beaconCurrentStep === 0) {
      this.setPreviousStep();
    } else {
      this.beaconCurrentStep -= 1;
    }
    window.scrollTo(0, 0);
  }

  @action setVehicleCurrentStep(step) {
    VehicleState.setCurrentStep(step);
  }
  @action setVehicleNextStep() {
    if (VehicleState.getCurrentStep() === 1) {
      this.setNextStep();
    } else {
      VehicleState.setNextStep();
    }
  }
  @action setVehiclePreviousStep() {
    if (VehicleState.getCurrentStep() === 0) {
      this.setPreviousStep();
    } else {
      VehicleState.setPreviousStep();
    }
  }

  @action updateActivityFields(values) {
    this.activityFields = { ...this.activityFields, ...values };
    console.log("log: ActivityState -> @action updateActivityFields -> this.activityFields", this.activityFields);
  }

  @action setActivityFields(activityFields) {
    this.activityFields = activityFields;
  }

  isAnEltOrAnEpirbAssociated(activity) {
    if(activity){
      this.activityFields = activity;
    }
    console.log("log: ActivityState -> isAnEltOrAnEpirbAssociated -> this.activityFields", this.activityFields);
    if (this.activityFields.selectBeacon) {
      let beacon = DashboardState.findBeaconByHexInMemory(this.activityFields.selectBeacon);
      if (!beacon) return false;
      console.log("log: ActivityState -> isAnEltOrAnEpirbAssociated -> beacon", beacon);

      if (beacon.beaconType.includes("EPIRB") || beacon.beaconType.includes("ELT")) {
        return true;
      }

      return false;
    }
  }

  isAnEltAssociated(activity) {
    if(activity){
      this.activityFields = activity;
    }
    console.log("log: ActivityState -> isAnEltOrAnEpirbAssociated -> this.activityFields", this.activityFields);
    if (this.activityFields.selectBeacon) {
      let beacon = DashboardState.findBeaconByHexInMemory(this.activityFields.selectBeacon);
      if (!beacon) return false;
      //console.log("log: ActivityState -> isAnEltOrAnEpirbAssociated -> beacon", beacon);

      if (beacon.beaconType.includes("ELT")) {
        return true;
      }

      return false;
    }
  }

  isAnEpirbAssociated(activity) {
     if(activity){
      this.activityFields = activity;
    }
    console.log("log: ActivityState -> isAnEltOrAnEpirbAssociated -> this.activityFields", this.activityFields);

    if (this.activityFields.selectBeacon) {
      let beacon = DashboardState.findBeaconByHexInMemory(this.activityFields.selectBeacon);
      if (!beacon) return false;
      //console.log("log: ActivityState -> isAnEltOrAnEpirbAssociated -> beacon", beacon);

      if (beacon.beaconType.includes("EPIRB")) {
        return true;
      }

      return false;
    }
  }

  getAssociatedBeacon(activity) {
    if(activity){
      return DashboardState.findBeaconByHexInMemory(activity.selectBeacon);
    }

    if (this.activityFields.selectBeacon) {
      return DashboardState.findBeaconByHexInMemory(this.activityFields.selectBeacon);
    }
  }

  getActivityFields() {
    return this.activityFields;
  }

  getOwner() {
    let ownerId;
    let roleId = UserState.currentUserProfile.roleId;
    switch (roleId) {
      case "5":
        ownerId = UserState.currentUserProfile._id;
        break;
      case "1":
        if (this.activityFields.owner) {
          ownerId = this.activityFields.owner;
        } else {
          ownerId = UserState.currentUserProfile._id;
        }
        break;

      default:
        ownerId = UserState.currentUserProfile._id;
        break;
    }
    return ownerId;
  }

  async saveActivity() {
    this.setIsSaving(true);
    try {
      let ownerId = this.getOwner();
      let associatedUserId = await AssociatedUserState.saveAssociatedUser(this.activityFields, ownerId, this.activityFields.beaconCountryCode);
      this.activityFields.holderId = associatedUserId;
      if (!this.isAnEltOrAnEpirbAssociated()) {
        let vehicleId = await VehicleState.saveVehicle(this.activityFields, ownerId, this.activityFields.beaconCountryCode);
        this.activityFields.vehicleId = vehicleId;
        console.log("log: ActivityState -> saveActivity -> this.activityFields", this.activityFields);
      }
      if(this.activityFields.selectBeacon){
        this.activityFields.beaconId = this.activityFields.selectBeacon;
      }
      let result = await ActivityService.saveActivity(this.activityFields, ownerId);
      console.log("ActivityState:saveActivity - result", result);

      this.activityFields = {};
      this.setIsSaving(false);
      return result.id;
    } catch (error) {
      console.error("ActivityState:saveActivity - error", error);
      this.setIsSaving(false);
    }
  }

  async getActivity(id) {
    try {
      let result = await ActivityService.getDocumentById(id);
      // let result = await ActivityService.getDocumentByTypeAndId("tempActivity", id);
      return result;
    } catch (error) {
      console.error("ActivityState:getActivity - error", error);
    }
  }

  async getBeacon(id) {
    try {
      let result = await ActivityService.getDocumentById(id);
      // let result = await ActivityService.getDocumentByTypeAndId("beacon", id);
      return result;
    } catch (error) {
      console.error("ActivityState:getBeacon - error", error);
    }
  }

  isEditable() {
    if (!UserState.currentUserProfile) return false;
    let notAllowEditByRoleId = ["4"];
    let canEdit = notAllowEditByRoleId.includes(UserState.currentUserProfile.roleId) ? false : true;
    return canEdit;
  }
}

const singleton = new ActivityState(); //export class as singleton to be used through the application.
export default singleton;
