import { action, observable, computed, toJS } from "mobx";
import UserState from './userState';
import RouteState from './routeState';
import * as UserService from "../services/userServices";


class DevState {
  enableDebug = false;
  autoLogin = false;
  redirectTo = "/dashboard"

  async initDevTools() {
    this.createNdpDevUser();
    if (!UserState.currentUser && this.autoLogin) {
      await this.autoLogDevUser();
    }
  }

  async autoLogDevUser() {
    try {
      let result = await UserState.login("devUser", "devUserPassword");
      console.warn("autoLogDevUser is on: DevState -> autoLogDevUser -> result", result);
      if(this.redirectTo){
        RouteState.setRoute(this.redirectTo)
      }

    } catch (error) {
			if(error){
				//await this.createDevUser();
				//this.autoLogDevUser();
			}
      console.log("log: DevState -> autoLogDevUser -> error", error);
    }
  }

	async createDevUser(){
    console.log('log: DevState -> createDevUser -> createDevUser');
		try {
			let result = await UserService.signUp("devUser", "devUserPassword", {meta:"noMeta", roles:  ["admin","member"]})      
			let profileResult = await UserService.saveUserProfile(devUserProfile);      
			return profileResult;
		} catch (error) {
      console.log('log: DevState -> createDevUser -> error', error);
			throw error;
		}		
	}

  async createNdpDevUser(){
    console.log('log: DevState -> createNdpDevUser -> createDevUser');
		try {
			//let result = await UserService.signUp("ndpDevUser", "ndpDevUserPassword", {meta:"noMeta"})      
			let profileResult = await UserService.saveUserProfile(ndpDevUserProfile);      
			return profileResult;
		} catch (error) {
      console.log('log: DevState -> createNdpDevUser -> error', error);
			throw error;
		}		
	}

}

const singleton = new DevState(); //export class as singleton to be used through the application.
export default singleton;

const devUserProfile = {
	"_id": "org.couchdb.user:devUser",  
  "roleId": "5",
  "ownerName": "Hanhyeim",
  "emailAddress": "snow1410@naver.com",
  "challengeQuestion": "What is the name of your favorite movie?",
  "challengeResponse": "Black",
  "type": "user",
}

const ndpDevUserProfile = {
	"_id": "org.couchdb.user:ndpDevUser",  
  "roleId": "1",
  "ownerName": "Hanhyeim",
  "mailCountry":"205",
  "emailAddress": "snow1410@naver.com",
  "challengeQuestion": "What is the name of your favorite movie?",
  "challengeResponse": "Black",
  "type": "user",
}