import React, { Component } from 'react';
import Footer from '../../components/footer';
import TopMenu from '../../components/topMenu';
import { Container, Modal, Header, Button } from 'semantic-ui-react';
import VehicleState from '../../state/vehicleState';
import VehicleSteps from './vehicle/vehicleSteps';
import RouteState from '../../state/routeState';
import { observer } from 'mobx-react';
import './registerVehicle.css';
import ActivityState from '../../state/activityState'
import i18n from "i18next";
export default class RegisterVehiclePage extends Component {

    render() {
		return (
			<div className="registerBeacon-page">
				<TopMenu />
				<RegisterVehicle />
				<Footer />
			</div>
		)
	}
}

@observer
class RegisterVehicle extends Component {

	state = {
		showModal: false
	}

	onAskToCancel = (e) => {
		this.setState({
			showModal: true
		});
		if(e){
			e.stopPropagation(); //This prevent the tab itself to register the click.
		}
	}

	onModalCancel = () => {
		this.setState({
		  showModal: false
		});
	}

	onModalAccept = () => {
		this.setState({
			showModal: false
		})
		VehicleState.resetSteps();
		VehicleState.resetData();
		ActivityState.setIsEditActivity(false);
		RouteState.setRoute('/dashboard/activities');
		ActivityState.setActiveTab(2);
	}

	render() {
		const {showModal} = this.state
		let vehicle = VehicleState.getVehicleFields();

		let display = VehicleState.currentStep == 3 ? 'none' : 'block'

		return (
			<div className="registerUser-page">
				<Container className='animated fadeIn faster cancel-btn-margin noprint'>
					<a style={{display: display}} className='cancel-add-edit' onClick={this.onAskToCancel}>{i18n.t('cancel-and-return-to-dashboard')}</a>
				</Container>

				<Container className='registerBeacon-mainFormContainer animated fadeIn faster adjust-margin'>
					<Container className='beaconDetail-form animated fadeIn faster'>
						<VehicleSteps vehicle={vehicle} allowPreview={true} allowSelectVehicle={false} onCancel={this.onAskToCancel} />
					</Container>
				</Container>

				<Modal centered={false} open={showModal} size={"tiny"} className="centered">
					<Modal.Content>
						<Header className="modal-header">{i18n.t('warning')}</Header>
						{i18n.t('do-you-want-to-cancel-editing-and-return-to-the-dashboard')}
						<div className="activityDetail-submitContainer">
							<Button className="registerBeacon-submitButton" onClick={this.onModalAccept}>{i18n.t('yes')}</Button>
							<Button className="registerBeacon-submitButton" onClick={this.onModalCancel} primary>{i18n.t('no')}</Button>
						</div>
					</Modal.Content>
				</Modal>
			</div>
		)
	}
}
