import dbService, { getOfflineBeaconDb, storeOffline } from "./dbService";
import { insertOrUpdateDocument } from "./serviceUtils";
import * as Yup from "yup"; // for everything
let db;
import * as UserService from "./userServices";

export async function initIndexes() {
  db = dbService();
  try {
    let ownerBeaconCount = await createUserCountIndex();
    console.log("log ~ file: beaconService.js:12 ~ initIndexes ~ ownerBeaconCount:", ownerBeaconCount);
    return;

    let createBeaconSortIndexResult = await createBeaconSortIndex();
    console.log("BeaconService: initIndexes -> createBeaconSortIndex");
    let numberOfBeaconPerNPDIndex = await createBeaconPerNDPIndex();
    console.log("log: initIndexes -> numberOfBeaconPerNPDIndex", numberOfBeaconPerNPDIndex);
    let beaconPerUserIndex = await createBeaconPerUserIndex();
    console.log("BeaconService: initIndexes -> beaconPerUserIndex", beaconPerUserIndex);

    console.log("BeaconService: initIndexes -> ownerBeaconCount", ownerBeaconCount);

    let createBeaconSearchIndexResult = await createBeaconSearchIndex();
    console.log("BeaconService: initIndexes -> createBeaconSearchIndex", createBeaconSearchIndexResult);

    let createReportIndex = await createBeaconTypeReportIndex();
    console.log("BeaconService: initIndexes -> createBeaconTypeReportIndex", createReportIndex);
    // let ownerBeaconCount = await createUserCountIndex();
    // console.log("BeaconService: initIndexes -> ownerBeaconCount", ownerBeaconCount);
    let documentsByTypeIndex = await createDocumentsByTypeIndex();
    console.log("BeaconService: initIndexes -> documentsByTypeIndex", documentsByTypeIndex);
  } catch (error) {
    console.error("BeaconService: initIndexes -> error", error);
  }
}

export async function initIndexesOffline() {
  db = dbService();
  try {
    let setOffline = true;
    let createBeaconSortIndexResult = await createBeaconSortIndex(setOffline);
    console.log("BeaconService: initIndexes -> createBeaconSortIndex");
    let numberOfBeaconPerNPDIndex = await createBeaconPerNDPIndex(setOffline);
    console.log("log: initIndexes -> numberOfBeaconPerNPDIndex", numberOfBeaconPerNPDIndex);
    let beaconPerUserIndex = await createBeaconPerUserIndex(setOffline);
    console.log("BeaconService: initIndexes -> documentsByTypeIndex", beaconPerUserIndex);

    let ownerBeaconCount = await createUserCountIndex(setOffline);
    console.log("BeaconService: initIndexes -> ownerBeaconCount", ownerBeaconCount);

    let createBeaconSearchIndexResult = await createBeaconSearchIndex(setOffline);
    console.log("BeaconService: initIndexes -> createBeaconSearchIndex", createBeaconSearchIndexResult);

    let createReportIndex = await createBeaconTypeReportIndex(setOffline);
    console.log("BeaconService: initIndexes -> createBeaconTypeReportIndex", createReportIndex);
    // let ownerBeaconCount = await createUserCountIndex();
    // console.log("BeaconService: initIndexes -> ownerBeaconCount", ownerBeaconCount);
    let documentsByTypeIndex = await createDocumentsByTypeIndex(setOffline);
    console.log("BeaconService: initIndexes -> documentsByTypeIndex", documentsByTypeIndex);
  } catch (error) {
    console.error("BeaconService: initIndexes -> error", error);
  }
}

export async function checkIfHexIsRegistered(hex) {
  try {
    db = dbService();
    let result = await db.get("beacon_" + hex.toUpperCase());
    if (result.specialStatus === "SOLD") {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    if (error.name === "not_found") {
      return false;
    } else {
      console.error("BeaconService - checkIfHexIsRegistered", error);
    }
  }
}

export async function getConfig() {
  try {
    db = dbService();
    let result = await db.get("IBRD_CONFIG");
    let offlineBeaconDb = getOfflineBeaconDb();
    console.log("log ~ file: beaconService.js ~ line 58 ~ getConfig ~ offlineBeaconDb", offlineBeaconDb);
    storeOffline(result, offlineBeaconDb);
    //createBeaconSearchIndex(false);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function updateConfig(config) {
  try {
    db = dbService();
    let result = await insertOrUpdateDocument(config, db);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getAllbeacons() {
  try {
    db = dbService();
    let result = await db.allDocs({ startkey: "beacon_", endkey: "beacon_\uffff", include_docs: true });
    return result;
  } catch (error) {}
}

export async function getBeaconByIds(beaconIds) {
  try {
    db = dbService();
    let result = await db.allDocs({
      keys: beaconIds,
      include_docs: true
    });
    return result;
  } catch (error) {
    console.error(error);
  }
}

export async function searchBeaconByHex(criteria) {
  try {
    db = dbService();
    let startKey = "beacon_" + criteria.hexId;
    let endKey = "beacon_" + criteria.hexId + "\uffff";
    let result = await db.allDocs({ startkey: startKey, endkey: endKey, include_docs: true });
    return result;
  } catch (error) {}
}

export async function getBeaconByHexId(hex) {
  try {
    db = dbService();
    let response = await db.get("beacon_" + hex.toUpperCase());

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getMultipleBeaonsByIds(ids) {
  try {
    let response = await db.bulkGet({
      docs: ids
    });
    let beacons = response.results.map((result) => {
      return result.docs[0].ok;
    });
    return beacons;
  } catch (error) {
    console.log("log: getMultipleBeaonsByIds -> error", error);
  }
}

export async function getBeacon(id) {
  try {
    db = dbService();
    let result = await db.get(id);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function saveBeacon(beacon) {
  console.log("log ~ file: beaconService.js:174 ~ saveBeacon ~ beacon:", beacon);
  try {
    db = dbService();
    beacon._id = "beacon_" + beacon.hexId;
    beacon.type = "beacon";
    let result = await insertOrUpdateDocument(beacon, db);
    return result;
  } catch (error) {}
}

export async function updateBeacon(beacon) {
  console.log("log ~ file: beaconService.js:185 ~ updateBeacon ~ beacon:", beacon);
  try {
    db = dbService();
    let result = await insertOrUpdateDocument(beacon, db);
    return result;
  } catch (error) {}
}

export async function removeSearchersFromBeacon(id) {
  try {
    db = dbService();
    let beacon = await db.get(id);
    beacon.searchers = null;
    let result = await insertOrUpdateDocument(beacon, db);
    return result;
  } catch (error) {
    console.error("BeaconService - removeSearchersFromBeacon", error);
  }
}

export async function storeBeaconOffline(beacons) {
  const beaconOfflineDb = getOfflineBeaconDb();

  for (let beacon of beacons) {
    //console.log("log ~ file: beaconService.js ~ line 172 ~ storeBeaconOffline ~ beacon", beacon);
    await storeOffline(beacon, beaconOfflineDb);
  }
}

// export async function searchBeacon(criteria, offset, limit) {
export async function searchBeacon(criteria, beaconPerPage, beaconPagingCursor, beaconSortColumn, beaconSortDirection) {
  console.log("BeaconService: searchBeacon -> criteria", criteria, beaconSortColumn, beaconSortDirection);
  let willRequireTwoQuery = false;
  let beaconCountryCodeQueries = false;
  let beaconCountryCode = [];
  let selector2 = {
    type: "beacon"
  };
  let selector = {
    type: "beacon"
  };
  let simpleBeaconQueryNoSort = false;

  //Criteria per user role
  if (criteria.owner) {
    if (criteria.owner.includes("org.couchdb.user:")) {
      //selector["$or"] = [{ owner: criteria.owner }, { owner: criteria.owner.split(":")[1] }];

      willRequireTwoQuery = true;
      selector["owner"] = { $eq: criteria.owner };
      selector2["owner"] = { $eq: criteria.owner.split(":")[1] };
    } else {
      selector["owner"] = criteria.owner; //For Block User
    }
  }

  //Beacon Search Filter
  if (criteria.hexId) {
    // selector["hexId"] = { $gte: criteria.hexId[0].toUpperCase(), $lte: criteria.hexId[0].toUpperCase() + "\uffff" };
    if ([15, 23].includes(criteria.hexId[0].length)) {
      selector["hexId"] = { $eq: criteria.hexId[0].toUpperCase() };
      simpleBeaconQueryNoSort = true;
    } else {
      //selector["hexId"] = { $regex: "(?i)" + criteria.hexId[0].toUpperCase() + "(?i)" };
      selector["hexId"] = { $gte: criteria.hexId[0].toUpperCase(), $lte: criteria.hexId[0].toUpperCase() + "\uffff" };
    }
  }
  if (criteria.vehicleName) {
    // selector["vehicleName"] = { $regex: ".*" + criteria.vehicleName[0] + ".*" };
    selector["vehicleName"] = { $regex: "(?i)" + criteria.vehicleName[0] + "(?i)" };
  }
  if (criteria.callSign) {
    // selector["callSign"] = { $gte: criteria.callSign[0].toLowerCase(), $lte: criteria.callSign[0] + "\uffff" };
    selector["callSign"] = { $regex: "(?i)" + criteria.callSign[0] + "(?i)" };
  }

  if (criteria.MMSI) {
    console.log("log ~ file: beaconService.js:319 ~ searchBeacon ~ criteria.emailAddress:", criteria.emailAddress);
    if (criteria.MMSI[0].startsWith("*")) {
      let MMSI = criteria.MMSI[0].replaceAll("*", "");
      selector["MMSI"] = { $regex: "(?i)" + MMSI + "(?i)" };
    } else {
      let MMSI = criteria.MMSI[0].replaceAll("*", "");
      //selector["emailAddress"] = { $regex: "(?i)" + criteria.emailAddress + "(?i)" };
      //selector["MMSI"] = { $gte: MMSI.toLowerCase(), $lte: MMSI.toUpperCase() + "\uffff" };
      //selector["$or"] = [{ MMSI: { $gte: MMSI.toLowerCase(), $lte: MMSI.toUpperCase() + "\uffff" } }, { MMSIDecoded: { $gte: MMSI.toLowerCase(), $lte: MMSI.toUpperCase() + "\uffff" } }];

      willRequireTwoQuery = true;
      selector["MMSI"] = { $gte: MMSI.toLowerCase(), $lte: MMSI.toUpperCase() + "\uffff" };
      selector2["MMSIDecoded"] = { $gte: MMSI.toLowerCase(), $lte: MMSI.toUpperCase() + "\uffff" };
    }
  }

  if (criteria.vehicleRegistrationNumber) {
    console.log("log ~ file: beaconService.js:319 ~ searchBeacon ~ criteria.emailAddress:", criteria.emailAddress);
    if (criteria.vehicleRegistrationNumber[0].startsWith("*")) {
      let vehicleRegistrationNumber = criteria.vehicleRegistrationNumber[0].replaceAll("*", "");
      selector["vehicleRegistrationNumber"] = { $regex: "(?i)" + vehicleRegistrationNumber + "(?i)" };
    } else {
      let vehicleRegistrationNumber = criteria.vehicleRegistrationNumber[0].replaceAll("*", "");
      //selector["emailAddress"] = { $regex: "(?i)" + criteria.emailAddress + "(?i)" };
      selector["vehicleRegistrationNumber"] = { $gte: vehicleRegistrationNumber.toLowerCase(), $lte: vehicleRegistrationNumber.toUpperCase() + "\uffff" };
    }
  }

  if (criteria.aircraft24BitAddress) {
    console.log("log ~ file: beaconService.js:319 ~ searchBeacon ~ criteria.emailAddress:", criteria.emailAddress);
    if (criteria.aircraft24BitAddress[0].startsWith("*")) {
      let aircraft24BitAddress = criteria.aircraft24BitAddress[0].replaceAll("*", "");
      selector["aircraft24BitAddress"] = { $regex: "(?i)" + typeApprovalCertificate + "(?i)" };
    } else {
      let aircraft24BitAddress = criteria.aircraft24BitAddress[0].replaceAll("*", "");
      //selector["emailAddress"] = { $regex: "(?i)" + criteria.emailAddress + "(?i)" };
      selector["aircraft24BitAddress"] = { $gte: aircraft24BitAddress.toLowerCase(), $lte: aircraft24BitAddress.toUpperCase() + "\uffff" };
    }
  }

  if (criteria.aircraft24BitAddressDecoded) {
    console.log("log ~ file: beaconService.js:319 ~ searchBeacon ~ criteria.emailAddress:", criteria.emailAddress);
    if (criteria.aircraft24BitAddressDecoded[0].startsWith("*")) {
      let aircraft24BitAddressDecoded = criteria.aircraft24BitAddressDecoded[0].replaceAll("*", "");
      selector["aircraft24BitAddressDecoded"] = { $regex: "(?i)" + typeApprovalCertificate + "(?i)" };
    } else {
      let aircraft24BitAddressDecoded = criteria.aircraft24BitAddressDecoded[0].replaceAll("*", "");
      //selector["emailAddress"] = { $regex: "(?i)" + criteria.emailAddress + "(?i)" };
      selector["aircraft24BitAddressDecoded"] = { $gte: aircraft24BitAddressDecoded.toLowerCase(), $lte: aircraft24BitAddressDecoded.toUpperCase() + "\uffff" };
    }
  }

  if (criteria.typeApprovalCertificate) {
    console.log("log ~ file: beaconService.js:319 ~ searchBeacon ~ criteria.emailAddress:", criteria.emailAddress);
    if (criteria.typeApprovalCertificate[0].startsWith("*")) {
      let typeApprovalCertificate = criteria.typeApprovalCertificate[0].replaceAll("*", "");
      selector["typeApprovalCertificate"] = { $regex: "(?i)" + typeApprovalCertificate + "(?i)" };
    } else {
      let typeApprovalCertificate = criteria.emailAddress[0].replaceAll("*", "");
      //selector["emailAddress"] = { $regex: "(?i)" + criteria.emailAddress + "(?i)" };
      selector["typeApprovalCertificate"] = { $gte: typeApprovalCertificate.toLowerCase(), $lte: typeApprovalCertificate.toUpperCase() + "\uffff" };
    }
  }

  if (criteria.beaconType) {
    selector["beaconType"] = { $gte: criteria.beaconType[0], $lte: criteria.beaconType + "\uffff" };
  }
  if (criteria.serialNumber) {
    // selector["serialNumber"] = { $gte: criteria.serialNumber[0], $lte: criteria.serialNumber[0] + "\uffff" };

    let serialNumberInt = parseInt(criteria.serialNumber[0]); // this field in database and decoded function are int
    selector["serialNumber"] = { $eq: serialNumberInt };
  }
  if (criteria.specialStatus) {
    if (criteria.specialStatus == "ACTIVE") {
      selector["$or"] = [{ specialStatus: { $exists: false } }, { specialStatus: { $in: ["ACTIVE", ""] } }, { specialStatus: { $eq: null } }];
    } else {
      selector["specialStatus"] = { $in: criteria.specialStatus };
    }
  }
  if (criteria.ownerName) {
    //selector["ownerName"] = { $gte: criteria.ownerName[0].toLowerCase(), $lte: criteria.ownerName[0] + "\uffff" };

    if (criteria.ownerName[0].startsWith("*")) {
      let ownerName = criteria.ownerName[0].replaceAll("*", "");
      selector["ownerName"] = { $regex: "(?i)" + ownerName + "(?i)" };
    } else {
      let ownerName = criteria.ownerName[0].replaceAll("*", "");
      selector["ownerName"] = { $gte: ownerName.toLowerCase(), $lte: ownerName.toUpperCase() + "\uffff" };
    }
  }
  // for Admin, SAR, NDP Task_ID: #60493586
  if (criteria.accountOwnerName) {
    // use this if DON'T NEED to check with userProfile db
    // selector["ownerName"] = { $regex: "(?i)" + criteria.accountOwnerName[0] + "(?i)" };

    // use this if NEED to check with userProfile db
    let userIds = await UserService.getUserProfileByOwnerName(criteria.accountOwnerName[0]);
    selector["owner"] = { $in: userIds };
  }
  if (criteria.ownerUsername) {
    //make owner username to be able to use wildcards
    // selector["owner"] = { $regex: "(?i)" + criteria.ownerUsername[0] + "(?i)" }; // currently use

    // owner: "org.couchdb.user:blockuser01"
    //let userId = "org.couchdb.user:" + criteria.ownerUsername[0];
    //selector["owner"] = { $in: [userId, criteria.ownerUsername[0]] };
    //selector["$or"] = [{ owner: { $eq: "org.couchdb.user:" + criteria.ownerUsername[0] } }, { owner: { $eq: criteria.ownerUsername[0] } }];
    willRequireTwoQuery = true;
    selector["owner"] = criteria.ownerUsername[0]; //For Block User
    selector2["owner"] = "org.couchdb.user:" + criteria.ownerUsername[0]; //For Block User
  }
  if (criteria.emailAddress) {
    console.log("log ~ file: beaconService.js:319 ~ searchBeacon ~ criteria.emailAddress:", criteria.emailAddress);
    if (criteria.emailAddress[0].startsWith("*")) {
      let email = criteria.emailAddress[0].replaceAll("*", "");
      selector["emailAddress"] = { $regex: "(?i)" + email + "(?i)" };
    } else {
      let email = criteria.emailAddress[0].replaceAll("*", "");
      //selector["emailAddress"] = { $regex: "(?i)" + criteria.emailAddress + "(?i)" };
      selector["emailAddress"] = { $gte: email.toLowerCase(), $lte: email.toUpperCase() + "\uffff" };
    }
  }
  if (criteria.countryName) {
    //selector["countryName"] = { $gte: criteria.countryName[0].toLowerCase(), $lte: criteria.countryName[0] + "\uffff" };
    //selector["$or"] = [{ countryName: { $gte: criteria.countryName[0].toLowerCase(), $lte: criteria.countryName[0] + "\uffff" } }, { mailCountry: { $gte: criteria.countryName[0].toLowerCase(), $lte: criteria.countryName[0] + "\uffff" } }];
    willRequireTwoQuery = true;
    selector["countryName"] = { $gte: criteria.countryName[0].toLowerCase(), $lte: criteria.countryName[0] + "\uffff" };
    selector2["mailCountry"] = { $gte: criteria.countryName[0].toLowerCase(), $lte: criteria.countryName[0] + "\uffff" };
  }

  if (criteria.beaconCountryCode) {
    // selector["beaconCountryCode"] = { $in: criteria.beaconCountryCode }; //For NDP or filter

    let filtered = criteria.beaconCountryCode.filter((countryCode) => countryCode !== null);
    if (Array.isArray(filtered[0])) {
      filtered = filtered[0];
    }
    if (filtered.length > 1) {
      beaconCountryCodeQueries = true;
      beaconCountryCode = filtered;
      willRequireTwoQuery = true;
      selector["beaconCountryCode"] = { $eq: filtered[0] };
      selector2["beaconCountryCode"] = { $eq: filtered[1] };
    } else {
      selector["beaconCountryCode"] = { $eq: filtered[0] };
    }
  }
  if (criteria.maintProvider) {
    selector["maintProvider"] = criteria.maintProvider; //For Maintenance Provider
    selector["specialStatus"] = "RELEASETOMAINTENANCE";
  }

  if (criteria.lastEditDate) {
    let startDate = new Date(criteria.lastEditDate.date + " 00:00:00");
    let endDate = new Date(criteria.lastEditDate.date + " 23:59:59");

    if (criteria.lastEditDate.condition == "Exact") {
      selector["lastEditDate"] = { $gte: startDate.getTime(), $lte: endDate.getTime() };
    } else if (criteria.lastEditDate.condition == "Before") {
      selector["lastEditDate"] = { $lt: startDate.getTime() };
    } else if (criteria.lastEditDate.condition == "After") {
      selector["lastEditDate"] = { $gt: endDate.getTime() };
    }
  }
  if (criteria.lastConfirmationDate) {
    let startDate = new Date(criteria.lastConfirmationDate.date + " 00:00:00");
    let endDate = new Date(criteria.lastConfirmationDate.date + " 23:59:59");

    if (criteria.lastConfirmationDate.condition == "Exact") {
      selector["lastConfirmationDate"] = { $gte: startDate.getTime(), $lte: endDate.getTime() };
    } else if (criteria.lastConfirmationDate.condition == "Before") {
      selector["lastConfirmationDate"] = { $lt: startDate.getTime() };
    } else if (criteria.lastConfirmationDate.condition == "After") {
      selector["lastConfirmationDate"] = { $gt: endDate.getTime() };
    }
  }
  if (criteria.delegationStatus) {
    switch (criteria.delegationStatus[0]) {
      case "NDPManaged":
        selector["$or"] = [{ isDelegated: { $exists: false } }, { isDelegated: { $in: [false, ""] } }];
        break;
      case "NDPDelegated":
        selector["isDelegated"] = true;
        selector["delegateConfirmDate"] = { $ne: null };
        break;
      case "NDPDelegatedImcomplete":
        selector["isDelegated"] = true;
        selector["delegationIncomplete"] = true;
        break;
      case "NDPDelegatedPending":
        selector["isDelegated"] = true;
        selector["delegateConfirmDate"] = null;
        selector["$or"] = [{ delegationIncomplete: { $exists: false } }, { delegationIncomplete: { $ne: true } }];
        break;
    }
  }

  console.log("beaconService: searchBeacon -> selector", selector);
  try {
    db = dbService();
    console.time("beaconService::searchBeacon time");
    console.log("log: searchBeacon -> beaconSortColumn", beaconSortColumn);

    if (!beaconSortColumn) beaconSortColumn = "lastEditDate";
    if (!beaconSortDirection) beaconSortDirection = "descending";
    if (selector[beaconSortColumn]) {
      selector[beaconSortColumn].$exists = true;
    } else {
      selector[beaconSortColumn] = { $exists: true };
    }

    let searchOptions = {
      selector: selector,
      limit: beaconPerPage,
      skip: beaconPagingCursor,
      sort: [{ [beaconSortColumn]: beaconSortDirection == "descending" ? "desc" : "asc" }],
      use_index: "beaconSearchIndex",
      execution_stats: true
    };
    let manualSort = false;
    if (simpleBeaconQueryNoSort || willRequireTwoQuery || !beaconPerPage) {
      delete searchOptions["sort"];
      delete searchOptions.selector[beaconSortColumn];
      manualSort = true;
    }

    console.log("log ~ file: beaconService.js ~ line 384 ~ searchBeacon ~ searchOptions", searchOptions);

    db.explain(searchOptions)
      .then(function(explanation) {
        console.log("log ~ file: beaconService.js:393 ~ .then ~ explanation", explanation);
        // view explanation
      })
      .catch(function(err) {
        console.log(err);
      });

    console.time("beaconService::beaconSearch::Find1");
    var result = await db.find(searchOptions);
    console.timeEnd("beaconService::beaconSearch::Find1");
    console.log("log ~ file: beaconService.js ~ line 378 ~ searchBeacon ~ result", result);
    if (willRequireTwoQuery) {
      searchOptions.selector = Object.assign(selector, selector2);

      try {
        db.explain(searchOptions)
          .then(function(explanation) {
            console.log("log ~ file: beaconService.js:393 ~ .then ~ explanation 2", explanation);
            // view explanation
          })
          .catch(function(err) {
            console.log(err);
          });

        console.time("beaconService::beaconSearch::Find2");
        let result2 = await db.find(searchOptions);
        console.timeEnd("beaconService::beaconSearch::Find2");
        //console.log("log ~ file: beaconService.js:414 ~ searchBeacon ~ result2", result2);
        result.docs = result.docs.concat(result2.docs);
      } catch (error) {
        console.log("log ~ file: beaconService.js:446 ~ searchBeacon ~ error", error);
      }
    }
    //console.log("log ~ file: beaconService.js:417 ~ searchBeacon ~ result", result);

    console.timeEnd("beaconService::searchBeacon time");
    if (manualSort) {
      result.docs = result.docs.sort((a, b) => {
        if (beaconSortDirection == "descending") {
          return b[beaconSortColumn] - a[beaconSortColumn];
        } else {
          return a[beaconSortColumn] - b[beaconSortColumn];
        }
      });
    }
    return result;
  } catch (err) {
    throw err;
  }
}

async function createBeaconSearchIndex(setOffline) {
  try {
    db = dbService(setOffline);
    let newNdpIndexResult = await db.createIndex({
      index: { fields: ["beaconCountryCode", "type"] },
      name: "NDPbeaconSearchIndex",
      ddoc: "NDPbeaconSearchIndex",
      type: "json"
    });
    console.log("log: createBeaconSearchIndex -> newNdpIndexResult", newNdpIndexResult);

    var result = await db.createIndex({
      index: {
        fields: [
          "lastEditDate",
          "type",
          "owner", //for Block User
          "beaconCountryCode", //for NDP
          "maintProvider", //for Maintenance Provider
          "hexId",
          "vehicleName",
          "callSign",
          "MMSI",
          "vehicleRegistrationNumber",
          "aircraft24BitAddress",
          "aircraft24BitAddressDecoded",
          "typeApprovalCertificate",
          "beaconType",
          "serialNumber",
          "specialStatus",
          "ownerName",
          "countryName",
          "lastConfirmationDate"
        ],
        name: "beaconSearchIndex",
        ddoc: "beaconSearchIndex",
        type: "json"
      }
    });
    console.log("log ~ file: beaconService.js:473 ~ createBeaconSearchIndex ~ result", result);

    var result = await db.createIndex({
      index: {
        fields: ["lastEditDate", "type", "hexId"],
        name: "beaconSearchIndexHexOnly",
        ddoc: "beaconSearchIndex",
        type: "json"
      }
    });
    console.log("log ~ file: beaconService.js:482 ~ createBeaconSearchIndex ~ result", result);

    var result = await db.createIndex({
      index: {
        fields: ["lastEditDate", "type"],
        name: "beaconSearchIndexTypeOnly",
        ddoc: "beaconSearchIndex",
        type: "json"
      }
    });
    console.log("log ~ file: beaconService.js:493 ~ createBeaconSearchIndex ~ result", result);
       var result = await db.createIndex({
      index: {
        fields: ["type" , "lastEditDate"],
        name: "beaconTypeLastEditDate",
        ddoc: "beaconSearchIndex",
        type: "json"
      }
    });
    console.log("log ~ file: beaconService.js:493 ~ createBeaconSearchIndex ~ result", result);
    await waitOneSecond();
    var result = await db.createIndex({
      index: {
        fields: ["lastEditDate"],
        name: "beaconSearchIndexLastEditDateOnly",
        ddoc: "beaconSearchIndex",
        type: "json"
      }
    });
    console.log("log ~ file: beaconService.js:503 ~ createBeaconSearchIndex ~ result", result);

    var result = await db.createIndex({
      index: {
        fields: ["type", "owner", "lastEditDate"],
        name: "typeOwnerLastEditDate",
        ddoc: "beaconSearchIndex",
        type: "json"
      }
    });
    console.log("log ~ file: beaconService.js:509 ~ createBeaconSearchIndex ~ result", result);

    var result = await db.createIndex({
      index: {
        fields: ["type", "ownerName"],
        name: "typeOwnerName",
        ddoc: "beaconSearchIndex",
        type: "json"
      }
    });
    console.log("log ~ file: beaconService.js:577 ~ createBeaconSearchIndex ~  typeOwnerName result:", result);
    await waitOneSecond();
    var result = await db.createIndex({
      index: {
        fields: ["type", "beaconCountryCode", "ownerName"],
        name: "typeBeaconCountryCodeOwnerName",
        ddoc: "beaconSearchIndex",
        type: "json"
      }
    });
    console.log("log ~ file: beaconService.js:587 ~ createBeaconSearchIndex ~ typeBeaconCountryCodeOwnerName result:", result);

    var result = await db.createIndex({
      index: {
        fields: ["type", "owner"],
        name: "typeOwner",
        ddoc: "beaconSearchIndex",
        type: "json"
      }
    });

    console.log("log ~ file: beaconService.js:519 ~ createBeaconSearchIndex ~ result", result);
    await waitOneSecond();
    var result = await db.createIndex({
      index: {
        fields: ["type", "beaconCountryCode", "lastEditDate"],
        name: "typeBeaconCountryCodeLastEditDate",
        ddoc: "beaconSearchIndex",
        type: "json"
      }
    });
    console.log("log ~ file: beaconService.js:529 ~ createBeaconSearchIndex ~ result", result);

    var result = await db.createIndex({
      index: {
        fields: ["type", "beaconCountryCode"],
        name: "typeBeaconCountryCode",
        ddoc: "beaconSearchIndex",
        type: "json"
      }
    });
    console.log("log ~ file: beaconService.js:539 ~ createBeaconSearchIndex ~ result", result);
    await waitOneSecond();
    var result = await db.createIndex({
      index: {
        fields: ["type", "hexId", "lastEditDate"],
        name: "typeHexLastEditDate",
        ddoc: "beaconSearchIndex",
        type: "json"
      }
    });
    console.log("log ~ file: beaconService.js:551 ~ createBeaconSearchIndex ~ result", result);

    var result = await db.createIndex({
      index: {
        fields: ["type", "hexId"],
        name: "typeHex",
        ddoc: "beaconSearchIndex",
        type: "json"
      }
    });
    console.log("log ~ file: beaconService.js:561 ~ createBeaconSearchIndex ~ result", result);

    var result = await db.createIndex({
      index: {
        fields: ["type", "emailAddress"],
        name: "typeEmail",
        ddoc: "beaconSearchIndex",
        type: "json"
      }
    });
    console.log("log ~ file: beaconService.js:619 ~ createBeaconSearchIndex ~  typeEmail result:", result);

    var result = await db.createIndex({
      index: {
        fields: ["type", "typeApprovalCertificate"],
        name: "typeTypeApprovalCertificate",
        ddoc: "beaconSearchIndex",
        type: "json"
      }
    });
    console.log("log ~ file: beaconService.js:619 ~ createBeaconSearchIndex ~  typeEmail result:", result);
    await waitOneSecond();

    var result = await db.createIndex({
      index: {
        fields: ["type", "aircraft24BitAddressDecoded"],
        name: "typeAircraft24BitAddressDecoded",
        ddoc: "beaconSearchIndex",
        type: "json"
      }
    });
    console.log("log ~ file: beaconService.js:619 ~ createBeaconSearchIndex ~  typeEmail result:", result);
    var result = await db.createIndex({
      index: {
        fields: ["type", "aircraft24BitAddress"],
        name: "typeAircraft24BitAddress",
        ddoc: "beaconSearchIndex",
        type: "json"
      }
    });
    console.log("log ~ file: beaconService.js:619 ~ createBeaconSearchIndex ~  typeEmail result:", result);
    var result = await db.createIndex({
      index: {
        fields: ["type", "vehicleRegistrationNumber"],
        name: "typeVehicleRegistrationNumber",
        ddoc: "beaconSearchIndex",
        type: "json"
      }
    });
    console.log("log ~ file: beaconService.js:619 ~ createBeaconSearchIndex ~  typeEmail result:", result);
    await waitOneSecond();
    var result = await db.createIndex({
      index: {
        fields: ["type", "mailCountry", "lastEditDate"],
        name: "typeMailCountryLastEditDate",
        ddoc: "beaconSearchIndex",
        type: "json"
      }
    });
    console.log("log ~ file: beaconService.js:576 ~ createBeaconSearchIndex ~ result", result);

    var result = await db.createIndex({
      index: {
        fields: ["type", "MMSI"],
        name: "typeMMSI",
        ddoc: "beaconSearchIndex",
        type: "json"
      }
    });
    console.log("log ~ file: beaconService.js:619 ~ createBeaconSearchIndex ~  typeEmail result:", result);

    var result = await db.createIndex({
      index: {
        fields: ["type", "MMSIDecoded"],
        name: "typeMMSIDecoded",
        ddoc: "beaconSearchIndex",
        type: "json"
      }
    });
    console.log("log ~ file: beaconService.js:619 ~ createBeaconSearchIndex ~  typeEmail result:", result);

    var result = await db.createIndex({
      index: {
        fields: ["type", "callSign"],
        name: "typeCallSign",
        ddoc: "beaconSearchIndex",
        type: "json"
      }
    });
    console.log("log ~ file: beaconService.js:619 ~ createBeaconSearchIndex ~  typeEmail result:", result);
    var result = await db.createIndex({
      index: {
        fields: ["type", "vehicleName"],
        name: "typeVehicleName",
        ddoc: "beaconSearchIndex",
        type: "json"
      }
    });
    console.log("log ~ file: beaconService.js:619 ~ createBeaconSearchIndex ~  typeEmail result:", result);

    await waitOneSecond();
    var result = await db.createIndex({
      index: {
        fields: ["type", "countryName", "lastEditDate"],
        name: "typeCountryNameLastEditDate",
        ddoc: "beaconSearchIndex",
        type: "json"
      }
    });
    console.log("log ~ file: beaconService.js:585 ~ createBeaconSearchIndex ~ result", result);

    var result = await db.createIndex({
      index: {
        fields: ["type", "beaconCountryCode", "beaconType"],
        name: "typeBeaconCountryCodeBeaconType",
        ddoc: "beaconSearchIndex",
        type: "json"
      }
    });
    console.log("log ~ file: beaconService.js:614 ~ createBeaconSearchIndex ~ result", result);

    var result = await db.createIndex({
      index: {
        fields: ["type", "hexId", "beaconCountryCode"],
        name: "typeBeaconCountryCodeHexId",
        ddoc: "beaconSearchIndex",
        type: "json"
      }
    });
    console.log("log ~ file: beaconService.js:625 ~ createBeaconSearchIndex ~ result", result);

    var result = await db.createIndex({
      index: {
        fields: ["type", "beaconCountryCode", "emailAddress"],
        name: "typeBeaconCountryCodeEmail",
        ddoc: "beaconSearchIndex",
        type: "json"
      }
    });
    console.log("log ~ file: beaconService.js:669 ~ createBeaconSearchIndex ~ result typeBeaconCountryCodeEmail:", result);

    return result;
  } catch (err) {
    console.error("error: createBeaconSearchIndex -> err", err);
    console.log(err);
  }
}

export async function getBeaconTypeCount(owner) {
  let username = owner ? (owner.includes("org.couchdb.user:") ? owner.split(":")[1] : owner) : null;
  console.log("log ~ file: beaconService.js:652 ~ getBeaconTypeCount ~ username:", username);
  let result = await db.query("ownerBeaconCount/beaconsByType", {
    startkey: username,
    endkey: username + "\uffff",
    reduce: true,
    group: true,
    include_docs: false
  });
  console.log("log ~ file: beaconService.js:659 ~ getBeaconTypeCount ~ result:", result);

  return result.rows;
}

export async function getUserBeacons(owner) {
  try {
    console.time("log ~ file: beaconService.js:648 ~ getUserBeacons ~ owner:");
    db = dbService();
    console.time("beaconService::getUserBeaconCount");
    let result = await db.query("ownerBeaconCount/beacons", {
      key: owner,
      reduce: false,
      group: false,
      include_docs: true
    });

    let result2 = { rows: [] };
    if (owner.includes("org.couchdb.user:")) {
      result2 = await db.query("ownerBeaconCount/beacons", {
        key: owner.split(":")[1],
        reduce: false,
        group: false,
        include_docs: true
      });
    }
    console.timeEnd("log ~ file: beaconService.js:648 ~ getUserBeacons ~ owner:");
    console.log("log: getUserBeaconCount -> result", result);
    return result.rows.concat(result2.rows);
  } catch (error) {
    console.log("log: getUserBeaconCount -> error", error);
  }
}

export async function getUserBeaconsByOwnerAndAccount(ownerName, ownerUsername, reduce) {
  console.log("log ~ file: beaconService.js ~ line 454 ~ getUserBeaconsByOwnerAndAccount ~ ownerName, ownerUsername", ownerName, ownerUsername);
  console.log("log: getUserBeaconsByOwnerAndAccount -> ownerName, ownerUsername", "org.couchdb.user:" + ownerUsername);
  try {
    db = dbService();
    console.time("beaconService::getUserBeaconsByOwnerAndAccount");
    let result;
    if (ownerName && ownerUsername) {
      result = await db.query("ownerBeaconCount/ownerNameAndAccount", {
        key: ownerName + "_org.couchdb.user:" + ownerUsername,
        reduce: reduce,
        group: reduce,
        include_docs: !reduce
      });

      if (result.rows.length === 0) {
        result = await db.query("ownerBeaconCount/ownerNameAndAccount", {
          key: ownerName + "_" + ownerUsername,
          reduce: reduce,
          group: reduce,
          include_docs: !reduce
        });
      }
    }
    if (ownerName && !ownerUsername) {
      result = await db.query("ownerBeaconCount/ownerName", {
        key: ownerName,
        reduce: reduce,
        group: reduce,
        include_docs: !reduce
      });
    } else {
      let result2 = await db.query("ownerBeaconCount/beacons", {
        key: "org.couchdb.user:" + ownerUsername,
        reduce: reduce,
        group: reduce,
        include_docs: !reduce
      });

      let result3 = await db.query("ownerBeaconCount/beacons", {
        key: ownerUsername,
        reduce: reduce,
        group: reduce,
        include_docs: !reduce
      });
      result = {
        rows: [
          {
            value: 0
          }
        ]
      };
      if (result2.rows.length !== 0) {
        result.rows[0].value += result2.rows[0].value;
      }
      if (result3.rows.length !== 0) {
        result.rows[0].value += result3.rows[0].value;
      }
    }

    console.timeEnd("beaconService::getUserBeaconsByOwnerAndAccount");
    console.log("log: getUserBeaconCount -> result", result);
    return result;
  } catch (error) {
    console.log("log: getUserBeaconCount -> error", error);
  }
}

async function createUserCountIndex(setOffline) {
  try {
    var ddoc = {
      _id: "_design/ownerBeaconCount",
      views: {
        beacons: {
          map: function(doc) {
            if (doc.type === "beacon") {
              emit(doc.owner);
            }
          }.toString(),
          reduce: "_count"
        },
        beaconsByType: {
          map: function(doc) {
            if (doc.type === "beacon") {
              var owner = doc.owner ? (doc.owner.indexOf("org.couchdb.user:") !== -1 ? doc.owner.split(":")[1] : doc.owner) : null;
              if (doc.beaconType.indexOf("EPIRB") !== -1) {
                emit(owner + "_EPIRB" + ":" + doc.beaconCountryCode);
              } else if (doc.beaconType.indexOf("PLB") !== -1) {
                emit(owner + "_PLB" + ":" + doc.beaconCountryCode);
              } else if (doc.beaconType.indexOf("ELT") !== -1) {
                emit(owner + "_ELT" + ":" + doc.beaconCountryCode);
              }
            }
          }.toString(),
          reduce: "_count"
        },

        ownerNameAndAccount: {
          map: function(doc) {
            if (doc.type === "beacon" && doc.ownerName && doc.owner) {
              emit(doc.ownerName + "_" + doc.owner);
            }
          }.toString(),
          reduce: "_count"
        },
        ownerName: {
          map: function(doc) {
            if (doc.type === "beacon" && doc.ownerName) {
              emit(doc.ownerName);
            }
          }.toString(),
          reduce: "_count"
        }
      }
    };

    db = dbService(setOffline);
    return await insertOrUpdateDocument(ddoc, db);
  } catch (error) {
    console.log("log: createUserCountIndex -> error", error);
  }
}

async function createDocumentsByTypeIndex(setOffline) {
  try {
    var ddoc = {
      _id: "_design/documentsByType",
      views: {
        beacons: {
          map: function(doc) {
            emit(doc.type);
          }.toString(),
          reduce: "_count"
        }
      }
    };

    db = dbService(setOffline);
    return await insertOrUpdateDocument(ddoc, db);
  } catch (error) {
    console.log("log: createUserCountIndex -> error", error);
  }
}

async function createBeaconPerUserIndex(setOffline) {
  try {
    var ddoc = {
      _id: "_design/beaconPerUser",
      views: {
        beacons: {
          map: function(doc) {
            emit(doc.type + doc.owner);
          }.toString(),
          reduce: "_count"
        }
      }
    };

    db = dbService(setOffline);
    return await insertOrUpdateDocument(ddoc, db);
  } catch (error) {
    console.log("log: createUserCountIndex -> error", error);
  }
}

async function createBeaconPerNDPIndex(setOffline) {
  try {
    var ddoc = {
      _id: "_design/beaconPerNDP",
      views: {
        beacons: {
          map: function(doc) {
            emit(doc.type + doc.beaconCountryCode);
          }.toString(),
          reduce: "_count"
        }
      }
    };

    db = dbService(setOffline);
    return await insertOrUpdateDocument(ddoc, db);
  } catch (error) {
    console.log("log: createUserCountIndex -> error", error);
  }
}

async function createBeaconSortIndex(setOffline) {
  try {
    db = dbService(setOffline);
    var result;
    result = await db.createIndex({
      index: {
        fields: ["type", "owner"],
        name: "typeAndOwnerIndex",
        ddoc: "typeAndOwnerIndex",
        type: "json"
      }
    });
    await waitOneSecond();
    console.log("createBeaconSortIndex -> type / owner", result);

    result = await db.createIndex({
      index: {
        fields: ["type", "beaconCountryCode"],
        name: "typeAndOwnerIndex",
        ddoc: "typeAndOwnerIndex",
        type: "json"
      }
    });
    console.log("createBeaconSortIndex -> type / beaconCountryCode", result);
    result = await db.createIndex({
      index: {
        fields: ["aircraft24BitAddress"],
        name: "aircraft24BitAddressSortIndex",
        ddoc: "aircraft24BitAddressSortIndex",
        type: "json"
      }
    });

    await waitOneSecond();
    console.log("createBeaconSortIndex -> aircraft24BitAddress", result);
    result = await db.createIndex({
      index: {
        fields: ["aircraft24BitAddressDecoded"],
        name: "aircraft24BitAddressDecodedSortIndex",
        ddoc: "aircraft24BitAddressDecodedSortIndex",
        type: "json"
      }
    });
    console.log("createBeaconSortIndex -> aircraft24BitAddressDecoded", result);
    result = await db.createIndex({
      index: {
        fields: ["typeApprovalCertificate"],
        name: "typeApprovalCertificateSortIndex",
        ddoc: "typeApprovalCertificateSortIndex",
        type: "json"
      }
    });

    await waitOneSecond();
    console.log("createBeaconSortIndex -> typeApprovalCertificate", result);
    result = await db.createIndex({
      index: {
        fields: ["hexId"],
        name: "hexIdSortIndex",
        ddoc: "hexIdSortIndex",
        type: "json"
      }
    });
    console.log("createBeaconSortIndex -> hexId", result);
    result = await db.createIndex({
      index: {
        fields: ["serialNumber"],
        name: "serialNumberSortIndex",
        ddoc: "serialNumberSortIndex",
        type: "json"
      }
    });
    console.log("createBeaconSortIndex -> serialNumber", result);
    result = await db.createIndex({
      index: {
        fields: ["countryName"],
        name: "countryNameSortIndex",
        ddoc: "countryNameSortIndex",
        type: "json"
      }
    });

    await waitOneSecond();
    console.log("createBeaconSortIndex -> countryName", result);
    result = await db.createIndex({
      index: {
        fields: ["beaconCountryCode"],
        name: "beaconCountryCodeSortIndex",
        ddoc: "beaconCountryCodeSortIndex",
        type: "json"
      }
    });
    console.log("createBeaconSortIndex -> beaconCountryCode", result);

    result = await db.createIndex({
      index: {
        fields: ["lastEditDate"],
        name: "lastEditDateSortIndex",
        ddoc: "lastEditDateSortIndex",
        type: "json"
      }
    });
    console.log("createBeaconSortIndex -> lastEditDate", result);
    result = await db.createIndex({
      index: {
        fields: ["beaconType"],
        name: "beaconTypeSortIndex",
        ddoc: "beaconTypeSortIndex",
        type: "json"
      }
    });

    await waitOneSecond();
    console.log("createBeaconSortIndex -> beaconType", result);
    result = await db.createIndex({
      index: {
        fields: ["ownerName"],
        name: "ownerNameSortIndex",
        ddoc: "ownerNameSortIndex",
        type: "json"
      }
    });
    console.log("createBeaconSortIndex -> ownerName", result);
    result = await db.createIndex({
      index: {
        fields: ["vehicleName"],
        name: "vehicleNameSortIndex",
        ddoc: "vehicleNameSortIndex",
        type: "json"
      }
    });
    console.log("createBeaconSortIndex -> vehicleName", result);
    result = await db.createIndex({
      index: {
        fields: ["callSign"],
        name: "callSignSortIndex",
        ddoc: "callSignSortIndex",
        type: "json"
      }
    });

    await waitOneSecond();
    console.log("createBeaconSortIndex -> callSign", result);
    result = await db.createIndex({
      index: {
        fields: ["MMSI"],
        name: "MMSISortIndex",
        ddoc: "MMSISortIndex",
        type: "json"
      }
    });
    console.log("createBeaconSortIndex -> MMSI", result);
    result = await db.createIndex({
      index: {
        fields: ["vehicleRegistrationNumber"],
        name: "vehicleRegistrationNumberSortIndex",
        ddoc: "vehicleRegistrationNumberSortIndex",
        type: "json"
      }
    });
    console.log("createBeaconSortIndex -> vehicleRegistrationNumber", result);
    result = await db.createIndex({
      index: {
        fields: ["vehicleType"],
        name: "vehicleTypeSortIndex",
        ddoc: "vehicleTypeSortIndex",
        type: "json"
      }
    });
    // debugger;
    console.log("createBeaconSortIndex -> type / owner", result);
  } catch (err) {
    // debugger;
    console.log("createBeaconSortIndex -> error", err);
  }
}

function waitOneSecond() {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, 1000);
  });
}

// New Registration Report
async function createBeaconTypeReportIndex(setOffline) {
  var ddoc = {
    _id: "_design/newRegistrationReport",
    views: {
      newRegistrationReport: {
        map: function(doc) {
          if (doc.initialDate && doc.beaconType) {
            // var yearAndMonth = doc.initialDate.substr(0, 7); // 2013-05-22 02:06:26.000 -> 2013-05

            var d, yearAndMonth;
            if (typeof doc.initialDate == "number") {
              let createDate = new Date(doc.initialDate);
              let year = createDate.getFullYear();
              let month = ("0" + (createDate.getMonth() + 1)).slice(-2);
              let day = ("0" + createDate.getDate()).slice(-2);
              let hours = ("0" + createDate.getHours()).slice(-2);
              let minutes = ("0" + createDate.getMinutes()).slice(-2);
              let seconds = ("0" + createDate.getSeconds()).slice(-2);

              d = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
              yearAndMonth = d.substr(0, 10);
            } else {
              yearAndMonth = doc.initialDate.substr(0, 10);
            }

            var beaconType = "";
            var beaconTypeSplt = doc.beaconType.split(" ");
            if (beaconTypeSplt.length > 1) {
              beaconType = beaconTypeSplt[0]; // PLB SERIAL (STANDARD) -> PLB
            } else {
              beaconType = doc.beaconType; //Case where only the type is without added info (never found one actualyl)
            }
            emit(yearAndMonth + "-" + beaconType);
          }
        }.toString(),

        reduce: "_count"
      }
    }
  };

  // save the design doc
  try {
    try {
      db = dbService(setOffline);
      await insertOrUpdateDocument(ddoc, db);
      // await insertOrUpdateDocument(ddoc, db) // if need to update index use thi line
    } catch (err) {
      if (err.name !== "conflict") {
        throw err;
      }
      // ignore if doc already exists
    }
  } catch (err) {
    console.log(err);
  }
}

export async function getBeaconTypeReport(startDate, endDate) {
  try {
    db = dbService();
    var result = await db.query("newRegistrationReport", {
      group: true,
      include_docs: false,
      startkey: startDate,
      endkey: endDate + "\uffff",
      inclusive_end: false
    });
    return result;
  } catch (err) {
    console.log(err);
  }
}

export async function storeBulkBeacons(beacons) {
  console.log("log ~ file: beaconService.js:1106 ~ storeBulkBeacons ~ beacons:", beacons);
  try {
    const withAddedDate = beacons.map((beacon) => {
      beacon.lastEditDate = Date.now();
      return beacon;
    });
    db = dbService();
    let result = await db.bulkDocs(withAddedDate);
    return result;
  } catch (error) {
    console.warn("beaconService: storeBulkBeacons -> error", error);
  }
}

export async function getBeaconTotalRows() {
  try {
    db = dbService();
    /*let result = await db.allDocs({
      startkey: "beacon_",
      endkey: "beacon_" + "\uffff",
      include_docs: false,
    });*/
    let result = await db.query("documentsByType/beacons", {
      key: "beacon",
      include_docs: false,
      group: true,
      reduce: true
    });
    console.log("log: getBeaconTotalRows -> result", result);
    let nbRows = result.rows[0];
    return nbRows.value; //result.total_rows;
  } catch (error) {
    console.log("log: getBeaconTotalRows -> error", error);
  }
}

export async function getBeaconTotalRowsPerUser(ownerName) {
  console.log("log: getBeaconTotalRowsPerUser -> ownerName", ownerName);
  try {
    db = dbService();
    /*let result = await db.allDocs({
      startkey: "beacon_",
      endkey: "beacon_" + "\uffff",
      include_docs: false,
    });*/
    let result = await db.query("beaconPerUser/beacons", {
      key: "beacon" + "org.couchdb.user:" + ownerName,
      include_docs: false,
      group: true,
      reduce: true
    });
    if (result.rows.length === 0) {
      result = await db.query("beaconPerUser/beacons", {
        key: "beacon" + ownerName,
        include_docs: false,
        group: true,
        reduce: true
      });
    }
    console.log("log: getBeaconTotalRowsPerUser -> result", result);
    let nbRows = result.rows[0];
    return (nbRows && nbRows.value) || 0; //result.total_rows;
  } catch (error) {
    console.log("log: getBeaconTotalRows -> error", error);
  }
}

export async function getBeaconTotalRowsPerNDP(beaconCountryCode) {
  console.log("log: getBeaconTotalRowsPerNDP -> beaconCountryCode", beaconCountryCode);
  try {
    db = dbService();
    /*let result = await db.allDocs({
      startkey: "beacon_",
      endkey: "beacon_" + "\uffff",
      include_docs: false,
    });*/

    let filteredContryCode = beaconCountryCode.filter((code) => code);
    let code = filteredContryCode[0];
    if (code.indexOf(",") !== -1) {
      code = code.split(",")[0];
    }

    let key = "beacon" + code;

    let keys = beaconCountryCode.map((mid) => {
      return "beacon" + mid;
    });
    console.log("log ~ file: beaconService.js ~ line 971 ~ keys ~ keys", keys);

    console.log("log: getBeaconTotalRowsPerNDP -> key", key);

    //keys = ["beacon266"]

    let result = await db.query("beaconPerNDP/beacons", {
      keys: keys,
      include_docs: false,
      group: true,
      reduce: true
    });

    console.log("log: getBeaconTotalRowsPerNDP -> result", result);
    let returnValue = 0;
    for (let row of result.rows) {
      returnValue += row.value;
    }
    return returnValue;
    let nbRows = result.rows[0];
    return (nbRows && nbRows.value) || 0; //result.total_rows;
  } catch (error) {
    console.log("log: getBeaconTotalRowsPerNDP -> error", error);
    return 0;
  }
}

export async function getAllBeaconsPaged(beaconPerPage, beaconPagingCursor, beaconSortColumn, beaconSortDirection) {
  try {
    db = dbService();

    /*console.warn("log: getAllBeaconsPaged");
    console.log("beaconPerPage", beaconPerPage);
    console.log("beaconPagingCursor", beaconPagingCursor);
    console.log("beaconSortColumn", beaconSortColumn);
    console.log("beaconSortDirection", beaconSortDirection);*/

    let selector = {};
    selector["type"] = "beacon";    
    console.time("getAllBeaconsPaged::time");

    let searchOptions = {
      selector: selector,
      limit: beaconPerPage,
      skip: beaconPagingCursor,      
    };

    db.explain(searchOptions)
      .then(function(explanation) {
        console.log('log ~ file: beaconService.js:1472 ~ getAllBeaconsPaged ~ explanation:', explanation);
       
        // view explanation
      })
      .catch(function(err) {
        console.log(err);
      });

    let result = await db.find(searchOptions);
    console.timeEnd("getAllBeaconsPaged::time");
    console.log("log: getAllBeaconsPaged -> beacons", result);
    return result;
  } catch (error) {
    console.log("log: getAllBeaconsPaged -> error", error);
  }
}

export async function getBeaconHistory(beaconId) {
  try {
    db = dbService();
    let result = await db.get(beaconId, {
      revs: true,
      revs_info: true
    });
    console.log("log: getBeaconHistory -> result", result);
    let docWithRevs = result._revs_info.map((revInfo) => {
      return {
        id: beaconId,
        rev: revInfo.rev
      };
    });
    console.log("log: getBeaconHistory -> docWithRevs", docWithRevs);
    let fullHistory = await db.bulkGet({ docs: docWithRevs });
    console.log("log: getBeaconHistory -> fullHistory", fullHistory);
    let onlyBeacons = [];

    for (let rev of fullHistory.results) {
      if (rev.docs[0].ok) {
        onlyBeacons.push(rev.docs[0].ok);
      }
    }

    return onlyBeacons;
  } catch (error) {
    console.log("log: getBeaconHistory -> error", error);
  }
}

export const BeaconModel = {
  hexId: "REQUIRED",
  beaconType: "REQUIRED",
  countryName: "",
  beaconCountryCode: "REQUIRED",
  beaconManufacturer: "",
  beaconManufacturerOther: "",
  serialNumber: "",
  beaconModel: "",
  typeApprovalCertificate: "",
  beaconHomingDevice: "",
  beaconHomingDeviceOther: "",
  beaconActivationMethod: "",
  initialDate: "",
  lastEditDate: "",
  specialStatus: "",
  specialStatusInfo: "",
  specialStatusDate: "",
  additionalBeaconData: "",
  lastConfirmationDate: "",
  type: "beacon",
  vehicleType: "REQUIRED for ELT and EPIRB",
  vehicleTypeOther: "",
  vehicleRegistrationNumber: "REQUIRED for ELT and EPIRB",
  homeICAOCode: "",
  aircraftManufacturer: "",
  aircraftModel: "",
  aircraftColor: "",
  aircraftOperatingAgency: "",
  aircraftOperatingAgencyPhone: "",
  radioEquipment: "",
  radioEquipmentOther: "",
  deployableSurvivalCrafts: "",
  fixedSurvivalCrafts: "",
  maxEndurance: 0,
  cruiseAirSpeed: 0,
  length: 0,
  wingSpan: 0,
  peopleCapacity: 0,
  aircraft24BitAddress: "",
  aircraft24BitAddressDecoded: "",
  vehicleNationality: "",
  additionalComment: "",
  vehicleName: "",
  vehicleModel: "",
  homePort: "",
  vehicleColor: "",
  nbLifeBoat: 0,
  nbLifeRaft: 0,
  callSign: "",
  callSignDecoded: "",
  aisNumber: "",
  Imarsat: "",
  vehicleCellularNum: "",
  vehicleSatellitePhone: "",
  MMSI: "",
  MMSIDecoded: "",
  equippedWithDataRecord: "",
  usageMoreInfo: "",
  usageMoreInfoOther: "",
  ownerName: "REQUIRED",
  emailAddress: "REQUIRED for ELT",
  address: "",
  mailCode: "",
  city: "",
  province: "",
  mailCountry: "",
  medicalInfo: "",
  operatorLanguage: "",
  phone1Num: "REQUIRED",
  phone1Type: "REQUIRED",
  phone1TypeOther: "",
  phone2Num: "",
  phone2Type: "",
  phone2TypeOther: "",
  phone3Num: "",
  phone3Type: "",
  phone3TypeOther: "",
  phone4Num: "",
  phone4Type: "",
  phone4TypeOther: "",
  primaryContactName: "REQUIRED",
  primaryContactAddressLine1: "",
  primaryContactAddressLine2: "",
  primaryPhone1Num: "REQUIRED",
  primaryPhone1Type: "REQUIRED",
  primaryPhone1TypeOther: "",
  primaryPhone2Num: "",
  primaryPhone2Type: "",
  primaryPhone2TypeOther: "",
  primaryPhone3Num: "",
  primaryPhone3Type: "",
  primaryPhone3TypeOther: "",
  primaryPhone4Num: "",
  primaryPhone4Type: "",
  primaryPhone4TypeOther: "",
  alternateContactName: "",
  alternateContactAddressLine1: "",
  alternateContactAddressLine2: "",
  alternatePhone1Num: "",
  alternatePhone1Type: "",
  alternatePhone1TypeOther: "",
  alternatePhone2Num: "",
  alternatePhone2Type: "",
  alternatePhone2TypeOther: "",
  alternatePhone3Num: "",
  alternatePhone3Type: "",
  alternatePhone3TypeOther: "",
  alternatePhone4Num: "",
  alternatePhone4Type: "",
  alternatePhone4TypeOther: ""
};

export function validateBeacon(beacon) {
  if (beacon.beaconType.includes("ELT")) {
    return ELTbeaconSchema.validate(beacon);
  }
  if (beacon.beaconType.includes("PLB")) {
    return PLBbeaconSchema.validate(beacon);
  }
  if (beacon.beaconType.includes("EPIRB")) {
    return EPIRBbeaconSchema.validate(beacon);
  }
}

const phoneRegExp = /^[\+\d]+((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const ELTbeaconSchema = Yup.object().shape({
  hexId: Yup.string()
    .required("Hex ID is required!")
    .matches(/^[0-9A-Fa-f]{1,64}$/, {
      //regex for valid HEX code.
      message: "Please enter only valid hexadecimal characters A-F, 0-9"
    })
    .test("len", "Please enter only valid hexadecimal characters A-F, 0-9", function(val) {
      if (val && (val.length === 15 || val.length === 23)) return true;
      else return false;
    }),
  ownerName: Yup.string().required("Beacon Owner Name is required"),
  dontHaveEmail: Yup.boolean(),
  emailAddress: Yup.string()
    .notRequired()
    .email("Email format is invalid"),
  specialStatus: Yup.string()
    .notRequired()
    .oneOf(["CANCELLED", "LOST", "REPLACED", "SOLD", "STOLEN", "ACTIVE", "RELEASETOMAINTENANCE", "OUTOFSERVICE", ""], 'specialStatus must be "CANCELLED" or "LOST" or "REPLACED" or "SOLD" or "STOLEN" or "ACTIVE" or "RELEASETOMAINTENANCE" or "OUTOFSERVICE"'),
  phone1Num: Yup.string().required("Beacon Owner Phone Number is required"),
  phone1Type: Yup.string()
    .required("Beacon Owner Phone Number Type is required")
    .oneOf(["CELL", "FAX", "HOME", "OTHR", "WORK"], 'phone1Type must be "CELL" or "FAX" or "HOME" or "OTHR" or "WORK"'),

  phone2Num: Yup.string().notRequired(),
  phone3Num: Yup.string().notRequired(),
  phone4Num: Yup.string().notRequired(),
  primaryContactName: Yup.string().required("Emergency Contact name is required"),
  primaryPhone1Num: Yup.string().required("Emergency Contact phone number is required"),
  primaryPhone1Type: Yup.string()
    .required("Emergency Contact phone type is required")
    .oneOf(["CELL", "FAX", "HOME", "OTHR", "WORK"], 'primaryPhone1Type  must be "CELL" or "FAX" or "HOME" or "OTHR" or "WORK"'),

  primaryPhone2Num: Yup.string().notRequired(),
  primaryPhone3Num: Yup.string().notRequired(),
  primaryPhone4Num: Yup.string().notRequired(),
  alternateContactName: Yup.string(),
  alternatePhone1Num: Yup.string().when("alternateContactName", {
    is: (value) => value,
    then: Yup.string().required("Alternate Contact phone number is required"),
    otherwise: Yup.string().notRequired()
  }),
  alternatePhone1Type: Yup.string().when("alternatePhone1Num", {
    is: (value) => value,
    then: Yup.string()
      .required('Alternate Contact phone number type (alternatePhone1Type) is required, one of "CELL", "FAX", "HOME", "OTHR", "WORK"')
      .oneOf(["CELL", "FAX", "HOME", "OTHR", "WORK"], 'alternatePhone1Type must be "CELL" or "FAX" or "HOME" or "OTHR" or "WORK"')
  }),
  alternatePhone2Num: Yup.string().notRequired(),
  alternatePhone3Num: Yup.string().notRequired(),
  alternatePhone4Num: Yup.string().notRequired(),
  vehicleType: Yup.string()
    .required("Vehicle Type is required")
    .oneOf(["Helicopter", "Multiple Engine Jet", "Multiple Engine Propeller", "Other", "Single Engine Jet", "Single Engine Propeller"], 'ELT Vehicle type must be "Helicopter" or "Multiple Engine Jet" or "Multiple Engine Propeller" or "Other" or "Single Engine Jet" or "Single Engine Propeller"'),
  beaconHomingDevice: Yup.string().notRequired(),
  //.oneOf(["121.5MHz", "SART", "Other", "None"], 'beaconHomingDevice must be "121.5_MHz" or "SART" or  "Other" or "None"'),

  maxEndurance: Yup.number().min(0, "Length Overall (m) must be greater than or equal to 0"),
  cruiseAirSpeed: Yup.number().min(0, "Length Overall (m) must be greater than or equal to 0"),
  length: Yup.number().min(0, "Length Overall (m) must be greater than or equal to 0"),
  wingSpan: Yup.number().min(0, "Length Overall (m) must be greater than or equal to 0"),
  peopleCapacity: Yup.number().min(0, "Capacity (Crew and Passengers) must be greater than or equal to 0"),
  vehicleNationality: Yup.number()
    .notRequired()
    .min(200, "vehicleNationality must be an MID value between 200 and 775")
    .max(775, "vehicleNationality must be an MID value between 200 and 775"),

  aircraft24BitAddress: Yup.string()
    .notRequired()
    .matches(/^[0-9A-Fa-f]{1,64}$/, {
      message: "Aircraft 24 Bit Address must be valid 6 hexadecimal characters A-F, 0-9"
    })
    .test("len", "Aircraft 24 Bit Address must be valid 6 hexadecimal characters A-F, 0-9", function(val) {
      if ((val && val.length === 6) || !val) {
        return true;
      } else {
        return false;
      }
    })
});

export const EPIRBbeaconSchema = Yup.object().shape({
  hexId: Yup.string()
    .required("Hex ID is required!")
    .matches(/^[0-9A-Fa-f]{1,64}$/, {
      //regex for valid HEX code.
      message: "Please enter only valid hexadecimal characters A-F, 0-9"
    })
    .test("len", "Please enter only valid hexadecimal characters A-F, 0-9", function(val) {
      if (val && (val.length === 15 || val.length === 23)) return true;
      else return false;
    }),
  ownerName: Yup.string().required("Beacon Owner Name is required"),
  dontHaveEmail: Yup.boolean(),
  emailAddress: Yup.string()
    .notRequired()
    .email("Email format is invalid"),
  specialStatus: Yup.string()
    .notRequired()
    .oneOf(["CANCELLED", "LOST", "REPLACED", "SOLD", "STOLEN", "ACTIVE", "RELEASETOMAINTENANCE", "OUTOFSERVICE", ""], 'specialStatus must be "CANCELLED" or "LOST" or "REPLACED" or "SOLD" or "STOLEN" or "ACTIVE" or "RELEASETOMAINTENANCE" or "OUTOFSERVICE"'),
  phone1Num: Yup.string().required("Beacon Owner Phone Number is required"),
  phone1Type: Yup.string()
    .required("Beacon Owner Phone Number Type is required")
    .oneOf(["CELL", "FAX", "HOME", "OTHR", "WORK"], 'phone1Type must be "CELL" or "FAX" or "HOME" or "OTHR" or "WORK"'),

  phone2Num: Yup.string().notRequired(),
  phone3Num: Yup.string().notRequired(),
  phone4Num: Yup.string().notRequired(),
  primaryContactName: Yup.string().required("Emergency Contact name is required"),
  primaryPhone1Num: Yup.string().required("Emergency Contact phone number is required"),
  primaryPhone1Type: Yup.string()
    .required("Emergency Contact phone type is required")
    .oneOf(["CELL", "FAX", "HOME", "OTHR", "WORK"], 'primaryPhone1Type must be "CELL" or "FAX" or "HOME" or "OTHR" or "WORK"'),
  primaryPhone2Num: Yup.string().notRequired(),
  primaryPhone3Num: Yup.string().notRequired(),
  primaryPhone4Num: Yup.string().notRequired(),
  alternateContactName: Yup.string(),
  alternatePhone1Num: Yup.string().when("alternateContactName", {
    is: (value) => value,
    then: Yup.string().required("Alternate Contact phone number is required"),
    otherwise: Yup.string().notRequired()
  }),
  alternatePhone1Type: Yup.string().when("alternatePhone1Num", {
    is: (value) => value,
    then: Yup.string()
      .required('Alternate Contact phone number type (alternatePhone1Type) is required, one of "CELL", "FAX", "HOME", "OTHR", "WORK"')
      .oneOf(["CELL", "FAX", "HOME", "OTHR", "WORK"], 'alternatePhone1Type must be "CELL" or "FAX" or "HOME" or "OTHR" or "WORK"')
  }),
  alternatePhone2Num: Yup.string().notRequired(),
  alternatePhone3Num: Yup.string().notRequired(),
  alternatePhone4Num: Yup.string().notRequired(),
  beaconHomingDevice: Yup.string().notRequired(),
  //.oneOf(["121.5MHz", "SART", "Other", "None"], 'beaconHomingDevice must be "121.5MHz" or "SART" or  "Other" or "None"'),
  equippedWithDataRecord: Yup.string()
    .notRequired()
    .oneOf(["Y", "N"], 'beaconHomingDevice must be "Y" or "N"'),

  nbLifeBoat: Yup.number().min(0, "Number of Life Boats must be greater than or equal to 0"),
  nbLifeRaft: Yup.number().min(0, "Number of Life Rafts must be greater than or equal to 0"),
  MMSIDecoded: Yup.string().notRequired(),
  MMSI: Yup.string()
    .notRequired()
    .matches(/^[0-9]{9}$/, {
      message: "MMSI Number must be 0-9, 9 digits"
    }),
  callSignDecoded: Yup.string().notRequired(),
  length: Yup.number()
    .notRequired()
    .min(0, "Length Overall (m) must be greater than or equal to 0"),
  peopleCapacity: Yup.number()
    .notRequired()
    .min(0, "Capacity (Crew and Passengers) must be greater than or equal to 0"),
  vehicleNationality: Yup.number()
    .notRequired()
    .min(200, "vehicleNationality must be an MID value between 200 and 775")
    .max(775, "vehicleNationality must be an MID value between 200 and 775")
});

export const PLBbeaconSchema = Yup.object().shape({
  hexId: Yup.string()
    .required("Hex ID is required!")
    .matches(/^[0-9A-Fa-f]{1,64}$/, {
      //regex for valid HEX code.
      message: "Please enter only valid hexadecimal characters A-F, 0-9"
    })
    .test("len", "Please enter only valid hexadecimal characters A-F, 0-9", function(val) {
      if (val && (val.length === 15 || val.length === 23)) return true;
      else return false;
    }),
  ownerName: Yup.string().required("Beacon Owner Name is required"),
  dontHaveEmail: Yup.boolean(),
  emailAddress: Yup.string()
    .notRequired()
    .email("Email format is invalid"),
  specialStatus: Yup.string()
    .notRequired()
    .oneOf(["CANCELLED", "LOST", "REPLACED", "SOLD", "STOLEN", "ACTIVE", "RELEASETOMAINTENANCE", "OUTOFSERVICE", ""], 'specialStatus must be "CANCELLED" or "LOST" or "REPLACED" or "SOLD" or "STOLEN" or "ACTIVE" or "RELEASETOMAINTENANCE" or "OUTOFSERVICE"'),
  phone1Num: Yup.string().required("Beacon Owner Phone Number is required"),
  phone1Type: Yup.string()
    .required("Beacon Owner Phone Number Type is required")
    .oneOf(["CELL", "FAX", "HOME", "OTHR", "WORK"], 'phone1Type must be "CELL" or "FAX" or "HOME" or "OTHR" or "WORK"'),

  phone2Num: Yup.string().notRequired(),
  phone3Num: Yup.string().notRequired(),
  phone4Num: Yup.string().notRequired(),
  primaryContactName: Yup.string().required("Emergency Contact name is required"),
  primaryPhone1Num: Yup.string().required("Emergency Contact phone number is required"),
  primaryPhone1Type: Yup.string()
    .required("Emergency Contact phone type is required")
    .oneOf(["CELL", "FAX", "HOME", "OTHR", "WORK"], 'primaryPhone1Type must be "CELL" or "FAX" or "HOME" or "OTHR" or "WORK"'),
  primaryPhone2Num: Yup.string().notRequired(),
  primaryPhone3Num: Yup.string().notRequired(),
  primaryPhone4Num: Yup.string().notRequired(),
  alternateContactName: Yup.string(),
  alternatePhone1Num: Yup.string().when("alternateContactName", {
    is: (value) => value,
    then: Yup.string().required("Alternate Contact phone number is required"),
    otherwise: Yup.string().notRequired()
  }),
  alternatePhone1Type: Yup.string().when("alternateContactName", {
    is: (value) => value,
    then: Yup.string()
      .required('Alternate Contact phone number type (alternatePhone1Type) is required, one of "CELL", "FAX", "HOME", "OTHR", "WORK"')
      .oneOf(["CELL", "FAX", "HOME", "OTHR", "WORK"], 'alternatePhone1Type must be "CELL" or "FAX" or "HOME" or "OTHR" or "WORK"')
  }),
  beaconHomingDevice: Yup.string().notRequired(),
  //.oneOf(["121.5 MHz", "SART", "Other", "None"], 'beaconHomingDevice must be "121.5 MHz" or "SART" or  "Other" or "None"'),
  alternatePhone2Num: Yup.string().notRequired(),
  alternatePhone3Num: Yup.string().notRequired(),
  alternatePhone4Num: Yup.string().notRequired()
});
