import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import RouteState from './state/routeState';
import AppState from './state/appState'
import AppConfigState from './state/appConfigState';
import i18next from 'i18next';
import { observer } from "mobx-react";

import Home from './pages/home/home';

import Dashboard from './pages/dashboard/dashboard';
import Decode from './pages/beaconDecode/beaconDecode';
import BeaconReview from './pages/registerBeacon/beacon/beaconReviewDetails';
import Contact from './pages/contact/contact';
import RegisterActivity from './pages/registerActivity/registerActivityPage';
import AssociatedUser from './pages/associatedUser/associatedUserPage';
import RegisterVehicle from './pages/registerVehicle/registerVehiclePage';
import ContactUsComplete from './pages/contact/contactComplete';


import Loading from './pages/loading';
// import BeaconManufacturerList from './pages/dashboard/beaconManufacturer';
import PointOfContacts from './pages/dashboard/pointOfContacts';
import EmailTemplateForm from './pages/dashboard/admin/emailTemplateForm';
import RegisterBeacon from './pages/registerBeacon/registerBeaconPage';
import RegisterUser from './pages/registerUser/registerUserForm';
import RegisterUserConsent from './pages/registerUser/registerUserConsent';
import './App.css';
import ScrollToTop from './components/scrollToTop';
import Faq from './pages/faq/faq';
//Comments to trigger a commit
const MIDinfo = Loadable({
  loader: () => import('./pages/dashboard/admin/midInfo'),
  loading: Loading,
});

const POCAll = Loadable({
  loader: () => import('./pages/pocAll/pocAll'),
  loading: Loading,
});

const Disclaimer = Loadable({
  loader: () => import('./components/disclaimer'),
  loading: Loading,
});

const UserDisclaimer = Loadable({
  loader: () => import('./components/userDisclaimer'),
  loading: Loading,
});

const SARDisclaimer = Loadable({
  loader: () => import('./components/disclaimer-sar'),
  loading: Loading,
});

const ContactListPage = Loadable({
  loader: () => import('./pages/dashboard/admin/contactListPage'),
  loading: Loading,
});

const AdminUsers = Loadable({
  loader: () => import('./pages/dashboard/admin/users'),
  loading: Loading,
});

const ForgotPassword = Loadable({
  loader: () => import('./pages/forgotPassword/forgotPassword'),
  loading: Loading,
});

const EmailValidation = Loadable({
  loader: () => import('./pages/verification/emailValidation'),
  loading: Loading,
});

const NewBeacon = Loadable({
  loader: () => import('./pages/registerBeacon/newBeacon'),
  loading: Loading,
});

const Report = Loadable({
  loader: () => import('./pages/report/report'),
  loading: Loading,
});

const BulkUpload = Loadable({
  loader: () => import('./pages/bulkUpload/bulkUpload.js'),
  loading: Loading,
});

const PageContentEditor = Loadable({
  loader: () => import('./pages/dashboard/admin/pageContentEditor'),
  loading: Loading,
});

const AdminPhoneType = Loadable({
  loader: () => import('./pages/dashboard/admin/phoneType'),
  loading: Loading,
});
const AdminActivationMethod = Loadable({
  loader: () => import('./pages/dashboard/admin/activationMethod'),
  loading: Loading,
});
const AdminBeaconHomingDevice = Loadable({
  loader: () => import('./pages/dashboard/admin/beaconHomingDevice'),
  loading: Loading,
});
const AdminBeaconManufacturer = Loadable({
  loader: () => import('./pages/dashboard/admin/beaconManufacturer'),
  loading: Loading,
});
const AdminRadioCallSign = Loadable({
  loader: () => import('./pages/dashboard/admin/radioCallSign'),
  loading: Loading,
});
const AdminRadioEquipment = Loadable({
  loader: () => import('./pages/dashboard/admin/radioEquipment'),
  loading: Loading,
});
const AdminVehicleTypeELT = Loadable({
  loader: () => import('./pages/dashboard/admin/vehicleTypeELT'),
  loading: Loading,
});
const AdminVehicleTypeEPIRB = Loadable({
  loader: () => import('./pages/dashboard/admin/vehicleTypeEPIRB'),
  loading: Loading,
});
const AdminVehicleTypePLB = Loadable({
  loader: () => import('./pages/dashboard/admin/vehicleTypePLB'),
  loading: Loading,
});
const AdminPasswordChallengeEnglish = Loadable({
  loader: () => import('./pages/dashboard/admin/passwordChallengeEnglish'),
  loading: Loading,
});
const AdminPasswordChallengeFrench = Loadable({
  loader: () => import('./pages/dashboard/admin/passwordChallengeFrench'),
  loading: Loading,
});
const AdminPasswordChallengeRussian = Loadable({
  loader: () => import('./pages/dashboard/admin/passwordChallengeRussian'),
  loading: Loading,
});
const AdminPasswordChallengeSpanish = Loadable({
  loader: () => import('./pages/dashboard/admin/passwordChallengeSpanish'),
  loading: Loading,
});
const AdminImportInstructions = Loadable({
  loader: () => import('./pages/dashboard/admin/ImportInstructions'),
  loading: Loading,
});
const AdminTacManagement = Loadable({
  loader: () => import('./pages/dashboard/admin/tacManagement'),
  loading: Loading,
});
const TacFrontend = Loadable({
  loader: () => import('./pages/dashboard/admin/tacFrontend'),
  loading: Loading,
});

@observer
class App extends Component {
  render() {
    let currentLocale = AppState.currentLocale;
    return (
      <div className="App">
        <Router>
          <ScrollToTop>
            <RoutesWithRouter currentLocale={currentLocale} />
          </ScrollToTop>
        </Router>
      </div>);

  }}


class Routes extends Component {

  componentDidMount(){
    let { history } = this.props;
    RouteState.registerHistory(history);
    AppState.initApp();

    console.log('------> hostname: ', location.hostname)
  }


  render() {

    let { location } = this.props;
    let pathname = location.pathname;
    return (

      <Switch key={pathname} location={location}>
        <Route exact path="/" component={Home} />
       
        <Route exact path="/newBeacon" component={NewBeacon} />
        <Route exact path="/registerBeacon" component={RegisterBeacon} />
        <Route exact path="/reviewBeacon" component={BeaconReview} />
        <Route exact path="/registerUser" component={RegisterUser} />
        <Route exact path="/registerUserConsent" component={RegisterUserConsent} />
        <Route path="/dashboard/:tab?" component={Dashboard} />
        <Route exact path="/contact" component={Contact}/>
        <Route exact path="/contact/complete" component={ContactUsComplete}/>
         <Route exact path="/contacts-details-all" component={POCAll}/>
        <Route path="/decode" component={Decode} />
        <Route exact path="/registerActivity" component={RegisterActivity} />
        <Route exact path="/registerAssociatedUser" component={AssociatedUser} />
        <Route exact path="/registerVehicle" component={RegisterVehicle} />
        <Route exact path="/forgot-password/:token?" component={ForgotPassword} />
        <Route path="/report/:id?" component={Report} />
        <Route exact path="/point-of-contacts" component={PointOfContacts} />
        <Route path="/email-template/:id?" component={EmailTemplateForm} />
        <Route path="/verification/:token" component={EmailValidation} />
        <Route exact path="/mid-info" component={MIDinfo} />
        <Route exact path="/disclaimer" component={Disclaimer} />
        <Route exact path="/user-disclaimer" component={UserDisclaimer} />
        <Route exact path="/sar-disclaimer" component={SARDisclaimer} />
        <Route exact path="/users" component={AdminUsers} />
        <Route exact path="/bulkUpload" component={BulkUpload} />
        <Route exact path="/contact-list" component={ContactListPage} />
        <Route exact path="/page-content" component={PageContentEditor} />
        <Route exact path="/faq/:id?" component={Faq}/>

        <Route exact path="/phone-type" component={AdminPhoneType} />
        <Route exact path="/activation-method" component={AdminActivationMethod} />
        <Route exact path="/beacon-homing-device" component={AdminBeaconHomingDevice} />
        {/* <Route exact path="/beacon-manufacturer" component={BeaconManufacturerList} /> */}
        <Route exact path="/beacon-manufacturer" component={AdminBeaconManufacturer} />
        <Route exact path="/radio-call-sign" component={AdminRadioCallSign} />
        <Route exact path="/radio-equipment" component={AdminRadioEquipment} />
        <Route exact path="/vehicle-type-elt" component={AdminVehicleTypeELT} />
        <Route exact path="/vehicle-type-epirb" component={AdminVehicleTypeEPIRB} />
        <Route exact path="/vehicle-type-plb" component={AdminVehicleTypePLB} />
        <Route exact path="/password-challenge-english" component={AdminPasswordChallengeEnglish} />
        <Route exact path="/password-challenge-french" component={AdminPasswordChallengeFrench} />
        <Route exact path="/password-challenge-russian" component={AdminPasswordChallengeRussian} />
        <Route exact path="/password-challenge-spanish" component={AdminPasswordChallengeSpanish} />
        {/* <Route exact path="/password-challenge-english" component={AdminPasswordChallengeEnglish} />
        <Route exact path="/password-challenge-english" component={AdminPasswordChallengeEnglish} /> */}
        <Route exact path="/import-instructions" component={AdminImportInstructions} />
        <Route exact path="/tac-management" component={AdminTacManagement} />
        <Route exact path="/approved-tac" component={TacFrontend} />
         <Route path="*" component={Home} />
      </Switch>);

  }}


const RoutesWithRouter = withRouter(Routes);
//export default hot(module)(App);
export default App;