import i18n from "i18next";
import React, { Component, useEffect } from "react";
import * as Yup from "yup";
import { Form, Input, Button, Popup, Select, Header, Grid, Message, Transition } from "semantic-ui-react";
import RegistrationState from "../../../state/registrationState";
import withSemanticUIFormik from "../../../utils/formHelper";
import RouteState from "../../../state/routeState";
import UserState from "../../../state/userState";
import { FormErrorDisplay } from "../../../components/formErrorDisplay";
import AppConfigState from "../../../state/appConfigState";

// const options = [{ key: "m", text: "Male", value: "male" }, { key: "f", text: "Female", value: "female" }, { key: "o", text: "Other", value: "other" }];

// const VehicleTypeOption = [
//   { key: "Aircraft", text: "Aircraft", value: "Aircraft" },
//   { key: "Boat", text: "Boat", value: "Boat" },
//   { key: "Helicopter", text: "Helicopter", value: "Helicopter" },
//   { key: "Land Vehicle", text: "Land Vehicle", value: "Land Vehicle" },
//   { key: "None", text: "None", value: "None" },
//   { key: "Other", text: "Other", value: "Other" },
// ];

const specificUsageOptions = [{ key: "Fishing", text: "Fishing", value: "Fishing" }, { key: "Hiking", text: "Hiking", value: "Hiking" }, { key: "Hunting", text: "Hunting", value: "Hunting" }, { key: "Other", text: "Other", value: "Other" }];
let storedValues = {};
const InnerForm = (props) => {
  const { values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, isValid } = props;

  let hexHasError = touched.hex && errors.hex && errors.hex.length > 0;
  let vehicleTypeError = touched.vehicleType && errors.vehicleType && errors.vehicleType.length > 0;
  let canEditByStatus = props.beacon.specialStatus == "ACTIVE" || props.beacon.specialStatus == undefined || props.beacon.specialStatus == "RELEASETOMAINTENANCE";

  const VehicleTypeOption = AppConfigState.vehiculeTypePLB;

  if (UserState.notAllowToEditByRoleId.includes(UserState.currentUserProfile.roleId)) {
    canEditByStatus = false;
  }

  const saveAndClose = () => {
    if (!RegistrationState.isNewRegisterBeacon) {
      RegistrationState.setIsSaving(true);
      RegistrationState.updateBeaconFields(props.values);
      RegistrationState.updateBeacon().then(() => {
        RegistrationState.setIsBeaconSaved(true);
        props.onClose();
        RegistrationState.setIsSaving(false);
      });
    }
  };

  storedValues = values;
  useEffect(() => {
    if (!RegistrationState.isNewRegisterBeacon) {
      var topPosition = document.getElementById("editBeaconModal");
      topPosition.scrollIntoView();
    } else {
      window.scrollTo({ top: 0 });
    }

    return function cleanup() {
      if (!RegistrationState.isNewRegisterBeacon) {
        console.log("log: InnerForm -> cleanup -> ", storedValues);
        //RegistrationState.updateBeaconFields(storedValues);
      }
    };
  }, []);

  // scroll to error
  if (isSubmitting && Object.keys(errors).length > 0) {
    var errorBox = document.getElementById("scrollToError");
    errorBox.scrollIntoView();
  }

  const saveToStateBeforeHandleChange = function(e, data) {
    console.log("log: InnerForm -> saveToStateBeforeHandleChange -> name, value", data.name, data.value);
    RegistrationState.saveOnFieldChange(data.name, data.value);
    handleChange(e, data);
  };

  return (
    <Form id="mainForm" className="beaconLocation-form  animated fadeIn faster">
      <Header as="h2" icon textAlign="center">
        <Header.Content>{i18n.t("please-supply-additional-information-about-your-vehicle-if-applicable")}</Header.Content>
      </Header>
      <div id="scrollToError" />
      <FormErrorDisplay errors={errors} touched={touched} status={status} />
      {/* <div className="required-field-notation">* required fields</div> */}

      <Form.Group widths="equal">
        <Form.Field>
          <Form.Select fluid label={i18n.t("vehicle-type")} options={VehicleTypeOption} placeholder="Select" name="vehicleType" value={values.vehicleType} onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} disabled={!canEditByStatus} className={canEditByStatus ? "editing-select" : ""} />

          {values.vehicleType == "Other" ? <Form.Input fluid placeholder="" name="vehicleTypeOther" value={values.vehicleTypeOther} onChange={handleChange} onBlur={handleBlur} /> : (values.vehicleTypeOther = "")}
        </Form.Field>

        <Form.Field>
          <label>
            {i18n.t("specific-usage")}
            <Popup content={i18n.t("select-that-which-is-most-appropriate-to-the-way-the-beacon-will-be-specifically-used-if-other-is-selected-enter-a-description-in-the-box-below-other")} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
          </label>
          <Form.Select fluid options={specificUsageOptions} placeholder={i18n.t("select")} name="usageMoreInfo" value={values.usageMoreInfo} onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} disabled={!canEditByStatus} className={canEditByStatus ? "editing-select" : ""} />

          {values.usageMoreInfo == "Other" ? <Form.Input fluid placeholder="" name="usageMoreInfoOther" value={values.usageMoreInfoOther} onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} disabled={!canEditByStatus} className={canEditByStatus ? "editing-input" : ""} /> : (values.usageMoreInfoOther = "")}
        </Form.Field>
      </Form.Group>

      <div>
        <label>
          {i18n.t("additional-vehicle-usage-information")}
          <Popup content={i18n.t("additional-information-deemed-appropriate-or-helpful-to-search-and-rescue-personnel-regarding-general-beacon-usage")} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
        </label>
        <Form.TextArea placeholder="" name="additionalComment" value={values.additionalComment} onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} disabled={!canEditByStatus} className={canEditByStatus ? "editing-input" : ""} />
      </div>
      <div>
        <label>
          {i18n.t("sar-hazard")}
          <Popup content={i18n.t("sar-hazard-pop")} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
        </label>
        <Form.TextArea placeholder="" name="sarHazard" value={values.sarHazard} onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} disabled={!canEditByStatus} className={canEditByStatus ? "editing-input" : ""} />
      </div>

      {/* <Grid centered columns={3} stackable>
        <Grid.Column>
          <Button className="registerBeacon-submitButton" onClick={onBackClick}>
            Back
          </Button>
        </Grid.Column>
        <Grid.Column>
          <Button className="registerBeacon-submitButton" type="submit" primary onClick={handleSubmit}>
            Next
          </Button>
        </Grid.Column>
      </Grid> */}

      <div className="activityDetail-submitContainer">
        <Button className="registerBeacon-submitButton" onClick={onBackClick}>
          {i18n.t("back")}
        </Button>
        <Button className="registerBeacon-submitButton" type="submit" primary onClick={handleSubmit}>
          {i18n.t("next")}
        </Button>
      </div>
    </Form>
  );
};

function onBackClick() {
  RegistrationState.setPreviousStep();
}

const beaconLocationForm = withSemanticUIFormik({
  mapPropsToValues: ({ beacon }) => {
    if (!beacon) {
      beacon = {};
    }

    return {
      vehicleType: beacon.vehicleType || "",
      vehicleTypeOther: beacon.vehicleTypeOther || "",
      usageMoreInfo: beacon.usageMoreInfo || "",
      usageMoreInfoOther: beacon.usageMoreInfoOther || "",
      additionalComment: beacon.additionalComment || "",
      sarHazard: beacon.sarHazard || ""
    };
  },

  validationSchema: Yup.object().shape({
    // vehicleType: Yup.string().required('Vehicle Type is required'),
  }),

  handleSubmit: (values, { setSubmitting, isValid }) => {
    console.log("log: values", values, isValid);

    RegistrationState.updateBeaconFields(values);
    RegistrationState.setNextStep();
  }
})(InnerForm);

export default beaconLocationForm;
