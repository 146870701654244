import React, { Component } from 'react';
import { observer } from 'mobx-react';
import AppConfigState from '../state/appConfigState';
import { Container, Divider, Dimmer, Loader } from "semantic-ui-react";
import DashboardState from '../state/dashboardState';
import UserState from '../state/userState';

@observer
export default class NdpDetail extends Component {

    componentDidMount = async () => {
        const {beacon} = this.props
        // let username = beacon.createdByNdp ? (beacon.createdByNdp.includes('org.couchdb.user:') ? beacon.createdByNdp.split(':')[1] : beacon.createdByNdp): null;

        UserState.getUserProfileById(beacon.createdByNdp)
        // DashboardState.getTotalBeaconsByOwner({ownerUsername: [username]});
        // DashboardState.getTotalBeaconsByOwner({accountOwnerName: [beacon.accountOwnerName]});
    }

    render(){

        let accountDetail = UserState.accountDetail;

        return (
            <Container className="beaconReviewDetails-mainFormContainer animated fadeIn faster">
                {
                    !accountDetail || DashboardState.isLoading ?
                    <Dimmer active={DashboardState.isLoading} inverted>
                        <Loader>Loading</Loader>
                    </Dimmer>
                    :
                    <>
                        <div>
                        Name: <strong>{accountDetail.ownerName || ""}</strong>
                        </div>
                        <div>
                        Username: <strong>{(accountDetail._id.includes('org.couchdb.user:') ? accountDetail._id.split(':')[1] : accountDetail._id) || ""}</strong>
                        </div>
                        <div>
                        Email: <strong>{accountDetail.emailAddress || ""}</strong>
                        </div>
                        <div>
                        Address: <strong>{accountDetail.address || ""}</strong>
                        </div>
                        <div>
                        Zip/Postal Code: <strong>{accountDetail.mailCode || ""}</strong>
                        </div>
                        <div>
                        City: <strong>{accountDetail.city || ""}</strong>
                        </div>
                        <div>
                        State/Province: <strong>{accountDetail.province || ""}</strong>
                        </div>
                        <div>
                        Country: <strong>{accountDetail.mailCountry || ""}</strong>
                        </div>
                        {accountDetail.phone1Num && accountDetail.phone1Num != "" ? (
                        <div>
                            Telephone Number 1:{" "}
                            <strong>
                            {accountDetail.phone1Num}
                            {accountDetail.phone1Type ? " (" + phoneTypeToText(accountDetail.phone1Type, accountDetail.phone1TypeOther) + ")" : ""}
                            </strong>
                        </div>
                        ) : (
                        ""
                        )}
                        {accountDetail.phone2Num && accountDetail.phone2Num != "" ? (
                        <div>
                            Telephone Number 2:{" "}
                            <strong>
                            {accountDetail.phone2Num}
                            {accountDetail.phone2Type ? " (" + phoneTypeToText(accountDetail.phone2Type, accountDetail.phone2TypeOther) + ")" : ""}
                            </strong>
                        </div>
                        ) : (
                        ""
                        )}
                        {accountDetail.phone3Num && accountDetail.phone3Num != "" ? (
                        <div>
                            Telephone Number 3:{" "}
                            <strong>
                            {accountDetail.phone3Num}
                            {accountDetail.phone3Type ? " (" + phoneTypeToText(accountDetail.phone3Type, accountDetail.phone3TypeOther) + ")" : ""}
                            </strong>
                        </div>
                        ) : (
                        ""
                        )}
                        {accountDetail.phone4Num && accountDetail.phone4Num != "" ? (
                        <div>
                            Telephone Number 4:{" "}
                            <strong>
                            {accountDetail.phone4Num}
                            {accountDetail.phone4Type ? " (" + phoneTypeToText(accountDetail.phone4Type, accountDetail.phone4TypeOther) + ")" : ""}
                            </strong>
                        </div>
                        ) : (
                        ""
                        )}

                        <div>
                        Assigned MID's: <strong>{accountDetail.countryNumber || ""}</strong>
                        </div>


                        {/* <Divider />
                        <div>
                            <a className="beaconReviewDetails-Link" onClick={this.onViewAllBeaconsOfOwner} >
                                Account Owner <b>{accountDetail.ownerName || ''}</b> owns <b>{DashboardState.totalBeaconsForUser}</b> beacons. Click here to view all owned beacons.
                            </a>
                        </div> */}
                    </>
                }
            </Container>
        )
    }
}

function phoneTypeToText(id, otherType = "") {
    let phoneTypes = AppConfigState.phoneTypes;
    let item = phoneTypes.find((row) => row.value == id);
    if (!item) return "";
    if (item.value == "OTHR") {
      return item.text + " - " + otherType;
    }
    return item.text;
}