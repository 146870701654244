import React, { Component } from "react";
import "./footer.css";
import { Icon, Grid, Form, Modal, Button, Header, Message } from "semantic-ui-react";
import QrReader from "./qrReader/index";
import i18n from "i18next";
export default class QRInput extends Component {
  state = {
    showCamera: false,
    error: false,
  };

  componentDidMount() {
    if (this.props.value) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  openQrScanner = () => {
    this.setState({
      showCamera: true,
    });
  };

  closeQrScanner = () => {
    this.setState({
      showCamera: false,
    });
  };

  onQrScanned = (data) => {
    if (data) {
      console.log("log: QRInput -> onQrScanned -> data", data);
      this.props.onChange(null, {
        name: this.props.name,
        value: data,
        qrScanned: true,
      });
      this.closeQrScanner();
    }
  };

  onQrError = (error) => {
    console.log("log: QRInput -> onQrError -> data", error);
    this.setState({
      error: error.message,
    });
  };

  render() {
    const { onChange, onBlur, hexHasError, value, className, name, placeholder } = this.props;
    console.log("log: QRInput -> render -> value", value);
    const { showCamera, error } = this.state;

    let modalContent = <QrReader delay={300} onError={this.onQrError} onScan={this.onQrScanned} style={{ width: "100%" }} />;

    if (error) {
      modalContent = (
        <Message negative icon>
          <Icon name="exclamation triangle" />
          <Message.Content>
            <Message.Header>{i18n.t('were-sorry-we-could-not-access-your-camera')}</Message.Header>
            {error}
          </Message.Content>
        </Message>
      );
    }

    return (
      <>
        <Form.Input
          placeholder={placeholder}
          name={name}
          rows="2"
          value={value}
          className={className}
          onChange={onChange}
          onBlur={onBlur}
          error={hexHasError}
          action={<Button onClick={this.openQrScanner} icon="camera" />}
        />
        <Modal closeIcon open={showCamera} onClose={this.closeQrScanner} size="tiny">
          <Header icon="qrcode" content={i18n.t('qr-code-scanner')} />
          <Modal.Content>{modalContent}</Modal.Content>
        </Modal>
      </>
    );
  }
}
