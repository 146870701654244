import React, { Component } from "react";
import { Form, Button, Message, Header, Transition, Segment, Grid } from "semantic-ui-react";
import i18n from "i18next";
import AppState from "../state/appState";
const t = AppState.t;

export let MESSAGES = {
  SENT_VERIFICATION_SUCCESS: i18n.t("email-verification-sent-look-for-the-verification-email-in-your-inbox"),
  SENT_VERIFICATION_FAIL: i18n.t("cannot-send-verification-email-please-check-your-email-is-valid-or-contact-administrator"),
};

MESSAGES.SENT_VERIFICATION_SUCCESS = i18n.t("email-verification-sent-look-for-the-verification-email-in-your-inbox");
MESSAGES.SENT_VERIFICATION_FAIL = i18n.t("cannot-send-verification-email-please-check-your-email-is-valid-or-contact-administrator");

export const FormErrorDisplay = ({ errors, touched, status }) => {
  let errorList = [];
  Object.keys(errors).forEach((key) => {
    let value = errors[key];
    if (touched.hasOwnProperty(key) && value) {
      console.log('log ~ file: formErrorDisplay.js:20 ~ Object.keys ~ value:',key, value);
      errorList.push(<Message.Item key={value}>{value}</Message.Item>);
    }
  });

  if (status) {
    console.log("log ~ file: formErrorDisplay.js ~ line 19 ~ FormErrorDisplay ~ status", status);
    errorList.push(<Message.Item key={status}>{status}</Message.Item>);
  }

  return (
    <Transition.Group animation="fade down" duration={500}>
      {errorList.length > 0 && (
        <Message error>
          <Message.Header>{i18n.t("there-are-items-that-require-your-attention")}</Message.Header>
          <Message.List>{errorList}</Message.List>
        </Message>
      )}
    </Transition.Group>
  );
};

export const UserFormErrorDisplay = ({ errors, touched, status }) => {
  console.log("log ~ file: formErrorDisplay.js ~ line 36 ~ UserFormErrorDisplay ~ errors, touched, status", errors, touched, status);
  let errorList = [];
  Object.keys(errors).forEach((key) => {
    let value = errors[key];
    if (touched.hasOwnProperty(key)) {
      errorList.push(<Message.Item key={value}>{value}</Message.Item>);
    }
  });

  if (status) {
    errorList.push(<Message.Item key={status}>{status}</Message.Item>);
  }

  if (status === "User profile saved") {
    return (
      <Transition.Group animation="fade down" duration={500}>
        <Message success header={i18n.t("user-profile-saved")} content={i18n.t("your-profile-has-been-updated")} />
      </Transition.Group>
    );
  }

  return (
    <Transition.Group animation="fade down" duration={500}>
      {errorList.length > 0 && (
        <Message error>
          <Message.Header>{i18n.t("there-are-items-that-require-your-attention")}</Message.Header>
          <Message.List>{errorList}</Message.List>
        </Message>
      )}
    </Transition.Group>
  );
};

export const UserFormWithPasswordErrorDisplay = ({ errors, touched, status }) => {
  MESSAGES.SENT_VERIFICATION_SUCCESS = i18n.t("email-verification-sent-look-for-the-verification-email-in-your-inbox");
  MESSAGES.SENT_VERIFICATION_FAIL = i18n.t("cannot-send-verification-email-please-check-your-email-is-valid-or-contact-administrator");

  console.log("log ~ file: formErrorDisplay.js ~ line 69 ~ UserFormWithPasswordErrorDisplay ~ errors, touched, status", errors, touched, status);
  let errorList = [];
  Object.keys(errors).forEach((key) => {
    let value = errors[key];
    if (touched.hasOwnProperty(key)) {
      if (!(key == "passwordConfirm" || key == "password")) {
        errorList.push(<Message.Item key={value}>{value}</Message.Item>);
      }
    }
  });

  if (errors["password"] && errors["passwordConfirm"] && touched.hasOwnProperty("password") && touched.hasOwnProperty("passwordConfirm")) {
    errorList.push(<Message.Item key={errors["password"]}>{errors["password"]}</Message.Item>);
    errorList.push(<Message.Item key={errors["passwordConfirm"]}>{errors["passwordConfirm"]}</Message.Item>);
  } else if (errors["password"] && touched.hasOwnProperty("password")) {
    errorList.push(<Message.Item key={errors["password"]}>{errors["password"]}</Message.Item>);
  } else if (errors["passwordConfirm"] && touched.hasOwnProperty("passwordConfirm")) {
    errorList.push(<Message.Item key={errors["passwordConfirm"]}>{errors["passwordConfirm"]}</Message.Item>);
  }

  if (status) {
    errorList.push(<Message.Item key={status}>{status}</Message.Item>);
  }

  if (status === "User profile saved") {
    return (
      <Transition.Group animation="fade down" duration={500}>
        <Message success header={i18n.t("user-profile-saved")} content={i18n.t("your-profile-has-been-updated")} />
      </Transition.Group>
    );
  }

  if (status === MESSAGES.SENT_VERIFICATION_SUCCESS) {
    return (
      <Transition.Group animation="fade down" duration={500}>
        <Message success content={MESSAGES.SENT_VERIFICATION_SUCCESS} />
      </Transition.Group>
    );
  }

  return (
    <Transition.Group animation="fade down" duration={500}>
      {errorList.length > 0 && (
        <Message error>
          <Message.Header>{i18n.t("there-are-items-that-require-your-attention")}</Message.Header>
          <Message.List>{errorList}</Message.List>
        </Message>
      )}
    </Transition.Group>
  );
};
