import React, { Component } from "react";
import "./footer.css";
import { Icon, Grid } from "semantic-ui-react";
import RouteState from "../state/routeState";
import AppState from "../state/appState";
import i18n from "i18next";
import { observer } from "mobx-react";

@observer
export default class Footer extends Component {
  onLinkClick = (path) => {
    RouteState.setRoute(path);
  };

  onClickOffline() {
    let workOfflineText = i18n.t("work-offline");
    if (navigator.onLine) {
      AppState.switchOfflineOnline();
    }
  }

  render() {
    let isOnline = AppState.isOnline;

    let workOfflineText = i18n.t("work-offline");
    if (!isOnline && navigator.onLine) {
      workOfflineText = "Work Online";
    }

    return (
      <footer className="footer noprint">
        <div className="centered">
          <div>
            <a onClick={() => this.onLinkClick("/")}>{i18n.t("login-register")}</a>
            {" | "}
            <a onClick={() => this.onLinkClick("/faq")}>{i18n.t("help-and-faqs")}</a>
            {" | "}
            <a onClick={() => this.onLinkClick("/contact")}>{i18n.t("contact-us")}</a>
            {" | "}
            <a onClick={this.onClickOffline}>{workOfflineText}</a>
            {" | "}
            <Icon name="facebook" />
            {" | "}
            <a onClick={() => this.onLinkClick("/decode")}>{i18n.t("beacon-decode-lookup")}</a>
          </div>

          <div style={{ marginTop: "20px" }}>
            <p>{AppState.version}</p>
          </div>
        </div>
      </footer>
    );
  }
}
