import i18n from "i18next";
import React, { Component, useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Input, Button, Segment, Checkbox, Header, Grid } from "semantic-ui-react";
import RegistrationState from "../../../state/registrationState";

import withSemanticUIFormik from "../../../utils/formHelper";
import RouteState from "../../../state/routeState";
import { FormErrorDisplay } from "../../../components/formErrorDisplay";
import AppConfigState from "../../../state/appConfigState";

const statusOption = [
  { key: "af", value: "af", text: "Working" },
  { key: "ax", value: "ax", text: "Aland Islands" },
  { key: "al", value: "al", text: "Albania" },
  { key: "dz", value: "dz", text: "Algeria" },
  { key: "as", value: "as", text: "American Samoa" },
];

const options = [{ key: "m", text: "Male", value: "male" }, { key: "f", text: "Female", value: "female" }, { key: "o", text: "Other", value: "other" }];

const InnerForm = (props) => {
  const { values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, isValid } = props;

  let hexHasError = touched.hex && errors.hex && errors.hex.length > 0;

  let MidInfo = AppConfigState.config.MIDInfo.find((row) => row.Mid == RegistrationState.decodedBeacon.beaconCountryCode);
  let regulationUrl = MidInfo
    ? MidInfo.beaconRegulationUrl == "" || MidInfo.beaconRegulationUrl == undefined
      ? "https://www.cospas-sarsat.int/en/documents-pro/system-documents"
      : MidInfo.beaconRegulationUrl
    : "https://www.cospas-sarsat.int/en/documents-pro/system-documents";

  useEffect(() => {
    AppConfigState.getAppCOnfig().then((response) => {
      if (!response) return;

      MidInfo = AppConfigState.config.MIDInfo.find((row) => row.Mid == RegistrationState.decodedBeacon.beaconCountryCode);
      regulationUrl = MidInfo
        ? MidInfo.beaconRegulationUrl == "" || MidInfo.beaconRegulationUrl == undefined
          ? "https://www.cospas-sarsat.int/en/documents-pro/system-documents"
          : MidInfo.beaconRegulationUrl
        : "https://www.cospas-sarsat.int/en/documents-pro/system-documents";
    });
  });

  const saveAndClose = () => {
    if (!RegistrationState.isNewRegisterBeacon) {
      RegistrationState.setIsSaving(true);
      RegistrationState.updateBeaconFields(props.values);
      RegistrationState.updateBeacon().then(() => {
        RegistrationState.setIsBeaconSaved(true);
        props.onClose();
        RegistrationState.setIsSaving(false);
      });
    }
  };

  // scroll to error
  if (isSubmitting && Object.keys(errors).length > 0) {
    var errorBox = document.getElementById("mainForm");
    errorBox.scrollIntoView();
  }

  return (
    <Form id="mainForm" className="beaconLocation-form animated fadeIn faster">
      <FormErrorDisplay errors={errors} touched={touched} status={status} />
      <Header as="h2" icon textAlign="center">
        <Header.Content>{i18n.t('please-read-the-regulations')}</Header.Content>
      </Header>

      <div style={{ margin: "20px 0 40px 0", textAlign: "center" }}>
        <a href={regulationUrl} target="_blank">
          {regulationUrl}
        </a>
      </div>

      <Grid centered columns={3} stackable>
        <Grid.Column>
          <Button className="registerBeacon-submitButton" onClick={onBackClick}>
            {i18n.t('back')}
          </Button>
        </Grid.Column>
        <Grid.Column>
          <Button className="registerBeacon-submitButton" type="submit" primary onClick={handleSubmit}>
            {i18n.t('next')}
          </Button>
        </Grid.Column>
        {/* {
            RegistrationState.isNewRegisterBeacon ? ''
            :
            <Grid.Column>
              <Button className="registerBeacon-submitButton" primary onClick={saveAndClose} loading={RegistrationState.isSaving}>
                Save & Close
              </Button>
            </Grid.Column>
          } */}
      </Grid>
    </Form>
  );
};

function onBackClick() {
  RegistrationState.setBeaconPreviousStep();
}

const BeaconDetailsForm = withSemanticUIFormik({
  mapPropsToValues: () => ({
    agreeWithRegulations: false,
  }),
  validationSchema: Yup.object().shape({
    // agreeWithRegulations: Yup
    // .boolean()
    // .oneOf([true], 'Must Accept Terms and Conditions'),
  }),

  handleSubmit: (values, { setSubmitting, isValid }) => {
    console.log("log: values", values, isValid);
    RegistrationState.updateBeaconFields(values);
    RegistrationState.setBeaconNextStep();
  },
})(InnerForm);

export default BeaconDetailsForm;
