import React, { Component } from 'react';
import Footer from '../../components/footer';
import TopMenu from '../../components/topMenu';
import { Header, Checkbox, Segment, Button } from 'semantic-ui-react';
import RouteState from '../../state/routeState';
import RegistrationState from '../../state/registrationState';
import UserState from '../../state/userState';
import './registerUser.css';
import AppState from "../../state/appState";


export default class RegisterUserConsent extends Component {

	state = {
		agreed: false,
	}

	onCheckBoxChange = (e, { checked }) => {
		console.log('log: RegisterUserConsent -> onCheckBoxChange -> checked', checked);
		this.setState({
			agreed: checked,
		})
	}

	onComplete = () => {
		let { agreed } = this.state;
		if(agreed){
			RegistrationState.signUp()
			RouteState.setRoute("/newBeacon");
		}		
	}

	onClickBack = () => {
		RouteState.setRoute("/registerUser");
	}

	render() {
		let { agreed } = this.state;
		let beaconHex = RegistrationState.decodedBeacon.hexId;
		
		return (
			<div>
				<TopMenu />
				<ConsentForm onCheck={this.onCheckBoxChange} onBack={this.onClickBack} agreed={agreed} onComplete={this.onComplete} beaconHex={beaconHex} />
				<Footer />
			</div>
		)
	}
}

const ConsentForm = ({ onCheck, agreed, onComplete,beaconHex, onBack }) => {
	const t = AppState.t;
	return (
	<div className="registerUser-page">
		<Segment className='consent-mainContainer'>
			<Header as='h2'>{t('consent-to-the-international-beacon-registration-database-rules-and-regs')}</Header>
			<Header as='h2'>{t('beacon-type-elt-id')}{beaconHex}</Header>
			<Segment textAlign='justified' secondary className='consent-text'>
				<p>
					{t('elt_conscent_form')}
			</p>
			
			</Segment>
			<div className='consent-confirm'>
				<Checkbox label={t('i-agree')} onChange={onCheck} />
			</div>
			<div className='consent-actions'>
				<Button content={t('back')} secondary onClick={onBack} />
				<Button content={t('complete-your-cospass-sarsat-registration')} primary disabled={!agreed} onClick={onComplete} />
			</div>
		</Segment>
	</div>

)}

