import React, { Component } from "react";
import { observer } from "mobx-react";
import AppConfigState from "../state/appConfigState";
import { Container, Divider, Dimmer, Loader, Segment } from "semantic-ui-react";
import DashboardState from "../state/dashboardState";

@observer
export default class OwnerDetail extends Component {
  componentDidMount = async () => {
    const { beacon } = this.props;
    let username = beacon.owner ? (beacon.owner.includes("org.couchdb.user:") ? beacon.owner.split(":")[1] : beacon.owner) : null;
    // DashboardState.getTotalBeaconsByOwner({ ownerName: beacon.ownerName, ownerUsername: username });
    DashboardState.getTotalBeaconsByOwner({ ownerName: beacon.ownerName })

    // DashboardState.getTotalBeaconsForUser(beacon.owner);
    // console.log('>>>>>>>> total beacons: ', DashboardState.totalBeaconsForUser)
  };

  onViewAllBeaconsOfOwner = () => {
    const { beacon, onClose, onUpdateSearch } = this.props;
    let username = beacon.owner ? (beacon.owner.includes("org.couchdb.user:") ? beacon.owner.split(":")[1] : beacon.owner) : null;

    // DashboardState.getBeaconsByOwner({ ownerName: beacon.ownerName, ownerUsername: username });
    DashboardState.getBeaconsByOwner({ ownerName: beacon.ownerName })
    .then(data => {
      let _totalBeacon = DashboardState.totalBeaconsForUser
      DashboardState.setTotalBeacons(_totalBeacon)
    })
    DashboardState.setSpecialQuery(true)
    // let _totalBeacon = DashboardState.totalBeaconsForUser
    // console.log('------> total beacon: ', _totalBeacon)
    // DashboardState.setTotalBeacons(_totalBeacon)
    onClose();
    onUpdateSearch(beacon.ownerName);
    // onUpdateSearch(beacon.ownerName, beacon.accountOwnerName, username);
  };

  render() {
    const { beacon } = this.props;
    console.log('log ~ file: ownerDetail.js ~ line 40 ~ OwnerDetail ~ render ~ beacon', beacon);
    let username = beacon.owner ? (beacon.owner.includes('org.couchdb.user:') ? beacon.owner.split(':')[1] : beacon.owner): null;
    return (
      <>
        <Container className="beaconReviewDetails-mainFormContainer animated fadeIn faster">
          {
            <>
              <div>
                Name: <strong>{beacon.ownerName || ""}</strong>
              </div>
              <div>
                Email: <strong>{beacon.emailAddress || ""}</strong>
              </div>
              <div>
                Address: <strong>{beacon.address || ""}</strong>
              </div>
              <div>
                Zip/Postal Code: <strong>{beacon.mailCode || ""}</strong>
              </div>
              <div>
                City: <strong>{beacon.city || ""}</strong>
              </div>
              <div>
                State/Province: <strong>{beacon.province || ""}</strong>
              </div>
              <div>
                Country: <strong>{beacon.mailCountry || ""}</strong>
              </div>
              <div>
                Medical Information: <strong>{beacon.medicalInfo || ""}</strong>
              </div>
              <div>
                Communication Language: <strong>{beacon.operatorLanguage || ""}</strong>
              </div>
              {beacon.phone1Num && beacon.phone1Num != "" ? (
                <div>
                  Beacon Owner Telephone Number 1:{" "}
                  <strong>
                    {beacon.phone1Num}
                    {beacon.phone1Type ? " (" + phoneTypeToText(beacon.phone1Type, beacon.phone1TypeOther) + ")" : ""}
                  </strong>
                </div>
              ) : (
                ""
              )}
              {beacon.phone2Num && beacon.phone2Num != "" ? (
                <div>
                  Beacon Owner Telephone Number 2:{" "}
                  <strong>
                    {beacon.phone2Num}
                    {beacon.phone2Type ? " (" + phoneTypeToText(beacon.phone2Type, beacon.phone2TypeOther) + ")" : ""}
                  </strong>
                </div>
              ) : (
                ""
              )}
              {beacon.phone3Num && beacon.phone3Num != "" ? (
                <div>
                  Beacon Owner Telephone Number 3:{" "}
                  <strong>
                    {beacon.phone3Num}
                    {beacon.phone3Type ? " (" + phoneTypeToText(beacon.phone3Type, beacon.phone3TypeOther) + ")" : ""}
                  </strong>
                </div>
              ) : (
                ""
              )}
              {beacon.phone4Num && beacon.phone4Num != "" ? (
                <div>
                  Beacon Owner Telephone Number 4:{" "}
                  <strong>
                    {beacon.phone4Num}
                    {beacon.phone4Type ? " (" + phoneTypeToText(beacon.phone4Type, beacon.phone4TypeOther) + ")" : ""}
                  </strong>
                </div>
              ) : (
                ""
              )}
              <br />
              <div>
                24-Hour Emergency Contact Name: <strong>{beacon.primaryContactName}</strong>
              </div>
              <div>
                Emergency Contact Address Line 1: <strong>{beacon.primaryContactAddressLine1}</strong>
              </div>
              <div>
                Emergency Contact Address Line 2: <strong>{beacon.primaryContactAddressLine2}</strong>
              </div>
              {beacon.primaryPhone1Num && beacon.primaryPhone1Num != "" ? (
                <div>
                  Emergency Contact Phone Number 1:{" "}
                  <strong>
                    {beacon.primaryPhone1Num}
                    {beacon.primaryPhone1Type ? " (" + phoneTypeToText(beacon.primaryPhone1Type, beacon.primaryPhone1TypeOther) + ")" : ""}
                  </strong>
                </div>
              ) : (
                ""
              )}
              {beacon.primaryPhone2Num && beacon.primaryPhone2Num != "" ? (
                <div>
                  Emergency Contact Phone Number 2:{" "}
                  <strong>
                    {beacon.primaryPhone2Num}
                    {beacon.primaryPhone2Type ? " (" + phoneTypeToText(beacon.primaryPhone2Type, beacon.primaryPhone2TypeOther) + ")" : ""}
                  </strong>
                </div>
              ) : (
                ""
              )}
              {beacon.primaryPhone3Num && beacon.primaryPhone3Num != "" ? (
                <div>
                  Emergency Contact Phone Number 3:{" "}
                  <strong>
                    {beacon.primaryPhone3Num}
                    {beacon.primaryPhone3Type ? " (" + phoneTypeToText(beacon.primaryPhone3Type, beacon.primaryPhone3TypeOther) + ")" : ""}
                  </strong>
                </div>
              ) : (
                ""
              )}
              {beacon.primaryPhone4Num && beacon.primaryPhone4Num != "" ? (
                <div>
                  Emergency Contact Phone Number 4:{" "}
                  <strong>
                    {beacon.primaryPhone4Num}
                    {beacon.primaryPhone4Type ? " (" + phoneTypeToText(beacon.primaryPhone4Type, beacon.primaryPhone4TypeOther) + ")" : ""}
                  </strong>
                </div>
              ) : (
                ""
              )}
              <div>
                Alternate 24-Hour Emergency Contact Name: <strong>{beacon.alternateContactName}</strong>
              </div>
              <div>
                Alternate Contact Address Line 1: <strong>{beacon.alternateContactAddressLine1}</strong>
              </div>
              <div>
                Alternate Contact Address Line 2: <strong>{beacon.alternateContactAddressLine2}</strong>
              </div>
              {beacon.alternatePhone1Num && beacon.alternatePhone1Num != "" ? (
                <div>
                  Alternate Contact Phone Number 1:{" "}
                  <strong>
                    {beacon.alternatePhone1Num}
                    {beacon.alternatePhone1Type ? " (" + phoneTypeToText(beacon.alternatePhone1Type, beacon.alternatePhone1TypeOther) + ")" : ""}
                  </strong>
                </div>
              ) : (
                ""
              )}
              {beacon.alternatePhone2Num && beacon.alternatePhone2Num != "" ? (
                <div>
                  Alternate Contact Phone Number 2:{" "}
                  <strong>
                    {beacon.alternatePhone2Num}
                    {beacon.alternatePhone2Type ? " (" + phoneTypeToText(beacon.alternatePhone2Type, beacon.alternatePhone2TypeOther) + ")" : ""}
                  </strong>
                </div>
              ) : (
                ""
              )}
              {beacon.alternatePhone3Num && beacon.alternatePhone3Num != "" ? (
                <div>
                  Alternate Contact Phone Number 3:{" "}
                  <strong>
                    {beacon.alternatePhone3Num}
                    {beacon.alternatePhone3Type ? " (" + phoneTypeToText(beacon.alternatePhone3Type, beacon.alternatePhone3TypeOther) + ")" : ""}
                  </strong>
                </div>
              ) : (
                ""
              )}
              {beacon.alternatePhone4Num && beacon.alternatePhone4Num != "" ? (
                <div>
                  Alternate Contact Phone Number 4:{" "}
                  <strong>
                    {beacon.alternatePhone4Num}
                    {beacon.alternatePhone4Type ? " (" + phoneTypeToText(beacon.alternatePhone4Type, beacon.alternatePhone4TypeOther) + ")" : ""}
                  </strong>
                </div>
              ) : (
                ""
              )}

              {DashboardState.isLoading ? (
                  <Segment placeholder>
                <Dimmer active={DashboardState.isLoading} inverted>
                  <Loader>beacons registered by Account Owner </Loader>
                </Dimmer>
                 </Segment>
              ) : (
                <>
                  <Divider />
                  <div>
                    <a className="beaconReviewDetails-Link" onClick={this.onViewAllBeaconsOfOwner}>
                      Beacon Owner <b>{beacon.ownerName || ""}</b> has <b>{DashboardState.totalBeaconsForUser}</b> beacons registered by Account Owner{" "}
                      <b>{username || ""}</b>. Click here to view all registered beacons.
                    </a>
                  </div>
               </>
              )}
            </>
          }
        </Container>
      </>
    );
  }
}

function phoneTypeToText(id, otherType = "") {
  let phoneTypes = AppConfigState.phoneTypes;
  let item = phoneTypes.find((row) => row.value == id);
  if (!item) return "";
  if (item.value == "OTHR") {
    return item.text + " - " + otherType;
  }
  return item.text;
}
