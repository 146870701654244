import i18n from "i18next";
import React, { Component } from "react";
import * as Yup from "yup";
import { Form, Input, Button, Popup, Select, Header, Message, Grid, Dropdown, TextArea } from "semantic-ui-react";
import RegistrationState from "../../../state/registrationState";
import UserState from "../../../state/userState";
import AppState from "../../../state/appState"
import AppConfigState from "../../../state/appConfigState";
import withSemanticUIFormik from "../../../utils/formHelper";
import RouteState from "../../../state/routeState";
import decodeBeacon from "../../../utils/beaconDecode/gen2decode";
import { observer } from "mobx-react";
import { toJS } from "mobx";

// const HomingSignalOptions = [
//   { key: "None", text: "None", value: "None" },
//   { key: "121.5 MHz ", text: "121.5 MHz ", value: "121.5 MHz " },
//   { key: "SART", text: "SART", value: "SART" },
//   { key: "AIS", text: "AIS", value: "AIS" },
//   { key: "Other", text: "Other", value: "Other" },
// ];

@observer
class InnerForm extends Component {
  state = {
    requestEdid: false,
    beaconTypeFromInitial: "",
    maintenanceStatus: false,
    canEditStatus: true,
  };

  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    } else {
      RouteState.setRoute("/newBeacon");
    }
  };

  componentDidMount = () => {
    if (!RegistrationState.isNewRegisterBeacon) {
      var topPosition = document.getElementById("editBeaconModal");
      topPosition.scrollIntoView();
    } else {
      window.scrollTo({ top: 0 });
    }

    UserState.getMaintProviderUsers();
    this.setState({
      maintenanceStatus: this.props.values.specialStatus && this.props.values.specialStatus == "RELEASETOMAINTENANCE",
    });

    if (this.props.values.hex.length == 15 || this.props.values.hex.length == 23) {
      RegistrationState.decodedBeaconInfo(this.props.values.hex).then((beaconInfo) => {
        // console.log('>>> beaconInfo: ', beaconInfo)
        if (beaconInfo) {
          this.setState({ beaconTypeFromInitial: beaconInfo.beaconType });
        }
      });
    }
    console.log('------------> beaconInfo: ', toJS(this.props.beacon))
    this.setCanEditStatus(this.props.beacon);
  };

  // setCanEditStatus(decodedBeacon) {
  //   console.log("log: InnerForm -> setCanEditStatus -> decodedBeacon", decodedBeacon);

  //   let canEditByStatus = decodedBeacon.specialStatus == "ACTIVE" || decodedBeacon.specialStatus == undefined || decodedBeacon.specialStatus == "RELEASETOMAINTENANCE";

  //   if (UserState.currentUserProfile && UserState.notAllowToEditByRoleId.includes(UserState.currentUserProfile.roleId)) {
  //     canEditByStatus = false;
  //     this.setState({ canEditStatus: false });
  //   }
  //   this.setState({ requestEdid: canEditByStatus });
  // }

  setCanEditStatus(decodedBeacon) {
    let canEditByStatus = decodedBeacon.specialStatus == "ACTIVE" || decodedBeacon.specialStatus == undefined || decodedBeacon.specialStatus == "RELEASETOMAINTENANCE" || decodedBeacon.specialStatus == "" || decodedBeacon.specialStatus == 'undefined';
    if (UserState.currentUserProfile && UserState.notAllowToEditByRoleId.includes(UserState.currentUserProfile.roleId)) {
      canEditByStatus = false;
      this.setState({ canEditStatus: false });
    }
    this.setState({ requestEdid: canEditByStatus });
  }

  componentWillUnmount() {
    if (!RegistrationState.isNewRegisterBeacon) {
      console.log("log: InnerForm -> componentWillUnmount -> RegistrationState.isNewRegisterBeacon", RegistrationState.isNewRegisterBeacon);
      RegistrationState.updateBeaconFields(this.props.values);
    }
  }

  handleSpecialStatus = (e, data) => {
    this.props.setFieldValue(data.name, data.value);
    if (data.value == "RELEASETOMAINTENANCE") {
      this.setState({
        maintenanceStatus: true,
      });
    } else {
      this.setState({
        maintenanceStatus: false,
      });
    }
  };

  saveAndClose = () => {
    if (!RegistrationState.isNewRegisterBeacon) {
      RegistrationState.setIsSaving(true);
      // console.log('>>>> save & close', this.props.values)
      RegistrationState.updateBeaconFields(this.props.values);
      RegistrationState.updateBeacon().then(() => {
        RegistrationState.setIsBeaconSaved(true);
        this.props.onClose();
        RegistrationState.setIsSaving(false);
      });
    }
  };

  render() {
    const { values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, isValid, onClose } = this.props;
    const { requestEdid, canEditStatus } = this.state;
    const hexHasError = touched.hex && errors.hex && errors.hex.length > 0;
    const statusOptions = AppConfigState.beaconStatusOption;

    let manufacturerOption = AppConfigState.manufacturerOption;
    let maintProviderOptions = UserState.maintProviderOptions;
    let roleId = UserState.currentUserProfile ? UserState.currentUserProfile.roleId : null;
    let HomingSignalOptions = AppConfigState.homingDeviceOption;
    let currentLocale = AppState.currentLocale;

    const activationMethodOptions = AppConfigState.beaconActivationMethod;

    let MidInfo = AppConfigState.config ? AppConfigState.config.MIDInfo.find((row) => row.Mid == RegistrationState.decodedBeacon.beaconCountryCode) : null;
    let regulationUrl = MidInfo && MidInfo.beaconRegulationUrl ? MidInfo.beaconRegulationUrl : null;

    console.log("BeaconDetailForm::Render:: values.beaconManufacturer ", values.beaconManufacturer, values.beaconManufacturerOther)
   

    const saveToStateBeforeHandleChange = function(e, data) {
      if(data.name === 'hex'){
        data.value.toUpperCase()
      }
      console.log("log: InnerForm -> saveToStateBeforeHandleChange -> name, value", data.name, data.value);
      RegistrationState.saveOnFieldChange(data.name, data.value);
      handleChange(e, data);
    };

    return (
      <Form className="beaconDetail-form animated fadeIn ">
        <Header as="h3" icon textAlign="center">
          <Header.Content> {i18n.t('to-register-a-beacon-you-must-complete-the-beacon-details-below-verify-the-owner-details-provide-emergency-contact-information-supply-vehicle-information-review-your-beacon-summary')}</Header.Content>
          <Header.Subheader>
           
          </Header.Subheader>
        </Header>

         <Header as='h4' >{i18n.t('prepopulated-information-has-been-decoded-from-the-hex-id-or-tac')}</Header>
         
        <Form.Field inline>
          <label>
            {i18n.t('beacon-type')}
            {/* <Popup content="Help!" trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} /> */}
          </label>
          <Input
            placeholder={i18n.t('beacon-type')}
            name="beaconType"
            value={values.beaconType}
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            className={(this.state.beaconTypeFromInitial == "" || this.state.beaconTypeFromInitial === undefined) && requestEdid ? "editing-input" : ""}
            disabled={!((this.state.beaconTypeFromInitial == "" || this.state.beaconTypeFromInitial === undefined) && requestEdid)}
          />
        </Form.Field>

        <Form.Field inline>
          <label>
           {i18n.t('hex-id')}{" "}
            <Popup
              content={i18n.t('hexadecimal-0-9-and-a-f-beacon-identification-code')}
              trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
            />
          </label>
          <Input placeholder={i18n.t('hex-id-0')} name="hex" onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} value={values.hex} disabled={true} />
        </Form.Field>

        <Form.Field inline>
          <label>{i18n.t('country')}</label>
          <Input placeholder="" name="countryName" onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} value={values.countryName} disabled={true} />
        </Form.Field>

        <Form.Field inline>
          <label>
            {i18n.t('serial-number')}
            <Popup content="The beacon’s serial number." trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
          </label>
          <Input placeholder={i18n.t('serial-number')} name="serialNumber" onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} value={values.serialNumber} disabled={true} />
        </Form.Field>

        <Form.Field inline>
          <label>
            {i18n.t('c-s-type-approval-number')}{" "}
            <Popup
              content={i18n.t('the-type-approval-number-assigned-by-cospas-sarsat-for-this-beacon-manufacturer-and-model')}
              trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
            />
          </label>
          <Input
            placeholder={i18n.t('c-s-type-approval-number')}
            name="typeApprovalCertificate"
            onChange={saveToStateBeforeHandleChange}
            value={values.typeApprovalCertificate}
            onBlur={handleBlur}
            disabled={true}
          />
        </Form.Field>
        <Header as='h4'>{i18n.t('complete-the-information-below-about-your-beacon')}</Header>
        <Form.Field inline>
          <label>
            {i18n.t('manufacturer')}{" "}
            <Popup
              content={i18n.t('name-of-company-that-manufactured-the-beacon-only-entered-if-can-not-be-decoded-from-beacon-id')}
              trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
            />
          </label>

          <Select
            placeholder={i18n.t('manufacturer')}
            name="beaconManufacturer"
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            value={values.beaconManufacturer}
            options={manufacturerOption}
            disabled={!requestEdid}
            className={"beaconDetailform-selectInput " + (requestEdid ? "editing-select" : "")}
          />
        </Form.Field>

        {values.beaconManufacturer == "Other" ? (
          <Form.Field inline>
            <label />
            <Input
              placeholder=""
              name="beaconManufacturerOther"
              value={values.beaconManufacturerOther}
              onChange={saveToStateBeforeHandleChange}
              onBlur={handleBlur}
              disabled={!requestEdid}
              className={requestEdid ? "editing-input" : ""}
            />
          </Form.Field>
        ) : (
          (values.beaconManufacturerOther = "")
        )}

        <Form.Field inline>
          <label>
            {i18n.t('model-name')}
            {/* <Popup content="Manufacturer's Model Number for the beacon. Only entered if cannot be decoded from Beacon ID." trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} /> */}
          </label>
          <Input
            placeholder={i18n.t('model-name')}
            name="beaconModel"
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            value={values.beaconModel}
            disabled={!requestEdid}
            className={requestEdid ? "editing-input" : ""}
          />
        </Form.Field>

        {RegistrationState.isNewRegisterBeacon ? (
          ""
        ) : (
          <Form.Field inline>
            <label>{i18n.t('select-beacon-status')}</label>
            <Select
              placeholder={i18n.t('select')}
              name="specialStatus"
              value={values.specialStatus}
              options={statusOptions}
              onChange={this.handleSpecialStatus}
              onBlur={handleBlur}
              disabled={!canEditStatus}
              className={"beaconDetailform-selectInput" + (canEditStatus ? " editing-select" : "")}
            />
          </Form.Field>
        )}
        {["1", "3"].includes(roleId) && ( // for admin & NDP
          <Form.Field inline>
            <label>{i18n.t('special-status-info')}</label>
            <Input
              placeholder={i18n.t('special-status-info')}
              name="specialStatusInfo"
              value={values.specialStatusInfo}
              onChange={saveToStateBeforeHandleChange}
              onBlur={handleBlur}
              disabled={!canEditStatus}
              className={canEditStatus ? "editing-input" : ""}
            />
          </Form.Field>
        )}
        {this.state.maintenanceStatus && (
          <Form.Field inline>
            <label>{i18n.t('maintenance-provider')}</label>
            <Select
              placeholder={i18n.t('select')}
              className="beaconDetailform-selectInput editing-select"
              name="maintProvider"
              value={values.maintProvider}
              options={maintProviderOptions}
              onChange={saveToStateBeforeHandleChange}
              onBlur={handleBlur}
              disabled={!canEditStatus}
              className={"beaconDetailform-selectInput" + (canEditStatus ? " editing-select" : "")}
            />
          </Form.Field>
        )}
        {roleId == "7" && (
          <Form.Field inline style={{ textAlign: "center" }}>
            <span style={{ color: "red" }}>{i18n.t('warning-once-you-change-beacon-status-or-maintenance-provider-you-will-no-longer-have-access-to-view-or-edit-the-beacon-record')}</span>
          </Form.Field>
        )}

        {/* 2nd tab */}
        <Form.Field inline>
          <label>
            {i18n.t('beacon-homing-device')}{" "}
            <Popup
              content={i18n.t('the-type-of-homing-signal-or-device-used-by-the-beacon-selected-from-pull-down-menu')}
              trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
            />
          </label>
          <Select
            name="beaconHomingDevice"
            options={HomingSignalOptions}
            value={values.beaconHomingDevice}
            placeholder={i18n.t('select')}
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            disabled={!requestEdid}
            className={"beaconDetailform-selectInput" + (requestEdid ? " editing-select" : "")}
          />
        </Form.Field>

        {values.beaconHomingDevice == "Other" ? (
          <Form.Field inline>
            <label />
            <Input
              placeholder=""
              name="beaconHomingDeviceOther"
              value={values.beaconHomingDeviceOther}
              onChange={saveToStateBeforeHandleChange}
              onBlur={handleBlur}
              disabled={!requestEdid}
              className={requestEdid ? "editing-input" : ""}
            />
          </Form.Field>
        ) : (
          (values.beaconHomingDeviceOther = "")
        )}

        <Form.Field inline>
          <label>
            {i18n.t('activation-method')}{" "}
            <Popup
              content={i18n.t('applies-to-method-for-the-activation-of-the-beacon-automatic-manual-or-manual-only')}
              trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
            />
          </label>
          <Select
            name="beaconActivationMethod"
            options={activationMethodOptions}
            value={values.beaconActivationMethod}
            placeholder="Select"
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            disabled={!requestEdid}
            className={"beaconDetailform-selectInput" + (requestEdid ? " editing-select" : "")}
          />
        </Form.Field>

        <Form.Field inline>
          <label>
            {i18n.t('additional-beacon-information')}{" "}
            <Popup
              content={i18n.t('any-additional-information-regarding-the-beacon-specifically-that-may-be-useful-to-search-and-rescue-personnel')}
              trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
            />
          </label>
          <TextArea
            name="additionalBeaconData"
            label=""
            value={values.additionalBeaconData}
            placeholder=""
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            disabled={!requestEdid}
            className={requestEdid ? "beaconDetailform-textarea" : ""}
          />
        </Form.Field>

        {regulationUrl && (
          <div style={{ margin: "20px 0 40px 0", textAlign: "center" }}>
            <b>{i18n.t('please-read-the-regulations')} </b>
            <div>
              <a href={regulationUrl} target="_blank">
                {i18n.t('click-here-to-download-regulations')}
              </a>
            </div>
          </div>
        )}

        {/* <Grid centered columns={2} >
          <Grid.Column >
            <Button className="registerBeacon-submitButton" onClick={this.onClose}>
              Back
            </Button>
          </Grid.Column>
          <Grid.Column>
            <Button className="registerBeacon-submitButton" type="submit" primary onClick={handleSubmit}>
              Next
            </Button>
          </Grid.Column>
        </Grid> */}

        <div className="activityDetail-submitContainer">
          <Button className="registerBeacon-submitButton" onClick={this.onClose}>
            {i18n.t('back')}
          </Button>
          <Button className="registerBeacon-submitButton" type="submit" primary onClick={handleSubmit}>
            {i18n.t('next')}
          </Button>
        </div>
      </Form>
    );
  }
}

const BeaconDetailsForm = withSemanticUIFormik({
  mapPropsToValues: ({ decodedBeacon }) => {
    if (decodedBeacon) {
      // console.log('log: decodedBeacon', JSON.stringify(decodedBeacon));
      return {
        beaconType: decodedBeacon.beaconType || "",
        hex: decodedBeacon.hexId.toUpperCase() || "",
        beaconManufacturer: decodedBeacon.beaconManufacturer || "",
        beaconManufacturerOther: decodedBeacon.beaconManufacturerOther || "",
        serialNumber: decodedBeacon.serialNumber || "",
        beaconModel: decodedBeacon.beaconModel || "",
        typeApprovalCertificate: decodedBeacon.typeApprovalCertificate || "",
        specialStatus: decodedBeacon.specialStatus || "ACTIVE",
        specialStatusInfo: decodedBeacon.specialStatusInfo || "",
        maintProvider: decodedBeacon.maintProvider || "",
        countryName: decodedBeacon.countryName || "",

        beaconHomingDevice: decodedBeacon.beaconHomingDevice || "",
        beaconActivationMethod: decodedBeacon.beaconActivationMethod || "",
        additionalBeaconData: decodedBeacon.additionalBeaconData || "",
        beaconHomingDeviceOther: decodedBeacon.beaconHomingDeviceOther || "",
      };
    } else {
      return {
        beaconType: "",
        hex: " ",
        beaconManufacturer: "",
        beaconManufacturerOther: "",
        modelName: "",
        serialNumber: "",
        beaconModel: "",
        typeApprovalCertificate: "",
        specialStatus: "",
        specialStatusInfo: "",
        maintProvider: "",
        countryName: "",

        beaconHomingDevice: "",
        beaconActivationMethod: "",
        additionalBeaconData: "",
        beaconHomingDeviceOther: "",
      };
    }
  },
  validationSchema: Yup.object().shape({
    hex: Yup.string()
      .required("Hex ID is required!")
      .matches(/^[0-9A-Fa-f]{1,64}$/, {
        //regex for valid HEX code.
        message:i18n.t('please-enter-only-valid-hexadecimal-characters-a-f-0-9'),
      })
      .test("len", i18n.t('please-enter-only-valid-hexadecimal-characters-a-f-0-9'), function(val) {
        if (val && (val.length === 15 || val.length === 23)) return true;
        else return false;
      }),
  }),

  handleSubmit: (values, { setSubmitting, isValid, props }) => {
    console.log("BeaconDetailForm: handleSubmit values", values, props);
    //should prevent edit beacon type and hex(should come from decoded)
    // values.beaconType = (props.decodedBeacon.beaconType == '' || props.decodedBeacon.beaconType === undefined) ? values.beaconType : props.decodedBeacon.beaconType
    values.hex = props.beacon.hexId.toUpperCase();
    // values.countryName = props.decodedBeacon.countryName
    RegistrationState.updateBeaconFields(values);
    RegistrationState.setNextStep();
  },
})(InnerForm);

export default BeaconDetailsForm;
