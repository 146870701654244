import React, { Component } from 'react';
import { Form, Input, Button, Popup, Select, Header, Message, Icon } from 'semantic-ui-react';
import RouteState from '../../../state/routeState';
import ActivityState from '../../../state/activityState';
import i18n from "i18next";
export default class ActivityComplete extends Component {

	returnToDashboardClick = () => {
		if(ActivityState.isAdminEditing){
			RouteState.setRoute('/dashboard/activities');
			ActivityState.resetSteps();
			ActivityState.setAdminActivityView(false)
			ActivityState.setIsAdminEditing(false);
		}
		else{
			ActivityState.resetSteps();
			ActivityState.setIsEditActivity(false);
			RouteState.setRoute('/dashboard/activities');
		}
	}

	addNewActivityClick = ()=>{
		ActivityState.resetSteps();
		ActivityState.resetData();
		ActivityState.setIsEditActivity(false);
		RouteState.setRoute('/registerActivity');
	}

	render() {
		return (
			<div>
				<Header as='h2' icon textAlign='center'>
					<Icon name='check' circular color="green" />
					<Header.Content>{i18n.t('registration-complete')}</Header.Content>
				</Header>
				<div className="activityDetail-submitContainer">
						<Button className="registerBeacon-submitButton" onClick={this.returnToDashboardClick} >{i18n.t('return-to-dashboard')}</Button>
						<Button className="registerBeacon-submitButton" type="submit" primary onClick={this.addNewActivityClick}>{i18n.t('register-a-new-activity')}</Button>
					</div>
			</div>
		)
	}
}