import dbService from "./dbService";
import { insertOrUpdateDocument } from "./serviceUtils";

export async function initIndexes() {
  try {
    let createIndexMenufacturer = await createIndexBeaconMenufacturer();
    console.log("ReportService -> createIndexMenufacturer -> result: " + JSON.stringify(createIndexMenufacturer));

    let createIndexStatus = await createIndexSpecialStatus();
    console.log("ReportService -> createIndexStatus -> result: " + JSON.stringify(createIndexStatus));

    let createCountryCodeIndex = await createCountryCodeReportIndex();
    console.log("ReportService -> createCountryCodeIndex -> result: " + JSON.stringify(createCountryCodeIndex));

    let createOperatorLangIndex = await createOperatorLanguageReportIndex();
    console.log("ReportService -> createOperatorLangIndex -> result: " + JSON.stringify(createOperatorLangIndex));
  } catch (err) {
    console.log("ReportService -> initIndexes -> err: " + err);
  }
}

// Beacon Menufacturer Report
async function createIndexBeaconMenufacturer() {
  let db = dbService();
  var ddoc = {
    _id: "_design/beaconMenufacturerReport",
    views: {
      beaconMenufacturerReport: {
        map: function(doc) {
          // if (doc.initialDate && doc.beaconType && doc.beaconManufacturer && doc.beaconManufacturer != "0") {
          if (doc.initialDate && doc.beaconType && doc.beaconManufacturer != "0") {
            // var yearAndMonth = doc.initialDate.substr(0, 7); // 2013-05-22 02:06:26.000 -> 2013-05

            var d, yearAndMonth
            if(typeof(doc.initialDate) == 'number'){
              let createDate = new Date(doc.initialDate)
              let year = createDate.getFullYear()
              let month = ("0" + (createDate.getMonth() + 1)).slice(-2)
              let day = ("0" + createDate.getDate()).slice(-2)
              let hours = ("0" + createDate.getHours()).slice(-2)
              let minutes = ("0" + createDate.getMinutes()).slice(-2)
              let seconds = ("0" + createDate.getSeconds()).slice(-2)

              d = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
              yearAndMonth = d.substr(0, 10);
            }
            else{
              yearAndMonth = doc.initialDate.substr(0, 10);
            }

            var beaconType = "";
            var beaconTypeSplt = doc.beaconType.split(" ");
            if (beaconTypeSplt.length > 1) {
              beaconType = beaconTypeSplt[0]; // PLB SERIAL (STANDARD) -> PLB
            } else {
              beaconType = doc.beaconType; //Case where only the type is without added info (never found one actualyl)
            }
            var beaconManufacturer = doc.beaconManufacturer; // it is id
            // emit(yearAndMonth + "-" + beaconManufacturer + "-" + beaconType);
            emit(yearAndMonth + "==" + beaconManufacturer + "==" + beaconType);
          }
        }.toString(),

        reduce: "_count"
      }
    }
  };

  // save the design doc
  try {
    try {
      return await insertOrUpdateDocument(ddoc, db); // if need to update index too!
    } catch (err) {
      if (err.name !== "conflict") {
        throw err;
      }
      // ignore if doc already exists
    }
  } catch (err) {
    console.log('log: createIndexBeaconMenufacturer -> err', err);

  }
}

export async function getBeaconManufacturerReport(startDate, endDate) {
  let db = dbService();
  try {
    var result = await db.query("beaconMenufacturerReport", {
      group: true,
      include_docs: false,
      startkey: startDate,
      endkey: endDate + "\uffff",
      inclusive_end: false
    });
    // console.log('===============> report data > beaconMenufacturerReport: ' + JSON.stringify(result))
    return result;
  } catch (err) {
    console.log(err);
  }
}

// Special Status Report
async function createIndexSpecialStatus() {
  let db = dbService();
  var ddoc = {
    _id: "_design/specialStatusReport",
    views: {
      specialStatusReport: {
        map: function(doc) {
        //   if (doc.initialDate && doc.specialStatus) {
		    if (doc.initialDate) {
            //var yearAndMonth = doc.initialDate.substr(0, 7); // 2013-05-22 02:06:26.000 -> 2013-05

            // var yearAndMonth = d.substr(0, 7);
            var d, yearAndMonth
            if(typeof(doc.initialDate) == 'number'){
              let createDate = new Date(doc.initialDate)
              let year = createDate.getFullYear()
              let month = ("0" + (createDate.getMonth() + 1)).slice(-2)
              let day = ("0" + createDate.getDate()).slice(-2)
              let hours = ("0" + createDate.getHours()).slice(-2)
              let minutes = ("0" + createDate.getMinutes()).slice(-2)
              let seconds = ("0" + createDate.getSeconds()).slice(-2)

              d = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
              yearAndMonth = d.substr(0, 10);
            }
            else{
              yearAndMonth = doc.initialDate.substr(0, 10);
            }

            var beaconStatus = doc.specialStatus;
            emit(yearAndMonth + "-" + beaconStatus);
          }
        }.toString(),

        reduce: "_count"
      }
    }
  };

  // save the design doc
  try {
    try {
      await insertOrUpdateDocument(ddoc, db); // if need to update index too!
    } catch (err) {
      if (err.name !== "conflict") {
        throw err;
      }
      // ignore if doc already exists
    }

    // var result = await db.query("specialStatusReport", {
    //   group: true,
    //   include_docs: false
    // });
    // console.log('log: createIndexSpecialStatus -> result', result);
	// return result;

  } catch (err) {
    console.log('log: createIndexSpecialStatus -> err', err);
  }
}

export async function getSpecialStatusReport(startDate, endDate) {
  let db = dbService();
  try {
    var result = await db.query("specialStatusReport", {
      group: true,
      include_docs: false,
      startkey: startDate,
      endkey: endDate + "\uffff",
      inclusive_end: false
    });
    // console.log('===============> report data > getSpecialStatusReport: ' + JSON.stringify(result))
    return result;
  } catch (err) {
    console.log('log: getSpecialStatusReport -> err', err);
  }
}

// Country code report
async function createCountryCodeReportIndex() {
  let db = dbService();
  var ddoc = {
    _id: "_design/countryCodeReport",
    views: {
      countryCodeReport: {
        map: function(doc) {
        //   if (doc.initialDate && doc.beaconCountryCode && doc.beaconType) {
			if (doc.initialDate && doc.beaconCountryCode) {
        // var yearAndMonth = doc.initialDate.substr(0, 7); // 2013-05-22 02:06:26.000 -> 2013-05

        var d, yearAndMonth
        if(typeof(doc.initialDate) == 'number'){
          let createDate = new Date(doc.initialDate)
          let year = createDate.getFullYear()
          let month = ("0" + (createDate.getMonth() + 1)).slice(-2)
          let day = ("0" + createDate.getDate()).slice(-2)
          let hours = ("0" + createDate.getHours()).slice(-2)
          let minutes = ("0" + createDate.getMinutes()).slice(-2)
          let seconds = ("0" + createDate.getSeconds()).slice(-2)

          d = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
          yearAndMonth = d.substr(0, 10);
        }
        else{
          yearAndMonth = doc.initialDate.substr(0, 10);
        }

				var countryCode = doc.beaconCountryCode;

        var beaconType = "";
        var beaconStatus = doc.specialStatus ? doc.specialStatus : "ACTIVE";
				var beaconTypeSplt = doc.beaconType.split(" ");
				if (beaconTypeSplt.length > 1) {
          beaconType = beaconTypeSplt[0]; // PLB SERIAL (STANDARD) -> PLB
				} else {
				  beaconType = doc.beaconType; //Case where only the type is without added info (never found one actualyl)
				}
        var actualBeaconType = "unknown"
        if (doc.beaconType.indexOf("EPIRB") !== -1) {
          actualBeaconType = "EPIRB";
        } else if (doc.beaconType.indexOf("ELT") !== -1) {
          actualBeaconType = "ELT";
        } else if (doc.beaconType.indexOf("PLB") !== -1) {
          actualBeaconType = "PLB";
        }   

				emit(yearAndMonth + "-" + countryCode + "-" + actualBeaconType + "-" + beaconStatus);
      }
        }.toString(),

        reduce: "_count"
      }
    }
  };

  // save the design doc
  try {
    try {
      return await insertOrUpdateDocument(ddoc, db); // if need to update index use thi line
    } catch (err) {
      if (err.name !== "conflict") {
        throw err;
      }
      // ignore if doc already exists
    }
  } catch (err) {
    console.log('log: createCountryCodeReportIndex -> err', err);
  }
}

export async function getCountryCodeReport(startDate, endDate) {
  let db = dbService();
  try {
    var result = await db.query("countryCodeReport", {
      group: true,
      include_docs: false,
      startkey: startDate,
      endkey: endDate + "\uffff",
      inclusive_end: false
    });
    // console.log("===============> report data > getCountryCodeReport: " + JSON.stringify(result));
    return result;
  } catch (err) {
    console.log('log: getCountryCodeReport -> err', err);
  }
}

// Operator language report
async function createOperatorLanguageReportIndex() {
  let db = dbService();
  var ddoc = {
    _id: "_design/operatorLanguageReport",
    views: {
      operatorLanguageReport: {
        map: function(doc) {
			// if (doc.initialDate && doc.operatorLanguage) {
			if (doc.initialDate) {
        // var yearAndMonth = doc.initialDate.substr(0, 7); // 2013-05-22 02:06:26.000 -> 2013-05

        var d, yearAndMonth
        if(typeof(doc.initialDate) == 'number'){
          let createDate = new Date(doc.initialDate)
          let year = createDate.getFullYear()
          let month = ("0" + (createDate.getMonth() + 1)).slice(-2)
          let day = ("0" + createDate.getDate()).slice(-2)
          let hours = ("0" + createDate.getHours()).slice(-2)
          let minutes = ("0" + createDate.getMinutes()).slice(-2)
          let seconds = ("0" + createDate.getSeconds()).slice(-2)

          d = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
          yearAndMonth = d.substr(0, 10);
        }
        else{
          yearAndMonth = doc.initialDate.substr(0, 10);
        }

				var operatorLanguage = doc.operatorLanguage;

				emit(yearAndMonth + "-" + operatorLanguage);
			}
        }.toString(),

        reduce: "_count"
      }
    }
  };

  // save the design doc
  try {
    try {
      return await insertOrUpdateDocument(ddoc, db); // if need to update index use thi line
    } catch (err) {
      if (err.name !== "conflict") {
        throw err;
      }
      // ignore if doc already exists
    }
  } catch (err) {
    console.log(err);
  }
}

export async function getOperatorLanguageReport(startDate, endDate) {
  let db = dbService();
  try {
    var result = await db.query("operatorLanguageReport", {
      group: true,
      include_docs: false,
      startkey: startDate,
      endkey: endDate + "\uffff",
      inclusive_end: false
    });
    // console.log("===============> report data > getoperatorLanguageReport: " + JSON.stringify(result));
    return result;
  } catch (err) {
    console.log(err);
  }
}
