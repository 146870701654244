import { action, observable, computed, toJS } from "mobx";
import * as BeaconService from "../services/beaconService";
import * as ActivityService from "../services/activityService";
import * as AssociatedUserService from "../services/associatedUserService";
import * as VehicleService from "../services/vehicleService";
import * as UserService from "../services/userServices";
import * as AccessLogService from "../services/accessLogService";
import * as ReportService from "../services/reportService";
import * as PocService from "../services/pocService";
import dbService, { initDbConnections, getUserProfileDb, offlineIndexExists, replicateDb } from "../services/dbService";
import { createReminderConfirmationToken, executeReminderConfirmationToken, createDelegateBeaconToken, createValidateEmailToken } from "../services/authActionService";
import AppConfigState from "./appConfigState";
import DevState from "./devState";
import UserState from "./userState";
import PocState from "./pocState";
import { testMail } from "../services/mailService";
import { insertOrUpdateDocument } from "../services/serviceUtils";

class appState {
  @observable appLoaded = false;
  @observable currentLanguage = "en-US";
  @observable isOnline = navigator.onLine;
  version = "2.0.0 build-5022";   
  @observable currentLocale = "en";
  @observable userBlockedForMaintenance = false;
  t;

  async initApp() {
    try {
      //testLambdaFindBeacon();
      await AppConfigState.getClientConfig();
      initDbConnections(); 
      //this.checkOfflineDbIndexes();
      await AppConfigState.getAppCOnfig();
      //let hasSession = await UserState.checkForSession(); 
      //console.log("log: appState -> initApp -> hasSession", hasSession);
      //DevState.initDevTools();
      this.setAppLoadedState(true);
      this.initDbIndexes();
      //testMail();
      this.updateOnlineStatus();
      window.addEventListener("online", this.updateOnlineStatus);
      window.addEventListener("offline", this.updateOnlineStatus); 
      //testCreateUser();
      
    } catch (error) {
      console.log("log: appState -> initApp -> error", error);
    }
  }

  initDbIndexes() {
    let db = dbService();
    db.info().then((info) => {
      console.log("log: appState -> initDbIndexes -> info", info);
      ReportService.initIndexes();
      return;
      BeaconService.initIndexes();
     
      ActivityService.initIndexes();
      AssociatedUserService.initIndexes();
      VehicleService.initIndexes();
      AccessLogService.initIndexes();
      
      PocService.initIndexes();
    });
  }

  async checkOfflineDbIndexes() {
    let exists = await offlineIndexExists();
    //console.log("log ~ file: appState.js ~ line 63 ~ appState ~ checkOfflineDbIndexes ~ exists", exists);
    if (!exists) {
      //BeaconService.initIndexesOffline();
    }
  }

  @action setAppLoadedState(loaded) {
    this.appLoaded = loaded;
  }

  @action setLanguage(value) {
    console.log("log ~ file: appState.js ~ line 67 ~ appState ~ @actionsetLanguage ~ value", value);
    this.currentLocale = value;
    window.localStorage.setItem("i18nextLng", value);
  }

  @action updateOnlineStatus() {
    this.isOnline = navigator.onLine;
    if (this.isOnline && UserState.currentUserProfile) {
      console.warn("log ~ file: appState.js ~ line 86 ~ appState ~ @actionupdateOnlineStatus ~ UserState.currentUserProfile REPLICATE", UserState.currentUserProfile._id);
      replicateDb();
    }
  }

  @action switchOfflineOnline() {
    this.isOnline = !this.isOnline;
  }

  @action setUserBlockedForMaintenance(blocked) {
    console.log("log ~ file: appState.js ~ line 95 ~ appState ~ @actionsetUserBlockedForMaintenance ~ blocked", blocked);
    this.userBlockedForMaintenance = blocked;
  }
}

const singleton = new appState(); //export class as singleton to be used through the application.
export default singleton;

async function testGetBeaconHistory() {
  let history = await BeaconService.getBeaconHistory("beacon_212E959230FFBFF");
  console.log("log: testGetBeaconHistory -> history", history);
}

async function testAuthAction() {
  let token = await createReminderConfirmationToken("beacon_19A4B8E0C0FFBFZ");
  console.log("log: testAuthAction -> token", token);
  let executeResponse = await executeReminderConfirmationToken(token);
  console.log("log: testAuthAction -> executeResponse", executeResponse);
}

async function testLambdaFindBeacon() {
  const adminSearchTest = {
    username: "admindevuser",
    password: "devUserPassword",
    searchParams: {
      hexId: "a709054C34D34D2",
    },
  };
  let resultFindUsername1 = await UserService.lambdaFindBeacon(adminSearchTest);
  console.log('log ~ file: appState.js:124 ~ testLambdaFindBeacon ~ resultFindUsername1', resultFindUsername1);
   const userSearchTest = {
    username: "rdemarco",
    password: "Changeyourpassword123",
  };
  let resultFindUsername2 = await UserService.lambdaFindBeacon(userSearchTest);
  console.log('log ~ file: appState.js:124 ~ testLambdaFindBeacon ~ resultFindUsername2', resultFindUsername2);
}

async function testCreateUser() {
  try {
    let user1 = {
      password: "98df9s8dfshf9w37",
      username: "testuserlambda8",
      roleId: "5",
      emailAddress: "test@mail.com",
      ownerName: "test user",
    };
    let user1Update = {
      password: "devUserPassword",
    };
    let resultFindUsername1 = await UserService.lambdaFindUsername("19A4657342FFBFF");
    console.log("testCreateUser: lambdaFindUsername -> result rdemarco1969@gmail.com", resultFindUsername1);
    let resultGetChallengeQuestion2 = await UserService.findUserAndCreateResetPasswordToken("william.thivierge@gmail.com");
    console.log("log: testCreateUser -> resultGetChallengeQuestion2", resultGetChallengeQuestion2);
    let resultResetPassword = await UserService.lambdaResetpassword("org.couchdb.user:devuser2", user1Update);
    console.log("testCreateUser: lambdaResetpassword -> resultResetPassword", resultResetPassword);

    let resultSignup = await UserService.lambdaSignup(user1);
    console.log("testCreateUser: testCreateUser -> resultSignup", resultSignup);

    let resultDeleteUser = await UserService.lambdaDeleteUser(resultSignup.userId);
    console.log("testCreateUser: lambdaDeleteUser -> resultDeleteUser", resultDeleteUser);

    //let resultGetChallengeQuestion = await UserService.lambdaGetChallengeQuestion("ndpDevUser2");
    //console.log('log: testCreateUser -> resultGetChallengeQuestion', resultGetChallengeQuestion);

    //et resultGetChallengeQuestion3 = await UserService.lambdaGetChallengeQuestion("william.thivierge@gmail.com");
    //console.log('log: testCreateUser -> resultGetChallengeQuestion3', resultGetChallengeQuestion3);

    let resultFindUsername2 = await UserService.lambdaFindUsername("ACA9C65C34D34D1");
    console.log("testCreateUser: lambdaFindUsername -> result ACA9C65C34D34D1", resultFindUsername2);

    let resultFindUsername3 = await UserService.lambdaFindUsername("ShouldNotReturnAnything");
    console.log("testCreateUser: lambdaFindUsername -> result ShouldNotReturnAnything", resultFindUsername3);
  } catch (error) {
    console.log("testCreateUser: testCreateUser -> error", error);
  }
}

async function testCreateDelegateToken() {
  let token = await createDelegateBeaconToken("beacon_1785E847E0FFB12"); //beacon_id
  console.log("test create delegate token -> ", token);
}
async function testCreateValidateEmailToken() {
  let token = await createValidateEmailToken("org.couchdb.user:paula-dev"); //user_id
  console.log("test create validate email token -> ", token);
}

async function testMapReduce() {
  console.log("log: testMapReduce ");
  let db = dbService();
  var ddoc = {
    _id: "_design/newRegistrationReport",
    views: {
      newRegistrationReport: {
        map: function(doc) {
          if (doc.initialDate && doc.beaconType) {
            var year = doc.initialDate.substr(0, 4); // 2013-05-22 02:06:26.000 -> 2013-05
            var Month = doc.initialDate.substr(5, 2); // 2013-05-22 02:06:26.000 -> 2013-05
            var beaconType = "";
            var beaconTypeSplt = doc.beaconType.split(" ");
            if (beaconTypeSplt.length > 1) {
              beaconType = beaconTypeSplt[0]; // PLB SERIAL (STANDARD) -> PLB
            } else {
              beaconType = doc.beaconType; //Case where only the type is without added info (never found one actualyl)
            }
            emit([year, Month, beaconType]);
          }
        }.toString(),
        reduce: "_count",
      },
    },
  };
  db.put(ddoc)
    .catch(function(err) {
      if (err.name !== "conflict") {
        throw err;
      }
      // ignore if doc already exists
    })
    .then(function() {
      // find docs where title === 'Lisa Says'
      return db.query("newRegistrationReport", {
        include_docs: false,
        group: true,
      });
    })
    .then(function(result) {
      console.log("log: testMapReduce -> result", result);
      // handle result
    })
    .catch(function(err) {
      console.log(err);
    });
}

async function testWebHook(url = "") {
  // Default options are marked with *
  //curl -d "scope=sdpodapi&authtoken=cea8587608f624f5474fae63041e9b95&OPERATION_NAME=ADD_REQUEST&INPUT_DATA={operation:{Details:{REQUESTER:Chris Cullen, SUBJECT: Subject of the request,  REQUESTTEMPLATE:Default Request, PRIORITY:High, URGENCY:High,  LEVEL :Tier 1, IMPACT:Affects Business, UDF_CHAR2:PLB, DESCRIPTION:Mail Server is down}}}"
  let data = {
    scope: "sdpodapi",
    authtoken: "cea8587608f624f5474fae63041e9b95",
    OPERATION_NAME: "ADD_REQUEST",
    INPUT_DATA: {
      operation: {
        Details: {
          REQUESTER: "William Thivierge",
          SUBJECT: "testing web hook",
          REQUESTTEMPLATE: "Default Request",
          PRIORITY: "Low",
          URGENCY: "Low",
          LEVEL: "Tier 1",
          IMPACT: "Affects Business",
          UDF_CHAR2: "PLB",
          DESCRIPTION: "testing web hook",
        },
      },
    },
  };
  const response = await fetch(
    "https://sdpondemand.manageengine.com/api/json/request?scope=sdpodapi&authtoken=cea8587608f624f5474fae63041e9b95&OPERATION_NAME=ADD_REQUEST&INPUT_DATA={operation:{Details:{REQUESTER:Chris Cullen, SUBJECT: Subject of the request,  REQUESTTEMPLATE:Default Request, PRIORITY:High, URGENCY:High,  LEVEL :Tier 1, IMPACT:Affects Business, DESCRIPTION:Mail Server is down}}}",
    {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *client
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    }
  );
  let jsonResponse = await response.json(); // parses JSON response into native JavaScript objects
  console.log("log: testWebHook -> jsonResponse", jsonResponse);
}

async function createPocOptions() {
  let testResult = await PocService.getAllPoc();
  // console.log('log: getPocData -> testResult', testResult);

  // let _first = [{ key: '0', value: '0', text: '' }]
  let options = testResult.map((row) => {
    if (row._id == 0) {
      return { key: row._id, value: row._id, text: "" };
    } else {
      let text = row.name + " (" + row._id + ")";
      return { key: row._id, value: row._id, text: text };
    }
  });
  let _first = new Array(options.find((row) => row.key == "0"));
  let _options = options.filter((row) => row.key != "0"); // add 0 to first
  let lastOptions = [..._first, ..._options];
  PocState.setPocOptions(lastOptions);
  // console.log('log: getPocData -> testResult', JSON.stringify(lastOptions))
}

async function updateSpecialStatus() {
  try {
    let newSpecialStatus = [
      { SpecialStatus: "CANCELLED", SpecialStatusDescription: "UIN Cancelled" },
      { SpecialStatus: "OUTOFSERVICE", SpecialStatusDescription: "Out of Service/Destroyed" },
      { SpecialStatus: "LOST", SpecialStatusDescription: "Lost" },
      { SpecialStatus: "REPLACED", SpecialStatusDescription: "Replaced" },
      { SpecialStatus: "SOLD", SpecialStatusDescription: "Sold" },
      { SpecialStatus: "STOLEN", SpecialStatusDescription: "Stolen" },
    ];

    let newConfig = AppConfigState.config;
    newConfig.specialStatus = newSpecialStatus;

    let db = dbService();
    let savedConfig = insertOrUpdateDocument(newConfig, db);
    savedConfig.then(() => {
      console.log(">>>>>> Updated!: ", AppConfigState.config.specialStatus);
    });
  } catch (error) {
    console.log(">>>>>> Update error: ", error);
  }
}

async function testDeleteUser() {
  // let resultDeleteUser = await UserService.lambdaDeleteUser('org.couchdb.user:tienDev111');
  // console.log("testCreateUser: lambdaDeleteUser -> resultDeleteUser", resultDeleteUser);

  try {
    let loginResult = await dbService().login("dbAdmin", "dbadminpassword");
    let doc = await getUserProfileDb().get("org.couchdb.user:tienDev112");
    console.log("log: deleteUser -> doc", doc);
    return await getUserProfileDb().remove(doc);
  } catch (error) {
    console.warn("log: deleteUser -> error", error);
    throw error;
  }
}
