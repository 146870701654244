import { action, observable, computed, toJS } from "mobx";
import * as VehicleService from "../services/vehicleService";
import * as ActivityService from "../services/activityService";
import UserState from "./userState";
import RouteState from "./routeState";

class VehicleState {
  @observable vehicleFields = {};
  @observable currentStep = 0;
  @observable currentSpecificUsage = "";
  @observable hasRelatedActivity = false;

  @action setHasRelatedActivity(value) {
    this.hasRelatedActivity = value;
  }
  getCurrentStep() {
    return this.currentStep;
  }
  @action setCurrentStep(step) {
    this.currentStep = step;
    window.scrollTo(0, 0);
  }
  @action setNextStep() {
    this.currentStep += 1;
    window.scrollTo(0, 0);
  }
  @action setPreviousStep() {
    if (this.currentStep == 0) {
      RouteState.setRoute("/dashboard/vehicle");
    } else {
      this.currentStep -= 1;
    }
    window.scrollTo(0, 0);
  }

  @action resetSteps() {
    this.currentStep = 0;
    window.scrollTo(0, 0);
  }

  @action resetData() {
    this.vehicleFields = {};
    this.hasRelatedActivity = false;
  }

  @action updateVehicleFields(values) {
    this.vehicleFields = { ...this.vehicleFields, ...values };
  }

  @action setVehicleFields(vehicleFields) {
    this.vehicleFields = vehicleFields;
  }

  getVehicleFields() {
    return this.vehicleFields;
  }

  saveOnFieldChange(name, value) {
    this.vehicleFields[name] = value;
  }

  getOwner() {
    let ownerId;
    let roleId = UserState.currentUserProfile.roleId;
    switch (roleId) {
      case "5":
        ownerId = UserState.currentUserProfile._id;
        break;
      case "1":
        if (this.vehicleFields.owner) {
          ownerId = this.vehicleFields.owner;
        } else {
          ownerId = UserState.currentUserProfile._id;
        }
        break;

      default:
        ownerId = UserState.currentUserProfile._id;
        break;
    }
    return ownerId;
  }

  async saveVehicle(vehiclefields, ownerId, beaconCountryCode = "") {
    if (!vehiclefields) {
      vehiclefields = this.vehicleFields;
    }
    if (!ownerId) {
      ownerId = this.getOwner();
    }
    try {
      let result = await VehicleService.saveVehicle(vehiclefields, ownerId, beaconCountryCode);
      console.log("VehicleState:saveVehicle - result", result);

      this.vehicleFields = {};
      return result.id;
    } catch (error) {
      console.error("VehicleState:saveVehicle - error", error);
    }
  }

  async getVehicle(id) {
    try {
      let result = await ActivityService.getDocumentById(id);
      // let result = await ActivityService.getDocumentByTypeAndId("vehicle", id);
      console.log("VehicleState:getVehicle - result", result);
      return result;
    } catch (error) {
      console.error("VehicleState:getVehicle - error", error);
    }
  }

  @action setCurrentSpecificUsage(value) {
    this.currentSpecificUsage = value;
  }
}

const singleton = new VehicleState(); //export class as singleton to be used through the application.
export default singleton;
