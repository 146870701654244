import i18n from 'i18next'
import React, { Component } from 'react'
import * as Yup from 'yup'
import { Form, Button, Header, Tab, Dropdown, Container, TextArea } from 'semantic-ui-react'
import withSemanticUIFormik from '../../../utils/formHelper'
import { insertOrUpdateDocument } from '../../../services/serviceUtils'
import dbService from '../../../services/dbService'
import AppConfigState from '../../../state/appConfigState'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import EditEmailTemplateState from '../../../state/editEmailTemplateState'
import DashboardLayout from '../../../components/dashboardLayout'
import RouteState from "../../../state/routeState"
import './editEmailTemplate.css'

import CKEditor from 'ckeditor4-react';


let formOptions = [

  {
    key: 'User Registration',
    text: 'User Registration',
    value: '10',
  },
  {
    key: 'User Registration Complettion',
    text: 'User Registration Completion',
    value: '11',
  },
  {
    key: 'Beacon Registration',
    text: 'Beacon Registration',
    value: '2',
  },
  {
    key: 'Beacon Modification',
    text: 'Beacon Modification',
    value: '14',
  },
  {
    key: 'Delegate Beacon Registration For New User',
    text: 'Delegate Beacon Registration For New User',
    value: '16',
  },
  {
    key: 'Delegate Beacon Registration',
    text: 'Delegate Beacon Registration',
    value: '12',
  },
  {
    key: 'Delegate Beacon Registration Completion',
    text: 'Delegate Beacon Registration Completion',
    value: '13',
  },
  {
    key: 'Password Reset',
    text: 'Password Reset',
    value: '3',
  },
  {
    key: 'Questionnaire',
    text: 'Questionnaire',
    value: '4',
  },
  {
    key: 'Reminder Email',
    text: 'Reminder Email',
    value: '5',
  },
  {
    key: 'Reminder SMS',
    text: 'Reminder SMS',
    value: '6',
  },
  {
    key: 'Contact Us',
    text: 'Contact Us',
    value: '1',
  },
  {
    key: 'Contact Us Not Supported',
    text: 'Contact Us Not Supported',
    value: '7',
  },
  {
    key: 'Contact Up Supported',
    text: 'Contact Up Supported',
    value: '8',
  },
  {
    key: 'Contact Us Auto',
    text: 'Contact Us Auto',
    value: '9',
  },
  {
    key: 'Beacon Status',
    text: 'Beacon Status',
    value: '15',
  },
  {
    key: "Admin Updated User's Email",
    text: "Admin Updated User's Email",
    value: '17',
  },
  {
    key: "Admin Created New User",
    text: "Admin Created New User",
    value: '18',
  },
  {
    key: "Legacy User Reset Password",
    text: "Legacy User Reset Password",
    value: '19',
  },
  {
    key: "Attempt Register Existed Beacon",
    text: "Attempt Register Existed Beacon",
    value: '20',
  },
  {
    key: "Beacon Status Set Sold Via Email",
    text: "Beacon Status Set Sold Via Email",
    value: '21',
  },
  {
    key: "Beacon Status Set Sold Notify User",
    text: "Beacon Status Set Sold Notify User",
    value: '22',
  }
]


@observer
class InnerForm extends Component {

  state = {
    currentTemplate: '',
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props
    EditEmailTemplateState.setItems({}) // clear
    this.setState({currentTemplate: convertParamToKey(params.id)})
  }

  handleChange = (e, { name, value }) => {
    EditEmailTemplateState.setItems({}) // clear
    this.setState({ currentTemplate: value })
    RouteState.setRoute('/email-template/' + convertIndexToRoute(value))
  }

	render() {
		const {
			values,
			touched,
			errors,
			dirty,
			isSubmitting,
			handleChange,
			handleBlur,
			handleSubmit,
			handleReset,
			isValid,
      onClose,
    } =	this.props;

    let currentTemplate = this.state.currentTemplate
    let currentItem
    if(AppConfigState.config){
      currentItem = AppConfigState.config.emailTemplateLanguages.filter(row => parseInt(row.emailtemplateKey) == currentTemplate)
    }
    else{
      // return <div>Loading</div>
    }

    let panes = getPanes({ currentItem: currentItem })

		return (
      <>
      <DashboardLayout>
			<Form className="email-template-form animated fadeIn faster">
				<Header as='h2' icon>Edit Email Template</Header>
        <Form.Group >
          <Form.Field width='4'>
            Email Templates:
            <Dropdown placeholder='Select Email Template' selection options={formOptions} onChange={this.handleChange} value={currentTemplate} />
          </Form.Field>
        </Form.Group>

        <Tab panes={panes} renderActiveOnly={false} />


 				<div className="activityDetail-submitContainer">
					<Button className="registerBeacon-submitButton" onClick={onClose}>Cancel</Button>
					<Button className="registerBeacon-submitButton" type="submit" primary onClick={handleSubmit} loading={EditEmailTemplateState.isSaving} >Save</Button>
				</div>
			</Form>
      </DashboardLayout>
      </>
		);
	}

};

const FormWrapper = withSemanticUIFormik({
	mapPropsToValues: ({ item }) => {

	},
	validationSchema: Yup.object().shape({

	}),

	handleSubmit: (values, { setSubmitting, isValid, props }) => {
        // console.log('log: values=====================@comment', JSON.stringify(EditEmailTemplateState.items) )

        EditEmailTemplateState.setIsSaving(true)
        let newEmailTemplate = AppConfigState.config.emailTemplateLanguages.map(row => {
            // change to user data editing
            for (const [key, value] of Object.entries(EditEmailTemplateState.items)) {
                let keySplit = key.split('_')
                if(row.emailtemplateKey == keySplit[1] && row.cultureKey == keySplit[2]){
                  if(keySplit[0] == 'subject'){
                    row.subject = value
                  }
                  if(keySplit[0] == 'content'){
                    row.content = value
                  }
                }
            }
            return row
        })
        // console.log('>>>>>>>> new config: ' + JSON.stringify(newEmailTemplate))

        let newConfig = AppConfigState.config
        newConfig.emailTemplateLanguages = newEmailTemplate

        let db = dbService()
        let savedConfig = insertOrUpdateDocument(newConfig, db)
        // console.log('>>>>>>>> new config: ' + JSON.stringify(savedConfig))
        savedConfig.then(() => {
            // what to do after saved?
            // console.log('>>>>>> finished save')
            EditEmailTemplateState.setIsSaving(false)
        })
    }

})(InnerForm);

export default FormWrapper;

function getPanes({ currentItem }) {
  if(!currentItem) return
  let allPanes = currentItem.map(row => {
    let language
    switch (row.cultureKey) {
      case '1':
        language = 'English'
        break;
      case '2':
        language = 'French'
        break;
      case '3':
        language = 'Spanish'
        break;
      case '4':
        language = 'Russian'
        break;
      case '5':
        language = 'Other1'
        break;
      case '6':
        language = 'Other2'
        break;

      default:
        break;
    }
    return {
      menuItem: language,
      pane: { key: row.cultureKey, content: <TemplateWithContent emailtemplateKey={row.emailtemplateKey} cultureKey={row.cultureKey} subject={row.subject} content={row.content} /> }
    }
  })

  return allPanes
}

@observer
class TemplateWithContent extends Component {

  constructor(props) {
    super(props)
    this.keySubject = 'subject_' + this.props.emailtemplateKey +'_'+ this.props.cultureKey
    this.keyContent = 'content_' + this.props.emailtemplateKey +'_'+ this.props.cultureKey
  }

  onEditorChange = ( event ) => {
    // console.log('>>>>>>>> content: '+ this.keyContent )
    EditEmailTemplateState.updateItems({[this.keyContent]: event.editor.getData()})
  }

  handleChange = (e, {name, value}) => {
    // console.log('>>>>>>>> subject: '+ this.keySubject )
    EditEmailTemplateState.updateItems({[name]: value})
  }


  render(){
    let {emailtemplateKey, cultureKey, subject, content} = this.props

    subject = EditEmailTemplateState.items[this.keySubject] || subject
    content = EditEmailTemplateState.items[this.keyContent] || content

    return (
      <>
        <Form.Group >
            <Form.Field width='2'>
                Subject:
            </Form.Field>
            <Form.Input width='14' fluid name={this.keySubject} value={subject} placeholder='' onChange={this.handleChange} />
        </Form.Group>

        Content:
        <CKEditor
          config={{height:350}}
          data={content}
          onChange={this.onEditorChange}
        />
      </>
    )
  }

}


function convertParamToKey(param) {
  switch (param) {
      case "contact-us":
          return '1';
      case "beacon-registration":
          return '2';
      case "reset-password":
          return '3';
      case "questionnaire":
          return '4';
      case "reminder-email":
          return '5';
      case "reminder-sms":
          return '6';
      case "contact-us-not-supported":
          return '7';
      case "contact-up-supported":
          return '8';
      case "contact-us-auto":
          return '9';
      case "user-registration":
          return '10';
      case "user-registration-completion":
          return '11';
      case "delegate-beacon-registration":
        return '12';
      case "delegate-beacon-registration-completion":
        return '13';
      case "beacon-modification":
        return '14';
      case "beacon-status":
        return '15';
      case "delegate-beacon-registration-newuser":
        return '16';
      case "admin-updated-user-email":
        return '17';
      case 'admin-created-new-user':
        return '18';
      case 'legacy-user-reset-password':
        return '19';
      case 'attempt-register-existed-beacon':
        return '20';
      case 'beacon-status-set-sold-via-email':
        return '21';
      case 'beacon-status-set-sold-notify-user':
        return '22';

      default:
          return 0;
  }
}

function convertIndexToRoute(index) {
  switch (index) {
    case '1':
        return 'contact-us';
    case '2':
        return 'beacon-registration';
    case '3':
        return 'reset-password';
    case '4':
        return 'questionnaire';
    case '5':
        return 'reminder-email';
    case '6':
        return 'reminder-sms';
    case '7':
        return 'contact-us-not-supported';
    case '8':
        return 'contact-up-supported';
    case '9':
        return 'contact-us-auto';
    case '10':
        return 'user-registration';
    case '11':
        return 'user-registration-completion';
    case '12':
      return 'delegate-beacon-registration';
    case '13':
      return 'delegate-beacon-registration-completion';
    case '14':
      return 'beacon-modification';
    case '15':
      return 'beacon-status';
    case '16':
      return 'delegate-beacon-registration-newuser';
    case '17':
      return 'admin-updated-user-email';
    case '18':
      return 'admin-created-new-user';
    case '19':
      return 'legacy-user-reset-password';
    case '20':
      return 'attempt-register-existed-beacon';
    case '21':
      return 'beacon-status-set-sold-via-email';
    case '22':
      return 'beacon-status-set-sold-notify-user';

    default:
      return 0;
  }
}
