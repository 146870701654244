import React, { Component } from 'react';
import { Container, Icon, Step, Divider } from 'semantic-ui-react'
import RegistrationState from '../../../state/registrationState';
import BeaconDetailForm from './beaconDetailForm';
import BeaconLocationForm from './beaconLocationForm';
import BeaconRegulationForm from './beaconRegulationForm';
import { observer } from 'mobx-react';
import AppConfigState from '../../../state/appConfigState'
import i18n from "i18next";
@observer
export default class BeaconRegistrationStep extends Component {

	componentDidMount = () => {
		// let MidInfo = AppConfigState.config.MIDInfo.find(row => row.Mid == RegistrationState.decodedBeacon.beaconCountryCode)
  		// (MidInfo && MidInfo.beaconRegulationUrl) ? RegistrationState.setHasRegulationUrl(true) : RegistrationState.setHasRegulationUrl(false)
	}

	onSelectStep = (event, data) => {
		//console.log("Home: BeaconRegistrationStep -> onSelectStep -> data", data);
		/*if(!RegistrationState.isNewRegisterBeacon){
			RegistrationState.setBeaconCurrentStep(data.stepnumber)
		}*/
	}

	render() {
		let {beacon, onClose} = this.props;
		let currentStep = RegistrationState.beaconCurrentStep;

		if(AppConfigState.config){
			let MidInfo = AppConfigState.config.MIDInfo.find(row => row.Mid == RegistrationState.decodedBeacon.beaconCountryCode)
  			if(MidInfo && MidInfo.beaconRegulationUrl) {
				RegistrationState.setHasRegulationUrl(true)
			}
			else{
				RegistrationState.setHasRegulationUrl(false)
			}
		}


		return (
			<Container className='registerBeacon-subContainer animated fadeIn fast'>
				{/* <Divider /> */}
				<BeaconRegistrationSteps currentStep={currentStep} onSelectStep={this.onSelectStep} />
				<CurrentBeaconStep currentStep={currentStep} decodedBeacon={beacon} onClose={onClose} />
			</Container>
		)
	}
}

const BeaconRegistrationSteps = ({ currentStep, onSelectStep }) => (
	<Step.Group widths={3}>
		<Step active={currentStep === 0} stepnumber={0} >
			<Step.Content>
				<Step.Title>{i18n.t('beacon-details')}</Step.Title>
			</Step.Content>
			<StepComplete currentStep={currentStep} stepnumber={0} />
		</Step>

		{/* <Step active={currentStep === 1} stepnumber={1} >
			<Step.Content>
				<Step.Title>Beacon Location</Step.Title>
			</Step.Content>
			<StepComplete currentStep={currentStep} stepnumber={1} />
		</Step>

		{
			(RegistrationState.hasRegulationUrl) ?
				<Step active={currentStep === 2} stepnumber={2} >
					<Step.Content>
						<Step.Title>Regulations</Step.Title>
					</Step.Content>
					<StepComplete currentStep={currentStep} stepnumber={2} />
				</Step>
			: ''
		} */}

	</Step.Group>
)

const CurrentBeaconStep = ({ currentStep, decodedBeacon, onClose }) => {
	switch (currentStep) {
		case 0:
			return <BeaconDetailForm decodedBeacon={decodedBeacon} onClose={onClose} />
			break;
		case 1:
			return <BeaconLocationForm decodedBeacon={decodedBeacon} onClose={onClose} />
			break;
		case 2:
			return <BeaconRegulationForm decodedBeacon={decodedBeacon} onClose={onClose} />
			break;

		default:
			break;
	}
}

const StepComplete = ({ currentStep, stepnumber }) => {
  if (currentStep > stepnumber && RegistrationState.isNewRegisterBeacon) {
    return <Icon name="check" color="green" className="registerBeacon-stepComplete" />;
  } else {
    return null;
  }
};

