import i18n from "i18next";
import React, { Component, useEffect } from "react";
import * as Yup from "yup";
import { Form, Input, Button, Popup, Select, Header, Grid } from "semantic-ui-react";
import RegistrationState from "../../../state/registrationState";
import withSemanticUIFormik from "../../../utils/formHelper";
import RouteState from "../../../state/routeState";
import AppConfigState from "../../../state/appConfigState";
import UserState from "../../../state/userState";

const isRecordOption = [{ key: "Y", text: "Yes", value: "Y" }, { key: "N", text: "No", value: "N" }];

let storedValues = {};
const InnerForm = (props) => {
  const { values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, isValid } = props;

  let hexHasError = touched.hex && errors.hex && errors.hex.length > 0;
  let lengthHasError = touched.length && errors.length && errors.length.length > 0;
  let peopleCapacityHasError = touched.peopleCapacity && errors.peopleCapacity && errors.peopleCapacity.length > 0;

  let countryCodeOptions = AppConfigState.countryNamesWithCode.sort((a, b) => a.text.localeCompare(b.text));
  let canEditByStatus =
    props.decodedBeacon.specialStatus == "ACTIVE" || props.decodedBeacon.specialStatus == undefined || props.decodedBeacon.specialStatus == "RELEASETOMAINTENANCE";

  if (UserState.notAllowToEditByRoleId.includes(UserState.currentUserProfile.roleId)) {
    canEditByStatus = false;
  }

  const saveAndClose = () => {
    if (!RegistrationState.isNewRegisterBeacon) {
      RegistrationState.setIsSaving(true);
      RegistrationState.updateBeaconFields(props.values);
      RegistrationState.updateBeacon().then(() => {
        RegistrationState.setIsBeaconSaved(true);
        props.onClose();
        RegistrationState.setIsSaving(false);
      });
    }
  };
  storedValues = values;
  const saveToStateBeforeHandleChange = function(e, data) {
    console.log("log: InnerForm -> saveToStateBeforeHandleChange -> name, value", data.name, data.value);
    RegistrationState.saveOnFieldChange(data.name, data.value);
    handleChange(e, data);
  };

  return (
    <Form className="beaconLocation-form  animated fadeIn faster">
      <Header as="h2" icon textAlign="center">
        <Header.Content>Please supply additional information about your vehicle, if applicable</Header.Content>
      </Header>

      <Form.Group widths="equal">
        <Form.Field>
          <label>
            Length Overall (m)
            <Popup content="Overall length in metres." trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
          </label>
          <Form.Input
            fluid
            placeholder=""
            type="number"
            min="0"
            name="length"
            value={values.length}
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-input" : ""}
            error={lengthHasError}
          />
        </Form.Field>

        <Form.Input
          fluid
          label="Capacity (Crew and Passengers)"
          placeholder=""
          type="number"
          min="0"
          name="peopleCapacity"
          value={values.peopleCapacity}
          onChange={saveToStateBeforeHandleChange}
          onBlur={handleBlur}
          disabled={!canEditByStatus}
          className={canEditByStatus ? "editing-input" : ""}
          error={peopleCapacityHasError}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Field>
          <label>
            Vehicle Nationality
            <Popup
              content="MID country code for flag state or aircraft nationality of registration."
              trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
            />
          </label>
          <Form.Select
            fluid
            search
            options={countryCodeOptions}
            placeholder="Select"
            name="vehicleNationality"
            value={values.vehicleNationality}
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-select" : ""}
          />
        </Form.Field>

        <Form.Select
          fluid
          options={isRecordOption}
          placeholder="Select"
          label="Equipped with Simplified Voyage Data Recorder"
          name="equippedWithDataRecord"
          value={values.equippedWithDataRecord}
          onChange={saveToStateBeforeHandleChange}
          onBlur={handleBlur}
          disabled={!canEditByStatus}
          className={canEditByStatus ? "editing-select" : ""}
        />
      </Form.Group>

      <Grid centered columns={3} stackable>
        <Grid.Column>
          <Button className="registerBeacon-submitButton" onClick={onBackClick}>
            Back
          </Button>
        </Grid.Column>
        <Grid.Column>
          <Button className="registerBeacon-submitButton" type="submit" primary onClick={handleSubmit}>
            Next
          </Button>
        </Grid.Column>
        {/* {RegistrationState.isNewRegisterBeacon ? (
          ""
        ) : (
          <Grid.Column>
            <Button className="registerBeacon-submitButton" primary onClick={saveAndClose} loading={RegistrationState.isSaving}>
              Save & Close
            </Button>
          </Grid.Column>
        )} */}
      </Grid>
    </Form>
  );
};

function onBackClick() {
  RegistrationState.setVehiculePreviousStep();
}

const beaconLocationForm = withSemanticUIFormik({
  mapPropsToValues: ({ decodedBeacon }) => {
    if (!decodedBeacon) {
      decodedBeacon = {};
    }
    return {
      length: decodedBeacon.length || "",
      peopleCapacity: decodedBeacon.peopleCapacity || "",
      vehicleNationality: decodedBeacon.vehicleNationality || "",
      equippedWithDataRecord: decodedBeacon.equippedWithDataRecord || "",
    };
  },
  validationSchema: Yup.object().shape({
    length: Yup.number()
      .notRequired()
      .min(0, "Length Overall (m) must be greater than or equal to 0"),
    peopleCapacity: Yup.number()
      .notRequired()
      .min(0, "Capacity (Crew and Passengers) must be greater than or equal to 0"),
  }),

  handleSubmit: (values, { setSubmitting, isValid }) => {
    console.log("log: values", values, isValid);

    RegistrationState.updateBeaconFields(values);
    RegistrationState.setVehiculeNextStep();
  },
})(InnerForm);

export default beaconLocationForm;
