import i18n from "i18next";
import React, { Component } from "react";
import { Header, Container, Table, Icon, Grid, Pagination, Dropdown, Dimmer, Loader, Segment, Modal, Form, Button, Input, Label, Divider, Select } from "semantic-ui-react";
import { observer } from "mobx-react";
import * as PocService from "../../services/pocService";
import PocState from "../../state/pocState";
import PocForm from "./pointOfContactsEditForm";
import DashboardLayout from "../../components/dashboardLayout";
import UserState from "../../state/userState";
import RouteState from "../../state/routeState";

const paginationOptions = [{ key: "25", text: "25", value: "25" }, { key: "50", text: "50", value: "50" }, { key: "100", text: "100", value: "100" }];

const searchFields = [
  {
    key: "name",
    value: "name",
    text: "Name",
  },
  {
    key: "mid",
    value: "mid",
    text: "MID",
  },
  {
    key: "country",
    value: "country",
    text: "Country",
  },
  {
    key: "type",
    value: "type",
    text: "Type",
  },
  {
    key: "_id",
    value: "_id",
    text: "ID",
  },
  {
    key: "registration",
    value: "registration",
    text: "Registration",
  },
  {
    key: "poc",
    value: "poc",
    text: "POC",
  },
  {
    key: "mcc",
    value: "mcc",
    text: "MCC",
  },
];

@observer
export default class PointOfContact extends Component {
  state = {
    itemsPerPage: 25,
    currentPage: 1,
    criteriaComponents: [{ index: 0, key: searchFields[0].value, value: "" }],
    inputPage: 1,
  };
  componentDidMount = async () => {
    await PocState.searchPOC({});
    // sroted by pocIndex,ASC first
    PocState.defaultSort('_id', 'number');
  };
  onSetPage = (e, data) => {
    console.log("log: onSetPage -> data.items", data.activePage);
    this.setState({
      currentPage: data.activePage,
    });
  };
  onChangeItemsPerPage = (e, { value }) => {
    this.setState({
      itemsPerPage: value,
    });
  };

  // for search section
  onCriteriaChange = (e, data) => {
    let objIndex = this.state.criteriaComponents.findIndex((obj) => obj.index == data.criteria.index);
    this.state.criteriaComponents[objIndex].key = data.value;
    this.state.criteriaComponents[objIndex].value = ""; // reset value if field is changed
    this.setState({ criteriaComponents: this.state.criteriaComponents });
  };
  onFieldChange = (e, data) => {
    let objIndex = this.state.criteriaComponents.findIndex((obj) => obj.index == data.criteria.index);
    this.state.criteriaComponents[objIndex].value = data.value;
    this.setState({ criteriaComponents: this.state.criteriaComponents });
  };
  onDeleteCriteria = (criteria) => {
    let newCriteria = this.state.criteriaComponents.filter((item) => item.index != criteria.index);
    this.setState({ criteriaComponents: newCriteria });
  };
  onAddCriteriaComponent = () => {
    if (this.state.criteriaComponents.length === searchFields.length) return;
    const _index = this.state.criteriaComponents.length;
    let criteria = { index: _index, key: searchFields[_index].value, value: "" };
    this.state.criteriaComponents.push(criteria);
    this.setState({ criteriaComponents: this.state.criteriaComponents });
  };
  onSearchClick = () => {
    let searchCriteriaObj = buildFilterObject(this.state.criteriaComponents);
    // console.log('>>>>>>>>>>> criteria: ', searchCriteriaObj);
    PocState.searchPOC(searchCriteriaObj).then((result) => {
      this.setState({ inputPage: 1, currentPage: 1 });
    });
  };
  onClearCriteria = () => {
    this.setState({
      criteriaComponents: [{ index: 0, key: searchFields[0].value, value: "" }],
      inputPage: 1,
      currentPage: 1,
    });
    PocState.searchPOC({}).then(() => {});
  };

  // new pagination
  onInputPageChange = (e) => {
    let pattern = /^\d+$/;
    let isNumber = pattern.test(e.target.value);
    if (isNumber || isNumber == "") {
      this.setState({ inputPage: e.target.value });
    }
  };
  onSetPageByBlur = (nbOfPages) => {
    if (!this.state.inputPage || this.state.inputPage == "") return;
    if (parseInt(this.state.inputPage) < 1) {
      this.setState({ inputPage: 1, currentPage: 1 });
    } else if (parseInt(this.state.inputPage) > nbOfPages) {
      this.setState({ inputPage: nbOfPages, currentPage: nbOfPages });
    } else {
      this.setState({ currentPage: parseInt(this.state.inputPage) });
    }
  };
  keyPressed = (event, nbOfPages) => {
    if (event.key === "Enter") {
      this.onSetPageByBlur(nbOfPages);
    }
  };
  onPreviousPageClick = () => {
    let currentPage = parseInt(this.state.currentPage);
    if (currentPage > 1) {
      this.setState({ inputPage: currentPage - 1, currentPage: currentPage - 1 });
    }
  };
  onNextPageClick = (nbOfPages) => {
    let currentPage = parseInt(this.state.currentPage);
    if (currentPage < nbOfPages) {
      this.setState({ inputPage: currentPage + 1, currentPage: currentPage + 1 });
    }
  };

  render() {
    const { itemsPerPage, currentPage, criteriaComponents, inputPage } = this.state;

    let items = PocState.pocData || [];
    let nbOfPages = Math.ceil(items.length / itemsPerPage);
    let pagedItems = items.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    // console.log('===> poc type: ', PocState.ContactTypesOptions);

    return (
      <>
        <DashboardLayout>
          <Container>
            <Header as="h2" textAlign="center">
              Point of Contacts
            </Header>

            <Grid stackable className="POC-caption">
              <Grid.Row>
                <Grid.Column width="16">
                  <Form autoComplete="off">
                    <SearchCriteriaDisplay
                      criteriaComponents={criteriaComponents}
                      searchFields={searchFields}
                      onCriteriaChange={this.onCriteriaChange}
                      onFieldChange={this.onFieldChange}
                      onDeleteCriteria={this.onDeleteCriteria}
                    />
                  </Form>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="dashboard-beacons-gridRow">
                <Grid.Column width={8}>
                  <Button primary onClick={this.onAddCriteriaComponent}>
                    Add search criteria
                  </Button>
                  <Button primary onClick={this.onSearchClick}>
                    Search
                  </Button>
                </Grid.Column>
                <Grid.Column width={8} textAlign="right">
                  <Button onClick={this.onClearCriteria}>Clear</Button>
                </Grid.Column>
              </Grid.Row>

              <Divider />
              <Grid.Row>
                <Grid.Column width={3}>Total records : {items.length}</Grid.Column>
                <Grid.Column width={7}>
                  {/* <Pagination
								fluid
								defaultActivePage={currentPage}
								totalPages={nbOfPages}
								firstItem={null}
								lastItem={null}
								secondary
								size="mini"
								onPageChange={this.onSetPage}
							/> */}
                  <Button
                    icon="left chevron"
                    size="tiny"
                    onClick={() => this.onPreviousPageClick()}
                    disabled={PocState.isLoading}
                    style={{ color: "red", backgroundColor: "transparent" }}
                  />
                  Page:{" "}
                  <Input
                    className="beacon-page-number"
                    style={{ width: "60px", height: "30.8px" }}
                    name="currentPage"
                    min={1}
                    type="number"
                    disabled={PocState.isLoading}
                    onChange={this.onInputPageChange}
                    value={inputPage}
                    onBlur={() => this.onSetPageByBlur(nbOfPages)}
                    onKeyPress={(e) => this.keyPressed(e, nbOfPages)}
                  />{" "}
                  of{" " + nbOfPages + " "}
                  <Button
                    icon="right chevron"
                    size="tiny"
                    onClick={() => this.onNextPageClick(nbOfPages)}
                    disabled={PocState.isLoading}
                    style={{ color: "red", backgroundColor: "transparent" }}
                  />
                </Grid.Column>
                <Grid.Column width={6}>
                  Records per page:{" "}
                  <Dropdown inline header="Adjust records per page" options={paginationOptions} defaultValue={paginationOptions[0].value} onChange={this.onChangeItemsPerPage} />
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <MainContent items={pagedItems} isLoading={PocState.isLoading} onSearchClick={this.onSearchClick} />
          </Container>
        </DashboardLayout>
      </>
    );
  }
}

const SearchCriteriaDisplay = ({ criteriaComponents, searchFields, onFieldChange, onCriteriaChange, onDeleteCriteria }) => {
  let chunkedArray = chunkArray(criteriaComponents, 2); //This is just to create rows, turning the set into chunks
  let gridRows = chunkedArray.map((chunk, index) => {
    //for every chunks
    let gridCells = chunk.map((
      criteria,
      index //for every criteria in chunk
    ) => (
      <Grid.Column className="SearchCriteriaDisplay-column" key={index}>
        <SearchCriteria
          criteriaComponents={criteriaComponents}
          defaultValue={criteria.key}
          searchFields={searchFields}
          onFieldChange={onFieldChange}
          currentField={criteria.key}
          criteria={criteria}
          onCriteriaChange={onCriteriaChange}
          onDeleteCriteria={() => onDeleteCriteria(criteria)}
        />
      </Grid.Column>
    ));

    return <Grid.Row key={index}>{gridCells}</Grid.Row>;
  });

  return (
    <Grid celled container stackable columns={2} style={{ boxShadow: "none" }}>
      {gridRows}
    </Grid>
  );
};

const SearchCriteria = (props) => {
  let { defaultValue, searchFields, onFieldChange, criteria, onCriteriaChange, onDeleteCriteria, criteriaComponents, preventEdit } = props;

  const inputFields = ["name", "country", "mid", "_id", "registration", "mcc", "poc"];
  const dropdownFields = ["type"];

  let filteredSearchFields = searchFields.filter((field) => {
    let criteriaComponentIndex = criteriaComponents.findIndex((component) => component.key === field.key);
    if (criteriaComponentIndex !== -1) {
      return false;
    } else {
      return true;
    }
  });
  let currentlySelectedField = searchFields.find((field) => field.key === criteria.key);
  filteredSearchFields.unshift(currentlySelectedField);

  return (
    <div className="beacon-searchCriteria">
      <Dropdown
        value={criteria.key || ""}
        selection
        options={filteredSearchFields}
        onChange={onCriteriaChange}
        style={{ marginRight: "5px" }}
        criteria={criteria}
        className="beacon-searchCriteria-field"
        disabled={preventEdit}
      />
      {inputFields.includes(criteria.key) && (
        <Input key={criteria.key} onChange={onFieldChange} criteria={criteria} value={criteria.value} className="beacon-searchCriteria-input" disabled={preventEdit} />
      )}
      {dropdownFields.includes(criteria.key) && (
        <div className="beacon-searchCriteria-input">
          <Dropdown selection options={PocState.ContactTypesOptions} onChange={onFieldChange} criteria={criteria} clearable className="beacon-searchCriteria-inputDropdown" value={criteria.value} style={{lineHeight:'1.1em', borderRadius:'initial'}} />
        </div>
        )}

      <Label
        color="red"
        floating
        className="beacon-searchCriteria-closeButton"
        onClick={() => {
          onDeleteCriteria(criteria);
        }}
      >
        <Icon name="delete"  />
      </Label>
    </div>
  );
};

function chunkArray(myArray, chunk_size) {
  var index = 0;
  var arrayLength = myArray.length;
  var tempArray = [];

  for (index = 0; index < arrayLength; index += chunk_size) {
    let myChunk = myArray.slice(index, index + chunk_size);
    // Do something if you want with the group
    tempArray.push(myChunk);
  }

  return tempArray;
}

@observer
class MainContent extends Component {
  state = {
    showModal: false,
    editingItem: {},
    isNewRecord: false,
  };

  onShowModalEdit = (item) => {
    this.setState({ showModal: true, editingItem: item, isNewRecord: false });
  };

  onCloseModalEdit = () => {
    this.setState({ showModal: false });
  };

  handleSort = (clickedColumn, type) => {
    PocState.handleSort(clickedColumn, type);
  };

  onAddNewRecord = () => {
    this.setState({ showModal: true, editingItem: {}, isNewRecord: true })
  }

  render() {
    const { items, isLoading, onSearchClick } = this.props;
    const { showModal, editingItem, isNewRecord } = this.state;

    const column = PocState.column;
    const direction = PocState.direction;

    return (
      <Container>
        <Button icon="plus circle" content="New Record" primary size='small' onClick={this.onAddNewRecord} />

        <Segment>
          <Dimmer active={isLoading} inverted>
            <Loader>Loading</Loader>
          </Dimmer>

          <div className="sidescroll">
            <Table celled striped selectable sortable textAlign="center" className="animated fadeIn">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell className="dashboard-beacon-tableHeader" sorted={column === "_id" ? direction : null} onClick={() => this.handleSort("_id", "number")}>
                    ID
                  </Table.HeaderCell>
                   <Table.HeaderCell className="dashboard-beacon-tableHeader" >
                    type
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className="dashboard-beacon-tableHeader"
                    sorted={column === "name" ? direction : null}
                    onClick={() => this.handleSort("name")}
                  >
                    name
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className="dashboard-beacon-tableHeader"
                    sorted={column === "telex" ? direction : null}
                    onClick={() => this.handleSort("telex")}
                  >
                    telex
                  </Table.HeaderCell>
                  <Table.HeaderCell className="dashboard-beacon-tableHeader" sorted={column === "fax1" ? direction : null} onClick={() => this.handleSort("fax1")}>
                    fax
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className="dashboard-beacon-tableHeader"
                    sorted={column === "telephone1" ? direction : null}
                    onClick={() => this.handleSort("telephone1")}
                  >
                    telephone
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className="dashboard-beacon-tableHeader"
                    sorted={column === "address" ? direction : null}
                    onClick={() => this.handleSort("address")}
                  >
                    address
                  </Table.HeaderCell>
                  <Table.HeaderCell className="dashboard-beacon-tableHeader" sorted={column === "city" ? direction : null} onClick={() => this.handleSort("city")}>
                    city
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className="dashboard-beacon-tableHeader"
                    sorted={column === "zipcode" ? direction : null}
                    onClick={() => this.handleSort("zipcode")}
                  >
                    zipcode
                  </Table.HeaderCell>
                  <Table.HeaderCell className="dashboard-beacon-tableHeader" sorted={column === "country" ? direction : null} onClick={() => this.handleSort("country")}>
                    country
                  </Table.HeaderCell>
                  <Table.HeaderCell className="dashboard-beacon-tableHeader" sorted={column === "mid" ? direction : null} onClick={() => this.handleSort("mid")}>
                    mid
                  </Table.HeaderCell>

                  <Table.HeaderCell className="dashboard-beacon-tableHeader" sorted={column === "email" ? direction : null} onClick={() => this.handleSort("email")}>
                    email
                  </Table.HeaderCell>
                  <Table.HeaderCell className="dashboard-beacon-tableHeader" sorted={column === "registration" ? direction : null} onClick={() => this.handleSort("registration")}>
                    registration
                  </Table.HeaderCell>
                  <Table.HeaderCell className="dashboard-beacon-tableHeader" sorted={column === "poc" ? direction : null} onClick={() => this.handleSort("poc")}>
                    poc
                  </Table.HeaderCell>
                  <Table.HeaderCell className="dashboard-beacon-tableHeader" sorted={column === "mcc" ? direction : null} onClick={() => this.handleSort("mcc")}>
                    mcc
                  </Table.HeaderCell>

                  <Table.HeaderCell className="dashboard-beacon-tableHeader" sorted={column === "aftn" ? direction : null} onClick={() => this.handleSort("aftn")}>
                    aftn
                  </Table.HeaderCell>
                  <Table.HeaderCell className="dashboard-beacon-tableHeader" sorted={column === "email2" ? direction : null} onClick={() => this.handleSort("email2")}>
                    email2
                  </Table.HeaderCell>

                  <Table.HeaderCell
                    className="dashboard-beacon-tableHeader"
                    sorted={column === "website_url" ? direction : null}
                    onClick={() => this.handleSort("website_url")}
                  >
                    website_url
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>{items ? items.map((item, index) => <TableRow key={index} item={item} onClick={() => this.onShowModalEdit(item)} />) : ""}</Table.Body>
            </Table>
          </div>
          <EmptyStatePlaceHolder items={items} />
        </Segment>
        <br />

        <Modal centered={false} open={showModal} onClose={this.onCloseModalEdit} className="dashboard-poc-edit" closeIcon closeOnDimmerClick={false}>
          {/* <Modal.Header>Edit point of contact</Modal.Header> */}
          <Modal.Content className="dashboard-poc-edit-content">
            <PocForm item={editingItem} onClose={this.onCloseModalEdit} isNewRecord={isNewRecord} onSearchClick={onSearchClick} />
          </Modal.Content>
        </Modal>
      </Container>
    );
  }
}

const TableRow = ({ item, onClick }) => {
  let bcnRegFAX = item.fax1 ? cleanupHtmlEntities(item.fax1) : "";
  let bcnRegPhoneNum = item.telephone1 ? cleanupHtmlEntities(item.telephone1) : "";
  let address = item.address ? cleanupHtmlEntities(item.address) : "";
  let bcnRegMaintName = item.name ? cleanupHtmlEntities(item.name) : "";

  return (
    <Table.Row onClick={onClick} style={{ cursor: "pointer" }}>
      <Table.Cell>{item._id || ""}</Table.Cell>
       <Table.Cell>{item.type || ""}</Table.Cell>
      {/* <Table.Cell>{item.name || ""}</Table.Cell> */}
      <Table.Cell>{bcnRegMaintName || ""}</Table.Cell>
      <Table.Cell>{item.telex || ""}</Table.Cell>
      <Table.Cell>{bcnRegFAX}</Table.Cell>
      <Table.Cell>{bcnRegPhoneNum}</Table.Cell>
      {/* <Table.Cell>{bcnRegMaintName || ""}</Table.Cell> */}
      <Table.Cell>{address}</Table.Cell>
      <Table.Cell>{item.city || ""}</Table.Cell>
      <Table.Cell>{item.zipcode || ""}</Table.Cell>
      <Table.Cell>{item.country || ""}</Table.Cell>
      <Table.Cell>{item.mid || ""}</Table.Cell>

      <Table.Cell>{item.email || ""}</Table.Cell>
      <Table.Cell>{item.registration || ""}</Table.Cell>
      <Table.Cell>{item.poc || ""}</Table.Cell>
      <Table.Cell>{item.mcc || ""}</Table.Cell>

      <Table.Cell>{item.aftn || ""}</Table.Cell>
      <Table.Cell>{item.email2 || ""}</Table.Cell>
      <Table.Cell>{item.website_url || ""}</Table.Cell>
    
      {/* <Table.Cell>{item.comments || ""}</Table.Cell> */}
    </Table.Row>
  );
};

function cleanupHtmlEntities(string) {
  var br = "<br>";
  var reBr = new RegExp(br, "g");

  var br2 = "<br />";
  var reBr2 = new RegExp(br2, "g");

  var lt = "&lt;";
  var reLt = new RegExp(lt, "g");

  var gt = "&gt;";
  var reGt = new RegExp(gt, "g");

  var nbsp = "&nbsp;";
  var reNbsp = new RegExp(nbsp, "g");

  var p = "<p>";
  var reP = new RegExp(p, "g");

  var p2 = "</p>";
  var reP2 = new RegExp(p2, "g");

  var b = "<b>";
  var reB = new RegExp(b, "g");

  var b2 = "</b>";
  var reB2 = new RegExp(b2, "g");

  var br = "<br>";
  var reBr = new RegExp(find, "g");
  return string

    .replace(reLt, "<")
    .replace(reGt, ">")
    .replace(reBr, " ")
    .replace(reBr2, " ")
    .replace(reNbsp, " ")
    .replace(reP, " ")
    .replace(reB, " ")
    .replace(reP2, " ")
    .replace(reB2, " ");
}

const EmptyStatePlaceHolder = ({ items }) => {
  if (items && items.length > 0) {
    return null;
  }

  return (
    <Header as="h2" icon textAlign="center">
      <Icon name="file outline" circular />
      <Header.Content>No recorded statistics</Header.Content>
    </Header>
  );
};

function buildFilterObject(rawFilter) {
  if (!rawFilter) return {};
  let searchCriteriaObj = {};
  searchCriteriaObj = rawFilter.reduce((result, currentValue) => {
    let key = currentValue.key;
    if (currentValue.value != "") {
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(currentValue.value);
    }
    console.log(">>>>>>> raw data: ", typeof currentValue.value, result);
    return result;
  }, {});

  return searchCriteriaObj;
}
