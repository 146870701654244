import i18n from "i18next";
import React, { Component, useEffect } from "react";
import * as Yup from "yup";
import { Form, Input, Button, Popup, Select, Header, Grid } from "semantic-ui-react";
import RegistrationState from "../../../state/registrationState";
import AppConfigState from "../../../state/appConfigState";
import { observer } from "mobx-react";
import UserState from "../../../state/userState";

import withSemanticUIFormik from "../../../utils/formHelper";
import RouteState from "../../../state/routeState";

const statusOption = [
  { key: "af", value: "af", text: "Working" },
  { key: "ax", value: "ax", text: "Aland Islands" },
  { key: "al", value: "al", text: "Albania" },
  { key: "dz", value: "dz", text: "Algeria" },
  { key: "as", value: "as", text: "American Samoa" },
];

const HomingSignalOptions = [
  { key: "None", text: "None", value: "None" },
  { key: "121.5 MHz ", text: "121.5 MHz ", value: "121.5 MHz " },
  { key: "SART", text: "SART", value: "SART" },
  { key: "AIS", text: "AIS", value: "AIS" },
  { key: "Other", text: "Other", value: "Other" },
];
let storedValues = {};
const InnerForm = (props) => {
  const { values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, isValid } = props;
  console.log("log: InnerForm -> values", values.additionalBeaconData);

  const activationMethodOptions = AppConfigState.beaconActivationMethod;
  let canEditByStatus =
    props.decodedBeacon.specialStatus == "ACTIVE" || props.decodedBeacon.specialStatus == undefined || props.decodedBeacon.specialStatus == "RELEASETOMAINTENANCE";

  if (UserState.notAllowToEditByRoleId.includes(UserState.currentUserProfile.roleId)) {
    canEditByStatus = false;
  }

  const saveAndClose = () => {
    if (!RegistrationState.isNewRegisterBeacon) {
      RegistrationState.setIsSaving(true);
      RegistrationState.updateBeaconFields(props.values);
      RegistrationState.updateBeacon().then(() => {
        RegistrationState.setIsBeaconSaved(true);
        props.onClose();
        RegistrationState.setIsSaving(false);
      });
    }
  };
  storedValues = values; //if we don't do this, it keeps only the first references of values without the changes after mount
  /*useEffect(() => {
    return function cleanup() {
      if (!RegistrationState.isNewRegisterBeacon) {
        console.log("BeaconLocationForm: InnerForm -> cleanup -> ", storedValues);
        RegistrationState.updateBeaconFields(storedValues);
      }
    };
  }, []);*/

  const saveToStateBeforeHandleChange = function(e, data) {
    console.log("log: InnerForm -> saveToStateBeforeHandleChange -> name, value", data.name, data.value);
    RegistrationState.saveOnFieldChange(data.name, data.value);
    handleChange(e, data);
  };

  return (
    <>
      <Form className="beaconLocation-form animated fadeIn faster">
        <Header as="h2" icon textAlign="center">
          <Header.Content>{i18n.t('please-supply-the-following-information-about-your-beacon')}</Header.Content>
        </Header>

        <Form.Group widths="equal">
          <Form.Field>
            <label>
              {i18n.t('beacon-homing-device')}{" "}
              <Popup
                content={i18n.t('the-type-of-homing-signal-or-device-used-by-the-beacon-selected-from-pull-down-menu')}
                trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
              />
            </label>
            <Form.Select
              fluid
              name="beaconHomingDevice"
              options={HomingSignalOptions}
              value={values.beaconHomingDevice}
              placeholder={i18n.t('select')}
              onChange={saveToStateBeforeHandleChange}
              onBlur={handleBlur}
              disabled={!canEditByStatus}
              className={canEditByStatus ? "editing-select" : ""}
            />

            {values.beaconHomingDevice == "Other" ? (
              <Form.Input
                fluid
                placeholder=""
                name="beaconHomingDeviceOther"
                value={values.beaconHomingDeviceOther}
                onChange={saveToStateBeforeHandleChange}
                onBlur={handleBlur}
                disabled={!canEditByStatus}
                className={canEditByStatus ? "editing-input" : ""}
              />
            ) : (
              (values.beaconHomingDeviceOther = "")
            )}
          </Form.Field>

          <Form.Field>
            <label>
              {i18n.t('activation-method')}{" "}
              <Popup
                content={i18n.t('applies-to-method-for-the-activation-of-the-beacon-automatic-manual-or-manual-only')}
                trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
              />
            </label>
            <Form.Select
              fluid
              name="beaconActivationMethod"
              options={activationMethodOptions}
              value={values.beaconActivationMethod}
              placeholder="Select"
              onChange={saveToStateBeforeHandleChange}
              onBlur={handleBlur}
              disabled={!canEditByStatus}
              className={canEditByStatus ? "editing-select" : ""}
            />
          </Form.Field>
        </Form.Group>

        <label>
          {i18n.t('additional-beacon-information')}{" "}
          <Popup
            content={i18n.t('any-additional-information-regarding-the-beacon-specifically-that-may-be-useful-to-search-and-rescue-personnel')}
            trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
          />
        </label>
        <Form.TextArea
          name="additionalBeaconData"
          label=""
          value={values.additionalBeaconData}
          placeholder=""
          onChange={saveToStateBeforeHandleChange}
          onBlur={handleBlur}
          disabled={!canEditByStatus}
          className={canEditByStatus ? "editing-input" : ""}
        />

        <Grid centered columns={3} stackable>
          <Grid.Column>
            <Button className="registerBeacon-submitButton" onClick={onBackClick}>
              {i18n.t('back')}
            </Button>
          </Grid.Column>
          <Grid.Column>
            <Button className="registerBeacon-submitButton" type="submit" primary onClick={handleSubmit} loading={RegistrationState.isSaving}>
              {i18n.t('next')}
            </Button>
          </Grid.Column>
          {/* {RegistrationState.isNewRegisterBeacon ? (
            ""
          ) : (
            <Grid.Column>
              <Button className="registerBeacon-submitButton" primary onClick={saveAndClose} loading={RegistrationState.isSaving}>
                Save & Close
              </Button>
            </Grid.Column>
          )} */}
        </Grid>
      </Form>
    </>
  );
};

function onBackClick() {
  RegistrationState.setBeaconPreviousStep();
}

const beaconLocationForm = withSemanticUIFormik({
  mapPropsToValues: ({ decodedBeacon }) => {
    if (decodedBeacon) {
      console.log("beaconLocationForm: decodedBeacon", decodedBeacon);
      return {
        // protocol: decodedBeacon.protocol || "",
        // mailCountry: decodedBeacon.mailCountry || "",
        // internalLocation:  decodedBeacon.internalLocation || "",
        // rSLCapabilityAndNumber:  decodedBeacon.rSLCapabilityAndNumber || "",
        beaconHomingDevice: decodedBeacon.beaconHomingDevice || "",
        beaconActivationMethod: decodedBeacon.beaconActivationMethod || "",
        additionalBeaconData: decodedBeacon.additionalBeaconData || "",
        beaconHomingDeviceOther: decodedBeacon.beaconHomingDeviceOther || "",
      };
    } else {
      return {
        // beaconType: "",
        // hex: " ",
        // manufacturer: "",
        // modelName: "",
        // serialNumber: "",
        // modelReference: "",
        // beaconStatus: "",
        // rSLCapabilityAndNumber: "",
        beaconHomingDevice: "",
        beaconActivationMethod: "",
        additionalBeaconData: "",
        beaconHomingDeviceOther: "",
      };
    }
  },
  validationSchema: Yup.object().shape({}),

  handleSubmit: (values, { setSubmitting, isValid }) => {
    console.log("log: values", values, isValid);
    RegistrationState.updateBeaconFields(values);
    RegistrationState.setBeaconNextStep();
  },
})(InnerForm);

export default beaconLocationForm;
