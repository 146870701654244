import React, { Component } from 'react';
import { Container, Step } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import VehicleState from '../../../state/vehicleState';
import VehicleDetailForm from './vehicleDetailForm';
import VehicleCommentForm from './vehicleCommentForm';
import VehicleReview from './vehicleReview';
import VehicleComplete from './vehicleComplete';
import ActivityState from '../../../state/activityState'

@observer
export default class VehicleSteps extends Component {

    onSelectStep = (event, data) => {
		/*if(ActivityState.isEditActivity){
			VehicleState.setCurrentStep(data.stepnumber);
		}*/
    }

    render(){
        let { vehicle, onClose, onSave, allowPreview, allowSelectVehicle, onCancel } = this.props;
		let currentStep = VehicleState.currentStep;

        return (
            <Container className='registerBeacon-subContainer animated fadeIn fast'>
                <VehicleEditor currentStep={currentStep} vehicle={vehicle} onClose={onClose} onSave={onSave} allowPreview={allowPreview} allowSelectVehicle={allowSelectVehicle} onCancel={onCancel} />
            </Container>
        )
    }
}

const Steps = ({ currentStep, onSelectStep }) => {
	if(currentStep === 0 || currentStep === 1)
		return (
			<Step.Group widths={2}>
				<Step active={currentStep === 0} stepnumber={0} >
					<Step.Content>
						<Step.Title>Vehicle Details</Step.Title>
					</Step.Content>
				</Step>

				<Step active={currentStep === 1} stepnumber={1} >
					<Step.Content>
						<Step.Title>Comments and Image</Step.Title>
					</Step.Content>
				</Step>
			</Step.Group>
		);
	else
		return "";
}

const VehicleEditor = ({ currentStep, vehicle, onClose, onSave, allowPreview, allowSelectVehicle, onCancel }) => {
	switch (currentStep) {
		case 0:
			return <VehicleDetailForm vehicle={vehicle} onClose={onClose} allowSelectVehicle={allowSelectVehicle} onCancel={onCancel} />
		case 1:
			return <VehicleCommentForm vehicle={vehicle} onSave={onSave} allowPreview={allowPreview} />
		case 2:
			return <VehicleReview />
		case 3:
			return <VehicleComplete />
		default:
			break;
	}
}