import i18n from "i18next";
import React, { Component, useState } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import RegistrationState from "../../state/registrationState";
import UserState from "../../state/userState";
import AppConfigState from "../../state/appConfigState";
import AppState from "../../state/appState";
import * as Yup from "yup";
import { Form, Button, Message, Header, Transition, Segment, Grid, Popup, Container, Icon, Dimmer, Loader } from "semantic-ui-react";
import withSemanticUIFormik from "../../utils/formHelper";
import RouteState from "../../state/routeState";
import { ErrorMessage } from "formik";
import Footer from "../../components/footer";
import TopMenu from "../../components/topMenu";
import "./registerUser.css";
import { createValidateEmailToken } from "../../services/authActionService";
import * as MailService from "../../services/mailService";
import { UserFormWithPasswordErrorDisplay, MESSAGES } from "../../components/formErrorDisplay";
import beaconDecode from "../../utils/beaconDecode/BeaconDecode";

//const contactNoOption = [{ key: "1", text: "1", value: "1" }, { key: "2", text: "2", value: "2" }, { key: "3", text: "3", value: "3" }, { key: "4", text: "4", value: "4" }];

// const phoneTypeOption = [
//   { key: "cellular", text: "cellular", value: "cellular" },
//   { key: "home", text: "home", value: "home" },
//   { key: "work", text: "work", value: "work" },
//   { key: "fax", text: "fax", value: "fax" },
//   { key: "other", text: "other", value: "other" },
// ];
let contact = 0;

class InnerForm extends Component {
  constructor(props) {
    super(props);

    UserState.setTmpPassword(UserState.defaultTmpPassword);
    MESSAGES.SENT_VERIFICATION_SUCCESS = i18n.t("email-verification-sent-look-for-the-verification-email-in-your-inbox");
    MESSAGES.SENT_VERIFICATION_FAIL = i18n.t("cannot-send-verification-email-please-check-your-email-is-valid-or-contact-administrator");
  }
  state = {
    contact: 1,
    dontHaveEmail: false,
    isRevealPassword: false,
    valuePassword: UserState.currentUserProfile ? UserState.defaultTmpPassword : "",
    valuePasswordConfirm: UserState.currentUserProfile ? UserState.defaultTmpPassword : "",
    isSendingVerifyEmail: false,
    showInstructions: false
  };

  addContactNumber = () => {
    let totalPhone = document.getElementsByClassName("phoneNumber").length;
    this.setState({
      contact: totalPhone + 1
    });
  };

  removeContactNumber = (fieldName, phoneType) => {
    this.props.values[fieldName] = "";
    this.props.values[phoneType] = "";
    this.setState((prevState) => ({
      contact: prevState.contact - 1
    }));
  };

  resendVerificationEmail = async () => {
    this.setState({ isSendingVerifyEmail: true });
    let user = UserState.currentUserProfile;
    if (user && user.emailAddress) {
      let user = UserState.currentUserProfile;
      let token = await createValidateEmailToken(user._id);
      console.log("log ~ file: registerUserForm.js ~ line 72 ~ InnerForm ~ resendVerificationEmail= ~ token", token);
      const emailTemplateKey = AppConfigState.getEmailTemplateKey("USER_REGISTRATION");
      const emailTemplate = AppConfigState.getEmailTemplate(emailTemplateKey);
      let emailData = MailService.prepareEmailData(emailTemplate, null, user, token, null);

      let response = await MailService.sendMail(user.emailAddress, emailData.subject, emailData.content);
      console.log("log ~ file: registerUserForm.js ~ line 78 ~ InnerForm ~ resendVerificationEmail= ~ response", response);
      console.log("log ~ file: registerUserForm.js ~ line 78 ~ InnerForm ~ resendVerificationEmail= ~ user.emailAddress, emailData.subject, emailData.content", user.emailAddress, emailData.subject, emailData.content);
      if (response && response.err) {
        this.setState({ isSendingVerifyEmail: false });
        this.props.setStatus(MESSAGES.SENT_VERIFICATION_FAIL);
      } else {
        this.setState({ isSendingVerifyEmail: false });
        this.props.setStatus(MESSAGES.SENT_VERIFICATION_SUCCESS);
      }
      return response;
    } else {
      this.setState({ isSendingVerifyEmail: false });
      // this.props.setStatus('E-mail address is required')
      this.props.setErrors({ emailAddress: "E-mail address is required" });
    }
  };

  dontHaveEmailToggle = (e, data) => {
    this.props.values.dontHaveEmail = data.checked;
    this.setState({ dontHaveEmail: data.checked });
    this.props.values.emailAddress = "";
    this.props.setErrors({}); // remove error message
  };

  toggleRevealPassword = (e) => {
    this.setState({ isRevealPassword: !this.state.isRevealPassword });
  };

  handleChangePassword = (e, data) => {
    this.props.values.password = data.value;
    this.setState({ valuePassword: data.value });
    //set change password
    UserState.setTmpPassword(data.value);
    this.setState({ isShowPassword: true });
  };

  handleChangePasswordConfirm = (e, data) => {
    this.props.values.passwordConfirm = data.value;
    this.setState({ valuePasswordConfirm: data.value });
  };

  onInstructionClick = () => {
    this.setState({ showInstructions: !this.state.showInstructions });
  };

  handleChangeUsername = (e, data) => {
    if (data && data.name) {
      let sanitizeUsername = this.removeEmptySpaces(data.value);
      this.props.setFieldValue(data.name, sanitizeUsername);
    }
  };

  removeEmptySpaces = (str) => {
    return str.replace(/\s+/g, "").replace(/[^a-zA-Z0-9]/g, "");
  };

  render() {
    let { contact, dontHaveEmail, isRevealPassword, valuePassword, valuePasswordConfirm, isSendingVerifyEmail, showInstructions } = this.state;
    const { values, touched, errors, status, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, setFieldTouched, isValid, setErrors } = this.props;
    console.log("log: InnerForm -> render -> errors", errors, touched);

    if (!AppConfigState.config) {
      return (
        <Segment>
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        </Segment>
      );
    }

    let hasFirstNameError = touched.ownerName && errors.ownerName && errors.ownerName.length > 0;
    let hasLastNameError = touched.lastName && errors.lastName && errors.lastName.length > 0;
    let hasContactPhoneError = touched.phone1Num && errors.phone1Num && errors.phone1Num.length > 0;
    let hasContactPhoneTypeError = touched.phone1Type && errors.phone1Type && errors.phone1Type.length > 0;
    let hasPasswordError = touched.password && errors.password && errors.password.length > 0;
    let hasPasswordConfirmError = touched.passwordConfirm && errors.passwordConfirm && errors.passwordConfirm.length > 0;
    let haschallengeQuestionError = touched.challengeQuestion && errors.challengeQuestion && errors.challengeQuestion.length > 0;
    let haschallengeResponseError = touched.challengeResponse && errors.challengeResponse && errors.challengeResponse.length > 0;
    let hasUsernameError = touched.username && errors.username && errors.username.length > 0;
    let hasEmailAddressError = touched.emailAddress && errors.emailAddress && errors.emailAddress.length > 0;
    let phone2NumError = touched.phone2Num && errors.phone2Num && errors.phone2Num.length > 0;
    let phone3NumError = touched.phone3Num && errors.phone3Num && errors.phone3Num.length > 0;
    let phone4NumError = touched.phone4Num && errors.phone4Num && errors.phone4Num.length > 0;

    let hasError = hasTouchedErrors(errors, touched);
    //console.log("registerUser: errors", touched, errors, isSubmitting, status);

    function hasTouchedErrors(errors, touched) {
      for (const [key, value] of Object.entries(errors)) {
        if (touched[key]) {
          return true;
        }
      }
      return false;
    }

    const phoneTypeOption = AppConfigState.phoneTypes;
    let countryOptions = AppConfigState.countryNames;
    let challengeQuestionsOptions = AppConfigState.challengeQuestions;
    let languages = AppConfigState.languages;
    let editUser = false;
    if (values._id) {
      editUser = true;
    }

    let containerClassName = "";
    console.log("log: InnerForm -> render -> hasError", hasError, errors);
    if (hasError) {
      containerClassName = "hasError";
    }

    // let RedSpan;
    // if (RegistrationState.decodedBeacon && RegistrationState.decodedBeacon.beaconType && RegistrationState.decodedBeacon.beaconType.includes("ELT")) {
    //   RedSpan = <span style={{ color: "red" }}>*</span>;
    // }
    let RedSpan = <span style={{ color: "red" }}>*</span>;

    var AnotherContactButton;

    let userFromAdmin = toJS(UserState.userFromAdmin);
    console.log("log: InnerForm -> render -> userFromAdmin", userFromAdmin);
    const t = AppState.t;
    if (contact < 4) {
      AnotherContactButton = (
        <Form.Group widths="equal">
          <a className="registerUserForm-addContactsButton" onClick={this.addContactNumber}>
            <Icon name="plus circle" color="red" size="large" />
            {"   "}
            {UserState.userFromAdmin ? "Add Another Phone Number" : "Add another Phone Number"}
          </a>
        </Form.Group>
      );
    }

    // scroll to error
    if ((isSubmitting && Object.keys(errors).length !== 0) || status === "User profile saved") {
      var errorBox = document.getElementById("registerUserForm");
      errorBox.scrollIntoView();
    }

    let instructions = null;
    let data = AppConfigState.getPublishedPageContentWithCurrentLanguage("CreateUserInstructions")[0];
    console.log("log: InnerForm -> render -> data", data);
    let instructionText = i18n.t("show-instructions");
    if (showInstructions && data) {
      instructionText = i18n.t("hide-instructions");
      instructions = (
        <Message info className="animated fadeIn">
          <Message.Header dangerouslySetInnerHTML={{ __html: data.subject }} />
          <p dangerouslySetInnerHTML={{ __html: data.content }} />
        </Message>
      );
    }

    return (
      <div className="registerUser-page">
        <TopMenu />

        <Segment className="registerUser-mainContainer  animated fadeIn faster" padded="very">
          <Button floated="right" onClick={this.onInstructionClick}>
            {instructionText}
          </Button>
          <Header as="h2">
            <Header.Content>{UserState.currentUserProfile ? i18n.t("edit-ibrd-account") : i18n.t("create-a-new-ibrd-account")}</Header.Content>
            <Header.Subheader>{UserState.currentUserProfile ? "" : i18n.t("complete-the-information-below-to-create-your-ibrd-profile-account")}</Header.Subheader>
          </Header>

          <div className={"registerUser-messageContainer " + containerClassName}>
            {instructions}
            {UserState.currentUserProfile && !UserState.currentUserProfile.emailValidated ? (
              <Message icon warning>
                <Icon name="exclamation circle" />
                <Message.Content>
                  <Message.Header>{i18n.t("please-confirm-your-email")}</Message.Header>
                  {i18n.t("you-can-resend-the-confirmation-email-using-this-button")}
                </Message.Content>
                <Button floated="right" color="orange" icon="send" basic onClick={this.resendVerificationEmail} loading={isSendingVerifyEmail}>
                  <Icon name="send" />
                  {i18n.t("resend-verification-email")}
                </Button>
              </Message>
            ) : (
              ""
            )}

            {UserState.delegatedUser ? (
              <Message icon warning>
                <Icon name="exclamation circle" />
                <Message.Content>
                  <Message.Header>{i18n.t("please-update-your-password")}</Message.Header>
                  {i18n.t("please-verify-and-complete-your-profile-information-and-click-save-to-update-your-profile")}
                </Message.Content>
              </Message>
            ) : UserState.userFromAdmin ? (
              <Message icon warning>
                <Icon name="exclamation circle" />
                <Message.Content>
                  <Message.Header>{i18n.t("please-update-your-password")}</Message.Header>
                  {i18n.t("please-verify-and-complete-your-profile-information-and-click-save-to-update-your-profile")}
                </Message.Content>
              </Message>
            ) : (
              ""
            )}
            <UserFormWithPasswordErrorDisplay errors={errors} touched={touched} status={status} />
          </div>
          <Form id="registerUserForm" error={hasError} className="registerUser-form">
            <div className="required-field-notation">* required fields</div>

            <Form.Group>
              <Form.Field width={16}>
                <label>
                  Name
                  <span style={{ color: "red" }}> * </span>
                  <Popup content={i18n.t("name-of-person-company-or-government-agency-if-a-person-the-format-is-last-first-middle-initial-commas-are-not-needed")} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
                </label>
                <Form.Input fluid name="ownerName" placeholder="Name" error={hasFirstNameError} onChange={handleChange} onBlur={handleBlur} required value={values.ownerName} className="editing-input" required />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field width="12">
                <label>
                  Address
                  <Popup content={i18n.t("street-address-po-box-where-owner-lives")} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
                </label>
                <Form.Input name="address" placeholder="" className="editing-input" onChange={handleChange} onBlur={handleBlur} value={values.address} />
              </Form.Field>
              <Form.Field width={4}>
                <label>
                  {i18n.t("zip-postal-code")}
                  <Popup content={i18n.t("appropriate-zip-or-postal-code-to-the-address")} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
                </label>
                <Form.Input name="mailCode" placeholder="" className="editing-input" onChange={handleChange} onBlur={handleBlur} value={values.mailCode} />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label>
                  {i18n.t("city")}
                  <Popup content={i18n.t("city")} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
                </label>
                <Form.Input name="city" fluid placeholder={i18n.t("city")} className="editing-input" onChange={handleChange} onBlur={handleBlur} value={values.city} />
              </Form.Field>
              <Form.Field>
                <label>
                  {i18n.t("state-or-province")}
                  <Popup content={i18n.t("state-or-province")} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
                </label>
                <Form.Input name="province" fluid placeholder={i18n.t("state-or-province")} className="editing-input" onChange={handleChange} onBlur={handleBlur} value={values.province} />
              </Form.Field>
              <Form.Field>
                <label>
                  {i18n.t("country")}
                  <Popup content={i18n.t("country")} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
                </label>
                <Form.Dropdown name="mailCountry" fluid options={countryOptions} placeholder={i18n.t("country")} onChange={handleChange} onBlur={handleBlur} search scrolling className="editing-select form-dropdown-style margin-top-0" value={values.mailCountry} />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label>
                  {i18n.t("e-mail")}
                  {RedSpan}
                  <Popup content={i18n.t("e-mail-address-where-all-correspondence-pertaining-to-the-beacon-should-be-sent-e-mail-addresses-are-not-used-for-any-purpose-other-than-this-registry-and-for-search-and-rescue")} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
                </label>
                <Form.Input name="emailAddress" fluid placeholder="john@doe.com" onChange={handleChange} onBlur={handleBlur} value={values.emailAddress} disabled={dontHaveEmail || UserState.delegatedUser} error={hasEmailAddressError} className={dontHaveEmail || UserState.delegatedUser ? "" : "editing-input"} type="email" />

                {UserState.delegatedUser || (UserState.currentUserProfile && UserState.currentUserProfile.roleId == "1") || (RegistrationState.decodedBeacon && RegistrationState.decodedBeacon.beaconType && RegistrationState.decodedBeacon.beaconType.includes("ELT")) ? (
                  ""
                ) : (
                  <Popup content={i18n.t("i-understand-that-by-not-providing-an-e-mail")} trigger={<Form.Checkbox name="dontHaveEmail" defaultChecked={values.dontHaveEmail} label={i18n.t("i-do-not-have-an-e-mail-address-that-can-be-used-for-this-purpose")} style={{ marginTop: "10px" }} onChange={this.dontHaveEmailToggle} />} />
                )}
              </Form.Field>

              <Form.Field>
                <label>
                  {i18n.t("communication-language")}
                  <Popup content={i18n.t("preferred-language-of-verbal-communication")} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
                </label>
                <Form.Input name="operatorLanguage" fluid placeholder="" className="editing-input" onChange={handleChange} onBlur={handleBlur} value={values.operatorLanguage} />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <label>
                  {i18n.t("username")} <span style={{ color: "red" }}>* </span>
                  <Popup content={i18n.t("username-must-be-unique-and-not-already-exist-in-the-ibrd-it-can-not-be-an-email-address-or-a-hex-id")} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
                </label>
                {/* <Form.Input name="username" fluid placeholder="John doe" onChange={handleChange} error={hasUsernameError} onBlur={handleBlur} value={values.username} disabled={editUser} className={editUser ? "" : "editing-input"} required /> */}

                <Form.Input name="username" fluid placeholder="John doe" onChange={this.handleChangeUsername} error={hasUsernameError} onBlur={handleBlur} value={values.username} disabled={editUser} className={editUser ? "" : "editing-input"} required />
              </Form.Field>

              <>
                <Form.Field>
                  <label>
                    {i18n.t("password")} <span style={{ color: "red" }}>* </span>
                    <Popup content={i18n.t("the-password-must-be-8-or-more-characters-and-have-one-each-of-upper-case-letter-lower-case-letter-number-and-symbol")} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
                  </label>
                  <Form.Input
                    name="password"
                    fluid
                    placeholder={i18n.t("password")}
                    autoComplete="new-password"
                    type={isRevealPassword ? "text" : "password"}
                    className="editing-input"
                    error={hasPasswordError}
                    onChange={this.handleChangePassword}
                    onBlur={handleBlur}
                    value={valuePassword}
                    required
                    icon={isRevealPassword ? <Icon name="eye" link className="eyeIcon showPassword" onClick={this.toggleRevealPassword} /> : <Icon name="eye slash" link className="eyeIcon showPassword" onClick={this.toggleRevealPassword} />}
                  />
                </Form.Field>

                {/* <div className="revealPassword" onClick={this.toggleRevealPassword}>
                    {isRevealPassword ? <Icon name="eye" className="eyeIcon" /> : <Icon name="eye slash" className="eyeIcon" />}
                  </div> */}

                <Form.Input
                  name="passwordConfirm"
                  fluid
                  label={i18n.t("confirm-password")}
                  autoComplete="new-password"
                  placeholder="confirm"
                  type={isRevealPassword ? "text" : "password"}
                  className="editing-input"
                  error={hasPasswordConfirmError}
                  onChange={this.handleChangePasswordConfirm}
                  onBlur={handleBlur}
                  required
                  value={valuePasswordConfirm}
                  onPaste={(e) => {
                    e.preventDefault();
                  }}
                />
              </>
            </Form.Group>

            <Form.Group>
              {/* <Form.Input
                fluid
                name="contactNumber"
                label=""
                placeholder="#"
                disabled
                options={contactNoOption}
                width={2}
                value={1}
                onChange={handleChange}
                onBlur={handleBlur}
                className="contact-number"
              /> */}

              <Form.Field width={8}>
                <label>
                  {i18n.t("phone-number")}
                  <span style={{ color: "red" }}> * </span>
                  <Popup content={i18n.t("between-one-and-four-telephone-numbers-may-be-entered")} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
                </label>
                <Form.Input fluid name="phone1Num" placeholder={i18n.t("phone-number")} error={hasContactPhoneError} onChange={handleChange} onBlur={handleBlur} required value={values.phone1Num} className="phoneNumber editing-input" />
              </Form.Field>

              <Form.Field width={8}>
                <label>
                  {i18n.t("phone-number-type")} <span style={{ color: "red" }}>* </span>
                </label>
                <Form.Group widths="equal" className="phone-type-other">
                  <Form.Select
                    fluid
                    name="phone1Type"
                    // label="Phone Number Type"
                    options={phoneTypeOption}
                    placeholder={i18n.t("phone-number-type")}
                    className="editing-select"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone1Type}
                    error={hasContactPhoneTypeError}
                    required
                  />
                  {values.phone1Type == "OTHR" ? <Form.Input fluid placeholder="" className="editing-input" name="phone1TypeOther" value={values.phone1TypeOther} onChange={handleChange} onBlur={handleBlur} /> : (values.phone1TypeOther = "")}
                </Form.Group>
              </Form.Field>
            </Form.Group>

            <AdditionnalContacts handleChange={handleChange} handleBlur={handleBlur} hasContactPhoneError={hasContactPhoneError} values={values} phoneTypeOption={phoneTypeOption} number={2} errors={phone2NumError} contact={contact} removeContactNumber={() => this.removeContactNumber("phone2Num", "phone2Type")} />
            <AdditionnalContacts handleChange={handleChange} handleBlur={handleBlur} hasContactPhoneError={hasContactPhoneError} values={values} phoneTypeOption={phoneTypeOption} number={3} errors={phone3NumError} contact={contact} removeContactNumber={() => this.removeContactNumber("phone3Num", "phone3Type")} />
            <AdditionnalContacts handleChange={handleChange} handleBlur={handleBlur} hasContactPhoneError={hasContactPhoneError} values={values} phoneTypeOption={phoneTypeOption} number={4} errors={phone4NumError} contact={contact} removeContactNumber={() => this.removeContactNumber("phone4Num", "phone4Type")} />

            {AnotherContactButton}

            {!UserState.userFromAdmin || UserState.userFromAdmin.roleId == "5" ? (
              <>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>
                      {i18n.t("medical-information")}
                      <Popup content="List any relevant medical information such as medications or conditions." trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
                    </label>
                    <Form.TextArea name="medicalInfo" placeholder={i18n.t("medical-information")} className="editing-input" onChange={handleChange} onBlur={handleBlur} value={values.medicalInfo} />
                  </Form.Field>
                </Form.Group>
              </>
            ) : (
              ""
            )}

            <Form.Group widths="equal">
              <Form.Select
                name="challengeQuestion"
                fluid
                label={i18n.t("select-a-security-question")}
                options={challengeQuestionsOptions}
                placeholder="Select"
                className="editing-select"
                error={haschallengeQuestionError}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.challengeQuestion}
                required={UserState.userFromAdmin && UserState.userFromAdmin.roleId != "1" ? false : true}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                name="challengeResponse"
                required={UserState.userFromAdmin && UserState.userFromAdmin.roleId != "1" ? false : true}
                fluid
                label={i18n.t("your-answer")}
                placeholder={i18n.t("your-answer")}
                className="editing-input"
                error={haschallengeResponseError}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.challengeResponse}
              />
            </Form.Group>

            <Grid>
              <Grid.Row centered>
                {/* <Grid.Column>
                  <Button type="button" className="registerUser-button">
                    Back
                  </Button>
                </Grid.Column> */}
                <Grid.Column style={{ textAlign: "center" }}>
                  <Button type="submit" primary className="registerUser-button" loading={isSubmitting} onClick={handleSubmit} style={{ margin: "0" }}>
                    {UserState.currentUserProfile ? i18n.t("update-account") : i18n.t("create-account")}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Segment>
        <Footer />
      </div>
    );
  }
}

const AdditionnalContacts = ({ handleChange, handleBlur, values, phoneTypeOption, number, contact, removeContactNumber, errors }) => {
  // if (contact < number) {
  //   return null;
  // }

  if (!values["phone" + number + "Num"] && contact < number) {
    return null;
  }

  // let hasError = errors["contactPhone" + number];
  const t = AppState.t;
  return (
    <Form.Group className="additional-phone">
      {/* <Form.Input fluid name="contactNumber" label="" placeholder="#" disabled width={2} value={number} onChange={handleChange} onBlur={handleBlur} className="contact-number" /> */}

      <Form.Field width={8}>
        <label>
          Phone Number
          <Popup content={i18n.t("between-one-and-four-telephone-numbers-may-be-entered")} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
        </label>
        <Form.Input fluid name={"phone" + number + "Num"} placeholder={i18n.t("phone-number")} className="phoneNumber editing-input" error={errors} onChange={handleChange} onBlur={handleBlur} required value={values["phone" + number + "Num"]} />
      </Form.Field>

      <Form.Field width={8}>
        <label>Phone Number Type</label>
        <Form.Group widths="equal" className="phone-type-other">
          <Form.Select
            fluid
            name={"phone" + number + "Type"}
            // label="Phone Number Type"
            options={phoneTypeOption}
            placeholder="phoneType"
            className="editing-select"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values["phone" + number + "Type"]}
            required
            action="Search"
          />
          {values["phone" + number + "Type"] == "OTHR" ? <Form.Input fluid placeholder="" className="editing-input" name={"phone" + number + "TypeOther"} value={values["phone" + number + "TypeOther"]} onChange={handleChange} onBlur={handleBlur} /> : (values["phone" + number + "TypeOther"] = "")}
        </Form.Group>
      </Form.Field>

      {/* <Button icon circular className="registerUser-additionnalContactRemove" size="tiny" onClick={removeContactNumber}>
        {" "}
        <Icon name="minus circle" color="red" size="large" />
      </Button> */}
      <Popup
        content={i18n.t("delete-this-number")}
        trigger={
          <Button icon circular className="registerUser-additionnalContactRemove" size="tiny" onClick={removeContactNumber}>
            {" "}
            <Icon name="trash circle" color="red" size="large" />
          </Button>
        }
      />
    </Form.Group>
  );
};

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const NewUserForm = observer(
  withSemanticUIFormik({
    mapPropsToValues: ({}) => {
      let userFromState = RegistrationState.userToEdit || UserState.currentUserProfile || UserState.delegatedUser || UserState.userFromAdmin;
      let user = toJS(userFromState);
      if (user) {
        if (user.firstName && user.lastName) {
          user.ownerName = user.firstName + " " + user.lastName;
        }

        let currentUserName = UserState.currentUser ? UserState.currentUser.name : "";
        if (UserState.delegatedUser) {
          currentUserName = UserState.delegatedUser.emailAddress;
        } else if (UserState.userFromAdmin) {
          currentUserName = UserState.userFromAdmin.username;
        }

        return {
          ownerName: user.ownerName || "",
          contactNumber: user.contactNumber || "1",
          phone1Num: user.phone1Num || "",
          phone1Type: user.phone1Type || "",
          phone2Num: user.phone2Num || "",
          phone2Type: user.phone2Type || "",
          phone3Num: user.phone3Num || "",
          phone3Type: user.phone3Type || "",
          phone4Num: user.phone4Num || "",
          phone4Type: user.phone4Type || "",
          address: user.address || "",
          mailCode: user.mailCode || "",
          city: user.city || "",
          province: user.province || "",
          mailCountry: user.mailCountry || "",
          emailAddress: user.emailAddress || "",
          username: currentUserName || "",
          password: "",
          passwordConfirm: "",
          medicalInfo: user.medicalInfo || "",
          challengeQuestion: user.challengeQuestion || "",
          challengeResponse: user.challengeResponse || "",
          operatorLanguage: user.operatorLanguage || "",
          _id: user._id,
          roleId: user.roleId,
          dontHaveEmail: user.dontHaveEmail || "",
          phone1TypeOther: user.phone1TypeOther || "",
          phone2TypeOther: user.phone2TypeOther || "",
          phone3TypeOther: user.phone3TypeOther || "",
          phone4TypeOther: user.phone4TypeOther || "",
          countryNumber: user.countryNumber || []
        };
      } else {
        return {
          ownerName: "",
          contactNumber: "",
          phone1Num: "",
          phone1Type: "",
          phone2Num: "",
          phone2Type: "",
          phone3Num: "",
          phone3Type: "",
          phone4Num: "",
          phone4Type: "",
          address: "",
          mailCode: "",
          city: "",
          province: "",
          mailCountry: "",
          emailAddress: "",
          username: "",
          password: "",
          passwordConfirm: "",
          medicalInfo: "",
          challengeQuestion: "",
          challengeResponse: "",
          operatorLanguage: "",
          phone1TypeOther: "",
          phone2TypeOther: "",
          phone3TypeOther: "",
          phone4TypeOther: ""
        };
      }
    },
    validationSchema: function() {
      let user = UserState.currentUserProfile;
      if (user) {
        if (UserState.tmpPassword == UserState.defaultTmpPassword) {
          return Yup.object().shape({
            ownerName: Yup.string().required(i18n.t("name-is-required")),
            // password: Yup.string()
            //   .required("Password is required")
            //   .min(8, "The password must be 8 or more characters and have one each of: upper-case letter, lower-case letter, number and symbol.")
            //   .matches(
            //     new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*+=-_()])(?=.{8,})"),
            //     "The password must be 8 or more characters and have one each of: upper-case letter, lower-case letter, number and symbol."
            //   ),
            // passwordConfirm: Yup.string()
            //   .oneOf([Yup.ref("password"), null], "Password must match confirmation")
            //   .required("Confirm password is required"),
            phone1Num: Yup.string().required(i18n.t("phone-number-is-required")),
            phone2Num: Yup.string(),
            phone3Num: Yup.string(),
            phone4Num: Yup.string(),
            phone1Type: Yup.string().required(i18n.t("phone-number-type-is-required")),
            challengeQuestion: Yup.string().required(i18n.t("security-question-is-required")),
            challengeResponse: Yup.string().required(i18n.t("security-answer-is-required")),
            dontHaveEmail: Yup.boolean(),
            emailAddress: Yup.string().when("dontHaveEmail", {
              is: (value) => value == false || value == undefined,
              then: Yup.string()
                .required(i18n.t("e-mail-address-is-required"))
                .email(i18n.t("e-mail-format-is-invalid"))
            })
          });
        } else {
          return Yup.object().shape({
            ownerName: Yup.string().required(i18n.t("name-is-required")),
            password: Yup.string()
              .required(i18n.t("password-is-required"))
              .min(8, i18n.t("the-password-must-be-8-or-more-characters-and-have-one-each-of-upper-case-letter-lower-case-letter-number-and-symbol-0"))
              .matches(new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*+=-_()])(?=.{8,})"), i18n.t("the-password-must-be-8-or-more-characters-and-have-one-each-of-upper-case-letter-lower-case-letter-number-and-symbol-0")),
            passwordConfirm: Yup.string()
              .oneOf([Yup.ref("password"), null], i18n.t("password-must-match-confirmation"))
              .required(i18n.t("confirm-password-is-required")),
            phone1Num: Yup.string().required(i18n.t("phone-number-is-required")),
            phone2Num: Yup.string(),
            phone3Num: Yup.string(),
            phone4Num: Yup.string(),
            phone1Type: Yup.string().required(i18n.t("phone-number-type-is-required")),
            challengeQuestion: Yup.string().required(i18n.t("security-question-is-required")),
            challengeResponse: Yup.string().required(i18n.t("security-answer-is-required")),
            dontHaveEmail: Yup.boolean(),
            emailAddress: Yup.string().when("dontHaveEmail", {
              is: (value) => value == false || value == undefined,
              then: Yup.string()
                .required(i18n.t("e-mail-address-is-required"))
                .email(i18n.t("e-mail-format-is-invalid"))
            })
          });
        }
      } else if (UserState.delegatedUser || (UserState.userFromAdmin && UserState.userFromAdmin.roleId == "1")) {
        return Yup.object().shape({
          ownerName: Yup.string().required(i18n.t("name-is-required")),
          password: Yup.string()
            .required(i18n.t("password-is-required"))
            .min(8, i18n.t("the-password-must-be-8-or-more-characters-and-have-one-each-of-upper-case-letter-lower-case-letter-number-and-symbol-0"))
            .matches(new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*+=-_()])(?=.{8,})"), i18n.t("the-password-must-be-8-or-more-characters-and-have-one-each-of-upper-case-letter-lower-case-letter-number-and-symbol-0")),
          passwordConfirm: Yup.string()
            .oneOf([Yup.ref("password"), null], i18n.t("password-must-match-confirmation"))
            .required(i18n.t("confirm-password-is-required")),
          phone1Num: Yup.string().required(i18n.t("phone-number-is-required")),
          phone2Num: Yup.string(),
          phone3Num: Yup.string(),
          phone4Num: Yup.string(),
          phone1Type: Yup.string().required(i18n.t("phone-number-type-is-required")),
          challengeQuestion: Yup.string().required(i18n.t("security-question-is-required")),
          challengeResponse: Yup.string().required(i18n.t("security-answer-is-required")),
          dontHaveEmail: Yup.boolean(),
          emailAddress: Yup.string().when("dontHaveEmail", {
            is: (value) => value == false || value == undefined,
            then: Yup.string()
              .required(i18n.t("e-mail-address-is-required"))
              .email(i18n.t("e-mail-format-is-invalid"))
          })
        });
      } else if (UserState.userFromAdmin) {
        return Yup.object().shape({
          ownerName: Yup.string().required(i18n.t("name-is-required")),
          password: Yup.string()
            .required(i18n.t("password-is-required"))
            .min(8, i18n.t("the-password-must-be-8-or-more-characters-and-have-one-each-of-upper-case-letter-lower-case-letter-number-and-symbol-0"))
            .matches(new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*+=-_()])(?=.{8,})"), i18n.t("the-password-must-be-8-or-more-characters-and-have-one-each-of-upper-case-letter-lower-case-letter-number-and-symbol-0")),
          passwordConfirm: Yup.string()
            .oneOf([Yup.ref("password"), null], i18n.t("password-must-match-confirmation"))
            .required("Confirm password is required"),
          phone1Num: Yup.string().required("Phone Number is required"),
          phone2Num: Yup.string(),
          phone3Num: Yup.string(),
          phone4Num: Yup.string(),
          phone1Type: Yup.string().required("Phone Number Type is required"),
          //challengeQuestion: Yup.string().required("Security question is required"),
          //challengeResponse: Yup.string().required("Security answer is required"),
          dontHaveEmail: Yup.boolean(),
          emailAddress: Yup.string().when("dontHaveEmail", {
            is: (value) => value == false || value == undefined,
            then: Yup.string()
              .required("E-mail address is required")
              .email("E-mail format is invalid")
          })
        });
      }

      return Yup.object().shape({
        ownerName: Yup.string().required(i18n.t("name-is-required")),
        username: Yup.string()
          .required(i18n.t("username-is-required-0"))
          .test("not-email", i18n.t("username-should-not-be-email-format"), (value) => !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)),
        password: Yup.string()
          .required(i18n.t("password-is-required"))
          .min(8, i18n.t("the-password-must-be-8-or-more-characters-and-have-one-each-of-upper-case-letter-lower-case-letter-number-and-symbol-0"))
          .matches(new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*+=-_()])(?=.{8,})"), i18n.t("the-password-must-be-8-or-more-characters-and-have-one-each-of-upper-case-letter-lower-case-letter-number-and-symbol-0")),
        passwordConfirm: Yup.string()
          .oneOf([Yup.ref("password"), null], i18n.t("password-must-match-confirmation"))
          .required(i18n.t("confirm-password-is-required")),
        phone1Num: Yup.string().required(i18n.t("phone-number-is-required")),
        phone2Num: Yup.string(),
        phone3Num: Yup.string(),
        phone4Num: Yup.string(),
        phone1Type: Yup.string().required(i18n.t("phone-number-type-is-required")),
        challengeQuestion: Yup.string().required(i18n.t("security-question-is-required")),
        challengeResponse: Yup.string().required(i18n.t("security-answer-is-required")),
        dontHaveEmail: Yup.boolean(),
        emailAddress: Yup.string().when("dontHaveEmail", {
          is: (value) => value == false || value == undefined,
          then: Yup.string()
            .required(i18n.t("e-mail-address-is-required"))
            .email(i18n.t("e-mail-format-is-invalid"))
        })
      });
    },

    handleSubmit: async (values, { setSubmitting, isValid, setStatus, setErrors }) => {
      console.log("---> handleSubmit values : ", isValid, values);
      delete values.dontHaveEmail; // remove decorate field
      if (values.username) {
        values.username = values.username.replace(/\s+/g, ""); // remove space
      }

      if (values.emailAddress) values.emailAddress = values.emailAddress.toLowerCase();

      let decodedBeacon = RegistrationState.decodedBeacon;
      if (decodedBeacon && decodedBeacon.beacontype && decodedBeacon.beaconType.includes("ELT")) {
        if (!values.emailAddress) {
          setStatus(i18n.t("e-mail-is-required-for-elt-beacon-user"));
        }
      }

      if (values._id) {
        setSubmitting(true);
        // debugger;
        // check existing if change to new email
        if (UserState.currentUserProfile && values.emailAddress !== UserState.currentUserProfile.emailAddress) {
          let emailExist = await RegistrationState.checkIfEmailExist(values.emailAddress);
          if (emailExist) {
            setSubmitting(false);
            setErrors({ emailAddress: i18n.t("e-mail-already-exists") });
            return;
          }
        }

        if (UserState.delegatedUser) {
          let username = UserState.delegatedUser.emailAddress;
          let password = values.password;
          UserState.saveDelegatedUserProfile(values)
            .then((result) => {
              console.log(">>> saved delegated user: ", UserState.delegatedUser);
              setStatus(i18n.t("user-profile-saved"));
              setSubmitting(false);

              UserState.activateDelegatedToken(UserState.delegatedToken, UserState.delegatedUser).then((result) => {
                console.log(">>> activate delegated token: ", result);
                UserState.resetDelegatedToken();

                UserState.login(username, password).then((result) => {
                  console.log(">>> auto login: ", result);
                  RouteState.setRoute("/dashboard");
                });
              });
            })
            .catch((error) => {
              console.error("log: error", error);
              setStatus(i18n.t("error-saving-profile"));
              setSubmitting(false);
            });
        } else if (UserState.userFromAdmin) {
          let username = values.username;
          let password = values.password;

          if (UserState.userFromAdmin.isTmpProfile) {
            // need to signup first
            let updatedData = { ...UserState.legacyUserProfile, ...values };
            let result = await UserState.signUpLegacyUserWithEmail(updatedData, false);
            // console.log("------------> signup tmp profile: ", result);

            if (!result.error) {
              setStatus(i18n.t("user-profile-saved"));
              setSubmitting(false);

              await UserState.resetUserFromAdmin();
              let loggedIn = await UserState.loginWithoutDisclaimer(username, password);
              if (loggedIn) {
                RouteState.setRoute("/dashboard");
              }
            } else {
              console.error("log: error", result.error);
              setStatus(i18n.t("error-saving-profile"));
              setSubmitting(false);
            }
          } else {
            UserState.saveUserFromAdminProfile(values)
              .then((result) => {
                setStatus(i18n.t("user-profile-saved"));
                setSubmitting(false);

                UserState.activateUserFromAdmin(UserState.userFromAdminToken, UserState.userFromAdmin).then((result) => {
                  UserState.resetUserFromAdmin();

                  UserState.loginWithoutDisclaimer(username, password).then((result) => {
                    RouteState.setRoute("/dashboard");
                  });
                });
              })
              .catch((error) => {
                console.error("log: error", error);
                setStatus(i18n.t("error-saving-profile"));
                setSubmitting(false);
              });
          }
        } else {
          if (UserState.tmpPassword == UserState.defaultTmpPassword) {
            UserState.saveUserProfile(values)
              // save without password if logged in because changed requirement with hide password field
              .then((result) => {
                console.log(">>> saved user: ", UserState.currentUserProfile);
                setStatus(i18n.t("user-profile-saved"));
                setSubmitting(false);
              })
              .catch((error) => {
                console.error("log: error", error);
                setStatus(i18n.t("error-saving-profile"));
                setSubmitting(false);
              });
          } else {
            UserState.saveUserProfileWithPassword(values)
              .then((result) => {
                console.log(">>> saved user with password: ", UserState.currentUserProfile);
                setStatus(i18n.t("user-profile-saved"));
                setSubmitting(false);
              })
              .catch((error) => {
                console.error("log: error", error);
                setStatus(i18n.t("error-saving-profile"));
                setSubmitting(false);
              });
          }
        }
      } else {
        try {
          setSubmitting(true);
          let emailExist = await RegistrationState.checkIfEmailExist(values.emailAddress);
          if (emailExist) {
            setSubmitting(false);
            setErrors({ emailAddress: i18n.t("e-mail-already-exists") });
            return;
          }
          let usernameExist = await RegistrationState.checkIfUsernameExist(values);
          if (usernameExist) {
            setSubmitting(false);
            setErrors({ username: i18n.t("username-already-exists") });
            return;
          }

          // check username cannot be hex id
          try {
            let decodedBeacon = await beaconDecode(values.username);
            if (decodedBeacon && decodedBeacon.hexId == values.username) {
              setSubmitting(false);
              setErrors({ username: i18n.t("username-should-not-be-hex-id") });
              return;
            }
          } catch (error) {
            console.log("log: registerUserForm -> decodedBeacon -> error: ", error);
          }

          let signupResult = await RegistrationState.signUp(values);
          let loginResult = await UserState.login(values.username, values.password);
          try {
            window.gtag("event", "profmgmt_success", {
              user: values.username
            });
          } catch (error) {
            console.log("log ~ UserState ~ login ~ gtag error:", error);
          }
          setSubmitting(false);
          RouteState.setRoute("/newBeacon");
        } catch (error) {
          console.log("handleSubmit: error", error);
          setSubmitting(false);
          setStatus(error);
        }
      }
    }
  })(observer(InnerForm))
);

export default NewUserForm;
