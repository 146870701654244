export default function getFiveCharChecksum(bcnId) {
  let returnLimit = 1048576; //used to limit the return value to a 20 bit result
  let runningSumLimit = 538471; // large prime which will not cause an overflow
  let constPrimVal = 3911; //small prime value that stays constant throughout
  let modifierLimit = 3847; //small prime which will not cause an overflow
  let modifier = 3803; //modifier, simply initialized to a prime value
  let runningSum = 0; //variable to hold the running value of the checksum
  let tmpLongValue = 0; //holds temporary data
  let decimalValue = 0; //holds decimal value for character
  // Note: int data type is 4 bytes, largest positive value is 2,147,483,647 and
  //  all computations are designed to remain within this value (no overflows)
  for (var i = 0; i < bcnId.length; i++) {
    let char = bcnId[i];
    decimalValue = hexToASCIIDecimals[char];
    //console.log("log: getFiveCharChecksum -> decimalValue", decimalValue, char, i);
    tmpLongValue = runningSum * modifier + decimalValue;
    // on last character here use the higher resolution result as input to final truncation
    if (i == bcnId.length - 1) {
      runningSum = tmpLongValue % returnLimit;
      //console.log("log: getFiveCharChecksum -> runningSum", runningSum);
    } else {
      runningSum = tmpLongValue % runningSumLimit;
      //console.log("log: getFiveCharChecksum -> runningSum", runningSum);
      modifier = (constPrimVal * modifier) % modifierLimit;
    }
  }
  let hexValue = runningSum.toString(16).toUpperCase().padStart(5, '0');
  //console.log("log: getFiveCharChecksum -> hexValue", hexValue);

  return hexValue;
}

let hexToASCIIDecimals = { //it was actually to charCode after doing some research, the paper just had weird wordings. 
  "0": 48,
  "1": 49,
  "2": 50,
  "3": 51,
  "4": 52,
  "5": 53,
  "6": 54,
  "7": 55,
  "8": 56,
  "9": 57,
  A: 65,
  B: 66,
  C: 67,
  D: 68,
  E: 69,
  F: 70,
};
