import i18n from "i18next";
import React, { Component, useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { Form, Input, Button, Message, Transition, Header, Grid, Popup, Icon } from "semantic-ui-react";
import RegistrationState from "../../../state/registrationState";
import AppConfigState from "../../../state/appConfigState";
import withSemanticUIFormik from "../../../utils/formHelper";
import RouteState from "../../../state/routeState";
import UserState from "../../../state/userState";
import { FormErrorDisplay } from "../../../components/formErrorDisplay";

const titleOption = [{ key: "m", text: "Mr", value: "Mr" }, { key: "f", text: "Ms", value: "Ms" }];
let storedValues = {};
const InnerForm = (props) => {
  const { values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, isValid } = props;

  let hasError = Object.keys(errors).length !== 0;
  let primaryContactNameError = touched.primaryContactName && errors.primaryContactName && errors.primaryContactName.length > 0;
  let hasContactPhoneError = touched.primaryPhone1Num && errors.primaryPhone1Num && errors.primaryPhone1Num.length > 0;
  let hasContactPhoneTypeError = touched.primaryPhone1Type && errors.primaryPhone1Type && errors.primaryPhone1Type.length > 0;
  let primaryPhone2NumError = touched.primaryPhone2Num && errors.primaryPhone2Num && errors.primaryPhone2Num.length > 0;
  let primaryPhone3NumError = touched.primaryPhone3Num && errors.primaryPhone3Num && errors.primaryPhone3Num.length > 0;
  let primaryPhone4NumError = touched.primaryPhone4Num && errors.primaryPhone4Num && errors.primaryPhone4Num.length > 0;

  let alternatePhone1NumError = touched.alternatePhone1Num && errors.alternatePhone1Num && errors.alternatePhone1Num.length > 0;
  let alternatePhone1TypeError = touched.alternatePhone1Type && errors.alternatePhone1Type && errors.alternatePhone1Type.length > 0;
  let alternatePhone2NumError = touched.alternatePhone2Num && errors.alternatePhone2Num && errors.alternatePhone2Num.length > 0;
  let alternatePhone3NumError = touched.alternatePhone3Num && errors.alternatePhone3Num && errors.alternatePhone3Num.length > 0;
  let alternatePhone4NumError = touched.alternatePhone4Num && errors.alternatePhone4Num && errors.alternatePhone4Num.length > 0;

  const phoneTypeOption = AppConfigState.phoneTypes;
  let canEditByStatus =
    props.decodedBeacon.specialStatus == "ACTIVE" || props.decodedBeacon.specialStatus == undefined || props.decodedBeacon.specialStatus == "RELEASETOMAINTENANCE";

  if (UserState.notAllowToEditByRoleId.includes(UserState.currentUserProfile.roleId)) {
    canEditByStatus = false;
  }

  // Emergency Contact Phone Number
  const [contact, setContact] = useState(1);
  const addContactNumber = () => {
    // setContact(contact + 1);

    let totalPhone = document.getElementsByClassName("primaryPhone").length;
    setContact(totalPhone + 1);
  };
  const removeContactNumber = (fieldName, phoneType) => {
    props.values[fieldName] = "";
    props.values[phoneType] = "";
    setContact(contact - 1);
  };
  var AnotherContactButton;
  if (contact < 4) {
    AnotherContactButton = (
      <Form.Group widths="equal">
        <a
          className="registerUserForm-addContactsButton"
          onClick={
            canEditByStatus
              ? addContactNumber
              : (e) => {
                  e.preventDefault();
                }
          }
        >
          <Icon name="plus circle" color="red" size="large" />
          {"   "}
          {i18n.t('add-another-emergency-contact-phone-number')}
        </a>
      </Form.Group>
    );
  }

  // Alternate Contact Phone Number
  const [alternateContact, setAlternateContact] = useState(1);
  const addAlternateNumber = () => {
    // setAlternateContact(alternateContact + 1);

    let totalPhone = document.getElementsByClassName("alternatePhone").length;
    setAlternateContact(totalPhone + 1);
  };
  const removeAlternateNumber = (fieldName, phoneType) => {
    props.values[fieldName] = "";
    props.values[phoneType] = "";
    setAlternateContact(alternateContact - 1);
  };
  var alternateContactButton;
  if (alternateContact < 4) {
    alternateContactButton = (
      <Form.Group widths="equal">
        <a
          className="registerUserForm-addContactsButton"
          onClick={
            canEditByStatus
              ? addAlternateNumber
              : (e) => {
                  e.preventDefault();
                }
          }
        >
          <Icon name="plus circle" color="red" size="large" />
          {"   "}
          {i18n.t('add-another-alternate-contact-phone-number')}
        </a>
      </Form.Group>
    );
  }

  const saveAndClose = () => {
    if (!RegistrationState.isNewRegisterBeacon) {
      RegistrationState.setIsSaving(true);
      RegistrationState.updateBeaconFields(props.values);
      RegistrationState.updateBeacon().then(() => {
        RegistrationState.setIsBeaconSaved(true);
        props.onClose();
        RegistrationState.setIsSaving(false);
      });
    }
  };
  storedValues = values;
  useEffect(() => {
    if (!RegistrationState.isNewRegisterBeacon) {
      var topPosition = document.getElementById("editBeaconModal");
      topPosition.scrollIntoView();
    } else {
      window.scrollTo({ top: 0 });
    }

    return function cleanup() {
      if (!RegistrationState.isNewRegisterBeacon) {
        console.log("log: InnerForm -> cleanup -> ", storedValues);
        //RegistrationState.updateBeaconFields(storedValues);
      }
    };
  }, []);

  // scroll to error
  if (isSubmitting && Object.keys(errors).length > 0) {
    var errorBox = document.getElementById("scrollToError");
    errorBox.scrollIntoView();
  }

  const saveToStateBeforeHandleChange = function(e, data) {
    console.log("log: InnerForm -> saveToStateBeforeHandleChange -> name, value", data.name, data.value);
    RegistrationState.saveOnFieldChange(data.name, data.value);
    handleChange(e, data);
  };

  return (
    <Form id="mainForm" className="beaconLocation-form animated fadeIn faster" error={hasError}>
      <Header as="h2" icon textAlign="center">
        <Header.Content>{i18n.t('please-supply-the-following-information-about-your-emergency-contact')}</Header.Content>
      </Header>
      <div id="scrollToError" />
      <FormErrorDisplay errors={errors} touched={touched} status={status} />
      <div className="required-field-notation">* required fields</div>
       <Header as="h3">{i18n.t('primary-emergency-contact')}</Header>
      <Form.Group>
        <Form.Field width={16}>
          <label>
            {i18n.t('24-hour-emergency-contact-name')} <span style={{ color: "red" }}>*</span>
            <Popup trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}>
              {i18n.t('name-of-person-to-contact')},{" "}
              <b>
                <u>{i18n.t('other-than-user')}</u>
              </b>
              , {i18n.t('in-event-of-detection-of-beacon-activation')}
            </Popup>
          </label>
          <Form.Input
            fluid
            placeholder=""
            name="primaryContactName"
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            value={values.primaryContactName}
            error={primaryContactNameError}
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-input" : ""}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input
          fluid
          label={i18n.t('emergency-contact-address-line-1')}
          placeholder=""
          name="primaryContactAddressLine1"
          onChange={saveToStateBeforeHandleChange}
          onBlur={handleBlur}
          value={values.primaryContactAddressLine1}
          disabled={!canEditByStatus}
          className={canEditByStatus ? "editing-input" : ""}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input
          fluid
          label={i18n.t('emergency-contact-address-line-2')}
          placeholder=""
          name="primaryContactAddressLine2"
          onChange={saveToStateBeforeHandleChange}
          onBlur={handleBlur}
          value={values.primaryContactAddressLine2}
          disabled={!canEditByStatus}
          className={canEditByStatus ? "editing-input" : ""}
        />
      </Form.Group>

      <Form.Group widths="equal">
        {/* <Form.Input fluid label="" placeholder="#" disabled width={2} value={1} onChange={handleChange} onBlur={handleBlur} className="contact-number" /> */}

        <Form.Field width={8}>
          <label>
            {i18n.t('emergency-contact-phone-number')} <span style={{ color: "red" }}>*</span>
            <Popup
              content={i18n.t('between-one-and-four-telephone-numbers-may-be-entered-for-primary-emergency-contact-an-appropriate-telephone-number-type-must-be-selected')}
              trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
            />
          </label>
          <Form.Input
            fluid
            placeholder=""
            name="primaryPhone1Num"
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            value={values.primaryPhone1Num}
            error={hasContactPhoneError}
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-input primaryPhone" : "primaryPhone"}
          />
        </Form.Field>

        <Form.Field width={8}>
          <label>
            {i18n.t('emergency-contact-phone-number-type')} <span style={{ color: "red" }}>*</span>
          </label>
          <Form.Group widths="equal" className="phone-type-other">
            <Form.Select
              fluid
              options={phoneTypeOption}
              placeholder="Select"
              name="primaryPhone1Type"
              onChange={saveToStateBeforeHandleChange}
              onBlur={handleBlur}
              value={values.primaryPhone1Type}
              error={hasContactPhoneTypeError}
              disabled={!canEditByStatus}
              className={canEditByStatus ? "editing-select" : ""}
            />
            {values.primaryPhone1Type == "OTHR" ? (
              <Form.Input
                fluid
                placeholder=""
                name="primaryPhone1TypeOther"
                value={values.primaryPhone1TypeOther}
                onChange={handleChange}
                onBlur={handleBlur}
                className={canEditByStatus ? "editing-input" : ""}
                disabled={!canEditByStatus}
              />
            ) : (
              (values.primaryPhone1TypeOther = "")
            )}
          </Form.Group>
        </Form.Field>
      </Form.Group>
      <AdditionnalContacts
        handleChange={saveToStateBeforeHandleChange}
        handleBlur={handleBlur}
        hasContactPhoneError={hasContactPhoneError}
        values={values}
        phoneTypeOption={phoneTypeOption}
        number={2}
        errors={primaryPhone2NumError}
        contact={contact}
        removeContactNumber={() => removeContactNumber("primaryPhone2Num", "primaryPhone2Type")}
        namePrefix="primaryPhone"
        labelPrefix="Emergency Contact Phone Number"
        canEditByStatus={canEditByStatus}
      />
      <AdditionnalContacts
        handleChange={saveToStateBeforeHandleChange}
        handleBlur={handleBlur}
        hasContactPhoneError={hasContactPhoneError}
        values={values}
        phoneTypeOption={phoneTypeOption}
        number={3}
        errors={primaryPhone3NumError}
        contact={contact}
        removeContactNumber={() => removeContactNumber("primaryPhone3Num", "primaryPhone3Type")}
        namePrefix="primaryPhone"
        labelPrefix="Emergency Contact Phone Number"
        canEditByStatus={canEditByStatus}
      />
      <AdditionnalContacts
        handleChange={saveToStateBeforeHandleChange}
        handleBlur={handleBlur}
        hasContactPhoneError={hasContactPhoneError}
        values={values}
        phoneTypeOption={phoneTypeOption}
        number={4}
        errors={primaryPhone4NumError}
        contact={contact}
        removeContactNumber={() => removeContactNumber("primaryPhone4Num", "primaryPhone4Type")}
        namePrefix="primaryPhone"
        labelPrefix="Emergency Contact Phone Number"
        canEditByStatus={canEditByStatus}
      />

      {AnotherContactButton}
      	<Header as="h3">{i18n.t('alternate-emergency-contact')}</Header>
      {/* Alternate contact */}
      <Form.Group>
        <Form.Field width={16}>
          <label>
            {i18n.t('alternate-24-hour-emergency-contact-name')}
            <Popup trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}>
              {i18n.t('name-of-alternate-person-to-contact-in-event-of-detection-of-beacon-activation-if-primary-contact-unavailable')}
            </Popup>
          </label>
          <Form.Input
            fluid
            placeholder=""
            name="alternateContactName"
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            value={values.alternateContactName}
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-input" : ""}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input
          fluid
          label={i18n.t('alternate-contact-address-line-1')}
          placeholder=""
          name="alternateContactAddressLine1"
          onChange={saveToStateBeforeHandleChange}
          onBlur={handleBlur}
          value={values.alternateContactAddressLine1}
          disabled={!canEditByStatus}
          className={canEditByStatus ? "editing-input" : ""}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input
          fluid
          label={i18n.t('alternate-contact-address-line-2')}
          placeholder=""
          name="alternateContactAddressLine2"
          onChange={saveToStateBeforeHandleChange}
          onBlur={handleBlur}
          value={values.alternateContactAddressLine2}
          disabled={!canEditByStatus}
          className={canEditByStatus ? "editing-input" : ""}
        />
      </Form.Group>

      <Form.Group widths="equal">
        {/* <Form.Input fluid label="" placeholder="#" disabled width={2} value={1} onChange={handleChange} onBlur={handleBlur} className="contact-number" /> */}

        <Form.Field width={8}>
          <label>
            {i18n.t('alternate-contact-phone-number')}
            {values.alternateContactName && <span style={{ color: "red" }}>*</span>}
          </label>
          <Form.Input
            fluid
            placeholder=""
            name="alternatePhone1Num"
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            value={values.alternatePhone1Num}
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-input alternatePhone" : "alternatePhone"}
            error={alternatePhone1NumError}
          />
        </Form.Field>

        <Form.Field width={8}>
          <label>
            {i18n.t('alternate-contact-phone-number-type')}
            {values.alternateContactName && <span style={{ color: "red" }}>*</span>}
          </label>
          <Form.Group widths="equal" className="phone-type-other">
            <Form.Select
              fluid
              options={phoneTypeOption}
              placeholder="Select"
              name="alternatePhone1Type"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.alternatePhone1Type}
              disabled={!canEditByStatus}
              className={canEditByStatus ? "editing-select" : ""}
              error={alternatePhone1TypeError}
            />
            {values.alternatePhone1Type == "OTHR" ? (
              <Form.Input
                fluid
                placeholder=""
                name="alternatePhone1TypeOther"
                value={values.alternatePhone1TypeOther}
                onChange={saveToStateBeforeHandleChange}
                onBlur={handleBlur}
                className={canEditByStatus ? "editing-input" : ""}
                disabled={!canEditByStatus}
              />
            ) : (
              (values.alternatePhone1TypeOther = "")
            )}
          </Form.Group>
        </Form.Field>
      </Form.Group>
      <AdditionnalContacts
        handleChange={saveToStateBeforeHandleChange}
        handleBlur={handleBlur}
        hasContactPhoneError={hasContactPhoneError}
        values={values}
        phoneTypeOption={phoneTypeOption}
        number={2}
        errors={alternatePhone2NumError}
        contact={alternateContact}
        removeContactNumber={() => removeAlternateNumber("alternatePhone2Num", "alternatePhone2Type")}
        namePrefix="alternatePhone"
        labelPrefix="Alternate Contact Phone Number"
        canEditByStatus={canEditByStatus}
      />
      <AdditionnalContacts
        handleChange={saveToStateBeforeHandleChange}
        handleBlur={handleBlur}
        hasContactPhoneError={hasContactPhoneError}
        values={values}
        phoneTypeOption={phoneTypeOption}
        number={3}
        errors={alternatePhone3NumError}
        contact={alternateContact}
        removeContactNumber={() => removeAlternateNumber("alternatePhone3Num", "alternatePhone3Type")}
        namePrefix="alternatePhone"
        labelPrefix="Alternate Contact Phone Number"
        canEditByStatus={canEditByStatus}
      />
      <AdditionnalContacts
        handleChange={saveToStateBeforeHandleChange}
        handleBlur={handleBlur}
        hasContactPhoneError={hasContactPhoneError}
        values={values}
        phoneTypeOption={phoneTypeOption}
        number={4}
        errors={alternatePhone4NumError}
        contact={alternateContact}
        removeContactNumber={() => removeAlternateNumber("alternatePhone4Num", "alternatePhone4Type")}
        namePrefix="alternatePhone"
        labelPrefix="Alternate Contact Phone Number"
        canEditByStatus={canEditByStatus}
      />

      {alternateContactButton}

      {/* <Grid centered columns={3} stackable>
        <Grid.Column>
          <Button className="registerBeacon-submitButton" onClick={onBackClick}>
            Back
          </Button>
        </Grid.Column>
        <Grid.Column>
          <Button className="registerBeacon-submitButton" type="submit" primary onClick={handleSubmit}>
            Next
          </Button>
        </Grid.Column>
      </Grid> */}

        <div className="activityDetail-submitContainer">
          <Button className="registerBeacon-submitButton" onClick={onBackClick}>
            {i18n.t('back')}
          </Button>
          <Button className="registerBeacon-submitButton" type="submit" primary onClick={handleSubmit}>
            {i18n.t('next')}
          </Button>
        </div>
    </Form>
  );
};

function onBackClick() {
  RegistrationState.setOwnerPreviousStep();
}

const AdditionnalContacts = ({ handleChange, handleBlur, values, phoneTypeOption, number, contact, removeContactNumber, errors, namePrefix, labelPrefix, canEditByStatus }) => {
  // if (contact < number) {
  //   return null;
  // }

  if (!values[namePrefix + number + "Num"] && contact < number) {
    return null;
  }

  // let hasError = errors["contactPhone" + number];

  return (
    <Form.Group className="additional-phone">
      {/* <Form.Input fluid name="contactNumber" label="" placeholder="#" disabled width={2} value={number} onChange={handleChange} onBlur={handleBlur} className="contact-number" /> */}

      <Form.Field width={8}>
        <label>
          {/* Beacon Owner Telephone Number */}
          {labelPrefix}
          {/* <Popup
            content="Between one and four telephone numbers may be entered. An appropriate telephone number type must be selected."
            trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
          /> */}
        </label>
        <Form.Input
          fluid
          name={namePrefix + number + "Num"}
          placeholder="Phone number"
          error={errors}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values[namePrefix + number + "Num"]}
          disabled={!canEditByStatus}
          className={namePrefix + (canEditByStatus && " editing-input")}
        />
      </Form.Field>

      <Form.Field width={8}>
        <label>{labelPrefix + " " + " Type"}</label>
        <Form.Group widths="equal" className="phone-type-other">
          <Form.Select
            fluid
            name={namePrefix + number + "Type"}
            // label={labelPrefix + ' ' + number + " Type"}
            // label={labelPrefix + ' ' + " Type"}
            options={phoneTypeOption}
            placeholder="phoneType"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values[namePrefix + number + "Type"]}
            action="Search"
            disabled={!canEditByStatus}
            className={canEditByStatus && " editing-select"}
          />
          {values[namePrefix + number + "Type"] == "OTHR" ? (
            <Form.Input
              fluid
              placeholder=""
              name={namePrefix + number + "TypeOther"}
              value={values[namePrefix + number + "TypeOther"]}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!canEditByStatus}
              className={canEditByStatus && " editing-input"}
            />
          ) : (
            (values[namePrefix + number + "TypeOther"] = "")
          )}
        </Form.Group>
      </Form.Field>

      {/* <Button icon circular className="registerUser-additionnalContactRemove" size="tiny" onClick={removeContactNumber}>
        {" "}
        <Icon name="minus circle" color="red" size="large" />
      </Button> */}
      <Popup
        content={i18n.t('delete-this-number')}
        trigger={
          <Button icon circular className="registerUser-additionnalContactRemove" size="tiny" onClick={removeContactNumber}>
            {" "}
            <Icon name="trash circle" color="red" size="large" />
          </Button>
        }
      />
    </Form.Group>
  );
};

//const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const beaconLocationForm = withSemanticUIFormik({
  mapPropsToValues: ({ decodedBeacon }) => {
    if (decodedBeacon) {
      console.log("BeaconDetailsForm: decodedBeacon", decodedBeacon);
      return {
        primaryContactName: decodedBeacon.primaryContactName || "",
        primaryContactAddressLine1: decodedBeacon.primaryContactAddressLine1 || "",
        primaryContactAddressLine2: decodedBeacon.primaryContactAddressLine2 || "",
        primaryPhone1Num: decodedBeacon.primaryPhone1Num || "",
        primaryPhone1Type: decodedBeacon.primaryPhone1Type || "",
        primaryPhone2Num: decodedBeacon.primaryPhone2Num || "",
        primaryPhone2Type: decodedBeacon.primaryPhone2Type || "",
        primaryPhone3Num: decodedBeacon.primaryPhone3Num || "",
        primaryPhone3Type: decodedBeacon.primaryPhone3Type || "",
        primaryPhone4Num: decodedBeacon.primaryPhone4Num || "",
        primaryPhone4Type: decodedBeacon.primaryPhone4Type || "",

        alternateContactName: decodedBeacon.alternateContactName || "",
        alternateContactAddressLine1: decodedBeacon.alternateContactAddressLine1 || "",
        alternateContactAddressLine2: decodedBeacon.alternateContactAddressLine2 || "",
        alternatePhone1Num: decodedBeacon.alternatePhone1Num || "",
        alternatePhone1Type: decodedBeacon.alternatePhone1Type || "",
        alternatePhone2Num: decodedBeacon.alternatePhone2Num || "",
        alternatePhone2Type: decodedBeacon.alternatePhone2Type || "",
        alternatePhone3Num: decodedBeacon.alternatePhone3Num || "",
        alternatePhone3Type: decodedBeacon.alternatePhone3Type || "",
        alternatePhone4Num: decodedBeacon.alternatePhone4Num || "",
        alternatePhone4Type: decodedBeacon.alternatePhone4Type || "",

        primaryPhone1TypeOther: decodedBeacon.primaryPhone1TypeOther || "",
        primaryPhone2TypeOther: decodedBeacon.primaryPhone2TypeOther || "",
        primaryPhone3TypeOther: decodedBeacon.primaryPhone3TypeOther || "",
        primaryPhone4TypeOther: decodedBeacon.primaryPhone4TypeOther || "",
        alternatePhone1TypeOther: decodedBeacon.alternatePhone1TypeOther || "",
        alternatePhone2TypeOther: decodedBeacon.alternatePhone2TypeOther || "",
        alternatePhone3TypeOther: decodedBeacon.alternatePhone3TypeOther || "",
        alternatePhone4TypeOther: decodedBeacon.alternatePhone4TypeOther || "",
      };
    }
  },
  validationSchema: Yup.object().shape({
    primaryContactName: Yup.string().required(i18n.t('emergency-contact-name-is-required')),
    primaryPhone1Num: Yup.string()
      .required(i18n.t('emergency-contact-phone-number-is-required')),
    primaryPhone1Type: Yup.string().required(i18n.t('emergency-contact-phone-type-is-required')),
    primaryPhone2Num: Yup.string()
      .notRequired(),
    primaryPhone3Num: Yup.string()
      .notRequired(),
    primaryPhone4Num: Yup.string()
      .notRequired(),

    // if use alternate contact
    alternateContactName: Yup.string(),
    alternatePhone1Num: Yup.string().when("alternateContactName", {
      is: (value) => value,
      then: Yup.string()
        .required(i18n.t('alternate-contact-phone-number-is-required')),
      otherwise: Yup.string()
        .notRequired(),
    }),
    alternatePhone1Type: Yup.string().when("alternateContactName", {
      is: (value) => value,
      then: Yup.string().required(i18n.t('alternate-contact-phone-number-type-is-required')),
    }),
    alternatePhone2Num: Yup.string()
      .notRequired(),
    alternatePhone3Num: Yup.string()
      .notRequired(),
    alternatePhone4Num: Yup.string()
      .notRequired(),
  }),

  handleSubmit: (values, { setSubmitting, isValid }) => {
    console.log("ownerEmergencyContactForm: handleSubmit", values, isValid);
    RegistrationState.updateBeaconFields(values);
    RegistrationState.setOwnerNextStep();
  },
})(InnerForm);

export default beaconLocationForm;
