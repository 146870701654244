import React, { Component, useState, useEffect } from "react";
import DashboardState from "../../state/dashboardState";
import { observer } from "mobx-react";
import { Message, Segment, Header, Table, Grid, Input, Icon, Button, Dropdown, Divider, Pagination, Dimmer, Loader, Tab, Popup, Card, Modal } from "semantic-ui-react";
import "./dashboard.css";
import "./activities.css";
import RouteState from "../../state/routeState";
import ActivityState from "../../state/activityState";
import AssociatedUserState from "../../state/associatedUserState";
import AppConfigState from "../../state/appConfigState";
import VehicleState from "../../state/vehicleState";
import UserState from "../../state/userState";
import Users from "./users";
import Vehicle from "./vehicle";
import * as Utils from "../../utils/utils";
import "./beacons.css";
import { isBrowser, isMobile } from "react-device-detect";
import ActivityReview from "../registerActivity/activity/activityReview"
import { toJS } from "mobx";
import RegisterActivity from "../registerActivity/registerActivity";
import AccountDetail from "../../components/accountDetail";
import i18n from "i18next";

const paginationOptions = [
  { key: "25", text: "25", value: 25 },
  { key: "50", text: "50", value: 50 },
  { key: "100", text: "100", value: 100 },
  { key: "0", text: "All", value: 0 },
];

const searchFields = [
  {
    key: "Activity Name",
    text: "Activity Name",
    value: "activityName",
  },
  {
    key: "Account Owner Name",
    text: "Account Owner Name",
    value: "accountOwnerName"
  },
  {
    key: "Beacon Holder Name",
    text: "Beacon Holder Name",
    value: "holderName"
  },
];

export default class ActivitiesNavigation extends Component {
  state = {
    activeIndex: 0,
    showInstructions: false,
  };

  onSetTab = (e, data) => {
    this.setState({
      activeIndex: data.activeIndex,
    });
  };

  onInstructionClick = () => {
    this.setState({ showInstructions: !this.state.showInstructions });
  };

  render() {
    const { onAddActivity, onAddAssociatedUser, onAddVehicle } = this.props;
    const { showInstructions } = this.state;

    const panes = [
      {
        menuItem: i18n.t('activity'),
        render: () => (
          <Tab.Pane>
            <Activities onAddActivity={onAddActivity} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: i18n.t('participants'),
        render: () => (
          <Tab.Pane>
            <Users onAddAssociatedUser={onAddAssociatedUser} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: i18n.t('vehicles'),
        render: () => (
          <Tab.Pane>
            <Vehicle onAddVehicle={onAddVehicle} />
          </Tab.Pane>
        ),
      },
    ];

    let instructionText = i18n.t('show-instructions');
    if (showInstructions) instructionText = i18n.t('hide-instructions');

    return (
      <div>
        <Button floated="right" icon="send" className="activity-instructions-button" onClick={this.onInstructionClick}>
          {instructionText}
        </Button>
        <ActivityInstructions showInstructions={showInstructions} />
        <Tab className="dashboard-tab" attached="top" panes={panes} onTabChange={this.handleChange} defaultActiveIndex={ActivityState.activeTab} />
      </div>
    );
  }
}

function ActivityInstructions({ showInstructions }) {
  let data = AppConfigState.getPublishedPageContentWithCurrentLanguage("CreateActivityInstructions")[0];
  let instructions = null;
  if (showInstructions && data) {
    instructions = (
      <Message info className="activity-instructions animated fadeIn">
        <Message.Header dangerouslySetInnerHTML={{ __html: data.subject }} />
        <p dangerouslySetInnerHTML={{ __html: data.content }} />
      </Message>
    );
  }

  return instructions;
}

@observer
export class Activities extends Component {
  constructor(props) {
    super(props);
    this.dropdownRef = React.createRef();
  }

  state = {
    activityPerPage: 25,
    currentPage: 1,
    isLoading: true,

    // for filter
    searchOption: [],
    searchCriteria: [],
    searchCriteriaObj: {},
    searchType: searchFields[0].value,
    searchTypeLabel: searchFields[0].text,
    searchString: "",
    activeIndex: 0,
    displaySearch: false,
  };

  componentDidMount = async () => {
    this.onClearAllSearchCriteria();
  };

  onSetPage = (e, data) => {
    console.log("log: onSetPage -> data.activePage", data.activePage);
    this.setState({
      currentPage: data.activePage,
    });
  };

  onSetTab = (e, data) => {
    this.setState({
      activeIndex: data.activeIndex,
    });
  };

  onChangeactivityPerPage = (e, { value }) => {
    this.setState({
      activityPerPage: value,
      currentPage: 1,
    });
  };

  // for filter
  onSearchTypeChange = (e, { value }) => {
    let label = findLabelByValue(value, searchFields);
    console.log("log: onSearchTypeChange -> value, text", value, label);
    this.setState({
      searchType: value,
      searchTypeLabel: label,
    });
  };

  onAddCriteria = () => {
    let { searchCriteria, searchCriteriaObj, searchOption, searchType, searchString, searchTypeLabel } = this.state;
    let newCriteria = searchType + ":" + searchString;
    searchCriteriaObj[searchType] = searchString;
    let newCriteriaLabel = searchTypeLabel + ":" + searchString;
    if (!searchCriteria.includes(newCriteria) && searchString !== "") {
      searchOption.push({ key: newCriteria, text: newCriteriaLabel, value: newCriteria });
      searchCriteria.push(newCriteria);
      this.setState({
        searchOption: searchOption,
        searchCriteria: searchCriteria,
        searchString: "",
        searchCriteriaObj: searchCriteriaObj,
      });
      this.setState({
        isLoading: true,
      });

      const queryString = new URLSearchParams(window.location.search);
      if (queryString.has("owner")) {
        let ownerId = "org.couchdb.user:" + queryString.get("owner");
        searchCriteriaObj.owner = ownerId;
      }

      console.log('---> searchCriteriaObj: ', searchCriteriaObj)

      DashboardState.searchActivities(searchCriteriaObj).then((result) => {
        this.setState({
          isLoading: false,
        });
      });

      this.dropdownRef.current.handleClick(new Event("click")); //strangly the dropdown won't update until it receive a click
    }
  };

  onSearchStringChange = (e, { value }) => {
    this.setState({
      searchString: value,
    });
  };

  onChange = (e, { searchQuery, value }) => {
    this.setState({
      searchCriteria: value,
    });
  };

  onClearAllSearchCriteria = () => {
    const queryString = new URLSearchParams(window.location.search);
    let activityFilter = {};
    if (queryString.has("owner")) {
      let ownerId = "org.couchdb.user:" + queryString.get("owner");
      activityFilter.owner = ownerId;
    }

    this.setState({
      searchOption: [],
      searchCriteria: [],
      searchString: "",
      searchCriteriaObj: activityFilter,
    });
    this.setState({
      isLoading: true,
    });
    DashboardState.searchActivities(activityFilter).then((result) => {
      this.setState({
        isLoading: false,
      });
    });
  };

  onExpandSearch = () => {
    this.setState({ displaySearch: !this.state.displaySearch });
  };

  onSetPageByBlur = (nbOfPages) => {
    console.log("log: onSetPageByBlur -> nbOfPages", nbOfPages);
    this.setState({
      currentPage: nbOfPages,
    });
  };
  keyPressed = (event, nbOfPages) => {
    if (event.key === "Enter") {
      this.onSetPageByBlur(nbOfPages);
    }
  };

  onPreviousPageClick = () => {
    let currentPage = this.state.currentPage;
    if (currentPage > 1) {
      this.setState({ inputPage: currentPage - 1 });
    }
  };

  onNextPageClick = (nbOfPages) => {
    let currentPage = parseInt(DashboardState.beaconsCurrentPage);
    if (currentPage < nbOfPages) {
      this.setState({ inputPage: currentPage + 1 });
    }
  };

  onInputPageChange = (e) => {
    let pattern = /^\d+$/;
    let isNumber = pattern.test(e.target.value);
    if (isNumber || isNumber == "") {
      this.setState({ inputPage: e.target.value });
    }
  };

  // search activity by owner
  onSearchActivityByOwner = async (ownerId) => {

    let { searchCriteria, searchCriteriaObj, searchOption, searchType, searchString, searchTypeLabel } = this.state;

    let userInfo = await UserState.getUserProfileById(ownerId)

    let newCriteria = searchFields[1].value + ":" + userInfo.ownerName;
    let newCriteriaLabel = searchFields[1].text + ":" + userInfo.ownerName;
    searchOption.push({ key: newCriteria, text: newCriteriaLabel, value: newCriteria });
    searchCriteria.push(newCriteria);
    // searchCriteriaObj[searchType] = searchString;
    
    let activityFilter = {};
    if (ownerId) {
      activityFilter.owner = ownerId;
    }

    this.setState({
      searchOption: searchOption,
      searchCriteria: searchCriteria,
      searchString: "",
      searchCriteriaObj: activityFilter,
      displaySearch: true,
      isLoading: true,
    });

    DashboardState.searchActivities(activityFilter).then((result) => {
      this.setState({
        isLoading: false,
      });
      this.dropdownRef.current.handleClick(new Event("click"));
    });
  };

  render() {
    let { activityPerPage, currentPage, isLoading, searchCriteria, searchOption, searchString, displaySearch } = this.state;
    const { onAddActivity } = this.props;
    //console.log("log: render -> onAddActivity", onAddActivity);

    let activities = DashboardState.activities;

    activityPerPage = activityPerPage == "0" ? activities.length : activityPerPage;
    let nbOfPages = Math.ceil(activities.length / activityPerPage);
    let pagedActivities = activities.slice((currentPage - 1) * activityPerPage, currentPage * activityPerPage);
    let searchIconClass = "caret down";
    let searchRow = null;

    if (displaySearch) {
      searchIconClass = "caret up";
      searchRow = (
        <Grid.Row>
          <Grid.Column width={2}>
            <Header as="h4">{i18n.t('find-an-activity')}:</Header>
          </Grid.Column>
          <Grid.Column width={3} className="mobile-padding">
            <Dropdown defaultValue={searchFields[0].value} fluid selection options={searchFields} onChange={this.onSearchTypeChange} />
          </Grid.Column>
          <Grid.Column width={4} className="mobile-padding">
            <Input
              action={{ content: "ADD", color: "orange", onClick: () => this.onAddCriteria() }}
              value={searchString}
              placeholder={i18n.t('add-search-criteria-0')}
              fluid
              onChange={this.onSearchStringChange}
            />
          </Grid.Column>
          <Grid.Column width={5} className="mobile-padding">
            <Dropdown
              className="dashboard-beacons-searchCriteria"
              fluid
              multiple
              selection
              ref={this.dropdownRef}
              options={searchOption}
              value={searchCriteria}
              onChange={this.onChange}
            />
          </Grid.Column>

          <Grid.Column width={2} className="mobile-padding">
            <Button className="button-mobile" onClick={this.onClearAllSearchCriteria}>
              {i18n.t('clear')}
            </Button>
          </Grid.Column>
        </Grid.Row>
      );
    }
    return (
      <div>
        <Grid stackable className="activities-caption">
          <Grid.Row>
            <Grid.Column width={9} className="beacons-optionsRow">
              <div style={{ display: "inline-block" }} className="pagination-mobile">
                {i18n.t('total-records')}: {activities.length}
              </div>

              <div style={{ display: "inline-block" }} className="pagination-mobile">
                <Button
                  icon="left chevron"
                  size="tiny"
                  onClick={() => this.onPreviousPageClick()}
                  disabled={DashboardState.isLoading}
                  style={{ color: "red", backgroundColor: "transparent" }}
                />
                {i18n.t('page')}:{" "}
                <Input
                  className="beacon-page-number"
                  // style={{width:'60px', height:'30.8px'}}
                  name="currentPage"
                  min={1}
                  type="number"
                  size="small"
                  disabled={DashboardState.isLoading}
                  onChange={this.onInputPageChange}
                  value={currentPage}
                  onBlur={() => this.onSetPageByBlur(nbOfPages)}
                  onKeyPress={(e) => this.keyPressed(e, nbOfPages)}
                />{" "}
                of{" " + nbOfPages + " "}
                <Button
                  icon="right chevron"
                  size="tiny"
                  onClick={() => this.onNextPageClick(nbOfPages)}
                  disabled={DashboardState.isLoading}
                  style={{ color: "red", backgroundColor: "transparent" }}
                />
              </div>

              <div style={{ display: "inline-block" }} className="pagination-mobile">
                {i18n.t('records-per-page')}:{" "}
                <Dropdown inline header="Adjust records per page" options={paginationOptions} defaultValue={paginationOptions[0].value} onChange={this.onChangeactivityPerPage} />
              </div>
            </Grid.Column>

            <Grid.Column width={7} textAlign="right">
              {UserState.currentUserProfile.roleId !== '3' && (<AddActivityConfirmButton onAddActivity={onAddActivity} />)}

              <Button basic onClick={this.onExpandSearch} size="small">
                {i18n.t('search-and-filter')}
                <Icon color="red" name={searchIconClass} className="beacons-search-icon" />
              </Button>
            </Grid.Column>
          </Grid.Row>
          {searchRow}
          <Grid.Row className="dashboard-beacons-gridRow-last ">
            <Grid.Column width={11} />
            <Grid.Column width={5} textAlign="right">
              {i18n.t('click-an-activity-to-edit')}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <ActivitiesTable activities={pagedActivities} isLoading={isLoading} onSearchActivityByOwner={this.onSearchActivityByOwner} />
      </div>
    );
  }
}

const AddActivityConfirmButton = ({ onAddActivity }) => {
  return (
    <Popup
      wide
      position="bottom right"
      trigger={
        <Button primary size="small">
          {i18n.t('add-activity')}
          <Icon name="plus" className="beacons-search-icon" />
        </Button>
      }
      content={
        <Message>
          <Header as="h3" textAlign="center">
            <div className="addActivityConfirm-popup-header">
              <Header.Content>{i18n.t('warning')}</Header.Content>
            </div>
            <Header.Subheader>
              {i18n.t('this-information-will-be-consulted')}
            </Header.Subheader>
          </Header>
          <Button basic primary fluid className="dashboard-activities-addConfirmpopup" onClick={onAddActivity}>
            {i18n.t('continue')}
          </Button>
        </Message>
      }
      on="click"
    />
  );
};

class ActivitiesTable extends Component {
  state = {
    editActivity: null,
    // showAdminActivityView: false,
    activity: null,
    showExitModal: false,
    isShowAccountPopup: false,
    ownerSelecting: null
  };

  handleSort = (clickedColumn) => {
    DashboardState.handleSortActivity(clickedColumn);
  };

  handleCloseAdminActivityView = () => {
    // ask to confirm cancel
    if(ActivityState.isAdminEditing){
      this.setState({
        showExitModal: true
      });
    }
    // do not need to ask
    else{
      this.setState({
        // showAdminActivityView: false,
        activity: null,
      });
      ActivityState.setAdminActivityView(false)
      ActivityState.setHideAskToCancel(false)
      ActivityState.setIsAdminEditing(false)
    }
  };

  onAdminActicityEdit = (activity) => {
    activity.userGroup = "selectExistingUser";
    activity.selectUser = activity.holderId;
    activity.vehicleGroup = "selectExistingVehicle";
    activity.selectVehicle = activity.vehicleId;
    ActivityState.setActivityFields(activity);
    // console.log('--> activity: ', toJS(activity))

    this.handleCloseAdminActivityView();
    this.setState({
      // showAdminActivityView: true,
      activity: activity,
    });
    ActivityState.setAdminActivityView(true)
    ActivityState.setIsAdminEditing(true)
    ActivityState.setHideAskToCancel(true)
    ActivityState.resetSteps();
  };


  onModalCancel = () => {
    this.setState({
      showExitModal: false,
    });
  };

  onModalAccept = () => {
    this.setState({
      showExitModal: false,
      // showAdminActivityView: false,
    });
    ActivityState.setAdminActivityView(false)
    ActivityState.setHideAskToCancel(false)
    ActivityState.setIsAdminEditing(false)
  };


  onAccountOwnerClick = (ownerInfo) => {
    this.setState({
      isShowAccountPopup: true,
      ownerSelecting: ownerInfo
    })
  }
  onCloseAccountPopup = () => {
    this.setState({isShowAccountPopup: false})
  }


  render() {
    let { activities, isLoading, onSearchActivityByOwner } = this.props;
    let { editActivity, activity, isEditing, showExitModal, isShowAccountPopup, ownerSelecting } = this.state;

    const column = DashboardState.column;
    const direction = DashboardState.direction;

    let editButton = null;
    if (UserState.isAdmin()) {
      editButton = (
        <Button floated="right" primary icon onClick={() => this.onAdminActicityEdit(activity)}>
          <Icon name="edit" />
          {i18n.t('edit-activity')}
        </Button>
      );
    }

    return (
      <div>
        <Segment>
          <Dimmer active={isLoading} inverted>
            <Loader>{i18n.t('loading')}</Loader>
          </Dimmer>
          <div className="sidescroll">
            <Table celled striped selectable sortable className="beacon-mobile">
              <Table.Header className="contactList-tableHeader">
                <Table.Row>
                  <Table.HeaderCell className="dashboard-beacon-tableHeader" sorted={column === "activityName" ? direction : null} onClick={() => this.handleSort("activityName")}>
                    {i18n.t('activity')}
                  </Table.HeaderCell>
                  <Table.HeaderCell className="dashboard-beacon-tableHeader" sorted={column === "duration" ? direction : null} onClick={() => this.handleSort("duration")}>
                    {i18n.t('status')}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className="dashboard-beacon-tableHeader"
                    sorted={column === "durationSpecificStartDate" ? direction : null}
                    onClick={() => this.handleSort("durationSpecificStartDate")}
                  >
                    {i18n.t('start-date')}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className="dashboard-beacon-tableHeader"
                    sorted={column === "durationSpecificEndDate" ? direction : null}
                    onClick={() => this.handleSort("durationSpecificEndDate")}
                  >
                    {i18n.t('end-date')}
                  </Table.HeaderCell>

                  <Table.HeaderCell
                    className="dashboard-beacon-tableHeader"
                    style={{ cursor: "initial" }}
                  >
                    {i18n.t('account-owner')}
                  </Table.HeaderCell>

                  <Table.HeaderCell className="dashboard-beacon-tableHeader" sorted={column === "beaconHolder" ? direction : null} onClick={() => this.handleSort("beaconHolder")}>
                    {i18n.t('beacon-holder')}
                  </Table.HeaderCell>
                  <Table.HeaderCell className="dashboard-beacon-tableHeader" sorted={column === "beaconId" ? direction : null} onClick={() => this.handleSort("beaconId")}>
                    {i18n.t('beacon-hex-id')}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className="dashboard-beacon-tableHeader"
                    sorted={column === "arrivalLocation" ? direction : null}
                    onClick={() => this.handleSort("arrivalLocation")}
                  >
                    {i18n.t('arrival-location')}
                  </Table.HeaderCell>
                  <Table.HeaderCell className="dashboard-beacon-tableHeader" sorted={column === "lastEditDate" ? direction : null} onClick={() => this.handleSort("lastEditDate")}>
                    {i18n.t('last-edit-date')}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {activities.map((activity) => (
                  <TableRow key={activity._id} activity={activity} onClick={this.onActivityClick} onAccountOwnerClick={this.onAccountOwnerClick} />
                ))}
              </Table.Body>
            </Table>
          </div>
          <EmptyStatePlaceHolder activities={activities} />
        </Segment>

        {/* summary modal */}
        <Modal onClose={this.handleCloseAdminActivityView} open={ActivityState.showAdminActivityView}>
          {
            !ActivityState.isAdminEditing ? (<Modal.Header>Activity Information{" "}{editButton}</Modal.Header>)
            : (<Modal.Header>{i18n.t('edit-activity-information')}</Modal.Header>)
          }
          <Modal.Content>
            <Modal.Description>
              {
                !ActivityState.isAdminEditing ? (<ActivityReview hideButtons={true} activity={activity} onClose={this.handleCloseAdminActivityView} onSearchActivityByOwner={onSearchActivityByOwner} showOwnerActivityNumber={true} />)
                : (<RegisterActivity activity={activity} />)
              }
            </Modal.Description>
          </Modal.Content>
          {
            !ActivityState.isAdminEditing && (
              <Modal.Actions>
                <Button primary onClick={this.handleCloseAdminActivityView}>
                  {i18n.t('close')}
                </Button>
              </Modal.Actions>
            )
          }
        </Modal>

        {/* ask to cancel for admin */}
        {showExitModal && (
          <Modal size="tiny" centered={false} open={showExitModal} className="centered">
            <Modal.Content>
              <Header className="modal-header">WARNING</Header>
              {i18n.t('do-you-want-to-cancel-editing-and-return-to-the-dashboard')}
              <div className="activityDetail-submitContainer">
                <Button className="registerBeacon-submitButton" onClick={this.onModalAccept}>
                  {i18n.t('yes')}
                </Button>
                <Button className="registerBeacon-submitButton" onClick={this.onModalCancel} primary>
                  {i18n.t('no')}
                </Button>
              </div>
            </Modal.Content>
          </Modal>
        )}

        {/* Account detail */}
        {isShowAccountPopup && (
          <Modal
            id="accountPopup"
            centered={false}
            open={isShowAccountPopup}
            onClose={this.onCloseAccountPopup}
            closeOnDimmerClick={false}
            className="dashboard-beacon-editBeacon"
            closeIcon
          >
            <Modal.Header>{i18n.t('account-owner')}</Modal.Header>
            <Modal.Content className="dashboard-beacon-editBeacon-content">
              <AccountDetail beacon={ownerSelecting} onClose={this.onCloseAccountPopup} isActivity={true} onSearchActivityByOwner={onSearchActivityByOwner} />
            </Modal.Content>
          </Modal>
        )}
      </div>
    );
  }

  // this action for editing activity
  onActivityClick = (activity) => {
    console.log("============== edit id: " + activity._id);

    Utils.sendGtagEvent("editActivity_display");

    if (DashboardState.isAdmin()) {
      ActivityState.resetData();
      ActivityState.updateActivityFields(activity);
      this.setState({
        // showAdminActivityView: true,
        activity: activity,
      });
      ActivityState.setAdminActivityView(true)
      return;
    }

    // use in case all fields atore at activity state: current version
    activity.userGroup = "selectExistingUser";
    activity.selectUser = activity.holderId;

    // get beacon detail
    //activity.selectBeacon = activity.beaconId;
    ActivityState.getBeacon(activity.beaconId).then((info) => {
      if (info !== undefined) {
        activity.beaconType = info.beaconType;
        activity.beaconMailCountry = info.mailCountry;
        activity.beaconCountryCode = info.beaconCountryCode;
      }
    });

    // use in case all fields atore at activity state: fix issue adding blank vehicle if beacon or vehicle is not selected
    activity.vehicleGroup = "selectExistingVehicle";
    activity.selectVehicle = activity.vehicleId;
    console.log("=============== activity : ", toJS(activity));

    ActivityState.setActivityFields(activity);

    // redirect to edit page
    ActivityState.resetSteps();
    ActivityState.setIsEditActivity(true);
    AssociatedUserState.setHasRelatedActivity(true);
    VehicleState.setHasRelatedActivity(true);
    RouteState.setRoute("/registerActivity");
  };
}

const EmptyStatePlaceHolder = ({ activities }) => {
  if (activities && activities.length > 0) {
    return null;
  }

  return (
    <Header as="h2" icon textAlign="center">
      <Icon name="file outline" circular />
      <Header.Content>{i18n.t('no-temporary-activity-found')}</Header.Content>
    </Header>
  );
};

const TableRow = ({ activity, onClick, onAccountOwnerClick }) => {
  let hexId = activity.beaconId ? activity.beaconId.split("_") : undefined;

  // console.log('---> activity: ', toJS(activity))
  
  const [helderName, setHelderName] = useState("");
  const [accountOwner, setAccountOwner] = useState(null);
  useEffect(() => {
    if (activity.firstName) {
      let fullName = (activity.firstName + " " || "") + (activity.lastName || "");
      setHelderName(fullName);
    }

    // get account owner
    if(activity.owner){
      UserState.getUserProfileById(activity.owner).then(data => {
        setAccountOwner(data)
      })
    }
  }, []);

  return (
    <Table.Row /*onClick={() => onClick(activity)}*/>
      {isBrowser ? (
        <>
          <Table.Cell onClick={() => onClick(activity)} className="dashboard-beacon-resultCell dashboard-beacon-resultCell-link">
            {activity.activityName || ""}
          </Table.Cell>
          <Table.Cell style={{ textTransform: "capitalize" }}>{activity.duration || ""}</Table.Cell>
          <Table.Cell>{(activity.duration === "specific" ? Utils.dateFormatWithTime(activity.durationSpecificStartDate) : activity.durationUnspecified) || "N/A"}</Table.Cell>
          <Table.Cell>{(activity.duration === "specific" ? Utils.dateFormatWithTime(activity.durationSpecificEndDate) : activity.durationUnspecified) || "N/A"}</Table.Cell>
          <Table.Cell onClick={() => onAccountOwnerClick(activity)} className="dashboard-beacon-resultCell dashboard-beacon-resultCell-link">{accountOwner ? accountOwner.ownerName : ''}</Table.Cell>
          <Table.Cell>{helderName || ""}</Table.Cell>
          <Table.Cell>{hexId ? hexId[1] : ""}</Table.Cell>
          <Table.Cell>{Utils.formatLocation(activity.arrivalLocation, activity.arrivalNearestTown, activity.arrivalState, activity.arrivalCountry)}</Table.Cell>
          <Table.Cell>{Utils.dateFormatWithTime(activity.lastEditDate) || ""}</Table.Cell>
        </>
      ) : (
        <>
          {activity.activityName && (
            <Table.Cell onClick={() => onClick(activity)} className="contactList-cell dashboard-beacon-resultCell dashboard-beacon-resultCell-link">
              <div className="contactList-cell-mobileHeader">{i18n.t('activity')}</div>
              <div className="contactList-cell-content">{activity.activityName || ""}</div>
            </Table.Cell>
          )}

          {activity.duration && (
            <Table.Cell className="contactList-cell" style={{ textTransform: "capitalize" }}>
              <div className="contactList-cell-mobileHeader">{i18n.t('status')}</div>
              <div className="contactList-cell-content">{activity.duration || ""}</div>
            </Table.Cell>
          )}

          <Table.Cell className="contactList-cell">
            <div className="contactList-cell-mobileHeader">{i18n.t('start-date')}</div>
            <div className="contactList-cell-content">
              {(activity.duration === "specific" ? Utils.dateFormatWithTime(activity.durationSpecificStartDate) : activity.durationUnspecified) || "N/A"}
            </div>
          </Table.Cell>

          <Table.Cell className="contactList-cell">
            <div className="contactList-cell-mobileHeader">{i18n.t('end-date')}</div>
            <div className="contactList-cell-content">
              {(activity.duration === "specific" ? Utils.dateFormatWithTime(activity.durationSpecificEndDate) : activity.durationUnspecified) || "N/A"}
            </div>
          </Table.Cell>

          <Table.Cell onClick={() => onAccountOwnerClick(activity)} className="contactList-cell dashboard-beacon-resultCell dashboard-beacon-resultCell-link">
            <div className="contactList-cell-mobileHeader">{i18n.t('account-owner')}</div>
            <div className="contactList-cell-content">{accountOwner ? accountOwner.ownerName : ''}</div>
          </Table.Cell>

          {helderName && (
            <Table.Cell className="contactList-cell">
              <div className="contactList-cell-mobileHeader">{i18n.t('beacon-holder')}</div>
              <div className="contactList-cell-content">{helderName || ""}</div>
            </Table.Cell>
          )}

          {hexId && (
            <Table.Cell className="contactList-cell">
              <div className="contactList-cell-mobileHeader">{i18n.t('beacon-hex-id')}</div>
              <div className="contactList-cell-content">{hexId ? hexId[1] : ""}</div>
            </Table.Cell>
          )}

          {Utils.formatLocation(activity.arrivalLocation, activity.arrivalNearestTown, activity.arrivalState, activity.arrivalCountry) && (
            <Table.Cell className="contactList-cell">
              <div className="contactList-cell-mobileHeader">{i18n.t('arrival-location')}</div>
              <div className="contactList-cell-content">
                {Utils.formatLocation(activity.arrivalLocation, activity.arrivalNearestTown, activity.arrivalState, activity.arrivalCountry)}
              </div>
            </Table.Cell>
          )}

          {activity.lastEditDate && (
            <Table.Cell className="contactList-cell">
              <div className="contactList-cell-mobileHeader">{i18n.t('last-edit-date')}</div>
              <div className="contactList-cell-content">{Utils.dateFormatWithTime(activity.lastEditDate) || ""}</div>
            </Table.Cell>
          )}
        </>
      )}
    </Table.Row>
  );
};

function findLabelByValue(value, dataSet) {
  let option = dataSet.find((entry) => entry.value === value);
  return option.text;
}
