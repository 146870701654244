import i18n from "i18next";
import React, { Component } from "react";
import { Segment, Header, Icon, List, Button, Container } from "semantic-ui-react";
import TopMenu from "../../components/topMenu";
import Footer from "../../components/footer";
import RouteState from "../../state/routeState";
import AppState from "../../state/appState";
import "./contact.css";
import { observer } from "mobx-react";
import ContactUsState from "../../state/contactUsState";
import AppConfigState from "../../state/appConfigState";
import { toJS } from "mobx";

export default class ContactUsComplete extends Component {
  state = {
    country: {},
    pocInfo: {},
  };

  componentDidMount = async () => {
    let _country = AppConfigState.config ? AppConfigState.countryCode.find((country) => country.key === ContactUsState.mid) : null;
    let midInfo = AppConfigState.config ? await AppConfigState.config.MIDInfo.find((midInfo) => midInfo.Mid === ContactUsState.mid) : null;
    let pocInfo;
    if (midInfo) {
      pocInfo = await ContactUsState.getPOC(midInfo, ContactUsState.beaconType);
    }
    this.setState({
      country: _country,
      pocInfo: pocInfo,
    });
  };
  
  // componentWillUnmount = () => { 
  //     ContactUsState.resetState();
  // }

  render() {
    let { country, pocInfo } = this.state;
    // console.log('---------------------- current lang >> ' + JSON.stringify(i18n))
    if (AppConfigState.config) {
      console.log("----------POC >> ", country, pocInfo, AppConfigState.countryCode, toJS(ContactUsState));
    }

    return (
      <>
        <TopMenu />
        <ContactSubmitComplete country={country} pocInfo={pocInfo} />
        {/* <Button content="Go To ?" primary /> */}
        <Footer />
      </>
    );
  }
}

const ContactSubmitComplete = ({ country, pocInfo }) => {
  const t = AppState.t;
  let completeText = (
    <span>
      <i>
       { i18n.t('your-email-has-been-sent-to')} <b>{pocInfo ? pocInfo.name : "n/a"},</b> {i18n.t('which-is-your-designated-contact-for-beacons-registered-in-country')} <b>'{country ? country.text : "n/a"}'.</b> {i18n.t('someone-from')} <b>{pocInfo ? pocInfo.name : "n/a"}</b> {i18n.t('will-be-in-contact-with-your-directly-else-contact-us-again')}
      </i>
    </span>
  );

  if (ContactUsState.isSupported) {
    completeText = i18n.t('your-email-has-been-relayed-to-the-secretariat-of-the-international-cospas-sarsat-programme-and-someone-will-contact-you-with-a-response-within-72-hours');
  }
  
  return (
    <Container style={{ margin: "20px auto" }}>
      <Segment>
        <Header as="h2" icon textAlign="center">
          <Icon name="check" circular color="green" />
          <Header.Content>{i18n.t('form-submission-complete')}</Header.Content>
          <br />
          <Header.Subheader>{completeText}</Header.Subheader>
          <br />
          {/* <Button content="Go To ?" primary /> */}
        </Header>
      </Segment>
    </Container>
  );
};
