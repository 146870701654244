import { action, observable, computed, toJS } from 'mobx';
import * as AssociatedUserService from '../services/associatedUserService';
import * as ActivityService from '../services/activityService';
import UserState from "./userState";
import RouteState from './routeState';

class AssociatedUserState {
    @observable userFields = {};
    @observable currentStep = 0;
    @observable hasRelatedActivity = false;

    @action setHasRelatedActivity(value){
        this.hasRelatedActivity = value;
    }
    getCurrentStep() {
        return this.currentStep;
    }
    @action setCurrentStep(step){
        this.currentStep = step;
        window.scrollTo(0,0);
    }
    @action setNextStep() {
        this.currentStep += 1;
        window.scrollTo(0,0);
    }
    @action setPreviousStep() {
        if(this.currentStep==0){
            RouteState.setRoute('/dashboard/users');
        }
        else{
            this.currentStep -= 1;
        }
        window.scrollTo(0,0);
    }

    @action resetSteps(){
        this.currentStep = 0;
        window.scrollTo(0,0);
    }

    @action resetData(){
        this.userFields = {}
        this.hasRelatedActivity = false
    }

    @action updateUserFields(values) {
        this.userFields = { ...this.userFields, ...values }
    }

    @action setUserFields(userFields){
        this.userFields = userFields;
    }

    getUserFields() {
        return this.userFields;
    }

    getOwner() {
        let ownerId;
        let roleId = UserState.currentUserProfile.roleId;
        switch (roleId) {
            case "5":
                ownerId = UserState.currentUserProfile._id;
                break;
            case "1":
                if(this.userFields.owner) {
                    ownerId = this.userFields.owner;
                }
                else {
                    ownerId = UserState.currentUserProfile._id;
                }
                break;

            default:
                ownerId = UserState.currentUserProfile._id;
                break;
        }
        return ownerId;
    }

    async saveAssociatedUser(userFields, ownerId, beaconCountryCode=''){
        try {
            let result = await AssociatedUserService.saveAssociatedUser(userFields, ownerId, beaconCountryCode);
            console.log('AssociatedUserState:saveAssociatedUser -> result', result);

            this.userFields = {};
            return result.id;
        } catch (error) {
            console.error('AssociatedUserState:saveAssociatedUser -> error', error);
        }
    }

    async getAssociatedUser(id){
        try {
          let result = await ActivityService.getDocumentById(id);
          // let result = await ActivityService.getDocumentByTypeAndId("user", id);
          console.log('AssociatedUserState:getAssociatedUser - result', result);
          return result;
        } catch (error) {
          console.error('AssociatedUserState:getAssociatedUser - error', error);
        }
    }

}


const singleton = new AssociatedUserState(); //export class as singleton to be used through the application.
export default singleton;