import React, { Component } from "react";
import { Grid, Container, Dimmer, Loader } from "semantic-ui-react";
import { observer } from "mobx-react";
import TopMenu from "./topMenu";
import Footer from "./footer";
import DefaultPageLayout from "./defaultPageLayout";
import SideMenu from "./sideMenu";
import UserState from "../state/userState";
import AppState from "../state/appState";
import { Redirect } from "react-router-dom";

@observer
export default class DashboardLayout extends Component {
  render() {
    let { children } = this.props;
    let userProfile = UserState.currentUserProfile;
    let isOnline = AppState.isOnline;
    
    if (!userProfile) {
     return <Redirect to="/" />;
    }



    if (userProfile && UserState.userIsAdmin(userProfile) && isOnline) {
      return (
        <div className="dashboard-layout dashboard-page">
          <TopMenu hasTopBanner={false} />
          <Grid stackable textAlign="center" style={{ margin: "0 auto", clear: "both" }}>
            <Grid.Row>
              <Grid.Column textAlign="left" width="3" style={{ margin: "5px", padding: "15px", backgroundColor: "#FFF" }}>
                <SideMenu />
              </Grid.Column>
              <Grid.Column textAlign="left" width="12" style={{ margin: "5px", padding: "15px", backgroundColor: "#FFF" }}>
                {children || ""}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Footer />
        </div>
      );
    }

    return (
      <DefaultPageLayout>
        <Grid stackable textAlign="center" className="dashboardLayout-grid" style={{ margin: "0 auto", clear: "both", backgroundColor: "#FFF" }}>
          <Grid.Row className="dashboardLayout-row">
            <Grid.Column textAlign="left" width="16" style={{ backgroundColor: "#FFF" }}>
              {children || ""}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </DefaultPageLayout>
    );
  }
}
