import i18n from "i18next";
import React, { Component, useEffect } from "react";
import * as Yup from "yup";
import { Form, Input, Button, Popup, Select, Header, Grid, Message, Transition, TextArea, Icon } from "semantic-ui-react";
import RegistrationState from "../../../state/registrationState";
import withSemanticUIFormik from "../../../utils/formHelper";
import RouteState from "../../../state/routeState";
import UserState from "../../../state/userState";
import { FormErrorDisplay } from "../../../components/formErrorDisplay";
import beaconDecode from "../../../utils/beaconDecode/BeaconDecode";
import AppConfigState from "../../../state/appConfigState";
import ImageUploader from "../../../components/imageUpload";

// const statusOption = [
//   { key: "af", value: "af", text: "Working" },
//   { key: "ax", value: "ax", text: "Aland Islands" },
//   { key: "al", value: "al", text: "Albania" },
//   { key: "dz", value: "dz", text: "Algeria" },
//   { key: "as", value: "as", text: "American Samoa" },
// ];

// const options = [{ key: "m", text: "Male", value: "male" }, { key: "f", text: "Female", value: "female" }, { key: "o", text: "Other", value: "other" }];

// const vehicleTypeOption = [
//   { key: "SAIL", text: "SAIL", value: "SAIL" },
//   { key: "POWER Fishing", text: "POWER Fishing", value: "POWER Fishing" },
//   { key: "POWER Tug", text: "POWER Tug", value: "POWER Tug" },
//   { key: "POWER Cargo", text: "POWER Cargo", value: "POWER Cargo" },
//   { key: "POWER Tanker", text: "POWER Tanker", value: "POWER Tanker" },
//   { key: "POWER Pleasure Craft", text: "POWER Pleasure Craft", value: "POWER Pleasure Craft" },
//   { key: "POWER “Other”", text: "POWER “Other”", value: "POWER “Other”" },
//   { key: "NON-POWER Life Boat", text: "NON-POWER Life Boat", value: "NON-POWER Life Boat" },
//   { key: "NON-POWER Life Raft", text: "NON-POWER Life Raft", value: "NON-POWER Life Raft" },
//   { key: "NON-POWER “Other”", text: "NON-POWER “Other”", value: "NON-POWER “Other”" },
// ];

// const radioEquipmentOption = [
//   { key: "HF", text: "HF", value: "HF" },
//   { key: "MF", text: "MF", value: "MF" },
//   { key: "SSB", text: "SSB", value: "SSB" },
//   { key: "VHF", text: "VHF", value: "VHF" },
//   { key: "Other", text: "Other", value: "Other" },
// ];

const isRecordOption = [{ key: "Y", text: "Yes", value: "Y" }, { key: "N", text: "No", value: "N" }];

const InnerForm = (props) => {
  const { values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, isValid } = props;

  let hexHasError = touched.hex && errors.hex && errors.hex.length > 0;
  let vehicleRegistrationNumberError = touched.vehicleRegistrationNumber && errors.vehicleRegistrationNumber && errors.vehicleRegistrationNumber.length > 0;
  let vehicleTypeError = touched.vehicleType && errors.vehicleType && errors.vehicleType.length > 0;
  let nbLifeBoatError = touched.nbLifeBoat && errors.nbLifeBoat && errors.nbLifeBoat.length > 0;
  let nbLifeRaftError = touched.nbLifeRaft && errors.nbLifeRaft && errors.nbLifeRaft.length > 0;
  let MMSIHasError = touched.MMSI && errors.MMSI && errors.MMSI.length > 0;
  let callSignHasError = touched.callSign && errors.callSign && errors.callSign.length > 0;

  let canEditByStatus = props.decodedBeacon.specialStatus == "ACTIVE" || props.decodedBeacon.specialStatus == undefined || props.decodedBeacon.specialStatus == "RELEASETOMAINTENANCE";
  if (UserState.notAllowToEditByRoleId.includes(UserState.currentUserProfile.roleId)) {
    canEditByStatus = false;
  }
  let storedValues = {};
  const saveAndClose = () => {
    if (!RegistrationState.isNewRegisterBeacon) {
      let newEquipmentFormat;
      if (props.values.radioEquipment && typeof props.values.radioEquipment == "object") {
        newEquipmentFormat = props.values.radioEquipment.join();
      }
      props.values.radioEquipment = newEquipmentFormat;
      // console.log('log: props.values', props.values, isValid);

      // new form if is SAIL
      if (props.values.vehicleType == "SAIL") {
        props.values.vehicleType = props.values.vehicleType + " " + props.values.vehicleTypeSailMast + " Masts";
      }
      delete props.values.vehicleTypeSailMast;

      RegistrationState.setIsSaving(true);
      RegistrationState.updateBeaconFields(props.values);
      RegistrationState.updateBeacon().then(() => {
        RegistrationState.setIsBeaconSaved(true);
        props.onClose();
        RegistrationState.setIsSaving(false);
      });
    }
  };
  storedValues = values;
  useEffect(() => {
    if (!RegistrationState.isNewRegisterBeacon) {
      var topPosition = document.getElementById("editBeaconModal");
      topPosition.scrollIntoView();
    } else {
      window.scrollTo({ top: 0 });
    }

    return function cleanup() {
      if (!RegistrationState.isNewRegisterBeacon) {
        console.log("log: InnerForm -> cleanup -> ", storedValues);
        //RegistrationState.updateBeaconFields(values);
      }
    };
  }, []);

  // handle mmsi
  const handleChangeMMSI = (e, data) => {
    if (data && data.name && data.value.length <= 9) {
      props.setFieldValue(data.name, data.value);
    }
  };
  let showDecodedMismatchWarning = false;
  let MMSIinputWarning = null;
  let callSignWarning = null;
  if (values.MMSIDecoded !== values.MMSI) {
    showDecodedMismatchWarning = true;
    MMSIinputWarning = <Popup content={i18n.t("information-entered-does-not-match-the-information-decoded-from-your-uin-please-contact-your-beacon-manufacturer-or-place-of-purchase-to-resolve")} trigger={<Icon name="warning" color="yellow" inverted circular link />} />;
  }

  if (values.callSign !== values.callSignDecoded) {
    showDecodedMismatchWarning = true;
    callSignWarning = <Popup content={i18n.t("information-entered-does-not-match-the-information-decoded-from-your-uin-please-contact-your-beacon-manufacturer-or-place-of-purchase-to-resolve")} trigger={<Icon name="warning" color="yellow" inverted circular link />} />;
  }

  let countryCodeOptions = AppConfigState.countryNamesWithCode.sort((a, b) => a.text.localeCompare(b.text));
  let lengthHasError = touched.length && errors.length && errors.length.length > 0;
  let peopleCapacityHasError = touched.peopleCapacity && errors.peopleCapacity && errors.peopleCapacity.length > 0;
  let radioEquipmentOption = AppConfigState.radioEquipmentOption;
  const vehicleTypeOption = AppConfigState.vehiculeTypeEPIRB;

  // scroll to error
  if (isSubmitting && Object.keys(errors).length > 0) {
    var errorBox = document.getElementById("scrollToError");
    errorBox.scrollIntoView();
  }

  const saveToStateBeforeHandleChange = function(e, data) {
    console.log("log: InnerForm -> saveToStateBeforeHandleChange -> name, value", data.name, data.value);
    RegistrationState.saveOnFieldChange(data.name, data.value);
    handleChange(e, data);
  };

  return (
    <Form id="mainForm" className="beaconLocation-form  animated fadeIn faster">
      <Header as="h2" icon textAlign="center">
        <Header.Content>{i18n.t("please-supply-additional-information-about-your-vehicle-if-applicable")}</Header.Content>
      </Header>
      <div id="scrollToError" />
      <FormErrorDisplay errors={errors} touched={touched} status={status} />
      {/* <DecodedMismatchWarning show={showDecodedMismatchWarning} /> */}
      <div className="required-field-notation">* required fields</div>

      <Form.Group widths="equal">
        <Form.Field>
          <Form.Select name="vehicleType" fluid label={i18n.t("vehicle-type")} options={vehicleTypeOption} placeholder="Select" onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} value={values.vehicleType} required error={vehicleTypeError} disabled={!canEditByStatus} className={canEditByStatus ? "editing-select" : ""} />

          {values.vehicleType == "SAIL" ? (
            <Form.Input fluid placeholder={i18n.t("number-of-masts")} type="number" min="0" name="vehicleTypeSailMast" value={values.vehicleTypeSailMast} onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} disabled={!canEditByStatus} className={canEditByStatus ? "editing-input" : ""} />
          ) : (
            (values.vehicleTypeSailMast = "")
          )}
        </Form.Field>

        <Form.Field>
          <label>
            {i18n.t("vehicle-registration-number")} <span style={{ color: "red" }}>*</span>
          </label>
          <Form.Input fluid placeholder="" name="vehicleRegistrationNumber" value={values.vehicleRegistrationNumber} onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} required error={vehicleRegistrationNumberError} disabled={!canEditByStatus} className={canEditByStatus ? "editing-input" : ""} />
        </Form.Field>
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Field>
          <label>
            {i18n.t("vessel-name")}
            <Popup content={i18n.t("name-of-vessel")} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
          </label>
          <Form.Input name="vehicleName" fluid placeholder="" onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} value={values.vehicleName} disabled={!canEditByStatus} className={canEditByStatus ? "editing-input" : ""} />
        </Form.Field>

        <Form.Input name="vehicleModel" fluid label={i18n.t("vessel-model")} placeholder="" onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} value={values.vehicleModel} disabled={!canEditByStatus} className={canEditByStatus ? "editing-input" : ""} />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label>
            {i18n.t("radio-call-sign")}
            <Popup content={i18n.t("radio-call-sign-associated-with-vessel-should-equal-decoded-or-be-left-blank")} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
          </label>
          <Form.Input name="callSign" icon={callSignWarning} fluid placeholder="" onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} value={values.callSign} disabled={!canEditByStatus} className={canEditByStatus ? "editing-input" : ""} error={callSignHasError} />
        </Form.Field>

        <Form.Input name="callSignDecoded" fluid label={i18n.t("radio-call-sign-decoded-0")} placeholder="" onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} value={values.callSignDecoded} disabled={true} />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label>
            {i18n.t("mmsi-number")}
            <Popup content={i18n.t("maritime-mobile-service-identity-associated-with-vessel")} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
          </label>
          <Form.Input name="MMSI" icon={MMSIinputWarning} fluid placeholder="" onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} value={values.MMSI} disabled={!canEditByStatus} className={canEditByStatus ? "editing-input" : ""} error={MMSIHasError} />
        </Form.Field>

        <Form.Input name="MMSIDecoded" fluid label={i18n.t("mmsi-number-decoded-0")} placeholder="" onChange={handleChange} onBlur={handleBlur} value={values.MMSIDecoded} disabled={true} />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input name="homePort" fluid label={i18n.t("home-port")} placeholder="" onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} value={values.homePort} disabled={!canEditByStatus} className={canEditByStatus ? "editing-input" : ""} />
        <Form.Input name="vehicleColor" fluid label={i18n.t("vessel-colour")} placeholder="" onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} value={values.vehicleColor} disabled={!canEditByStatus} className={canEditByStatus ? "editing-input" : ""} />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input name="nbLifeBoat" fluid label={i18n.t("number-of-life-boats")} placeholder="" onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} value={values.nbLifeBoat} type="number" min="0" disabled={!canEditByStatus} className={canEditByStatus ? "editing-input" : ""} error={nbLifeBoatError} />
        <Form.Input name="nbLifeRaft" fluid label={i18n.t("number-of-life-rafts")} placeholder="" onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} value={values.nbLifeRaft} type="number" min="0" disabled={!canEditByStatus} className={canEditByStatus ? "editing-input" : ""} error={nbLifeRaftError} />
        <Form.Field>
          <label>
            {i18n.t("radio-equipment")}
            <Popup content={i18n.t("type-s-of-radio-equipment-present-on-the-vessel-check-any-and-all-that-apply-if-other-is-selected-enter-a-description-in-the-box-below")} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
          </label>
          <Form.Select multiple name="radioEquipment" fluid options={radioEquipmentOption} placeholder="Select" onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} value={values.radioEquipment} disabled={!canEditByStatus} className={canEditByStatus ? "editing-select" : ""} />

          {values.radioEquipment && values.radioEquipment.includes("Other") ? (
            <Form.Input fluid placeholder="" name="radioEquipmentOther" value={values.radioEquipmentOther} onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} disabled={!canEditByStatus} className={canEditByStatus ? "editing-input" : ""} />
          ) : (
            (values.radioEquipmentOther = "")
          )}
        </Form.Field>
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input name="aisNumber" fluid label={i18n.t("ais-number")} placeholder="" onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} value={values.aisNumber} disabled={!canEditByStatus} className={canEditByStatus ? "editing-input" : ""} />
        <Form.Input name="Imarsat" fluid label={i18n.t("inmarsat")} placeholder="" onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} value={values.Imarsat} disabled={!canEditByStatus} className={canEditByStatus ? "editing-input" : ""} />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Field>
          <label>
            {i18n.t("vessel-cellular")}
            <Popup content={i18n.t("cellular-phone-number-associated-with-vessel")} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
          </label>
          <Form.Input name="vehicleCellularNum" fluid placeholder="" onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} value={values.vehicleCellularNum} disabled={!canEditByStatus} className={canEditByStatus ? "editing-input" : ""} />
        </Form.Field>

        <Form.Input name="vehicleSatellitePhone" fluid label={i18n.t("vessel-satellite-phone")} placeholder="" onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} value={values.vehicleSatellitePhone} disabled={!canEditByStatus} className={canEditByStatus ? "editing-input" : ""} />
      </Form.Group>

      {/* 2nd tab */}
      <Form.Group widths="equal">
        <Form.Field>
          <label>
            {i18n.t("length-overall-m")}
            <Popup content={i18n.t("overall-length-in-metres")} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
          </label>
          <Form.Input fluid placeholder="" type="number" min="0" name="length" value={values.length} onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} disabled={!canEditByStatus} className={canEditByStatus ? "editing-input" : ""} error={lengthHasError} />
        </Form.Field>

        <Form.Input fluid label={i18n.t("capacity-crew-and-passengers")} placeholder="" type="number" min="0" name="peopleCapacity" value={values.peopleCapacity} onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} disabled={!canEditByStatus} className={canEditByStatus ? "editing-input" : ""} error={peopleCapacityHasError} />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Field>
          <label>
            {i18n.t("vehicle-nationality")}
            <Popup content={i18n.t("mid-country-code-for-flag-state-or-aircraft-nationality-of-registration")} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
          </label>
          <Form.Select fluid search options={countryCodeOptions} placeholder={i18n.t("select")} name="vehicleNationality" value={values.vehicleNationality} onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} disabled={!canEditByStatus} className={canEditByStatus ? "editing-select" : ""} />
        </Form.Field>

        <Form.Select
          fluid
          options={isRecordOption}
          placeholder="Select"
          label={i18n.t("equipped-with-simplified-voyage-data-recorder")}
          name="equippedWithDataRecord"
          value={values.equippedWithDataRecord}
          onChange={saveToStateBeforeHandleChange}
          onBlur={handleBlur}
          disabled={!canEditByStatus}
          className={canEditByStatus ? "editing-select" : ""}
        />
      </Form.Group>

      {/* 3rd tab */}
      <div>
        <label>
          {i18n.t("additional-vehicle-usage-information")}
          <Popup content={i18n.t("additional-information-deemed-appropriate-or-helpful-to-search-and-rescue-personnel-regarding-general-beacon-usage")} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
        </label>
        <Form.TextArea placeholder="" name="additionalComment" value={values.additionalComment} onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} disabled={!canEditByStatus} className={canEditByStatus ? "editing-input" : ""} />
      </div>

      <div>
        <label>
          {i18n.t("sar-hazard")}
          <Popup content={i18n.t("sar-hazard-pop")} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
        </label>
        <Form.TextArea placeholder="" name="sarHazard" value={values.sarHazard} onChange={saveToStateBeforeHandleChange} onBlur={handleBlur} disabled={!canEditByStatus} className={canEditByStatus ? "editing-input" : ""} />
      </div>

      <Form.Group widths="equal" style={{ "margin-top": "1em" }}>
        <Form.Field>
          <ImageUploader name="picture1" disabled={!canEditByStatus} value={values.picture1} onChange={saveToStateBeforeHandleChange} />
        </Form.Field>
        <Form.Field>
          <ImageUploader name="picture2" disabled={!canEditByStatus} value={values.picture2} onChange={saveToStateBeforeHandleChange} />
        </Form.Field>
      </Form.Group>

      {/* <Grid centered columns={3} stackable>
        <Grid.Column>
          <Button className="registerBeacon-submitButton" onClick={onBackClick}>
            Back
          </Button>
        </Grid.Column>
        <Grid.Column>
          <Button className="registerBeacon-submitButton" type="submit" primary onClick={handleSubmit}>
            Next
          </Button>
        </Grid.Column>
      </Grid> */}

      <div className="activityDetail-submitContainer">
        <Button className="registerBeacon-submitButton" onClick={onBackClick}>
          {i18n.t("back")}
        </Button>
        <Button className="registerBeacon-submitButton" type="submit" primary onClick={handleSubmit}>
          {i18n.t("next")}
        </Button>
      </div>
    </Form>
  );
};

function onBackClick() {
  RegistrationState.setVehiculePreviousStep();
}

const beaconLocationForm = withSemanticUIFormik({
  mapPropsToValues: ({ decodedBeacon }) => {
    if (!decodedBeacon) {
      decodedBeacon = {};
    }

    let vehicleType;
    let vehicleTypeSailMast;
    if (decodedBeacon.vehicleType && decodedBeacon.vehicleType.includes("SAIL")) {
      vehicleType = "SAIL";
      vehicleTypeSailMast = parseInt(decodedBeacon.vehicleType.replace("SAIL", "").replace("Masts", ""));
    } else {
      vehicleType = decodedBeacon.vehicleType;
    }

    let radioEquipment;
    if (decodedBeacon.radioEquipment) {
      radioEquipment = typeof decodedBeacon.radioEquipment == "string" ? decodedBeacon.radioEquipment.split(",") : decodedBeacon.radioEquipment;
    } else {
      radioEquipment = [];
    }

    let newMMSIDecodedDisplay;
    if (decodedBeacon.MMSIDecoded && decodedBeacon.MMSIDecoded != "NULL") {
      if (decodedBeacon.MMSIDecoded.length <= 6) {
        newMMSIDecodedDisplay = decodedBeacon.beaconCountryCode + ("000000" + decodedBeacon.MMSIDecoded).slice(-6);
      } else {
        newMMSIDecodedDisplay = decodedBeacon.MMSIDecoded;
      }
    }
    // console.log('>>>>>>>>>> mmsi:', ("000000" + decodedBeacon.MMSIDecoded).slice(-6));

    return {
      vehicleType: vehicleType || "",
      vehicleTypeSailMast: vehicleTypeSailMast || "",
      vehicleRegistrationNumber: decodedBeacon.vehicleRegistrationNumber || "",
      vehicleName: decodedBeacon.vehicleName || "",
      vehicleModel: decodedBeacon.vehicleModel || "",
      homePort: decodedBeacon.homePort || "",
      vehicleColor: decodedBeacon.vehicleColor || "",
      nbLifeBoat: decodedBeacon.nbLifeBoat || "",
      nbLifeRaft: decodedBeacon.nbLifeRaft || "",
      // radioEquipment: decodedBeacon.radioEquipment || "",
      radioEquipment: radioEquipment,
      callSign: decodedBeacon.callSign || "",
      callSignDecoded: decodedBeacon.callSignDecoded || "",
      aisNumber: decodedBeacon.aisNumber || "",
      Imarsat: decodedBeacon.Imarsat || "",
      vehicleCellularNum: decodedBeacon.vehicleCellularNum || "",
      vehicleSatellitePhone: decodedBeacon.vehicleSatellitePhone || "",
      MMSI: decodedBeacon.MMSI || "",
      // MMSIDecoded: decodedBeacon.MMSIDecoded || "",
      MMSIDecoded: newMMSIDecodedDisplay || "",
      radioEquipmentOther: decodedBeacon.radioEquipmentOther || "",

      length: decodedBeacon.length || "",
      peopleCapacity: decodedBeacon.peopleCapacity || "",
      vehicleNationality: decodedBeacon.vehicleNationality || "",
      equippedWithDataRecord: decodedBeacon.equippedWithDataRecord || "",

      additionalComment: decodedBeacon.additionalComment || "",
      sarHazard: decodedBeacon.sarHazard || "",
      picture1: decodedBeacon.picture1 || "",
      picture2: decodedBeacon.picture2 || ""
    };
  },
  validationSchema: Yup.object().shape({
    vehicleType: Yup.string().required(i18n.t("vehicle-type-is-required")),
    vehicleRegistrationNumber: Yup.string().required(i18n.t("vehicle-registration-number-is-required")),
    nbLifeBoat: Yup.number().min(0, i18n.t("number-of-life-boats-must-be-greater-than-or-equal-to-0")),
    nbLifeRaft: Yup.number().min(0, i18n.t("number-of-life-rafts-must-be-greater-than-or-equal-to-0")),

    MMSIDecoded: Yup.string().notRequired(),
    MMSI: Yup.string()
      .notRequired()
      .matches(/^[0-9]{9}$/, {
        message: i18n.t("mmsi-number-must-be-0-9-9-digits")
      }),
    // .test("len", "MMSI Number must be 9 digits", function(val) {
    //   if ((val && val.length === 9) || !val) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }),

    callSignDecoded: Yup.string().notRequired(),
    // callSign: Yup.string()
    //   .notRequired()
    //   .matches(/^[0-9A-Fa-f]{1,64}$/, {
    //     message: "Radio Call Sign must be hexadecimal characters A-F, 0-9",
    //   }),

    length: Yup.number()
      .notRequired()
      .min(0, i18n.t("length-overall-m-must-be-greater-than-or-equal-to-0")),
    peopleCapacity: Yup.number()
      .notRequired()
      .min(0, i18n.t("capacity-crew-and-passengers-must-be-greater-than-or-equal-to-0"))
  }),

  handleSubmit: (values, { setSubmitting, isValid }) => {
    // console.log('log: values', values, isValid);

    let newEquipmentFormat;
    if (values.radioEquipment && typeof values.radioEquipment == "object") {
      newEquipmentFormat = values.radioEquipment.join();
    }
    values.radioEquipment = newEquipmentFormat;
    // console.log('log: values', values, isValid);

    // new form if is SAIL
    if (values.vehicleType == "SAIL") {
      values.vehicleType = values.vehicleType + " " + values.vehicleTypeSailMast + " Masts";
    }
    delete values.vehicleTypeSailMast;

    RegistrationState.updateBeaconFields(values);
    RegistrationState.setVehiculeNextStep();
  }
})(InnerForm);

const DecodedMismatchWarning = ({ show }) => {
  if (show) {
    return (
      <Transition.Group animation="fade down" duration={500}>
        <Message warning>
          <Message.Header>{i18n.t("warning-0")}</Message.Header>
          <Message.List>{i18n.t("information-entered-does-not-match-the-information-decoded-from-your-uin-please-contact-your-beacon-manufacturer-or-place-of-purchase-to-resolve")}</Message.List>
        </Message>
      </Transition.Group>
    );
  } else {
    return null;
  }
};

export default beaconLocationForm;
