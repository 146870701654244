
import getCountryByCode from './countryCode';
import * as CONST from './beaconConstants';

export function Hex2Bin(hex) {
  if (!checkHex(hex))
  return 0;


  let binaryString = "";
  for (var i = 0; i < hex.length; i++) {
    let char = hex.charAt(i);

    let binary = hexTable[char.toLowerCase()];
    //console.log("Home: exportfunctionHex2Bin -> char", char, binary);
    binaryString += binary;
  }
  return binaryString;
}

export function checkHex(n) {
  return /^[0-9A-Fa-f]{1,64}$/.test(n);
}

const hexTable = {
  0: '0000',
  1: '0001',
  2: '0010',
  3: '0011',
  4: '0100',
  5: '0101',
  6: '0110',
  7: '0111',
  8: '1000',
  9: '1001',
  a: '1010',
  b: '1011',
  c: '1100',
  d: '1101',
  e: '1110',
  f: '1111' };



export function getCountryCodeAndName(beaconBinary) {
  let countryCodeBinary = beaconBinary.substring(CONST.COUNTRY_CODE_BITS.start, CONST.COUNTRY_CODE_BITS.end);
  let countryCode = parseInt(countryCodeBinary, 2).toString();
  let countryName = getCountryByCode(countryCode);
  return {
    countryCode,
    countryName };

}

export function getFiveCharChecksum(hexId){
  let returnLimit = 1048576
  let runningSumLimit = 538471
  let constPrimVal = 3911
  let modifierLimit = 3847
  let modifier = 3803
  let runningSum = 0
  let tmpLongValue = 0
  let decimalValue = 0
  let i = 0
  for (let char in hexId){
    decimalValue = parseInt(hexId.charCodeAt(char));
    tmpLongValue = (runningSum * modifier) + decimalValue;
    if (i == hexId.length - 1){
      runningSum = tmpLongValue % returnLimit;
    }
    else{
      runningSum = tmpLongValue % runningSumLimit;
    }
    modifier = (constPrimVal * modifier) % modifierLimit;
    i += 1;
  }
  return ("000000" + runningSum.toString(16).toUpperCase()).slice(-5);
}


export default {
  Hex2Bin: Hex2Bin,
  checkHex: checkHex,
  getCountryCodeAndName: getCountryCodeAndName,
  getFiveCharChecksum: getFiveCharChecksum,
};