import { action, observable, computed, toJS } from "mobx";
import * as UserService from "../services/userServices";
import RouteState from "./routeState";
import * as AccessLogService from "../services/accessLogService";
import { executeDelegateBeaconToken } from "../services/authActionService";
import AppConfigState from "./appConfigState";
import ActivityState from "./activityState";
import AppState from "./appState";
import * as MailService from "../services/mailService";
import DashboardState from "./dashboardState";
import * as utils from "../utils/utils";
import db, { getTokenDb, getUserProfileDb, getAccountDb } from "../services/dbService";
import publicIp from "public-ip";

const TWENTY_MINUTES = 20 * 60 * 1000;

class UserState {
  @observable currentUser;
  @observable currentUserProfile;
  @observable hasSession = false;
  @observable allAdminUsers = [];
  @observable passwordResetSuccessful = false;
  @observable delegatedToken = null;
  @observable delegatedUser = null;
  @observable resetPasswordMailSent = false;
  @observable resetPasswordTokenUsed = false;
  @observable resetPasswordBanned = false;
  @observable challengeQuestionTries = 0;
  @observable challengeQuestionResult;
  @observable challengeSuccessful = false;
  @observable notAllowPasswordReset = false;
  passwordResetToken = "";
  resetPasswordUserId = "";
  defaultTmpPassword = "KEEP_S4ME_PASSW";
  @observable tmpPassword = this.defaultTmpPassword;

  @observable maintProviderUsers = [];
  @observable maintProviderOptions = [];
  @observable loginBanned = false;
  @observable loginTries = 0;
  // @observable roleIdForBriefCertificate = ["1","2"];
  @observable roleIdForBriefCertificate = ["2"];
  @observable tmpUserObject = null;
  @observable tmpUserProfile = null;
  showDisclaimerRole = ["2", "4"];
  notAllowToEditByRoleId = ["2", "4"];
  notAllowedToResetPassword = ["1", "2", "3", "4"];
  hasExpendedSearchAtFirst = ["3", "4"];
  canLoginThroughMaintenance = ["3", "4"];
  @observable column = null;
  @observable direction = null;
  @observable maintProviderInfo = null;
  @observable userFromAdminToken = null;
  @observable userFromAdmin = null;
  @observable legacyUserProfile = null;
  @observable accountDetail = null;

  constructor() {
    this.currentUser;
  }

  @action setLegacyUserProfile(legacyUser) {
    this.legacyUserProfile = legacyUser;
  }

  @action async setLegacyUserProfileFromUsername(username) {
    let userProfile = await UserService.getUserProfile(username);
    console.log('log ~ file: userState.js:68 ~ UserState ~ @actionsetLegacyUserProfileFromUsername ~ userProfile:', userProfile);
    this.legacyUserProfile = userProfile;
    return userProfile;
  }

  @action setTmpPassword(value) {
    this.tmpPassword = value;
  }

  /*
  async login(username, password) {
    try {
      let isLoginBanned = await this.loginIsStillBanned(username);
      if(isLoginBanned){
        // console.log("log: UserState -> login -> banned", this.loginTries);
        return {error: 'login_banned', message: 'Your account has been temporarily disabled after 5 unsuccessful login attempts. Please click on ‘Forgot your password’ to reset your password.'};
      }
      let result = await UserService.login(username, password);
      let userObject = await UserService.getUserObject(result.name);
      let userProfile = await UserService.getUserProfile(userObject._id);
      console.log("log: UserState -> login -> result", result, userObject, userProfile);
      this.setUserAndProfile(userObject, userProfile);

      //save access log
      AccessLogService.save({
        userId: userObject._id,
        userName: username,
        password: password,
        ipAddress: "",
        sessionDate: new Date(),
        roleId: userProfile.roleId || ''
      });

      return result;
    } catch (error) {
      //console.log('log: UserState -> login -> error', error);
      throw error;
    }
  }
  */

  async login(username, password) {
    try {
      let isLoginBanned = false; // await this.loginIsStillBanned(username); // Can't stand the time that takes!!
      if (isLoginBanned) {
        return {
          error: "login_banned",
          message: "Your account has been temporarily disabled after 5 unsuccessful login attempts. Please click on ‘Forgot your password’ to reset your password.",
        };
      }
      username = username.toLowerCase();
      let result = await UserService.login(username, password);

      if (result && result.isLegacyUser) {
        let error = new Error("User is legacy, needs to reset password");
        error.isLegacyUser = true;
        error.legacyUserProfile = await UserService.getUserProfile(result.username);
        throw error;
      }

      console.log("log: UserState -> login -> result", result);
      let userObject;
      try {
        userObject = await UserService.getUserObject(result.name);
      } catch (error) {
        console.log("log ~ file: userState.js ~ line 119 ~ UserState ~ login ~ error", error);
        //throw error;
      }
      let userProfile;
      try {
        userProfile = await UserService.getUserProfile(userObject._id);
      } catch (error) {
        console.log("log ~ file: userState.js ~ line 127 ~ UserState ~ login ~ error", error);
        //throw error;
      }

      let isInMaintenance = AppConfigState.config.maintenance;
      console.log("log ~ file: userState.js ~ line 134 ~ UserState ~ login ~ isInMaintenance", isInMaintenance);

      if (isInMaintenance !== "false" && !this.canLoginThroughMaintenance.includes(userProfile.roleId)) {
        AppState.setUserBlockedForMaintenance(true);
        throw new Error("Login is blocked for maintenance");
      }

      console.log("log: UserState -> login -> result", result, userObject, userProfile);
      // this.setUserAndProfile(userObject, userProfile);

      // display disclaimer for all users
      this.tmpUserObject = userObject; 
      this.tmpUserProfile = userProfile; 
      result.displayDisclaimer = true;
      result.roleId = userProfile.roleId; 

      try {
        window.gtag('event', 'profmgmt_login', {
          'user': userObject._id,          
        });
      } catch (error) {
        console.log('log ~ UserState ~ login ~ gtag error:', error);        
      }
      

      //save access log
      AccessLogService.save({
        userId: userObject._id, 
        userName: username,
        ipAddress: "", 
        sessionDate: new Date(),
        roleId: userProfile.roleId || "",
      });

      let config = await AppConfigState.getAppCOnfig();
      console.log("----> refresh app config: ", toJS(AppConfigState.config));

      return result;
    } catch (error) {
      throw error;
    }
  }

  @action resetTmp() {
    this.tmpUserObject = null;
    this.tmpUserProfile = null;
  }

  async logOut() {
    // reset state before logout
    ActivityState.setActiveTab(0);

    let result = await UserService.logOut();
    this.setUserAndProfile(null, null);
    DashboardState.setFilterUsername(null);
    DashboardState.reset();
    RouteState.setRoute("/");
    return result;
  }

  // async checkForSession() {
  //   try {
  //     let user = await UserService.checkForSession();
  //     console.log("log: UserState -> checkForSession -> user", user);
  //     let userProfile;
  //     if (!user) {
  //       return false;
  //     } else {
  //       userProfile = await UserService.getUserProfile(user._id);
  //     }
  //     this.setUserAndProfile(user, userProfile);
  //     return true;
  //   } catch (error) {
  //     console.error("log: UserState -> checkForSession -> error", error);
  //   }
  // }

  async checkForSession() {
    try {
      let user = await UserService.checkForSession();
      console.log("log: UserState -> checkForSession -> user", user);
      let userProfile;
      if (!user) {
        return false; 
      } else {
        userProfile = await UserService.getUserProfile(user._id);
      }
      // this.setUserAndProfile(user, userProfile);

      // if(this.showDisclaimerRole.includes(userProfile.roleId)){
      //   // set temporary data
      //   this.tmpUserObject = user;
      //   this.tmpUserProfile = userProfile;
      //   RouteState.setRoute("/ship-disclaimer"); 
      // }
      // else{ 
      //   this.setUserAndProfile(user, userProfile); 
      // }   
      // display disclaimer for all user
      this.tmpUserObject = user;
      this.tmpUserProfile = userProfile;
      //this.currentUserProfile = userProfile;
      switch (userProfile.roleId) {
        case "4":
          RouteState.setRoute("/sar-disclaimer");
          break;
        default:
          //RouteState.setRoute("/user-disclaimer");
          break;
      }

      return true;
    } catch (error) {
      console.error("log: UserState -> checkForSession -> error", error);
    }
  }

  @action setUserAndProfile(user, userProfile) {
    console.log("log: UserState -> @actionsetUserAndProfile -> user", user, userProfile);
    this.currentUser = user;
    this.currentUserProfile = userProfile;
    this.hasSession = true;
    if (this.currentUserProfile) {
      AppState.updateOnlineStatus();
    }
  }

  async saveUserProfile(userProfile) {
    delete userProfile.username; //just in case
    delete userProfile.password;
    delete userProfile.passwordConfirm;
    this.userProfile = userProfile;
    this.currentUserProfile = userProfile; // update user info for ui after saved
    return await UserService.saveUserProfile(userProfile);
  }

  async saveUserProfileWithPassword(userProfile) {
    let result = await this.setPasswordForUser(userProfile._id, userProfile.password);
    return await this.saveUserProfile(userProfile);
  }

  async saveDelegatedUserProfile(userProfile) {
    this.delegatedUser = { ...this.delegatedUser, ...userProfile };
    delete this.delegatedUser.requireToSetPassword;
    return await this.saveUserProfileWithPassword(this.delegatedUser);
  }

  async setPasswordForUser(userId, password) {
    console.log("log: UserState -> setPasswordForUser -> userId, password", userId, password);

    if (userId && userId.includes(":")) {
      //userId = userId.substring(userId.indexOf(":") + 1);
      console.log("log ~ file: userState.js ~ line 279 ~ UserState ~ setPasswordForUser ~ userId", userId);
    }

    let newPassword = { password: password };
    let result = await UserService.lambdaResetpassword(userId, newPassword);
    console.log("log: UserState -> setPasswordForUser -> result", result);
    let resetLoginBannedDate = await this.resetLoginBannedDate(userId);
    return result;
  }

  async setLegacyUserPasswordFromAdmin(userInfo, password) {
    try {
      userInfo.username = userInfo._id;
      userInfo.password = userInfo.password;
      userInfo.isLegacyUser = true;

      delete userInfo._id;
      delete userInfo._rev;
      delete userInfo.type;
      delete userInfo.salt;

      let result = await UserService.lambdaSignup(userInfo);

      let deleteResult = await UserService.deleteUserProfile(userInfo.username);
      console.log("log: UserState -> setLegacyUserPasswordFromAdmin -> result", result, deleteResult);
      return {
        result,
        deleteResult,
      };
    } catch (error) {}
  }

  @action setChallengeSuccessful(success) {
    this.challengeSuccessful = success;
  }

  async searchUsers(criteria) {
    try {
      let result = await UserService.searchUsers(criteria);
      let sortedByUsername = result.sort((a, b) => (a.username > b.username ? 1 : -1));
      this.setAllAdminUsers(sortedByUsername);
      console.log("log: UserState -> searchUsers -> result", result);
    } catch (error) {
      console.error("log: UserState -> searchUsers -> error", error);
    }
  }

  @action setAllAdminUsers(value) {
    this.allAdminUsers = value;
  }

  userIsAdmin(userProfile) {
    if (!userProfile) return false;
    return userProfile.roleId === "3"; //System Manager
  }

  isAdmin() {
    return this.currentUserProfile.roleId === "3";
  }

  async getMaintProviderUsers() {
    try {
      let result = await UserService.searchUsersByRole("7"); //Maintenance Provider Role
      this.setMaintProviderUsers(result.docs);
      this.createMaintProviderOptions(result.docs);
    } catch (error) {
      console.error("log: UserState -> getMaintProviderUsers -> error", error);
    }
  }

  @action setMaintProviderUsers(values) {
    this.maintProviderUsers = values;
  }

  async createMaintProviderOptions(users) {
    let options = [];
    if (users) {
      options = users.map((row) => {
        return { key: row._id, value: row._id, text: row.ownerName };
      });
    }
    this.setMaintProviderOptions(options);
  }

  @action setMaintProviderOptions(values) {
    this.maintProviderOptions = values;
  }

  @action async incrementChallengeQuestionTries() {
    try {
      this.challengeQuestionTries += 1;
      console.log("log: UserState -> @actionincrementChallengeQuestionTries -> challengeQuestionTries", this.challengeQuestionTries);
      if (this.challengeQuestionTries === 5) {
        this.resetPasswordBanned = true;
        let userProfile = await UserService.getUserProfile(this.challengeQuestionResult.userId);
        userProfile.forgotPasswordBan = Date.now();
        let saveResult = await UserService.saveUserProfile(userProfile);
        console.log("log: UserState -> @actionincrementChallengeQuestionTries -> saveResult", saveResult);
      }
    } catch (error) {
      console.warn("log: UserState -> @actionincrementChallengeQuestionTries -> error", error);
    }
  }

  @action resetForgotPassword() {
    this.resetPasswordMailSent = false;
    this.resetPasswordTokenUsed = false;
    this.resetPasswordBanned = false;
    this.challengeQuestionTries = 0;
    this.challengeQuestionResult = null;
    this.challengeSuccessful = false;
    this.notAllowPasswordReset = false;
  }

  async getPasswordResetTokenInfo(token) {
    try {
      this.passwordResetToken = token;
      let tokenObject = await UserService.getPasswordResetInfo(token);
      console.log("log: UserState -> getPasswordResetTokenInfo -> tokenObject", tokenObject);
      let userProfile = await UserService.getUserProfile(tokenObject.data.userId);
      if (this.userIsStillBanned(userProfile)) {
        return;
      }
      if (tokenObject && !tokenObject.useDate) {
        this.setChallengeQuestion(tokenObject.data);
      } else {
        this.setResetPasswordTokenUsed(true);
      }
    } catch (error) {
      console.warn("log: UserState -> getPasswordResetTokenInfo -> error", error);
      this.setResetPasswordTokenUsed(true);
    }
  }

  @action userIsStillBanned(userProfile) {
    if (userProfile.forgotPasswordBan && Date.now() - userProfile.forgotPasswordBan < TWENTY_MINUTES) {
      this.resetPasswordBanned = true;
      return true;
    } else {
      this.resetPasswordBanned = false;
      return false;
    }
  }

  @action async loginIsStillBanned(username) {
    let result = await UserService.lambdaFindUsername(username);

    let userId = !result.error ? "org.couchdb.user:" + result.username : "org.couchdb.user:" + username;
    let userProfile = await UserService.getUserProfile(userId);
    if (userProfile && userProfile.loginBannedDate && Date.now() - userProfile.loginBannedDate < TWENTY_MINUTES) {
      this.loginBanned = true;
      return true;
    } else {
      this.loginBanned = false;
      return false;
    }
  }

  @action async findUsername(username) {
    try {
      let result = await UserService.lambdaFindUsername(username);
      console.log("log ~ file: userState.js:443 ~ UserState ~ @actionfindUsername ~ result:", result);
      if (!result.username) {
        throw new Error("Username not found");
      }
      return result;
    } catch (error) {
      console.log("log ~ file: userState.js:444 ~ UserState ~ @actionfindUsername ~ error:", error);
      throw error;
    }
  }

  @action async incrementLoginTries(loginData) {
    try {
      this.loginTries += 1;
      if (this.loginTries === 5) {
        this.loginBanned = true;

        let result = await UserService.lambdaFindUsername(loginData.username);
        let userId = !result.error ? "org.couchdb.user:" + result.username : "org.couchdb.user:" + loginData.username;
        let userProfile = await UserService.getUserProfile(userId);
        userProfile.loginBannedDate = Date.now();
        let saveResult = await UserService.saveUserProfile(userProfile);
        console.log("log: UserState -> @incrementLoginTries -> saveResult", saveResult);
      }
    } catch (error) {
      console.warn("log: UserState -> @incrementLoginTries -> error", error);
    }
  }

  @action setResetPasswordTokenUsed(used) {
    this.resetPasswordTokenUsed = used;
  }

  @action async findUserAndCreateResetPasswordToken(value) {
    value = value.toLowerCase();
    console.log("log: UserState -> findUserAndCreateResetPasswordToken -> value", value);
    try {
      let tokenResult = await UserService.findUserAndCreateResetPasswordToken(value);
      console.log("log: UserState -> findUserAndCreateResetPasswordToken -> token", tokenResult);

      if (tokenResult.status === 404) {
        throw new Error("User not found");
      }

      if (this.notAllowedToResetPassword.includes(tokenResult.roleId)) {
        this.notAllowPasswordReset = true;
        return;
      }

      if (tokenResult.error === "User is banned from reset password") {
        this.resetPasswordBanned = true;
        return;
      }

      let ipAddress = "";
      try {
        ipAddress = await publicIp.v4();
      } catch (error) {
        console.log('log ~ file: userState.js:488 ~ UserState ~ @actionfindUserAndCreateResetPasswordToken ~ error:', error);
        ipAddress = "Couldn't find your IP"
      }
      console.log('log ~ file: userState.js:492 ~ UserState ~ @actionfindUserAndCreateResetPasswordToken ~ ipAddress:', ipAddress);

      if (tokenResult.emailAddress) {
        let emailTemplateKey = AppConfigState.getEmailTemplateKey("PASSWORD_RESET");
        if (emailTemplateKey) {
          const emailTemplate = AppConfigState.getEmailTemplate(emailTemplateKey);
          let emailData = MailService.prepareResetPasswordEmail(emailTemplate, tokenResult.userId, tokenResult.token, tokenResult.ownerName, ipAddress);
          console.log("log: UserState -> findUserAndCreateResetPasswordToken -> emailData", emailData);
          let response = await MailService.sendMail(tokenResult.emailAddress, emailData.subject, emailData.content);
          console.log("log: UserState -> findUserAndCreateResetPasswordToken -> response", response);
        }
        this.setResetMailSent(true);
      } else {
        await this.getPasswordResetTokenInfo(tokenResult.token);
      }

      return true;
    } catch (error) {
      console.log("log: UserState -> findUserAndCreateResetPasswordToken -> error", error);
      throw error;
    }
  }

  @action setResetMailSent(isSent) {
    this.resetPasswordMailSent = isSent;
  }

  @action setChallengeQuestion(challengeQuestion) {
    console.log("log: UserState -> @actionsetChallengeQuestion -> challengeQuestion", challengeQuestion);
    this.challengeQuestionResult = challengeQuestion;
  }

  async resetPassword(values) {
    try {
      let userId = this.challengeQuestionResult.userId;
      let passwordResetResult = await UserService.lambdaResetpassword(userId, values, this.passwordResetToken);
      console.log("log: UserState -> resetPassword -> passwordResetResult", passwordResetResult);
      this.setPasswordResetSuccess(true);
      let username = userId.substring(userId.indexOf(":") + 1);
      let loginResult = await this.login(username, values.password);
      let resetLoginBannedDate = await this.resetLoginBannedDate(userId);
      return passwordResetResult;
    } catch (error) {
      console.log("log: UserState -> resetPassword -> error", error);
      throw error;
    }
  }

  async resetLoginBannedDate(userId) {
    console.log("log ~ file: userState.js ~ line 529 ~ UserState ~ resetLoginBannedDate ~ userId", userId);

    try {
      let userProfile = await UserService.getUserProfile(userId);
      let d = new Date();
      userProfile.loginBannedDate = d.setFullYear(2010, 0, 1);
      let saveResult = await UserService.saveUserProfile(userProfile);
    } catch (error) {
      console.log("log: UserState -> resetPassword -> error", error);
      throw error;
    }
  }

  @action setPasswordResetSuccess(success) {
    this.passwordResetSuccessful = success;
  }

  async initialDelegatedToken(token, delegatedUser) {
    if (token != null) {
      this.delegatedToken = token;
      this.delegatedUser = delegatedUser;
    }
  }

  async activateDelegatedToken(token, delegatedUser) {
    let result = await executeDelegateBeaconToken(token, delegatedUser._id);
    console.log("log: UserState -> activateDelegatedToken -> result", token, result);
    if (result) {
      //send completion email
      const emailTemplateKey = AppConfigState.getEmailTemplateKey("DELEGATE_BEACON_REGISTRATION_COMPLETION");
      const emailTemplate = AppConfigState.getEmailTemplate(emailTemplateKey);
      let emailData = MailService.prepareEmailData(emailTemplate, null, delegatedUser, null, null);
      let response = await MailService.sendMail(delegatedUser.emailAddress, emailData.subject, emailData.content);
    }
    return result;
  }

  async resetDelegatedToken() {
    this.delegatedToken = null;
    this.delegatedUser = null;
  }

  async initialUserFromAdmin(token, user) {
    if (token != null) {
      this.userFromAdminToken = token;
      this.userFromAdmin = user;
      if (user.isLegacyUser) {
        this.legacyUserProfile = user;
      }
    }
  }

  async activateUserFromAdmin(token, user) {
    let result = await executeDelegateBeaconToken(token, user._id);
    // console.log("log: UserState -> activateDelegatedToken -> result", token, result, user);
    if (result && this.legacyUserProfile && !this.legacyUserProfile.isLegacyUser) {
      //send completion email
      const emailTemplateKey = AppConfigState.getEmailTemplateKey("USER_REGISTRATION_COMPLETION");
      const emailTemplate = AppConfigState.getEmailTemplate(emailTemplateKey);
      let emailData = MailService.prepareEmailData(emailTemplate, null, user, null, null);
      let response = await MailService.sendMail(user.emailAddress, emailData.subject, emailData.content);
    }
    return result;
  }

  async resetUserFromAdmin() {
    this.userFromAdminToken = null;
    this.userFromAdmin = null;
    this.legacyUserProfile = null;
  }

  async saveUserFromAdminProfile(userProfile) {
    this.userFromAdmin = { ...this.userFromAdmin, ...userProfile };
    delete this.userFromAdmin.requireToSetPassword;
    delete this.userFromAdmin.isLegacyUser;
    return await this.saveUserProfileWithPassword(this.userFromAdmin);
  }

  @action handleSortUsers(clickedColumn) {
    let sortUsers = this.allAdminUsers;
    if (this.column !== clickedColumn) {
      sortUsers = utils.sortBy(sortUsers, clickedColumn);
      sortUsers = sortUsers.slice().reverse();
      this.column = clickedColumn;
      this.direction = "descending";
    } else {
      sortUsers = utils.sortBy(sortUsers, clickedColumn);
      this.direction = this.direction === "descending" ? "ascending" : "descending";
      if (this.direction === "descending") {
        sortUsers = sortUsers.slice().reverse();
      }
    }
    this.setAllAdminUsers(sortUsers);
  }

  @action setMaintProviderInfo(value) {
    this.maintProviderInfo = value;
  }

  async getMaintProviderProfile(userId) {
    try {
      let userProfile = await UserService.getUserProfile(userId);
      this.setMaintProviderInfo(userProfile);
      return userProfile;
    } catch (error) {
      console.log("log: UserState -> getUserProfile -> error", error);
      throw error;
    }
  }

  async signUpLegacyUserWithEmail(userInfo, sendEmail = true) {
    try {
      userInfo.username = userInfo._id;
      userInfo.password = userInfo.password ? userInfo.password : "T3mP@" + userInfo._id;
      userInfo.requireToSetPassword = true;
      userInfo.isLegacyUser = true;

      delete userInfo._id;
      delete userInfo._rev;
      delete userInfo.type;
      delete userInfo.salt;

      let result = await UserService.lambdaSignup(userInfo);
      let deleteResult = await UserService.deleteUserProfile(userInfo.username);
      console.log("log ~ file: userState.js ~ line 643 ~ UserState ~ signUpLegacyUserWithEmail ~ deleteResult", deleteResult);
      console.log("IBRD: RegistrationState -> signUp legacy user -> result", result);

      if (!result.error) {
        let response;
        if (userInfo.emailAddress && sendEmail) {
          userInfo.ownerName = userInfo.ownerName ? userInfo.ownerName : userInfo.username;
          const emailTemplateKey = AppConfigState.getEmailTemplateKey("LEGACY_USER_RESET_PASSWORD");
          const emailTemplate = AppConfigState.getEmailTemplate(emailTemplateKey);
          let emailData = MailService.prepareEmailData(emailTemplate, null, userInfo, result.validateMailToken, null);
          let response = await MailService.sendMail(userInfo.emailAddress, emailData.subject, emailData.content);

          if (response && response.err) {
            console.log("send mail response -> err", response);
            result.error = "send mail error";
            result.reason = response.err.message;
            result.status = response.err.statusCode;
            result.name = response.err.code;
            result.message = response.err.message;
            delete result.ok;
          }
          this.setResetMailSent(true);
          // this.setLegacyUserProfile(null);
        }
      }
      return result;
    } catch (error) {
      console.log("IBRD: RegistrationState -> signUp legacy user -> error", error);
      throw error;
    }
  }

  async logOutForClearSession() {
    let result = await UserService.logOut();
    this.setUserAndProfile(null, null);
    DashboardState.setFilterUsername(null);
    return result;
  }

  async loginWithoutDisclaimer(username, password) {
    try {
      let result = await UserService.login(username, password);
      let userObject = await UserService.getUserObject(result.name);
      let userProfile = await UserService.getUserProfile(userObject._id);
      console.log("log: UserState -> login -> result", result, userObject, userProfile);
      this.setUserAndProfile(userObject, userProfile);

      //save access log
      AccessLogService.save({
        userId: userObject._id,
        userName: username,
        password: password,
        ipAddress: "",
        sessionDate: new Date(),
        roleId: userProfile.roleId || "",
      });

      return result;
    } catch (error) {
      throw error;
    }
  }

  async legacyProfileToUpdate() {
    try {
      this.legacyUserProfile.isTmpProfile = true;
      this.legacyUserProfile.username = this.legacyUserProfile._id;
      this.userFromAdmin = this.legacyUserProfile;
      RouteState.setRoute("/registerUser");
    } catch (error) {
      throw error;
    }
  }

  async getUserProfileById(userId) {
    console.log("log ~ file: userState.js ~ line 706 ~ UserState ~ getUserProfileById ~ userId", userId);
    try {
      let userProfile;
      try {
        userProfile = await UserService.getUserProfile(userId);
      } catch (error) {
        console.log("log ~ file: userState.js ~ line 712 ~ UserState ~ getUserProfileById ~ error", error);
        //throw error;
      }

      if (!userProfile) {
        try {
          userProfile = await UserService.getUserProfile("org.couchdb.user:" + userId);
        } catch (error) {
          console.log("log ~ file: userState.js ~ line 720 ~ UserState ~ getUserProfileById ~ error", error);
          throw error;
        }
      }

      console.log("log: UserState -> getUserProfileById -> result", userProfile);
      this.setAccountDetail(userProfile);

      return userProfile;
    } catch (error) {
      console.log("log ~ file: userState.js ~ line 727 ~ UserState ~ getUserProfileById ~ error", error);
      //throw error;
    }
  }
  @action setAccountDetail(value) {
    this.accountDetail = value;
  }
}

const singleton = new UserState(); //export class as singleton to be used through the application.
export default singleton;
