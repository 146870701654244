import hexUtils from './hexUtils';
import * as CONSTANTS from './beaconConstants';
import PouchDB from "pouchdb";
import decodeGen1Hex from './gen1decode';
import decodeGen2Hex from './gen2decode'
var IBRD_CONFIG = {
  "apikey": "19PU3-amHoD4_KBQOv0ozGvONA7nCDZfpiNc3h3lw_Dt",
  "host": "b7ae5786-8abe-4fba-bf17-15cbf21db7ef-bluemix.cloudantnosqldb.appdomain.cloud",
  "iam_apikey_description": "Auto-generated for key 21da7471-8e9b-456a-b698-55c8f60b3237",
  "iam_apikey_name": "ibrdtest",
  "iam_role_crn": "crn:v1:bluemix:public:iam::::serviceRole:Manager",
  "iam_serviceid_crn": "crn:v1:bluemix:public:iam-identity::a/9094160ed5244abe9359eabf56ccdd47::serviceid:ServiceId-0f09e657-0a5a-49a0-9a74-d5f730ce3df5",
  "password": "e1924aec6a94741172d24863587c9525e77bd433b21331d9b19e90c08257d65b",
  "port": 443,
  "url": "https://b7ae5786-8abe-4fba-bf17-15cbf21db7ef-bluemix.cloudantnosqldb.appdomain.cloud",
  "username": "b7ae5786-8abe-4fba-bf17-15cbf21db7ef-bluemix",
  "authActionsEndpoint" : "https://srn99qz2n6.execute-api.us-east-1.amazonaws.com/default/authActions",
  "sendMailEndpoint": "https://iyanouw7ee.execute-api.us-east-1.amazonaws.com/default/ibrdsendmail",
  "adminEmail": "william.thivierge@ofitechnology.com",
  //"baseUrl": "https://www.406registration.com",
  "baseUrl": "http://ibrd-staging.s3-website-us-east-1.amazonaws.com",
  "createUserEndpoint":"https://srn99qz2n6.execute-api.us-east-1.amazonaws.com/default/createUser"
}

const username = IBRD_CONFIG.username;
const password = IBRD_CONFIG.password;
const host = IBRD_CONFIG.host;
const dbUrl = "https://" + username + ":" + password + "@" + host;
let tacDb_remote = new PouchDB(dbUrl + "/tac_dev", { skip_setup: true });

export default async function decodeBeacon(beaconHex) {
  let beaconBinary = hexUtils.Hex2Bin(beaconHex);
  //console.log("Home: decodeBeacon -> beaconBinary", beaconBinary, beaconBinary.length);
  let decodedBeacon;
  if (beaconBinary.length === CONSTANTS.FIRST_GEN_BINARY_LENGTH) {
    decodedBeacon = await decodeGen1Hex(beaconBinary, tacDb_remote);
  } else if (beaconBinary.length === CONSTANTS.SECOND_GEN_BINARY_LENGTH) {
    decodedBeacon = await decodeGen2Hex(beaconBinary, tacDb_remote);
  } else {
    throw new Error('Please enter only valid hexadecimal characters A-F, 0-9')
  }
  decodedBeacon.hexId = beaconHex;
  console.log('log ~ file: BeaconDecode.js:43 ~ decodeBeacon ~ decodedBeacon:', decodedBeacon);
  return decodedBeacon;

}

