import { action, observable, computed, toJS } from "mobx";
import * as TacService from "../services/tacService";
import AppState from "./appState";
import * as utils from "../utils/utils";

class TacState {
  @observable tacData = [];
  @observable isLoading = false;
  @observable column = null;
  @observable direction = null;
  @observable manufacturers = [];
  @observable selectAll = false;
  lastTacId = 0;
  lastManufacturerId = 0;
  manufacturerOption = [];
  beaconTypeOption = [];

  publishOption = [{ key: "1", value: "1", text: "Published" }, { key: "0", value: "0", text: "Unpublished" }];

  beaconFreqencyOption = [
    { key: "0", value: "0", text: "- Select beacon frequency channel -" },
    { key: "1", value: "1", text: "406.025 MHz" },
    { key: "2", value: "2", text: "406.028 MHz" },
    { key: "3", value: "3", text: "406.037 MHz" },
    { key: "4", value: "4", text: "406.040 MHz" },
    { key: "5", value: "5", text: "406.049 MHz" },
    { key: "24", value: "24", text: "406.050 MHz" },
    { key: "6", value: "6", text: "406.052 MHz" },
    { key: "12", value: "12", text: "Not applicable" },
    { key: "16", value: "16", text: "406.025/406.028" },
    { key: "17", value: "17", text: "406.028/406.037" },
    { key: "18", value: "18", text: "406.037/406.040" },
    { key: "19", value: "19", text: "406.037 MHz" },
    { key: "20", value: "20", text: "406.028/406.040" },
    { key: "21", value: "21", text: "121.5/243 MHz/9" },
    { key: "23", value: "23", text: "406.031 MHz" },
  ];

  productionStatusOption = [{ key: "1", value: "1", text: "in production" }, { key: "0", value: "0", text: "not in production" }, { key: "-2", value: "-2", text: "Discontinued" }, { key: "-1", value: "-1", text: "unknown" }];

  protocolsTestedOption = [
    { key: "U", value: "U", text: "U - User" },
    { key: "UL", value: "UL", text: "UL - User-Location" },
    { key: "SL", value: "SL", text: "SL - Standard Location" },
    { key: "NL", value: "NL", text: "NL - National Location" },
    { key: "SSL", value: "SSL", text: "SSL - Standard Location Short" },
    { key: "NSL", value: "NSL", text: "NSL - National Location Short" },
    { key: "SSAS", value: "SSAS", text: "SSAS - Ship Security Alert System" },
    { key: "ELTPLB", value: "ELTPLB", text: "ELT and PLB Varia" },
    { key: "NUS", value: "NUS", text: "RLS - RLS Location" },
    { key: "DT", value: "DT", text: "DT - ELT(DT) Location" },
    { key: "SGB", value: "SGB", text: "SGB – Second Generation Beacon" },
  ];

  selfTestedOption = [{ key: "1", value: "1", text: "yes" }, { key: "0", value: "0", text: "no" }, { key: "-1", value: "-1", text: "unknown" }];

  testDurationOption = [{ key: "short", value: "short", text: "short" }, { key: "long", value: "long", text: "long" }, { key: "short_or_long", value: "short_or_long", text: "short or long" }, { key: "0", value: "0", text: "not available" }];

  testFlagOption = [{ key: "flag", value: "flag", text: "Corresponds to nominal flag" }, { key: "short", value: "short", text: "short" }, { key: "long", value: "long", text: "long" }, { key: "1", value: "1", text: "yes" }, { key: "0", value: "0", text: "no" }, { key: "-1", value: "-1", text: "unknown" }];

  homerFrequencyOption = [
    { key: "0", value: "0", text: "- Select homer frequency -" },
    { key: "7", value: "7", text: "121.5 MHz" },
    { key: "8", value: "8", text: "243 MHz" },
    { key: "9", value: "9", text: "121.5/243 MHz" },
    { key: "10", value: "10", text: "Not applicable" },
    { key: "13", value: "13", text: "243/245.1 MHz" },
    { key: "15", value: "15", text: "121.5 MHz/9 GHz" },
    { key: "21", value: "21", text: "121.5/243 MHz/9" },
    { key: "22", value: "22", text: "121.5/243/282.8" },
  ];

  strobeLightOption = [{ key: "1", value: "1", text: "yes" }, { key: "0", value: "0", text: "no" }, { key: "-1", value: "-1", text: "unknown" }];

  antennaSeparableOption = [{ key: "2", value: "2", text: "yes (AP)" }, { key: "1", value: "1", text: "yes" }, { key: "0", value: "0", text: "no" }, { key: "-1", value: "-1", text: "unknown" }];

  inProductionOption = [{ key: "0", value: "In business", text: "In business" }, { key: "1", value: "Not in business", text: "Not in business" }];

  encposIntervalTypeOption = [
    { key: "0", value: "N/A", text: "N/A" }, 
    { key: "1", value: "No (does not support position data update after beacon activation)", text: "No (does not support position data update after beacon activation)" }, 
    { key: "2", value: "Fixed value (minutes)", text: "Fixed value (minutes)" },
    { key: "3", value: "Range (minutes)", text: "Range (minutes)" },
    { key: "4", value: "Other", text: "Other" } ]

  countryOption = [
    { key: "0", value: "0", text: "- Select country -" },
    { key: "0", value: "3", text: "Canada" },
    { key: "0", value: "6", text: "Australia" },
    { key: "0", value: "7", text: "Afghanistan" },
    { key: "0", value: "8", text: "Alaska (State of)" },
    { key: "0", value: "9", text: "United States" },
    { key: "0", value: "10", text: "Spain" },
    { key: "0", value: "11", text: "Spain" },
    { key: "0", value: "12", text: "United Arab Emirates" },
    { key: "0", value: "13", text: "Algeria" },
    { key: "0", value: "14", text: "Argentina" },
    { key: "0", value: "15", text: "Japan" },
    { key: "0", value: "16", text: "Japan" },
    { key: "0", value: "17", text: "France" },
    { key: "0", value: "18", text: "France" },
    { key: "0", value: "19", text: "France" },
    { key: "0", value: "20", text: "United States" },
    { key: "0", value: "21", text: "Russian Federation" },
    { key: "0", value: "22", text: "South Africa" },
    { key: "0", value: "23", text: "Indonesia" },
    { key: "0", value: "24", text: "Singapore" },
    { key: "0", value: "25", text: "Singapore" },
    { key: "0", value: "26", text: "Singapore" },
    { key: "0", value: "27", text: "Thailand" },
    { key: "0", value: "28", text: "New Zealand" },
    { key: "0", value: "29", text: "Greece" },
    { key: "0", value: "30", text: "Greece" },
    { key: "0", value: "31", text: "Korea (Republic of)" },
    { key: "0", value: "32", text: "Korea (Republic of)" },
    { key: "0", value: "33", text: "India" },
    { key: "0", value: "34", text: "Brazil" },
    { key: "0", value: "35", text: "United States" },
    { key: "0", value: "36", text: "United States" },
    { key: "0", value: "37", text: "United States" },
    { key: "0", value: "38", text: "United Kingdom" },
    { key: "0", value: "39", text: "United Kingdom" },
    { key: "0", value: "40", text: "United Kingdom" },
    { key: "0", value: "41", text: "United Kingdom" },
    { key: "0", value: "44", text: "Albania" },
    { key: "0", value: "45", text: "Andorra" },
    { key: "0", value: "46", text: "Austria" },
    { key: "0", value: "47", text: "Belgium" },
    { key: "0", value: "48", text: "Bulgaria" },
    { key: "0", value: "49", text: "Belarus" },
    { key: "0", value: "50", text: "Vatican City State" },
    { key: "0", value: "51", text: "Cyprus" },
    { key: "0", value: "52", text: "Cyprus" },
    { key: "0", value: "53", text: "Germany" },
    { key: "0", value: "54", text: "Cyprus" },
    { key: "0", value: "55", text: "Georgia" },
    { key: "0", value: "56", text: "Moldova" },
    { key: "0", value: "57", text: "Malta" },
    { key: "0", value: "58", text: "Armenia" },
    { key: "0", value: "59", text: "Germany" },
    { key: "0", value: "60", text: "Denmark" },
    { key: "0", value: "61", text: "Denmark" },
    { key: "0", value: "62", text: "Finland" },
    { key: "0", value: "63", text: "Faroe Islands" },
    { key: "0", value: "64", text: "Gibraltar" },
    { key: "0", value: "65", text: "Greece" },
    { key: "0", value: "66", text: "Croatia" },
    { key: "0", value: "67", text: "Morocco" },
    { key: "0", value: "68", text: "Hungary" },
    { key: "0", value: "69", text: "Netherlands" },
    { key: "0", value: "70", text: "Netherlands" },
    { key: "0", value: "71", text: "Netherlands" },
    { key: "0", value: "72", text: "Italy" },
    { key: "0", value: "73", text: "Malta" },
    { key: "0", value: "74", text: "Malta" },
    { key: "0", value: "75", text: "Ireland" },
    { key: "0", value: "76", text: "Iceland" },
    { key: "0", value: "77", text: "Liechtenstein" },
    { key: "0", value: "78", text: "Luxembourg" },
    { key: "0", value: "79", text: "Monaco" },
    { key: "0", value: "80", text: "Malta" },
    { key: "0", value: "81", text: "Norway" },
    { key: "0", value: "82", text: "Norway" },
    { key: "0", value: "83", text: "Norway" },
    { key: "0", value: "84", text: "Poland" },
    { key: "0", value: "85", text: "Portugal" },
    { key: "0", value: "86", text: "Romania" },
    { key: "0", value: "87", text: "Sweden" },
    { key: "0", value: "88", text: "Sweden" },
    { key: "0", value: "89", text: "Slovak Republic" },
    { key: "0", value: "90", text: "San Marino" },
    { key: "0", value: "91", text: "Switzerland" },
    { key: "0", value: "92", text: "Czech Republic" },
    { key: "0", value: "93", text: "Turkey" },
    { key: "0", value: "94", text: "Ukraine" },
    { key: "0", value: "95", text: "North Macedonia (Republic of)" },
    { key: "0", value: "96", text: "Latvia" },
    { key: "0", value: "97", text: "Estonia" },
    { key: "0", value: "98", text: "Lithuania" },
    { key: "0", value: "99", text: "Slovenia" },
    { key: "0", value: "100", text: "Anguilla" },
    { key: "0", value: "101", text: "Antigua and Barbuda" },
    { key: "0", value: "102", text: "Antigua and Barbuda" },
    { key: "0", value: "103", text: "Netherlands Antilles" },
    { key: "0", value: "104", text: "Aruba" },
    { key: "0", value: "105", text: "Bahamas" },
    { key: "0", value: "106", text: "Bahamas" },
    { key: "0", value: "107", text: "Bermuda" },
    { key: "0", value: "108", text: "Bahamas" },
    { key: "0", value: "109", text: "Belize" },
    { key: "0", value: "110", text: "Barbados" },
    { key: "0", value: "111", text: "Cayman Islands" },
    { key: "0", value: "112", text: "Costa Rica" },
    { key: "0", value: "113", text: "Cuba" },
    { key: "0", value: "114", text: "Dominica" },
    { key: "0", value: "115", text: "Dominican Republic" },
    { key: "0", value: "116", text: "Guadeloupe" },
    { key: "0", value: "117", text: "Grenada" },
    { key: "0", value: "118", text: "Greenland" },
    { key: "0", value: "119", text: "Guatemala" },
    { key: "0", value: "120", text: "Honduras" },
    { key: "0", value: "121", text: "Haiti" },
    { key: "0", value: "122", text: "Jamaica" },
    { key: "0", value: "123", text: "Saint Kitts and Nevis" },
    { key: "0", value: "124", text: "Saint Lucia" },
    { key: "0", value: "125", text: "Mexico" },
    { key: "0", value: "126", text: "Martinique" },
    { key: "0", value: "127", text: "Montserrat" },
    { key: "0", value: "128", text: "Nicaragua" },
    { key: "0", value: "129", text: "Panama" },
    { key: "0", value: "130", text: "Panama" },
    { key: "0", value: "131", text: "Panama" },
    { key: "0", value: "132", text: "Panama" },
    { key: "0", value: "133", text: "Panama" },
    { key: "0", value: "134", text: "Panama" },
    { key: "0", value: "135", text: "Panama" },
    { key: "0", value: "136", text: "Puerto Rico" },
    { key: "0", value: "137", text: "El Salvador" },
    { key: "0", value: "138", text: "St. Pierre and Miquelon" },
    { key: "0", value: "139", text: "Trinidad and Tobago" },
    { key: "0", value: "140", text: "Turks and Caicos Islands" },
    { key: "0", value: "141", text: "Panama" },
    { key: "0", value: "142", text: "Panama" },
    { key: "0", value: "143", text: "Panama" },
    { key: "0", value: "144", text: "Saint Vincent and the Grenadines" },
    { key: "0", value: "145", text: "Saint Vincent and the Grenadines" },
    { key: "0", value: "146", text: "Saint Vincent and the Grenadines" },
    { key: "0", value: "147", text: "British Virgin Islands" },
    { key: "0", value: "148", text: "United States Virgin Islands" },
    { key: "0", value: "149", text: "Saudi Arabia" },
    { key: "0", value: "150", text: "Bangladesh" },
    { key: "0", value: "151", text: "Bahrain" },
    { key: "0", value: "152", text: "Bhutan" },
    { key: "0", value: "153", text: "China" },
    { key: "0", value: "154", text: "China" },
    { key: "0", value: "155", text: "Chinese Taipei" },
    { key: "0", value: "156", text: "Sri Lanka" },
    { key: "0", value: "157", text: "Iran" },
    { key: "0", value: "158", text: "Azerbaijan" },
    { key: "0", value: "159", text: "Iraq" },
    { key: "0", value: "160", text: "Israel" },
    { key: "0", value: "161", text: "Democratic People's Republic of Korea" },
    { key: "0", value: "162", text: "Turkmenistan" },
    { key: "0", value: "163", text: "Kuwait" },
    { key: "0", value: "164", text: "Kazakhstan" },
    { key: "0", value: "165", text: "Uzbekistan" },
    { key: "0", value: "166", text: "Jordan" },
    { key: "0", value: "167", text: "Fiji" },
    { key: "0", value: "168", text: "Lebanon" },
    { key: "0", value: "169", text: "Cocos (Keeling) Islands" },
    { key: "0", value: "170", text: "Kiribati" },
    { key: "0", value: "171", text: "Laos" },
    { key: "0", value: "172", text: "Malaysia" },
    { key: "0", value: "173", text: "Northern Mariana Islands" },
    { key: "0", value: "174", text: "Marshall Islands" },
    { key: "0", value: "175", text: "New Caledonia" },
    { key: "0", value: "176", text: "Niue" },
    { key: "0", value: "177", text: "Nauru" },
    { key: "0", value: "178", text: "French Polynesia" },
    { key: "0", value: "179", text: "Philippines" },
    { key: "0", value: "180", text: "Macao, China" },
    { key: "0", value: "181", text: "Maldives" },
    { key: "0", value: "182", text: "Papua New Guinea" },
    { key: "0", value: "183", text: "Mongolia" },
    { key: "0", value: "184", text: "Pitcairn" },
    { key: "0", value: "185", text: "Nepal" },
    { key: "0", value: "186", text: "Solomon Islands" },
    { key: "0", value: "187", text: "Oman" },
    { key: "0", value: "188", text: "American Samoa" },
    { key: "0", value: "189", text: "Pakistan" },
    { key: "0", value: "190", text: "Samoa" },
    { key: "0", value: "191", text: "Qatar" },
    { key: "0", value: "192", text: "Tonga" },
    { key: "0", value: "193", text: "Tuvalu" },
    { key: "0", value: "194", text: "Syria" },
    { key: "0", value: "195", text: "Vietnam" },
    { key: "0", value: "196", text: "Vanuatu" },
    { key: "0", value: "197", text: "Wallis and Futuna Islands" },
    { key: "0", value: "198", text: "Angola" },
    { key: "0", value: "199", text: "Yemen" },
    { key: "0", value: "200", text: "Yemen" },
    { key: "0", value: "201", text: "Hong Kong, China" },
    { key: "0", value: "202", text: "Burundi" },
    { key: "0", value: "203", text: "Bosnia and Herzegovina" },
    { key: "0", value: "204", text: "Benin" },
    { key: "0", value: "205", text: "Botswana" },
    { key: "0", value: "206", text: "Myanmar" },
    { key: "0", value: "207", text: "Central African Republic" },
    { key: "0", value: "208", text: "Brunei Darussalam" },
    { key: "0", value: "209", text: "Cameroon" },
    { key: "0", value: "210", text: "Micronesia" },
    { key: "0", value: "211", text: "Congo" },
    { key: "0", value: "212", text: "Palau" },
    { key: "0", value: "213", text: "Comoros" },
    { key: "0", value: "214", text: "Cambodia" },
    { key: "0", value: "215", text: "Cape Verde" },
    { key: "0", value: "216", text: "Cambodia" },
    { key: "0", value: "217", text: "Christmas Island" },
    { key: "0", value: "218", text: "Cook Islands" },
    { key: "0", value: "219", text: "Malawi" },
    { key: "0", value: "220", text: "Niger" },
    { key: "0", value: "221", text: "Nigeria" },
    { key: "0", value: "222", text: "Namibia" },
    { key: "0", value: "223", text: "Reunion (also same country code for Mayotte)" },
    { key: "0", value: "224", text: "Rwanda" },
    { key: "0", value: "225", text: "Sudan" },
    { key: "0", value: "226", text: "Djibouti" },
    { key: "0", value: "227", text: "Senegal" },
    { key: "0", value: "228", text: "Seychelles" },
    { key: "0", value: "229", text: "Egypt" },
    { key: "0", value: "230", text: "Ethiopia" },
    { key: "0", value: "231", text: "St. Helena" },
    { key: "0", value: "232", text: "Somalia" },
    { key: "0", value: "233", text: "Sierra Leone" },
    { key: "0", value: "234", text: "Sao Tome and Principe" },
    { key: "0", value: "235", text: "Swaziland" },
    { key: "0", value: "236", text: "Chad" },
    { key: "0", value: "237", text: "Togo" },
    { key: "0", value: "238", text: "Tunisia" },
    { key: "0", value: "239", text: "Tanzania" },
    { key: "0", value: "240", text: "Uganda" },
    { key: "0", value: "241", text: "Democratic Republic of the Congo" },
    { key: "0", value: "242", text: "Tanzania" },
    { key: "0", value: "243", text: "Zambia" },
    { key: "0", value: "244", text: "Zimbabwe" },
    { key: "0", value: "245", text: "Bolivia" },
    { key: "0", value: "246", text: "Chile" },
    { key: "0", value: "247", text: "Colombia" },
    { key: "0", value: "248", text: "Ecuador" },
    { key: "0", value: "249", text: "Falkland Islands (Malvinas)" },
    { key: "0", value: "250", text: "Guyana" },
    { key: "0", value: "251", text: "Paraguay" },
    { key: "0", value: "252", text: "Peru" },
    { key: "0", value: "253", text: "Suriname" },
    { key: "0", value: "254", text: "Uruguay" },
    { key: "0", value: "255", text: "Venezuela" },
    { key: "0", value: "256", text: "Eritrea" },
    { key: "0", value: "257", text: "Gabon" },
    { key: "0", value: "258", text: "Ghana" },
    { key: "0", value: "259", text: "Gambia" },
    { key: "0", value: "260", text: "Guinea-Bissau" },
    { key: "0", value: "261", text: "Equatorial Guinea" },
    { key: "0", value: "262", text: "Guinea" },
    { key: "0", value: "263", text: "Burkina Faso" },
    { key: "0", value: "264", text: "Kenya" },
    { key: "0", value: "265", text: "Liberia" },
    { key: "0", value: "266", text: "Liberia" },
    { key: "0", value: "267", text: "Libya" },
    { key: "0", value: "268", text: "Lesotho" },
    { key: "0", value: "269", text: "Mauritius" },
    { key: "0", value: "270", text: "Madagascar" },
    { key: "0", value: "271", text: "Mali" },
    { key: "0", value: "272", text: "Mozambique" },
    { key: "0", value: "273", text: "Mauritania" },
    { key: "0", value: "275", text: "Greece" },
    { key: "0", value: "276", text: "Azores" },
    { key: "0", value: "277", text: "Madeira" },
    { key: "0", value: "278", text: "Montenegro" },
    { key: "0", value: "279", text: "Serbia" },
    { key: "0", value: "280", text: "Palestine" },
    { key: "0", value: "281", text: "Kyrgyz Republic" },
    { key: "0", value: "282", text: "Adelie Land" },
    { key: "0", value: "283", text: "Saint Paul and Amsterdam Islands" },
    { key: "0", value: "284", text: "Ascension Island" },
    { key: "0", value: "285", text: "Crozet Archipelago" },
    { key: "0", value: "286", text: "Ivory Coast" },
    { key: "0", value: "287", text: "Kerguelen Islands" },
    { key: "0", value: "288", text: "Guiana (French Dept. Of)" },
    { key: "0", value: "289", text: "Timor-Leste" },
    { key: "0", value: "291", text: "Vanuatu" },
    { key: "0", value: "292", text: "Malta" },
    { key: "0", value: "295", text: "Panama" },
    { key: "0", value: "296", text: "Singapore" },
    { key: "0", value: "297", text: "China" },
    { key: "0", value: "298", text: "Tajikistan" },
    { key: "0", value: "299", text: "299" },
    { key: "0", value: "300", text: "Malta" },
    { key: "0", value: "301", text: "Malta" },
    { key: "0", value: "302", text: "Malta" },
    { key: "0", value: "303", text: "Malta" },
    { key: "0", value: "304", text: "Comoros" },
    { key: "0", value: "305", text: "South Sudan" },
    { key: "0", value: "306", text: "Panama" },
    { key: "0", value: "307", text: "United Arab Emirates" },
    { key: "0", value: "308", text: "Timor-Leste (Democratic Republic of)" },
  ];

  @action setTacData(data) {
    console.log('log ~ file: tacState.js ~ line 382 ~ TacState ~ @actionsetTacData ~ data', data);
    this.tacData = data;
  }
  @action setManufacturers(data) {
    this.manufacturers = data;
  }
  @action setIsLoading(value) {
    this.isLoading = value;
  }

  async searchTAC(criteria) {
    this.setIsLoading(true);
    
    let tacData = await TacService.searchTAC(criteria);
    if (tacData) {
      // sort by tac_number first
      tacData.docs.sort((a,b) => a.tac_number - b.tac_number)
      this.setTacData(tacData.docs);
    }
    this.setIsLoading(false);
  }

  async saveTAC(item) {
    let saved = await TacService.saveTAC(item);
    return saved;
  }

  async deleteTAC(tacId) {
    this.setIsLoading(true);
    let deleted = await TacService.deleteTAC(tacId);
    this.setIsLoading(false);
    return deleted;
  }

  @action handleSort(clickedColumn) {
    let sortData = this.tacData;
    if (this.column !== clickedColumn) {
      sortData = utils.sortBy(sortData, clickedColumn);
      sortData = sortData.slice().reverse();
      this.column = clickedColumn;
      this.direction = "descending";
    } else {
      sortData = utils.sortBy(sortData, clickedColumn);
      this.direction = this.direction === "descending" ? "ascending" : "descending";
      if (this.direction === "descending") {
        sortData = sortData.slice().reverse();
      }
    }
    this.setTacData(sortData);
  }

  @action defaultSort(clickedColumn) {
    let sortData = this.tacData;
    sortData = utils.sortBy(sortData, clickedColumn);
    sortData = sortData.slice();
    this.column = clickedColumn;
    this.direction = "ascending";
    this.setTacData(sortData);
  }

  async getManufacturers() {
    let manufacturers = await TacService.getManufacturers();
    // console.log('>>>>>>>>>>>>query2: ', manufacturers);
    if (manufacturers) {
      this.manufacturers = manufacturers.docs;
    }
  }

  async createManufacturerOption(criteria) {
    // this.setIsLoading(true)
    let options = [];
    let data = await TacService.getManufacturers(criteria);
    if (data) {
      options = data.docs.map((entry) => {
        return { key: entry.id, value: entry.id, text: entry.name };
      });
      options.sort((a, b) => a.text.localeCompare(b.text));
      options.unshift({ key: "idle", value: "", text: "-- All Manufacturers --" });
      this.setManufacturerOption(options);
    }
    // this.manufacturerOption = options;
    // this.setIsLoading(false)
    return options;
  }
  @action setManufacturerOption(value) {
    this.manufacturerOption = value;
  }

  async createBeaconTypeOption(criteria) {
    // this.setIsLoading(true)
    let options = [];
    let data = await TacService.getBeaconTypes(criteria);
    if (data) {
      options = data.docs.map((entry) => {
        return { key: entry.id, value: entry.id, text: entry.value };
      });
      options.sort((a, b) => a.text.localeCompare(b.text));
      options.unshift({ key: "idle", value: "", text: "-- All Beacon Types --" });
      this.setTacBeaconTypeOption(options);
    }
    // this.beaconTypeOption = options;
    // this.setIsLoading(false)
    return options;
  }
  @action setTacBeaconTypeOption(value) {
    this.beaconTypeOption = value;
  }

  async searchManufacturer(criteria) {
    this.setIsLoading(true);
    let result = await TacService.searchManufacturer(criteria);
    if (result) {
      this.setManufacturers(result.docs);
    }
    this.setIsLoading(false);
  }

  @action sortManufacturer(clickedColumn) {
    let sortData = this.manufacturers;
    if (this.column !== clickedColumn) {
      sortData = utils.sortBy(sortData, clickedColumn);
      sortData = sortData.slice().reverse();
      this.column = clickedColumn;
      this.direction = "descending";
    } else {
      sortData = utils.sortBy(sortData, clickedColumn);
      this.direction = this.direction === "descending" ? "ascending" : "descending";
      if (this.direction === "descending") {
        sortData = sortData.slice().reverse();
      }
    }
    this.setManufacturers(sortData);
  }

  // async saveManufacturer(item){
  //     let saved = await TacService.saveManufacturer(item);
  //     return saved
  // }

  // async deleteManufacturer(tacId){
  //     this.setIsLoading(true);
  //     let deleted = await TacService.deleteManufacturer(tacId);
  //     this.setIsLoading(false);
  //     return deleted
  // }

  async getLastTacId() {
    let tacData = await TacService.getLastTacId();
    if (tacData) {
      // this.lastTacId = tacData.docs[tacData.docs.length - 1]._id;
      let allItems = tacData.docs;
      let sortData = utils.sortBy(allItems, "id");
      this.lastTacId = sortData[sortData.length - 1]._id;
    }
    return this.lastTacId;
  }

  async getLastManufacturerId() {
    let data = await TacService.getLastManufacturerId();
    if (data) {
      let allItems = data.docs;
      let sortData = utils.sortBy(allItems, "id");
      this.lastManufacturerId = sortData[sortData.length - 1].id;
    }
    return this.lastManufacturerId;
  }

  dateTimeFormat(timestamp, displayTime = false) {
    if (!timestamp) return;
    let newDate = new Date(timestamp);
    let year = newDate.getFullYear();
    let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
    let date = ("0" + newDate.getDate()).slice(-2);
    let hours = ("0" + newDate.getHours()).slice(-2);
    let minutes = ("0" + newDate.getMinutes()).slice(-2);
    let seconds = ("0" + newDate.getSeconds()).slice(-2);

    if (displayTime) {
      return year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
    } else {
      return year + "-" + month + "-" + date;
    }
  }

  @action toggleSelectItem = (e, data) => {
    this.tacData.map((row) => {
      if (row.id == data.value) {
        row.checked = data.checked;
      }
      return row;
    });
    // console.log("toggleSelectItem -> selectedBeacons: After: ", toJS(this.tacData), data)
  };

  @action toggleSelectAll = () => {
    this.selectAll = !this.selectAll;
    this.tacData.map((row) => {
      row.checked = this.selectAll;
      return row;
    });
  };

  @action toggleExpanse = (data) => {
    this.tacData.map((row) => {
      if (row.id == data.id) {
        row.isExpanse = data.isExpanse ? !data.isExpanse : true;
      }
      return row;
    });
    // console.log("toggleSelectItem -> selectedBeacons: After: ", toJS(this.tacData), data)
  };

  @action selectAllItems = () => {
    this.selectAll = true;
    this.tacData.map((row) => {
      row.checked = this.selectAll;
      return row;
    });
  };
  @action clearAllItems = () => {
    this.selectAll = false;
    this.tacData.map((row) => {
      row.checked = this.selectAll;
      return row;
    });
  };
}

const singleton = new TacState();
export default singleton;
