import { action, observable, computed, toJS } from "mobx";
import * as PocService from "../services/pocService";
import * as TacService from "../services/tacService";
import AppState from "./appState";
import * as utils from "../utils/utils";
import Fuse from "fuse.js";

const ContactTypesOptions = [
  {
    key: "406 MHz Beacon Registers",
    text: "406 MHz Beacon Registers",
    value: "406reg", //Here should we look for both or just one like 406Rreg
  },
  {
    key: "National Contacts for Beacon Matters",
    text: "National Contacts for Beacon Matters",
    value: "406admin", //Not sure what Contacts for Beacon Matter, but it is the same as 406Reg or 406Admin?
  },
  {
    key: "SPOC - SAR Points of Contact",
    text: "SPOC - SAR Points of Contact",
    value: "spoc", //Pretty confident here
  },
  {
    key: "PAIS/SAIS - Primary / Secondary Air Information Stations",
    text: "PAIS/SAIS - Primary / Secondary Air Information Stations",
    value: "sais_pais", //Pretty confident here
  },
  {
    key: "MCC - Mission Control Centers",
    text: "MCC - Mission Control Centers",
    value: "mcc", //Pretty confident here
  },
  {
    key: "Ground Segment Equipment Manufacturers",
    text: "Ground Segment Equipment Manufacturers",
    value: "gsem", //Pretty confident here
  },

  {
    key: "List of Beacon Test Facilities",
    text: "List of Beacon Test Facilities",
    value: "????", //Not sure where to find this list
  }, 
];

class PocState {
  @observable pocData = [];
  @observable isLoading = false;
  @observable pocOption = [];
  @observable column = null;
  @observable direction = null;
  pocAll = [];
  @observable pocAllIsLoading = false;
  @observable pocAllFiltered = [];
  allPocData = [];
  ContactTypesOptions = ContactTypesOptions;
  @observable searchString = "";
  @observable selectAllToggled = false;
  @observable midFilter;
  searchTimeout = null;
  lastPocId = 0;

  @action setPocData(data) {
    // console.log('log: PocState -> @actionsetPocData -> data', data);
    this.pocData = data;
  }

  @action setIsLoading(value) {
    this.isLoading = value;
  }

  // async searchPOC(criterias) {
  //   try {
  //     console.log("log: PocState -> searchPOC -> criterias", criterias);
  //     this.setIsLoading(true);
  //     if (!AppState.appLoaded) {
  //       // if app is not loaded yet,
  //       setTimeout(() => {
  //         this.searchPOC(criterias);
  //       }, 300);
  //     }
  //     if (!this.allPocData || this.allPocData.length == 0) {
  //       this.allPocData = await PocService.getAllPoc();
  //     }

  //     if (this.allPocData) {
  //       let filteredPocData = this.filterData(this.allPocData, criterias);
  //       console.log("log: PocState -> searchPOC -> filteredPocData", filteredPocData);

  //       this.setPocData(filteredPocData);
  //       this.createPocOptions(this.allPocData);
  //     }
  //     this.setIsLoading(false);
  //   } catch (error) {
  //     console.log("log: PocState -> searchPOC -> error", error);
  //   }

  async searchPOC(criterias) {
    try {
      console.log("log: PocState -> searchPOC -> criterias", criterias);
      this.setIsLoading(true);

      let allPocData = await PocService.getAllPoc()   
      if (allPocData) {
        let filteredPocData = this.filterData(allPocData, criterias);
        console.log("log: PocState -> searchPOC -> filteredPocData", filteredPocData);

        this.setPocData(filteredPocData);
        this.createPocOptions(allPocData);
      }
      this.setIsLoading(false);
    } catch (error) {
      console.log("log: PocState -> searchPOC -> error", error);
    }
  }

  filterData(pocData, criterias) {
    let filteredPocData = pocData;
    if (JSON.stringify(criterias) !== JSON.stringify({})) {
      filteredPocData = pocData.filter((poc) => {
        let passFilter = false;
        for (const property in criterias) {
          let criteriaValue = criterias[property][0];
          let pocValue = poc[property];
          // if (pocValue && pocValue.includes(criteriaValue)) {
          if (pocValue && pocValue.toLowerCase().includes(criteriaValue.toLowerCase())) {
            passFilter = true;
          } else {
            return false;
          }
        }
        return passFilter;
      });
    }

    return filteredPocData;
  }

  async savePoc(item) {
    let saved = await PocService.savePoc(item);
    return saved;
  }

  @action setPocOptions(value) {
    this.pocOption = value;
  }

  async createPocOptions(pocData) {
    if (!pocData) return;

    let options = pocData.map((row) => {
      // if(row.pOCIndex==0){
      //     return {key: row.pOCIndex, value: row.pOCIndex, text: ''}
      // }
      // else{
      //     let text = row.bcnRegPocName + ' (' + row.pOCIndex + ')'
      //     return {key: row.pOCIndex, value: row.pOCIndex, text: text}
      // }
      if (row._id == 0) {
        return { value: row._id, label: "" };
      } else {
        let text = row.name + " (" + row._id + ")";
        return { value: row._id, label: text };
      }
    });
    // let _first = new Array(options.find(row => row.key == '0'))
    // let _options = options.filter(row => row.key != '0')
    // let lastOptions = [..._first, ..._options]
    this.setPocOptions(options);
    // console.log('>>>>>>>>>>> poc option: ' + JSON.stringify(lastOptions))
  }

  @action handleSort(clickedColumn, type) {
    let sortData = this.pocData;
    if (this.column !== clickedColumn) {
      sortData = utils.sortBy(sortData, clickedColumn, type);
      sortData = sortData.slice().reverse();
      this.column = clickedColumn;
      this.direction = "descending";
    } else {
      sortData = utils.sortBy(sortData, clickedColumn, type);
      this.direction = this.direction === "descending" ? "ascending" : "descending";
      if (this.direction === "descending") {
        sortData = sortData.slice().reverse();
      }
    }
    this.setPocData(sortData);
  }

  @action defaultSort(clickedColumn, type) {
    let sortData = this.pocData;
    sortData = utils.sortBy(sortData, clickedColumn, type);
    sortData = sortData.slice();
    this.column = clickedColumn;
    this.direction = "ascending";
    this.setPocData(sortData);
  }

  async getAllPocs(type) {
    try {
      this.setAllPocsIsLoading(true);
      let result;
      if (type === "beaconManufacturers") {
        result = await TacService.getManufacturers();
      } else {
        result = await PocService.searchPOCByType(type);
      }
      console.log("log ~ file: pocState.js ~ line 183 ~ PocState ~ getAllPocs ~ result", result);
      this.setAllPocsIsLoading(false);
      this.setAllPocs(result.docs);
    } catch (error) {
      console.log("log ~ file: pocState.js ~ line 186 ~ PocState ~ getAllPocs ~ error", error);
      this.setAllPocsIsLoading(false);
    }
  }
  @action async setAllPocs(pocAll) {
    console.log("log ~ file: pocState.js ~ line 192 ~ @actionsetAllPocs ~ pocAll", pocAll);
    this.pocAll = pocAll;
    this.setFiltered(pocAll);

    /*let IBRDDuplicates = this.pocAll.filter((poc) => poc.name.includes("International Beacon Registration Database (IBRD)"))
    console.log('log ~ file: pocState.js ~ line 209 ~ PocState ~ @actionsetAllPocs ~ IBRDDuplicates', IBRDDuplicates);
    let firstElementToKeep = IBRDDuplicates.shift();
    console.log('log ~ file: pocState.js ~ line 211 ~ PocState ~ @actionsetAllPocs ~ firstElementToKeep', firstElementToKeep);
    let toDelete = IBRDDuplicates.map(poc =>{
      poc._deleted = true;
      return poc;
    })
    let result = await PocService.storeBulkPOCs(toDelete);*/
  }

  @action expandPoc(poc) {
    console.log("log ~ file: pocState.js ~ line 199 ~ PocState ~ @actionexpandPoc ~ poc", poc);
    let newData = [];
    for (let aPoc of this.pocAllFiltered) {
      if (aPoc._id == poc._id) {
        aPoc.expanded = !aPoc.expanded;
      }
      newData.push(aPoc);
    }
    this.pocAllFiltered = newData;
  }

  @action selectPoc(poc) {
    console.log("log ~ file: pocState.js ~ line 199 ~ PocState ~ @selectPOC ~ poc", poc);
    let newData = [];
    for (let aPoc of this.pocAllFiltered) {
      if (aPoc._id == poc._id) {
        aPoc.selected = !aPoc.selected;
      }
      newData.push(aPoc);
    }
    this.pocAllFiltered = newData;
  }

  @action selectAll() {
    console.log("log ~ file: pocState.js ~ line 199 ~ PocState ~ @selectAll ~ ");
    let newData = [];
    for (let aPoc of this.pocAllFiltered) {
      aPoc.selected = true;
      newData.push(aPoc);
    }
    this.pocAllFiltered = newData;
    this.selectAllToggled = true;
  }

  @action unSelectAll() {
    console.log("log ~ file: pocState.js ~ line 199 ~ PocState ~ @selectAll ~ ");
    let newData = [];
    for (let aPoc of this.pocAllFiltered) {
      aPoc.selected = false;
      newData.push(aPoc);
    }
    this.pocAllFiltered = newData;
    this.selectAllToggled = false;
  }

  getSelectedPoc() {
    let selectedIndex = this.pocAllFiltered.findIndex((row) => row.selected == true);
    console.log("log ~ file: pocState.js ~ line 251 ~ PocState ~ getSelectedPoc ~ selectedIndex", selectedIndex);
    if (selectedIndex != -1) {
      return this.pocAllFiltered.filter((row) => row.selected == true);
    } else {
      return this.pocAllFiltered;
    }
  }

  @action setFiltered(pocAll) {
    //console.log('log ~ file: pocState.js ~ line 214 ~ PocState ~ @actionsetFiltered ~ pocAll', pocAll);

    if (this.midFilter) {
      pocAll = pocAll.filter((row) => row.mid == this.midFilter);
    }

    if (this.searchString == "") {
      this.pocAllFiltered = pocAll;
    } else {
      const options = {
        isCaseSensitive: true,
        includeScore: false,
        shouldSort: true,
        // includeMatches: false,
        // findAllMatches: false,
        // minMatchCharLength: 1,
        // location: 0,
        threshold: 0.3,
        distance: 200,
        // useExtendedSearch: false,
        ignoreLocation: true,
        // ignoreFieldNorm: false,
        keys: ["name", "city", "address", "website_url", "email", "country","mid", "countryCode"],
      };

      pocAll = pocAll.map((poc) => {
        if (!poc.name) {
          console.log("emptyName", poc);
          poc.name = "";
        }
        return poc;
      });

      let withNames = [];
      for (let poc of pocAll) {
        if (poc.name) {
          withNames.push(poc);
        }
      }

      //console.log("log ~ file: pocState.js ~ line 306 ~ PocState ~ @actionsetFiltered ~ pocAll", pocAll);

      const fuse = new Fuse(withNames, options);

      // Change the pattern
      const pattern = this.searchString;
      //console.log('log ~ file: pocState.js ~ line 238 ~ PocState ~ @actionsetFiltered ~ pattern', pattern);

      let result = fuse.search(pattern);
      console.log('log ~ file: pocState.js ~ line 315 ~ PocState ~ @actionsetFiltered ~ result', result);
      this.pocAllFiltered = result;
      //console.log('log ~ file: pocState.js ~ line 239 ~ PocState ~ @actionsetFiltered ~ result', result);
    }
  }

  @action setAllPocsIsLoading(value) {
    this.pocAllIsLoading = value;
  }

  setSearchString(string) {
    this.searchString = string;
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      //simple debounce
      this.setFilter(string);
    }, 300);
  }

  @action setFilter(string) {
    this.searchString = string;
    this.setFiltered(this.pocAll);
  }

  @action setMidFilter(mid) {
    console.log("log ~ file: pocState.js ~ line 317 ~ PocState ~ @actionsetMidFilter ~ mid", mid);
    this.midFilter = mid;
    this.setFiltered(this.pocAll);
  }

  @action clearFilter() {
    this.searchString = "";
    this.midFilter = "";
    this.setFiltered(this.pocAll);
  }

  async deletePOC(id) {
    this.setIsLoading(true);
    let deleted = await PocService.deletePOC(id);
    this.setIsLoading(false);
    return deleted;
  }

  async getLastId() {
    let allItems = await PocService.getAllPoc();
    if (allItems) {
      let sortData = utils.sortBy(allItems, "id");
      this.lastPocId = sortData[sortData.length - 1].id;
    }
    return this.lastPocId;
  }

}

const singleton = new PocState();
export default singleton;
