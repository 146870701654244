import React, { Component } from "react";
import { Segment, Header, Button, Icon, Dimmer, Loader } from "semantic-ui-react";
import * as UserService from "../services/userServices";
import Dropzone from "react-dropzone";
import i18n from "i18next";
export default class ImageUploader extends React.Component {
  state = {
    imageData: "",
    value: "",
    loading: false,
  };

  constructor(props) {
    super(props);
    this.uploadFileRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.value) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  onFileSelected = async (acceptedFiles) => {
    let file = acceptedFiles[0];

    if (!file) {
      this.setState({
        fileError: true,
      });
      return;
    }
    console.log("IBRD: ImageUploader -> onFileSelected -> event", file, acceptedFiles);
    var reader = new FileReader();
    this.setState({
      loading: true,
    });
    reader.onloadend = async (e) => {
      console.log("log: ImageUploader -> reader.onLoad -> e", e);

      let onlyBase64 = e.target.result.split(",")[1];

      try {
        let response = await UserService.lambdaUploadFile(onlyBase64);
        await response.body.fullPath;
        let fullPath = response.body.fullPath;
        this.setState({
          value: fullPath,
          loading: false,
        });
        this.sendOnChangeEvent(fullPath);
      } catch (error) {
        this.setState({
          loading: false,
        });
        console.log("log: ImageUploader -> reader.onloadend -> error", error);
      }
    };
    reader.readAsDataURL(file);
  };

  sendOnChangeEvent(fullPath) {
    if (this.props.onChange) {
      this.props.onChange(null, {
        name: this.props.name,
        value: fullPath,
      });
    }
  }

  onClick = () => {
    this.uploadFileRef.current.click();
  };

  render() {
    let { value, loading } = this.state;
    let { disabled } = this.props;
    return (
      <Dropzone onDrop={this.onFileSelected} accept=".jpeg,.png,.jpg">
        {({ getRootProps, getInputProps }) => (
          <Segment placeholder className="imageUpload-segment">
            <Dimmer active={loading}>
              <Loader>{i18n.t('uploading-image')}</Loader>
            </Dimmer>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <PlaceHolderOrImage imageData={value} />
            </div>
          </Segment>
        )}
      </Dropzone>
    );
  }
}

const PlaceHolderOrImage = ({ imageData, onClick }) => {
  console.log("log: PlaceHolderOrImage -> imageData", imageData);
  if (imageData) {
    return <img className="imageUpload-preview" src={imageData} />;
  } else {
    return (
      <>
        <Header icon className="imageUpload-header">
          <Icon name="camera" color="grey" />
          {i18n.t('upload-image')}
          <Header.Subheader>{i18n.t('png-and-jpeg-supported')}</Header.Subheader>
        
        </Header>
      </>
    );
  }
};
